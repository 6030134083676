import {
  CHECK_PASSWORD_TYPE,
  PICTURE_PASSWORD,
  STANDARD_PASSWORD
} from '../actions';

const initialState = {};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_PASSWORD_TYPE:
        return Object.assign({}, state, {asyncStatus: 'PENDING', set_password: false, data: []})
    case PICTURE_PASSWORD:
        return Object.assign({}, state, {asyncStatus: 'SUCCESS', data: action.payload.data.set_password ? [] : action.payload.data, set_password: action.payload.data.set_password, error: action.payload.error})
    case STANDARD_PASSWORD:
        return Object.assign({}, state, {asyncStatus: 'SUCCESS', data: []})
    default:
        return state;
  }
}

export default LoginReducer;
