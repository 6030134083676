import React, {Component} from  'react'
import _ from 'lodash'

/**
 * Applies different classes to an icon element depending on which state the element is in.
 * props:
 *
 * enabled {boolean} - whether the element is enabled, if not enabled it cannot be activated
 * active {boolean}
 *
 * iconId - id to give the child icon
 * activeClasses {string}
 * inactiveClasses {string}
 * disabledClasses {string}
 *
 * onClick()
 * onActivated()
 * onDeactivated()
 * onMouseOver()
 * onMouseLeave()
 *
 * Uses the properties enabled (true or false) and active (true or false).
 * Active is always false when enabled is false
 *
 */
class MultiStateIcon extends Component {

    constructor(props) {
        super(props);
        console.log(this.props);
        this.state = {
            enabled: this.props.enabled,
            active: this.props.active
        };
        this.onClick = this.onClick.bind(this);
    }

    /**
     * On click, if enabled then switch active state, call onClick() prop, followed by either onActivated()
     * or onDeactivated() prop handler.
     */
    onClick() {
        if(this.state.enabled === true) {
            const newActiveState = !this.state.active;
            this.setState({
                active: !this.state.active
            });

            // Call on click if defined
            if(this.props.onClick)
                this.props.onClick();

            if(this.props.joyrideShouldRun)
                this.props.facilitateHeaderJoyride();

            // Call handler prop if provided
            if((newActiveState === true) && (this.props.onActivated)) {
                this.props.onActivated();
            } else if((newActiveState === false) && (this.props.onDeactivated)) {
                this.props.onDeactivated();
            }
        }
    }

    getClass() {
        let classes = this.props.sharedClasses ? this.props.sharedClasses + " " : "";
        if(this.state.enabled) {
            classes += (this.state.active ? this.props.activeClasses : this.props.inactiveClasses);
        } else {
            classes += this.props.disabledClasses;
        }
        return classes;
    }

    getIcon() {
        if (this.props.imageIcon) {
            return (
                <span id={this.props.iconId}
                        onClick={this.onClick}
                        onMouseOver={this.props.onMouseOver}
                        onMouseLeave={this.props.onMouseLeave}
                        className={this.getClass()}
                        title={this.props.tooltipText} 
                >
                    <img src={this.props.imageIcon} />
                </span>
            );
        } else {
            return <i id={this.props.iconId}
                        onClick={this.onClick}
                        onMouseOver={this.props.onMouseOver}
                        onMouseLeave={this.props.onMouseLeave}
                        className={this.getClass()}
                        title={this.props.tooltipText}
                    />
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.enabled !== nextProps.enabled || this.props.active !== nextProps.active) {
            this.setState({active: nextProps.active, enabled: nextProps.enabled})
        }
    }

    render() {
        return (
            this.getIcon()
       )
    }
}

export default MultiStateIcon
