export default function ShowWhatYouKnowReducer(state = {displayedTab: undefined}, action) {
    switch (action.type) {
        case 'SHOW_WHAT_YOU_KNOW_SWITCH_TABS':
            return Object.assign({}, state, {
                displayedTab: action.payload,
            });

        default:
           return state;
    }
}