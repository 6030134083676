import React from 'react'
import ToolTip from 'react-portal-tooltip'

let style1 = {
    style: {
        height: "auto",
        width: "215px",
        border: "1px solid #F5F5F5",
        borderRadius: "8px",
        backgroundColor: "rgba(255,255,255)",
        boxShadow: "0 2px 12px 0 rgba(0,0,0,0.5)",
        padding: "20px 20px 20px 20px",
        left: '0px',
        zIndex: 1000
    },
    arrowStyle: {
        color: "white",
        borderColor: "#F5F5F5"
    }
};

class AddPopOver extends React.Component {
    render() {
        let myPar = "#"+ this.props.myId;
        let showToolTip = true; //this.props.isTooltipActive
        return (
            <ToolTip id="onlytooltipintheworld" active={showToolTip} position={this.props.position} arrow="center" parent={myPar} style={style1} positionLeft={0}>
                {this.props.children}
            </ToolTip>
        )
    }
}

export default AddPopOver;
