import React, { Component } from "react";
import * as actions from "../../redux/actions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import authApi from "../../api/authAPI";

class MagicBoxReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: undefined,
    };
  }

  buildMagicBoxUrl = (token, bookId) => {
    console.log('buildMagicBoxUrl called');
    console.log(`bookId:${bookId} and token:${token}`);
    if (process.env.NODE_ENV === "production") {
      console.log(`production: https://www.knowandgrowunlimited.com/security/sw/websso.htm?code=${token}&skunumber=${bookId}`);

      return `https://www.knowandgrowunlimited.com/security/sw/websso.htm?code=${token}&skunumber=${bookId}`;
    } else {
      console.log("else production");
      console.log(`production: https://mbx-staging.getmagicbox.com/security/sw/websso.htm?state=reader&code=${token}&skunumber=${bookId}`);

      return `https://mbx-staging.getmagicbox.com/security/sw/websso.htm?state=reader&code=${token}&skunumber=${bookId}`;
    }
  };

  componentWillMount = () => {
    const userId = localStorage.getItem("user_id");

    authApi.getOAuthToken(userId).then((resp) => {
      if (resp) {
        this.setState({
          token: resp.data.token,
        });
      }
    });
  };

  render() {
    const { bookId, bookName } = this.props;
    const { token } = this.state;
    console.log(`state:${token} and bookId:${bookId} and bookName:${bookName}`);
    return (
      <>
        {bookId && token && (
          <div
            style={{
              position: "absolute",
              top: "0px",
              bottom: "0px",
              left: "0px",
              right: "0px",
              marginTop: "60px",
            }}
          >
            <iframe
              title={bookName}
              src={this.buildMagicBoxUrl(token, bookId)}
              width="100%"
              height="100%"
              allowFullScreen
            ></iframe>
          </div>
        )}
      </>
    );
  }
}

export default connect(null, actions, null, { withRef: true })(
  withRouter(MagicBoxReader, { withRef: true })
);
