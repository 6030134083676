import React,{Component} from 'react'
import CollapsibleComponent from '../../containers/Details/CollapsibleComponent'
import {markdownHelper} from '../../markdown/MarkdownHelper';
import mySolidArrowRight from '../../assets/icons/SVG/my-solid-arrow-right.png';
import mySolidArrow from '../../assets/icons/SVG/my-solid-arrow.png'

class WhatsNextComponent extends Component{
    
     render(){
         let finalData=this.props.content.whats_next;
         let contentBox=[
            {
                name:'STRUCTURES, ROUTINES, AND RESOURCES',
                content:'structuresRoutinesResources'
            },
            // {
            //     name:'INDEPENDENT APPLICATIONS',
            //     content:'independentApplications'
            // },
            {
                name:'INDEPENDENT READING/WRITING',
                content:'independentApplications'
            },
            {
                name:'GUIDED PRACTICE',
                content:'guidedPractice'
            },
            {
                name:'ASSESSMENTS',
                content:'assessment'
            },
            {
                name:'ELL/ELD SUPPORTS',
                content:'suggestedText'
            },
            {
                name:'READING/WRITING CONNECTIONS',
                content:'readingWritingConnections'
            },
            {
                name:'HOME/SCHOOL CONNECTIONS',
                content:'homeSchoolConnections'
            }
         ];

         console.log("FINAL DATA: ",this.props.content);
        
         return(
            <div className="list-of-items">
                {this.props.content.lesson? "" : <div> No data found</div>}
                {
                    contentBox.map((main,i)=>(
                        finalData[main.content] &&
                    (<CollapsibleComponent key={i} idText={'whatsnext-row'} collapsed={false} header={main.name} headerStyle={{fontSize: "16px",letterSpacing:1.5, fontWeight:'bold', color: "#4A4A4A", borderBottom: "1px solid rgba(151, 151, 151,0.5)", paddingBottom: "10px", paddingTop: "15px",fontFamily:"ProximaNova" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        {finalData[main.content] && finalData[main.content].text ?
                            <div className='lesson-text-padding' dangerouslySetInnerHTML={{__html: markdownHelper.toHTML(finalData[main.content].text)}}/> :
                            <div className='lesson-text-padding'>No data found</div>
                        }
                    </CollapsibleComponent> )
                ))}
            </div>
        )
     }
}

export default WhatsNextComponent