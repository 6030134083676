import {
    GET_UNITS_REQUEST,
    GET_UNITS_SUCCESS,
    GET_UNITS_FAILURE,
    GET_MORE_UNITS_REQUEST,
    GET_MORE_UNITS_SUCCESS,
    GET_MORE_UNITS_FAILURE,
    GET_ELEARNING_UNITS_REQUEST,
    GET_ELEARNING_UNITS_SUCCESS,
    GET_ELEARNING_UNITS_FAILURE,
    GET_FOUNDATIONAL_UNITS_REQUEST,
    GET_FOUNDATIONAL_UNITS_SUCCESS,
    GET_FOUNDATIONAL_UNITS_FAILURE,
    GET_MORE_FOUNDATIONAL_UNITS_REQUEST,
    GET_MORE_FOUNDATIONAL_UNITS_SUCCESS,
    GET_MORE_FOUNDATIONAL_UNITS_FAILURE,
    GET_MORE_ELEARNING_UNITS_REQUEST,
    GET_MORE_ELEARNING_UNITS_SUCCESS,
    GET_MORE_ELEARNING_UNITS_FAILURE,
} from '../actions';


const initialState = {};

const UnitsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_UNITS_REQUEST:
        case GET_MORE_UNITS_REQUEST:
        case GET_ELEARNING_UNITS_REQUEST: 
        case GET_FOUNDATIONAL_UNITS_REQUEST:
        case GET_MORE_FOUNDATIONAL_UNITS_REQUEST:
        case GET_MORE_ELEARNING_UNITS_REQUEST:
            return Object.assign({},state, {asyncStatus:  'PENDING'});
        case GET_UNITS_SUCCESS:
        case GET_ELEARNING_UNITS_SUCCESS:
        case GET_FOUNDATIONAL_UNITS_SUCCESS:
            return Object.assign({},state, {asyncStatus:  'SUCCESS', data: action.payload});
        case GET_UNITS_FAILURE:
        case GET_ELEARNING_UNITS_FAILURE:
        case GET_FOUNDATIONAL_UNITS_FAILURE:
            return Object.assign({},state, {asyncStatus:  'FAILURE', data: action.payload});
        case GET_MORE_UNITS_SUCCESS:
        case GET_MORE_FOUNDATIONAL_UNITS_SUCCESS:
        case GET_MORE_ELEARNING_UNITS_SUCCESS:
            return Object.assign({},state, {asyncStatus:  'SUCCESS', data: {
                ...action.payload,
                results: [
                    ...state.data.results,
                    ...action.payload.results
                ]
            }});
        case GET_MORE_UNITS_FAILURE:
        case GET_MORE_FOUNDATIONAL_UNITS_FAILURE:
        case GET_MORE_ELEARNING_UNITS_FAILURE:
            return Object.assign({},state, {asyncStatus:  'FAILURE', data: {
                ...action.payload,
                results: [
                    ...state.data.results,
                    ...action.payload.results
                ]
            }});
        default:
            return state;
    }
};

export default UnitsReducer;