import {
    GET_CUSTOM_FILTERS_REQUEST,
    GET_CUSTOM_FILTERS_SUCCESS,
    GET_CUSTOM_FILTERS_FAILURE,
    UPDATE_FILTER_OPTIONS,
    DECREMENT_CUSTOM_FILTERS_STRAND_TOTAL
} from '../actions';

const initialState = {};

const GetCustomFiltersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CUSTOM_FILTERS_REQUEST:
            return Object.assign({},state, {asyncStatus:  'PENDING'});
        case GET_CUSTOM_FILTERS_SUCCESS:
            return Object.assign({},state, {asyncStatus:  'SUCCESS', data: action.payload, strand: action.strand, strand_total: action.payload.total});
        case GET_CUSTOM_FILTERS_FAILURE:
            return Object.assign({},state, {asyncStatus:  'FAILURE', data: action.payload, strand: action.strand});
        case UPDATE_FILTER_OPTIONS:
            return Object.assign({}, state, {data: {filters: action.payload}})
        case DECREMENT_CUSTOM_FILTERS_STRAND_TOTAL:
            return Object.assign({}, state, {strand_total: parseInt(state.strand_total)-1});
        default:
            return state;
    }
};

export default GetCustomFiltersReducer;
