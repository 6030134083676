import React, {Component} from 'react'
import {connect} from 'react-redux'
import * as actions from '../../redux/actions'
import "../../assets/styles/index.scss"
import Joyride from 'react-joyride'
import {browserHistory} from 'react-router'

// const tooltipStrings = {
//   skip: 'Skip tour',
//   next: 'NEXT',
//   close: 'START A NEW TOUR',
//   last: 'START A NEW TOUR',
//   back: 'Back'
// }

const tooltipStringsEnd = {
  skip: 'Exit Tour',
  next: 'Next',
  close: 'START A NEW TOUR',
  last: 'START A NEW TOUR',
  back: 'Back'
}

class JoyrideCustomComponent extends Component {
  constructor(props){
    super(props);
      this.state = {
        endIndex: 4,
        stepIndex: 0,
        run: false,
        locale: tooltipStringsEnd,
        allowClicksThruHere: true
      }

      this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  componentWillReceiveProps(next){
    if(next.reset){
      this.props.resetResetJoyride();
      return this.joyride.reset(true);
    }
    this.setState({
      run: next.run,
      endIndex: next.endIndex,
      stepIndex: next.stepIndex,
      locale: next.locale,
      steps: next.steps,
      allowClicksThruHere: next.allowClicksThruHereProp
    });
    if(next.location.action==="POP")
        this.props.stopJoyride();
  }

  addSteps = (steps)=>{
    if(!Array.isArray(steps))
      steps = [steps];
    if(steps.length < 1)
      return;
    this.setState(currentState => {
      currentState.steps = currentState.steps.concat(steps);
      return currentState;
    })
  }

  joyrideCallback = (obj)=>{
    if( obj.type==='error:target_not_found')
      return this.props.stopJoyride();
    if(obj.action === 'next' && obj.type === 'step:before' && obj.step && obj.step.title === 'Assign - Adding')
      this.props.allowClicksThruHere(true);
    if(obj.action === 'next' && obj.type === 'step:before' && obj.step && obj.step.allowClicksThruHere === false)
      this.props.allowClicksThruHere(false);
    if(obj.action==='close' || obj.action==='skip' || obj.type === 'finished'){
      this.props.stopJoyride();
      if((obj.action==='close' || obj.action==='skip' || obj.type === 'finished') && this.props.location.pathname==='/home') {
        browserHistory.push('/planner');
      }
      this.props.launchTourModal();
    }
  }

  render(){
    return (
      <div>
        <Joyride
            ref={c=>(this.joyride = c)}
            callback={this.joyrideCallback}
            debug={false}
            run={this.state.run && this.state.stepIndex <= this.state.endIndex}
            autoStart={this.state.run && this.state.stepIndex <= this.state.endIndex}
            type='continuous'
            steps={this.state.steps}
            stepIndex={this.state.stepIndex}
            disableOverlay={true}
            allowClicksThruHole={this.state.allowClicksThruHere === false ? false : true}
            showSkipButton={true}
            showStepsProgress={false}
            locale={tooltipStringsEnd}
            keyboardNavigation={false}
            />
            {this.props.children}
        </div>
      )
  }

}

const mapStateToProps = (state) => {
    return ( {
      run: state.JoyrideReducer.joyrideShouldRun,
      stepIndex: state.JoyrideReducer.joyrideStepIndex,
      steps: state.JoyrideReducer.joyrideSteps,
      endIndex: state.JoyrideReducer.joyrideShouldEndAt,
      reset: state.JoyrideReducer.reset,
      allowClicksThruHereProp: state.JoyrideReducer.allowClicksThruHere
    });
};

export default connect(mapStateToProps, actions)(JoyrideCustomComponent)
