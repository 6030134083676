import {
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_FAILURE
} from '../actions';


const initialState = {};

const UserByIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_REQUEST:
            return Object.assign({},state, {asyncStatus:  'PENDING'});
        case GET_USER_SUCCESS:
            return Object.assign({},state, {asyncStatus:  'SUCCESS', data: {...state.data, [action.id]: action.payload}});
        case GET_USER_FAILURE:
            return Object.assign({},state, {asyncStatus:  'FAILURE', data: action.payload});
        default:
            return state;
    }
};

export default UserByIdReducer;