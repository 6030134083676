import React,{Component} from 'react'
import {Row,Col} from 'react-bootstrap'

class SummaryRow extends Component {
    render() {
        return (
            <Row id='summary-row'>
                <Col sm={12} md={3} className='summary-heading' id='bcol-padding'>
                    {this.props.heading}
                </Col>
                <Col sm={12} md={9} className='summary-content'>
                    {this.props.children}
                </Col>
            </Row>);
    }
}

class SummaryContent extends Component{
    render(){
        return(
            <div className="list-of-items">
                {this.props.content?
                   <div>
                       <SummaryRow heading="GRADE LEVEL">
                           {this.props.content.grades? this.props.content.grades.join(', ') : "No Grade level"}
                       </SummaryRow>
                       <div className='main-seperator'/>
                       {/* <SummaryRow heading="STANDARDS">
                           {this.props.content.focusedStandards &&
                                <span>
                                    {this.props.content.focusedStandards.map((focusedStandard,i)=>
                                        (
                                            <span style={{fontWeight: "700"}}>
                                                {i===0?focusedStandard:(", ").concat(focusedStandard)}
                                            </span>
                                        )
                                    )}
                                </span>
                           }
                           {this.props.content.focusedStandards &&
                                this.props.content.focusedStandards[0] &&
                                this.props.content.otherStandards &&
                                this.props.content.otherStandards[0] &&
                                    <span>, </span>
                           }
                           {this.props.content.otherStandards &&
                                <span>
                                    {this.props.content.otherStandards.map((otherStandard,i)=>
                                        (
                                            <span>{i===0?otherStandard:(", ").concat(otherStandard)}</span>)
                                        )
                                    }
                                </span>
                           }
                       </SummaryRow> */}
                       <div className='main-seperator'/>
                       <SummaryRow heading="SKILLS/STRATEGIES">
                           {this.props.content.skills? this.props.content.skills.join(', ') : "No Skills"}
                       </SummaryRow>
                       <div className='main-seperator'/>
                       <SummaryRow heading="TYPE">
                           {this.props.content.lessonType? this.props.content.lessonType : "No Lesson Type"}
                       </SummaryRow>
                       <div className='main-seperator'/>
                       <SummaryRow heading="UNIT">
                           {this.props.content.units && this.props.content.units.length > 0 ? this.props.content.units[0].name : "No Units"}
                       </SummaryRow>
                    </div>
                    :<div>No summary Found</div>
                }
            </div>
        );
    }
}

export default SummaryContent