import React, { Component } from "react";
import constants from "../constants/index";
import { FilterMenuButtons } from "../components/FilterMenu/FilterMenuButtons";
import { FilterMenu } from "../components/FilterMenu/FilterMenu";
import * as actions from "../redux/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";
import {
  gradesOnly,
  lessonFilterObjects,
  unitFilterObjects,
  resourceFilterObject,
  assessmentsFilterObject,
  bookFilterObjectNonStudent,
  bookFilterObjectStudent,
} from "../components/FilterMenu/filters";

/**
 *
 * Container for the filter menu
 * Props
 * typeOfResource
 * strand
 */
class NewFilterMenuContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      selectedFilters: { filterList: [] },
      queryObj: {},
      showLoader: false,
      shouldReset: false,
    };
    this.onFilterChange = this.onFilterChange.bind(this);
    this.addingFiltersAction = _.once(this.props.addingFiltersAction);
  }

  getSelectedFilters() {
    let x = this.state?.selectedFilters?.filterList
      ? this.state.selectedFilters.filterList
      : this.state.selectedFilters;
    if (
      JSON.parse(window.sessionStorage.getItem("selectedFilters")) != null &&
      JSON.parse(window.sessionStorage.getItem("selectedFilters")).length > 0
    ) {
      x = JSON.parse(window.sessionStorage.getItem("selectedFilters"));
    }
    return x || [];
  }

  onFilterChange(
    filterObj,
    isChecked,
    isRemove,
    buttonClickValue,
    typeOfResource,
    query
  ) {
    let filters = [];
    if (isChecked) {
      if (filterObj.query === "lexileLevel") {
        filters = _.filter(
          this.getSelectedFilters(),
          (selectedFilter) => selectedFilter.query !== filterObj.query
        );
        filters.push(filterObj);
        this.setState({ min: filterObj.min, max: filterObj.max });
      } else {
        let x = this.getSelectedFilters();
        filters = Array.isArray(x) ? x.slice(0) : [];
        filters.push(filterObj);
        this.combineFilters(this.state.filters, filters);
      }
    } else {
      filters = _.filter(
        this.getSelectedFilters(),
        (selectedFilter) =>
          selectedFilter._id !== filterObj._id ||
          selectedFilter.name !== filterObj.name
      );
      this.unselectFilter(this.state.filters, filterObj);
      if (filterObj.query === "lexileLevel") {
        filters = _.filter(
          this.getSelectedFilters(),
          (selectedFilter) => selectedFilter.query !== filterObj.query
        );
        filters.push(filterObj);
      }
    }

    let queryObjectToString = "";
    for (let i of Object.keys(query)) {
      for (let value of query[i]) queryObjectToString += `&${i}=${value}`;
    }

    window.sessionStorage.setItem("selectedFilters", JSON.stringify(filters));

    this.setState(
      {
        selectedFilters: { filterList: filters },
        queryObj: query,
        showLoader: true,
      },
      () => {
        if (this.props.location.pathname.includes("library")) {
          this.props.getCustomLibraryFiltersAction(
            this.props?.strand ?? this.props?.libraryStrand,
            typeOfResource,
            queryObjectToString,
            undefined,
            true
          );
        }
        this.props.addingFiltersAction(filters, queryObjectToString);
        let self = this;
        setTimeout(function () {
          self.setState({
            showLoader: self.props.showFilterLoader,
          });
        }, 5000);
      }
    );
  }

  clearAllFilters() {
    const typeOfResource =
      this.props?.typeOfResource || this.getTypeOfResource();
    const strandFromPath = this.props.location.pathname.split("/").slice(-1)[0];
    const strand =
      this.props.strand || constants.convertToStrandNumber(strandFromPath);
    let filters = [...this.state.filters];

    filters.map((item, i) =>
      item?.filters.map((filter, index) =>
        filter.checked ? (filters[i].filters[index].checked = false) : ""
      )
    );

    if (this.props.location.pathname.includes("browse")) {
      if (typeOfResource === "gradesOnly") {
        window.sessionStorage.removeItem("selectedFilters");
        this.props.getHomeResourcesAction();
        setTimeout(() => {
          this.props.getCustomFiltersAction(strand, typeOfResource);
        }, 2000);
        this.props.addingFiltersAction([]);
        this.setState({ filters, selectedFilters: { filterList: [] } });
      } else {
        window.sessionStorage.removeItem("selectedFilters");
        if (typeOfResource === "books") {
          this.props.fetchBooks();
          this.props.addingFiltersAction([]);
          this.setState({ filters, selectedFilters: { filterList: [] } });
        } else {
          this.props.addingFiltersAction([]);
          this.setState({ filters, selectedFilters: { filterList: [] } });
        }
      }
    } else {
      window.sessionStorage.removeItem("selectedFilters");
      this.props.addingFiltersAction([]);
      this.setState({ filters, selectedFilters: { filterList: [] } });
    }
  }

  getFilterObject(typeOfResource) {
    let bookFilterObject = [];

    if (localStorage.user_type !== "Student") {
      bookFilterObject = bookFilterObjectNonStudent();
    } else {
      bookFilterObject = bookFilterObjectStudent();
    }

    switch (typeOfResource) {
      case "gradesOnly":
        return gradesOnly();
      case "lessons":
        return lessonFilterObjects();
      case "resources":
      case "teachingResources":
        return resourceFilterObject();
      case "units":
        return unitFilterObjects();
      case "books":
        return bookFilterObject;
      case "assessments":
        return assessmentsFilterObject();
      default:
        return;
    }
  }

  createFilterGroupData(typeOfResource, filterOptions = {}) {
    const filterObject = this.getFilterObject(typeOfResource);
    const blankFilterObject = _.cloneDeep(filterObject);

    if (Object.keys(filterOptions).length) {
      _.each(filterObject, (filterObjGroup, i) => {
        if (filterOptions[filterObjGroup.queryString]) {
          if (filterObjGroup.queryString !== "resourceTypes") {
            filterObject[i].filters = filterOptions[filterObjGroup.queryString];
          } else if (filterObjGroup.queryString === "resourceTypes") {
            filterOptions[filterObjGroup.queryString].forEach(function (
              item,
              key
            ) {
              if (
                item &&
                item.value &&
                (item.value === "Shared Writing Lesson" ||
                  item.value === "Interactive Writing" ||
                  item.value === "Introducing Centers" ||
                  item.value === "VBook Mini Lesson")
              ) {
                delete filterOptions[filterObjGroup.queryString][key];
              }
            });
            filterObject[i].filters = filterOptions[filterObjGroup.queryString];
          }
          if (
            filterObjGroup.queryString === "skills" ||
            filterObjGroup.queryString === "assessmentTypes" ||
            filterObjGroup.queryString === "genres" ||
            filterObjGroup.queryString === "themes" ||
            filterObjGroup.queryString === "topics"
          ) {
            filterObject[i].filters.sort(function (a, b) {
              if (a.value.toLowerCase() < b.value.toLowerCase()) {
                return -1;
              }
              if (a.value.toLowerCase() > b.value.toLowerCase()) {
                return 1;
              }
              return 0;
            });
          }
          for (let j = 0; j < filterObject[i].filters.length; j++) {
            let matchingFilter = _.find(this.getSelectedFilters(), {
              value: filterObject[i]?.filters[j]?.value,
              _id: filterObject[i]?.filters[j]?._id,
            });
            if (filterObject[i] && filterObject[i].filters[j])
              filterObject[i].filters[j].checked = matchingFilter !== undefined;
            if (
              matchingFilter !== undefined &&
              filterObject[i].filters[j].value === "Lexile Level"
            ) {
              filterObject[i].filters[j].min = matchingFilter.min;
              filterObject[i].filters[j].max = matchingFilter.max;
            }
          }
        }
      });
    } else {
      return filterObject;
    }

    //On My Library, we have an issue with race conditions allowing incorrect filters to be partially loaded.
    //To prevent this, we do not load any filters in the UI if any category of filters is incomplete.
    return _.every(
      filterObject,
      (filterSubObject) => filterSubObject.filters.length > 0
    )
      ? filterObject
      : blankFilterObject;
  }

  componentWillReceiveProps(props) {
    if (props.shouldReset && !this.state.shouldReset) {
      this.setState({ shouldReset: true });
      this.clearAllFilters();
      props.updateShouldReset(false);
      this.setState({ shouldReset: false });
    }

    if (
      this.props.strand !== props.strand ||
      this.props.typeOfResource !== props.typeOfResource
    ) {
      // Strand has changed, clear selected filters
      this.setState({ selectedFilters: { filterList: [] }, queryObj: {} });
      this.props.addingFiltersAction([]);
    } else {
      // If selected filters have changed, update state
      let selectedFilters = this.getSelectedFilters(),
        stateSelectedFilterCount = selectedFilters.length,
        propsSelectedFilterCount =
          props.selectedFilters && props.selectedFilters.filterList
            ? props.selectedFilters.filterList.length
            : 0;

      if (stateSelectedFilterCount !== propsSelectedFilterCount) {
        this.setState({
          selectedFilters: { filterList: props.selectedFilters.filterList },
        });
        selectedFilters = props.selectedFilters.filterList;
      }

      //Some functions below expect a query object with keys = collapsible category and value = array of selected filters.
      let filtersGroupedByName = _.groupBy(selectedFilters, "query"),
        lexile = _.pick(filtersGroupedByName, "lexileLevel").lexileLevel;

      _.unset(filtersGroupedByName, "lexileLevel");

      let groupedAndStrippedFilters = _.mapValues(
        filtersGroupedByName,
        (filterArr) => _.map(filterArr, (obj) => obj._id)
      );
      if (lexile && lexile[0].min)
        groupedAndStrippedFilters["minLexileLevel"] = [lexile[0].min];
      if (lexile && lexile[0].max)
        groupedAndStrippedFilters["maxLexileLevel"] = [lexile[0].max];

      this.setState({ queryObj: groupedAndStrippedFilters });
    }

    if (
      JSON.stringify(props.filterOptions) !==
      JSON.stringify(this.props.filterOptions)
    ) {
      let filters = this.createFilterGroupData(
        this.getTypeOfResource(),
        props.filterOptions
      );
      this.combineFilters(filters, this.getSelectedFilters());
    }
  }

  combineFilters(filters, selectedFilters) {
    _.each(selectedFilters, (selectedFilter) => {
      if (!selectedFilter) return;
      let selectedValue = selectedFilter.value;
      let selectedId = selectedFilter._id;
      _.each(filters, (filterGroupOption, optionIndex) => {
        _.each(filterGroupOption.filters, (filterOption, filterIndex) => {
          if (
            filterOption?.value === selectedValue &&
            filterOption?._id === selectedId
          ) {
            filters[optionIndex].filters[filterIndex].checked = true;
          }
        });
      });
    });

    this.setState({
      filters,
    });
  }

  unselectFilter(filters, unselectedFilter) {
    const { query, index, _id } = unselectedFilter;

    filters.forEach((filterGroup) => {
      const foundIndex = filterGroup.filters.findIndex(
        (filter) => filter._id === _id
      );

      const indexToUse = index ? index : foundIndex;
      if (
        filterGroup.queryString === query &&
        filterGroup.filters[indexToUse]
      ) {
        filterGroup.filters[indexToUse].checked = false;
      }
    });

    this.setState({ filters });
  }

  componentDidMount() {
    this.setState({
      filters: this.createFilterGroupData(this.getTypeOfResource()),
    });
  }

  libraryStrandSelected = (strandName) => {
    if (strandName)
      this.props.getCustomLibraryFiltersAction(
        constants.caseMap[strandName.toLowerCase()],
        constants.normalizeTypeName(this.props.typeOfResource),
        ""
      );
    else {
      this.props.clearMyLibraryFiltersAction();
      this.props.getMyLibraryAction(
        constants.normalizeTypeName(this.props.typeOfResource)
      );
    }
  };

  checkActive = (queryString, id) => {
    let filterOptions = this.props?.filterOptions;
    if (filterOptions) {
      let optionForCheck = filterOptions[queryString]?.find(
        (option) => parseInt(option?._id) === parseInt(id)
      );
      if (optionForCheck?.checked) {
        return true;
      } else {
        return false;
      }
    }
  };

  getTypeOfResource = () => {
    const pathName = this.props.location.pathname;

    if (this.props.typeOfResource) {
      return this.props.typeOfResource;
    } else {
      return pathName.endsWith("browse") || pathName.endsWith("library")
        ? "gradesOnly"
        : pathName.includes("teaching-resources") ||
          pathName.includes("resources")
        ? "teachingResources"
        : pathName.includes("lessons")
        ? "lessons"
        : pathName.includes("books")
        ? "books"
        : pathName.includes("units")
        ? "units"
        : pathName.includes("assessments")
        ? "assessments"
        : pathName.endsWith("browse")
        ? "gradesOnly"
        : "";
    }
  };

  getFiltered(filterObjIncomplete, isChecked, isRemove, queryString) {
    let filterObj = {
      ...filterObjIncomplete,
      query: queryString,
    };
    let queryObj = _.cloneDeep(this.state.queryObj);
    if (queryObj[filterObj?.query] === undefined)
      queryObj[filterObj?.query] = [];
    if (
      filterObj?.query !== "audiobook" &&
      filterObj?.query !== "lexileLevel"
    ) {
      if (isChecked) {
        queryObj[filterObj.query].push(filterObj._id);
      } else {
        queryObj[filterObj.query] = _.without(
          queryObj[filterObj.query],
          filterObj._id
        );
      }
    } else if (filterObj?.query === "audiobook") {
      queryObj.audiobook = [isChecked];
    } else {
      queryObj.minLexileLevel = [filterObj.min];
      queryObj.maxLexileLevel = [filterObj.max];
    }
    let buttonClickValue = constants.convertToStrandNumber(this.props.strand);

    this.onFilterChange(
      filterObj,
      isChecked,
      isRemove,
      buttonClickValue,
      this.getTypeOfResource(),
      queryObj
    );
  }

  customFilterClicked = (queryString, value, id) => {
    let filters = _.cloneDeep(this.state.filters);
    let selectedFilter = filters.find((obj) => obj.queryString === queryString);
    let selectedObj = selectedFilter?.filters.find(
      (filter) => filter?.value === value
    );
    // See if getFiltered is called and what happens
    if (selectedObj) {
      if (this.checkActive(queryString, id)) {
        this.getFiltered(selectedObj, false, undefined, queryString);
      } else {
        this.getFiltered(selectedObj, true, undefined, queryString);
      }
    }
  };

  getNewCustomFilters = () => {
    const newAllCustomFilters = this.props.newCustomFilters;
    const pathName = this.props.location.pathname;

    let newCustomFilters = {};
    if (newAllCustomFilters?.length) {
      if (pathName.includes("resources")) {
        let index = newAllCustomFilters.findIndex((item) => {
          return item.type === "teaching resource";
        });
        newCustomFilters = newAllCustomFilters[index];
      } else if (pathName.includes("books")) {
        let index = newAllCustomFilters.findIndex((item) => {
          return item.type === "books";
        });
        newCustomFilters = newAllCustomFilters[index];
      } else if (pathName.includes("units")) {
        let index = newAllCustomFilters.findIndex((item) => {
          return item.type === "units";
        });
        newCustomFilters = newAllCustomFilters[index];
      } else if (pathName.includes("assessments")) {
        let index = newAllCustomFilters.findIndex((item) => {
          return item.type === "assessments";
        });
        newCustomFilters = newAllCustomFilters[index];
      } else if (pathName.includes("lessons")) {
        let index = newAllCustomFilters.findIndex((item) => {
          return item.type === "lessons";
        });
        newCustomFilters = newAllCustomFilters[index];
      }
    }
    return newCustomFilters;
  };

  getCapitalizedStrand = () => {
    const pathName = this.props.location.pathname;
    const strandFromUrl = pathName.includes("books")
      ? "default"
      : window.location.pathname.substring(
          window.location.pathname.lastIndexOf("/") + 1
        );
    return pathName.includes("books")
      ? strandFromUrl.charAt(0) + strandFromUrl.slice(1)
      : strandFromUrl.charAt(0).toUpperCase() + strandFromUrl.slice(1);
  };

  render() {
    return this.props.shown ? (
      <div>
        {!this.props.location.pathname.includes("library") ? (
          <FilterMenuButtons
            newCustomFilters={this.getNewCustomFilters()}
            customFilterClicked={this.customFilterClicked}
            checkActive={this.checkActive}
            location={this.props.location}
            strand={this.getCapitalizedStrand()}
          />
        ) : (
          <div style={{ height: 20 }} />
        )}
        {this.props.location.pathname.endsWith("library") ||
        this.props.location.pathname.endsWith("browse") ? (
          <></>
        ) : (
          <FilterMenu
            filters={this.state.filters}
            shouldReset={this.props.shouldReset}
            onFilterChange={this.onFilterChange}
            filterLoadStatus={this.props.filterLoadStatus}
            strand={this.props.strand}
            selectedFilters={this.state.selectedFilters}
            typeOfResource={this.getTypeOfResource()}
            joyrideShouldRun={this.props.joyrideShouldRun}
            location={this.props.location}
            setStrand={this.libraryStrandSelected}
            queryObj={this.state.queryObj}
            showLoader={this.state.showLoader}
            fetchBooks={this.props.fetchBooks}
            closeAllFilters={this.props.closeAllFilters}
            updateCloseAllFilters={this.props.updateCloseAllFilters}
          />
        )}
      </div>
    ) : (
      <div />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filterLoadStatus: state.NewCustomFilters
      ? state.NewCustomFilters.asyncStatus
      : null,
    selectedFilters: state.FilterScale,
    showFilterLoader: state.FilterScale.loading,
    filterScale: state.FilterScale,
    newCustomFilters: state.NewCustomFilters.data
      ? state.NewCustomFilters.data
      : {},
    filterOptions: state.CustomFilters?.data?.filters ?? {},
    strand: state.CustomFilters.strand,
    joyrideShouldRun: state.JoyrideReducer.joyrideShouldRun,
    joyrideStepIndex: state.JoyrideReducer.joyrideStepIndex,
    joyrideIntendsToStart: state.JoyrideReducer.joyrideIntendsToStart,
    path: state.Location,
  };
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(NewFilterMenuContainer));
