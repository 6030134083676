import React, { Component } from "react";
import { connect } from "react-redux";
import authAPI from "../../../api/authAPI";
import DatePicker from "react-datepicker";
import Datetime from 'react-datetime';
import moment from "moment";
import {
  Row,
  Col,
  Button
} from "react-bootstrap";
import styled from "styled-components";

const dateNow = new Date()
export class DistrictFormLicensesInfo extends Component {
  state = {
    selectedLicensesType: "7",
    licenses: [],
    licenses_count: 1,
    date_start: new Date().toISOString(),
    date_end: new Date(dateNow.setFullYear( dateNow.getFullYear() + 1 )).toISOString(),
    disabledButton: false,
    isLicenseAdded: false,
    isEditLicense: false,
    licenseIndex: '',
    expirationDate: '',
    licenseCount: ''
  };
  componentDidMount = () => {
    this.getLicensesList();
  };

  license_edit = (i) => {
    this.setState({isEditLicense : true, licenseIndex : i.idx});
  };

  handleDate = (date) => {
    this.setState({expirationDate: date._d});
  };

  handleLicenseCount = (count) => {
    let countData = count.target.value;
    this.setState({licenseCount: countData});
  }

  getLicensesList = async () => {
    const { districtId } = this.props;
    const response = await authAPI.getDistrictLicenses({ _id: districtId });
    if (response && response.data) this.setState({ licenses: response.data });
  }

  editLicenseData = (l) => {
    let licenseData = l.license;
    const { districtId } = this.props;
    let assignedLicense = parseInt(licenseData.quantity)-parseInt(licenseData.available);
    if(this.state.licenseCount){
      licenseData.quantity = parseInt(assignedLicense) + parseInt(this.state.licenseCount);
      licenseData.available = parseInt(licenseData.quantity) - parseInt(assignedLicense);
    }
    if(this.state.expirationDate){
      let newExpDate = moment(this.state.expirationDate).format("L");
      licenseData.expire_date = newExpDate;
    } 
    authAPI.putNewCountLicense(districtId,licenseData)
        .then((res)=>{
            console.log(res);
            if(res.statusCode === 200){
              console.log("edited succesfully");
              this.setState({ isEditLicense : false }); 
            }else{
              console.log("error occured")
            }
        })
        .catch(function (error) {
            console.log("exception occured");
        })
  }

  handleChangeControl = name => event =>
    this.setState({ [name]: event.target.value });

  addLicenses = async () => {
    const {
      selectedLicensesType,
      licenses_count,
      date_start,
      date_end
    } = this.state;
    const { districtId } = this.props;

    const data = {
      _id: districtId,
      licenses_type: selectedLicensesType,
      licenses_count: licenses_count
    };
    console.log(data);
    if (selectedLicensesType == 4 || selectedLicensesType == 9) data.date = { start: date_start, end: date_end };
    this.setState({ disabledButton : true})
    const response = await authAPI.addDistrictLicenses(data);
    this.setState({ disabledButton : false})
    this.setState({isLicenseAdded : true})
    if (response) {
      this.getLicensesList()
      setTimeout(()=>{
        this.setState({isLicenseAdded : false})
      }, 1000)
    }
    
  };

  handleChangeDate = name => (value, formattedValue) =>{
    this.setState({ [name]: value });
  }
  render() {
    const { licenses, selectedLicensesType, licenses_count } = this.state;
    const licenseAddedAlert = (
      <span style={{"padding": "5px 0 0 10px" , "color": "#46C56D"}}> License Added!</span>
    )
    return (
      <div>
        <Row className="show-grid">
          <ModalLabel className={"text-center"}>
            ADD MORE LICENSES TO
          </ModalLabel>
          <Col xs={6} md={6} lg={6}>
            <LeftBlock >
              <select
                style={{ maxWidth: 140 }}
                className={`add-user-form`}
                type="number"
                value={selectedLicensesType}
                // defaultValue={selectedLicensesType}
                onChange={this.handleChangeControl("selectedLicensesType")}
              >
                <option value="7">Print Only</option>
                <option value="4">Unlimited</option>
                <option value="9">Text Only</option>
              </select>
              <input
                type={`number`}
                className={"add-user-form ml-16"}
                style={{ width: 60 }}
                value={licenses_count}
                onChange={this.handleChangeControl("licenses_count")}
              />
            </LeftBlock>
            {selectedLicensesType == 4 ? (
              <LeftBlock className={`mt-10`}>
              <span className={"text-right mr-15 mt-10"}>
                From
              </span>
                <DatePicker
                  minDate={new Date().toISOString()}
                  value={this.state.date_start}
                  isClearable={false}
                  onChange={this.handleChangeDate('date_start')}
                />
              </LeftBlock>
            ) : null}
          </Col>

          <Col xs={6} md={6} lg={6}>
            <RightBlock>
              <Button
                onClick={this.addLicenses}
                className={`dialog-save-button`}
                disabled={this.state.disabledButton}
              >
                {this.state.disabledButton ? 'Loading...' : 'Add'}
              </Button>
              { this.state.isLicenseAdded  && licenseAddedAlert }
              
            </RightBlock>
            {selectedLicensesType == 4 ? (
              <RightBlock className={`mt-13`}>
              <span className={"text-right mr-15 mt-10"}>
                To
              </span>
                <DatePicker
                  minDate={new Date().toISOString()}
                  value={this.state.date_end}
                  isClearable={false}
                  onChange={this.handleChangeDate('date_end')}
                />
              </RightBlock>
            ) : null}
          </Col>
        </Row>
        <hr />

        <Row className="show-grid" style={{ height: "250px",  overflow: "auto" }}>
          <Col xs={6} md={6} lg={6}>
            <LeftBlock>
              <ModalLabel className={"text-center"}>
                Number of unlimited licenses
              </ModalLabel>
            </LeftBlock>
          </Col>
          <Col xs={6} md={6} lg={6}>
            <RightBlock>
              <ModalLabel className={"text-center"}>Expiration Date</ModalLabel>
            </RightBlock>
          </Col>
          {licenses
            .filter(license => license.license_type == "U")
            .map( (license, idx) => (
              <div key={idx} className='row license-edit'>
                <Col xs={6} md={6} lg={6}>
                  <LeftBlock>
                    <div className='blocks'>
                      <h4>Assigned</h4>
                      <span>{(license.quantity)-(license.available)}</span>
                    </div>
                    {this.state.isEditLicense && this.state.licenseIndex === idx ?
                      <div className='blocks'>
                        <h4>Available</h4>
                        <input
                          type={`number`}
                          className={"add-user-form"}
                          style={{ width: 60 }}
                          defaultValue={license.available}
                          onChange={this.handleLicenseCount}
                        />
                      </div>
                      :
                      <div className='blocks'>
                        <h4>Available</h4>
                        <span>{license.available}</span>
                      </div>
                    }
                  </LeftBlock>
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <RightBlock>
                    {this.state.isEditLicense && this.state.licenseIndex === idx ?
                    <div style={{"margin-top": "10pt"}}>
                      <Datetime className='mobile-datetime user-modal-form' 
                        input={true} 
                        closeOnSelect={true} 
                        initialViewDate={new Date(license.expire_date)}
                        initialValue={new Date(license.expire_date)}
                        dateFormat={true}
                        timeFormat={false}
                        inputProps={{required:true, readOnly: true, name: 'expirationDate'}}
                        onChange={this.handleDate}
                      /> 
                      <span className="license-edit-btn"><button className="dialog-save-button" onClick={() => this.editLicenseData({license})}>OK</button></span>
                    </div>
                    :
                    <div style={{"margin-top": "10pt"}}>
                      <span>{license.expire_date} </span>
                      <span className="license-edit-btn"><button className="dialog-save-button" onClick={() => this.license_edit({idx})}>EDIT</button></span>
                    </div>
                    }
                  </RightBlock>
                </Col>
              </div>
            ))}
        </Row>
        
        <Row className="show-grid" style={{ height: "250px",  overflow: "auto" }}>
          <Col xs={6} md={6} lg={6}>
            <LeftBlock>
              <ModalLabel className={"text-center"}>
                Number of text only licenses
              </ModalLabel>
            </LeftBlock>
          </Col>
          <Col xs={6} md={6} lg={6}>
            <RightBlock>
              <ModalLabel className={"text-center"}>Expiration Date</ModalLabel>
            </RightBlock>
          </Col>
          {licenses
            .filter(license => license.license_type == "T")
            .map( (license, idx) => (
              <div key={idx} className='row license-edit'>
                <Col xs={6} md={6} lg={6}>
                  <LeftBlock>
                    <div className='blocks'>
                      <h4>Assigned</h4>
                      <span>{(license.quantity)-(license.available)}</span>
                    </div>
                    {this.state.isEditLicense && this.state.licenseIndex === idx ?
                      <div className='blocks'>
                        <h4>Available</h4>
                        <input
                          type={`number`}
                          className={"add-user-form"}
                          style={{ width: 60 }}
                          defaultValue={license.available}
                          onChange={this.handleLicenseCount}
                        />
                      </div>
                      :
                      <div className='blocks'>
                        <h4>Available</h4>
                        <span>{license.available}</span>
                      </div>
                    }
                  </LeftBlock>
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <RightBlock>
                    {this.state.isEditLicense && this.state.licenseIndex === idx ?
                    <div style={{"margin-top": "10pt"}}>
                      <Datetime className='mobile-datetime user-modal-form' 
                        input={true} 
                        closeOnSelect={true} 
                        initialViewDate={new Date(license.expire_date)}
                        initialValue={new Date(license.expire_date)}
                        dateFormat={true}
                        timeFormat={false}
                        inputProps={{required:true, readOnly: true, name: 'expirationDate'}}
                        onChange={this.handleDate}
                      /> 
                      <span className="license-edit-btn"><button className="dialog-save-button" onClick={() => this.editLicenseData({license})}>OK</button></span>
                    </div>
                    :
                    <div style={{"margin-top": "10pt"}}>
                      <span>{license.expire_date} </span>
                      <span className="license-edit-btn"><button className="dialog-save-button" onClick={() => this.license_edit({idx})}>EDIT</button></span>
                    </div>
                    }
                  </RightBlock>
                </Col>
              </div>
            ))}
        </Row>

        <Row className="show-grid">
          <Col xs={6} md={6} lg={6}>
            <LeftBlock>
              <ModalLabel className={"text-center"}>
                Print Only licenses
              </ModalLabel>
            </LeftBlock>
          </Col>
          <Col xs={6} md={6} lg={6}>
            <RightBlock>
              <ModalLabel className={"text-center"}>Expiration Date</ModalLabel>
            </RightBlock>
          </Col>
          {licenses
            .filter(license => license.license_type == "P")
            .map(license => (
              <div>
                <Col xs={6} md={6} lg={6}>
                  <LeftBlock>{license.quantity_sum}</LeftBlock>
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <RightBlock>Perpetual</RightBlock>
                </Col>
              </div>
            ))}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistrictFormLicensesInfo);

const LeftBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const RightBlock = styled(LeftBlock)`
  justify-content: flex-start;
`;

const ModalLabel = styled.h5`
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  padding-top: 20px;
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
`;
