import React,{Component} from  'react';

class AnnotationNoteComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            text: "",
            mode: "Add"
        };
        this.textarea = null;
        this.itemId = null;
    }

    setModeAdd() {
        this.setState({mode:"Add"});
    }

    setModeEdit() {
        this.setState({mode:"Edit"});
    }

    getMode() {
        return this.state.mode;
    }

    open() {
        this.setState({isVisible:true});
    }

    hide() {
        this.setState({isVisible:false});
    }

    setItemId(id) {
        this.itemId = id;
    }

    getItemId() {
        return this.itemId;
    }

    getText() {
        return this.state.text;
    }

    setText(text) {
        this.setState({text:text});
    }

    clearText() {
        this.setState({text:""});
    }

    getDeleteClasses() {
        let deleteClasses = "annotation-note-delete";
        if (this.state.mode === "Add") {
            deleteClasses += " annotation-note-delete-hidden";
        }
        return deleteClasses;
    }

    getHeader() {
        if (this.state.mode === "Add") {
            return "ADD NOTE";
        }
        else if (this.state.mode === "Edit") {
            return "EDIT NOTE";
        }
        else {
            return "";
        }
    }

    save = () => {
        if (this.state.mode === "Add") {
            this.props.onAdd(this, this.props.data);
        }
        else if (this.state.mode === "Edit") {
            this.props.onEdit(this, this.props.data, this.itemId);
        }
    };

    cancel = () => {
        this.props.onCancel(this, this.props.data);
    };

    delete = () => {
        this.props.onDelete(this, this.props.data, this.itemId);
    };

    textChanged = (e) => {
        this.setState({text:e.target.value});
    };

    render() {

        let noteClasses = "annotation-note";
        if(this.state.isVisible === false) {
            noteClasses += " annotation-note-hidden"
        }

        return(
            <div className={noteClasses}>
                <div className="annotation-note-title">{this.getHeader()}</div>
                <textarea className="annotation-note-text" placeholder="Click here to add a note." ref={(el) => { this.textarea = el }} value={this.state.text} onChange={this.textChanged}/>
                <div className="annotation-note-divider"/>
                <div className="annotation-note-cancel" onClick={this.cancel}>CANCEL</div>
                <div className="annotation-note-save" onClick={this.save}>SAVE</div>
                <div className={this.getDeleteClasses()} onClick={this.delete}>DELETE</div>
            </div>
        )
    }
}

export default AnnotationNoteComponent