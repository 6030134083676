import React from 'react'
import {Col,Row} from 'react-bootstrap'
import {browserHistory} from 'react-router'

export default class Assignment extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            isTooltipActive: false,
            showDeleteAssignmentIcon:(localStorage.user_type==='Student' ? false:true)
        };
        this.revert = this.revert.bind(this);
        this.moveAssignmentToDone = this.moveAssignmentToDone.bind(this);
        this.onCheckAssignmentClicked = this.onCheckAssignmentClicked.bind(this);
    }

    moveAssignmentToDone() {
        this.props.completeAssignment(this.props.assignment._id,true)
            .then((response)=>{
            if(response.statusCode === 200){
                this.setState({
                    isTooltipActive: false
                },() => {
                    this.props.refresh(this.props.assignment.day)
                })
            }else{
                console.log("failure");
            }
            });

    };

    revert() {
        this.props.completeAssignment(this.props.assignment._id,false)
            .then((response)=>{
                if(response.statusCode === 200){
                    console.log("revert success");
                    this.props.refresh(this.props.assignment.day);
                }else{
                    console.log("revert failure");
                }
            });
    };

    // user clicks "done" or "undo"
    onCheckAssignmentClicked() {

        // Only allow task action if the user is a student
        if(localStorage.user_type==='Student') {
            if(this.props.selectedTab === "TO-DO" || this.props.selectedTab ==="PAST-DUE-ASSIGNMENT") {
                // If on todo tab, activate tooltip
                this.setState({isTooltipActive: true});
            } else {
                // Otherwise revert task
                this.revert();
            }
        }
    }

    deleteItem(assignment_id){
        let student_id = (window.location.href).split("student/")[1];
        this.props.deleteAssignment(assignment_id,student_id);
        document.getElementById("assignment_"+assignment_id).remove();
    }

    render() {
        let collapse = this.props.collapseDateList.indexOf(new Date(this.props.day).toDateString());
        if(collapse !== -1){
            return null;
        }

        // class for the checks in the todo
        let assignmentCheckClasses = ['check-assignment'];
        if(this.props.selectedTab === "TO-DO" || this.props.selectedTab ==="PAST-DUE-ASSIGNMENT") {
            assignmentCheckClasses.push("icon-icon-check");
        } else {
            assignmentCheckClasses.push("icon-icon-revert");
        }

        // Add teacher view class to the check class if the current user is not a student
        if(localStorage.user_type !== 'Student') {
            assignmentCheckClasses.push('teacher-view');
        }

        return (
            <Row id={"assignment_"+this.props.assignment._id}>
                <Col style={{paddingLeft: 0,paddingRight: 0,cursor:"pointer"}} xs={this.state.showDeleteAssignmentIcon ? 6 : 9} sm={this.state.showDeleteAssignmentIcon ? 6 : 9} md={this.state.showDeleteAssignmentIcon ? 6 : 9} lg={this.state.showDeleteAssignmentIcon ? 7 : 10}
                     className="assignment-item"
                     onClick={()=>browserHistory.push('/' + this.props.assignment.item_type.toLowerCase() + 's/' + this.props.assignment.item_id)}
                >
                    <Row>
                        {/*Icon*/}
                        <Col xs={2} sm={2} md={2} lg={1}>
                            {this.props.assignment.item_type === "Book" &&
                                <div className="book-assignment icon-icon-books-solid"/>
                            }
                            {this.props.assignment.item_type === "Assessment" &&
                                <div className="assessment-assignment icon-icon-piechart"/>
                            }
                            {this.props.assignment.item_type === "Resource" &&
                                <div className="resource-assignment icon-icon-rulerpencil"/>
                            }
                            {this.props.assignment.item_type === "Lesson" &&
                                <div className="resource-assignment icon-icon-rulerpencil"/>
                            }
                            {this.props.assignment.item_type === "Book" &&
                                <div className="item-type-book">Book</div>
                            }
                            {this.props.assignment.item_type === "Assessment" &&
                                <div className="item-type-assessment">Assessment</div>
                            }
                            {this.props.assignment.item_type === "Resource" &&
                                <div className="item-type-resource">Resource</div>
                            }
                            {this.props.assignment.item_type === "Lesson" &&
                                <div className="item-type-resource">Lesson</div>
                            }
                        </Col>
                        {/*Body*/}
                        <Col xs={8} sm={9} md={8} lg={9}>
                            <Row className="assignment-title">
                                <Col xs={12}>
                                    {this.props.assignment.name}
                                </Col>
                            </Row>
                            <Row className="assignment-subtext">
                                <Col xs={12}>
                                    {this.props.assignment.teacher}
                                </Col>
                            </Row>
                        </Col>
                        {/*Chevron*/}
                        <Col md={2} xs={2} sm={1}>
                            <div className="assignment-chevron icon-icon-chevron-right"/>
                        </Col>
                    </Row>
                </Col>
                <Col xs={3} sm={3} md={3} lg={2} id="remove-padding">
                    <Row>
                        <Col sm={8} smOffset={2}
                             md={8} mdOffset={2}
                             lg={8} lgOffset={2}
                             className="done"
                        >
                            <div className={assignmentCheckClasses.join(' ')}
                                 onClick={this.onCheckAssignmentClicked}
                                 id={"confirm"+this.props.assignment._id}
                            />
                            { this.state.isTooltipActive &&
                                <div className="confirmation-dialogue">
                                    <div style={{float:"left"}} className="confirmation-dialogue-icon icon-icon-check"/>
                                    <span style={{color: "#46C56D",fontSize: "17px",lineHeight: "20px"}}>
                                        Mark as Done <br/>
                                        <span style={{paddingBottom: "10px",color: "#666666",fontSize: "11px",lineHeight: "13px"}}>
                                            Are you sure you want to submit this assignment?
                                        </span>
                                    </span>
                                    <div style={{paddingTop: 15}}>
                                        <button onClick={this.moveAssignmentToDone}
                                                style={{border: "none", backgroundColor: "#46C56D",color: "#FFFFFF"}}
                                                className="confirm-cancel-button">
                                            CONFIRM
                                        </button>
                                        <button onClick={()=>this.setState({isTooltipActive: false})}
                                            style={{float:"right",border: "1px solid #9B9B9B", backgroundColor: "#FFFFFF",color: "#9B9B9B"}}
                                            className="confirm-cancel-button">
                                            CANCEL
                                        </button>
                                    </div>
                                </div>
                            }
                    </Col>
                    </Row>
                </Col>
                {this.state.showDeleteAssignmentIcon && 
                        <Col xs={3} sm={3} md={3} lg={3} onClick={()=>this.deleteItem(this.props.assignment._id)}>
                        <Row><Col sm={4} smOffset={2}
                             md={4} mdOffset={2}
                             lg={4} lgOffset={2} className="done"><div className="icon-icon-trash" style={{"textAlign": "center","marginTop": "21px",cursor: "pointer",color: "#928e8e"}}></div></Col></Row>
                        </Col>
                }
            </Row>
        );
    }
}
