/**
 * Created by ThomasL on 10/4/17.
 */
import {
    EREADER_MODE_EVENT,
    EREADER_DELETE_EVENT,
    EREADER_GET_BOOKMARKS_EVENT,
    EREADER_ADD_BOOKMARK_EVENT,
    EREADER_DELETE_BOOKMARK_EVENT,
    EREADER_SET_CURRENT_PAGE_EVENT,
    EREADER_SET_TOTAL_PAGES_EVENT,
    EREADER_SET_PAGE_MODE_EVENT,
    EREADER_SET_BOOK_LOADED
} from '../actions';


const initialState = {};

const EReaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case EREADER_MODE_EVENT:
            return Object.assign({},state, {
                mode: action.mode,
                actionFlipper: !state.actionFlipper
            });
        case EREADER_DELETE_EVENT:
            return Object.assign({},state, {
                deleteEventTime: new Date()
            });
        case EREADER_GET_BOOKMARKS_EVENT:
            let fetchedBookmarks = action.payload && action.payload.data;
            let pageMap = {};
            if(fetchedBookmarks)
            {
                for(let i = 0; i < fetchedBookmarks.length; i++)
                {
                    pageMap[fetchedBookmarks[i].page] = {
                        bookmark: fetchedBookmarks[i],
                        label: String(fetchedBookmarks[i].page)
                    }
                }
            }
            return Object.assign({}, state, {
                bookmarks: fetchedBookmarks,
                bookmarkPageMap: pageMap
            });
        case EREADER_ADD_BOOKMARK_EVENT:
            let currentBookmarksOnAdd;
            let currentBookmarkPageMapOnAdd;
            let addBookmarkResponse = action.payload && action.payload.data && action.payload.data;
            if(action.pageNumber && addBookmarkResponse._id)
            {
                currentBookmarksOnAdd = [...state.bookmarks];
                currentBookmarkPageMapOnAdd = {...state.bookmarkPageMap};
                let bookmark = {
                    page: action.pageNumber,
                    _id: addBookmarkResponse._id,
                };
                currentBookmarkPageMapOnAdd[action.pageNumber] = {
                    bookmark: bookmark,
                    label: String(bookmark.page)
                };
                currentBookmarksOnAdd.push(bookmark);
            }
            return Object.assign({}, state, {
                addBookmarkResponse: addBookmarkResponse,
                bookmarks: currentBookmarksOnAdd,
                bookmarkPageMap: currentBookmarkPageMapOnAdd
            });
        case EREADER_DELETE_BOOKMARK_EVENT:
            let currentBookmarksOnDelete;
            let currentBookmarkPageMapOnDelete;
            if(action.bookmarkId)
            {
                currentBookmarksOnDelete = [...state.bookmarks];
                currentBookmarkPageMapOnDelete = {...state.bookmarkPageMap};
                let pageNumber;

                for(let i = 0; i < currentBookmarksOnDelete.length; i++)
                {
                    if(currentBookmarksOnDelete[i]._id === action.bookmarkId)
                    {
                        pageNumber = currentBookmarksOnDelete[i].page;
                        currentBookmarksOnDelete.splice(i,1);
                        break;
                    }
                }
                if(pageNumber)
                {
                    delete currentBookmarkPageMapOnDelete[pageNumber];
                }
            }
            return Object.assign({}, state, {
                deleteBookmarkResponse: action.payload && action.payload.data,
                bookmarks: currentBookmarksOnDelete,
                bookmarkPageMap: currentBookmarkPageMapOnDelete
            });
        case EREADER_SET_CURRENT_PAGE_EVENT:
            return Object.assign({},state, {
                currentPage: action.currentPage
            });
        case EREADER_SET_TOTAL_PAGES_EVENT:
            return Object.assign({},state, {
                totalPages: action.totalPages
            });
        case EREADER_SET_PAGE_MODE_EVENT:
            return Object.assign({},state, {
                pageMode: action.pageMode
            });
        case EREADER_SET_BOOK_LOADED:
            return Object.assign({},state, {
                bookLoaded: action.bookLoaded
            });
        default:
            return state;
    }
};

export default EReaderReducer;