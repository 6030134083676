import React, {Component} from  'react'
import _ from 'lodash'

/**
 *
 * Applies different classes to an icon element depending on which state the element is in.
 * Same as MultiState but uses only props
 * props:
 *
 * enabled {boolean} - whether the element is enabled, if not enabled it cannot be activated
 * active {boolean}
 *
 * iconId - id to give the child icon
 * activeClasses {string}
 * inactiveClasses {string}
 * disabledClasses {string}
 *
 * onClick()
 * onActivated()
 * onDeactivated()
 * onMouseOver()
 * onMouseLeave()
 *
 * Uses the properties enabled (true or false) and active (true or false).
 * Active is always false when enabled is false
 *
 */
class PropsMultiStateIcon extends Component {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    /**
     * On click, if enabled then switch active state, call onClick() prop, followed by either onActivated()
     * or onDeactivated() prop handler.
     */
    onClick() {
        if(this.props.enabled === true) {

            const newActiveState = !this.props.active;

            // Call on click if defined
            if(this.props.onClick) {
                this.props.onClick();
            }

            if(this.props.joyrideShouldRun) {
                this.props.facilitateHeaderJoyride();
            }

            // Call handler prop if provided
            if((newActiveState === true) && (this.props.onActivated)) {
                this.props.onActivated();
            } else if((newActiveState === false) && (this.props.onDeactivated)) {
                this.props.onDeactivated();
            }
        }
    }

    getClass() {
        let classes = this.props.sharedClasses ? this.props.sharedClasses + " " : "";
        if(this.props.enabled) {
            classes += (this.props.active ? this.props.activeClasses : this.props.inactiveClasses);
        } else {
            classes += this.props.disabledClasses;
        }
        return classes;
    }

    render() {
        return (
            <i id={this.props.iconId}
               onClick={this.onClick}
               onMouseOver={this.props.onMouseOver}
               onMouseLeave={this.props.onMouseLeave}
               className={this.getClass()} 
               title={this.props.tooltipText}
            />
        )
    }
}

export default PropsMultiStateIcon
