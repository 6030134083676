import React,{Component} from  'react'
import {Button,ButtonGroup } from 'react-bootstrap'
import {withRouter,browserHistory} from 'react-router'

//Not used for npw
/*const ButtonItems =[
    {
        name : "OVERVIEW",
        className:'rectangle-2'
    },
    {
        name : "TEXT",
        className:'rectangle-2'
    },
    {
        name : "VOCABULARY",
        className:'rectangle-2'
    },
    {
        name : "ASSESSMENTS",
        className:'rectangle-2'
    }
];*/

class ButtonGrp extends Component{

    render(){
        return(
            <div>
                <ButtonGroup id="nav-buttons" className="align-button-grp hidden-xs">
                    <Button className="rectangle-2"
                            style={{backgroundColor:this.props.selectedTab==="OVERVIEW"? "#009BBD":'white'}}
                            onClick={() => {this.props.selectTab("OVERVIEW"); browserHistory.replace('/units/'+this.props.id)}} >
                            <span className="language" style={{ color : this.props.selectedTab === "OVERVIEW" ? '#FFFFFF': '#9B9B9B'}}>
                                OVERVIEW
                            </span>
                    </Button>


                    <Button className="rectangle-2"
                            style={{backgroundColor:this.props.selectedTab==="LESSONS"? "#009BBD":'white'}}
                            onClick={() => {this.props.selectTab("LESSONS");browserHistory.replace('/units/'+this.props.id +'/lessons')}} >
                            <span className="language" style={{ color : this.props.selectedTab === "LESSONS" ? '#FFFFFF': '#9B9B9B'}}>
                                LESSONS
                            </span>
                    </Button>

                    <Button className="rectangle-2"
                            style={{backgroundColor:this.props.selectedTab==="TEXT"? "#009BBD":'white'}}
                            onClick={() => {this.props.selectTab("TEXT");browserHistory.replace('/units/'+this.props.id +'/texts')}} >
                            <span className="language" style={{ color : this.props.selectedTab === "TEXT" ? '#FFFFFF': '#9B9B9B'}}>
                                TEXTS
                            </span>
                    </Button>

                    <Button className="rectangle-2"
                            style={{backgroundColor:this.props.selectedTab==="VOCABULARY"? "#009BBD":'white'}}
                            onClick={() => {this.props.selectTab("VOCABULARY");browserHistory.replace('/units/'+this.props.id +'/vocabulary')}} >
                            <span className="language" style={{ color : this.props.selectedTab === "VOCABULARY" ? '#FFFFFF': '#9B9B9B'}}>
                                VOCABULARY
                            </span>
                    </Button>
                    {/*{ this.props.eLearning && this.props.showAppendicesTab &&*/}
                    { this.props.showAppendicesTab &&
                        <Button className="rectangle-2"
                            style={{backgroundColor:this.props.selectedTab==="APPENDICES"? "#009BBD":'white'}}
                            onClick={() => {this.props.selectTab("APPENDICES");browserHistory.replace('/units/'+this.props.id +'/appendices')}} >
                            <span className="language" style={{ color : this.props.selectedTab === "APPENDICES" ? '#FFFFFF': '#9B9B9B'}}>
                                APPENDICES
                            </span>
                        </Button>
                    }
                    { this.props.showAssessmentsTab &&
                    <Button className="rectangle-2"
                            style={{backgroundColor:this.props.selectedTab==="ASSESSMENTS"? "#009BBD":'white'}}
                            onClick={() => {this.props.selectTab("ASSESSMENTS");browserHistory.replace('/units/'+this.props.id +'/assessments')}} >
                            <span className="language" style={{ color : this.props.selectedTab === "ASSESSMENTS" ? '#FFFFFF': '#9B9B9B'}}>
                                ASSESSMENTS
                            </span>
                    </Button>
                    }
                    
                    <Button className="rectangle-2"
                            style={{backgroundColor:this.props.selectedTab==="CORRELATIONS"? "#009BBD":'white'}}
                            onClick={() => {this.props.selectTab("CORRELATIONS");browserHistory.replace('/units/' + this.props.id +'/correlations')}} >
                            <span className="language" style={{ color : this.props.selectedTab === "CORRELATIONS" ? '#FFFFFF': '#9B9B9B'}}>
                                CORRELATIONS
                            </span>
                    </Button>

                </ButtonGroup>

            </div>
        );
    }
}

export default withRouter(ButtonGrp);