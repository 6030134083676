import React, { Component } from 'react'
import { Col, Row, Alert, Button } from 'react-bootstrap'
import UserDetails from './UserDetails'
import ButtonGroup from './UserButtonGroup'
import SetPicturePassword from './SetPicturePassword'
import '../../assets/styles/userProfile.scss'
import '../../assets/styles/login.scss'
import authAPI from '../../api/authAPI'
import { browserHistory } from 'react-router'
import * as actions from '../../redux/actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { gapi } from 'gapi-script'

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: "INFO",
            editUserData: null,
            errorMessage: "",
            showPasswordTour: false,
            showTokenExpired: false,
            isTokenChecked: false
        };

        this.selectTab = this.selectTab.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.removeError = this.removeError.bind(this);
        this.editUserForm = null;
    }

    selectTab(tab) {
        this.setState({ selectedTab: tab });
    }

    componentWillMount() {

        if (this.props.location.pathname === '/reset-password' && !this.props.location.query.token)
            browserHistory.push('/');

        if (this.props.location.pathname === '/user-profile' || this.props.location.pathname === '/password')
            this.props.getMyDetailsAction();

        if (this.props.location.pathname === '/reset-password') {
            this.props.reviewToken(this.props.location.query.token)
                .then(response => {
                    if (response.data) {
                        this.setState({ showTokenExpired: false });
                    } else {
                        this.setState({ showTokenExpired: true });
                    }
                    this.setState({ isTokenChecked: true });
                })
                .catch(function (error) {
                    console.log("exception occurred");
                });
        } else {
            this.setState({ isTokenChecked: true });
        }
    }

    componentDidMount() {
        let isSetOrResetPasswordPage = this.props.location.pathname === "/password" || this.props.location.pathname === "/reset-password",
            isFirstLoginInQuery = this.props.location.query && this.props.location.query.first_time_login === "true",
            isFirstLogin = localStorage.first_time_login === "true" || isFirstLoginInQuery,
            pathname = this.props.location && this.props.location.pathname,
            usernameFromState = this.props.location && this.props.location.state && this.props.location.state.username;

        this.setState({
            showPasswordTour: isSetOrResetPasswordPage && isFirstLogin,
            showSetPicturePassword: pathname === '/set-password' && usernameFromState,
            showUserDetails: pathname === '/user-profile',
            showUserProfileComponents: pathname === '/user-profile',
            showResetPassword: pathname === '/reset-password'
        });
    }

    removeError() {
        this.setState({
            errorMessage: ''
        })
    }

    saveChanges = () => {
        if (this.editUserForm.validateInput()) {
            let data;
            if (this.props.location.pathname === '/reset-password') {
                data = this.editUserForm.getDataPasswordReset();
            } else {
                data = this.editUserForm.getData();
            }

            let newObj = {};

            if (this.props.location.pathname === '/password') {
                newObj = {
                    "password": data.newPassword
                }
            } else if (this.props.location.pathname === '/reset-password') {
                newObj = {
                    "password": data.newPassword
                };
            } else if (data.newPassword !== '') {
                newObj = {
                    // "username": data.email,
                    "first_name": data.firstName,
                    "last_name": data.lastName,
                    "password": data.newPassword,
                    "old_password": data.oldPassword,
                    "grades": data.grades
                };
            } else {
                newObj = {
                    // "username": data.email,
                    "first_name": data.firstName,
                    "last_name": data.lastName,
                    "grades": data.grades
                };
            }
            //console.log("check",this.props.location.pathname)

            if (this.props.location.pathname === '/reset-password') {
                this.props.resetPasswordAction(newObj.password, this.props.location.query.token).then((response) => {
                    if (response.message === "Password Reset") {
                        browserHistory.push('/');
                    } else {
                        this.setState({
                            errorMessage: response.response && response.response.data && response.response.data.message,
                            selectedTab: "PASSWORD"
                        });
                    }
                })
                    .catch(function (error) {
                        console.log("exception occurred");
                    });
            } else {
                this.props.putUserAction(data._id, newObj).then((response) => {
                    if (response.message === "Update Successful") {
                        if (this.props.location.pathname === '/password') {
                            localStorage.user_type === "Student" ? browserHistory.push('/home') : browserHistory.push('/browse');
                        } else {
                            browserHistory.goBack();
                        }
                    } else {
                        if (response === "Incorrect Password") {
                            this.setState({
                                errorMessage: response, selectedTab: "PASSWORD"
                            });
                        }
                        else {
                            this.setState({
                                errorMessage: response.message, selectedTab: "PASSWORD"
                            });
                        }
                    }
                })
                    .catch(function (error) {
                        console.log("exception occurred");
                    });
            }
        }

        if (this.state.showUserDetailsWithSetPw) {
            this.editUserForm.refSetPicturePassword.getWrappedInstance().handleSaveClick();
        }
    };

    logout = () => {
        console.log("I am getting called logout");
        /* Ready. Make a call to gapi.auth2.init or some other API */
        var auth2 = gapi?.auth2?.getAuthInstance();
        localStorage.removeItem('prevPathLoc');
        if (auth2 && auth2.isSignedIn) {
            auth2.signOut().then(() => {
                this.setState({ user: null })
            });
            auth2.disconnect();
            let loginUrl = '/';

            if (localStorage.getItem('first_time_login') === "true" && localStorage.getItem('googlesignin') === "true") {
                authAPI.updateFirstTimeUserLogin(localStorage.getItem('googlesigninusername'));
            }

            if (localStorage.picture_password_user === "true") {
                loginUrl += 'picture-password/';
                if (localStorage.login_domain !== "false" && localStorage.login_domain !== undefined)
                    loginUrl += localStorage.login_domain;
            } else if (localStorage.login_domain !== "false" && localStorage.login_domain !== undefined) {
                loginUrl += 'login/' + localStorage.login_domain;
            }
            document.cookie = "sessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";


            localStorage.clear();

            browserHistory.push(loginUrl);
            setTimeout(function () { window.location.reload() }, 100);
        } else {
            this.props.logoutAction().then((response) => {
                if (response.statusCode === 200) {
                    let loginUrl = '/';

                    if (localStorage.picture_password_user === "true") {
                        loginUrl += 'picture-password/';
                        if (localStorage.login_domain !== "false" && localStorage.login_domain !== undefined)
                            loginUrl += localStorage.login_domain;
                    } else if (localStorage.login_domain !== "false" && localStorage.login_domain !== undefined) {
                        loginUrl += 'login/' + localStorage.login_domain;
                    }
                    document.cookie = "sessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

                    localStorage.clear();

                    browserHistory.push(loginUrl);
                    setTimeout(function () { window.location.reload() }, 100);
                } else {
                    console.log("logout failed");
                }
            }).catch(function (error) {
                console.log("logout exception occurred");
            });
        }
    };

    componentWillReceiveProps(props) {
        let userDataLoaded = props.UserData.asyncStatus === "SUCCESS" && props.UserData.data,
            pathname = this.props.location && this.props.location.pathname,
            usernameFromProps = props.UserData.data && props.UserData.data.username,
            passwordType = props.UserData.data && props.UserData.data.password_type_id;

        this.setState({
            showUserDetailsWithSetPw: pathname === '/user-profile' && usernameFromProps && passwordType === 2,
            showPassword: pathname === '/password' && usernameFromProps,
            userDetailsDataLoaded: userDataLoaded
        })
    }

    render() {
        return (
            <div>
                <div className='profile-header'
                    style={this.state.showPasswordTour ?
                        { boxShadow: 'inset 0 0 0 100px rgba(0, 0, 0, 0.5)', borderColor: 'rgba(0, 0, 0, 0.5)' } : {}}>
                    <Col mdOffset={4} md={4} smOffset={4} sm={4} xsOffset={this.state.showUserProfileComponents ? 0 : 3} xs={6}>
                        <div className='profile-header-name'>
                            {!this.state.showTokenExpired ?
                                (this.state.showUserProfileComponents ? "MY ACCOUNT" : "SET PASSWORD")
                                : "RESET PASSWORD LINK IS EXPIRED"
                            }
                        </div>
                    </Col>
                    <Col md={4} sm={4} smOffset={0} xsOffset={3} xs={3}>
                        <span style={{ float: "right", marginTop: 14 }}>
                            {this.state.showUserProfileComponents &&
                                <button className='logout-button' onClick={this.logout}>
                                    LOGOUT
                                </button>
                            }
                        </span>
                    </Col>
                </div>
                <div className="padding-under-fixed-header">
                    {this.state.showSetPicturePassword &&
                        <SetPicturePassword
                            router={this.props.router}
                            username={(this.props.location.state && this.props.location.state.username) || this.props.UserData.data.username}
                            domain={(this.props.location.state && this.props.location.state.domain)}
                            hideFooter={false}
                        />
                    }
                    {this.state.showUserProfileComponents &&
                        <ButtonGroup
                            selectTab={this.selectTab}
                            selectedTab={this.state.selectedTab}
                        />
                    }
                    {this.state.showUserDetails && this.state.userDetailsDataLoaded &&
                        <UserDetails
                            pathname={this.props.location.pathname}
                            removeError={this.removeError}
                            errorResponse={this.state.errorMessage}
                            selectedTab={this.state.selectedTab}
                            selectTab={this.selectTab}
                            userData={this.props.UserData.data}
                            ref={(el) => { this.editUserForm = el }}
                            showPasswordTour={this.state.showPasswordTour}
                            showPicturePassword={this.state.showUserDetailsWithSetPw}
                        />
                    }
                    {((this.state.showPassword || this.state.showResetPassword) && !this.state.showTokenExpired && this.state.isTokenChecked) &&
                        <UserDetails
                            pathname={this.props.location.pathname}
                            removeError={this.removeError}
                            errorResponse={this.state.errorMessage}
                            userData={this.props.UserData.data}
                            selectedTab="PASSWORD"
                            selectTab={this.selectTab}
                            ref={(el) => { this.editUserForm = el }}
                            showPasswordTour={this.state.showPasswordTour}
                        />
                    }
                    {this.state.showTokenExpired &&
                        <div className="form-wrapper-outer xs-scroll-content">
                            <div className="form-wrapper-inner content-center-width">
                                <div style={{ paddingTop: '20px' }}></div>
                                <Row className="row-padding-lr">
                                    <Alert bsStyle="danger" onDismiss={this.handleDismiss} style={{ textAlign: 'center' }}>
                                        <p>&nbsp;</p>
                                        <p>Sorry, but this link to reset your password has expired.</p>
                                        <p>No worries! You'll just need to request a password reset again.</p>
                                        <p>(Keep in mind the link is temporary, so update your password as soon as possible.)</p>
                                        <p>&nbsp;</p>
                                        <p>
                                            <Button bsStyle="danger" onClick={() => { browserHistory.push('/forgot-password'); }}>
                                                Re-generate your reset password link
                                            </Button>
                                        </p>
                                    </Alert>
                                </Row>
                            </div>
                        </div>
                    }
                    {!this.state.showSetPicturePassword &&
                        <div className='add-unit-footer'
                            style={this.state.showPasswordTour ?
                                { boxShadow: 'inset 0 0 0 100px rgba(0, 0, 0, 0.5)', borderColor: 'rgba(0, 0, 0, 0.5)' } : {}}>
                            {this.state.showUserDetails &&
                                <div className='button-regular-left'>
                                    <button className='cancel-lesson-unit' onClick={browserHistory.goBack}>
                                        CANCEL
                                    </button>
                                </div>
                            }
                            {this.state.isTokenChecked && !this.state.showTokenExpired &&
                                <div className='button-regular-right'>
                                    <button className='assign-lesson-unit'
                                        style={this.state.showPasswordTour ?
                                            { boxShadow: '0 0 0 10px white' } : {}}
                                        onClick={this.saveChanges}>
                                        SAVE
                                    </button>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        UserData: state.MyDetails
    });
};

export default connect(mapStateToProps, actions)(withRouter(UserProfile))
