import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import "../assets/styles/login.scss";
import * as actions from "../redux/actions";
import { connect } from "react-redux";
import { browserHistory, Link } from "react-router";
import "../assets/styles/mobile.scss";
import GoogleBtn from "./GoogleButton";
import constants from "../constants";
import createClass from "create-react-class";
import LogoImg from "../assets/icons/SVG/icon-schoolwide.svg";

class LoginComponent extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      myError: "",
      emailError: "",
      passwordError: "",
      first_time_login: true,
    };

    this.handleUserNameInput = this.handleUserNameInput.bind(this);
    this.handlePasswordInput = this.handlePasswordInput.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.openLearnMorePdf = this.openLearnMorePdf.bind(this);
  }

  componentDidMount = () => {
    console.log("I am mounted");
    // const { email } = this.props
    // email.length && this.setState({ username: email })
  };

  componentWillReceiveProps(next) {
    let prevPath = localStorage.getItem("prevPathLoc");
    console.log("received props");

    this.setState({
      first_time_login: next?.loginStatus?.userData?.first_time_login,
    });
    if (next.loginStatus.userData) {
      localStorage.clear();
      localStorage.setItem(
        "user_type",
        next.loginStatus.userData.user_type == undefined
          ? next.loginStatus.userData.data.user_type
          : next.loginStatus.userData.user_type
      );
      localStorage.setItem(
        "first_time_login",
        next.loginStatus.userData.first_time_login == undefined
          ? next.loginStatus.userData.data.first_time_login
          : next.loginStatus.userData.first_time_login
      );

      localStorage.setItem("trialPopupDismissed", "false");
      localStorage.setItem(
        "is_lite",
        next.loginStatus.userData.is_lite == undefined
          ? next.loginStatus.userData.data.is_lite
          : next.loginStatus.userData.is_lite
      );
      localStorage.setItem(
        "uuid",
        next.loginStatus.userData.uuid == undefined
          ? next.loginStatus.userData.data.uuid
          : next.loginStatus.userData.uuid
      );

      if (next.loginStatus.userData.district_id !== undefined) {
        localStorage.setItem(
          "district_id",
          next.loginStatus.userData.district_id == undefined
            ? next.loginStatus.userData.data.district_id
            : next.loginStatus.userData.district_id
        );
        localStorage.setItem(
          "district_name",
          next.loginStatus.userData.district_name == undefined
            ? next.loginStatus.userData.data.district_name
            : next.loginStatus.userData.district_name
        );
        localStorage.setItem(
          "name",
          next.loginStatus.userData.name == undefined
            ? next.loginStatus.userData.data.name
            : next.loginStatus.userData.name
        );
      }
      if (
        next.loginStatus.userData.user_type == undefined &&
        next.loginStatus.userData.data.googlesignin != undefined
      ) {
        localStorage.setItem(
          "googlesignin",
          next.loginStatus.userData.data.googlesignin
        );
        localStorage.setItem(
          "googlesigninusername",
          next.loginStatus.userData.data.userid
        );
        localStorage.setItem(
          "district_id",
          next.loginStatus.userData.data.district_id
        );
        localStorage.setItem(
          "district_name",
          next.loginStatus.userData.data.district_name
        );
        localStorage.setItem(
          "username",
          next.loginStatus.userData.data.username
        );
        localStorage.setItem("user_id", next.loginStatus.userData.data.userid);
        localStorage.setItem("name", next.loginStatus.userData.data.name);
        //authAPI.updateFirstTimeUserLogin(localStorage.getItem('googlesigninusername'));
      } else {
        localStorage.setItem("username", next.loginStatus.userData.username);
        localStorage.setItem("user_id", next.loginStatus.userData.userid);
        //authAPI.updateFirstTimeUserLogin(localStorage.getItem('user_id'));
      }

      localStorage.setItem("picture_password_user", "false");
      localStorage.setItem(
        "login_domain",
        this.props.domain ? this.props.domain : "false"
      );

      // if (prevPath && prevPath != "" && prevPath != null) {
      //   window.location.href = prevPath;
      // }
      if (
        next.loginStatus.userData.set_password === true &&
        next.loginStatus.userData.user_type !== "System Admin"
      ) {
        console.log("if 1");
        browserHistory.push(constants.PATHS.PASSWORD);
      } else {
        var currentpath = window.location.pathname;
        console.log("if 2");

        // if (
        //   document.location.hostname.indexOf("fundamentals.schoolwide.com") !=
        //   -1
        // ) {
        //   var today = new Date();
        //   var todayIso = today.toISOString();
        //   if (
        //     localStorage.getItem("user_id") != undefined &&
        //     localStorage.getItem("user_id") != null &&
        //     localStorage.getItem("user_id") != ""
        //   ) {
        //     window.civchat = {
        //       apiKey: "vFl1wt",
        //       name: localStorage.getItem("name"),
        //       user_id: localStorage.getItem("user_id"),
        //       email: localStorage.getItem("username"),
        //       gender: 1,
        //       status: 2,
        //       date_attr: todayIso, // Must be a valid ISO 8601 format
        //       phone_number: "", // Must be a valid E.164 format
        //       score: 0,
        //       company: {
        //         name: localStorage.getItem("district_name"),
        //         company_id: localStorage.getItem("district_id"),
        //         revenue: "",
        //       },
        //     };
        //   }
        // }

        // Adding redirect flow for lite users
        if (
          localStorage.is_lite === "true" ||
          next.loginStatus.userData.is_lite == "true"
        ) {
          console.log("DEFAULT_LITE_USER_PATH");

          browserHistory.push(constants.DEFAULT_LITE_USER_PATH);
        } else if (
          localStorage.user_type === "Student" ||
          next.loginStatus.userData.user_type == "Student"
        ) {
          console.log("DEFAULT_STUDENT_PATH");

          browserHistory.push(constants.DEFAULT_STUDENT_PATH);
        } else if (
          localStorage.user_type === "Print Only" ||
          next.loginStatus.userData.user_type == "Print Only"
        ) {
          console.log("DEFAULT_PRINT_ONLY_USER_PATH");

          browserHistory.push(constants.DEFAULT_PRINT_ONLY_USER_PATH);
        } else if (
          localStorage.user_type === "Unlimited" ||
          localStorage.user_type === "System Admin"
        ) {
          console.log("DEFAULT_UNLIMITED_USER_PATH");
          browserHistory.push(constants.DEFAULT_UNLIMITED_USER_PATH);
        } else if (localStorage.user_type === "Text Only") {
          console.log("DEFAULT_TEXT_ONLY_USER_PATH");
          browserHistory.push(constants.DEFAULT_TEXT_ONLY_USER_PATH);
        } else if (
          next.loginStatus.userData.user_type === "Unlimited" ||
          next.loginStatus.userData.user_type === "System Admin"
        ) {
          console.log("DEFAULT_UNLIMITED_USER_PATH 212");
          browserHistory.push(constants.DEFAULT_UNLIMITED_USER_PATH);
        } else {
          console.log("DEFAULT_NON_STUDENT_PATH");
          browserHistory.push(constants.DEFAULT_NON_STUDENT_PATH);
        }
      }
    } else {
      if (next.loginStatus.error.data.message === "Trial Login expired") {
        console.log("trial expired");
        this.handleError(
          "Please note your trial period has expired and you will not be able to login. Please contact support at <a href='mailto:help@schoolwide.com'>help@schoolwide.com</a> for further assistance."
        );
      } else if (next.loginStatus.error.data.message === "License expired") {
        console.log("license expired");
        let expiredAt = next.loginStatus.error.data.expired;
        expiredAt =
          expiredAt != null
            ? ` at ${new Date(expiredAt).getMonth() + 1}/${new Date(
                expiredAt
              ).getUTCDate()}/${new Date(expiredAt).getFullYear()}`
            : "";
        this.handleError(
          `Please note your license has expired ${expiredAt} and you will not be able to login. Please contact support at <a href='mailto:help@schoolwide.com'>help@schoolwide.com</a> for further assistance.`
        );
      } else if (next.loginStatus.error.data.registeredGoogleUser == false) {
        this.handleError(
          "Sorry, the account " +
            next.loginStatus.error.data.googleEmail +
            " doesnt exist. Please create one here <a href='https://fundamentals.schoolwide.com/teachers/' target='_blank'>Sign Up</a>"
        );
      } else {
        console.log("login failed");
        this.handleError("Login Failed");
      }
    }
    //window.location.href = '/browse';
  }

  handleUserNameInput(event) {
    this.setState({
      username: event.target.value,
    });
  }

  handlePasswordInput(event) {
    this.setState({
      password: event.target.value,
    });
  }

  handleError(myerr) {
    this.setState({
      myError: myerr,
    });
  }

  handleEmailError(myerr) {
    this.setState({
      emailError: myerr,
    });
  }

  handlePasswordError(myerr) {
    this.setState({
      passwordError: myerr,
    });
  }

  resetErrors() {
    this.setState({
      myError: "",
      emailError: "",
      passwordError: "",
    });
  }

  validateForm(u, p) {
    let hasErrors = false;
    let noOfErrors = 0;
    // eslint-disable-next-line
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (u === "") {
      hasErrors = true;
      noOfErrors = noOfErrors + 1;
      this.handleError("The form contains " + noOfErrors + " error.");
      this.handleEmailError("Email cannot be blank.");
      if (p === "") {
        hasErrors = true;
        noOfErrors = noOfErrors + 1;
        this.handleError("The form contains " + noOfErrors + " errors.");
        this.handlePasswordError("Password cannot be blank.");
      }
    } else if (!re.test(u)) {
      hasErrors = true;
      noOfErrors = noOfErrors + 1;
      this.handleError("The form contains " + noOfErrors + " error.");
      this.handleEmailError("Please enter a valid email address.");
      if (p === "") {
        hasErrors = true;
        noOfErrors = noOfErrors + 1;
        this.handleError("The form contains " + noOfErrors + " errors.");
        this.handlePasswordError("Password cannot be blank.");
      }
    } else if (p === "") {
      hasErrors = true;
      noOfErrors = noOfErrors + 1;
      this.handleError("The form contains " + noOfErrors + " error.");
      this.handlePasswordError("Password cannot be blank.");
    } else {
      hasErrors = false;
    }
    return !hasErrors;
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.submitForm();
    }
  };

  submitForm() {
    // If the domain is given use email + domain
    let username = this.props.domain
      ? this.state.username + "@" + this.props.domain
      : // If domain is not given, use email only
        this.state.username;
    let password = this.state.password;
    this.resetErrors();
    if (this.validateForm(username, password)) {
      this.props.login(username, password);
    }
  }

  openLearnMorePdf() {
    let toDay = new Date().setHours(0, 0, 0, 0);
    let requestString =
      "SAML_" + toDay + "_" + new Date().getTime() + "_XANEDU";
    let requestb64String = Buffer.from(requestString).toString("base64");
    window.open(
      "../../schoolwidefun/api/v1/files/files/Clear_your_cache_wLinks.pdf?request=" +
        requestb64String,
      "_blank"
    );
  }

  render() {
    console.log("login component");

    return (
      <div className="bg-color">
        <div className="login-box">
          <div className="imgcontainer">
            <img src={LogoImg} alt="Avatar" className="avatar" />
          </div>
          <If test={this.state.myError}>
            <Alert id="alert-danger" bsStyle="danger">
              <strong>
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.myError }}
                ></div>
              </strong>
            </Alert>
          </If>
          <div className="align-textbox">
            {/*Render username input, and domain if given*/}
            <span className="login-icons icon-icon-user" />
            <input
              id={this.state.emailError ? "red-border" : ""}
              className={this.props.domain ? "userid-domain" : "userid"}
              type="text"
              placeholder={this.props.domain ? "Username" : "Email"}
              value={this.state.username}
              name="uname"
              required
              onKeyPress={this.handleKeyPress}
              onChange={this.handleUserNameInput}
            />
            {/*Render domain if given*/}
            <If test={this.props.domain !== undefined}>
              <input
                type="text"
                className="domain"
                disabled={true}
                placeholder="domain"
                value={"@" + this.props.domain}
                name="domain"
              />
            </If>
            <If test={this.state.emailError}>
              <div className="error-format">{this.state.emailError}</div>
            </If>

            {/*Render password input*/}
            <div>
              <span className="login-icons icon-icon-key" />
              <input
                id={this.state.passwordError ? "red-border" : ""}
                className={"user-password"}
                type="password"
                placeholder="Password"
                value={this.state.password}
                name="psw"
                required
                onKeyPress={this.handleKeyPress}
                onChange={this.handlePasswordInput}
              />
              <If test={this.state.passwordError}>
                <div className="error-format">{this.state.passwordError}</div>
              </If>
            </div>
          </div>
          <div className="align-button">
            <button className="format-button" onClick={this.submitForm}>
              Continue
            </button>
          </div>
          <div className="align-link">
            <Link
              to={`/picture-password${
                this.props.domain ? "/" + this.props.domain : ""
              }`}
            >
              <span style={{ color: "#666666", textDecoration: "underline" }}>
                I Have a Picture Password
              </span>
            </Link>
          </div>
          <div className="align-link">
            <Link to="/forgot-password">
              <span style={{ color: "#666666", textDecoration: "underline" }}>
                Forgot My Password
              </span>
            </Link>
          </div>
          <div className="align-link" style={{ marginTop: "5px" }}>
            <Link to="/signup">
              <span style={{ color: "#666666", textDecoration: "underline" }}>
                I had a resources account and need to create my login
              </span>
            </Link>
          </div>
          <GoogleBtn />
          <div
            className="align-link"
            style={{ margin: "80px", fontSize: "16px" }}
          >
            <Link to="/privacyPolicy">
              <span
                style={{
                  color: "#666666",
                  textDecoration: "underline",
                  fontSize: "14px",
                }}
              >
                Privacy Policy
              </span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

let If = createClass({
  render: function () {
    if (this.props.test) {
      return this.props.children;
    } else {
      return false;
    }
  },
});
const mapStateToProps = ({ Auth, User, Location }) => ({
  email: Auth.email,
  loginStatus: User.LoginStatus,
  pathValue: Location,
});

export default connect(mapStateToProps, actions)(LoginComponent);
