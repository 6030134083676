import React,{Component} from 'react'
import InfoContent from './InfoComponent'
import StandardContent from './StandardsComponent'
import NewUnitButtonGroup from './NewUnitButtonGroup'


const componentMap = {
    "INFO": InfoContent,
    "STANDARDS": StandardContent,
    // "PROGRESS": ProgressContent,
};

export default class NewUnitSidebar extends Component{
    constructor (props) {
        super(props);
        this.state = { selectedTab: "INFO",render:false};
        this.selectTab = this.selectTab.bind(this);
    }

    // componentDidMount(){

    //     this.props.getLessonsDetailedAction(this.props.params.uid);
    //     setTimeout(()=>this.setState({render: true}), 500);

    // }

    selectTab (tab) {
        this.setState({ selectedTab: tab});
    }

    render(){
        const ContentComponent = componentMap[this.state.selectedTab];
        return(
            <div className="detailed-description" style={{height:'100vh', width:'357px'}}>
                <div>
                    <NewUnitButtonGroup selectTab={this.selectTab} selectedTab={this.state.selectedTab}/>
                    <div>
                        <ContentComponent  customUnitdata={this.props.customUnitdata} standardsForLesson={this.props.finalLessons}/>
                    </div>
                </div>
                <div>
                    {/*{this.props.lessonsDetail.asyncStatus === 'SUCCESS' && this.props.lessonsDetail.data &&
                       (<DetailedDescription  image= {this.props.lessonsDetail.data.image} name={this.props.lessonsDetail.data.name} detailType='lessons' description={this.props.lessonsDetail.data.rationale}> No detail </DetailedDescription>)}*/}
                </div>
                {/*
                {this.state.render && (this.props.lessonsDetail.asyncStatus === 'PENDING'? <div>Loading....</div>:<div></div>)}
                {this.state.render && (this.props.lessonsDetail.asyncStatus === 'FAILURE'? <div>Server not responding...</div>:<div></div>)}
                */}
            </div>
        );
    }
}

// const mapStateToProps = (state) => {
//     return ( {lessonsDetail : state.LessonsDetail

//     });
// }

// export default connect(mapStateToProps, actions)(withRouter(LessonsDetailedView))
