import React, {Component} from 'react'
import {Row,Col} from 'react-bootstrap'
import {browserHistory} from 'react-router'

//Future
/*let style1 = {
    style: {
        height: "65px",
        width: "250px",
        border: "1px solid #F5F5F5",
        borderRadius: "8px",
        backgroundColor: "rgba(255,255,255)",
        boxShadow: "0 2px 12px 0 rgba(0,0,0,0.5)",
        padding: "20px 20px 20px 20px"
    },
    arrowStyle: {
        color: "white",
        borderColor: "#F5F5F5"
    }
};*/

class TopBar extends Component {
    constructor(props){
        super(props);
        this.state={showPopOver: false,showMenu:false};
    }

    showSearch=()=>{
        this.setState({showMenu:!this.state.showMenu},()=>{this.props.showSearch()})
    };

    render() {
        return (
            <div>
              <Row style={{position: 'fixed', top: '0px', left: '0px', right: '0px', height: "60px", borderBottom: "1px solid #CCD2DE"}}>
                  <Col md={6} xs={6}><span><i className="my-class-group icon-icon-group"/></span><div className="my-class">My Class</div></Col>
                  <Col md={6} xs={6}>
                     {/* <i className="my-class-search icon-icon-search-circle-outline"/> */}
                      <div style={{float: "right", marginTop: 10}} className={this.state.showMenu?"icon-icon-search-circle-solid header-icon-my-library":"icon-icon-search-circle-outline header-icon-my-library"}  onClick={this.showSearch}/>
                      <i id="parent" onClick={()=>{browserHistory.push('/my-class/add-group');}} className={this.state.showPopOver ? "my-class-add-user-selected icon-blue icon-icon-add-user-circle-outline":"my-class-add-user icon-blue icon-icon-add-user-circle-outline"}/>
                      {/*<i id="parent" onClick={()=>{this.setState({showPopOver: true},()=>{setTimeout(()=>{this.setState({showPopOver:false})},3000)})}} className={this.state.showPopOver ? "my-class-add-user-selected icon-icon-add-user-circle-outline":"my-class-add-user icon-icon-add-user-circle-outline"}/>*/}
                      {/*<ToolTip active={this.state.showPopOver} position="bottom" arrow="" parent="#parent" style={style1}>*/}
                          {/*<Col>*/}
                             {/*<Row style={{cursor : "pointer"}}>*/}
                             {/*<Col md={2} sm={2}><i className="user-icon-add-student icon-icon-user"/></Col>*/}
                             {/*<Col md={10} sm={10}>*/}
                             {/*<Row className="popover-text"> Add Student</Row>*/}
                              {/*<Row className="popover-sub-text">  Add students and manage their portfolio</Row>*/}
                             {/*</Col>*/}
                             {/*</Row>*/}
                              {/*<Row style={{cursor : "pointer"}}>*/}
                                  {/*<Col md={2} sm={2}><i className="group-icon-add-group icon-icon-group"/></Col>*/}
                                  {/*<Col md={10} sm={10}>*/}
                                      {/*<Row className="popover-text" onClick={this.props.setShowAddGroup}> Add Group</Row>*/}
                                      {/*<Row className="popover-sub-text">  Group students</Row>*/}
                                  {/*</Col>*/}
                              {/*</Row>*/}
                          {/*</Col>*/}
                      {/*</ToolTip>*/}
                      {/*<i className="my-class-more icon-icon-more-circle-outline"/>*/}
                  </Col>
              </Row>
            </div>
        );
    }
}

export default TopBar
