import React, {Component} from 'react'
import {Button, Alert} from "react-bootstrap";

// 'Save' - _Default_, when password is unchanged
const SAVE_BUTTON_READY = 'SAVE_BUTTON_READY';

// 'Confirm' - _Success_, when button is clicked and password type choice is locked in but can be changed.
const SAVE_BUTTON_CONFIRM = 'SAVE_BUTTON_CONFIRM';

// 'Error' - _Danger_, if an error occurs. Would need to show the error message above/below the button in red text like
// we do on validation errors.
const SAVE_BUTTON_ERROR = 'SAVE_BUTTON_ERROR';

// 'Saved' - _Default_, after save completes. This shouldn't be clickable
const SAVE_BUTTON_SAVED = 'SAVE_BUTTON_SAVED';

const saveButtonStateMap = {
    SAVE_BUTTON_READY: {
        text: 'Reset Password',
        btnClass: 'footer-button btn-default single-button',
        enabled: true
    },
    SAVE_BUTTON_CONFIRM: {
        text: 'Confirm?',
        btnClass: 'footer-button btn-success single-button',
        enabled: true
    },
    SAVE_BUTTON_ERROR: {
        text: 'Error',
        btnClass: 'footer-button btn-danger single-button',
        enabled: false
    },
    SAVE_BUTTON_SAVED: {
        text: 'Saved',
        btnClass:  'footer-button btn-default single-button',
        enabled: false
    }
};

/**
 * Props
 * onClickPromise - Promise executed when the button is clicked
 * changed - boolean, whether the content has changed and is ready to be saved
 */
export default class ReaderResponseSaveButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            buttonState: SAVE_BUTTON_READY,
            error: ''
        }
    }

    componentWillReceiveProps(next){
      if(next.reset !== this.props.reset){
        this.setState({
          buttonState: SAVE_BUTTON_READY,
          error: ''
        })
      }
    }

    handleClick = ()=> {
      if(this.state.buttonState === SAVE_BUTTON_READY)
        return this.setState({buttonState: SAVE_BUTTON_CONFIRM});

      this.props.onClickPromise()
      .then(() => {
          // Button ready
          this.setState({
              buttonState: SAVE_BUTTON_SAVED,
              error: ''
          });
      }).catch((err) => {
          // Error
          this.setState({
              buttonState: SAVE_BUTTON_ERROR,
              error: err
          });
      });
    };

    render() {
        return (
            <div>
                <Button className={saveButtonStateMap[this.state.buttonState].btnClass}
                        onClick={this.handleClick}
                        disabled={!saveButtonStateMap[this.state.buttonState].enabled}
                        id="threeStateBtn"
                >
                    {saveButtonStateMap[this.state.buttonState].text}
                </Button>
            </div>
        );
    }
}
