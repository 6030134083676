import * as MackinActions from '../../actions/mackin';


const initialState = {
   book: {
       status: {},
       data: {}
   },
   errors: {}
};

const defaultGetSchoolError = 'Email does not match a registered School District'


// const handleError = payload => {
//     switch (true) {
//         case typeof payload === 'string':
//             return { error: payload  }
//             break;
//         case typeof payload === 'object' && Array.isArray(payload):
//             return { error: `${payload[0].field}:   ${payload[0].messages}`  }
//             break;
    
//         default:
//             return { error: `${payload[0].field}:   ${payload[0].messages}`  }
//             break;
//     }
// }
const MackinReducer = (state = initialState, { type, payload }) => {
    switch (type) {

            // get book from mackin
        case MackinActions.GET_MACKIN_USER_BOOK_URL_PENDING:
            return { ...state, book: { ...state.book, status: 'pending' }, errors: {} }
        case MackinActions.GET_MACKIN_USER_BOOK_URL_SUCCESS:
            return { ...state, book: { status: 'success', data: payload }, errors: {} }
        case MackinActions.GET_MACKIN_USER_BOOK_URL_FAILED:
            return { ...state, book: { status: 'failed', data: {} }, error: payload || defaultGetSchoolError }

        case MackinActions.GET_MACKIN_USER_BOOK_URL_DATA_CLEAR:
            return { ...initialState }

        default:
            return state;
    }
};

export default MackinReducer;