import React from 'react'
import {Link} from 'react-router'
import AddPopOverMobile from '../../containers/shared/AddPopOverMobile'
import Datetime from 'react-datetime'
let clearPopOver,clearDatePicker;

class LessonPopOverMobile extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showPopOver: false,
            success: false,
            failed: false,
            errorMessage: "",
            showDatePicker: false,
            addedToPlanner: false,
            addedToMyLibrary: false
        };
        this.addToLibrary = this.addToLibrary.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.showDatePicker = this.showDatePicker.bind(this);
        this.redirectXanedu = this.redirectXanedu.bind(this);
    }

    showTooltip() {
        this.setState({showPopOver: true, success: false, showDatePicker: false, failed: false})
    }

    hideTooltip=()=>{
        this.setState({showPopOver: false, success: false, showDatePicker: false, failed: false})
    }

    showDatePicker() {
        this.setState({showDatePicker: true, success: false, showPopOver: false, failed: false});
    }

    addToLibrary(){
        this.setState({showPopOver: false});
        this.props.addToLibrary(this.props.lid)

            .then((response)=>{
                if(response.statusCode === 200){
                    this.setState({
                      success : true,
                      addedToMyLibrary : true,
                      addedToPlanner: false
                    });
                }
                else{
                    this.setState({
                      success : false,
                      failed : true,
                      errorMessage: response.response.data.message.message
                    });
                }
            })
            .catch((error)=>{
              this.setState({
                success : false,
                failed : true,
                errorMessage: error
              });
            });
    }

    publishDATAToSAML = () =>{
        let jsonData = [{

           "title": "Test Book Title",

           "book_id": "123",

           "user_type": "Educator",

           "sections": [

              {

                 "title": "Test Section Title 1",

                 "royalty": 1.1,

                 "content_url": "https://fundamentals-stg700.schoolwide.com/manage/SAML/397.pdf",

              },

              {

                 "title": "Test Section Title 2",

                 "royalty": 2.0,

                 "content_url": "https://fundamentals-stg700.schoolwide.com/manage/SAML/5063.pdf",

              }

           ]

        }];

        this.props.postSAMLData(jsonData);
    };

    redirectXanedu=()=>{
        //window.open('https://www.xanedu.com','_blank');
        window.open('https://my.sharedbook.com/serve/nm/schoolwide/sso/acs.html','_blank');
    }

    handleDate(date){
        this.props.scheduleTask(date._d, this.props.lid, "Lesson")
            .then((response)=>{
                if(response.status === 201){
                    this.setState({
                      showDatePicker: false,
                      success: true,
                      addedToPlanner: true,
                      addedToMyLibrary: false
                    });
                } else {
                    this.setState({showDatePicker: false})
                }
            })
            .catch((error)=>{
              this.setState({
                success : false,
                failed : true,
                errorMessage: error
              });
            });
    }

    render() {
        return (
                <div>
                    <div  onClick={() => {this.showTooltip()}} id={this.props.popOverId}
                          className="more-demo-icon-lesson icon-icon-more-silo"
                          />
                    {this.state.showPopOver &&
                        <AddPopOverMobile
                            type="add"
                            arrow="center"
                            isTooltipActive={this.state.showPopOver}
                            addToLibrary={this.addToLibrary}
                            position="left"
                            hideTooltip={this.hideTooltip}
                            myId={this.props.popOverId}
                        >
                            <div>
                                <div className="modal-text-header-styles">
                                    ADD OPTIONS
                                </div>
                                <div onClick={this.addToLibrary}
                                    className="modal-text-styles"
                                    >
                                    Add to My Library
                                </div>
                                <div onClick={this.publishDATAToSAML}
                                    className="modal-text-styles">
                                    Publish
                                </div>
                                {/*<div onClick={this.showDatePicker}
                                    className="modal-text-styles"
                                    >
                                    Add to Planner
                                </div>*/}
                            </div>
                        </AddPopOverMobile>
                    }
                    {this.state.showDatePicker &&
                      <div className="mobile-datetime-wrapper">
                          <div className="datetime-close-x"
                            onClick={()=>this.setState({showDatePicker: !this.state.showDatePicker})}>
                            X
                          </div>
                          <Datetime className="mobile-datetime" open={this.state.showDatePicker} onChange={this.handleDate}/>
                      </div>
                    }

                    {this.state.success &&
                        <AddPopOverMobile
                            type="success"
                            arrow="center"
                            isTooltipActive={this.state.success}
                            myId={this.props.popOverId}
                            hideTooltip={this.hideTooltip}
                        >
                            <div>
                                <div className="lesson-added">
                                    <div style={{marginLeft:20,marginBottom: 10}}
                                         className="selected-checkbox-icon-lesson icon-icon-checkbox"
                                         />
                                        <strong>{this.props.lesson_name}</strong> was successfully added to {this.state.addedToMyLibrary && <span>your Library!</span>} {this.state.addedToPlanner && <span>Planner</span>}
                                    </div>
                                    {this.state.addedToMyLibrary &&
                                      <div>
                                          <span>
                                              <Link to="/library/resources">
                                                  <button className="go-to-my-library-planner">
                                                      GO TO MY LIBRARY
                                                  </button>
                                              </Link>
                                          </span>
                                          {/*<span>
                                              <button onClick={this.showDatePicker} className="add-to-planner-library">
                                                  ADD TO PLANNER
                                              </button>
                                          </span>*/}
                                      </div>
                                    }
                                    {this.state.addedToPlanner &&
                                      <div>
                                          <span>
                                            <button onClick={this.addToLibrary} className="add-to-planner-library">
                                                ADD TO MY LIBRARY
                                            </button>
                                          </span>
                                          {/* <span>
                                            <Link to="/planner">
                                              <button className="go-to-my-library-planner">
                                                GO TO PLANNER
                                              </button>
                                            </Link>
                                          </span> */}
                                      </div>
                                    }
                            </div>
                        </AddPopOverMobile>
                    }

                    {this.state.failed &&
                        <AddPopOverMobile
                            isTooltipActive={this.state.failed}
                            arrow="center"
                            myId={this.props.popOverId}
                            position="left"
                            tooltipStyle={{height: '40vw', paddingLeft: '10px'}}
                            hideTooltip={this.hideTooltip}
                            >
                            <div>
                                {this.state.errorMessage &&
                                <div>
                                    <div className="icon-icon-warning" style={{fontSize: 30,color:"#d0021b",float:"left"}}/>
                                    <span>
                                        <strong>{this.props.lesson_name}</strong> already exists in your Library.
                                    </span>
                                </div>
                                }
                            </div>
                        </AddPopOverMobile>
                    }
                </div>
              )
        }
}

export default LessonPopOverMobile;
