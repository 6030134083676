import {
    ASSIGNMENT_ITEM
} from '../actions';


const initialState = {};

const AssignmentItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case ASSIGNMENT_ITEM:
            state = initialState;
            return Object.assign({},state, {data: action.payload});
        default:
            return state;
    }
};

export default AssignmentItemReducer;