import React, {Component} from 'react'

const STUDENT_TEACHER_LIST_ITEM_CHECKED_CLASS = "selected-teacher icon-icon-checkbox";
const STUDENT_TEACHER_LIST_ITEM_UNCHECKED_CLASS = "inactive-teacher icon-icon-checkbox-inactive";
// props
// checked
// text
// onClick
export default class StudentTeacherListItem extends Component {
    render() {
        return (
            <div>
                <span className={this.props.checked? STUDENT_TEACHER_LIST_ITEM_CHECKED_CLASS: STUDENT_TEACHER_LIST_ITEM_UNCHECKED_CLASS}
                      onClick={this.props.onClick}
                />
                <span className="each-teacher">
                    {this.props.text}
                </span>
            </div>
        );
    }
}
