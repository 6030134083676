import React,{Component} from  'react'
import {Row,Col} from 'react-bootstrap'
import CollapsibleComponent from '../../containers/Details/CollapsibleComponent';
import mySolidArrowRight from '../../assets/icons/SVG/my-solid-arrow-right.png';
import mySolidArrow from '../../assets/icons/SVG/my-solid-arrow.png'

class Vocabulary extends Component{

    createSubArrays(wordsArr){
        if(!wordsArr) {
            return [];
        }
        let instNumber= Math.ceil(wordsArr.length/6);
        let arrayWithSotting = [];
        for(let i=0;i<6;i++){
            let arr = wordsArr.slice(instNumber*i,(i+1)*instNumber);
            arrayWithSotting.push(arr);
        }
        return arrayWithSotting;
    }

    render(){
        let vocabArr = this.createSubArrays(this.props.content.vocabulary);
        //let mentorArr = this.createSubArrays(this.props.content.mentorTexts);
        return(
            <div className='list-of-items unit-vocabulary'>
                <CollapsibleComponent collapsed={false} header="INSTRUCTIONAL VOCABULARY" headerStyle={{fontSize: "18px",fontWeight: 700, color: "#4A4A4A", borderBottom: "1px solid #CCD2DE", paddingBottom: "15px", paddingTop: "15px" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                    <Row>
                        {this.props.content.vocabulary && this.props.content.vocabulary.length > 0 ? vocabArr.map((childArr,i)=>(
                            <Col md={2} sm={6} xs={6} className='vocabulary-content' >
                                {childArr.map((vocabularyArray,j)=>(

                                    <div  className='word-style'>

                                        {vocabularyArray}
                                    </div>
                                ))}
                            </Col>
                        )):<div className='word-style'>No Vocabulary to Display</div>}
                    </Row>
                </CollapsibleComponent>
                <div>
                    <CollapsibleComponent collapsed={false} header="MENTOR TEXTS VOCABULARY" headerStyle={{fontSize: "18px",fontWeight: 700, color: "#4A4A4A", borderBottom: "1px solid #CCD2DE", paddingBottom: "15px", paddingTop: "15px" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        {this.props.content.mentorTexts && this.props.content.mentorTexts.length>0?this.props.content.mentorTexts.map((mentorTextsDetail,i)=>(
                            <div>
                                {i===0?null:<hr/>}
                                <Row className='text-sub-heading' key={i}>{mentorTextsDetail.name}</Row>
                                <Row className='vocabulary-content' >
                                    {!mentorTextsDetail.vocabulary || mentorTextsDetail.vocabulary.length===0?<div className='word-style'>No Vocabulary to Display</div>:
                                        this.createSubArrays(mentorTextsDetail.vocabulary).map((mentorSubArr,i)=>(
                                            <Col md={2} sm={6} xs={6} >
                                                {mentorSubArr.map((mentorTextsVocabulary,j) =>(
                                                    <div className='word-style'>{mentorTextsVocabulary}</div>
                                                ))}
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>
                        )):<div className='word-style'>No Vocabulary to Display</div>}
                    </CollapsibleComponent>
                </div>
            </div>
        );
    }}

export default Vocabulary