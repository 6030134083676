import React,{Component} from  'react'
import {Row,Panel} from 'react-bootstrap'
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from 'react-sortable-hoc'
import RemovePopOver from '../../components/Shared/RemoveItemPopOver'

const  DragHandle = SortableHandle(({value}) =>
    <div style={{display:'flex',width:'100%'}}>
        <div className="panel-item panel-view-icon">
            <div className="demo-detail-icon-view  icon-icon-drag"/>
        </div>
        <div className="panel-item panel-new-item-name icon-flex-right">
                <Row className="heading-format-in-panel">
                    TITLE
                </Row>
                {value.name &&
                    <Row>
                        {value.name}
                    </Row>
                }
            </div>
            <div className="panel-item panel-new-item-type icon-flex-right">
                <Row className="heading-format-in-panel">
                    TYPE
                </Row>
                {value.lessonType &&
                    <Row>
                        {value.lessonType}
                    </Row>
                }
            </div>
            <div className="panel-item panel-new-item-standard icon-flex-right">
                <Row className="heading-format-in-panel">
                    STANDARDS
                </Row>
                <Row>
                    {value.focusedStandards &&
                        <span>
                            {value.focusedStandards.map((focusedStandard,i)=> (
                                <span style={{fontWeight: "700"}}>
                                    {i===0?focusedStandard:(", ").concat(focusedStandard)}
                                </span>
                            ))}
                        </span>
                    }
                    {value.focusedStandards && value.focusedStandards[0] && value.otherStandards && value.otherStandards[0] &&
                        <span>, </span>
                    }
                    {value.otherStandards &&
                        <span>
                            {value.otherStandards.map((otherStandard,i)=> (
                                <span>
                                    {i===0?otherStandard:(", ").concat(otherStandard)}
                                </span>
                            ))}
                        </span>
                    }
                </Row>
            </div>
        </div>
);

const SortableItem = SortableElement(({value, index, infoValue, onRemove}) =>
  <div className='no-select'>
      <div id="my-custom-panel" className="panel-lesson-content">
            <DragHandle value={value}/>

            <div className="panel-item">
                    <RemovePopOver stopPropagation={true} preventDefault={true} popOverId={"popOverRemove"+value._id} className='remove-lesson' removeObj={value} remove={onRemove}/>
            </div>
            <div className="panel-item" style={{marginRight:'20px'}}>
                <a href={"/lessons/"+value._id} target="_blank">
                    <button style={{border:'None',backgroundColor:'#FFFFFF'}} className="demo-icon-view  icon--view"/>
                </a>
            </div>
       </div>
  </div>
);

const SortableList = SortableContainer(({items,onRemove}) => {
  return (
    <div id="joyride-select-child-sortable-units">
      {items.map((value,i) => (
        <SortableItem  value={value} index={i} infoValue={i} onRemove={onRemove}/>
      ))}
   </div>
  );
});

class NewUnitOverviewContent extends Component{
    constructor(props){
        super(props);
        this.state={
            items:[],
            isFirstLoad:false
        };
    }

    componentDidMount(){
        if(this.props.sortedLessons.length!==0){
            this.setState({items:this.props.sortedLessons});
        }
        this.setState({isFirstLoad:true});
    }

    componentWillReceiveProps(next) {
        let items=this.state.items;
        if(this.state.isFirstLoad){
            this.setState({items:next.lessons},()=>{this.props.sortLessons(this.state.items)});
        }
        if(!this.state.isFirstLoad){
            if(this.state.items.length<next.lessons.length){
                    items.unshift(next.lessons.slice(0)[0]);
                    this.setState({items:items},()=>{this.props.sortLessons(this.state.items)});
            }
            if(this.state.items.length>next.lessons.length){
                let sortedList= this.state.items;
                let newList = next.lessons;
                let newSortedList=[];
                for(let i=0;i<sortedList.length;i++){
                    for(let j=0;j<newList.length;j++){
                        if(sortedList[i]===newList[j]){
                           newSortedList.push(sortedList[i]);
                        }
                    }
                }
                this.setState({items:newSortedList},()=>{this.props.sortLessons(this.state.items)}) ;
            }
            //this.props.sortLessons(this.state.items)
        }
        this.setState({isFirstLoad:false});
        if(this.props.newLesson){
            if(this.state.items.length!==next.lessons.length){
                this.setState({items:next.lessons},()=>{this.props.changeNewLessonStatus(false)});
            }
        }
        //this.props.sortLessons(this.state.items)
    }

    remove=(obj)=> {
        this.props.removeLessons({isAdd:false,lesson:obj});
        this.props.removeFromSearch(obj);
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState({
            items: arrayMove(this.state.items, oldIndex, newIndex)
        });
        this.setState({sortedList:this.state.items});
        this.props.sortLessons(this.state.items);
    };

    render(){
        return(
            <div>
               {this.state.items[0]? "" :
                    <div>
                        <Panel id="my-panel">
                            <div className="panel-lesson">
                                There are currently no lesssons in this Unit. Please tap the "ADD LESSONS" button in the action bar to browse lessons
                            </div>
                        </Panel>
                    </div>
                }
                <div>
                    <SortableList items={this.state.items} helperClass="SortableHelper" onSortEnd={this.onSortEnd} useDragHandle={true} onRemove={this.remove}/>
                </div>
            </div>
        );
    }
}

export default NewUnitOverviewContent
