import React,{Component} from 'react';
import CollapsibleComponent from '../../containers/Details/CollapsibleComponent';
import ImageDetailsComponent from '../../containers/Details/ImageDetailsComponent';
import constants from "../../constants";
import DetailsListViewItem from "../../containers/Details/DetailsListViewItem";
import mySolidArrowRight from '../../assets/icons/SVG/my-solid-arrow-right.png';
import mySolidArrow from '../../assets/icons/SVG/my-solid-arrow.png';

class Appendicies extends Component {
    render() {
        //console.log('props',this.props.appendices_content,this.props);
        let appendices_result = Object.keys(this.props.appendices_content) .map(key => ({
            title: key, 
            data : this.props.appendices_content[key]
        }));
        //console.log(appendices_result);
        
        return(
            <div className={'list-of-items unit-appendicies'}>
                {appendices_result.map((n,i)=>
                    <CollapsibleComponent collapsed={true} header={n.title} headerStyle={{fontSize: "17px",letterSpacing:1.5,height:20.5, fontWeight:'bold', color: "#4A4A4A", borderBottom: "1px solid rgba(151, 151, 151,0.5)", paddingBottom: "35px", paddingTop: "15px",fontFamily:"ProximaNova" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        <ImageDetailsComponent componentData={n.data} componentType="appendicies" appendiciesType={n.title} unit_id={this.props.unit_id} />
                    </CollapsibleComponent>

                )}
                {appendices_result.length <= 0 &&
                    <div className='lesson-text-padding featured-reading' style={{textAlign: "center"}}> {'There are no appendices in this unit.'}</div>}
                
            </div>
        );
    }
}

export default Appendicies;