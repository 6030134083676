import React,{Component} from  'react'
import {Col} from 'react-bootstrap'
import Dialog from './Dialog'
import DistrictItem from  './DistrictItem'
import debounce from 'debounce'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import DistrictForm from './DistrictForm'
import InfiniteScroll from 'react-infinite-scroll-component';
import analytics360Icon from '../../assets/images/analytics360-icon.png';

const items={
    "DistrictItem" : DistrictItem
};

class DistrictsListView extends Component{

    constructor(props) {
        super(props);
        this.state = {
            addDistrict: false,
            editDistrict:false,
            deleteDistrict:false,
            deleteDistrictId:0,
            deleteDistrictName: "",
            editDistrictData: null,
            editDistrictId:0,
            searchTerm: ""
        };
        this.search = debounce(this.search,1000);
        this.addDistrictForm = null;
        this.editDistrictForm = null;
    }

    search=(val)=>{
        this.props.getDistricts(val,1,0);
        this.setState({searchTerm : val});
    };


    changeFunction= (event)=>{
        let val = event.target.value;
        this.search(val);
    };

    isActiveChangeFunction= (event)=>{
        if (event.target.checked === true){
            this.setState({filterIsActive: true});
        }
        else {
            this.setState({filterIsActive: false});
        }
    };

    onAddDistrictCancel = ()=>{
        this.setState({addDistrict: false});
    };

    onEditDistrictCancel = ()=>{
        this.setState({editDistrict: false});
    };

    onDeleteDistrictCancel = ()=>{
        this.setState({deleteDistrict: false});
    };

    onResetPasswordCancel = ()=>{
        this.setState({resetPassword: false});
    };


    onAddDistrictOk = ()=>{
        console.log("test here");
        if (this.addDistrictForm.validateInput()) {
            let data = this.addDistrictForm.getData();
            if(!data.hasOwnProperty("print_only_count")){
                data.print_only_count = 0
            } else {
                data.print_only_count = data.print_only_count !== '' ? parseInt(data.print_only_count, 10) : 0
            }
            if(!data.hasOwnProperty("unlimited_count")){
                data.unlimited_count = 0
            } else {
                data.unlimited_count = data.unlimited_count !== '' ? parseInt(data.unlimited_count, 10) : 0
            }

            if(!data.hasOwnProperty("text_only_count")){
                data.text_only_count = 0
            } else {
                data.text_only_count = data.text_only_count !== '' ? parseInt(data.text_only_count, 10) : 0
            }
            console.log(data);

            this.props.postDistrictAction(data)
                .then((response)=>{
                    this.setState({addDistrict: false});
                    if(response){
                        this.props.getDistricts("",1,0);
                    }
                    else{
                        console.log("error occured");
                        this.props.getDistricts("",1,0);

                    }
                })
                .catch(function (error) {
                    console.log("exception occured");
                });
        }
    };

    onEditDistrictOk = ()=>{
        console.log("edit district test here");

        //if (this.editDistrictForm.validateInput()) {
            let data = this.editDistrictForm.getData();
            this.props.putDistrictAction(this.state.editDistrictId,data)
                .then((response)=>{
                    if(response.statusCode === 200){
                        this.props.getDistricts("",1,0);
                    }else{
                        console.log("error occured")
                    }
                })
                .catch(function (error) {
                    console.log("exception occured");
                });
            this.setState({editDistrict: false});
        // }else{
        //     console.log("edit district else here");
        // }
    };

    onDeleteDistrictOk = ()=>{
        this.setState({deleteDistrict: false});

        this.props.deleteDistrict(this.state.deleteDistrictId)
            .then((response)=>{

                if(response.statusCode === 200){
                    this.props.getDistricts("",1,0);
                }
                else{
                    console.log("error occured")
                }
            })
            .catch(function (error) {
                console.log("exception occured");
            });
    };

    render(){
        const Item = items[this.props.item];
        let filteredValues ;
        // if(this.props.districts.asyncStatus === 'SUCCESS'){
            filteredValues = this.props.districts.data.results;
        // }
        return(
            <div>
                {(this.state.addDistrict || this.state.editDistrict || this.state.deleteDistrict)?this.props.backgroundChange(true):this.props.backgroundChange(false)}

                {this.state.addDistrict &&
                    <Dialog onCancel={this.onAddDistrictCancel} onOk={this.onAddDistrictOk} itemType={this.props.item}  title="ADD DISTRICT" okText="ADD" okStyle="dialog-save-button" dialogStyle='dialog-modal-district'>
                        <DistrictForm ref={(el)=>{this.addDistrictForm = el}} />
                    </Dialog>
                }

                {this.state.editDistrict &&
                    <Dialog onCancel={this.onEditDistrictCancel} onOk={this.onEditDistrictOk} itemType={this.props.item}  title="EDIT DISTRICT" okText="SAVE" okStyle="dialog-save-button" dialogStyle='dialog-modal-district'>
                        <DistrictForm ref={(el)=>{this.editDistrictForm = el}} districtData={this.state.editDistrictData} />
                    </Dialog>
                }

                {this.state.deleteDistrict &&
                    <Dialog onCancel={this.onDeleteDistrictCancel} onOk={this.onDeleteDistrictOk} itemType={this.props.item}  title="DELETE DISTRICT" okText="DELETE" okStyle="dialog-delete-button">
                        <div className="dialog-spacer">
                            Are you sure you want to delete district "{this.state.deleteDistrictName}"?
                        </div>
                    </Dialog>
                }

                <div  style={{marginTop:"75px"}}>
                    <div className="list-header" >
                        <div>
                            <span className="title-of-list">
                                <strong>
                                    {this.props.listTitle}
                                    </strong>
                            </span>
                            <span style={{display: 'inline-block', float: 'right'}}>
                                <a href='https://analytics.google.com/analytics/' target='_blank'  style={{display: 'inline-block'}}>
                                    <img src={analytics360Icon} style={{display: 'block', maxWidth: '100%', width: '25px', height: '25px', cursor: 'pointer'}}/>
                                </a>
                                <i onClick={()=>this.setState({addDistrict: true})} className="add-item-icon icon-icon-add-circle-solid can-hover" style={{lineHeight: '20px'}}/>
                            </span>
                        </div>
                        <div className="filter-panel">
                            <span> Search: <input type="text" placeholder="Name or State" onChange={this.changeFunction} className="admin-search-box"/></span>
                        </div>
                    </div>
                    <div className="list-view">
                        <InfiniteScroll  height="400px"
                                         next={()=>{
                                                 this.props.getDistrictsAction(this.state.searchTerm,+this.props.districts.page +1,1)
                                         }
                                         }
                                         hasMore={this.props.districts.hasMore}
                                         loader={<div>Loading...</div>}
                                         dataLength={filteredValues?.length ?? 0}
                        >
                        {filteredValues?.length && filteredValues.map((n)=>
                            <div className="list-items">
                                <Col className="list-item" md={6} sm={6} >
                                    <Item n={n}/>

                                    <span onClick={()=>this.setState({deleteDistrict: true,deleteDistrictId : n._id, deleteDistrictName: n.name})} className="can-hover"><i className="delete-item icon-icn-close"/> </span>
                                    <span onClick={()=>this.setState({editDistrict: true,editDistrictData:n, editDistrictId: n._id})} className="can-hover"><i className="edit-item-icon icon-icon-pen-circle-outline"/></span>
                                    <div className="item-border"/>
                                </Col>
                            </div>
                        )}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        districts : state.Districts,
    }
};

export default connect(mapStateToProps, actions)(DistrictsListView)