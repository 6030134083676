import React,{Component} from  'react';
import AppendicesDetailedView from './AppendicesDetailedView';

class AssessmentComponent extends Component{

    render(){

        return(
            <div>
                <AppendicesDetailedView/>
            </div>
        );
    }
}



export default AppendicesDetailedView;
