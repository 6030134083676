import React from "react";

const Snackbar = ({ message, open, duration = 3000, isError, isSuccess }) => {
  if (!open) return null;

  return <div style={styles({ isError, isSuccess })}>{message}</div>;
};

const styles = ({ isError, isSuccess }) => {
  return {
    position: "fixed",
    top: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: isSuccess ? "#07bc0c" : isError ? "#e74c3c" : "white",
    color: isError || isSuccess ? "white" : "black",
    padding: "12px 24px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
    transition: "opacity 0.3s ease",
  };
};

export default Snackbar;
