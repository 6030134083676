import React,{Component} from  'react'
import {withRouter} from 'react-router'
import {Col, Row} from 'react-bootstrap'
import {browserHistory} from 'react-router'
import {connect} from 'react-redux'
import * as actions from '../../redux/actions'
import authAPI from '../../api/authAPI'
import '../../assets/styles/index.scss'

class NewUnitHeader extends Component{
    constructor () {
        super();
        this.state={
            success:false,
            unitCheck:false,
            lessonIds:[],
            toggleAddLesson: false,
            buttonText: 'ADD LESSONS'
        };
        this.saveUnit = this.saveUnit.bind(this);
    }

    saveUnit(){
        if(!this.props.newUnit.heading && this.props.location.pathname==='/new-unit'){
            this.props.checkUnitHeadingAction(false);
            if(this.props.checkFilterStatus){
                this.props.infoClick();
            }
        }
        // ---required field validation for description
        // if(!this.props.newUnit.description){
        //     this.props.checkUnitDescriptionAction(false);
        //     if(this.props.checkFilterStatus){
        //         this.props.infoClick();
        //     }
        // }
        //----required fields setting up for saving new unit
        //if(this.props.location.pathname==='/new-unit'){
            // let introduction={
            //     'text':this.props.newUnit.description
            // };
            // this.props.lessons.map((lesson) => {
            //     this.state.lessonIds= this.state.lessonIds.concat(lesson._id)
            // });
            // this.setState({
            //     lessonIds:this.state.lessonIds
            // });
            // let imagePath='images/unit-custom.png';
            if(this.props.customUnitdata.is_custom===true){
                //---saving of unit(custom) instead of autosave----
                //  authAPI.updateUnit(this.props.params.uid,this.props.newUnit.heading, introduction, this.state.lessonIds,imagePath)
                // .then((response)=>{
                //     if(response.status === 200){
                //         this.setState({success:true})
                //         this.props.changeUnitHeadingAction('');
                //         this.props.changeUnitDescriptionAction('');
                //         browserHistory.push('/units/'+response.data._id);
                //     }else{
                //         this.setState({success:false});
                //     }
                // })
                browserHistory.replace('/units/'+this.props.params.uid);
            }else{
                //---saving of unit(new) instead of auto save-----
                // if(this.props.newUnit.heading!==''){
                //     authAPI.saveUnit(this.props.params.uid ? this.props.params.uid:this.props.newUnit.newId,this.props.newUnit.heading, introduction, this.state.lessonIds,imagePath)
                //     .then((response)=>{
                //         if(response.status === 201){
                //             this.setState({success:true})
                //             this.props.changeUnitHeadingAction('');
                //             this.props.changeUnitDescriptionAction('');
                //             browserHistory.push('/units/'+response.data._id);
                //         }else{
                //             this.setState({success:false});
                //         }
                //     })
                // }
                browserHistory.replace('/units/'+this.props.newUnit.newId);
            }
       // }
        // else{
        //     browserHistory.push('/units/'+this.props.params.uid);
        // }
    }

    showInfo=()=>{
        this.setState({toggleAddLesson:false});
        this.props.filterClick(false);
    }

    showLessonSearch=()=>{
        this.setState({toggleAddLesson:true});
        this.props.filterClick(true);
    }

    render(){
        return(
            <div className="search-bar">
                <Row>
                    <Col md={6} sm={6} className="lesson-header">
                        <span className="add-padding-back-icon">
                            <i onClick={browserHistory.goBack} className="chevron-left-demo-icon icon-icon-back"/>
                        </span>
                        <span>{this.props.newUnit.heading}</span>
                    </Col>
                    <Col md={6} sm={6}>
                        <span className="add-padding-to-icons">
                            <i onClick={this.showInfo} className={this.state.toggleAddLesson?"info-demo-icon icon-icon-info-circle-outline":"info-demo-icon icon-icon-info-circle-solid"}/>
                        </span>
                        <span className="add-padding-to-icons">
                            <i id="joyride-selector-unit-add-note" onClick={this.props.newUnit.heading===''?()=>this.props.checkUnitHeadingAction(false):this.showLessonSearch} className={!this.state.toggleAddLesson?"search-demo-icon icon-icon-add-note-circle-outline":"search-demo-icon icon-icon-add-note-circle-solid" }/>
                        </span>
                        {/*<span><button className="format-customize-button" style={this.buttonColor()} onClick={this.slideClick} >{this.state.buttonText}</button></span>*/}
                        <span className="verticalLine" style={{borderLeft:'None'}}>
                            <i className="browse-demo-icon icon-icon-checkbox" onClick={this.props.newUnit.heading?this.saveUnit:''} style={{color:(this.props.newUnit.heading==='' && this.props.location.pathname==='/new-unit')?'#9B9B9B':'#46C56D'}}>
                              <div id="joyride-selector-icon-checkbox"/>
                            </i>
                        </span>
                        {/*<span><button className="format-customize-button" onClick={this.saveUnit} >SAVE</button></span>*/}
                    </Col>
                </Row>
                {this.state.success?<div className={'success-message'}><div className='message-name' >Unit saved</div></div>:''}
                {/*{this.state.unitCheck?<div className={'success-message'} style={{animation: "fadeinout 30s linear forwards"}}><div className='message-name' >Please click on "ADD LESSON" button to give the unit name and Description</div></div>:''}*/}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        newUnit : state.NewUnit,
        saveUnit: state.SaveUnit
    });
};

export default connect(mapStateToProps, actions)(withRouter(NewUnitHeader))
