import React, { Component } from "react";
import authAPI from "../api/authAPI";
import constants from "../constants";
import { browserHistory, withRouter } from "react-router";
import "../assets/styles/globalSearchComponent.scss";
import { connect } from "react-redux";
import * as actions from "../redux/actions";
import { DebounceInput } from "react-debounce-input";

class NewGlobalSearchComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
      resources: [],
      pageNumber: 1,
      nextPage: -1,
      searchString: "",
      hasMore: true,
      lessonIds: [],
      selectedFilter: false,
      showOptions: false,
      queryItems: "",
      Assessment: {},
      Book: {},
      Lesson: {},
      Resource: {},
      Unit: {},
      queryString: "",
      timeout: 300,
    };
    this.pageLoad = true;
    this.fetchResources = this.fetchResources.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  setFocus = () => {
    if (window.innerWidth >= 768) this.gscInput.focus();
  };

  handleClick(e, i, item) {
    let selectedItem = this.state.queryItems;
    let value = e.target.checked;
    let currentQuery = this.state.queryString;
    if (value) {
      item.checked = true;
      if (
        this.props.itemType === "Planer" &&
        currentQuery ===
          "&item_type=Lesson&item_type=Resource&item_type=Book&item_type=Assessment"
      ) {
        currentQuery = "";
        selectedItem = "";
      }
      if (selectedItem === "") {
        selectedItem = selectedItem + item.name;
      } else {
        selectedItem = selectedItem + ", " + item.name;
      }
      currentQuery = currentQuery + "&item_type=" + item.query;
    } else {
      item.checked = false;
      let queryRe = new RegExp("&item_type=" + item.query);
      currentQuery = currentQuery.replace(queryRe, "");
      let re = new RegExp(", " + item.name);
      selectedItem = selectedItem.replace(re, "");
      if (selectedItem === this.state.queryItems) {
        let re = new RegExp(item.name + ", ");
        selectedItem = selectedItem.replace(re, "");
      }
      if (this.state.queryItems === item.name) {
        selectedItem = "";
      }
      if (this.props.itemType === "Planer" && currentQuery === "") {
        currentQuery =
          "&item_type=Lesson&item_type=Resource&item_type=Book&item_type=Assessment";
        selectedItem = "Assessments, Books, Resources, Lessons";
      }
    }

    if (document.querySelectorAll(":checked").length === 0) {
      this.setState({
        selectedFilter: false,
        resources: [],
        totalCount: 0,
      });
    } else {
      this.setState(
        {
          queryItems: selectedItem,
          stateQuery: item,
          queryString: currentQuery,
          pageNumber: 1,
          selectedFilter: true,
        },
        () => {
          this.fetchResources(this.state.searchString);
        }
      );
    }
  }

  getTypeOfResource() {
    const pathName = this.props.location.pathname;
    return pathName.includes("resources")
      ? "&item_type=Resource"
      : pathName.includes("books")
      ? "&item_type=Book"
      : pathName.includes("units")
      ? "&item_type=Unit"
      : pathName.includes("lessons")
      ? "&item_type=Lessons"
      : pathName.includes("texts")
      ? "&item_type=Book"
      : pathName.includes("assessments")
      ? "&item_type=Assessment"
      : pathName.endsWith("browse")
      ? "&item_type=Resource&item_type=Book&item_type=Unit&item_type=Lessons&item_type=Assessment"
      : "";
  }

  getLibraryTypeOfResource() {
    const pathName = this.props.location.pathname;
    return pathName.includes("resources")
      ? "teachingResources"
      : pathName.includes("books")
      ? "books"
      : pathName.includes("lessons")
      ? "lessons"
      : pathName.includes("units")
      ? "units"
      : pathName.includes("assessments")
      ? "assessments"
      : pathName.endsWith("browse")
      ? "gradesOnly"
      : "";
  }

  fetchResources(searchString, isGetMore) {
    if (!isGetMore) {
      this.setState({ resources: [] });
    }

    let self = this;
    let typeOfResource = this.getTypeOfResource();
    let libraryTypeOfResource = this.getLibraryTypeOfResource();

    if (self.state.selectedFilter === true) {
      self.state.resources = [];
      self.setState({ selectedFilter: false });
    }
    // Use /books endpoint if the logged in user is a student
    let resourceSearchPromise;
    if (localStorage.user_type === "Student") {
      // Added is_lite parameter to global search query
      // Sends this info to authAPI line 646
      resourceSearchPromise = authAPI.getBooks(
        isGetMore ? this.state.nextPage : this.state.pageNumber,
        "&search=" + searchString
      );
    } else {
      if (!this.props.isLibrary) {
        resourceSearchPromise = authAPI.pageSpecificSearch(
          isGetMore ? this.state.nextPage : this.state.pageNumber,
          searchString,
          typeOfResource,
          localStorage.is_lite
        );
      } else if (this.props.isLibrary) {
        if (window.location.pathname.endsWith("/library")) {
          this.props.globalLibrarySearchAction(searchString, 1);
        } else if (window.location.pathname.includes("/folders")) {
          console.log("line 173");
          this.props.setLibrarySearchValue(searchString);
          // this.props.getFoldersAction(1,searchString,'&search=${searchString}',localStorage.is_lite)
        } else {
          this.props.getCustomLibraryFiltersAction(
            this.props.libraryStrand,
            libraryTypeOfResource,
            "&search=" + searchString,
            undefined,
            true
          );
        }
      }
    }

    if (localStorage.user_type === "Student" || !this.props.isLibrary) {
      resourceSearchPromise.then((resp) => {
        let stateToUpdate;
        if (!resp?.results?.length) {
          stateToUpdate = {
            nextPage: -1,
            resources: [],
            totalCount: 0,
            hasMore: false,
            pageNumber: 1,
          };
        } else {
          stateToUpdate = {
            nextPage: resp.nextPage,
            resources: resp.results,
            totalCount: resp.total,
            hasMore: resp.totalCount > resp.results.length,
            pageNumber: resp.currentPage,
          };
        }

        this.setState({
          ...stateToUpdate,
        });

        this.props.updateFromSearch(
          stateToUpdate.resources,
          stateToUpdate.totalCount,
          false,
          stateToUpdate.nextPage
        );
      });
    }
  }

  handleSearchClear() {
    if (this.props.isLibrary) {
      if (window.location.pathname.endsWith("/library")) {
        this.props.getMyLibraryAction("recents");
      } else if (window.location.pathname.includes("/folders")) {
        this.props.getFoldersAction(1, "", "", localStorage.is_lite);
        this.props.setLibrarySearchValue("");
      } else {
        this.props.getCustomLibraryFiltersAction(
          this.props.libraryStrand,
          this.getLibraryTypeOfResource(),
          undefined,
          undefined,
          false
        );
      }
    } else {
      this.props.updateFromSearch([], 0, true, false);
    }
  }

  componentDidMount() {
    // Added logic for lite users
    if (localStorage.is_lite === "true") {
      this.setState({
        Assessment: {
          disabled: false,
          name: "Assessments",
          checked: false,
          query: "Assessment",
        },
        Book: { disabled: false, name: "Books", checked: false, query: "Book" },
        Lesson: {
          disabled: false,
          name: "Lessons",
          checked: false,
          query: "Lesson",
        },
        Resource: {
          disabled: false,
          name: "Resources",
          checked: true,
          query: "Resource",
        },
        Unit: { disabled: true, name: "Units", checked: false, query: "Unit" },
        queryItems: "Resources",
        queryString: "&item_type=Resource",
      });
    } else if (this.props.itemType === "Teaching Resources") {
      this.setState({
        Assessment: {
          disabled: false,
          name: "Assessments",
          checked: false,
          query: "Assessment",
        },
        Book: { disabled: false, name: "Books", checked: false, query: "Book" },
        Lesson: {
          disabled: false,
          name: "Lessons",
          checked: true,
          query: "Lesson",
        },
        Resource: {
          disabled: false,
          name: "Resources",
          checked: true,
          query: "Resource",
        },
        Unit: { disabled: false, name: "Units", checked: false, query: "Unit" },
        queryItems: "Lessons, Resources",
        queryString: "&item_type=Lesson&item_type=Resource",
      });
    } else if (this.props.itemType === "Units") {
      this.setState({
        Assessment: {
          disabled: false,
          name: "Assessments",
          checked: false,
          query: "Assessment",
        },
        Book: { disabled: false, name: "Books", checked: false, query: "Book" },
        Lesson: {
          disabled: false,
          name: "Lessons",
          checked: false,
          query: "Lesson",
        },
        Resource: {
          disabled: false,
          name: "Resources",
          checked: false,
          query: "Resource",
        },
        Unit: { disabled: false, name: "Units", checked: true, query: "Unit" },
        queryItems: "Units",
        queryString: "&item_type=Unit",
      });
    } else if (this.props.itemType === "Books") {
      this.setState({
        Assessment: {
          disabled: false,
          name: "Assessments",
          checked: false,
          query: "Assessment",
        },
        Book: { disabled: false, name: "Books", checked: true, query: "Book" },
        Lesson: {
          disabled: false,
          name: "Lessons",
          checked: false,
          query: "Lesson",
        },
        Resource: {
          disabled: false,
          name: "Resources",
          checked: false,
          query: "Resource",
        },
        Unit: { disabled: false, name: "Units", checked: false, query: "Unit" },
        queryItems: "Books",
        queryString: "&item_type=Book",
      });
    } else if (this.props.itemType === "Assessments") {
      this.setState({
        Assessment: {
          disabled: false,
          name: "Assessments",
          checked: true,
          query: "Assessment",
        },
        Book: { disabled: false, name: "Books", checked: false, query: "Book" },
        Lesson: {
          disabled: false,
          name: "Lessons",
          checked: false,
          query: "Lesson",
        },
        Resource: {
          disabled: false,
          name: "Resources",
          checked: false,
          query: "Resource",
        },
        Unit: { disabled: false, name: "Units", checked: false, query: "Unit" },
        queryItems: "Assessments",
        queryString: "&item_type=Assessment",
      });
    } else if (this.props.itemType === "Planer") {
      this.setState({
        Assessment: {
          disabled: false,
          name: "Assessments",
          checked: true,
          query: "Assessment",
        },
        Book: { disabled: false, name: "Books", checked: true, query: "Book" },
        Lesson: {
          disabled: false,
          name: "Lessons",
          checked: true,
          query: "Lesson",
        },
        Resource: {
          disabled: false,
          name: "Resources",
          checked: true,
          query: "Resource",
        },
        Unit: { disabled: true, name: "Units", checked: false, query: "Unit" },
        queryItems: "Assessments, Books, Resources, Lessons",
        queryString:
          "&item_type=Lesson&item_type=Resource&item_type=Book&item_type=Assessment",
      });
    } else if (
      this.props.itemType === "Library" &&
      localStorage.user_type === "Student"
    ) {
      this.setState({
        Assessment: {
          disabled: false,
          name: "Assessments",
          checked: true,
          query: "Assessment",
        },
        Book: { disabled: false, name: "Books", checked: true, query: "Book" },
        Lesson: {
          disabled: false,
          name: "Lessons",
          checked: true,
          query: "Lesson",
        },
        Resource: {
          disabled: false,
          name: "Resources",
          checked: true,
          query: "Resource",
        },
        Unit: { disabled: false, name: "Units", checked: false, query: "Unit" },
        queryItems: "Books",
        queryString: "item_type=Book",
      });
    } else {
      this.setState({
        Assessment: {
          disabled: false,
          name: "Assessments",
          checked: true,
          query: "Assessment",
        },
        Book: { disabled: false, name: "Books", checked: true, query: "Book" },
        Lesson: {
          disabled: false,
          name: "Lessons",
          checked: true,
          query: "Lesson",
        },
        Resource: {
          disabled: false,
          name: "Resources",
          checked: true,
          query: "Resource",
        },
        Unit: { disabled: false, name: "Units", checked: true, query: "Unit" },
        queryItems: "Assessments, Books, Resources, Lessons, Units",
        queryString:
          "&item_type=Lesson&item_type=Resource&item_type=Unit&item_type=Book&item_type=Assessment",
      });
    }
  }

  // This method isn't necessary if the checkboxes aren't being used
  // Greyed out right now because I disabled the click
  optionToggle = () => {
    this.setState({ showOptions: !this.state.showOptions });
  };

  toggleLesson = (lesson) => {
    let lessonId = lesson._id;
    let lessonIdArray = this.state.lessonIds;
    let lessonIdIndex = lessonIdArray.indexOf(lessonId);
    if (lessonIdIndex > -1) {
      lessonIdArray.splice(lessonIdIndex, 1);
      this.setState({
        lessonIds: lessonIdArray,
      });
    } else {
      lessonIdArray.push(lessonId);
      this.setState({
        lessonIds: lessonIdArray,
      });
    }
    this.props.sendResourceDetails(lesson._id, lesson.type);
  };

  componentWillReceiveProps(next) {
    if (!next.closeStatus) {
      this.setState({ lessonIds: [] });
    }
    if (next.focus) {
      this.setFocus();
    }
    if (next.getSearchNextPage && this.state.nextPage !== -1) {
      // Setting searchNextPage to false
      setTimeout(() => {
        this.props.setNoSearchNextPage();
        this.fetchResources(this.state.searchString, true);
      }, 1000);
    }
  }

  navigateToDetails = (lesson) => {
    if (lesson.type === "Resource") {
      browserHistory.push("/resources/" + lesson._id);
    } else if (lesson.type === "Book") {
      browserHistory.push("/books/" + lesson._id);
    } else if (lesson.type === "Lesson") {
      browserHistory.push("/lessons/" + lesson._id);
    } else if (lesson.type === "Unit") {
      browserHistory.push("/units/" + lesson._id);
    } else if (lesson.type === "Assessment") {
      browserHistory.push("/assessments/" + lesson._id);
    } else {
      browserHistory.push("/");
    }
  };

  searchResources(e) {
    let searchString = e.target.value.trim();
    if (searchString.length >= 2) {
      this.setState({ searchString });
      this.fetchResources(searchString);
    } else {
      this.handleSearchClear();
    }
  }

  _loadMore() {
    setTimeout(() => {
      if (this.state.totalCount > this.state.resources.length) {
        this.setState({ pageNumber: this.state.pageNumber + 1 });
        this.fetchResources(this.state.searchString);
      } else {
        this.setState({
          hasMore: false,
        });
      }
    }, 1000);

    if (this.pageLoad) {
      this.fetchResources(this.state.searchString);
      this.pageLoad = false;
    }
  }

  renderFilter = () => {
    if (localStorage.is_lite === "true") {
      return (
        <div>
          <div
            className="icon-icon-search global-textbox-icon-search"
            style={{ color: "#9B9B9B" }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div
            className={
              localStorage.user_type !== "Student"
                ? "icon-icon-dropdownsearch global-textbox-icon-search"
                : "icon-icon-search global-textbox-icon-search"
            }
            style={{ color: this.state.showOptions ? "#009BBD" : "#9B9B9B" }}
          />
        </div>
      );
    }
  };

  clearFilter = () => {
    // Ger number of strand via converting text
    let strandNumber = constants.convertToStrandNumber(
      this.props.params.strand
    );
    let typeOfResource;

    // get type of resource if it does not exist in redux action
    if (!this.props.typeOfResource) {
      let pathName = this.props.location.pathname;
      typeOfResource = pathName.includes("teaching-resources")
        ? "teachingResources"
        : pathName.includes("books")
        ? "books"
        : pathName.includes("units")
        ? "units"
        : pathName.includes("lessons")
        ? "lessons"
        : pathName.includes("assessments")
        ? "assessments"
        : "";

      // Call Backend

      switch (typeOfResource) {
        case "teachingResources":
          this.props.getResourcesAction(1, strandNumber);
          this.props.addingFiltersAction([]);
          this.props.clearAllFiltersAction(true);
          break;

        default:
          this.props.getCustomFiltersAction(
            strandNumber,
            this.props.typeOfResource,
            ""
          );
          break;
      }
    } else {
      // Call Backend
      this.props.getCustomFiltersAction(
        strandNumber,
        this.props.typeOfResource,
        ""
      );
    }
  };

  render() {
    let pathName = this.props.location.pathname;
    let searchText = pathName.includes("resources")
      ? this.props.isLibrary
        ? "Search Library Resources..."
        : "Search All Resources..."
      : pathName.includes("books")
      ? this.props.isLibrary
        ? "Search Library Texts..."
        : "Search All Texts..."
      : pathName.includes("units")
      ? this.props.isLibrary
        ? "Search Library Units..."
        : "Search All Units..."
      : pathName.includes("assessments")
      ? this.props.isLibrary
        ? "Search Library Assessments..."
        : "Search All Assessments..."
      : pathName.includes("lessons")
      ? this.props.isLibrary
        ? "Search Library Lessons..."
        : "Search All Lessons..."
      : this.props.isLibrary
      ? "Search Library..."
      : "Search Everything...";
    return (
      <div>
        <div className="new-global-search-component">
          <div className="global-searchbar-textbox">
            {pathName.endsWith("browse") || pathName.endsWith("library") ? (
              <div />
            ) : (
              <div className="global-search-heading">
                <p>Search & Filter</p>
                {!this.props?.isFolder && (
                  <div
                    className="global-clear-filter"
                    onClick={this.props.clearAndCloseFilters}
                  >
                    <span>X</span> Clear Filters
                  </div>
                )}
              </div>
            )}
            {localStorage.user_type !== "Student" && this.renderFilter()}
            {localStorage.user_type === "Student" && (
              <div>
                <div
                  className="icon-icon-search global-textbox-icon-search"
                  style={{ color: "#009BBD" }}
                />
              </div>
            )}

            <DebounceInput
              minLength={2}
              debounceTimeout={300}
              type="text"
              ref={(e) => (this.gscInput = e)}
              className="global-unit-searchbar"
              placeholder={
                this.state.queryItems.length < 39
                  ? this.state.queryItems
                  : searchText
              }
              onChange={this.searchResources.bind(this)}
              value={this.state.searchString}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default connect(null, actions)(withRouter(NewGlobalSearchComponent));
