import React from 'react'
import {Link} from 'react-router'
import authAPI from '../api/authAPI'
import * as actions from '../redux/actions'
import {connect} from 'react-redux'
import RemovePopOver from '../components/Shared/RemoveItemPopOver'

class MyEventWrapper extends React.Component {

    constructor(props) {
       super(props);
       this.state = {
           hide:false
       };
    }

    removeItem=(event)=>{
       let self =this;
       authAPI.removePlannerItem(event._id)
       .then((response) => {
            if (response.status === 200) {
                self.props.getScheduledTasksAction("grid",event.start);
            } else {
                console.log('failure');
            }
        })
        .catch(function (error) {
            console.log("exception occured",error);
        });
    };

    render () {
        if(this.props.event.title === 'Add Item') {
            return null;
        }
        let lesson_link ='/home';
        if(this.props.event.title === 'Add Item') {
            lesson_link = '/home';
        }
        else if(this.props.event.item_type === "Book" || this.props.event.item_type === "Assessment") {
            lesson_link = '/' + this.props.event.item_type.toLowerCase() + 's/' + this.props.event.item_id;
        }
        else if(this.props.event.item_type === "Correlations"){
            lesson_link = '/' + this.props.event.item_type.toLowerCase() + '/' + this.props.event.item_id;
        }
        else {
            lesson_link = '/' + this.props.event.item_type.toLowerCase() + 's/' + this.props.event.item_id;
        }
        return (
            <Link to={lesson_link} className='planner-item-link'>
                <div className={this.props.event.title === 'Add Item'? "my-event-wrapper-new-item": "my-event-wrapper"}
                     style={{display:this.state.hide?'none':''}}
                >
                    <RemovePopOver stopPropagation={true}
                                   preventDefault={true}
                                   className='planner-remove'
                                   popOverId={"popOverRemove" + this.props.event._id}
                                   removeObj={this.props.event}
                                   remove={this.removeItem}
                    />
                        <div className={this.props.event.title === 'Add Item'? "title-add-item": "title planner-item-title"}>
                            {this.props.event.title}
                        </div>
                        {this.props.event.desc &&
                            <div className="desc planner-item-details">
                                {this.props.event.desc}
                            </div>
                        }
                </div>
            </Link>
        )
    }
}

export default connect(null, actions)(MyEventWrapper)
