import {CHECK_HEADING,CHECK_DESCRIPTION} from '../actions'


const initialState={};

const CheckUnitReducer=(state=initialState,action)=>{
    switch(action.type){
       
        case CHECK_HEADING:
            return Object.assign({}, state, {headingStatus:action.payload});
        case CHECK_DESCRIPTION:
            return Object.assign({}, state, {descriptionStatus:action.payload});
        default:
            return state;
    }
}

export default CheckUnitReducer;

