import React, {Component} from 'react'
import {Row, Col, Modal} from 'react-bootstrap'
import authAPI from '../../api/authAPI'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import debounce from 'debounce'
import InfiniteScroll from 'react-infinite-scroll-component'
import {browserHistory} from 'react-router'
import { Alert } from 'react-bootstrap'
import { GoogleLogin } from 'react-google-login';
import gsuiteImage from '../../assets/images/GsuiteButton.png'

class AddGroup extends Component {

    constructor(props){
        super(props);
        this.state={
            searchTerm: "",
            addStudents: [],
            additionalStudents:[],
            addedStudents: [],
            groupName: "",
            groupNameValid: "",
            modal:false,
            classroomModal:false,
            studentEmail:"",
            success:false,
            existingStudents: null,
            errMsg:"",
            userData:[],
            googleCode:null,
            courseData:[],
            studentDataByCourse:[],
            courseStudents:[],
            selectedCourseStudents:[],
            selectedClassName: ''
        };
        this.search = debounce(this.search,100);
    }

    addToGroup = (sid)=>{
        for(let i=0;i<this.state.addStudents.length;i++){
           if (sid === this.state.addStudents[i]){
               this.state.addStudents.splice(i,1);
               this.setState({addStudents: this.state.addStudents})
               return;
           }
        }
        this.state.addStudents.push(sid);
        this.setState({addStudents: this.state.addStudents}) ;
    };

    deleteStudent = (sid) =>{        
        this.studentDelete(sid);
    };

    studentDelete = (sid)=>{
        let self = this;
        authAPI.deleteStudentUser(sid).then((resp)=>{
            self.props.getUsersAction("Student","",0,1,0,0);
            self.setState({
                searchTerm:""
            }) 
        });
    }

    createGroup = ()=>{

        if(this.state.groupName === "") {
            this.setState({groupNameValid: "Group name cannot be blank."});
            return;
        }
        else if (this.setState.groupNameValid !== ""){
            this.setState({groupNameValid: ""});
        }
        let postObject={"name": this.state.groupName,
                        "user_ids": this.state.addStudents};
        this.props.postGroupAction(postObject)
            .then((response)=>{

                if(response.statusCode === 201){
                    //console.log("group created");
                    browserHistory.push('/my-class');

                }
                else{
                    console.log("error occured");
                    browserHistory.push('/my-class');

                }
            })
            .catch(function (error) {
                console.log("exception occured",error);
                browserHistory.push('/my-class');
            });
    };

    changeFunction= (event)=>{
        let val = event.target.value;
        console.log("val"+val);
        this.setState({searchTerm: val});
        this.search(val);
    };

    setGroupName= (event)=>{
        this.setState({groupName: event.target.value});
    };

    search=(val)=>{
       this.props.getUsersAction("Student",val,0,1,0,0);
    };

    componentDidMount(){
        this.props.getUsersAction("Student","",0,1,0,0);
    }

    addAdditionalStudent = ()=>{
        this.setState({
            modal:true
        })
    }

    importClassroomStudent = ()=>{
        this.setState({
            classroomModal: true
        })
    }

    modalController = ()=>{
        this.setState({
            modal:false
        })
    }

    classroomModalController = ()=>{
        this.setState({
            classroomModal:false
        })
    }

    onGoogleSuccess = async (resp)=>{
        //console.log(resp.accessToken);    
        // let accessToken = resp.accessToken;
        //console.log(accessToken);
        let googleData = [];
        let self = this;
        self.setState({
            googleCode : resp.code
        })
        googleData.push({
            clientId:"284908834236-e1q6k1h7h580cacemp4kmuklk6fimjsc.apps.googleusercontent.com",
            //clientId: "168489875119-ir4fgo79okl6idedqiq8jbc452mjjr7r.apps.googleusercontent.com",
            //clientId:"144830858043-f28enmbd45di92rhjo4b6kr5otm2o036.apps.googleusercontent.com",
            clientSecret:"KBbGqX7KSoDY2BYyiw8zyIAg",
            //clientSecret: "O3VD2e_Nah3lWiwjjlIamBis",
            //clientSecret:"IgzJGfnsWQqR0EMHQJqxAYNu",
            refreshToken: resp.code
        })
        authAPI.fetchGoogleClasses(googleData).then((resp)=>{
            console.log(resp.data);
            if(resp.data.status != undefined && resp.data.status == 201){
                document.getElementById('errMsg').innerText = resp.data.message;
                setTimeout(()=>{
                    document.getElementById('errMsg').innerText = '';
                },5000);
            }else{
                let data = resp.data;
                let courseStudents = [];
                data.forEach((item,index)=>{
                    courseStudents[item.id]=item.students;
                });
                self.setState({
                    courseData: resp.data,
                    studentDataByCourse: courseStudents
                })
            }
            

        }).catch((err)=>{
            console.log(err);
        });
    }

    viewStudentByCourse= (e,courseId,courseDescription)=>{
        document.getElementById('successMsg').innerText = '';
        let clist=document.getElementsByClassName("gcStudentCheck");
            for (var i = 0; i < clist.length; ++i) { clist[i].checked = false; }
        this.setState({
            selectedCourseStudents : [],
            selectedClassName: courseDescription
        });
        

        let studentsData = this.state.studentDataByCourse[courseId];
        console.log(studentsData);
        if(studentsData && studentsData.length > 0){
            this.setState({
                courseStudents: studentsData
            });
            if(document.getElementById("classroomStudentSelectAll"))
                document.getElementById("classroomStudentSelectAll").checked = false;
        }else{
            this.setState({
                courseStudents: []
            });
            alert("There are no students associated with the course.");
        }
    }

    addStudentToSchoolwide = (e,student)=>{

        /**
         * To-do add checking for checkbox checked or not
         * based upon that uncheck select all if selected
         * remove selected student data from the state
         */
        console.log(e);
        console.log(e.target.checked);
        console.log(student);
        let selectedStudents = [];
        if(this.state.selectedCourseStudents && this.state.selectedCourseStudents.length > 0){
            selectedStudents = this.state.selectedCourseStudents;
        }
        if(e.target.checked){
            selectedStudents.push({
                course_id : student.courseId,
                email_id  : student.profile.emailAddress,
                first_name: student.profile.name.givenName,
                last_name : student.profile.name.familyName
            });
        }else{
            selectedStudents = selectedStudents.filter(function( obj ) {
                return obj.email_id !== student.profile.emailAddress;
            });
            if(document.getElementById("classroomStudentSelectAll"))
                document.getElementById("classroomStudentSelectAll").checked = false;
        }        

        this.setState({
            selectedCourseStudents : selectedStudents
        });
    }

    addCourseStudent = ()=>{
        document.getElementById('successMsg').innerText = '';
        document.getElementById('errMsg').innerText = '';
        let selectedStudents = this.state.selectedCourseStudents;
        if(this.state.selectedCourseStudents.length > 0){
            let self = this;

            authAPI.postImportStudents(this.state.selectedCourseStudents).then((resp)=>{
                console.log(resp);
                if(resp.status == 200){
                    let existingStudents = resp.data.existingEmailIds;
                    console.log(existingStudents);
                    let existingStudentsArr = existingStudents.split(',');
                    console.log("existing students");
                    console.log(existingStudentsArr);
                    let notExistingStudents = [];
                    if(resp.data.existingEmailIds != "" && existingStudentsArr.length > 0){
                        console.log("in if loop");
                        console.log(selectedStudents);
                        //exclude these email id from additional students
                        selectedStudents.forEach((el)=>{
                            console.log(el.emailId);
                            if(!existingStudentsArr.includes(el.email_id)){
                                console.log("in if");
                                notExistingStudents.push(el.email_id);
                            }
                        });
                        let msg = '';
                        console.log(notExistingStudents);
                        if(notExistingStudents.length > 0){
                            msg+=resp.data.message;
                        }
                        if(existingStudentsArr.length > 0){
                            msg+=resp.data.existingEmailIds+" are already in database.";
                        }
                        
                        document.getElementById('successMsg').innerText = msg;
                        setTimeout(()=>{
                            document.getElementById('successMsg').innerText = '';
                        },5000);
                    }else{
                        document.getElementById('successMsg').innerText = resp.data.message;
                        setTimeout(()=>{
                            document.getElementById('successMsg').innerText = '';
                        },5000);
                    }
                }
                
            });
        }else{
            document.getElementById('errMsg').innerText = 'Please select student first.';
            setTimeout(()=>{
                document.getElementById('errMsg').innerText = '';
            },1000);
        }
        let clist=document.getElementsByClassName("gcStudentCheck");
            for (var i = 0; i < clist.length; ++i) { clist[i].checked = false; }
        this.setState({
            selectedCourseStudents : []
        })
    }

    selectAllClassroomStudents = (e,studentData)=>{
        if(document.getElementById("classroomStudentSelectAll").checked){
            let students = [];
            studentData.forEach((item,index)=>{
                students.push({
                    course_id : item.courseId,
                    email_id  : item.profile.emailAddress,
                    first_name: item.profile.name.givenName,
                    last_name : item.profile.name.familyName
                });
            });
            let clist=document.getElementsByClassName("gcStudentCheck");
            for (var i = 0; i < clist.length; ++i) { clist[i].checked = true; }
            this.setState({
                selectedCourseStudents : students
            });
        }else{
            console.log("in else of select all");
            let clist=document.getElementsByClassName("gcStudentCheck");
            for (var i = 0; i < clist.length; ++i) { clist[i].checked = false; }
            this.setState({
                selectedCourseStudents : []
            });
        }
    }

    getInputValue = (event)=>{
        this.setState({
            studentEmail : event.target.value
        })
        console.log(this.state.studentEmail);
    }

    setAdditionalStudentData = () =>{
        let studentFirstName = document.getElementById('studentFirstName').value;
        let studentLastName = document.getElementById('studentLastName').value;
        let emailId = document.getElementById('studentEmail').value;
        let additionalStudents = this.state.additionalStudents;
        if(studentFirstName == '' || studentLastName == '' || emailId == ''){
            this.setState({
                errMsg : "All fields must be filled out"
            });
        }else if(emailId.includes("\,")){
            this.setState({
                errMsg : "Multiple entries not allowed"
            });
        }else if(additionalStudents.some(el => el.email_id === emailId)){
            this.setState({
                errMsg : emailId+" email Id already used for another user"
            });
        }else{
            this.setState({
                errMsg : ""
            });
            document.getElementById('studentFirstName').value = "";
            document.getElementById('studentLastName').value = "";
            document.getElementById('studentEmail').value = "";

            
            if(studentFirstName != '' && studentLastName != '' && emailId != ''){
                additionalStudents.push({
                    first_name : studentFirstName,
                    last_name : studentLastName,
                    email_id: emailId
                });
                this.setState({
                    additionalStudents : additionalStudents
                });
            }    
        }

        console.log(this.state.errMsg);
        
    }

    removeStudentEmail = (e,emailId) =>{
        let modifiedStudentArr = [];
        let self = this;
        let additionalStudents = this.state.additionalStudents;
        additionalStudents.forEach((stuData,index)=>{
            if(stuData.email_id !== emailId){
                modifiedStudentArr.push({
                    first_name : stuData.first_name,
                    last_name : stuData.last_name,
                    email_id: stuData.email_id
                })
            }

            if(index == additionalStudents.length -1){
                self.setState({
                    additionalStudents : modifiedStudentArr
                })
            }

        })
    }

    setAdditionalStudent = () =>{
        if(this.state.additionalStudents.length > 0){
            //save the students for this user for this district id
            authAPI.postImportStudents(this.state.additionalStudents).then((resp)=>{
                if(resp.status == 200){
                    this.setState({
                        modal:false,
                        success:true,
                        existingStudents :resp.data.existingEmailIds
                    });
                    let existingStudentsArr = this.state.existingStudents.split(',');
                    let notExistingStudents = [];
                    if(existingStudentsArr.length > 0){
                        //exclude these email id from additional students
                        let addlStu = this.state.additionalStudents;
                        addlStu.forEach((el)=>{
                            if(!existingStudentsArr.includes(el.email_id)){
                                notExistingStudents.push({
                                    first_name:el.first_name,
                                    last_name:el.last_name,
                                    email_id:el.email_id
                                });
                            }
                        });
                    }
                    

                    this.setState({
                        addedStudents: notExistingStudents
                    });
                    setTimeout(()=>{this.setState({
                        success:false,
                        additionalStudents:[],
                        addedStudents:[]
                    });
                    this.props.getUsersAction("Student",this.state.searchTerm,0,1,0,0);
                    },5000)
                }
            })
        }
    }

    render() {
        let height=window.innerHeight;
        let width =window.innerWidth; 
        let mobileWidth='576';
        console.log(this.state.courseData);
        return (
            <div>
                <Col md={12} sm={12} className="add-group-header">
                    <div className="add-group">
                        ADD GROUP
                    </div>
                </Col>
                <div>
                    <Col md={3} sm={3}/>
                    <Col md={6} sm={6} className="inner-add-group-content">
                    <div>
                        <div style={{display: "inine-block",marginBottom: 10}}>
                            GROUP NAME <span style={{color:"orange"}}>*</span>
                        </div>
                        <div>
                            <input type="text" onChange={this.setGroupName} className={this.state.groupNameValid === ""?"group-name":"add-user-form add-user-form-error"} />
                        </div>
                        {this.state.groupNameValid !== "" &&
                            <div className="add-group-error">
                                {this.state.groupNameValid}
                            </div>
                        }
                        {/*DESCRIPTION:<div ><textarea type="text" style={{width: "100%",marginBottom: 20}}></textarea></div>*/}
                        <div style={{display: "inine-block",marginBottom: 10}}>
                            CHOOSE STUDENT
                        </div>
                        <div>
                            <span className="search-student icon-icon-search"/>
                            <input type="text"  placeholder="Search for Student" onChange={this.changeFunction} value={this.state.searchTerm} className="search-by-student" />
                        </div>
                        <Row>
                            <Col xs={1} md={2} sm={1}/>
                            <Col md={8} sm={10}>
                                <div style={{textAlign: "center"}}>
                                    <button className="button-students">
                                        STUDENTS
                                    </button>
                                    {(localStorage.user_type == 'Individual Teacher' || localStorage.user_type == 'Unlimited' || localStorage.user_type == 'Print Only' || localStorage.user_type === 'System Admin') && 
                                    <button className="button-additional" onClick={this.addAdditionalStudent}>
                                        ADD ADDITIONAL STUDENTS
                                    </button>
                                    }
                                    {(localStorage.user_type == 'Individual Teacher' || localStorage.user_type == 'Unlimited' || localStorage.user_type == 'Print Only' || localStorage.user_type === 'System Admin') && 
                                    <button className="button-additional" onClick={this.importClassroomStudent}>
                                        Google Classroom
                                    </button>
                                    }
                                </div>
                            </Col>
                            <Col xs={1} md={2} sm={1}/>
                        </Row>
                        {!this.props.users.page &&
                            <div style={{fontFamily: "ProximaNova",color:"#9B9B9B",fontSize: 15,fontWeight: 600}}>
                            There are no students that meet that search criteria.
                            </div>
                        }
                        </div>
                        <InfiniteScroll  height={height-385+'px'}
                                         next={()=>this.props.getUsersAction("Student",this.state.searchTerm,0,this.props.users.page +1,0)}
                                         hasMore={this.props.users.hasMore["Student"]}
                                         loader={<Row>Loading...</Row>}
                                         dataLength={this.props?.users?.data?.Student?.results?.length ?? 0}
                            >
                            {this.props?.users?.data?.Student?.results?.length && this.props.users.data.Student.results.map((student,i)=>
                                <Row id={student._id}>
                                    <span>
                                        <i className="icon-icon-user my-class-add-group-user-icon" />
                                    </span>
                                    {i!=0 && <div className="my-class-add-group-border"/>}
                                    <Col md={8} sm={9} xs={8}>
                                        <Row  className="student-name">{student.first_name && <span>{student.first_name}</span>}
                                            {student.last_name && <span> {student.last_name}</span>}
                                        </Row>
                                        <Row style={{marginTop: 3}} className="student-sub-text">
                                            {student.username && student.username !== "" && student.username !== " " && 
                                                <span>USERNAME: {student.username} </span>
                                            }
                                            <br className="visible-xs visible-sm visible-md"/>{student.user_id_number && student.user_id_number !== "" && student.user_id_number !== " " && <span className="hidden-xs hidden-sm hidden-md">&nbsp;&#8226;&nbsp;</span>}
                                            {student.user_id_number && student.user_id_number !== "" && student.user_id_number !== " " && <span> ID: {student.user_id_number}</span>}
                                        </Row>
                                    </Col>
                                    {student.created_by == localStorage.getItem('user_id') &&
                                        <span>
                                            <i onClick={() => { if (window.confirm('Are you sure you wish to delete this student?')) this.deleteStudent(student._id) } } className="remove-student-solid-icon icon-icon-close-circle-solid"/>
                                        </span>
                                    }
                                    <span>
                                        <i onClick={()=>{this.addToGroup(student._id)}} className={ this.state.addStudents.indexOf(student._id)!== -1 ? "remove-student-solid-icon icon-icon-close-circle-solid" :"add-student-solid-icon icon-icon-add-circle-solid"}/>
                                    </span>
                                </Row>
                            )}
                        </InfiniteScroll>
                    </Col>
                    <Col md={3} sm={3}/>
                    <Modal id="start-tour-modal" bsSize="lg" show={this.state.modal} style={{'position':'relative','top':'23rem'}}>
                       <Modal.Body>
                            {/*<Row className='custom-modal' style={{'position':'relative','bottom':'4rem','left':'0'}}>                                   
                                <button type="button" className="close" aria-label="Close" onClick={this.modalController}><span aria-hidden="true">&times;</span></button>
                            </Row>*/}
                            <Row style={{marginTop: 3}}>
                                <i className="my-class-add-user icon-blue icon-icon-add-user-circle-outline" style={{"float":"left","marginTop":"-10px","fontSize":"55px"}}></i>
                                <span style={{"color":"#000","fontFamily":"ProximaNova","fontSize":"14px"}}>Add Students</span><br/><span style={{"color":"#000","fontFamily":"ProximaNova","fontSize":"12px"}}>For the bulk addition of students, please speak with your Fundamentals School Administrator or contact Schoolwide’s Customer Success team at <a href="mailto:customersuccess@schoolwide.com">customersuccess@schoolwide.com.</a></span>
                                {this.state.errMsg !="" &&
                                    <div id="errMsg" style={{"color":"red","fontFamily":"ProximaNova","fontSize":"22px","marginLeft":"38px"}}>{this.state.errMsg}</div>
                                }
                                <div style={{"clear":"both"}}>
                                    <input type="text" id="studentFirstName" style={{"border":"none","background":"#ddd",padding:"5px","borderBottom":"7px solid #279bbd","width":"40%","marginTop": "25px","marginLeft": "35px"}} placeholder="First Name"/>
                                    <input type="text" id="studentLastName" style={{"border":"none","background":"#ddd",padding:"5px","borderBottom":"7px solid #279bbd","width":"45%","marginTop": "25px","marginLeft": "35px"}} placeholder="Last Name"/>
                                </div>
                                <div>
                                    <input type="text" id="studentEmail" style={{"border":"none","background":"#ddd",padding:"5px","borderBottom":"7px solid #279bbd","width":"90%","marginTop": "25px","marginLeft": "35px"}} placeholder="Email Address"/>
                                    <i className="add-student-solid-icon icon-icon-add-circle-solid" onClick={this.setAdditionalStudentData} style={{marginTop:"28px"}}></i>
                                </div>
                                <div style={{height:"300px",marginTop:"11px",marginLeft:"35px",marginRight:"50px","background":"#ddd","overflowY":"scroll"}}>
                                    <ul className="additionalStudentList" style={{"listStyleType":"none"}}>
                                        {this.state.additionalStudents && this.state.additionalStudents.map((user)=>
                                            <li style={{padding: "4px"}}>
                                                <span style={{color: "#6c6c6c",fontSize:"17px",marginLeft: "-36px"}}>{user.first_name}&nbsp;{user.last_name}</span>
                                                <span style={{color: "#6c6c6c",fontSize:"17px","marginLeft":"220px"}}>{user.email_id}</span>
                                                <span style={{borderRadius:"2px solid red",background:"red",color:"#fff",float:"right",cursor:"pointer",borderRadius:"50%",paddingLeft:"8px",paddingTop:"3px",height:"25px",width:"25px"}} onClick={(e)=>this.removeStudentEmail(e,user.email_id)}>X</span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                {/*<textarea id="studentEmail" style={{"border":"none","background":"#ddd",padding:"5px","borderBottom":"7px solid #279bbd","width":"90%","marginTop": "25px","marginLeft": "35px","height": "350px","resize":"none"}} placeholder="Add students to add to class"></textarea>*/}
                                <button onClick={this.modalController} className="cancel-add-group">
                                    CANCEL
                                </button>
                                <button className="add-add-group" onClick={this.setAdditionalStudent}>
                                    ADD
                                </button>
                            </Row>
                       </Modal.Body>
                    </Modal>

                    <Modal id="start-tour-modal" bsSize="lg" show={this.state.classroomModal} style={{'position':'relative','top':'23rem'}}>
                       <Modal.Body>
                            {/*<Row className='custom-modal' style={{'position':'relative','bottom':'4rem','left':'0'}}>                                   
                                <button type="button" className="close" aria-label="Close" onClick={this.modalController}><span aria-hidden="true">&times;</span></button>
                            </Row>*/}
                            <Row style={{marginTop: 3}}>
                                <i className="my-class-add-user icon-blue icon-icon-add-user-circle-outline" style={{"float":"left","marginTop":"-10px","fontSize":"55px"}}></i>
                                <span style={{"color":"#000","fontFamily":"ProximaNova","fontSize":"14px"}}>Add Students</span><br/><span style={{"color":"#000","fontFamily":"ProximaNova","fontSize":"12px"}}>For the bulk addition of students, please speak with your Fundamentals School Administrator or contact Schoolwide’s Customer Success team at <a href="mailto:customersuccess@schoolwide.com">customersuccess@schoolwide.com.</a></span>
                                <div id="errMsg" style={{"color":"red","fontFamily":"ProximaNova","fontSize":"22px","marginLeft":"38px","overflowWrap":"break-word"}}></div>
                            
                                <div id="successMsg" style={{"color":"green","fontFamily":"ProximaNova","fontSize":"22px","marginLeft":"38px","overflowWrap":"break-word"}}></div>
                                <div style={{"textAlign":"center"}}>
                                {/* render={renderProps => (
                                        <button onClick={renderProps.onClick} style={{border: "1px solid #efefef",borderRadius:"5px"}}>
                                            <img src={gsuiteImage} style={{width:"175px"}}/>
                                        </button>
                                    )} */}
                                <GoogleLogin 
                                    clientId="284908834236-e1q6k1h7h580cacemp4kmuklk6fimjsc.apps.googleusercontent.com"
                                    //clientId="168489875119-ir4fgo79okl6idedqiq8jbc452mjjr7r.apps.googleusercontent.com"
                                    //clientId="144830858043-f28enmbd45di92rhjo4b6kr5otm2o036.apps.googleusercontent.com"
                                    render={renderProps => (
                                        <button onClick={renderProps.onClick} style={{border: "1px solid #efefef",borderRadius:"5px"}}>
                                            <img src={gsuiteImage} style={{width:"175px"}}/>
                                        </button>
                                    )}
                                    buttonText="Login"
                                    accessType="offline"
                                    approvalPrompt="force"
                                    prompt='consent'
                                    scope="openid
                                    https://www.googleapis.com/auth/classroom.courses.readonly
                                    https://www.googleapis.com/auth/classroom.rosters.readonly
                                    https://www.googleapis.com/auth/classroom.profile.emails
                                    https://www.googleapis.com/auth/classroom.profile.photos"
                                    onSuccess={this.onGoogleSuccess}
                                    cookiePolicy="single_host_origin"                                    responseType="code"
                                    isSignedIn={false}
                                    //scopes = "https://www.googleapis.com/auth/classroom.rosters https://www.googleapis.com/auth/classroom.courses https://www.googleapis.com/auth/classroom.coursework.me https://www.googleapis.com/auth/classroom.coursework.students https://www.googleapis.com/auth/classroom.profile.emails"
                                />                                
                                </div>
                                {this.state.courseData && this.state.courseData.length > 0 && <div id="course-list">
                                    <table style={{"width":"50%","marginLeft":"auto","marginRight":"auto","border":"1px solid #808080"}}>
                                        <thead>
                                            <tr style={{"border":"1px solid #808080"}}>
                                                <th style={{"border":"1px solid #808080","padding":"2px 4px"}}>Course Name</th>
                                                <th style={{"border":"1px solid #808080","padding":"2px 4px"}}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.courseData.map((course,i)=>
                                            <tr style={{"border":"1px solid #808080"}}>
                                                <td style={{"border":"1px solid #808080","padding":"2px 4px"}}>{course.descriptionHeading}</td>
                                                <td style={{"border":"1px solid #808080","padding":"2px 4px"}}><button onClick={()=>{this.viewStudentByCourse(this,course.id,course.descriptionHeading)}}>View Students</button></td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>}
                                {this.state.selectedClassName &&
                                    <h4 style={{"padding": "12px 0","marginLeft": "35px","color": "#4d9cbe"}}>Showing student list from {this.state.selectedClassName}</h4>
                                }
                                {this.state.courseStudents && this.state.courseStudents.length > 0 ? <div style={{height:"300px",marginTop:"11px",marginLeft:"35px",marginRight:"50px","background":"#ddd","overflowY":"scroll"}}>
                                        <input type="checkbox" value="all" id="classroomStudentSelectAll" onChange={(e)=>this.selectAllClassroomStudents(e,this.state.courseStudents)}/>Select All
                                        <ul className="classroomStudents" style={{"listStyleType":"none"}}>
                                            <li style={{padding: "4px"}}>
                                                <span style={{float:"right",color: "#6c6c6c",fontSize:"17px"}}>Action</span>
                                                <span style={{color: "#6c6c6c",fontSize:"17px",marginLeft: "-36px"}}>Name</span>
                                                <span style={{color: "#6c6c6c",fontSize:"17px","marginLeft":"220px"}}>Email</span>
                                            </li>
                                            {this.state.courseStudents.map((user)=>
                                                <li style={{padding: "4px"}}>
                                                    <span style={{float:"right"}}>
                                                        <input type="checkbox" value={user.profile.emailAddress} class="gcStudentCheck" onClick={(e)=>this.addStudentToSchoolwide(e,user)}/>
                                                    </span>
                                                    <span style={{color: "#6c6c6c",fontSize:"17px",marginLeft: "-36px"}}>{user.profile.name.fullName}</span>
                                                    <span style={{color: "#6c6c6c",fontSize:"17px","marginLeft":"134px"}}>{user.profile.emailAddress}</span>
                                                </li>
                                            )}
                                        </ul>                                        
                                    </div> : <div style={{height:"300px",marginTop:"11px",marginLeft:"35px",marginRight:"50px","background":"#ddd","overflowY":"scroll"}}>
                                        No students available.
                                    </div>
                                }
                                
                                {/*<textarea id="studentEmail" style={{"border":"none","background":"#ddd",padding:"5px","borderBottom":"7px solid #279bbd","width":"90%","marginTop": "25px","marginLeft": "35px","height": "350px","resize":"none"}} placeholder="Add students to add to class"></textarea>*/}
                                <button className="add-add-group" onClick={this.addCourseStudent} style={{"marginTop":"42px"}}>
                                    ADD Student
                                </button>
                                <button onClick={this.classroomModalController} className="cancel-add-group" style={{"marginLeft":"407px","marginTop":"42px"}}>
                                    CANCEL
                                </button>                                
                            </Row>
                       </Modal.Body>
                    </Modal>
                    

                    {this.state.success &&
                        <div className="single-select-popover folder-success-popver" style={{width:"35%"}}>
                            <div className="item-added" style={{"width":"auto !important"}}>
                                {/*<div style={{marginBottom: 10}} className="selected-checkbox-icon-add-multiple icon-icon-checkbox"/>*/}
                                <div style={{textAlign: "left"}}>
                                    {this.state.addedStudents && 
                                        <ul style={{listStyleType:"none"}}>
                                        {this.state.addedStudents.map((user)=>
                                            <li style={{padding: "4px"}}>
                                                <p style={{color: "#000",fontSize:"14px"}}>{user.first_name}&nbsp;{user.last_name}</p>
                                                <p style={{color: "#6c6c6c",fontSize:"17px"}}>USERNAME:{user.email_id}</p>
                                            </li>
                                        )}
                                        </ul>
                                    }
                                    {this.state.addedStudents.length > 0 &&
                                        <strong style={{color:"green","marginLeft":"25%"}}>Have been successfully added to the class</strong> 

                                    }
                                    {this.state.existingStudents.length>0 && 
                                        <p><strong>{this.state.existingStudents} are skipped as already exists</strong></p>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="add-group-footer">
                    <button onClick={()=>{browserHistory.push('/my-class')}} className="cancel-add-group">
                        CANCEL
                    </button>
                    <button onClick={()=>{this.createGroup()}} className="add-add-group">
                        ADD
                    </button>
                </div>
            </div>
        );
    }
}



const mapStateToProps = (state) => {
    return ( {users : state.Users

    });
};

export default connect(mapStateToProps, actions)(AddGroup)