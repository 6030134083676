import React, {Component} from 'react'
import {Col, Row} from 'react-bootstrap'

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const weekdays=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

class DateComponent extends Component{
    render() {
        let showDeleteButton = this.props.userType == 'Student' ? false : true;
        let clName = this.props.collapseDateList.indexOf(new Date(this.props.day).toDateString()) !== -1 ? "list-view-arrow-right icon-arrow-solid-right" : "list-view-arrow-down icon-arrow-solid-down";
        if(this.props.checkDate === new Date(this.props.day).getDate()){
            return null;
        }
        return (
            <Row>
                <div className="assignment-date-format">
                    <Col xs={showDeleteButton ? 6 : 9}
                         sm={showDeleteButton ? 6 : 9}
                         md={showDeleteButton ? 6 : 9}
                         lg={showDeleteButton ? 7 : 10}
                         className={this.props.checkDate === new Date(this.props.day).getDate()? "hide-assignment-header":"assignment-header"}
                    >
                        <span onClick={()=>{this.props.collapseAssignments(this.props.day)}}
                              style={{float: "left", cursor: "pointer"}}
                        >
                            <i className={clName}/>
                        </span>
                        {weekdays[new Date(this.props.day).getDay()]} {months[new Date(this.props.day).getMonth()]} {new Date(this.props.day).getDate()}, {new Date(this.props.day).getFullYear()}
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={2} id="remove-padding">
                         <Col xs={12}
                                 sm={8} smOffset={2}
                                 md={8} mdOffset={2}
                                 lg={8} lgOffset={2}
                                 className={this.props.i===0 ?"done-header":"hide-done-header"}
                            >
                                {this.props.i===0 &&  ((this.props.selectedTab === "TO-DO" || this.props.selectedTab === "PAST-DUE-ASSIGNMENT") ? <span>Done</span> : <span>Undo</span>)}
                            </Col>
                    </Col>
                    {showDeleteButton && <Col xs={3} sm={3} md={3} lg={2} id="remove-padding">
                         <Col xs={12}
                                 sm={8} smOffset={2}
                                 md={8} mdOffset={2}
                                 lg={8} lgOffset={2}
                                 className="done-header"
                            >
                                Remove
                            </Col>
                    </Col>}
                </div>
            </Row>
        );
    }
}

export default DateComponent
