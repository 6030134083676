import React, {Component} from 'react'
import {Row, Col, Modal} from 'react-bootstrap';
import avatar from '../../assets/images/Avatar.svg';
require('../../assets/styles/trialModal.scss');

export default class TrialModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isTrialModalClosed: false
        };
        this.onTrialModalClose = this.onTrialModalClose.bind(this);
        this.onContactUsClicked = this.onContactUsClicked.bind(this);
        this.dismissTrialModal = this.dismissTrialModal.bind(this);
    }

    showTrialModal() {
        return localStorage.first_time_login !== "true" &&
            localStorage.user_type === "Trial" &&
            !this.state.isTrialModalClosed &&
            (localStorage.trialPopupDismissed === "false");
    }

    dismissTrialModal() {
        localStorage.trialPopupDismissed = "true";
        this.setState({isTrialModalClosed: true})
    }

    onContactUsClicked() {
        // location.href = 'mailto:customerservice@schoolwide.com';
        this.dismissTrialModal();
    }

    onTrialModalClose() {
        this.dismissTrialModal();
    }

    render() {
        return (
            <Modal
                show={this.showTrialModal()}
                className="trialModal"
                backdrop={false}
                animation={false}
                onHide={this.onTrialModalClose}
            >
                <Modal.Header closeButton={true}>
                    <span className="trialModalWelcomeBackSpan">Welcome Back, {this.props.user_name}!</span>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={3}>
                            <div className="trialAvatarContainer">
                                <img src={avatar} alt="assistant"/>
                            </div>
                        </Col>
                        <Col sm={9} className="trialBodyText">
                          <p>Would you like more information about purchasing Fundamentals Unlimited for your school or district? Need more time to evaluate? How about a pilot? We would love to hear from you.</p>
                          <p>Our educational program advisors are available to address any questions or concerns you may have. Just send us a note.</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <span className="trialModalFooterContactUsText">
                        Prefer to talk on the phone? Call 1.800.261.9964 ext. 222
                    </span>
                    <button onClick={this.onContactUsClicked} className='btn trialModalContactUsButton'>
                        Contact Us
                    </button>
                </Modal.Footer>
            </Modal>)
    }
}
