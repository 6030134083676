import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory, withRouter } from "react-router";
import "../../assets/styles/mobile.scss";
import constants from "../../constants";
import * as actions from "../../redux/actions";

class NewResponsiveNavBtns extends Component {
  state = {
    btnOptions: this.props.isLibrary
      ? this.props.type === "Teaching Resources"
        ? [
            {
              name: "reading",
              displayName: "reading",
              color: "green",
              active: false,
            },
            {
              name: "writing",
              displayName: "writing",
              color: "blue",
              active: false,
            },
            {
              name: "language",
              displayName: "language",
              color: "purple",
              active: false,
            },
          ]
        : this.props.type === "Assessments"
        ? [
            {
              name: "reading",
              displayName: "reading",
              color: "green",
              active: false,
            },
            {
              name: "writing",
              displayName: "writing",
              color: "blue",
              active: false,
            },
            {
              name: "language",
              displayName: "language",
              color: "purple",
              active: false,
            },
          ]
        : this.props.type === "Units"
        ? [
            {
              name: "reading",
              displayName: "reading",
              color: "green",
              active: false,
            },
            {
              name: "writing",
              displayName: "writing",
              color: "blue",
              active: false,
            },
            {
              name: "grammar",
              displayName: "grammar",
              color: "orange",
              active: false,
            },
          ]
        : this.props.type === "Lessons"
        ? [
            {
              name: "reading",
              displayName: "reading",
              color: "green",
              active: false,
            },
            {
              name: "writing",
              displayName: "writing",
              color: "blue",
              active: false,
            },
            {
              name: "grammar",
              displayName: "language",
              color: "orange",
              active: false,
            },
          ]
        : []
      : this.props.type === "Teaching Resources"
      ? [
          {
            name: "reading",
            displayName: "reading",
            color: "green",
            active: false,
          },
          {
            name: "writing",
            displayName: "writing",
            color: "blue",
            active: false,
          },
          {
            name: "language",
            displayName: "language",
            color: "purple",
            active: false,
          },
        ]
      : this.props.type === "Assessments"
      ? [
          {
            name: "reading",
            displayName: "reading",
            color: "green",
            active: false,
          },
          {
            name: "writing",
            displayName: "writing",
            color: "blue",
            active: false,
          },
          {
            name: "language",
            displayName: "language",
            color: "purple",
            active: false,
          },
        ]
      : this.props.type === "Units"
      ? [
          {
            name: "reading",
            displayName: "reading",
            color: "green",
            active: false,
          },
          {
            name: "writing",
            displayName: "writing",
            color: "blue",
            active: false,
          },
          {
            name: "language",
            displayName: "grammar",
            color: "orange",
            active: false,
          },
          {
            name: "foundational",
            displayName: "foundational",
            color: "tirquise",
            active: false,
          },
          {
            name: "e-learning",
            displayName: "content literacy",
            color: "blue",
            active: false,
          },
        ]
      : this.props.type === "Lessons"
      ? [
          {
            name: "reading",
            displayName: "reading",
            color: "green",
            active: false,
          },
          {
            name: "writing",
            displayName: "writing",
            color: "blue",
            active: false,
          },
          {
            name: "grammar",
            displayName: "language",
            color: "orange",
            active: false,
          },
        ]
      : [],
  };

  handleOnClick = (index) => {
    let newBtns = this.state.btnOptions;
    const isButtonActive = newBtns[index].active;

    const changedButtons = newBtns.map((btn, i) => {
      if (i === index) {
        const button = {
          ...btn,
          active: !btn.active,
        };
        return button;
      } else {
        return {
          ...btn,
          active: false,
        };
      }
    });

    this.setState({ btnOptions: [...changedButtons] });

    const strandNumber = isButtonActive
      ? 100
      : constants.convertToStrandNumber(newBtns[index]?.name);

    const urlSuffix = isButtonActive
      ? "all"
      : this.state.btnOptions[index].name;

    let typeOfResource =
      this.props.type === "Lessons"
        ? "lessons"
        : this.props.type === "Teaching Resources"
        ? "teachingResources"
        : this.props.type === "Assessments"
        ? "assessments"
        : this.props.type === "Texts" || this.props.type === "Books"
        ? "books"
        : this.props.type === "Units"
        ? "units"
        : "";

    if (
      typeOfResource === "lessons" ||
      typeOfResource === "teachingResources" ||
      typeOfResource === "assessments" ||
      typeOfResource === "texts" ||
      typeOfResource === "units"
    ) {
      this.props.clearAllFilters();
      this.props.closeAllFilters();
      setTimeout(
        () =>
          this.props.getCustomFiltersAction(strandNumber, typeOfResource, ""),
        500
      );
    }

    browserHistory.push(
      (this.props.type === "Teaching Resources"
        ? "/browse/teaching-resources/"
        : this.props.type === "Assessments"
        ? "/browse/assessments/"
        : this.props.type === "Lessons"
        ? "/browse/lessons/"
        : this.props.type === "Units"
        ? "/browse/units/"
        : "/browse/teaching-resources/") + urlSuffix
    );
  };

  handleLibraryOnClick = (index, strandName) => {
    let newBtns = this.state.btnOptions;
    const isButtonActive = newBtns[index].active;

    const changedButtons = newBtns.map((btn, i) => {
      if (i === index) {
        const button = {
          ...btn,
          active: !btn.active,
        };
        return button;
      } else {
        return {
          ...btn,
          active: false,
        };
      }
    });

    this.setState({ btnOptions: [...changedButtons] });

    if (strandName && !isButtonActive) {
      this.props.updateLibraryStrand(
        constants.caseMap[strandName.toLowerCase()],
        strandName
      );
      this.props.clearAllFilters();
      this.props.closeAllFilters();
      setTimeout(
        () =>
          this.props.getCustomLibraryFiltersAction(
            constants.caseMap[strandName.toLowerCase()],
            constants.normalizeTypeName(this.props.type),
            "",
            1,
            true
          ),
        700
      );
    } else {
      this.props.closeAllFilters();
      this.props.clearMyLibraryFiltersAction();
      this.props.getMyLibraryAction(
        constants.normalizeTypeName(this.props.type)
      );
    }
  };

  setActive = (name) => {
    let newBtns = this.state.btnOptions;
    newBtns.map((btn, index) =>
      btn.name === name
        ? (newBtns[index] = {
            ...newBtns[index],
            active: true,
          })
        : ""
    );

    this.setState({ btnOptions: [...newBtns] });
  };

  setInactive = () => {
    if (this.state?.btnOptions) {
      const newBtns = this.state.btnOptions.map(
        (btn) =>
          (btn = {
            ...btn,
            active: false,
          })
      );

      this.setState({ btnOptions: [...newBtns] });
    }
  };

  componentDidMount() {
    const pathname = this.props?.location?.pathname;
    if (pathname) {
      if (pathname.includes("reading")) {
        this.setActive("reading");
      } else if (pathname.includes("writing")) {
        this.setActive("writing");
      } else if (pathname.includes("grammar")) {
        this.setActive("grammar");
      } else if (pathname.includes("foundational")) {
        this.setActive("foundational");
      } else if (pathname.includes("e-learning")) {
        this.setActive("e-learning");
      } else if (pathname.includes("language")) {
        this.setActive("language");
      } else if (pathname.includes("speaking-listening")) {
        this.setActive("speaking-listening");
      }
    }
  }

  componentDidUpdate(prevProps) {
    const pathnameProp = this.props.location.pathname;
    const pathnamePrevProp = prevProps.location.pathname;
    if (pathnameProp.includes("all") && pathnameProp !== pathnamePrevProp) {
      this.setInactive();
    }
  }

  render() {
    return (
      <div className="new-responsive-nav-btns-container">
        {(this.props.isLibrary &&
          this.props.location.pathname.endsWith("library")) ||
        this.props.isFolder
          ? ""
          : this.state.btnOptions.map((btn, index) => (
              <div
                key={index}
                className={
                  "new-single-responsive-btn" +
                  " " +
                  btn.color +
                  (btn.active ? " active" : "")
                }
                onClick={() =>
                  this.props.isLibrary
                    ? this.handleLibraryOnClick(index, btn.name)
                    : this.handleOnClick(index)
                }
              >
                <p className={btn.color}>{btn.displayName}</p>
              </div>
            ))}
      </div>
    );
  }
}

export default connect(null, actions)(withRouter(NewResponsiveNavBtns));
