import React, {Component} from 'react'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import authAPI from '../../api/authAPI'
import {browserHistory} from 'react-router'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import logo from "../../assets/images/Fundamentals_Logo.png";
import peer from "../../assets/images/Peer.svg";
import email from "../../assets/images/Email.svg";
import add from "../../assets/images/Add.svg";
import websearch from "../../assets/images/Websearch.svg";
require('../../assets/styles/trailAccount.scss')

class FloatingComponent extends Component{
    render(){
        return(
            <div>
                {/* <FloatingLabel id={this.props.name} name={this.props.name}  value={this.props.value} placeholder={this.props.placeholder} type={this.props.type} styles={inputStyle} onChange={this.props.onChange}/> */}
                <input id={this.props.name} name={this.props.name}  value={this.props.value} placeholder={this.props.placeholder} type={this.props.type} style={{"height": "33px",width: "100%","border-width": "0px 0px 1px","border-color": "rgb(63, 63, 63)","font-family": "ProximaNova","font-size": "14px","color": "rgb(0, 0, 0)",marginBottom:"10px"}} onChange={this.props.onChange}/>
                {(this.props.errorValue !== "") &&
                    <div className="add-user-error">
                        {this.props.errorValue!=='all' && this.props.errorValue}
                    </div>
                }
            </div>
        );
    }
}
class TrailAccounDetails extends Component {
    constructor() {
    super();
    this.state={
        form:{
            firstName:'',
            lastName:'',
            title:'',
            schoolName:'',
            email:'',
            telephone:''
        },
        country:'',
        region:'',
        firstNameValid:'',
        lastNameValid:'',
        titleValid:'',
        schoolNameValid:'',
        emailValid:'',
        telephoneValid:'',
        countryError:'',
        stateError:'',
        pageCount:1,
        counter :0,
        referral:'',
        referralId:-1,
        errorCount:0,
        goalTypes:[],
        goalsError:false,
        errorMessage:''
    }
  }

  handleClick=(e,index,grade)=> {
       let value=e.target.checked;
       grade.index = index;
       let removeIndex ='';
       let goalTypes = this.state.goalTypes;
       if(value){
           this.state.counter++;
           goalTypes.push(grade._id);
       }
       else{
           removeIndex = goalTypes.indexOf(grade._id)
           this.state.counter--;
           if(removeIndex >-1){
                goalTypes.splice(removeIndex,1);
           }
       }
       let tgrade = this.props.goals && this.props.goals.data && this.props.goals.data[index];
       tgrade.checked = value;
       this.setState({
            counter : this.state.counter,
            goalTypes: goalTypes,
            goalsError:false
       });
    }

  onChange=(event)=> {
      this.state.form[event.target.name] = event.target.value;
      this.setState({form: this.state.form});
  }

  GoBack=()=>{
      this.setState({pageCount:this.state.pageCount-1});
  }

  selectCountry (val) {
        this.setState({ country: val ,countryError:''});
    }

  selectRegion (val) {
    this.setState({ region: val, stateError:'' });
  }

  componentDidMount(){
      this.props.getGoalTypesAction();
      this.props.getReferralTypesAction();
      const scriptSrc =  document.createElement('script');
      //---adding a script to the project when component is mounting
      scriptSrc.src="//js.hs-scripts.com/3803240.js"
      scriptSrc.id='hs-script-loader';
      scriptSrc.async = 1;
      scriptSrc.defer = 1;
      document.getElementById('root').appendChild(scriptSrc);
  }

  Validation=()=>{
        let isGood = true;
        let count = 0;
        if(this.state.form.firstName === "") {
            this.setState({firstNameValid: "First Name cannot be blank."});
            isGood = false;
            count = count+1;
        }
        else if (this.setState.firstNameValid !== ""){
            this.setState({firstNameValid: ""});
            count = count-1;
        }

        if(this.state.form.lastName === "") {
            this.setState({lastNameValid: "Last Name cannot be blank."});
            isGood = false;
            count = count+1;
        }
        else if (this.state.lastNameValid !== ""){
            this.setState({lastNameValid: ""});
            count = count-1;
        }
        if(this.state.form.title === "") {
            this.setState({titleValid: "Title cannot be blank."});
            isGood = false;
            count = count+1;
        }
        else if (this.state.titleValid !== ""){
            this.setState({titleValid: ""});
            count = count-1;
        }
        if(this.state.form.schoolName === "") {
            this.setState({schoolNameValid: "District/School Name cannot be blank."});
            isGood = false;
            count = count+1;
        }
        else if (this.state.schoolNameValid !== ""){
            this.setState({schoolNameValid: ""});
            count = count-1;
        }
        if(this.state.country === "") {
            this.setState({countryError: "Country Name cannot be blank."});
            isGood = false;
            count = count+1;
        }
        else if (this.state.countryError !== ""){
            this.setState({countryError: ""});
            count = count-1;
        }
        if(this.state.region === "") {
            this.setState({stateError: "State Name cannot be blank."});
            isGood = false;
            count = count+1;
        }
        else if (this.state.stateError !== ""){
            this.setState({stateError: ""});
            count = count-1;
        }
        if(this.state.form.telephone === "") {
            this.setState({telephoneValid: "Telephone cannot be blank."});
            isGood = false;
            count = count+1;
        }
        else if (this.state.telephoneValid !== ""){
            this.setState({telephoneValid: ""});
            count = count-1;
        }
        if(this.state.form.email === "") {
            this.setState({emailValid: "Email cannot be blank."});
            isGood = false;
            count = count+1;
        }
        else {
            let self =this;
            // eslint-disable-next-line
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(re.test(this.state.form.email)){
                let encodedEmail = encodeURIComponent(this.state.form.email.trim())
                authAPI.emailValidation(encodedEmail)
                .then((response)=>{
                    if(response.status === 200){
                        if (this.state.emailValid !== ""){
                            this.setState({emailValid: ""});
                            count = count-1;
                        }
                    }else{
                        console.log("Error")
                    }
                }).catch(function (error) {
                    if(error.response.data.statusCode===409){
                        self.setState({pageCount:1,emailValid:"Email is already registered."});
                    }
                    else{
                        self.setState({pageCount:1,emailValid:error.response.data.message});
                    }
                });
            }
            else{
                this.setState({emailValid: "Please enter a valid email address."});
                isGood = false;
                count = count+1;
            }
        }
        if(isGood){
            this.setState({pageCount:2,errorCount:count});
        }else{
            this.setState({errorCount:count});
        }
        return isGood;
    }

  ValidateNextStep=()=>{
      if(this.state.pageCount===2){
        if(this.state.goalTypes.length!==0){
            this.setState({pageCount:3});
        }else{
            this.setState({goalsError:true});
        }
      }else{
          if(this.state.referralId===-1 || this.state.referralId===-2){
                this.setState({referralId:-2});
          }else{
                let finalObject={
                    first_name:this.state.form.firstName.trim(),
                    last_name:this.state.form.lastName.trim(),
                    title:this.state.form.title.trim(),
                    district_name:this.state.form.schoolName.trim(),
                    username:this.state.form.email.trim(),
                    phone_number:this.state.form.telephone,
                    trial_goal_type_ids:this.state.goalTypes,
                    referral_type_id:this.state.referralId,
                    state:this.state.region,
                    country:this.state.country
                }
                let self = this;
                authAPI.postTrailUser(finalObject)
                .then((response)=>{
                    if(response.status === 200){
                        localStorage.first_time_login = response.data.first_time_login;
                        localStorage.is_lite = false;
                        localStorage.user_type = 'Trial';
                        browserHistory.push('/password');
                    }
                }).catch(function (error) {
                    if(error.response.data.statusCode===409){
                        self.setState({pageCount:1,emailValid:"Email is already registered."});
                    }else{
                        self.setState({pageCount:3,errorMessage:'Unable to create trial.'});
                    }
                });
          }
      }
  }

  referralSelect=(referral)=>{
      let image='';
      if(referral.value==="Email"){
        image="Email.svg";
      }else if(referral.value==='Peer/Colleague'){
        image="Peer.svg";
      }else if(referral.value==='Web Search'){
        image="Websearch.svg";
      }else if(referral.value==='Advertisement'){
        image="Add.svg";
      }else if(referral.value==='Other'){
        image="";
      }else{
          image='';
      }
      this.setState({referralId:referral._id,referral:image});
  }

  componentWillUnmount () {
      //---removing script from the project when component is unmounting
      const scriptSrc = 'hs-script-loader';
      document.getElementById(scriptSrc).remove()
  }

  render() {
        return (
            <div id='trail-page-background'>
                <div className = 'trail-page-content'>
                    <div className ='trail-page-data'>
                        <div className='trail-head-name'>
                            <div className='trail-heading'>
                                <img width={130} src={logo} alt="" />
                            </div>
                        </div>
                        <div className='trail-content'>
                            <div className='trail-page-heading'>
                                {this.state.pageCount===1 && 'Welcome to your free trial!'}
                                {this.state.pageCount===2 && 'How can we support you?'}
                                {this.state.pageCount===3 && 'How did you learn about Fundamentals Unlimited?'}
                            </div>
                            <div className='trail-page-sub-heading'>
                                {this.state.pageCount===1 && 'Step 1 of 3: About you'}
                                {this.state.pageCount===2 && 'Step 2 of 3: Goals and interests'}
                                {this.state.pageCount===3 && 'Step 3 of 3: Who referred you to us?'}
                            </div>
                            {this.state.pageCount===1 &&
                                <div>
                                    {this.state.errorCount>=6 &&
                                        <div className='add-user-error'>
                                            *All fields are required.
                                        </div>
                                    }
                                    <form >
                                        <FloatingComponent name='firstName' placeholder="First Name" value={this.state.form.firstName} errorValue={this.state.errorCount>=8?'all':this.state.firstNameValid} type="text" id="firstName" onChange={this.onChange}/>
                                        <FloatingComponent name='lastName' placeholder="Last Name" value={this.state.form.lastName} errorValue={this.state.errorCount>=8?'all':this.state.lastNameValid} type="text" id="lastName" onChange={this.onChange}/>
                                        <FloatingComponent name='title' placeholder="Title" value={this.state.form.title} errorValue={this.state.errorCount>=8?'all':this.state.titleValid} type="text" id="title" onChange={this.onChange}/>
                                        <FloatingComponent name='schoolName' value={this.state.form.schoolName} errorValue={this.state.errorCount>=8?'all':this.state.schoolNameValid} placeholder="District/School Name" type="text" id="schoolName" onChange={this.onChange}/>
                                        <FloatingComponent name='email' value={this.state.form.email} errorValue={this.state.errorCount>=8?'all':this.state.emailValid} placeholder="Email" type="email" id="email" onChange={this.onChange}/>
                                        <FloatingComponent name='telephone' value={this.state.form.telephone} errorValue={this.state.errorCount>=8?'all':this.state.telephoneValid}  placeholder="Telephone" type="text" id="number" onChange={this.onChange}/>
                                      </form>
                                        <div className="trail-place">
                                            <div className="trail-place-label">
                                                Country
                                            </div>
                                            <CountryDropdown ref='country' value={this.state.country}
                                                            classes={this.state.countryError?"trail-dropdown-error":"trial-dropdown"}
                                                            valueType="short"
                                                            onChange={(val) => this.selectCountry(val)}/>
                                            {this.state.errorCount<8 && this.state.countryError &&
                                                <div className="error-format">
                                                    {this.state.countryError}
                                                </div>
                                            }
                                        </div>
                                        <div className="trail-place">
                                            <div className="trail-place-label">
                                                State
                                            </div>
                                            <RegionDropdown ref='state' value={this.state.region}
                                                                        classes={this.state.stateError?"trail-dropdown-error":"trial-dropdown"}
                                                                        valueType="short" countryValueType="short"
                                                                        country={this.state.country}
                                                                        onChange={(val) => this.selectRegion(val)}/>
                                            {this.state.errorCount<8 && this.state.stateError &&
                                                <div className="error-format">
                                                    {this.state.stateError}
                                                </div>
                                            }
                                        </div>
                                                        
                                    <div className='next-button-trail'>
                                        <button className="next-button" onClick={()=>this.Validation()}>
                                            Continue<span> &#8594;</span>
                                        </button>
                                    </div>
                                </div>
                            }
                            {this.state.pageCount===2 &&
                                <div>
                                    <div className='trail-page-sub-data' style={{color:this.state.goalsError?'#C44545':''}}>
                                        (Check all that apply)
                                    </div>
                                    <div className='two-column-display'>
                                        {this.props.goals && this.props.goals.asyncStatus === 'SUCCESS' && this.props.goals.data.map((goal,i)=>(
                                            <div className='checkbox-item-trail'>
                                                <label className="control control--checkbox" style={{lineHeight:'20px',zIndex: 1}}>{goal.value}
                                                    <input id='checkbox-trial' ref={this.props.tagName+i} type="checkbox" onChange={(e) => this.handleClick(e,i,goal)} checked={goal.checked}/>
                                                    <div className="control__indicator" style={{borderRadius:'0px'}}/>
                                                </label>
                                            </div>
                                        ))
                                        }
                                    </div>
                                    <div className='next-button-trail'>
                                        <button className="next-button" onClick={()=>this.ValidateNextStep()}>
                                            Last Step<span> &#8594;</span>
                                        </button>
                                    </div>
                                </div>
                            }
                            {this.state.pageCount===3 &&
                                <div>
                                    {this.state.referralId===-2 &&
                                        <div className='trail-page-sub-data' style={{color:'#C44545'}}>
                                            Select a Referrer
                                        </div>
                                    }
                                    {this.state.errorMessage&&
                                        <div className='trail-page-sub-data' style={{color:'#C44545'}}>
                                            {this.state.errorMessage}
                                        </div>
                                    }
                                    <div className='button-padding-referral'>
                                        {this.props.referrals && this.props.referrals.asyncStatus==='SUCCESS' && this.props.referrals.data.map((referral,i)=>(
                                            <div>
                                                <div className="referral-button"
                                                    style={{backgroundColor:(this.state.referralId===referral._id)?'#00D0C2':'',border:(this.state.referralId===referral._id)?"none":""}}
                                                    onClick={()=>this.referralSelect(referral)}>
                                                    <span className="referral-button-value" style={{color:(this.state.referralId===referral._id)?'#FFFFFF':'',opacity:(this.state.referralId===referral._id)?'1':''}}>
                                                        {referral.value}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='trail-image-size'>
                                        {this.state.referral && this.state.referral == 'Email.svg' &&
                                            <img style={{height:'180px'}}src={email}/>
                                        }
                                        {this.state.referral && this.state.referral == 'Peer.svg' &&
                                            <img style={{height:'180px'}}src={peer}/>
                                        }
                                        {this.state.referral && this.state.referral == 'Add.svg' &&
                                            <img style={{height:'180px'}}src={add}/>
                                        }
                                        {this.state.referral && this.state.referral == 'Websearch.svg' &&
                                            <img style={{height:'180px'}}src={websearch}/>
                                        }
                                    </div>
                                    <div className='next-button-trail'>
                                        <button className="next-button" onClick={()=>this.ValidateNextStep()}>
                                            Finish
                                        </button>
                                    </div>
                                </div>
                            }
                            <div className='go-back' style={{visibility:this.state.pageCount===1?'hidden':''}} onClick={()=>this.GoBack()}>
                                Go back
                            </div>
                            <div className='page-change'>
                                <div className='page-change-item'>
                                    <div className='color-change' style={{background:this.state.pageCount===1?'#00D0C2':'#D9D9D9'}}/>
                                </div>
                                <div className='page-change-item'>
                                    <div className='color-change' style={{background:this.state.pageCount===2?'#00D0C2':'#D9D9D9'}}/>
                                </div>
                                <div className='page-change-item'>
                                    <div className='color-change' style={{background:this.state.pageCount===3?'#00D0C2':'#D9D9D9'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        goals:state.GoalTypes,
        referrals:state.ReferralTypes
    }
};

export default connect(mapStateToProps, actions)(withRouter(TrailAccounDetails))