
import React, {Component} from 'react';
import StudentHome from './StudentHome'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
class Student extends Component {

    /**
     * Returns the id of the student to render
     * If user type is "Student" it returns this.props.myDetails.data._id
     * If not a student and it's a joyride tour, return the joyride student id
     * Otherwise it's a non student from the MyClass view, return the id param
     */
    getStudentId() {
        if(this.isUserStudent()) {
            return this.props.myDetails.data._id;
        } else {
            return this.isJoyrideTour()? 50 : this.props.params.id;
        }
    }

    /**
     * Checks if the logged in user is a student
     */
    isUserStudent() {
        return localStorage.user_type === "Student";
    }

    /**
     * Return the id route param if passed, otherwise return undefined
     */
    getIdParam() {
        if(this.props.params && (this.props.params.id !== undefined)) {
            return this.props.params.id;
        } else {
            return undefined;
        }
    }

    /**
     * Check if the user is viewing the joyride tour.
     * The user is not in a joyride tour if they are a student and the id param has not been supplied
     */
    isJoyrideTour() {
        return !this.isUserStudent() && this.getIdParam() === undefined;
    }

    componentDidMount(){
        this.props.getMyDetailsAction();
    }
    render(){
        return(
            <div style={{height: '100%'}}>
                {this.props.myDetails.asyncStatus === "SUCCESS" &&
                    <StudentHome name={this.props.myDetails.data.first_name}
                                 user_id={this.getStudentId()}
                                 tour={this.isJoyrideTour()}
                                 isEditStudentView={!this.isUserStudent() && !this.isJoyrideTour()}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        myDetails: state.MyDetails,
        assignments : state.Assignments,
    }
};

export default connect(mapStateToProps, actions)(withRouter(Student));
