import React,{Component} from  'react'
import {Row,Col} from 'react-bootstrap'
import {markdownHelper} from '../../markdown/MarkdownHelper'


class BookSummaryRow extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (<Row id='summary-row'>
                    <Col md={2} className='summary-heading' id='bcol-padding'>{this.props.label}</Col>
                    <Col md={10} className='summary-content'>
                        {this.props.children}
                    </Col>
                </Row>);
    }
}

class BookOverview extends Component{

    constructor (props) {
        super(props);
        this.state = {
            by: this.props.by,
            description: this.props.description,
            grades: this.props.grades,
            levels: this.props.levels,
            publisher: this.props.publisher,
            genres: this.props.genres,
            topics: this.props.topics,
            pages: this.props.pages,
            themes: this.props.themes,
            zingId: this.props.zingId
        }
    }

    render(){
        return(
            <div className="list-of-items">
                <BookSummaryRow label="AUTHOR">
                    {this.state.by}
                </BookSummaryRow>
                <div className='main-seperator'/>
                <BookSummaryRow label="PUBLISHER">
                    {this.state.publisher}
                </BookSummaryRow>
                <div className='main-seperator'/>
                <BookSummaryRow label="DESCRIPTION">
                    <div dangerouslySetInnerHTML={{__html: markdownHelper.toHTML(this.state.description.text)}} />
                </BookSummaryRow>
                <div className='main-seperator'/>
                <BookSummaryRow label="GUIDED READING LEVEL">
                    {this.state.levels}
                </BookSummaryRow>
                <div className='main-seperator'/>
                <BookSummaryRow label="INTEREST LEVEL">
                    {this.state.grades}
                </BookSummaryRow>
                <div className='main-seperator'/>
                <BookSummaryRow label="GENRE/TEXT TYPE ">
                    {this.state.genres.map((genre) => (
                        <div>{genre}</div>
                    ))}
                </BookSummaryRow>
                <div className='main-seperator'/>
                <BookSummaryRow label="TOPIC/THEME">
                    {this.state.topics.map((topic) => (
                        <div>{topic}</div>
                    ))}
                    {this.state.themes.map((theme) => (
                        <div>{theme}</div>
                    ))}
                </BookSummaryRow>
                <div className='main-seperator'/>
                <BookSummaryRow label="PAGE COUNT">
                    {this.state.pages}
                </BookSummaryRow>
                {/*
                <div className='main-seperator'/>
                {this.state.zingId !== null &&
                    <div onClick={this.openBook} style={{marginTop: '25px', marginLeft: '25px'}} target="_blank">
                        <button className='open-file-button'><i className="icon-icon-reader-circle" style={{fontSize: '30px'}}/></button>
                    </div>
                }
                */}
            </div>
        );
    };

}

export default BookOverview
