import React, { useState, useEffect, useRef, useMemo } from "react";
import Fuse from "fuse.js";

const Autocomplete = ({ suggestions = [], handleAddStudent }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [itemSelected, setItemSelected] = useState(false);

  const inputRef = useRef(null);

  const fuse = new Fuse(suggestions, {
    keys: ["first_name", "last_name", "username"],
    threshold: 0.6,
  });

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const onChange = (e) => {
    const userInput = e.target.value;
    setItemSelected(false);
    setInputValue(userInput);
    setFilteredSuggestions(
      fuse
        .search(userInput)
        .map((el) => el.item)
        .slice(0, 50)
    );
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const onClick = (e, item) => {
    setItemSelected(true);
    handleAddStudent([item]);
    setFilteredSuggestions([]);
    setInputValue(e.target.innerText);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      <ul className="autocomplete-dropdown">
        {filteredSuggestions.map((suggestion, index) => {
          let className;
          if (index === activeSuggestionIndex) {
            className = "suggestion-active";
          }

          return (
            <li
              className={className}
              key={`${suggestion}-${index}`}
              onClick={(e) => onClick(e, suggestion)}
            >
              {`${suggestion.first_name || "No First Name"} ${
                suggestion.last_name || "No Last Name"
              } (${suggestion.username || "No Email"})`}
            </li>
          );
        })}
      </ul>
    ) : (
      !itemSelected && (
        <div className="no-suggestions">
          <em>No suggestions available.</em>
        </div>
      )
    );
  };

  return (
    <div className="share-modal-input-container" style={{ width: "85%" }}>
      <input
        ref={inputRef}
        type="text"
        onChange={onChange}
        value={inputValue}
        style={{ width: "100%" }}
      />
      {showSuggestions && inputValue && <SuggestionsListComponent />}
    </div>
  );
};

export default Autocomplete;
