import React from 'react'
import {Link} from 'react-router'
import PopOver from '../PopOver'
import PopOverSuccess from '../PopOverSuccess'
import PopOverFailure from '../PopOverFailure'
import Datetime from 'react-datetime'
let clearPopOver,clearDatePicker;

class LessonPopOver extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showPopOver: false,
            success: false,
            failed: false,
            errorMessage: "",
            showDatePicker: false,
            addedToPlanner: false,
            addedToMyLibrary: false
        };
        this.addToLibrary = this.addToLibrary.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.showDatePicker = this.showDatePicker.bind(this);
    }

    showTooltip() {
        this.setState({showPopOver: true, success: false,showDatePicker: false})
    }

    showDatePicker() {
        this.setState({showDatePicker: true, success:false,showPopOver:false}, ()=>{clearDatePicker = setTimeout(()=>{this.setState({showDatePicker: false})},3000) });
    }

    addToLibrary(){
        this.setState({showPopOver: false});
        this.props.addToLibrary(this.props.lid)

            .then((response)=>{
                if(response.statusCode === 200){
                    this.setState({success : true,addedToMyLibrary : true,addedToPlanner: false},clearPopOver = setTimeout(()=>{this.setState({success: false})},3000 ));
                }
                else{
                    this.setState({success : false,failed : true,errorMessage: response.response.data.message.message},clearPopOver = setTimeout(()=>{this.setState({failed: false})},3000 ));
                }
            })
            .catch((error)=>{
              this.setState({success : false,failed : true,errorMessage: error},clearPopOver = setTimeout(()=>{this.setState({failed: false})},3000 ));
            });
    }

    handleDate(date){
        this.props.scheduleTask(date._d, this.props.lid, "Lesson")
            .then((response)=>{
                if(response.status === 201){
                    this.setState({showDatePicker: false,success: true,addedToPlanner: true,addedToMyLibrary: false}, clearPopOver = setTimeout(()=>{this.setState({success: false})},3000 ))
                }
                else{
                    this.setState({showDatePicker: false})
                }
            })
            .catch((error)=>{
              this.setState({showDatePicker: false})
            });
    }

    render() {
        return (
                <div>
                    <div onClick={() => {this.showTooltip()}} id={this.props.popOverId}  className="more-demo-icon-lesson icon-icon-more-silo"/>
                        {this.state.showPopOver &&
                        <PopOver isTooltipActive={this.state.showPopOver} arrow="center" addToLibrary={this.addToLibrary} count={this.props.popOverId} >
                            <div onMouseLeave={() => setTimeout(() => this.setState({showPopOver : false}),3000)}>
                                <div className="modal-text-header-styles">ADD OPTIONS</div>
                                <div onClick={this.addToLibrary}
                                    style={{cursor: "pointer",paddingBottom: "10px",color: "#3498DB",fontFamily: "ProximaNova",fontSize: "15px",lineHeight: "19px"}}>Add to My Library</div>
                                {/*<div onClick={this.showDatePicker}
                                    style={{cursor: "pointer",paddingBottom: "10px",color: "#3498DB",fontFamily: "ProximaNova",fontSize: "15px",lineHeight: "19px"}}>Add to Planner</div>*/}
                            </div>
                        </PopOver>}

                        {this.state.showDatePicker &&
                            <div onMouseOver={()=>{ clearTimeout(clearDatePicker);this.setState({showDatePicker:true})}} onMouseLeave={() => {clearDatePicker= setTimeout(() => this.setState({showDatePicker : false}),3000)}} style={{cursor: "pointer", position:"absolute", right:635}}>
                                <Datetime  open={true} onChange={this.handleDate}/>
                            </div>
                        }

                        {this.state.success &&
                            <PopOverSuccess isTooltipActive={this.state.success} arrow="center" count={this.props.popOverId} name={this.props.lesson_name}>
                                <div onMouseOver={()=>{ clearTimeout(clearPopOver); this.setState({success:true})}}
                                    onMouseLeave={()=> { clearPopOver = setTimeout(()=>{this.setState({success: false})},3000 )}} >
                                    <div className="lesson-added">
                                        <div style={{marginLeft:20,marginBottom: 10}} className="selected-checkbox-icon-lesson icon-icon-checkbox"/>
                                        <strong>{this.props.lesson_name}</strong> was successfully added to {this.state.addedToMyLibrary && <span>your Library!</span>} {this.state.addedToPlanner && <span>Planner</span>}</div>
                                    {this.state.addedToMyLibrary && <div>
                                        <span><Link to="/library/resources"><button className="go-to-my-library-planner">GO TO MY LIBRARY</button></Link></span>
                                        {/* <span><button onClick={this.showDatePicker} className="add-to-planner-library">ADD TO PLANNER</button></span> */}
                                    </div>}
                                    {this.state.addedToPlanner && <div>
                                        <span><button onClick={this.addToLibrary} className="add-to-planner-library">ADD TO MY LIBRARY</button></span>
                                        {/* <span><Link to="/planner"><button className="go-to-my-library-planner">GO TO PLANNER</button></Link></span> */}
                                    </div>}
                                </div>
                            </PopOverSuccess>
                        }

                        {this.state.failed &&
                            <PopOverFailure isTooltipActive={this.state.failed} arrow="center" errorMessage={this.state.errorMessage} count={this.props.popOverId}>
                                <div onMouseOver={()=>{ clearTimeout(clearPopOver); this.setState({failed:true})}} onMouseLeave={()=> {clearPopOver= setTimeout(()=>{this.setState({failed: false})},3000);}}>
                                    {this.state.errorMessage &&
                                    <div>
                                        <div className="icon-icon-warning" style={{fontSize: 30,color:"#d0021b",float:"left"}}/>
                                        <span><strong> {this.props.lesson_name} </strong> already exists in your Library.</span>
                                    </div>
                                    }
                                </div>
                            </PopOverFailure>
                        }
                </div>
              )
        }
}

export default LessonPopOver;
