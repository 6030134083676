import React, {Component} from 'react'
import "../assets/styles/login.scss"
import * as actions from '../redux/actions'
import {connect} from 'react-redux'
import {Link} from 'react-router'
import { Alert } from 'react-bootstrap'
import mailer from '../assets/images/mailer.png';

class ResendLink extends Component {
    constructor(){
        super();
        this.submitForm = this.submitForm.bind(this);

        this.state = {
            sendProcessing: false,
            showTrialMessage: false
        };
    }

    submitForm(){
        this.setState({
            sendProcessing: true,
            showTrialMessage: false
        });
        let username = this.props.emailAddress;
        this.props.recoverPasswordAction(username).then((resp) => {
            if(resp.info.message === "Trial Login expired"){
                this.setState({
                    showTrialMessage: true,
                    sendProcessing: false
                });
            }else{
                this.setState({
                    showTrialMessage: false,
                    sendProcessing: false
                });
            }
        });
    }

    render() {
        return (
            <div className="bg-color">
                <div className="login-box">
                    <div className="mailer-imgcontainer">
                        <img src={mailer} alt="Avatar" className="mailer"/>
                    </div>
                    <div className="reset-link-message">
                        All set! A reset link was sent to email address <span style={{color: "black"}}><strong>{this.props.emailAddress}. </strong></span>
                        If you have not received the email within 5 minutes, please check your spam folder. If you still have not received it, please contact us at <span style={{color: "black"}}><strong>help@schoolwide.com</strong></span>.
                    </div>
                    {
                            (this.state.showTrialMessage || this.props.showTrialMessage) && 
                            <div className="trial-expired-box">
                                <Alert id="alert-danger" bsStyle="danger">
                                    <strong>
                                    Please note your trial period has expired and you will not be able to login. Please contact support at <a href="mailto:help@schoolwide.com">help@schoolwide.com</a> for further assistance.
                                    </strong>
                                </Alert>
                            </div>
                        }
                    <div className="align-button">
                        <button className="format-button"
                                onClick={()=>{this.submitForm();}}
                                disabled={this.state.sendProcessing}>
                                    {this.state.sendProcessing ? 'Processing...' : 'Resend Link!'}
                        </button>
                    </div>
                    <div className="align-link">
                        <Link to='/'><span style={{color: "#666666",textDecoration: "underline"}}>&larr;&nbsp; Return to Log In</span></Link>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginStatus : state.User.LoginStatus,
        path:state.Location
    }
};

export default connect(mapStateToProps, actions)(ResendLink)