import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory, withRouter } from "react-router";
import * as actions from "../../redux/actions";
import authAPI from "../../api/authAPI";
import constants from "../../constants";
import BrowsePageContent from "../shared/BrowsePageContent";
import _ from "lodash";
import ResponsiveResourcesNew from "./ResponsiveResourcesNew";
import ResponsiveNavBtns from "./ResponsiveNavBtns";
import "../../assets/styles/mobile.scss";
import ResourceHeading from "../../components/ResourceHeading";
import { SnackBarModule } from "../../components/Shared";

const invertMap = [
  "Featured",
  "Reading",
  "Writing",
  "Speaking & Listening",
  "Language",
];

class LibraryFolderContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: constants.caseMap[this.props?.params?.strand],
      isFeatured: false,
      DataSource: this.props,
      loadingMore: false,
      pageNumber: 1,
      folderDetails: {},
      hasMore: true,
      total: 0,
      filter: false,
      queryValue: "",
      multiSelectMode: false,
      multipleItems: {
        Resource: [],
        Lesson: [],
        Correlations: [],
      },
      addedMultipleItemsToLibrary: false,
      addedMultipleItemsToFolder: false,
      createFolderSuccess: false,
      createFolderError: false,
      isJoyrideRunning: false,
      isBackButtonPressed: false,
      selectedResourceId: "",
      areAllSelected: false,
      removeFromLibrarySuccess: false,
      selectedItems: [],
      searchValue: "",
    };
    window.sessionStorage.setItem("selectedFilters", JSON.stringify([]));
  }

  millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  componentWillReceiveProps() {
    window.onpopstate = (e) => {
      this.setState({
        isBackButtonPressed: true,
      });
    };
  }

  setLibrarySearchValue = (searchValue) => {
    this.setState({ searchValue });
  };

  _loadMore() {
    setTimeout(() => {
      if (this.state.total > this.state?.data?.length) {
        let strandInfinite = constants.caseMap[this.props?.params?.strand],
          filtersSet =
            this.props.finalFilters &&
            this.props.finalFilters.filterList &&
            this.props.finalFilters.filterList.length > 0,
          eitherFiltersSetOrQueryEmpty =
            (filtersSet && this.state.queryValue !== "") ||
            (!filtersSet && this.state.queryValue === "");

        if (
          this.props?.params?.strand !== "featured" &&
          eitherFiltersSetOrQueryEmpty
        ) {
          authAPI
            .getResources(
              this.state.pageNumber + 1,
              strandInfinite,
              this.state.queryValue
            )
            .then((resp) => {
              if (resp.total && resp?.results) {
                this.setState({
                  total: resp.total,
                  data: _.concat(this.state.data, resp.results),
                  pageNumber: Number(resp.currentPage),
                  loadingMore: false,
                  filter: false,
                });
              }
            });
        }
      } else {
        this.setState({
          loadingMore: false,
          hasMore: false,
        });
      }
    }, 1000);
  }

  addMultipleItems = (tid, type = {}) => {
    if (type === "Resource") {
      this.state.multipleItems["Resource"].push(tid);
    } else if (type === "Correlations") {
      this.state.multipleItems["Correlations"].push(tid);
    } else {
      this.state.multipleItems["Lesson"].push(tid);
    }
    this.setState({ multipleItems: this.state.multipleItems });
  };

  deleteItem = (tid, type = "") => {
    if (type === "Resource") {
      let idx = this.state.multipleItems["Resource"].indexOf(tid);
      this.state.multipleItems["Resource"].splice(idx, 1);
    } else if (type === "Correlations") {
      let idx = this.state.multipleItems["Correlations"].indexOf(tid);
      this.state.multipleItems["Correlations"].splice(idx, 1);
    } else {
      let idx = this.state.multipleItems["Lesson"].indexOf(tid);
      this.state.multipleItems["Lesson"].splice(idx, 1);
    }

    this.setState({ multipleItems: this.state.multipleItems });
  };

  addMultipleItemsToLibrary = () => {
    for (let i = 0; i < this.state.multipleItems["Resource"].length; i++) {
      this.props
        .postAddTeachingResourceToLibraryAction(
          this.state.multipleItems["Resource"][i]
        )

        .then((response) => {
          if (response.statusCode === 200) {
            console.log(
              "Item added to library",
              this.state.multipleItems["Resource"][i]
            );
          } else {
            console.log(
              "Error ! Item not added to library",
              this.state.multipleItems["Resource"][i]
            );
          }
        })
        .catch(function (error) {
          console.log("Exception! Item not added to library");
        });
    }
    for (let i = 0; i < this.state.multipleItems["Lesson"].length; i++) {
      this.props
        .postAddLessonToLibraryAction(this.state.multipleItems["Lesson"][i])

        .then((response) => {
          if (response.statusCode === 200) {
            console.log(
              "Item added to library",
              this.state.multipleItems["Lesson"][i]
            );
          } else {
            console.log(
              "Error ! Item not added to library",
              this.state.multipleItems["Lesson"][i]
            );
          }
        })
        .catch(function (error) {
          console.log("Exception! Item not added to library");
        });
    }
    for (let i = 0; i < this.state.multipleItems["Correlations"].length; i++) {
      this.props
        .postAddCorrelationToLibraryAction(
          this.state.multipleItems["Correlations"][i]
        )

        .then((response) => {
          if (response.statusCode === 200) {
            console.log(
              "Item added to library",
              this.state.multipleItems["Correlations"][i]
            );
          } else {
            console.log(
              "Error ! Item not added to library",
              this.state.multipleItems["Correlations"][i]
            );
          }
        })
        .catch(function (error) {
          console.log("Exception! Item not added to library");
        });
    }
  };

  addMultipleItemsToFolder = () => {
    let items = [];
    let itemResources = [];
    for (let i = 0; i < this.state.multipleItems["Resource"].length; i++) {
      items.push(this.state.multipleItems["Resource"][i]);
      itemResources.push("Resource");
    }
    for (let i = 0; i < this.state.multipleItems["Lesson"].length; i++) {
      items.push(this.state.multipleItems["Lesson"][i]);
      itemResources.push("Lesson");
    }
    for (let i = 0; i < this.state.multipleItems["Correlations"].length; i++) {
      items.push(this.state.multipleItems["Correlations"][i]);
      itemResources.push("Correlations");
    }

    browserHistory.push({
      pathname: "/library/addfolder",
      state: {
        libraryItemId: items,
        libraryItemType: itemResources,
        isLibrary: false,
      },
    });
    this.props.saveAssignmentItemAction(itemResources, items);
  };

  handleAddMultiple = function (desktop) {
    this.addMultipleItemsToLibrary();
    this.setState(
      {
        multiSelectMode: !this.state.multiSelectMode,
        addedMultipleItemsToLibrary: true,
      },
      () => {
        if (desktop)
          setTimeout(
            () =>
              this.setState({
                addedMultipleItemsToLibrary: false,
                multipleItems: {
                  Resource: [],
                  Lesson: [],
                  Correlations: [],
                },
              }),
            3000
          );
      }
    );
  };

  handleAddMultipleToFolder = function (desktop) {
    this.addMultipleItemsToFolder();
    this.setState(
      {
        multiSelectMode: !this.state.multiSelectMode,
        addedMultipleItemsToFolder: true,
      },
      () => {
        if (desktop)
          setTimeout(
            () =>
              this.setState({
                addedMultipleItemsToFolder: false,
                multipleItems: {
                  Resource: [],
                  Lesson: [],
                  Correlations: [],
                },
              }),
            3000
          );
      }
    );
  };

  onSelectMenuItem = (ekey) => {
    browserHistory.replace(`/browse/teaching-resources/${ekey}`);
  };

  //This function expects to be bound in a child component's onClick
  handleClickForResourcesThumbnail = function (item) {
    if (this.props?.multiSelectMode) {
      if (
        (item.type === "Resource" &&
          this.props.multipleItems["Resource"].indexOf(item._id) === -1) ||
        (item.type === "Lesson" &&
          this.props.multipleItems["Lesson"].indexOf(item._id) === -1)
      ) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        item.type !== "Correlations" &&
          this.props.deleteItem(item._id, item.type);
      }
    } else {
      let id = item?._id ?? item?.id;
      browserHistory.push("/folders/" + id);
    }
  };

  //This function expects to be bound in a child component's onClick
  handleClickForResourcesAddBtn = function (item) {
    if (this.props.multiSelectMode) {
      if (
        (item.type === "Resource" &&
          this.props.multipleItems["Resource"].indexOf(item._id) === -1) ||
        (item.type === "Lesson" &&
          this.props.multipleItems["Lesson"].indexOf(item._id) === -1) ||
        (item.type === "Correlations" &&
          this.props.multipleItems["Correlations"].indexOf(item._id) === -1)
      ) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        this.props.deleteItem(item._id, item.type);
      }
    } else {
      //This replaces "showTooltip" in popover.
      this.setState({
        isTooltipActive: true,
        success: false,
        showDatePicker: false,
      });
    }
  };

  handleSelectResourceClick = (item) => {
    this.setState({ folderDetails: item });
  };

  handleCancel = () => {
    this.handleDeselectAll();
    this.setState({ multiSelectMode: !this.state.multiSelectMode });
  };

  handleMultiSelect = (item) => {
    const copyArr = [...this.state.selectedItems];
    if (copyArr.length !== 0) {
      if (!copyArr.filter((e) => e.item_id === item._id).length > 0) {
        copyArr.push({
          item_id: item._id,
          item_name: item.name,
          item_type: "Folder",
        });
        let uniqueItems = [
          ...new Map(copyArr.map((v) => [v.item_id, v])).values(),
        ];
        this.setState({ selectedItems: uniqueItems });
      } else {
        copyArr.splice(
          copyArr.findIndex((x) => x.item_id === item._id),
          1
        ); //deleting
        this.setState({ selectedItems: copyArr });
      }
    } else {
      copyArr.push({
        item_id: item._id,
        item_name: item.name,
        item_type: "Folder",
      });
      let uniqueItems = [
        ...new Map(copyArr.map((v) => [v.item_id, v])).values(),
      ];
      this.setState({ selectedItems: uniqueItems });
    }
  };

  handleSelectAll = () => {
    this.setState({ areAllSelected: true });
    const copyArr = [];
    this.props?.myLibrary?.data?.folders?.results?.forEach((item) => {
      copyArr.push({
        item_id: item._id,
        item_name: item.name,
        item_type: "Folder",
      });
    });
    this.setState({ selectedItems: copyArr });
  };

  handleDeselectAll = () => {
    this.setState({ areAllSelected: false });
    this.setState({ selectedItems: [] });
  };

  handleOpenMultiselect = () => {
    this.setState({ multiSelectMode: !this.state.multiSelectMode });
  };

  handleBulkRemoveFolder = async () => {
    const copySelectedItems = [...this.state.selectedItems];
    let selectedIDs = [];

    copySelectedItems.forEach((el) => {
      selectedIDs.push(el.item_id);
    });

    const response = await authAPI.bulkRemoveFromFolder({ f_ids: selectedIDs });

    if (response?.message === "Resource Deleted") {
      this.props.refreshLibrary();
      this.handleCancel();
      this.setState({ removeFromLibrarySuccess: true });
      setTimeout(() => {
        this.setState({ removeFromLibrarySuccess: false });
      }, 3000);
    } else {
      this.handleCancel();
      this.setState({ removeFromLibraryError: true });
      setTimeout(() => {
        this.setState({ removeFromLibraryError: false });
      }, 3000);
    }
  };

  handleCreateFolderSnackbar = ({ isError = false }) => {
    if (isError) {
      this.setState({ createFolderError: true });
      setTimeout(() => {
        this.setState({ createFolderError: false });
      }, 3000);
    } else {
      this.setState({ createFolderSuccess: true });
      setTimeout(() => {
        this.setState({ createFolderSuccess: false });
      }, 3000);
    }
  };

  getResources = () => {
    const startE = new Date().getTime();
    const resources = this.props?.myLibrary?.data?.folders?.results;
    return resources?.length >= 0
      ? resources.map((item, i) => (
          <ResponsiveResourcesNew
            item={item}
            i={i}
            key={i}
            handleClickForResourcesThumbnail={
              this.handleClickForResourcesThumbnail
            }
            handleClickForResourcesAddBtn={this.handleClickForResourcesAddBtn}
            handleSelectResourceClick={this.handleClickForResourcesThumbnail}
            multiSelectMode={this.state.multiSelectMode}
            addMultipleItems={this.addMultipleItems}
            deleteItem={this.deleteItem}
            multipleItems={this.state.multipleItems}
            scheduleTask={this.props.postScheduledTasksAction}
            addToLibrary={
              item.type === "Resource"
                ? this.props.postAddTeachingResourceToLibraryAction
                : item.type === "Correlations"
                ? this.props.postAddCorrelationToLibraryAction
                : this.props.postAddLessonToLibraryAction
            }
            addLessonToLibrary={this.props.postAddLessonToLibraryAction}
            startE={startE}
            active={
              parseInt(item?._id) === parseInt(this.state.selectedResourceId)
            }
            type={"library"}
            isFolder={true}
            refreshLibrary={this.props.refreshLibrary}
            handleMultiSelect={this.handleMultiSelect}
            selectedItems={this.state.selectedItems}
          />
        ))
      : [];
  };

  render() {
    const navButtons = (
      <ResponsiveNavBtns
        displayableSelectedStrand={
          invertMap[constants.caseMap[this.props?.params?.strand]]
        }
        onSelectMenuItem={this.onSelectMenuItem}
        selectedStrand={this.props?.params?.strand}
        btnOptions={[
          "featured",
          "reading",
          "writing",
          "language",
          "speaking-listening",
        ]}
        nextJoyrideStep={this.props.nextJoyrideStep}
        isJoyrideRunning={this.props.isJoyrideRunning}
      />
    );

    return (
      <>
        <BrowsePageContent
          name="Folders"
          asyncStatus={this.props?.myLibrary?.asyncStatus ?? ""}
          resources={
            this.state.searchValue !== ""
              ? this.getResources()?.filter((x) => {
                  return x.props?.item?.name
                    ?.toLowerCase()
                    .includes(this.state.searchValue.toLowerCase());
                })
              : this.getResources()
          }
          resourceHeading={
            <ResourceHeading
              multiSelectMode={this.state.multiSelectMode}
              areAllSelected={this.state.areAllSelected}
              handleOpenMultiselect={this.handleOpenMultiselect}
              handleCancel={this.handleCancel}
              handleDeselectAll={this.handleDeselectAll}
              handleSelectAll={this.handleSelectAll}
              handleRemoveFromLibrary={this.handleBulkRemoveFolder}
              refreshLibrary={this.props.refreshLibrary}
              handleCreateFolderSnackbar={this.handleCreateFolderSnackbar}
            />
          }
          selectedResource={
            this.state.folderDetails?._id ? this.state.folderDetails : ""
          }
          selectedResourceType={"folder"}
          handleClickForResourcesThumbnail={
            this.handleClickForResourcesThumbnail
          }
          navButtons={navButtons}
          isFeatured={this.state.isFeatured}
          hasMore={this.state.hasMore}
          loadMore={this._loadMore.bind(this)}
          setLibrarySearchValue={this.setLibrarySearchValue}
          location={this.props.location}
          type={"library"}
          refreshLibrary={this.props.refreshLibrary}
        />

        <SnackBarModule
          removeFromLibrarySuccess={this.state.removeFromLibrarySuccess}
          removeFromLibraryError={this.state.removeFromLibraryError}
          createFolderSuccess={this.state.createFolderSuccess}
          createFolderError={this.state.createFolderError}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    myLibrary: state.MyLibrary,
    finalFilters: state.FilterScale,
    isJoyrideRunning: state.JoyrideReducer.joyrideShouldRun,
    assessmentDetails: state.AssessmentDetails,
  };
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(LibraryFolderContent));
