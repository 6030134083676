import React,{Component} from  'react'
import * as actions from '../../redux/actions';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import DetailedHeader from '../../containers/Details/DetailedHeader';
import DetailedDescription from '../../containers/Details/DetailedDescription'
import CorrelationsOverview from './CorrelationsOverview';

class CorrelationsDetailedView extends Component{

    constructor (props) {
        super(props);
        this.state = {
            selectedTab: "SUMMARY",
            infoClicked: window.innerWidth < 768 ? false : true,
            searchClicked: false,
            load: "initial",
            status:"",
            data: {
                id: "",
                image: "",
                name: "",
                description: "",
                grades: "",
                texts: [],
                forms: [],
                printable:false
            }
        };
        this.selectTab = this.selectTab.bind(this);
        this.onInfoClick = this.onInfoClick.bind(this);
    }

    componentWillMount() {
        this.props.getCorrelationDetailsAction(this.props.params.uid);
    }

    componentWillReceiveProps(next) {
        if (next.correlationDetails) {
            if (next.correlationDetails.asyncStatus === "PENDING") {
                this.setState({status: "PENDING"});
            }
            else if (next.correlationDetails.asyncStatus === "FAILURE") {
                this.setState({correlationDetails: "FAILURE"});
            } else if (next.correlationDetails.asyncStatus === "SUCCESS" && next.correlationDetails.data.status === "FAILURE FOR PRINT ONLY USER") {
                this.setState({status: "FAILURE FOR PRINT ONLY USER"});
            } else if (next.correlationDetails.asyncStatus === "SUCCESS" && next.correlationDetails.data.status === "FAILURE FOR NOT APPROVED") {
                this.setState({status: "FAILURE FOR NOT APPROVED"});
            } else if (next.correlationDetails.asyncStatus === "SUCCESS") {
                let data = {
                    id: next.correlationDetails.data._id,
                    name: next.correlationDetails.data.name,
                    publish: next.correlationDetails.data.publish,
                    texts: next.correlationDetails.data.mentorTexts,
                    grades: next.correlationDetails.data.grades.join(", "),
                    forms: next.correlationDetails.data.files.map(file => ({file, image: file + ".thumb.jpg"})),
                    description: '',
                    image: '',
                    printable:next.correlationDetails.data.printable || false
                  };

                if(next.correlationDetails.data.description.description) {
                    data.description = next.correlationDetails.data.description.description;
                }
                else if(next.correlationDetails.data.description.text) {
                    data.description = next.correlationDetails.data.description.text;
                }

                if (next.correlationDetails.data.image) {
                    data.image = next.correlationDetails.data.image;
                }

                this.setState({data, status: "SUCCESS"});
            }
        }
        if(next.params.uid!==this.props.params.uid){
            this.props.getCorrelationDetailsAction(next.params.uid);
        }
    }

    onInfoClick(infoClicked = !this.state.infoClicked){
        if(infoClicked) {
            this.onSearchClick(false);
        }
        return new Promise((resolve, reject) => {
            this.setState({infoClicked: infoClicked, load: ""}, () => {
                resolve();
            });
        });

    }

    onSearchClick=(searchClicked = !this.state.searchClicked)=>{
        if(searchClicked) {
            this.onInfoClick(false);
        }
        return new Promise((resolve, reject) => {
            this.setState({searchClicked: searchClicked, load: ""}, () => {
                resolve();
            });
        });
    };

    selectTab (tab) {

        this.setState({selectedTab: tab});
    }

    render(){
        return(
            <div className="detail-wrapper">
                <DetailedHeader publish={this.state.data.publish} file={Array.isArray(this.state.data.forms) && this.state.data.forms[0] && this.state.data.forms[0].file} name={this.state.data.name} onSearchClick={this.onSearchClick} infoClick={this.onInfoClick} itemType="Correlation" itemId={this.state.data.id} infoClicked={this.state.infoClicked} searchClicked={this.state.searchClicked}/>
                <div className={((this.state.infoClicked || this.state.searchClicked)?"some-area fill-height-or-more":"fill-height-or-more some-area-without-info")}>
                    <div className="detail-holder">
                        {this.state.status === "PENDING" &&
                            <div style={{paddingTop: "10px", textAlign: "center"}}>Loading....</div>
                        }
                        {this.state.status  === "FAILURE" &&
                            <div style={{paddingTop: "10px", textAlign: "center"}}>Error Loading Data</div>
                        }
                        {this.state.status  === "FAILURE FOR PRINT ONLY USER" &&
                            <div style={{paddingTop: "10px", textAlign: "center"}}>This resource is not approved for viewing on Fundamentals Unlimited. Please contact us at <a href="mailto:help@schoolwide.com" target="_blank">help@schoolwide.com</a> for more information.</div>
                        }
                        {this.state.status  === "FAILURE FOR NOT APPROVED" &&
                            <div style={{paddingTop: "10px", textAlign: "center"}}>This resource is not approved for viewing on Fundamentals Unlimited. Please contact us at <a href="mailto:help@schoolwide.com" target="_blank">help@schoolwide.com</a> for more information.</div>
                        }
                        {this.state.status === "SUCCESS" &&
                            <CorrelationsOverview
                                texts={this.state.data.texts}
                                forms={this.state.data.forms}
                                description={{text: this.state.data.description}}
                                grades={this.state.data.grades}
                            />
                        }
                    </div>
                    <div>
                        <DetailedDescription
                            file={Array.isArray(this.state.data.forms) && this.state.data.forms[0] && this.state.data.forms[0].file} //Assuming form[0] -> image
                            image={this.state.data.image}
                            name={this.state.data.name}
                            detailType='assessments'
                            printable={this.state.data.printable}
                            printableFile={Array.isArray(this.state.data.forms) && this.state.data.forms[0] && this.state.data.forms[0].file}
                        />
                    </div>
                </div>
            </div>
        );
    };

}

const mapStateToProps = (state) => {
    return ({
        correlationDetails : state.CorrelationDetails
    });
};

export default connect(mapStateToProps, actions)(withRouter(CorrelationsDetailedView))
