import React,{Component} from 'react'
import {Col} from 'react-bootstrap'
import UserForm from './UserForms'
import BulkAddUser from './BulkAddUser'
import Dialog from './Dialog'
import SysAdminItem from './SysAdminItem'
import debounce from 'debounce'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import SchoolAdminItem from "./SchoolAdminItem"
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import authAPI from '../../api/authAPI';
import iconDownload from '../../assets/icons/SVG/icon-download.svg'

const items={
    "SysAdminItem" : SysAdminItem,
    "SchoolAdminItem":SchoolAdminItem
};

let districtIDFromLocalStorage = localStorage.getItem("district_id");


class ListView extends Component{

    constructor(props) {
        super(props);
        this.state = {
            form: {
                userType: "",
                license_ID: "",
                userIndex: ""
            },
            filterIsActive: false,
            addUser: false,
            editUser: false,
            deleteUser: false,
            bulkAddUser: false,
            listUser: false,
            deleteUserId: 0,
            deleteUserName: "",
            editUserData: null,
            searchTerm: "",
            // isTeacher:true,
// Added state for unlimited and printOnly, commented out state for teacher above
            isUnlimited:true,
            isPrintOnly:true,
            isTextOnly:true,
            isAdmin:true,
            isSystemAdmin: true,
            isContentEditor: true,
            adminOptions: ["Unlimited", "Print Only"],
            LicenseData: '',
            expirationDates: '',
            errorStatusIndex: '',
            errorStatusText: '',
            errorStatus: false,
            loggedInUserType: ''
        };
        this.search = debounce(this.search,100);
        this.addUserForm = null;
        this.editUserForm = null;
        this.serchTerm = "";
        this.onResetPasswordOk = this.onResetPasswordOk.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.getLicensesList = this.getLicensesList.bind(this);
        this.saveData = this.saveData.bind(this);
    }


    componentDidMount(){
        this.setState({
            loggedInUserType: window.localStorage.getItem('user_type')
        });
        districtIDFromLocalStorage = this.props.districtId
        
    }

    onChange(i,event,id) {
        this.state.form[event.target.name] = event.target.value;
        this.state.form.userIndex = id;
        this.setState({form: this.state.form});
        this.state.filteredNames[i].user_type = event.target.value;
    }

    handleDate(date,id){
        this.state.form.license_ID = date.target.value;
        this.state.form.userIndex = id;
        this.setState({form: this.state.form});
    };

    getLicensesList = async () => {
        const districtId = districtIDFromLocalStorage;
        const response = await authAPI.getDistrictLicenses({ _id: districtId });
        if (response && response.data) this.setState({ LicenseData: response.data });
    };

    checkLicenseAvailibility = async (lid, type) => {
        console.log("coming here with lid: "+lid+" and type : "+type);
        const districtId = districtIDFromLocalStorage;
        const response = await authAPI.getLicenseAvailibility({ _id: districtId, type: type, lid: lid });
        if (response) return response.data.status;
    }

    search=(val)=>{
        //console.log('search...');
        this.serchTerm = val;
        let userQuery=this.props.userType;
        
        if(this.props.userType==='combinedList') {
// Changed Teacher to Unlimited
           // userQuery='School+Admin&userType=Unlimited&userType=Print+Only';
           let selectedTypes = [];
                if (this.state.isSystemAdmin) {
                    selectedTypes.push("School+Admin");  
                }
                if (this.state.isUnlimited) {
                    selectedTypes.push("Unlimited");  
                }
                if (this.state.isPrintOnly) { 
                    selectedTypes.push("Print+Only");  
                }
                if (this.state.isTextOnly) { 
                    selectedTypes.push("Text+Only");  
                }
                                               
                userQuery = selectedTypes.join('&userType=');

                if (this.state.filterIsActive) { 
                    userQuery += "&is_active=true";
                }
        }
        else if(this.props.userType==='admin') {
            let selectedTypes = [];

            //userQuery='System+Admin&userType=Content+Editor';
            if (this.state.isSystemAdmin) {
                selectedTypes.push("System+Admin");  
            }
            if (this.state.isContentEditor) {
                selectedTypes.push("Content+Editor");  
            }
            userQuery = selectedTypes.join('&userType=');
        }
        else{
            userQuery=this.props.userType;
        }
    
        this.props.getUsers(userQuery,val,districtIDFromLocalStorage,1,0,0);

    };

    refreshSearch=()=> {
        console.log(this.state.isAdmin,'-',this.state.isSystemAdmin,'-',this.state.isPrintOnly,'-',this.state.isUnlimited,'-',this.state.isTextOnly);
        console.log(this.serchTerm);
        this.search(this.serchTerm);
        if(this.props.userType !=='admin')
            this.props.reloadLicenseDetais();
    };

    changeFunction= (event)=>{
        let val = event.target.value;
        this.setState({searchTerm: val});
        this.search(val);
    };


    isActiveChangeFunction= (event)=>{
        if (event.target.checked === true) {
            this.setState({filterIsActive: true},()=>{
                this.refreshSearch();
            });
        }
        else {
            this.setState({filterIsActive: false},()=>{
                this.refreshSearch();
            });
        }
    };
// Add toggle function for unlimited and print only commented out toggle for teacher above
    isUserTypeUnlimited= (event)=>{
        //console.log(event.target.checked);
        if (event.target.checked === false){
            this.setState({isUnlimited:false},()=>{
                this.refreshSearch();
            });
        }
        else {
            this.setState({isUnlimited: true},()=>{
                this.refreshSearch();
            });
        }   
       
// possible refactor?
//         this.setState({isUnlimited:event.target.checked})
    };

    isUserTypeTextOnly= (event)=>{
        //console.log(event.target.checked);
        if (event.target.checked === false){
            this.setState({isTextOnly:false},()=>{
                this.refreshSearch();
            });
        }
        else {
            this.setState({isTextOnly: true},()=>{
                this.refreshSearch();
            });
        }
    }

    isUserTypePrintOnly= (event)=>{
        if (event.target.checked === false){
            this.setState({isPrintOnly:false},()=>{
                this.refreshSearch();
            });
        }
        else {
            this.setState({isPrintOnly: true},()=>{
                this.refreshSearch();
            });
        }
    };

// -------------------------

    isUserTypeAdmin= (event)=>{
        if (event.target.checked === false){
            this.setState({isAdmin:false},()=>{
                this.refreshSearch();
            });
        }
        else {
            this.setState({isAdmin: true},()=>{
                this.refreshSearch();
            });
        }
    };

    isUserTypeSystemAdmin= (event)=>{
        if (event.target.checked === false){
            this.setState({isSystemAdmin:false},()=>{
                this.refreshSearch();
            });
        }
        else {
            this.setState({isSystemAdmin: true},()=>{
                this.refreshSearch();
            });
        }
    };

    isUserTypeContentEditor= (event)=>{
        if (event.target.checked === false){
            this.setState({isContentEditor:false},()=>{
                this.refreshSearch();
            });
        }
        else {
            this.setState({isContentEditor: true},()=>{
                this.refreshSearch();
            });
        }
    };

    onAddUserCancel = ()=>{
        this.setState({addUser: false});
    };

    onBulkAddUserCancel = ()=>{
        this.setState({bulkAddUser: false});
    };

    onlistUserCancel = ()=>{
        this.setState({listUser: false});
    };

    onEditUserCancel = ()=>{
        this.setState({editUser: false});
    };

    onDeleteUserCancel = ()=>{
        this.setState({deleteUser: false});
    };

    onResetPasswordCancel = ()=>{
        this.setState({resetPassword: false});
    };

    clearError() {
        if(this.state.errorMessage !== "") {
            this.setState({errorMessage: ""});
        }
    }

    getLicenseData() {
        return {
            userType: this.state.form.userType,
            licenseID: this.state.form.license_ID,
            usrIndex: this.state.form.userIndex
        }
    };

    saveData(data){
        this.props.updateLicenseAction(data).then((response)=>{
            if(response.message === "Update Successful"){
                this.refreshSearch();
                this.setState({listUser: false, filteredNames:this.state.filteredNames, saveStatus: true, errorStatus: false, form: { userType: "", license_ID: "", userIndex: "" } });
                setTimeout(() => {
                    this.setState({
                        saveStatus: false
                    });
                }, 2000);
            } else {
                if(response.errors[0].messages[0] == '"licenseID" must be a number'){
                    this.setState({errorStatus: true, errorStatusIndex: data.uid, errorStatusText: '"licenseID" must be a number' });
                    setTimeout(() => {
                        this.setState({
                            errorStatus: false
                        });
                    }, 5000);
                }
            }
        })
        .catch(function (error) {
            console.log("exception occurred");
        });
    }

    onAddLicenseOk = (u)=> {
        //console.log("dkwjkdwkjkw");
        let user_data = u.n;
        let data = this.getLicenseData();

        if(data.usrIndex && data.usrIndex == user_data._id) { // checking if data has been changed or not
                switch(user_data.user_type) { // checking the user type
                    case 'Unlimited':
                        if(data.licenseID && data.licenseID != user_data.license_id && data.licenseID !== 'invalid') { //if license changed then do it
                            // check if available licenses are there or not
                            this.checkLicenseAvailibility(data.licenseID, null)
                            .then(response => {
                                if(response) { // license available
                                    data.uid = user_data._id;
                                    data.userType = 'U';

                                    // call save api here.
                                    this.saveData(data);
                                } else {
                                    this.setState({errorStatus: true, errorStatusIndex: data.usrIndex, errorStatusText: 'No licenses available. Please add more license.' });
                                    setTimeout(() => {
                                        this.setState({
                                            errorStatus: false
                                        });
                                    }, 5000);
                                }
                            })

                        } else if(data.licenseID && data.licenseID == user_data.license_id && data.licenseID !== 'invalid'){        //if license not changed do nothing
                            console.log("Do nothing");
                        } else { // else show error
                            this.setState({errorStatus: true, errorStatusIndex: data.usrIndex, errorStatusText: 'Select Valid Expiration date' });
                            setTimeout(() => {
                                this.setState({
                                    errorStatus: false
                                });
                            }, 5000);
                        }
                    break;
                    case 'Text Only':
                        if(data.licenseID && data.licenseID != user_data.license_id && data.licenseID !== 'invalid') { //if license changed then do it
                            // check if available licenses are there or not
                            this.checkLicenseAvailibility(data.licenseID, null)
                            .then(response => {
                                if(response) { // license available
                                    data.uid = user_data._id;
                                    data.userType = 'T';

                                    // call save api here.
                                    this.saveData(data);
                                } else {
                                    this.setState({errorStatus: true, errorStatusIndex: data.usrIndex, errorStatusText: 'No licenses available. Please add more license.' });
                                    setTimeout(() => {
                                        this.setState({
                                            errorStatus: false
                                        });
                                    }, 5000);
                                }
                            })

                        } else if(data.licenseID && data.licenseID == user_data.license_id && data.licenseID !== 'invalid'){        //if license not changed do nothing
                            console.log("Do nothing");
                        } else { // else show error
                            this.setState({errorStatus: true, errorStatusIndex: data.usrIndex, errorStatusText: 'Select Valid Expiration date' });
                            setTimeout(() => {
                                this.setState({
                                    errorStatus: false
                                });
                            }, 5000);
                        }
                    break;

                    case 'Print Only':
                        console.log("update P license here"); // change the license type directly.
                        // check if available licenses are there or not
                        this.checkLicenseAvailibility(null, 'P')
                        .then(response => {
                            if(response) { // license available
                                data.uid = user_data._id;
                                data.userType = 'P';

                                // call save api here.
                                this.saveData(data);
                            } else {
                                this.setState({errorStatus: true, errorStatusIndex: data.usrIndex, errorStatusText: 'No licenses available. Please add more license.' });
                                setTimeout(() => {
                                    this.setState({
                                        errorStatus: false
                                    });
                                }, 5000);
                            }
                        })
                    break;
                }

        }
    };

    
    onAddUserOk = ()=> {
        //console.log("dksldklskdw");
        this.addUserForm.clearError();
        if (this.addUserForm.validateInput()) {
            let data = this.addUserForm.getData();
            let newObj={};
            if(data.userType==="System Admin"){
                newObj = {
                    "username": data.email,
                    "first_name": data.firstName,
                    "last_name": data.lastName,
                    "password": "TempPassword2017!",
                    "password_type": "standard",
                    "user_type": data.userType,
                    "district_id": data.districtId
                }
            }
            else if(data.userType==="Content Editor"){
                newObj = {
                    "username": data.email,
                    "first_name": data.firstName,
                    "last_name": data.lastName,
                    "password": "TempPassword#2",
                    "password_type": "standard",
                    "user_type": data.userType,
                    "district_id": data.districtId
                }
            }
            else{
                newObj = {
                    "username": data.email,
                    "first_name": data.firstName,
                    "last_name": data.lastName,
                    "user_type": data.userType,
                    "district_id": data.districtId
                };

                if(data.schoolId !== "") {
                    newObj.user_id_number = data.schoolId
                }

                if(data.password){
                    newObj.password = data.password;
                }
            }

             this.props.postUserAction(newObj).then((response)=>{
                if(response.statusCode === 201 || response.statusCode === 200){
                   newObj.is_active=true;
                   this.refreshSearch();
                   this.setState({addUser: false,filteredNames:this.state.filteredNames});
                }
                else{
                    this.addUserForm.showError(response.response.data.message);
                    console.log("error occurred");
                }
            })
            .catch(function (error) {
                console.log("exception occurred");
            });
        }
    };

    onEditUserOk = ()=>{
        if (this.editUserForm.validateInput()) {
            let data = this.editUserForm.getData();
            let newObj={};
            if(data.userType==="System Admin" || data.userType==="Content Editor"){
                newObj = {
                    "username": data.email,
                    "first_name": data.firstName,
                    "last_name": data.lastName,
                    "is_active": data.isActive,
                    "user_type": data.userType,
                    "district_id": data.districtId
                }
            } else {
                newObj = {
                    "username": data.email,
                    "first_name": data.firstName,
                    "last_name": data.lastName,
                    "is_active": data.isActive,
                    "user_id_number": data.schoolId,
                    "user_type": data.userType
                }
            }

            // if (data.userType == 'Unlimited') {
            //     newObj.expiration_date = data.expirationDate;
            // }

            if(data.password) {
                newObj.password = data.password;
            }
           
            this.props.putUserAction(this.state.editUserData._id, newObj).then((response)=>{
                if(response.message === "Update Successful"){
                    this.refreshSearch();
                    this.setState({editUser: false,filteredNames:this.state.filteredNames});
                    //window.location.reload(false);
                }
                else{
                    console.log("error occurred");
                }
            })
            .catch(function (error) {
                console.log("exception occurred");
            });
        }
    };

    onDeleteUserOk = ()=>{
        this.setState({deleteUser: false});
        console.log("deleted");
        this.props.deleteUser(this.state.deleteUserId)
            .then((response)=>{
                let self =this;
                if(response.message === "Resource Deleted"){
                    let deleteIndex='';
                    let finalDeletedList='';
                    //No array return
                    // eslint-disable-next-line
                    self.state.filteredNames.map((finalList,i)=>{
                        if(finalList._id===self.state.deleteUserId){
                            deleteIndex=i;
                        }
                    });
                    finalDeletedList = self.state.filteredNames.splice(deleteIndex,1)
                    this.setState({
                        filteredNames:finalDeletedList
                    });
                    console.log("deleted")
                    //this.state.filteredNames.map()
                    // this.setState({
                    //     filteredNames:this.state.filteredNames
                    // })
                }
                else{
                    console.log("error occurred")
                }
            })
            .catch(function (error) {
                console.log("exception occurred");
            });
    };

    onResetPasswordOk() {
        this.setState({resetPassword: false});
        this.props.resetPassword(this.state.resetPasswordId);
    };

    render(){
        const licenses={
            unlimited_count: this.props.unlimited_count,
            unlimited_available: this.props.unlimited_available,
            print_only_count: this.props.print_only_count,
            print_only_available: this.props.print_only_available
        };
        // const districDetails={
        //     distDetails: this.props.districtDetails 
        // };
        // console.log(districDetails);
        const Item = items[this.props.item];

        let filteredNames,filteredValues,activeFilter;
        // if(this.props.users.asyncStatus === 'SUCCESS'){
            filteredNames = this.props.users.data[this.props.userType]?this.props.users.data[this.props.userType].results:[];
            // No need to render again
            // eslint-disable-next-line
            this.state.filteredNames = filteredNames;
            // filteredValues = this.state.filterIsActive ? filteredNames.filter(n=>n.is_active === this.state.filterIsActive):filteredNames;
            activeFilter = this.state.filterIsActive ? filteredNames && filteredNames.filter(n=>n.is_active === this.state.filterIsActive):filteredNames;

// Added Filters for all combinations of switches.
// Changed this function to filter for unlimited and print only
            if(this.state.isAdmin===false && this.state.isUnlimited===false && this.state.isPrintOnly===false && this.state.isTextOnly===false){
               filteredValues = [];
            }
            else if(this.state.isAdmin===false && this.state.isUnlimited===false && this.state.isTextOnly ===false){
                filteredValues = this.state.isAdmin===false?activeFilter && activeFilter.filter(n=>n.user_type === 'Print Only'):activeFilter;
            }
            else if(this.state.isAdmin===false && this.state.isPrintOnly===false && this.state.isTextOnly ===false){
                filteredValues = this.state.isAdmin===false?activeFilter && activeFilter.filter(n=>n.user_type === 'Unlimited'):activeFilter;
            }
            else if(this.state.isAdmin===false && this.state.isUnlimited===false && this.state.isPrintOnly ===false){
                filteredValues = this.state.isAdmin===false?activeFilter && activeFilter.filter(n=>n.user_type === 'Text Only'):activeFilter;
            }
            else if(this.state.isUnlimited===false && this.state.isPrintOnly===false && this.state.isTextOnly === false){
                 filteredValues = this.state.isUnlimited===false?activeFilter && activeFilter.filter(n=>n.user_type === 'School Admin'):activeFilter;
            }
// Changed Teacher to Unlimited
            else if(this.state.isAdmin===false){
                filteredValues = this.state.isAdmin===false?activeFilter && activeFilter.filter(n=>n.user_type === 'Unlimited' || n.user_type === 'Print Only' || n.user_type === 'Text Only'):activeFilter;
            }
            else if(this.state.isUnlimited===false){
                filteredValues = this.state.isUnlimited===false?activeFilter && activeFilter.filter(n=>n.user_type === 'School Admin' || n.user_type === 'Print Only' || n.user_type === 'Text Only'):activeFilter;
            }
// Added line to filter is Print Only
            else if(this.state.isPrintOnly===false){
                 filteredValues = this.state.isPrintOnly===false?activeFilter && activeFilter.filter(n=>n.user_type === 'School Admin' || n.user_type === 'Unlimited' || n.user_type === 'Text Only'):activeFilter;
            }
            else if(this.state.isTextOnly===false){
                filteredValues = this.state.isTextOnly===false?activeFilter && activeFilter.filter(n=>n.user_type === 'School Admin' || n.user_type === 'Unlimited' || n.user_type === 'Print Only'):activeFilter;
           }
            else if(this.state.isSystemAdmin===false){
                filteredValues = this.state.isSystemAdmin===false?activeFilter && activeFilter.filter(n=>n.user_type === 'Content Editor'):activeFilter;
            }
            else if(this.state.isContentEditor===false ){
                filteredValues = this.state.isContentEditor===false?activeFilter && activeFilter.filter(n=>n.user_type === 'System Admin'):activeFilter;
            }
            else{
                filteredValues = activeFilter;
            }

        // }
        return(
            <div>
                {(this.state.addUser || this.state.editUser || this.state.bulkAddUser || this.state.deleteUser)?this.props.backgroundChange(true):this.props.backgroundChange(false) }
                {this.state.addUser &&
                    <Dialog onCancel={this.onAddUserCancel} onOk={this.onAddUserOk} userType={this.props.userType} title="ADD USER" okText="ADD" okStyle="dialog-save-button">
                        <UserForm ref={(el)=>{this.addUserForm = el}} userType={this.props.userType.replace("+"," ")} districtId={districtIDFromLocalStorage} {...licenses}/>
                    </Dialog>
                }

                {this.state.bulkAddUser &&
                    <BulkAddUser onCancel={this.onBulkAddUserCancel}  userType={this.props.userType} districtId={districtIDFromLocalStorage} bulkAddType={this.props.bulkAddType} refreshUsers={this.refreshSearch}/>
                }

                {this.state.editUser &&
                    <Dialog onCancel={this.onEditUserCancel} userType={this.props.userType} onOk={this.onEditUserOk} title="EDIT USER" okText="SAVE" okStyle="dialog-save-button">
                        <UserForm ref={(el)=>{this.editUserForm = el}} userData={this.state.editUserData} {...licenses} />
                    </Dialog>
                }

                {this.state.listUser &&
                    <Dialog onCancel={this.onlistUserCancel} userType={this.props.userType} title="USER LICENSES" okStyle="hidden" isListUser="true">
                        <div className="dialog-spacer">
                            
                            <InfiniteScroll height={400 + "px"}
                                     next={()=>{
                                            if(this.props.userType==='combinedList') {
                                              this.props.getUsersAction('School+Admin&userType=Unlimited&userType=Print+Only&userType=Text+Only', this.state.searchTerm, districtIDFromLocalStorage, +this.props.users.page + 1,0)
                                            }
                                        }
                                     }
                                     hasMore={this.props.users.hasMore[this.props.userType]}
                                     loader={<div>Loading...</div>}
                                    dataLength={filteredValues?.length ?? 0}
                                >
                                <div className="header-tab row">
                                    <Col className="list-item">
                                        <Col md={12} className={`successful alert alert-success ${this.state.saveStatus ? 'alert-shown' : 'alert-hidden'}`}>
                                            {this.state.saveStatus ? 'Update Successful' : ''}
                                        </Col>
                                        {/* <Col md={12} className={`notsuccessful alert ${this.state.errorStatus ? 'alert-error' : 'alert-hidden'}`}>
                                            {this.state.errorStatus ? 'Select Valid Expiration date' : ''}
                                        </Col> */}
                                        <Col md={4}>Name</Col>
                                        <Col md={3}>Select/Change the License</Col>
                                        <Col md={3}>Expiration date</Col>
                                        <Col md={2}></Col>
                                    </Col>
                                </div>
                                {filteredValues?.length && filteredValues.map((n,i)=>
                                    <div className="list-items" key={i}>
                                        <Col className="list-item row">
                                            <Col md={4}>
                                            {n.first_name &&
                                                <span>{n.first_name}&nbsp;</span>
                                            }
                                            {n.last_name &&
                                                <span>{n.last_name}</span>
                                            }
                                            </Col>
                                            <Col md={3}>
                                                {(n.expiration_date === null)  ?
                                                    <select className={"add-user-form"} type="text" name='userType' onChange={(e) => this.onChange(i, e, n._id)}>
                                                        <option selected>Select License</option>
                                                        {this.state.adminOptions.map((item, k) => (
                                                            <option key={k} value={item}>{item}</option> 
                                                        ))}
                                                    </select>
                                                    :
                                                    <select className={"add-user-form"} type="text" name='userType' onChange={(e) => this.onChange(i, e, n._id)}>
                                                        {this.state.adminOptions.map((item, k) => (
                                                            <option key={k} value={item} selected={n.user_type === item}>{item}</option> 
                                                        ))}
                                                    </select>
                                                }
                                            </Col>
                                            <Col md={3}>
                                                {n.user_type !== 'Print Only' ?
                                                    <select className={"add-user-form"} name='expireDate' onChange={(e) => this.handleDate(e, n._id)}>
                                                        <option value='invalid' selected>Select Date</option>
                                                        {this.state.LicenseData && this.state.LicenseData.map((item, i) => {
                                                            if( item.license_type === 'U' )
                                                            return <option key={i} value={item._id} selected={moment(n.expiration_date).format('MM/DD/YYYY') === item.expire_date}>{item.expire_date}</option> 
                                                        })}
                                                    </select>
                                                :
                                                    <span>Perpetual</span>
                                                }
                                                {this.state.errorStatusIndex==n._id &&
                                                    <Col md={12} className={`notsuccessful alert ${this.state.errorStatus ? 'alert-error' : 'alert-hidden'}`}>
                                                        {this.state.errorStatus ? this.state.errorStatusText : ''}
                                                    </Col>
                                                }
                                            </Col>
                                            <Col md={2}>
                                                <button type="submit" className="dialog-save-button" onClick={() => this.onAddLicenseOk({n})}>Save</button>
                                            </Col>
                                        </Col>
                                    </div>
                                )}
                            </InfiniteScroll>
                        </div>
                    </Dialog>
                }

                {this.state.deleteUser &&
                    <Dialog onCancel={this.onDeleteUserCancel} userType={this.props.userType} onOk={this.onDeleteUserOk} title="DELETE USER" okText="DELETE" okStyle="dialog-delete-button">
                        <div className="dialog-spacer">
                            Are you sure you want to delete the user "{this.state.deleteUserName}"?
                        </div>
                    </Dialog>
                }

                {this.state.resetPassword &&
                    <Dialog onCancel={this.onResetPasswordCancel}
                            onOk={this.onResetPasswordOk}
                            userType={this.props.userType}
                            title="RESET PASSWORD"
                            okText="RESET"
                            okStyle="dialog-delete-button"
                    >
                        <div className="dialog-spacer">
                            Are you sure you want to reset the user's password?
                        </div>
                    </Dialog>
                }
                <div>
                    <div className="list-header">
                        <div>
                            <span className="title-of-list">
                                <strong>
                                    {this.props.listTitle}
                                </strong>
                            </span>
                            {this.state.loggedInUserType == "System Admin" && filteredValues && districtIDFromLocalStorage &&
                                <span>
                                    <a href={this.props.templateUrl+"?user_type="+this.props.userType+"&district_id="+districtIDFromLocalStorage+"&district_name="+this.props.districtName}>
                                        {/* <i title="Download user list as CSV" className='add-item-icon icon-icon-link-circle-solid can-hover'></i> */}
                                        <img className="add-item-icon" src={iconDownload} title="Download user list as CSV" alt="Download user list as CSV" style={{width: '23px', marginTop: '4px', marginLeft: '4px'}} />
                                    </a>
                                </span>
                            }
                            {/* If either type of license is available button is enabled, If neither type of license is available, button is disabled*/}                
                            <span>
                                {
                                    (licenses.unlimited_available !== 0 || licenses.print_only_available !== 0 || this.props.userType === "Student") &&
                                    <i onClick={() => this.setState({addUser: true})} className="add-item-icon icon-icon-add-circle-solid can-hover"/>
                                }
                                {
                                    (licenses.unlimited_available === 0 && licenses.print_only_available === 0 && this.props.userType !== "Student") &&
                                    <i className="add-item-icon icon-icon-add-circle-solid" style={{color: 'lightgray'}}/>
                                }
                            </span>
                            {this.props.userType!=="admin" &&
                                <span>
                                    <i onClick={()=>this.setState({bulkAddUser: true})} className='add-item-icon icon-icon-group-circle-solid can-hover'/>
                                </span>
                            }
                            
                            {this.props.userType!=='admin' && this.props.userType==='combinedList' &&
                                <span>
                                    <i onClick={()=>{this.setState({listUser: true}); this.getLicensesList()}} className='add-item-icon icon-icon-more-circle-solid can-hover'/>
                                </span>
                            }
                        </div>
                        <div className="filter-panel">
                            <span> Search: <input type="text" placeholder={this.props.userType==='admin'? "Name or Email" : "Name, Email, or Id"} onChange={this.changeFunction} value={this.state.searchTerm} className="admin-search-box"/></span>
                            <span> Active Users: <input type="checkbox" onChange={this.isActiveChangeFunction} /></span>
                            {this.props.userType==='combinedList' &&
                                <span style={{marginLeft:'22px'}}>
                                    Role: <input type="checkbox" id="schoolAdmin" checked={this.state.isAdmin} onChange={this.isUserTypeAdmin} /> <label style={{fontWeight:'normal'}}>School Admin</label>
                                    {/*<input type="checkbox" id="teacher" checked={this.state.isTeacher} style={{marginLeft:'15px'}} onChange={this.isUserTypeTeacher} /> <label style={{fontWeight:'normal'}}>Teacher</label>*/}
{/* Checkboxes for unlimited and print only Commented out checkbox for teacher above */}
                                    <input type="checkbox" id="unlimited" checked={this.state.isUnlimited} style={{marginLeft:'15px'}} onChange={this.isUserTypeUnlimited} /> <label style={{fontWeight:'normal'}}>Unlimited</label>
                                    <input type="checkbox" id="printOnly" checked={this.state.isPrintOnly} style={{marginLeft:'15px'}} onChange={this.isUserTypePrintOnly} /> <label style={{fontWeight:'normal'}}>Print Only</label>
                                    <input type="checkbox" id="textOnly" checked={this.state.isTextOnly} style={{marginLeft:'15px'}} onChange={this.isUserTypeTextOnly} /> <label style={{fontWeight:'normal'}}>Text Only</label>

                                </span>
                            }
                            {this.props.userType==='admin' &&
                                <span style={{marginLeft:'22px'}}>
                                    Role: <input type="checkbox" id="systemAdmin" checked={this.state.isSystemAdmin} onChange={this.isUserTypeSystemAdmin} /> <label style={{fontWeight:'normal'}}>System Admin</label>
                                    <input type="checkbox" id="contentEditor" checked={this.state.isContentEditor} style={{marginLeft:'15px'}} onChange={this.isUserTypeContentEditor} /> <label style={{fontWeight:'normal'}}>Content Editor</label>
                                </span>
                            }
                        </div>
                    </div>
                    <div className="list-view">
                        <InfiniteScroll height={400 + "px"}
                                     next={()=>{
                                          if(this.props.userType==='combinedList') {
// Change Teacher to Unlimited
                                              this.props.getUsersAction('School+Admin&userType=Unlimited&userType=Print+Only&userType=Text+Only', this.state.searchTerm, districtIDFromLocalStorage, +this.props.users.page + 1,0)
                                          }
                                         else if(this.props.userType==='admin') {
                                             this.props.getUsersAction('System+Admin&userType=Content+Editor', this.state.searchTerm, districtIDFromLocalStorage, +this.props.users.page + 1,0)
                                         }
                                          else {
                                             this.props.getUsersAction(this.props.userType, this.state.searchTerm, districtIDFromLocalStorage, +this.props.users.page + 1,0)
                                          }
                                        }
                                     }
                                     hasMore={this.props.users.hasMore[this.props.userType]}
                                     loader={<div>Loading...</div>}
                                     dataLength={filteredValues?.length ?? 0}
                            >
                            {filteredValues?.length && filteredValues.map((n,i)=>
                                <div className="list-items" key={i}>
                                    <Col className="list-item" md={6} sm={6} >
                                        <Item n={n}/>
                                        <span onClick={()=>this.setState({resetPassword: true, resetPasswordId : n._id})} className="can-hover">
                                            <i className="reset-password-icon icon-icon-key"/>
                                        </span>
                                        <span onClick={()=>this.setState({deleteUser: true, deleteUserId : n._id, deleteUserName: n.first_name + " " + n.last_name})} className="can-hover">
                                            <i className="delete-item icon-icn-close"/>
                                        </span>
                                        <span onClick={()=>this.setState({editUser: true, editUserData:n})} className="can-hover">
                                            <i className="edit-item-icon icon-icon-pen-circle-outline"/>
                                        </span>
                                        <div className="item-border"/>
                                    </Col>
                                </div>
                            )}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        users : state.Users,
    }
};

export default connect(mapStateToProps, actions)(ListView)
