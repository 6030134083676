import React,{Component} from  'react'
import CollapsibleComponent from '../../containers/Details/CollapsibleComponent'
import TextContent from '../../containers/Details/TextContent'
import ImageDetails from '../../containers/Details/ImageDetailsComponent'
import {markdownHelper} from '../../markdown/MarkdownHelper';
import mySolidArrowRight from '../../assets/icons/SVG/my-solid-arrow-right.png';
import mySolidArrow from '../../assets/icons/SVG/my-solid-arrow.png';
import interactive from '../../assets/images/interactive.png';

class AppendicesOverview extends Component{
    constructor (props) {
        super(props);
        this.state = {
            texts: this.props.texts,
            skills: this.props.skills,
            description: this.props.description,
            grades: this.props.grades,
            resource:this.props.resource
        }
    }

    render(){
        return(
            <div className="list-of-items">
                {this.state.description && this.state.description.text.length > 0 &&
                   <CollapsibleComponent collapsed={false} header="DESCRIPTION" headerStyle={{fontSize: "17px",letterSpacing:1.5,height:20.5, fontWeight:'bold', color: "#4A4A4A", borderBottom: "1px solid rgba(151, 151, 151,0.5)", paddingBottom: "35px", paddingTop: "15px",fontFamily:"ProximaNova" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                       <div className="text-in-collapsible-component" dangerouslySetInnerHTML={{__html: markdownHelper.toHTML(this.state.description.text)}} />
                        {this.state.description.interactive && 
                          <img src={interactive} style={{width:"99px",height:"25px", "marginLeft":"12px", "marginBottom":"10px"}}/>
                        }
                   </CollapsibleComponent>
                }
                {this.state.grades && this.state.grades.length > 0 &&
                   <CollapsibleComponent collapsed={false} header="GRADES" headerStyle={{fontSize: "17px",letterSpacing:1.5,height:20.5, fontWeight:'bold', color: "#4A4A4A", borderBottom: "1px solid rgba(151, 151, 151,0.5)", paddingBottom: "35px", paddingTop: "15px",fontFamily:"ProximaNova" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        <div className="text-in-collapsible-component">
                          {this.state.grades}
                        </div>
                   </CollapsibleComponent>
                }
                {this.state.texts && this.state.texts.length > 0 &&
                   <CollapsibleComponent collapsed={false} header="TEXTS" headerStyle={{fontSize: "17px",letterSpacing:1.5,height:20.5, fontWeight:'bold', color: "#4A4A4A", borderBottom: "1px solid rgba(151, 151, 151,0.5)", paddingBottom: "35px", paddingTop: "15px",fontFamily:"ProximaNova" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        <TextContent requestType='assessments' content={{mentorTexts: this.state.texts}} />
                   </CollapsibleComponent>
                }
                 {this.state.skills && this.state.skills.length > 0 &&
                    <CollapsibleComponent collapsed={false} header="RESOURCE TYPE" headerStyle={{fontSize: "17px",letterSpacing:1.5,height:20.5, fontWeight:'bold', color: "#4A4A4A", borderBottom: "1px solid rgba(151, 151, 151,0.5)", paddingBottom: "35px", paddingTop: "15px",fontFamily:"ProximaNova" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        <div className="text-in-collapsible-component">
                          {this.state.resource}
                        </div>
                    </CollapsibleComponent>
                }
                {this.state.skills && this.state.skills.length > 0 &&
                    <CollapsibleComponent collapsed={false} header="SKILLS & STRATEGIES" headerStyle={{fontSize: "17px",letterSpacing:1.5,height:20.5, fontWeight:'bold', color: "#4A4A4A", borderBottom: "1px solid rgba(151, 151, 151,0.5)", paddingBottom: "35px", paddingTop: "15px",fontFamily:"ProximaNova" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        <div className="text-in-collapsible-component">
                          {this.state.skills}
                        </div>
                    </CollapsibleComponent>
                }
            </div>
        );
    };
}

export default AppendicesOverview;
