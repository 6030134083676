import {GET_HEADING,GET_DESCRIPTION,NEW_UNIT_ID} from '../actions'


const initialState={};

const NewUnitReducer=(state=initialState,action)=>{
    switch(action.type){
       
        case GET_HEADING:
            return Object.assign({}, state, {heading:action.payload});
        case GET_DESCRIPTION:
            return Object.assign({}, state, {description:action.payload});
        case NEW_UNIT_ID:
            return Object.assign({}, state, {newId:action.payload});
        default:
            return state;
    }
}

export default NewUnitReducer;

