import React, {Component} from 'react'
import { Row, Col, Alert } from 'react-bootstrap'
import "../assets/styles/login.scss"
import * as actions from '../redux/actions'
import {connect} from 'react-redux'
import {browserHistory,Link} from 'react-router'
import '../assets/styles/mobile.scss';
import iconSchoolWide from '../assets/icons/SVG/icon-schoolwide.svg';

import createClass from 'create-react-class';

class PicturePassword extends Component {
    constructor(){
        super();
        this.state = {
            username: "",
            isUsernameSubmitted: false,
            passwordSubmitted: false,
        };
        this.onContinueClicked = this.onContinueClicked.bind(this);
        this.onPictureClicked = this.onPictureClicked.bind(this);
        this.handleUserNameInput = this.handleUserNameInput.bind(this);
    }

    getPictureData() {
        if(this.props.loginData.asyncStatus === "SUCCESS" && this.props.loginData.data && this.props.loginData.data.length > 0) {
            return this.props.loginData.data;
        } else {
            return [];
        }
    }

    handleUserNameInput(event){
        this.setState({
            username: event.target.value
        });
    }

    handleError(err) {
        this.setState({
            error: true,
            errorMsg: err
        });
    }

    componentWillReceiveProps(next) {
      if (next.loginData) {
        this.setState({
          errorMail: Boolean(next.loginData.error),
          errorMsg: "Please enter a valid email address."
        })
      }
        if(next.loginData.set_password)
            browserHistory.push({
              pathname: '/set-password',
              state: {
                  domain: this.props.params.domain,
                  username: this.state.username.trim()
                }
              });
        if(this.state.passwordSubmitted) {
            if (next.loginStatus.userData) {
                localStorage.clear();
                localStorage.user_type = next.loginStatus.userData.user_type;
                localStorage.trialPopupDismissed = "false";
                if (next.loginStatus.userData.district_id !== undefined) {
                    localStorage.district_id = next.loginStatus.userData.district_id;
                }
                if (next.loginStatus.userData.first_time_login === true && next.loginStatus.userData.user_type !== 'System Admin') {
                    browserHistory.push('/password');
                } else {
                    if (localStorage.user_type !== "Student") {
                        browserHistory.push('/planner');
                    } else {
                        localStorage.picture_password_user = "true";
                        localStorage.login_domain = this.props.params.domain ? this.props.params.domain : "false";
                        browserHistory.push('/home');
                    }
                }
            } else {
                this.handleError("Login Failed");
            }
        }
    }

    onContinueClicked() {
        let username = this.state.username.trim();
        if(this.props.params.domain)
          username += `@${this.props.params.domain}`;
        this.props.loginWithUsernameAction(username);
        this.setState({
            isUsernameSubmitted: true
        });
    }

    onPictureClicked(picture) {
        let username = this.state.username.trim();
        if(this.props.params.domain)
            username += `@${this.props.params.domain}`;
        this.props.login(username, picture.uuid);
        this.setState({
            passwordSubmitted: true
        });
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.onContinueClicked();
        }
    };

    render() {
        return (
            <div className="bg-color">
                <div className="login-box">
                    <div className="imgcontainer">
                        <img src={iconSchoolWide} alt="Avatar" className="avatar"/>
                    </div>
                    <If test={this.state.error || this.state.errorMail}>
                        <Alert id="alert-danger" bsStyle="danger">
                            <strong>
                                {this.state.errorMsg}
                            </strong>
                        </Alert>
                    </If>
                    {(!this.state.isUsernameSubmitted || this.state.errorMail) ?
                      <div className="align-textbox">
                        {/*Render username input, and domain if given*/}
                        <span className='login-icons icon-icon-user'/>
                        <input className={this.props.params.domain? 'userid-domain' : 'userid'}
                               type="text"
                               disabled={this.state.isUsernameSubmitted && !this.state.errorMail}
                               placeholder={this.props.params.domain? 'Username' : 'Email' }
                               value={this.state.username}
                               name="uname" required
                               onChange={this.handleUserNameInput}
                               onKeyPress={this.handleKeyPress}
                        />
                        {this.props.params.domain &&
                          <input
                              type="text"
                              className="domain"
                              disabled={true}
                              placeholder="domain"
                              value={'@' + this.props.params.domain}
                              name="domain"/>
                        }
                    </div> :
                    <div className="select-picture-passwordtext">
                      {this.props.loginData.asyncStatus === 'SUCCESS' &&
                          <span>Please select your password picture</span>
                      }
                    </div>
                    }
                    {(!this.state.isUsernameSubmitted || this.state.errorMail) &&
                        <div className="align-button">
                            <button className="format-button" onClick={this.onContinueClicked}>
                                Continue
                            </button>
                        </div>
                    }
                <div className="picture-login-box">
                    {this.state.isUsernameSubmitted && !this.state.errorMail &&
                        <Row>
                            {this.getPictureData().map(pictureData => {
                                return (<Col md={2} sm={3} xs={4} style={{textAlign: 'center'}}>
                                            <img alt={pictureData.uuid}
                                                 src={pictureData.path}
                                                 className="picture-password-img"
                                                 onClick={() => {
                                                     this.onPictureClicked(pictureData)
                                                 }}
                                                />
                                        </Col>);
                                    })
                                }
                        </Row>
                    }
                  </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginReducer,
        loginStatus: state.User.LoginStatus
    }
};

let If = createClass({
    render: function() {
        if (this.props.test) {
            return this.props.children;
        }
        else {
            return false;
        }
    }
});

export default connect(mapStateToProps, actions)(PicturePassword)
