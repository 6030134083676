import React,{Component} from  'react'
import {Row,Col} from 'react-bootstrap';
import {markdownHelper} from '../../markdown/MarkdownHelper'

class ResourcesOverview extends Component{

    constructor (props) {
        super(props);
        this.state = {
            description: this.props.description,
            grades: this.props.grades,
            resourceType: this.props.resourceType,
            skills: this.props.skills,
            file: this.props.file
        }
    }

    render(){
        let openButtonText = this.state.resourceType === "Videos" ? "OPEN VIDEO" : "OPEN FILE";
        let buttonUrl = this.state.resourceType === "Videos" ?
            this.state.file : "../../schoolwidefun/api/v1/files/" + this.state.file;
        return(
            <div className="list-of-items">
                <Row id='summary-row'>
                    <Col md={2} className='summary-heading' id='bcol-padding'>
                      DESCRIPTION
                    </Col>
                    <Col md={10} className='summary-content'>
                        <div dangerouslySetInnerHTML={{__html: markdownHelper.toHTML(this.state.description.text)}} />
                    </Col>
                </Row>
                <div className='main-seperator'/>
                <Row id='summary-row'>
                    <Col md={2} className='summary-heading' id='bcol-padding'>
                      GRADES
                    </Col>
                    <Col md={10} className='summary-content'>
                        {this.state.grades}
                    </Col>
                </Row>
                <div className='main-seperator'/>
                <Row id='summary-row'>
                    <Col md={2} className='summary-heading' id='bcol-padding'>
                      RESOURCE TYPE
                    </Col>
                    <Col md={10} className='summary-content'>
                        {this.state.resourceType}
                    </Col>
                </Row>
                <div className='main-seperator'/>
                <Row id='summary-row'>
                    <Col md={2} className='summary-heading' id='bcol-padding'>
                      SKILLS & STRATEGIES
                    </Col>
                    <Col md={10} className='summary-content'>
                        {this.state.skills.map((skill) => (
                            <div>{skill}</div>
                        ))}
                    </Col>
                </Row>
                <div className='main-seperator' style={{marginBottom: '25px'}}/>
            </div>
        );
    };

}

export default ResourcesOverview
