import React,{Component} from 'react'
import {Col,Row} from 'react-bootstrap'

class Student extends Component {

    render(){
        return(
            <div>
                { this.props.user &&
                    <div>
                        {/*<div className="format-radio-button-student" style={{float: "left"}}> <input style={{height: 16,width:16}} type="radio" name="classroom"/></div>*/}
                        <span className="my-class-list-item-user-icon">
                            <i className="icon-icon-user list-item-user-img" />
                        </span>
                        <Col md={9} sm={8} xs={8}>
                            <Row>
                                {this.props.user.first_name && <span>{this.props.user.first_name}</span>}
                                {this.props.user.last_name && <span> {this.props.user.last_name}</span>}
                            </Row>
                            <Row className="item-sub-text">
                                {this.props.user.user_id_number && this.props.user.user_id_number !== "" && this.props.user.user_id_number !== " " &&
                                    <span> ID: {this.props.user.user_id_number}
                                        {this.props.user.username && this.props.user.username!=="" && this.props.user.username !== " " &&
                                            <span>&nbsp;&#8226;&nbsp;</span>
                                        }
                                    </span>
                                }
                                {this.props.user.username && this.props.user.username!=="" && this.props.user.username !== " " &&
                                    <span>USERNAME: {this.props.user.username} </span>
                                }
                            </Row>
                        </Col>
                    </div>
                }
            </div>
        );
    }
}

export default Student
