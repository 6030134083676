import React, { Component } from 'react'
import { Radio } from 'react-bootstrap'
import constants from '../constants'
import DeselectableRadioBtnGroup from './DeselectableRadioBtnGroup'
import _ from 'lodash'

export default class FilterStrandSection extends Component {
  constructor(){
    super();
    this.state = {
      url: (this.props && this.props.location.pathname) || window.location.href,
      strands: {} // Key: radio button name, Value: checked state. True = checked, undefined = unchecked, null = disabled
    }
  }

  componentWillMount(){
    this.updateStrandNames();

    // let isDisabledOnBrowse = this.getDisabledRadioState(strandName),
    //     isDefaultChecked   = !isDisabledOnBrowse && (this.state.url.indexOf("/browse/") > -1);
  }

  componentDidMount(){
    if(this.props.presetStrand && this.props.location.pathname.includes('/library'))
      this.radioBtnOnClick(null, this.getStrandNames(this.state.url, false)[this.props.presetStrand - 1], true);
  }

  componentWillReceiveProps(props){
    if(props.location.pathname !== this.props.location.pathname){
      //Update strand names will update the URL to prevent race conditions.
      this.updateStrandNames(props.location.pathname);
    }
  }

  updateStrandNames = ( url = null ) => {
    url = url || this.state.url;

    let strandNames = this.getStrandNames(url),
        radioBtnStates = [];

    for(let name of strandNames){
      let dis       = this.getDisabledRadioState(name),
          isActive  = url.indexOf( constants.normalizeTypeName(name) ) > -1;

      radioBtnStates.push({checked: isActive, disabled: dis});
    }
    let obj = _.zipObject(strandNames, radioBtnStates);

    this.setState({
      strands: obj,
      url: url
    })
  }

  radioBtnOnClick=(event, name, isOnMount = false)=>{
    let r = _.cloneDeep(this.state.strands);
    if(r[name].checked){
      r[name].checked = false;
      this.props.setStrand(null);
    } else {
      _.forOwn(r, (value, key)=>{
        r[key].checked = key == name ? true : false;
      });
      if(!isOnMount)
          this.props.setStrand(name);
    }
    this.setState({strands: r});
  }

  // The order we display strand name may not be their inherent numerical ordering.
  getStrandNames = (url, display = true) => {
    let arrayOfStrandNames = [];

    if(url.indexOf("resources") > -1 || url.indexOf("assessments") > -1)
      arrayOfStrandNames = display ?
                          ["Reading", "Writing", "Language", "Speaking & Listening"] :
                          ["Reading", "Writing", "Speaking & Listening", "Language"];

    else if(url.indexOf("units") > -1)
      arrayOfStrandNames = ["Reading", "Writing", "Grammar"];

    return arrayOfStrandNames;
  }

  //Pass this to the disabled prop, bound to the name. Check if the name is in the url (active strand) and is browse.
  getDisabledRadioState = ( strandName )=>{
    const isMyLibrary = this.state.url.indexOf("/library/") > -1; // If not library, all not disabled
          //isActive    = this.state.url.indexOf( constants.normalizeTypeName(strandName) ) > -1;     // If active and not my library, not disabled

    return !isMyLibrary;
  }

  /*
   *  Expects URL to determine strands to display.
   */
  render(){
    return (
      <div style={{paddingLeft: '20px'}}>
        {
          <DeselectableRadioBtnGroup  radios={this.state.strands}
                                      onClickCallback={this.radioBtnOnClick}
                                      pathname={this.props.location.pathname}/>
        }
      </div>
    )
  }
}
