import React,{Component} from 'react'
import {Dropdown, Glyphicon, MenuItem, Button, ButtonGroup} from 'react-bootstrap'
import {withRouter,browserHistory} from 'react-router'
import StrandButton from '../../components/Strand/StrandButton'
import "../../assets/styles/mobile.scss"
// Props
// onClick
// label
// isSelected
let urlMap=[],NameMap=[],url='';
class ResponsiveDetailButtonGroup extends Component{
    render(){
        if(this.props.itemType==='units'){
            urlMap = ['overview','lessons','texts','vocabulary','appendices','assessments','correlations'];
            NameMap= ["OVERVIEW","LESSONS","TEXT","VOCABULARY","APPENDICES","ASSESSMENTS","CORRELATIONS"];
        } 
        else if(this.props.itemType==='lessons'){
            urlMap = ['summary','preparation','lesson','whats-next'];
            NameMap=["SUMMARY","PREPARATION","LESSON","WHAT'S NEXT"];
        }
        return(
            <div >
                <div id='nav-button-alternative' className='visible-xs'>
                    <Dropdown style={{display: 'block', position: 'fixed', bottom: '70px', width: '100%', left: 0, right: 0}} dropup id="strand-btn-responsive">
                        <Dropdown.Toggle className="dropdown-toggle-styles">
                        <span style={{float: 'left', fontWeight: 'bold', fontFamily: 'ProximaNova', lineHeight: '20px'}}>{this.props.selectedTab}</span>
                        <span style={{float: 'right', paddingRight: '10px', fontSize: '9px', lineHeight: '20px'}} className="icon-icon-chevron-up"/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        {NameMap.map( (strand, i) => (
                            <MenuItem
                                eventKey={strand}
                                key={`nav-btn-${i}`}
                                onSelect={()=>this.props.selectTab(NameMap[i])}>
                                <StrandButton
                                    value={i}
                                    name={NameMap[i]}
                                    selected={this.props.selectedTab===NameMap[i]}
                                    path={'/'+this.props.itemType+'/'+this.props.id +'/'+urlMap[i]}
                                    className="full-width-btn"/>
                            </MenuItem>
                        ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        );
    }
}

export default withRouter(ResponsiveDetailButtonGroup)
