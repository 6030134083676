import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap'
import Slider from 'react-slick';
import {browserHistory, Link} from 'react-router'
import constants from '../constants';
import {connect} from 'react-redux'
import * as actions from '../redux/actions';
import PopOverAllResources from './shared/PopOverAllResources';

class SimpleSlider extends Component{

    handleLine(){
        if(this.props.name === "Featured Speaking & Listening" || this.props.name === "Recent Activity"){
            return true
        }
        else{
            return false
        }
    }

    onClickEvent(item) {
        if(this.props.carouselItem === "units" || item.type === "Unit") {
            browserHistory.push("/units/" + item._id);
        }
        else if(this.props.carouselItem === "assessments" || item.type === "assessments" || item.type === "Assessment") {
            browserHistory.push("/assessments/" + item._id);
        }
        else if(item.type === "Teaching Resource" || item.type === "Resource" ){
            browserHistory.push('/resources/'+item._id);
        }
        else if(item.type === "Book") {
            browserHistory.push('/books/'+item._id);
        }else if(item.type === "Correlations"){
            browserHistory.push('/correlations/' + item._id);
        }else {
            browserHistory.push('/lessons/'+item._id);
        }
    }

    render(){
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 5,
            arrows:true,
            swipeToSlide: false,
            responsive: [
                {
                    breakpoint: 1370,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        infinite: true,
                        swipeToSlide: false,
                    }
                },
                {
                    breakpoint: 1111,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        swipeToSlide: false
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false,
                        swipeToSlide: false,
                    }
                },
                {
                    breakpoint: 590,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                        swipeToSlide: false,
                    }
                }
            ]
        };

        return (
            <div>
                <Row className="featured-reading text-left">
                  <Col xs={9}>
                    {this.props.name}
                  </Col>
                  {this.props.viewAllUrl &&
                    <Col xs={3} className="text-right">
                    <Link to={{pathname: this.props.viewAllUrl}}
                          style={{
                            fontSize: '12px',
                            fontWeight: 'normal'
                          }}
                          >
                      View All
                    </Link>
                  </Col>}
                </Row>
              <Row className="slider-container">
                <Slider ref={c => this.slider = c } {...settings}>
                    {this.props.items.map((item, i) => (
                        <div key={item.name + i} className="reading-item" >
                            <div className='featured-image-center-resources' onClick={()=>{this.onClickEvent(item)}}>
                                <img className="image-container" src={constants.filesUrl+(item.image?.replace("../",'')||'')} />
                            </div>
                            <div className="resource-data">
                                <div className="resource-name">
                                {item.name &&
                                    <div className="slider-name" onClick={()=>{this.onClickEvent(item)}}>
                                        {item.name}
                                    </div>                                
                                }                            
                                {this.props.carouselItem==='units' || item.type==='Unit'?
                                    item.introduction ?
                                        <div className="slider-sub-name" onClick={()=>{this.onClickEvent(item)}}>
                                            {item.introduction.text}
                                        </div>:(
                                            <div className="slider-sub-name">
                                                No Introduction
                                            </div>
                                    ):item.description ?
                                        <div className="slider-sub-name" onClick={()=>{this.onClickEvent(item)}}>
                                            {item.description.text}
                                        </div>:(
                                            <div className="slider-sub-name">
                                                No Description
                                            </div>
                                        )
                                }
                                </div>
                                <div className="add-resource">
                                    <PopOverAllResources
                                        type={item.type || this.props.carouselItem}
                                        multipleItems={this.props.multipleItems}
                                        addMultipleItems={this.props.addMultipleItems}
                                        multiSelectMode={this.props.multiSelectMode}
                                        addLessonToLibrary={this.props.addLessonToLibrary}
                                        addToLibrary={this.props.carouselItem === 'resources' ? this.props.postAddTeachingResourceToLibraryAction : (item.type === "Correlations" ? this.props.postAddCorrelationToLibraryAction : ((this.props.carouselItem === "units" || item.type === "Unit") ? this.props.postAddUnitToLibraryAction : ((this.props.carouselItem === "assessments" || item.type === "assessments" || item.type === "Assessment") ? this.props.postAddAssessmentToLibraryAction : this.props.postAddLessonToLibraryAction)))}
                                        _id={item._id}
                                        name={item.name}
                                        item={item}
                                        handleClickForResourcesAddBtn={this.props.handleClickForResourcesAddBtn}
                                        count={`i${item._id}${i}`}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {this.handleLine()?'':<hr/>}
            </Row>
          </div>

        );
    }
}

export default connect(null, actions)(SimpleSlider)
