import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory, Link } from "react-router";
import * as actions from "../../redux/actions";
import SimpleSlider from "../../containers/carousel";
import authAPI from "../../api/authAPI";
import constants from "../../constants";
import BrowsePageContent from "../shared/BrowsePageContent";
import steps from "./stepsTR";
import _ from "lodash";
import ResponsiveResourcesNew from "./ResponsiveResourcesNew";
import ResponsiveNavBtns from "./ResponsiveNavBtns";
import UnattachedPopover from "../shared/UnattachedPopover";
import "../../assets/styles/mobile.scss";
import ResourceHeading from "../../components/ResourceHeading";
import AddToFolderModal from "../../components/AddToFolder/AddToFolderModal";
import { SnackBarModule } from "../../components/Shared";

const invertMap = [
  "Featured",
  "Reading",
  "Writing",
  "Speaking & Listening",
  "Language",
];

class TeachingResourcesContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: constants.caseMap[this.props.params.strand],
      isFeatured: false,
      DataSource: this.props,
      loadingMore: false,
      pageNumber: 1,
      data: [],
      searchedData: [],
      lessonsDetail: {},
      resourceDetails: {},
      correlationDetails: {},
      hasMore: true,
      total: 0,
      searchTotal: 0,
      filter: false,
      queryValue: "",
      multiSelectMode: false,
      multipleItems: {
        Resource: [],
        Lesson: [],
        Correlations: [],
      },
      areAllSelected: false,
      selectedItems: [],
      addedMultipleItemsToLibrary: false,
      addedMultipleItemsToFolder: false,
      isJoyrideRunning: false,
      isBackButtonPressed: false,
      selectedResourceId: "",
      isAddToFolderModalOpen: false,
      addToFolderSuccess: false,
      addToFolderError: false,
      addToLibrarySuccess: false,
      addToLibraryError: false,
      searchActive: false,
      getSearchNextPage: false,
    };

    this.continueTour = _.once(() => this.props.resetJoyride(steps.steps, 2));
    this.loadSteps = _.once(() => this.props.joyrideLoadSteps(steps.steps));
    window.sessionStorage.setItem("selectedFilters", JSON.stringify([]));
  }

  _handleAPI(pageNumber, handle) {
    if (handle === 0) {
      for (let i = 1; i <= 4; i++)
        this.props.getFeaturedTeachingResourcesAction(i);
      this.setState({
        isFeatured: true,
      });
    } else {
      this.props.getResourcesAction(pageNumber, handle);
      this.setState({
        isFeatured: false,
      });
    }
  }

  componentWillMount() {
    this._handleAPI(this.state.pageNumber, this.state.path);
    this.loadSteps();
  }

  millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  componentWillReceiveProps(next) {
    window.onpopstate = (e) => {
      this.setState({
        isBackButtonPressed: true,
      });
    };
    let path = constants.caseMap[next.params.strand];
    if (
      !this.state.searchActive &&
      next.resources.data &&
      next.resources.asyncStatus === "SUCCESS"
    ) {
      if (next.finalFilters.filterList.length === 0) {
        this.setState({
          filter: false,
          loadingMore: false,
          data: next.resources.data?.results,
          total:
            next.resources.data?.total ?? next.resources?.data?.results?.length,
        });
      }
    }

    if (this.props.params.strand !== next.params.strand) {
      this.setState(
        {
          path,
          data: [],
          pageNumber: 1,
          queryValue: "",
        },
        () =>
          this._handleAPI(
            this.state.pageNumber,
            constants.caseMap[next.params.strand]
          )
      );
    } else if (
      next.finalFilters &&
      next.finalFilters.filterList &&
      this.state.path !== 0
    ) {
      let queryString = constants.getQueryStringFromFilterList(
        next.finalFilters.filterList
      );
      if (this.state.queryValue !== queryString && queryString) {
        let filterInfinite = this.state.path;
        authAPI
          .getResources(
            1,
            filterInfinite,
            queryString,
            this.state.isBackButtonPressed
          )
          .then((resp) => {
            this.setState({
              data: resp.results ? resp.results : [],
              total: resp?.total ?? resp?.results?.length,
              pageNumber: 1,
              filter: true,
              queryValue: queryString,
              hasMore: true,
            });
          });
      }
    }

    if (this.props.location.state && this.props.location.state.beginTour) {
      this.setState({ continueTour: false });
      this.continueTour();
    }

    if (
      next?.resourceDetails?.asyncStatus &&
      this.state.selectedDetailsType === "Resource"
    ) {
      if (next?.resourceDetails?.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next?.resourceDetails?.asyncStatus === "FAILURE") {
        this.setState({ status: "FAILURE" });
      } else if (next?.resourceDetails?.data.status === "RESOURCE NOT FOUND") {
        this.setState({ status: "RESOURCE NOT FOUND" });
      } else if (next?.resourceDetails.asyncStatus === "SUCCESS") {
        let tempGrades = "";
        for (let i = 0; i < next.resourceDetails.data.grades.length; i++) {
          if (i === 0) {
            tempGrades += next.resourceDetails.data.grades[i];
          } else {
            tempGrades += ", " + next.resourceDetails.data.grades[i];
          }
        }

        const resourceDetails = {
          ...next.resourceDetails?.data,
          grades: tempGrades,
        };
        this.setState({
          status: "SUCCESS",
          correlationDetails: {},
          lessonsDetail: {},
          resourceDetails,
          selectedResourceId: next.resourceDetails.data._id,
        });
      }
    } else if (
      next?.lessonsDetail?.asyncStatus &&
      this.state.selectedDetailsType === "Lesson"
    ) {
      if (next.lessonsDetail.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next?.lessonsDetail?.asyncStatus === "FAILURE") {
        this.setState({ status: "FAILURE" });
      } else if (
        next?.lessonsDetail?.asyncStatus === "SUCCESS" &&
        next.lessonsDetail?.data?.status === "FAILURE FOR PRINT ONLY USER"
      ) {
        this.setState({ status: "FAILURE FOR PRINT ONLY USER" });
      } else if (
        next?.lessonsDetail?.asyncStatus === "SUCCESS" &&
        next.lessonsDetail?.data?.status === "FAILURE FOR NOT APPROVED"
      ) {
        this.setState({ status: "FAILURE FOR NOT APPROVED" });
      } else if (next?.lessonsDetail?.asyncStatus === "SUCCESS") {
        this.setState({
          correlationDetails: {},
          resourceDetails: {},
          lessonsDetail: next?.lessonsDetail.data,
          status: "SUCCESS",
          selectedResourceId: next.lessonsDetail.data._id,
        });
      }
    } else if (next?.correlationDetails?.asyncStatus) {
      if (next.correlationDetails.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next.correlationDetails.asyncStatus === "FAILURE") {
        this.setState({ correlationDetails: "FAILURE" });
      } else if (
        next.correlationDetails.asyncStatus === "SUCCESS" &&
        next.correlationDetails.data.status === "FAILURE FOR PRINT ONLY USER"
      ) {
        this.setState({ status: "FAILURE FOR PRINT ONLY USER" });
      } else if (
        next.correlationDetails.asyncStatus === "SUCCESS" &&
        next.correlationDetails.data.status === "FAILURE FOR NOT APPROVED"
      ) {
        this.setState({ status: "FAILURE FOR NOT APPROVED" });
      } else if (next.correlationDetails.asyncStatus === "SUCCESS") {
        let data = {
          id: next.correlationDetails.data._id,
          name: next.correlationDetails.data.name,
          publish: next.correlationDetails.data.publish,
          texts: next.correlationDetails.data.mentorTexts,
          grades: next.correlationDetails.data.grades.join(", "),
          forms: next.correlationDetails.data.files.map((file) => ({
            file,
            image: file + ".thumb.jpg",
          })),
          description: "",
          image: "",
          printable: next.correlationDetails.data.printable,
        };

        if (next.correlationDetails.data.description.description) {
          data.description =
            next.correlationDetails.data.description.description;
        } else if (next.correlationDetails.data.description.text) {
          data.description = next.correlationDetails.data.description.text;
        }

        if (next.correlationDetails.data.image) {
          data.image = next.correlationDetails.data.image;
        }

        this.setState({
          correlationDetails: data,
          status: "SUCCESS",
          selectedResourceId: next.correlationDetails.data._id,
        });
      }
    }
  }

  _loadMore() {
    if (
      this.state.searchActive &&
      !this.state.getSearchNextPage &&
      this.state.nextPage !== -1 &&
      this.state.hasMore
    ) {
      setTimeout(() => {
        this.setState({ getSearchNextPage: true });
      }, 1000);
    } else if (this.props.resources?.asyncStatus === "SUCCESS") {
      setTimeout(() => {
        if (this.state.total > this.state?.data?.length) {
          let strandInfinite = constants.caseMap[this.props.params.strand],
            filtersSet =
              this.props.finalFilters &&
              this.props.finalFilters.filterList &&
              this.props.finalFilters.filterList.length > 0,
            eitherFiltersSetOrQueryEmpty =
              (filtersSet && this.state.queryValue !== "") ||
              (!filtersSet && this.state.queryValue === "");

          if (
            this.props.params.strand !== "featured" &&
            eitherFiltersSetOrQueryEmpty
          ) {
            authAPI
              .getResources(
                this.state.pageNumber + 1,
                strandInfinite,
                this.state.queryValue
              )
              .then((resp) => {
                if (resp.total && resp?.results) {
                  this.setState({
                    total: resp.total,
                    pageNumber: Number(resp.currentPage),
                    data: _.concat(this.state.data, resp.results),
                    loadingMore: false,
                    filter: false,
                  });
                }
              });
          }
        } else {
          this.setState({
            loadingMore: false,
            hasMore: false,
          });
        }
      }, 1000);
    }
  }

  addMultipleItems = (tid, type = {}) => {
    if (type === "Resource") {
      this.state.multipleItems["Resource"].push(tid);
    } else if (type === "Correlations") {
      this.state.multipleItems["Correlations"].push(tid);
    } else {
      this.state.multipleItems["Lesson"].push(tid);
    }
    this.setState({ multipleItems: this.state.multipleItems });
  };

  deleteItem = (tid, type = "") => {
    if (type === "Resource") {
      let idx = this.state.multipleItems["Resource"].indexOf(tid);
      this.state.multipleItems["Resource"].splice(idx, 1);
    } else if (type === "Correlations") {
      let idx = this.state.multipleItems["Correlations"].indexOf(tid);
      this.state.multipleItems["Correlations"].splice(idx, 1);
    } else {
      let idx = this.state.multipleItems["Lesson"].indexOf(tid);
      this.state.multipleItems["Lesson"].splice(idx, 1);
    }

    this.setState({ multipleItems: this.state.multipleItems });
  };

  addMultipleItemsToLibrary = () => {
    for (let i = 0; i < this.state.multipleItems["Resource"].length; i++) {
      this.props
        .postAddTeachingResourceToLibraryAction(
          this.state.multipleItems["Resource"][i]
        )

        .then((response) => {
          if (response.statusCode === 200) {
            console.log(
              "Item added to library",
              this.state.multipleItems["Resource"][i]
            );
          } else {
            console.log(
              "Error ! Item not added to library",
              this.state.multipleItems["Resource"][i]
            );
          }
        })
        .catch(function (error) {
          console.log("Exception! Item not added to library");
        });
    }
    for (let i = 0; i < this.state.multipleItems["Lesson"].length; i++) {
      this.props
        .postAddLessonToLibraryAction(this.state.multipleItems["Lesson"][i])

        .then((response) => {
          if (response.statusCode === 200) {
            console.log(
              "Item added to library",
              this.state.multipleItems["Lesson"][i]
            );
          } else {
            console.log(
              "Error ! Item not added to library",
              this.state.multipleItems["Lesson"][i]
            );
          }
        })
        .catch(function (error) {
          console.log("Exception! Item not added to library");
        });
    }
    for (let i = 0; i < this.state.multipleItems["Correlations"].length; i++) {
      this.props
        .postAddCorrelationToLibraryAction(
          this.state.multipleItems["Correlations"][i]
        )

        .then((response) => {
          if (response.statusCode === 200) {
            console.log(
              "Item added to library",
              this.state.multipleItems["Correlations"][i]
            );
          } else {
            console.log(
              "Error ! Item not added to library",
              this.state.multipleItems["Correlations"][i]
            );
          }
        })
        .catch(function (error) {
          console.log("Exception! Item not added to library");
        });
    }
  };

  addMultipleItemsToFolder = () => {
    let items = [];
    let itemResources = [];
    for (let i = 0; i < this.state.multipleItems["Resource"].length; i++) {
      items.push(this.state.multipleItems["Resource"][i]);
      itemResources.push("Resource");
    }
    for (let i = 0; i < this.state.multipleItems["Lesson"].length; i++) {
      items.push(this.state.multipleItems["Lesson"][i]);
      itemResources.push("Lesson");
    }
    for (let i = 0; i < this.state.multipleItems["Correlations"].length; i++) {
      items.push(this.state.multipleItems["Correlations"][i]);
      itemResources.push("Correlations");
    }

    browserHistory.push({
      pathname: "/library/addfolder",
      state: {
        libraryItemId: items,
        libraryItemType: itemResources,
        isLibrary: false,
      },
    });
    this.props.saveAssignmentItemAction(itemResources, items);
  };

  handleAddMultiple = function (desktop) {
    this.addMultipleItemsToLibrary();
    this.setState(
      {
        multiSelectMode: !this.state.multiSelectMode,
        addedMultipleItemsToLibrary: true,
      },
      () => {
        if (desktop)
          setTimeout(
            () =>
              this.setState({
                addedMultipleItemsToLibrary: false,
                multipleItems: {
                  Resource: [],
                  Lesson: [],
                  Correlations: [],
                },
              }),
            3000
          );
      }
    );
  };

  handleAddMultipleToFolder = function (desktop) {
    this.addMultipleItemsToFolder();
    this.setState(
      {
        multiSelectMode: !this.state.multiSelectMode,
        addedMultipleItemsToFolder: true,
      },
      () => {
        if (desktop)
          setTimeout(
            () =>
              this.setState({
                addedMultipleItemsToFolder: false,
                multipleItems: {
                  Resource: [],
                  Lesson: [],
                  Correlations: [],
                },
              }),
            3000
          );
      }
    );
  };

  onSelectMenuItem = (ekey) => {
    browserHistory.replace(`/browse/teaching-resources/${ekey}`);
  };

  //This function expects to be bound in a child component's onClick
  handleClickForResourcesThumbnail = function (item) {
    if (this.props?.multiSelectMode) {
      if (
        (item.type === "Resource" &&
          this.props.multipleItems["Resource"].indexOf(item._id) === -1) ||
        (item.type === "Lesson" &&
          this.props.multipleItems["Lesson"].indexOf(item._id) === -1)
      ) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        item.type !== "Correlations" &&
          this.props.deleteItem(item._id, item.type);
      }
    } else {
      let id = item?._id ?? item?.id;
      switch (item.type) {
        case "Resource":
          browserHistory.push("/resources/" + id);
          return;
        case "Correlations":
          browserHistory.push("/correlations/" + id);
          return;
        default:
          browserHistory.push("/lessons/" + id);
          return;
      }
    }
  };

  //This function expects to be bound in a child component's onClick
  handleClickForResourcesAddBtn = function (item) {
    if (this.props.multiSelectMode) {
      if (
        (item.type === "Resource" &&
          this.props.multipleItems["Resource"].indexOf(item._id) === -1) ||
        (item.type === "Lesson" &&
          this.props.multipleItems["Lesson"].indexOf(item._id) === -1) ||
        (item.type === "Correlations" &&
          this.props.multipleItems["Correlations"].indexOf(item._id) === -1)
      ) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        this.props.deleteItem(item._id, item.type);
      }
    } else {
      //This replaces "showTooltip" in popover.
      this.setState({
        isTooltipActive: true,
        success: false,
        showDatePicker: false,
      });
    }
  };

  updateFromSearch = (data, total, clearSearch, nextPage) => {
    this.setState({
      searchTotal: total ?? data?.length ?? 0,
      searchedData: data ?? [],
      searchActive: clearSearch ? false : true,
      hasMore: nextPage !== -1 ? true : false,
    });
  };

  handleSelectResourceClick = (item) => {
    if (this.props.multiSelectMode) {
      if (
        (item.type === "Resource" &&
          this.props.multipleItems["Resource"].indexOf(item._id) === -1) ||
        (item.type === "Lesson" &&
          this.props.multipleItems["Lesson"].indexOf(item._id) === -1)
      ) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        item.type !== "Correlations" &&
          this.props.deleteItem(item._id, item.type);
      }
    } else {
      if (item.type) {
        this.setState({ selectedDetailsType: item.type });
        if (item.type === "Resource") {
          this.props.getResourceDetailsAction(item._id);
        } else if (item.type === "Correlations") {
          this.props.getCorrelationDetailsAction(item._id);
        } else {
          this.props.getLessonsDetailedAction(item._id);
        }
      }
    }
  };

  handleSuccessSnackBar = () => {
    this.handleCancel();
    this.setState({ isAddToFolderModalOpen: false });
    this.setState({ addToFolderSuccess: true });
    setTimeout(() => {
      this.setState({ addToFolderSuccess: false });
    }, 3000);
  };

  handleErrorSnackBar = (msg) => {
    this.handleCancel();
    this.setState({ isAddToFolderModalOpen: false });
    this.setState({ 
      addToFolderError: true,
      addToFolderErrorMsg:msg
    });
    setTimeout(() => {
      this.setState({ addToFolderError: false });
    }, 3000);
  };

  handleSuccessSnackBarLibrary = () => {
    this.handleCancel();
    this.setState({ addToLibrarySuccess: true });
    setTimeout(() => {
      this.setState({ addToLibrarySuccess: false });
    }, 3000);
  };

  handleErrorSnackBarLibrary = () => {
    this.handleCancel();
    this.setState({ addToLibraryError: true });
    setTimeout(() => {
      this.setState({ addToLibraryError: false });
    }, 3000);
  };

  handleBulkAddToFolderModalOpen = () => {
    this.setState({ isAddToFolderModalOpen: true });
  };

  handleBulkAddToFolderModalClose = () => {
    this.setState({ isAddToFolderModalOpen: false });
  };

  handleCancel = () => {
    this.handleDeselectAll();
    this.setState({ multiSelectMode: !this.state.multiSelectMode });
  };

  handleMultiSelect = (item) => {
    const copyArr = [...this.state.selectedItems];
    if (copyArr.length !== 0) {
      if (!copyArr.filter((e) => e.item_id === item._id).length > 0) {
        copyArr.push({
          item_id: item._id,
          item_name: item.name,
          item_type: "Resource",
        });
        let uniqueItems = [
          ...new Map(copyArr.map((v) => [v.item_id, v])).values(),
        ];
        this.setState({ selectedItems: uniqueItems });
      } else {
        copyArr.splice(
          copyArr.findIndex((x) => x.item_id === item._id),
          1
        ); //deleting
        this.setState({ selectedItems: copyArr });
      }
    } else {
      copyArr.push({
        item_id: item._id,
        item_name: item.name,
        item_type: "Resource",
      });
      let uniqueItems = [
        ...new Map(copyArr.map((v) => [v.item_id, v])).values(),
      ];
      this.setState({ selectedItems: uniqueItems });
    }
  };

  handleSelectAll = () => {
    this.setState({ areAllSelected: true });
    const copyArr = [];
    this.state.data.forEach((item) => {
      copyArr.push({
        item_id: item._id,
        item_name: item.name,
        item_type: "Resource",
      });
    });
    this.setState({ selectedItems: copyArr });
  };

  handleDeselectAll = () => {
    this.setState({ areAllSelected: false });
    this.setState({ selectedItems: [] });
  };

  handleOpenMultiselect = () => {
    this.setState({ multiSelectMode: !this.state.multiSelectMode });
  };

  handleAddToLibrary = () => {
    try {
      authAPI.bulkAddToLibrary(this.state.selectedItems).then((res) => {
        if (res.status === 200) {
          this.handleSuccessSnackBarLibrary();
        } else {
          this.handleErrorSnackBarLibrary();
        }
      });
    } catch (err) {
      console.log("Bulk add to library failed");
    }
  };

  getResources = () => {
    const resources = this.state?.searchActive
      ? this.state?.searchedData
      : this.state?.data;
    const startE = new Date().getTime();
    return resources?.length >= 0
      ? resources.map((resource, i) => (
          <ResponsiveResourcesNew
            item={resource}
            i={i}
            handleClickForResourcesThumbnail={
              this.handleClickForResourcesThumbnail
            }
            handleClickForResourcesAddBtn={this.handleClickForResourcesAddBtn}
            handleSelectResourceClick={this.handleSelectResourceClick}
            multiSelectMode={this.state.multiSelectMode}
            addMultipleItems={this.addMultipleItems}
            deleteItem={this.deleteItem}
            multipleItems={this.state.multipleItems}
            scheduleTask={this.props.postScheduledTasksAction}
            addToLibrary={
              resource.type === "Resource"
                ? this.props.postAddTeachingResourceToLibraryAction
                : resource.type === "Correlations"
                ? this.props.postAddCorrelationToLibraryAction
                : this.props.postAddLessonToLibraryAction
            }
            addLessonToLibrary={this.props.postAddLessonToLibraryAction}
            startE={startE}
            active={
              parseInt(resource?._id) ===
              parseInt(this.state.selectedResourceId)
            }
            handleMultiSelect={this.handleMultiSelect}
            selectedItems={this.state.selectedItems}
          />
        ))
      : [];
  };

  render() {
    const navButtons = (
      <ResponsiveNavBtns
        displayableSelectedStrand={
          invertMap[constants.caseMap[this.props.params.strand]]
        }
        onSelectMenuItem={this.onSelectMenuItem}
        selectedStrand={this.props.params.strand}
        btnOptions={[
          "featured",
          "reading",
          "writing",
          "language",
          "speaking-listening",
        ]}
        nextJoyrideStep={this.props.nextJoyrideStep}
        isJoyrideRunning={false}
      />
    );

    let featuredSliders = (
      <div style={{ height: "100%" }}>
        {this.props.teachingResources.asyncStatus === "SUCCESS" && (
          <div className="bottom-margin-browse">
            {this.props.teachingResources.data[1] && (
              <SimpleSlider
                name={"Featured Reading"}
                carouselItem="resources"
                items={this.props.teachingResources.data[1].results}
                viewAllUrl="/browse/teaching-resources/reading"
              />
            )}
            {this.props.teachingResources.data[2] && (
              <SimpleSlider
                name={"Featured Writing"}
                carouselItem="resources"
                items={this.props.teachingResources.data[2].results}
                viewAllUrl="/browse/teaching-resources/writing"
              />
            )}
            {this.props.teachingResources.data[4] && (
              <SimpleSlider
                name={"Featured Language"}
                carouselItem="resources"
                items={this.props.teachingResources.data[4].results}
                viewAllUrl="/browse/teaching-resources/language"
              />
            )}
            {this.props.teachingResources.data[3] && (
              <SimpleSlider
                name={"Featured Speaking & Listening"}
                carouselItem="resources"
                items={this.props.teachingResources.data[3].results}
                viewAllUrl="/browse/teaching-resources/speaking-listening"
              />
            )}
          </div>
        )}
      </div>
    );

    let beforeNonFeaturedContent = this.props.resources.asyncStatus ===
      "SUCCESS" && (
      <div className="bottom-margin-browse">
        {this.state.addedMultipleItemsToLibrary &&
          (this.state.multipleItems["Resource"].length !== 0 ||
            this.state.multipleItems["Lesson"].length !== 0 ||
            this.state.multipleItems["Correlations"] !== 0) && (
            <UnattachedPopover
              hideTooltip={() => {
                this.setState({ addedMultipleItemsToLibrary: false });
              }}
            >
              <div className="selected-checkbox-icon-add-multiple icon-icon-checkbox" />
              <span style={{ float: "left" }}>
                Successfully added&nbsp;
                <strong>
                  {this.state.multipleItems["Resource"].length +
                    this.state.multipleItems["Lesson"].length +
                    this.state.multipleItems["Correlations"].length}
                  &nbsp;
                  {this.state.multipleItems["Resource"].length +
                    this.state.multipleItems["Lesson"].length +
                    this.state.multipleItems["Correlations"].length ===
                  1 ? (
                    <span>item</span>
                  ) : (
                    <span>items</span>
                  )}
                </strong>
                &nbsp;to your Library!
              </span>
              <div>
                <span>
                  <Link to="/library/resources">
                    <button className="go-to-my-library-add-multiple">
                      GO TO MY LIBRARY
                    </button>
                  </Link>
                </span>
              </div>
            </UnattachedPopover>
          )}
      </div>
    );

    return (
      <>
        <BrowsePageContent
          name="Teaching Resources"
          asyncStatus={this.props?.resources?.asyncStatus ?? ""}
          resources={this.getResources()}
          resourceHeading={
            <ResourceHeading
              multiSelectMode={this.state.multiSelectMode}
              areAllSelected={this.state.areAllSelected}
              handleOpenMultiselect={this.handleOpenMultiselect}
              handleCancel={this.handleCancel}
              handleDeselectAll={this.handleDeselectAll}
              handleSelectAll={this.handleSelectAll}
              handleAddToLibrary={this.handleAddToLibrary}
              total={
                this.state.searchActive
                  ? this.state?.searchTotal
                  : this.state.total ?? 0
              }
              data={this.state.data}
              handleBulkAddToFolderModal={this.handleBulkAddToFolderModalOpen}
            />
          }
          selectedResource={
            this.state.resourceDetails?.id || this.state.resourceDetails?._id
              ? this.state.resourceDetails
              : this.state.lessonsDetail?.id || this.state.lessonsDetail?._id
              ? this.state.lessonsDetail
              : this.state.correlationDetails?.id ||
                this.state.correlationDetails?._id
              ? this.state.correlationDetails
              : ""
          }
          selectedResourceType={
            this.state.resourceDetails?.id || this.state.resourceDetails?._id
              ? "resource"
              : this.state.lessonsDetail?.id || this.state.lessonsDetail?._id
              ? "lesson"
              : this.state.correlationDetails?.id ||
                this.state.correlationDetails?._id
              ? "correlation"
              : ""
          }
          handleClickForResourcesThumbnail={
            this.handleClickForResourcesThumbnail
          }
          navButtons={navButtons}
          isFeatured={this.state.isFeatured}
          beforeNonFeaturedContent={beforeNonFeaturedContent}
          hasMore={this.state.hasMore}
          loadMore={this._loadMore.bind(this)}
          featuredSliders={featuredSliders}
          updateFromSearch={this.updateFromSearch}
          getSearchNextPage={this.state.getSearchNextPage}
          setNoSearchNextPage={() =>
            this.setState({ getSearchNextPage: false })
          }
          location={this.props.location}
        />

        {this.state.isAddToFolderModalOpen && (
          <AddToFolderModal
            handleClose={this.handleBulkAddToFolderModalClose}
            handleSuccessSnackbar={this.handleSuccessSnackBar}
            handleErrorSnackbar={this.handleErrorSnackBar}
            selectedItems={this.state.selectedItems}
          />
        )}

        <SnackBarModule
          addToFolderSuccess={this.state.addToFolderSuccess}
          addToFolderError={this.state.addToFolderError}
          addToFolderErrorMsg={this.state.addToFolderErrorMsg}
          addToLibrarySuccess={this.state.addToLibrarySuccess}
          addToLibraryError={this.state.addToLibraryError}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resources: state.Resources,
    teachingResources: state.TeachingResources,
    finalFilters: state.FilterScale,
    isJoyrideRunning: false, //state.JoyrideReducer.joyrideShouldRun,
    resourceDetails: state.ResourceDetails,
    lessonsDetail: state.LessonsDetail,
    correlationDetails: state.CorrelationDetails,
  };
};

export default connect(mapStateToProps, actions)(TeachingResourcesContent);
