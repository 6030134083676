import React,{Component} from 'react'
import ListView from './ListView'
import DistrictsListView from './DistrictsListView'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import "../../assets/styles/admin.scss"
import {browserHistory} from 'react-router'
import authAPI from "../../api/authAPI";

class SysAdmin extends Component{

    constructor (props) {
        super(props);
        this.state={
            disableScreen:false
        };
        this.resetPassword = this.resetPassword.bind(this);
    }

    componentDidMount(){
        this.props.getUsersAction("System+Admin&userType=Content+Editor","",localStorage.getItem("district_id"),1,0,0);
        this.props.getDistrictsAction("",1,0);
    }

    backgroundChange=(status)=>{
        this.setState({
            disableScreen:status
        });
    };

    resetPassword(user_id) {
        this.props.adminResetPassword(user_id);
    }

    // async reloadLicenseDetais(){
    //     let districtIDFromLocalStorage = localStorage.district_id;
        
    //     this.props.getUsersAction(
    //       "System+Admin&userType=Content+Editor",
    //       "",
    //       districtIDFromLocalStorage,
    //       1,
    //       0,
    //       0
    //     );
    //     const self = this;
    //     authAPI.getSchoolDistrict(districtIDFromLocalStorage).then(function(resp) {
    //       if (resp) {
    //         self.setState({
    //           districtDetails: resp,
    //           country: resp.country,
    //           region: resp.state
    //         });
    //       }
    //     });
    //   }
    

    render(){
        return(
            <div className={this.state.disableScreen?'dialog-background':''}>
                <div className="admin-bar">
                    <span className="add-padding-back-icon"><i onClick={browserHistory.goBack} className="chevron-left-demo-icon icon-icon-back"/></span>
                    System Admin
                </div>
                <div className="main-area">
                    {localStorage.user_type === "System Admin" &&
                        <div>
                            <DistrictsListView item="DistrictItem" backgroundChange={this.backgroundChange} getDistricts={this.props.getDistrictsAction} deleteDistrict={this.props.deleteDistrictAction} heightOfTheList="400px" listTitle="Manage Districts" />
                            <ListView userType="admin"
                                      item="SysAdminItem"
                                      backgroundChange={this.backgroundChange}
                                      getUsers={this.props.getUsersAction}
                                      deleteUser={this.props.deleteUserAction}
                                      heightOfTheList="400px"
                                      listTitle="Manage System Admins and Content Editors"
                                      resetPassword={this.resetPassword}
                                      //reloadLicenseDetais={this.reloadLicenseDetais}
                                      district_id={localStorage.district_id}

                            />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        users : state.Users,
        districts: state.Districts
    }
};

export default connect(mapStateToProps, actions)(SysAdmin)

