import React, { Component } from "react";
import googleClassroomIcon from "../../assets/icons/SVG/Google-Classroom.svg";
import pdfIcon from "../../assets/icons/SVG/pdf.svg";
import { withRouter } from "react-router";
import checkMarkDark from "../../assets/icons/SVG/my-check-image.svg";
import XIcon from "../../assets/icons/SVG/icon-dark-x.svg";
import constants from "../../constants";
import { Grid, Col, Row, Image } from "react-bootstrap";
import "../../assets/styles/mobile.scss";
import folderBigIcon from "../../assets/icons/SVG/folder-big.svg";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import authAPI from "../../api/authAPI";
import remove from "../../assets/icons/SVG/delete.svg";
import saveToFolderWhite from "../../assets/images/icon-add-to-library-white-new.svg";
import shareIcon from "../../assets/icons/SVG/icon-share.svg";
import sharedFolder from "../../assets/icons/SVG/shared_folder.svg";
import xIcon from "../../assets/icons/SVG/icon-dark-x.svg";
import PencilIcon from "../../assets/icons/SVG/icon-pencil.svg";
import pubIcon from '../../assets/icons/PNG/pub_mod.png';

import {
  DeleteConfirmationModal,
  CheckBox,
  SnackBarModule,
} from "../../components/Shared";
import ShareFolderModal from "../../components/AddToFolder/ShareFolderModal";
import { TextBox } from "../../components/ResourceHeading";

class ResponsiveResourcesNew extends Component {
  state = {
    showFullText: false,
    isHoveringFirst: false,
    isHoveringSecond: false,
    isHovering2First: false,
    isHovering2Second: false,
    isAddToFolderModalOpen: false,
    addToLibrarySuccess: false,
    isErrorOpen: false,
    isShareModalOpen: false,
    isDeleteModalOpen: false,
    sendEmailSuccess: false,
    sendEmailError: false,
    removeFromLibraryError: false,
    removeFromLibrarySuccess: false,
    addToFolderSuccess: false,
    folderRenameOpen: false,
    renameFolderSuccess: false,
    renameFolderError: false,
    folderNameExistsError: false,
    folderName: "",
  };

  handleModalOpen = () => {
    this.setState({ isAddToFolderModalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ isAddToFolderModalOpen: false });
  };

  handleModalShareOpen = () => {
    this.setState({ isShareModalOpen: true });
  };
  handleModalShareClose = () => {
    this.setState({ isShareModalOpen: false });
  };

  handleSendEmail = async (fid, users, shared_url) => {
    const response = await authAPI.shareFolder(fid, users, shared_url);

    if (response) {
      this.setState({ isShareModalOpen: false });
      this.setState({ sendEmailSuccess: true });

      setTimeout(() => {
        this.setState({ sendEmailSuccess: false });
      }, 3000);
    } else {
      this.setState({ isShareModalOpen: false });
      this.setState({ sendEmailError: true });
      setTimeout(() => {
        this.setState({ sendEmailError: false });
      }, 3000);
    }
  };

  handleAddToLibrary = (id) => {
    this.props
      .postAddAssessmentToLibraryAction(id)
      .then((res) => {
        if (res.statusCode === 200) {
          this.setState({ addToLibrarySuccess: true });
          setTimeout(() => {
            this.setState({ addToLibrarySuccess: false });
          }, 3000);
        } else {
          this.setState({ isErrorOpen: true });
          setTimeout(() => {
            this.setState({ isErrorOpen: false });
          }, 3000);
        }
      })
      .catch(() => {
        this.setState({ isErrorOpen: true });
        setTimeout(() => {
          this.setState({ isErrorOpen: false });
        }, 3000);
      });
  };

  handleRemoveFolder = (fid) => {
    authAPI.deleteFolders(fid).then((res) => {
      if (res.status === 200) {
        this.props.refreshLibrary();
        this.setState({ removeFromLibrarySuccess: true });
        setTimeout(() => {
          this.setState({ removeFromLibrarySuccess: false });
        }, 3000);
      } else {
        this.setState({ removeFromLibraryError: true });
        setTimeout(() => {
          this.setState({ removeFromLibraryError: false });
        }, 3000);
      }
    });
  };

  handleDeleteModalOpen = () => {
    this.setState({ isDeleteModalOpen: true });
  };

  handleDeleteModalClose = () => {
    this.setState({ isDeleteModalOpen: false });
  };

  handleDeclineFolder = (id) => {
    authAPI.removeSharedByUser(id).then((res) => {
      if (res) {
        this.props.refreshLibrary();
        this.setState({ declineFolderSuccess: true });
        setTimeout(() => {
          this.setState({ declineFolderSuccess: false });
        }, 3000);
      } else {
        this.setState({ declineFolderError: true });
        setTimeout(() => {
          this.setState({ declineFolderError: false });
        }, 3000);
      }
    });
  };

  handleSaveToFolder = (id) => {
    authAPI.saveToFolder(id).then((res) => {
      if (res) {
        this.props.refreshLibrary();
        this.setState({ addToLibrarySuccess: true });
        setTimeout(() => {
          this.setState({ addToLibrarySuccess: false });
        }, 3000);
      } else {
        this.setState({ addToLibrarySuccess: true });
        setTimeout(() => {
          this.setState({ addToLibrarySuccess: false });
        }, 3000);
      }
    });
  };

  handleSelect = () => {
    if (!this.state.folderRenameOpen) {
      const item = this.props.item;
      this.props.multiSelectMode
        ? this.props.handleMultiSelect(item)
        : this.props.handleSelectResourceClick(item);
    }
  };

  handleOpenFolderRename = () => {
    this.setState({
      folderRenameOpen: true,
    });
  };

  handleCloseFolderRename = () => {
    this.setState({
      folderRenameOpen: false,
    });
  };

  handleTextInput = (event) => {
    this.setState({
      folderName: event.target.value,
    });
  };

  isNewFolderNameValid = () => {
    if (this.props.item.name === this.state.folderName) {
      this.setState({ folderNameExistsError: true });
      setTimeout(() => {
        this.setState({ folderNameExistsError: false });
      }, 3000);
      return false;
    }

    if (this.state.folderName?.length <= 0) return false;

    return true;
  };

  publishDATAToSAML = (folder_id) =>{
    console.log(folder_id);
    window.open("/schoolwidefun/api/v1/users/samlp?item_id="+folder_id+"&item_type=folder","_blank")
  };

  handleFolderRename = (id) => {
    if (this.isNewFolderNameValid()) {
      authAPI.putFolders(id, this.state.folderName).then((res) => {
        if (res.status === 200) {
          this.props.refreshLibrary();
          this.handleCloseFolderRename();
          this.setState({ renameFolderSuccess: true });
          setTimeout(() => {
            this.setState({ renameFolderSuccess: false });
          }, 3000);
        } else {
          this.setState({ renameFolderError: true });
          setTimeout(() => {
            this.setState({ renameFolderError: false });
          }, 3000);
        }
      });
    }
  };

  dismissNotification = (id) => {
    const notification = this.props.notifications.find(
      ({ folder_id }) => folder_id === id
    );
    if (notification) {
      const notificationId = notification._id;
      this.props.dismissLibraryFolderNotification([notificationId]);
    }
  };

  render() {
    let item = this.props.item;
    let createdDate = item?.createdAt;
    let finalCreatedDate = new Date(createdDate);
    let i = this.props.i;

    const handleMouseEnterSecond = () => {
      this.setState({ isHoveringSecond: true });
    };

    const handleMouseLeaveSecond = () => {
      this.setState({ isHoveringSecond: false });
    };

    const handleMouse2EnterFirst = () => {
      this.setState({ isHovering2First: true });
    };

    const handleMouse2LeaveFirst = () => {
      this.setState({ isHovering2First: false });
    };

    const handleMouse2EnterSecond = () => {
      this.setState({ isHovering2Second: true });
    };
    const handleMouse2LeaveSecond = () => {
      this.setState({ isHovering2Second: false });
    };

    let district_name = '',
        pubShow = '';
    if(localStorage.getItem("district_name")){
      district_name =localStorage.getItem("district_name").toLowerCase();
      pubShow = district_name.includes('schoolwide');
    }
    return (
      <>
        <div
          className="hover-cursor-pointer"
          key={`${item?.type}-${i}`}
          onClick={this.handleSelect}
        >
          {/*Desktop component*/}
          <Col
            xsHidden
            smHidden
            className={
              this.props.multiSelectMode &&
              !this.props.selectedItems.some((e) => e.item_id === item._id)
                ? "new-resources-single-item-container-grayed"
                : this.props.active
                ? "new-resources-single-item-container-active"
                : "new-resources-single-item-container"
            }
          >
            <div className={"new-single-item-left-folder"}>
              <div className={"flex-row"}>
                <div
                  className={"new-single-item-image"}
                  onClick={() => {
                    this.props.multiSelectMode
                      ? console.log("")
                      : this.props.handleClickForResourcesThumbnail(item);
                  }}
                >
                  {item?.is_shared ? (
                    <img
                      onError={(event) => (event.target.src = sharedFolder)}
                      src={
                        item?.image
                          ? constants.filesUrl + (item.image || "")
                          : sharedFolder
                      }
                      alt=""
                    />
                  ) : (
                    <img
                      onError={(event) => (event.target.src = folderBigIcon)}
                      src={
                        item?.image
                          ? constants.filesUrl + (item.image || "")
                          : folderBigIcon
                      }
                      alt=""
                    />
                  )}
                </div>
                <div className="folder-texts-container">
                  <div className="top">
                    {!this.state.folderRenameOpen ? (
                      <h3 className="new-single-item-title">
                        {item?.is_shared
                          ? `Shared With You : ${item?.name}`
                          : item?.name ?? "No Title"}
                        <img
                          src={PencilIcon}
                          className="pencil-icon"
                          alt=""
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handleOpenFolderRename();
                          }}
                        />
                      </h3>
                    ) : (
                      <div className="add-to-folder-modal-input rename">
                        <TextBox
                          type="text"
                          rename={true}
                          placeholder={item?.name}
                          name="folderName"
                          // errorValue={this.state.folderNameValid}
                          onChange={(e) => {
                            e.stopPropagation();
                            this.handleTextInput(e);
                          }}
                        />

                        <div
                          style={{ margin: "0 10px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handleFolderRename(item._id);
                          }}
                        >
                          <img
                            src={checkMarkDark}
                            style={{ height: "20px", width: "20px" }}
                            alt="OK"
                          />
                        </div>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handleCloseFolderRename();
                          }}
                        >
                          <img
                            src={XIcon}
                            style={{ height: "25px", width: "25px" }}
                            alt="CLOSE"
                          />
                        </div>
                      </div>
                    )}

                    <p className="last-edit-text">
                      Created On:{" "}
                      {finalCreatedDate
                        ? (finalCreatedDate.getMonth() < 10
                            ? "0" + (finalCreatedDate.getMonth() + 1)
                            : finalCreatedDate.getMonth() + 1) +
                          "/" +
                          (finalCreatedDate.getDate() > 9
                            ? finalCreatedDate.getDate()
                            : "0" + finalCreatedDate.getDate()) +
                          "/" +
                          finalCreatedDate.getFullYear()
                        : ""}
                    </p>
                  </div>
                  <p className="contents-text">
                    Contents: {item?.resource_ids?.length}
                  </p>
                </div>
              </div>
              <div
                className={
                  this.props.isFolder
                    ? "color-container folder"
                    : item.type === "reading"
                    ? "color-container green"
                    : "color-container green"
                }
              >
                <div className="icons-left-container">
                  {this.props.multiSelectMode ? (
                    <CheckBox
                      key={this.props.selectedItems}
                      id={item?._id}
                      isChecked={this.props.selectedItems.some(
                        (e) => e.item_id === item?._id
                      )}
                      onChange={this.handleSelect}
                    />
                  ) : (
                    <>
                      {item?.file && item?.file.includes("pdf") && (
                        <span
                          style={{
                            backgroundColor: this.state.isHovering2First
                              ? "#FFFFFF80"
                              : "",
                          }}
                          onMouseEnter={handleMouse2EnterFirst}
                          onMouseLeave={handleMouse2LeaveFirst}
                        >
                          <a
                            href={
                              constants.filesUrl +
                              item?.file?.replace("../", "")
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={pdfIcon} alt="" />
                          </a>
                        </span>
                      )}
                      
                      {item?.showClassroomShareButtonLink && (
                        <span
                          style={{
                            backgroundColor: this.state.isHovering2Second
                              ? "#FFFFFF80"
                              : "",
                          }}
                          onMouseEnter={handleMouse2EnterSecond}
                          onMouseLeave={handleMouse2LeaveSecond}
                        >
                          <a
                            href={item?.showClassroomShareButtonLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={googleClassroomIcon} alt="" />
                          </a>
                        </span>
                      )}
                      {item?.is_shared ? (
                        <span
                          style={{
                            backgroundColor: this.state.isHovering2Second
                              ? "#FFFFFF80"
                              : "",
                            borderRadius: 6,
                            padding: "2px 4px",
                            marginLeft: 1,
                            color: "white",
                            background: "red",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.dismissNotification(item._id);
                            this.handleSaveToFolder(item?._id);
                          }}
                        >
                          <img
                            src={saveToFolderWhite}
                            alt=""
                            style={{ height: "26px" }}
                          />{" "}
                          Save To My Library
                        </span>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
                {this.props.multiSelectMode ? (
                  <></>
                ) : (
                  <div className="icons-right-container">
                    {localStorage.getItem("user_type") && (localStorage.getItem("user_type") == 'System Admin' || localStorage.getItem("user_type")=='School Admin') && pubShow &&
                        <span>
                            <img src={pubIcon} width='23' height='10' style={{cursor:'pointer'}} onClick={()=>this.publishDATAToSAML(item._id)}/>
                        </span>
                    }
                    
                    <p
                      style={{
                        backgroundColor: this.state.isHovering2First
                          ? "#FFFFFF80"
                          : "",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.handleModalShareOpen();
                      }}
                      onMouseEnter={handleMouse2EnterFirst}
                      onMouseLeave={handleMouse2LeaveFirst}
                    >
                      <img src={shareIcon} alt="" /> Share
                    </p>
                    {item?.is_shared ? (
                      <p
                        style={{
                          backgroundColor: this.state.isHoveringSecond
                            ? "#FFFFFF80"
                            : "",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.dismissNotification(item?._id);
                          this.handleDeclineFolder(item?._id);
                        }}
                        onMouseEnter={handleMouseEnterSecond}
                        onMouseLeave={handleMouseLeaveSecond}
                      >
                        <img src={xIcon} alt="" /> Decline
                      </p>
                    ) : (
                      <p
                        style={{
                          backgroundColor: this.state.isHoveringSecond
                            ? "#FFFFFF80"
                            : "",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleDeleteModalOpen();
                        }}
                        onMouseEnter={handleMouseEnterSecond}
                        onMouseLeave={handleMouseLeaveSecond}
                      >
                        <img src={remove} alt="" /> Remove
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Col>
          {/*Mobile component*/}
          <Grid className="hidden-md hidden-lg">
            <Col mdHidden lgHidden>
              <Row>
                <Col
                  xs={2}
                  onClick={this.props.handleClickForResourcesThumbnail.bind(
                    this,
                    item
                  )}
                  className="img-2-col"
                  style={{ boxShadow: "0px 0px 2px 2px #f5f5f5" }}
                >
                  <Image
                    responsive
                    src={`${constants.filesUrl}${
                      item.image?.replace("../", "") || ""
                    }`}
                  />
                </Col>
                <Col
                  xs={8}
                  onClick={this.props.handleClickForResourcesThumbnail.bind(
                    this,
                    item
                  )}
                  className="data-8-col"
                >
                  <Row>
                    <Col
                      xs={12}
                      className="resources-text-mobile"
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "100%",
                        paddingRight: "0px",
                      }}
                    >
                      {item.name}
                    </Col>
                    <Col
                      xs={12}
                      className="resources-subtext-mobile"
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "100%",
                        paddingRight: "0px",
                      }}
                    >
                      {item?.description?.text}
                    </Col>
                  </Row>
                </Col>
                {<Col xs={2} />}
              </Row>
              <div
                className="browse-separator"
                style={{
                  borderBottom: "1px solid #CCD2DE",
                  marginTop: "4px",
                  marginBottom: "-4px",
                  marginLeft: "62px",
                }}
              ></div>
            </Col>
          </Grid>
        </div>

        {this.state.isShareModalOpen ? (
          <ShareFolderModal
            handleModalShareClose={this.handleModalShareClose}
            handleSendEmail={this.handleSendEmail}
            itemClicked={item}
          />
        ) : (
          <></>
        )}

        {this.state.isDeleteModalOpen ? (
          <DeleteConfirmationModal
            handleRemoveFromLibrary={() => this.handleRemoveFolder(item._id)}
            handleDeleteModalClose={this.handleDeleteModalClose}
          />
        ) : (
          <></>
        )}

        <SnackBarModule
          addToLibrarySuccess={this.state.addToLibrarySuccess}
          isErrorOpen={this.state.isErrorOpen}
          declineFolderSuccess={this.state.declineFolderSuccess}
          declineFolderError={this.state.declineFolderError}
          addToFolderError={this.state.addToFolderError}
          addToFolderSuccess={this.state.addToFolderSuccess}
          removeFromLibrarySuccess={this.state.removeFromLibrarySuccess}
          removeFromLibraryError={this.state.removeFromLibraryError}
          renameFolderSuccess={this.state.renameFolderSuccess}
          renameFolderError={this.state.renameFolderError}
          folderNameExistsError={this.state.folderNameExistsError}
          // TODO: missing snackbars
          sendEmailSuccess={this.state.sendEmailSuccess}
          sendEmailError={this.state.sendEmailError}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notifications: state.MyLibrary?.data?.notifications?.results,
  };
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(ResponsiveResourcesNew));
