import React, {Component} from 'react'
import Footer from './Footer'
import * as actions from '../redux/actions'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

class StudentRoot extends Component{

    componentDidMount(){
        this.props.getMyDetailsAction();
    }

    render(){
        let rootStyle = {
            position: 'fixed',
            bottom: '70px',
            overflowY: 'auto',
            top: '60px',
            width: '100%'
        };

        if (window.location.pathname.indexOf("/browse/teaching-resources/") > -1) {
            rootStyle = {paddingBottom:"70px"};
        }
        else if (window.location.pathname.indexOf("/browse/assessments/") > -1) {
            rootStyle = {paddingBottom:"70px"};
        }
        else if (window.location.pathname.indexOf("/browse/units/") > -1) {
            rootStyle = {paddingBottom:"70px"};
        }

        return(
            <div style={{height: '100%'}}>
                <div style={rootStyle}>
                    {this.props.children}
                </div>
                {this.props.myDetails.asyncStatus === "SUCCESS" && <Footer name={this.props.myDetails.data.first_name}/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        myDetails: state.MyDetails,
    }
};

export default connect(mapStateToProps, actions)(withRouter(StudentRoot));