import React,{Component} from  'react'
import * as actions from '../../redux/actions';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import DetailedHeader from '../../containers/Details/DetailedHeader';
import DetailedDescription from '../../containers/Details/DetailedDescription'
import AppendicesOverview from './AppendicesOverview';

class AppendicesDetailedView extends Component{

 constructor (props) {
	super(props);
	this.state = {
		selectedTab: "SUMMARY",
		infoClicked: window.innerWidth < 768 ? false : true,
		searchClicked: false,
		load: "initial",
		status:"",
		data: {
			id: "",
			image: "",
			name: "",
			description: "",
			grades: "",
			skills:"",
			texts: [],
			forms: "",
			pdf:"",
			resource:"",
			printable: false
		}
	};
	this.selectTab = this.selectTab.bind(this);
	this.onInfoClick = this.onInfoClick.bind(this);
}

componentDidMount(){
	this.props.getResourceDetailsAction(this.props.params.uid).then((res)=>{
		//console.log(res);
		// console.log(res.google_form_link);
		let data = {
			id: res._id,
			name: res.name,
			grades: res.grades.join(", "),
			pdf: res.file,
			forms: res.google_form_link,
			description: res.description.text,
			resource:res.type,
			image: res.image,
			skills:res.skills.join(),
			is_interactive: res.is_interactive,
			printable: res.printable || false
		 };
		 // console.log(data);
		 this.setState({data, status: "SUCCESS"});

	});

}

onInfoClick(infoClicked = !this.state.infoClicked){
	if(infoClicked) {
		this.onSearchClick(false);
	}
	return new Promise((resolve, reject) => {
		this.setState({infoClicked: infoClicked, load: ""}, () => {
			resolve();
		});
	});

}

onSearchClick=(searchClicked = !this.state.searchClicked)=>{
	if(searchClicked) {
		this.onInfoClick(false);
	}
	return new Promise((resolve, reject) => {
		this.setState({searchClicked: searchClicked, load: ""}, () => {
			resolve();
		});
	});
};


selectTab (tab) {

	this.setState({selectedTab: tab});
}

	render(){
		return(
			<div className="detail-wrapper">
			<DetailedHeader publish={this.state.data.publish} file={Array.isArray(this.state.data.forms) &&
				 this.state.data.forms[0] && this.state.data.forms[0].file} name={this.state.data.name}
				  onSearchClick={this.onSearchClick} infoClick={this.onInfoClick} itemType="Appendecies" 
				  itemId={this.state.data.id} infoClicked={this.state.infoClicked} searchClicked={this.state.searchClicked}/>
			<div className={((this.state.infoClicked || this.state.searchClicked)?"some-area fill-height-or-more":"fill-height-or-more some-area-without-info")}>
				<div className="detail-holder">
					{this.state.status === "PENDING" &&
						<div style={{paddingTop: "10px", textAlign: "center"}}>Loading....</div>
					}
					{this.state.status  === "FAILURE" &&
						<div style={{paddingTop: "10px", textAlign: "center"}}>Error Loading Data</div>
					}
					{this.state.status  === "FAILURE FOR PRINT ONLY USER" &&
						<div style={{paddingTop: "10px", textAlign: "center"}}>This resource is not approved for viewing on Fundamentals Unlimited. Please contact us at <a href="mailto:help@schoolwide.com" target="_blank">help@schoolwide.com</a> for more information.</div>
					}
					{this.state.status  === "FAILURE FOR NOT APPROVED" &&
						<div style={{paddingTop: "10px", textAlign: "center"}}>This resource is not approved for viewing on Fundamentals Unlimited. Please contact us at <a href="mailto:help@schoolwide.com" target="_blank">help@schoolwide.com</a> for more information.</div>
					}
					{this.state.status === "SUCCESS" &&
						<AppendicesOverview
							// texts={this.state.data.texts}
							forms={this.state.data.forms}
							pdf={this.state.data.pdf}
							description={{text: this.state.data.description,interactive:this.state.data.is_interactive}}
							grades={this.state.data.grades}
							skills = {this.state.data.skills}
							resource={this.state.data.resource}
						/>
					}
				</div>
				<div>
					<DetailedDescription
						image={this.state.data.image}
						name={this.state.data.name}
						detailType='Unit_appendecies'
						printable={this.state.data.printable}
						printableFile={this.state.data.pdf}
						forms={this.state.data.forms}
					/>
				</div>
			</div>
		</div>
		);
	};
}

const mapStateToProps = (state) => {
    return ({
		appendeciesDetails : state.TeachingResources
    });
};

export default connect(mapStateToProps, actions)(withRouter(AppendicesDetailedView))
