import React,{Component} from 'react'
import ImageDetailsComponent from '../../containers/Details/ImageDetailsComponent'

class NewUnitAssessments extends Component{


    render(){
        return(
             <div className='list-of-items items-background'>
                <ImageDetailsComponent componentData={this.props.assessments} componentType='units'/>
             </div>
        );
    }}

export default NewUnitAssessments