import React, { Component } from "react";
import iconBullets from "../assets/icons/SVG/icon-list-bullet.svg";
import addToFolder from "../assets/icons/SVG/icon-add-folder.svg";
import addToLibrary from "../assets/icons/SVG/icon-add-to-library.svg";
import trashCan from "../assets/icons/SVG/delete.svg";
import publishIcon from "../assets/icons/SVG/Publish.svg";
import arrowRight from "../assets/icons/SVG/icon-arrow-right.svg";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router";
import checkMarkDark from "../assets/icons/SVG/my-check-image.svg";
import XIcon from "../assets/icons/SVG/icon-dark-x.svg";
import authAPI from "../api/authAPI";
import { DeleteConfirmationModal } from "../components/Shared/DeleteConfirmationModal";
import * as MackinActions from "../redux/actions/mackin";
import * as actions from "../redux/actions";
import { confirmAlert } from "react-confirm-alert";
import learnMorePublishing from "../assets/images/learn-more-about-publishing.jpg";
import MackinBookModal from "./Shared/MackinBookModal";
import pubIcon from '../assets/icons/PNG/pub_mod.png';

export class TextBox extends Component {
  render() {
    return (
      <div
        className={`add-to-folder-modal-input-textfield ${
          this.props.rename ? "rename" : ""
        }`}
      >
        <input
          className={
            this.props.errorValue === ""
              ? "add-user-form"
              : "add-user-form add-user-form-error"
          }
          name={this.props.name}
          type={this.props.type ? this.props.type : "text"}
          value={this.props.value}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
        />

        {this.props.errorValue !== "" && (
          <div className="add-user-error">{this.props.errorValue}</div>
        )}
        {this.props.errorValue === "" && (
          <div className="password-validation-messages">
            {this.props.Instruction}
          </div>
        )}
      </div>
    );
  }
}

class ResourceHeading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      isInputOpen: false,
      isInputOpenBottom: false,
      form: {
        folderName: "",
      },
      folderNameValid: "",
      trigger: false,
      shouldReset: false,
      isDeleteModalOpen: false,
      errorMessage: "",
      successMessage: "",
      mackin_id: null,
      libraryTotal: 0,
    };
  }

  componentDidMount() {
    if (this.props.params.tab === "books") {
      authAPI.getMySchoolDistrict().then((response) => {
        const district = response && response.data;
        if (district) {
          const { mackin_id } = district;
          this.setState({ mackin_id });
        }
      });
    }
    switch (this.props.params.tab) {
      case "resources":
        this.setState({
          page: "Teaching Resources",
        });
        break;
      case "units":
        this.setState({
          page: "Units",
        });
        break;
      case "lessons":
        this.setState({
          page: "Lessons",
        });
        break;
      case "assessments":
        this.setState({
          page: "Assessments",
        });
        break;
      case "books":
        this.setState({
          page: "Texts",
          mackin_id: this.props.mackin_id,
        });
        break;
      case "folders":
        this.setState({
          page: "Folders",
        });
        break;
      default:
        this.setState({});
        break;
    }
  }

  getLibraryTotal() {
    const library = this.props.myLibrary?.data;

    let tab;
    if (this.props.params.tab === "resources") {
      tab = "teachingResources";
    } else if (!this.props.params.tab) {
      tab = "recents";
    } else {
      tab = this.props.params.tab;
    }

    if (library?.[tab]?.total) {
      return `(${library?.[tab]?.total})`;
    } else {
      return "";
    }
  }

  handleOpen = () => {
    this.setState({ isInputOpen: true });
  };

  handleClose = () => {
    this.setState({ isInputOpen: false });
  };

  handleFolderName = (event) => {
    this.setState({
      form: {
        ...this.state.form,
        [event.target.name]: event.target.value,
      },
    });
  };

  openLearnMorePdf = () => {
    window.open(
      "../../schoolwidefun/api/v1/files/files/Custom_Resource.pdf",
      "_blank"
    );
  };

  learnMorePublishing = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui"
            style={{
              position: "relative",
              bacground: "#fff",
              backgroundImage: "url(" + learnMorePublishing + ")",
              "background-size": "cover",
              width: "700px",
              height: "390px",
              cursor: "pointer",
            }}
            onClick={this.openLearnMorePdf}
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
              style={{
                border: "1px #a3a3a3",
                "border-radius": "8px",
                color: "#fefefe",
                width: "30px",
                "font-size": "20px",
                height: "30px",
                padding: "0 !important",
                "font-weight": "bold",
                background: "#a3a3a3",
                position: "absolute",
                top: "0%",
                left: "90%",
              }}
            >
              X
            </button>
          </div>
        );
      },
    });
  };
  // Check if input is empty
  validateInput() {
    let isGood = true;
    if (
      this.state.form.folderName.length === 0 ||
      !this.state.form.folderName
    ) {
      this.setState({ folderNameValid: "Folder Name cannot be blank." });
      isGood = false;
    } else {
      this.setState({ folderNameValid: "" });
    }
    return isGood;
  }

  handleOpenBottom = () => {
    this.setState({ isInputOpenBottom: true });
  };

  handleCloseBottom = () => {
    this.setState({ isInputOpenBottom: false });
  };

  showError = (message) => {
    this.setState({ errorMessage: message });
    console.log(this.state.errorMessage);
  };

  showSuccess = (message) => {
    this.setState({ successMessage: message });
  };

  clearError = () => {
    if (this.state.errorMessage !== "") {
      this.setState({ errorMessage: "" });
    }
  };
  byBook = (bookId) => {
    this.props.buyBookOnMackin(bookId);
  };

  clearMackinData = () => {
    this.props.clearMackinData();
  };

  clearSuccess = () => {
    if (this.state.successMessage !== "") {
      this.setState({ successMessage: "" });
    }
  };

  // POST request for creating new folder
  handleCreatingNewFolder = () => {
    if (this.validateInput()) {
      const postNewFolder = authAPI.postFolder({
        name: this.state.form.folderName,
      });

      postNewFolder
        .then((response) => {
          if (response.statusCode === 201 || response.statusCode === 200) {
            this.props.refreshLibrary();
            this.props.handleCreateFolderSnackbar({ isError: false });
            this.setState({
              form: {
                folderName: "",
              },
              createFolderInput: false,
              folderNameValid: "",
              createFolder: false,
            });
            this.handleClose();
            this.props.refreshLibrary();
          } else {
            this.showError(response.message);
            this.props.handleCreateFolderSnackbar({ isError: true });
            setTimeout(() => {
              this.clearError();
            }, 3000);
          }
        })
        .catch(function (error) {
          console.log("exception occurred: ", error);
        });
    }
  };

  handleDeleteModalOpen = () => {
    this.setState({ isDeleteModalOpen: true });
  };

  handleDeleteModalClose = () => {
    this.setState({ isDeleteModalOpen: false });
  };

  getPublishButton = () => {
    return (
      localStorage.user_type !== "Student" && localStorage.user_type !== "Text Only"&& (
        <span
          style={{
            fontWeight: "normal",
            width: "291px",
            cursor: "pointer",
            fontSize: "15px",
          }}
          onClick={this.learnMorePublishing}
        >
          <img src={publishIcon} alt="" />
          Learn More About Publishing
        </span>
      )
    );
  };

  publishDATAToSAML = (folder_id) =>{
    console.log(folder_id);
    window.open("/schoolwidefun/api/v1/users/samlp?item_id="+folder_id+"&item_type=folder","_blank")
  };

  render() {
    let district_name = '',
        pubShow = '';
    if(localStorage.getItem("district_name")){
      district_name =localStorage.getItem("district_name").toLowerCase();
      pubShow = district_name.includes('schoolwide');
    }
    if (window.location.pathname.includes("browse")) {
      return (
        <div className="resource-heading">
          <h4>
            {this.props.typeOfResource === "Featured" &&
            !this.props.searchActive
              ? "Featured  "
              : this.props.typeOfResource === "Featured" &&
                this.props.searchActive
              ? "Available "
              : "Available "}
            {localStorage.user_type !=='Text Only'? "Materials" : "Titles"}: {this.props?.total ?? this.props?.data?.length ?? 0}
          </h4>

          <div className="multiselect-resource-heading">
            {!this.props.multiSelectMode ? (
              <div className={"select-multiple-button"}>
                
                {this.getPublishButton()}
                {localStorage.is_lite !== "true" && (
                  <span
                    onClick={() => this.props.handleOpenMultiselect()}
                    className="select-multiple-resources"
                  >
                    <img
                      style={{ height: "80%", marginRight: "6px" }}
                      alt=""
                      src={iconBullets}
                    />
                    Select Multiple
                  </span>
                )}
              </div>
            ) : (
              <div className={"select-multiple-button-active"}>
                {this.getPublishButton()}
                <p onClick={() => this.props.handleCancel()}>Cancel</p>
                {this.props.areAllSelected ? (
                  <p onClick={() => this.props.handleDeselectAll()}>
                    Deselect all
                  </p>
                ) : (
                  <p onClick={() => this.props.handleSelectAll()}>Select all</p>
                )}
                <div className="select-multiple-resources-icons">
                  <p
                    id="addToLibrary-p-tag"
                    onClick={this.props.handleAddToLibrary}
                  >
                    <img src={addToLibrary} alt="Add To Library" /> Add To
                    Library
                  </p>
                  {localStorage.getItem('user_type') && localStorage.getItem('user_type') !='Student' && <p
                    id="remove-p-tag"
                    onClick={this.props.handleBulkAddToFolderModal}
                  >
                    <img src={addToFolder} alt="Add To Folder" /> Add To Folder
                  </p>}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else if (window.location.pathname.includes("folders/")) {
      return (
        <>
          <div className="multiselect-resource-heading">
            <div className="multiselect-breadcrumbs">
              <Link to="/library" onlyActiveOnIndex>
                My Library{" "}
              </Link>
              <img
                src={arrowRight}
                alt=""
                style={{ width: "24px", height: "24px" }}
              />
              <Link to={`/library/folders`} onlyActiveOnIndex>
                Folders
              </Link>
              {this.props?.folderName && (
                <img
                  src={arrowRight}
                  alt=""
                  style={{ width: "24px", height: "24px" }}
                />
              )}
              {this.props?.folderName && (
                 <Link onlyActiveOnIndex>{this.props.folderName}</Link>
              )}
            </div>
            <div className={"select-multiple-button-active"}>
              {this.props.folderName && (localStorage.getItem("user_type") == 'System Admin' || localStorage.getItem("user_type")=='School Admin') && pubShow && 
                <span>
                    <img src={pubIcon} width='23' height='10' style={{cursor:'pointer'}} onClick={()=>this.publishDATAToSAML(this.props.params.id )}/>
                </span>
              }
              {this.getPublishButton()}
            </div>
          </div>
        </>
      );
    } else {
      const { book = {} } = this.props;

      return (
        <>
          <div className="multiselect-resource-heading">
            <div className="multiselect-breadcrumbs">
              <Link to="/library" onlyActiveOnIndex>
                My Library
              </Link>
              <img
                src={arrowRight}
                alt=""
                style={{ width: "24px", height: "24px" }}
              />
              <span>{this.getLibraryTotal()}</span>
            </div>
            {window.location.pathname === "/library/folders" ? (
              <>
                <div className="add-to-folder-modal-single-item no-border-folder">
                  {this.state.errorMessage && (
                    <div style={{ color: "red" }}>
                      {this.state.errorMessage}
                    </div>
                  )}
                  {this.state.successMessage && (
                    <div style={{ color: "green" }}>
                      {this.state.successMessage}
                    </div>
                  )}
                  {this.state.isInputOpen ? (
                    <div className="add-to-folder-modal-input">
                      <TextBox
                        type="text"
                        placeholder="Enter Folder Name"
                        name="folderName"
                        errorValue={this.state.folderNameValid}
                        onChange={this.handleFolderName}
                      />

                      <div
                        style={{ margin: "0 10px" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleCreatingNewFolder();
                        }}
                      >
                        <img
                          src={checkMarkDark}
                          style={{ height: "20px", width: "20px" }}
                          alt="OK"
                        />
                      </div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleClose();
                        }}
                      >
                        <img
                          src={XIcon}
                          style={{ height: "25px", width: "25px" }}
                          alt="CLOSE"
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="add-to-folder-modal-input"
                      onClick={() => this.handleOpen()}
                    >
                      <span style={{ fontSize: "25px", margin: "0 10px" }}>
                        +
                      </span>{" "}
                      Create A New Folder
                    </div>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
            {!this.props.multiSelectMode ? (
              <div className={"select-multiple-button"}>
                {this.state.page === "Texts" &&
                  this.state.mackin_id != null && (
                    <>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        // eslint-disable-next-line no-script-url
                        href="javascript:void(0)"
                        onClick={() => this.byBook(1)}
                        style={{ color: "#009BBD !important" }}
                      >
                        My Books
                      </a>
                      <MackinBookModal
                        onClose={this.clearMackinData}
                        book={book}
                      />
                    </>
                  )}
                {this.getPublishButton()}
                <span
                  onClick={() => this.props.handleOpenMultiselect()}
                  className="select-multiple-resources"
                >
                  <img
                    style={{ height: "80%", marginRight: "6px" }}
                    alt=""
                    src={iconBullets}
                  />
                  Select Multiple
                </span>
              </div>
            ) : (
              <div className={"select-multiple-button-active"}>
                {this.state.page === "Texts" &&
                  this.state.mackin_id != null && (
                    <>
                      <a
                        href="javscript:void(0)"
                        onClick={() => this.byBook(1)}
                        style={{ color: "#009BBD !important" }}
                      >
                        My Books
                      </a>
                      <MackinBookModal
                        onClose={this.clearMackinData}
                        book={book}
                      />
                    </>
                  )}
                
                {this.getPublishButton()}
                <p onClick={() => this.props.handleCancel()}>Cancel</p>
                {this.props.areAllSelected ? (
                  <p onClick={() => this.props.handleDeselectAll()}>
                    Deselect all
                  </p>
                ) : (
                  <p onClick={() => this.props.handleSelectAll()}>Select all</p>
                )}
                <div className="select-multiple-resources-icons">
                  {window.location.pathname === "/library/folders" ? (
                    <></>
                  ) : (
                    <p
                      id="addToFolder-p-tag"
                      onClick={this.props.handleBulkAddToFolderModal}
                    >
                      <img src={addToFolder} alt="Share" /> Add To Folder
                    </p>
                  )}
                  <p
                    id="remove-p-tag"
                    style={
                      window.location.pathname === "/library/folders"
                        ? { marginLeft: "-5px" }
                        : {}
                    }
                    onClick={() => this.handleDeleteModalOpen()}
                  >
                    <img src={trashCan} alt="Remove" /> Remove
                  </p>
                </div>
              </div>
            )}
          </div>
          {this.state.isDeleteModalOpen ? (
            <DeleteConfirmationModal
              handleRemoveFromLibrary={this.props.handleRemoveFromLibrary}
              handleDeleteModalClose={this.handleDeleteModalClose}
            />
          ) : (
            <></>
          )}
        </>
      );
    }
  }
}

const mapStateToProps = ({
  Mackin,
  Users,
  MyLibrary,
  CustomFilters,
  FilterScale,
}) => ({
  myLibrary: MyLibrary,
  custom: CustomFilters,
  strand_number: CustomFilters.strand,
  query: FilterScale.query,
  book: Mackin.book,
  errors: Mackin.errors,
  userPermission: Users.hasMore,
});

export default connect(mapStateToProps, { ...actions, ...MackinActions })(
  withRouter(ResourceHeading)
);
