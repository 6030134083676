import React , {Component} from  'react';


export default class MyMenu extends Component{
    constructor(props) {
        super(props);
        this.state = {
            visible:false
        };
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
    }

    show() {
        if(!this.props.showMenu){
            this.setState({visible:false});
            //this.props.menuShowHide = this.props.menuShowHide ? this.props.menuShowHide(false) : '';
        }else{
            this.setState({ visible: true });
            //this.props.menuShowHide = this.props.menuShowHide ? this.props.menuShowHide(true) :'';
        }
    }

    hide() {
        this.setState({ visible: false });
        //this.props.menuShowHide = this.props.menuShowHide ? this.props.menuShowHide(false) :'';
    }

    render() {
        return (
        <div className="menu">
            <div id="joyride-filter-menu"
                className={(this.state.visible ? "visible " : "") + this.props.alignment}
                style={{
                    height: '100%',
                    overflowY: this.props.showScroll ? 'scroll' : 'hidden'
                }}
            >
                {this.props.children}
            </div>
        </div>);
    }
}
