import React, { Component } from 'react'
import jwt_decode from "jwt-decode";
import * as actions from '../redux/actions';
import { connect } from 'react-redux';

const CLIENT_ID = '284908834236-e1q6k1h7h580cacemp4kmuklk6fimjsc.apps.googleusercontent.com'

class GoogleBtn extends Component {

    constructor() {
        super();
        this.handleCredentialResponse = this.handleCredentialResponse.bind(this);
        this.state = {
            user: null,
            isScriptLoaded: false,
        }
    }

    loadGoogleAuth = () => {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.id = 'googleAuth';
        document.body.appendChild(script);
        script.onload = () => {
            this.setState({
                isScriptLoaded: true
            })
        };
    };

    handleCredentialResponse(response) {
        if (response.credential) {
            const { email, email_verified } = jwt_decode(response.credential)
            if (email && email_verified) {
                this.props.googleSsologin(email);
            } else {
                console.log("Issue with user email");
            }
        } else {
            console.log("Malformed JWT");
        }
    }

    init() {
        window.google.accounts.id.initialize({
            client_id: CLIENT_ID,
            callback: this.handleCredentialResponse,
        });
    };

    componentDidMount() {
        this.loadGoogleAuth()
    }

    componentDidUpdate(_prevProps, prevState) {
        if (this.state.isScriptLoaded !== prevState.isScriptLoaded) {
            this.init();

            window.google.accounts.id.prompt();

            window.google.accounts.id.renderButton(
                document.getElementById("googleSignInButton"),
                { theme: "outline", size: "large", width: "310px" }
            );
        }
    }

    render() {
        return (
            <div id="googleSignInButton"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "15px"
                }} />
        )
    }
}

const mapStateToProps = ({ }) => ({});

export default connect(null, actions)(GoogleBtn)