import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

export default class MackinBookModal extends Component {
  state = {
    show: false,
  };

  componentWillReceiveProps = ({ book }) => {
    if (
      book.status !== this.props.book.status &&
      book.status === "success" &&
      this.state.show === false
    ) {
      this.handleOpen();
    }
  };

  handleClose = () => {
    this.setState({ show: false });
    this.props.onClose();
  };

  handleOpen = () => {
    this.setState({ show: true });
  };

  openLink = () => {
    window.open(this.props.book.data.viaUrl, "mackin");
    this.handleClose();
  };

  render() {
    return (
      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: 20, textAlign: "center" }}>
            You are now leaving Fundamentals Unlimited and being redirected to
            your MackinVIA library.
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button onClick={this.handleClose}>Close</Button>
          <Button onClick={this.openLink}>Open</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
