import React,{Component} from 'react'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import BrowsePage from "../shared/BrowsePage";

export class TeachingResources extends Component{

    constructor(){
        super();
        this.state={
            buttonClicked:'',
            className:""
        };
        this.handlingClick = this.handlingClick.bind(this);
        this.setContainerWidth = this.setContainerWidth.bind(this); 
    }

    handlingClick(buttonValue){
        if(buttonValue === "Featured"){
            this.setState({
                className : ""
            });
        }
        this.setState({
            buttonClicked:buttonValue,
        });
        /// header.js clearfilter
    }

    setContainerWidth(isMenuVisible){
        if(isMenuVisible){
            this.setState({
                className:"md9class"
            });
        }
        else{
            this.setState({
                className:""
            });
        }

    }

    render() {
        return (
            <BrowsePage
                name='Teaching Resources'
                typeOfResource='teachingResources' strandValue={this.props.params.strand}>
                {this.props.children}
            </BrowsePage>);
    }
}

export default connect(null, actions)(TeachingResources)