import React,{Component} from  'react';
import ResourcesDetailedView from './ResourcesDetailedView';

class ResourcesComponent extends Component{
    render(){

        return(
            <div>
                <ResourcesDetailedView />
            </div>
        );
    }
}



export default ResourcesComponent;
