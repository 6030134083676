import authAPI from "../../../api/authAPI";

export const AUTH_SET_USER_EMAIL = 'AUTH_SET_USER_EMAIL';
export const AUTH_GET_SCHOOL_BY_EMAIL_PENDING = 'AUTH_GET_SCHOOL_BY_EMAIL_PENDING';
export const AUTH_GET_SCHOOL_BY_EMAIL_SUCCESS = 'AUTH_GET_SCHOOL_BY_EMAIL_SUCCESS'
export const AUTH_GET_SCHOOL_BY_EMAIL_FAILED = 'AUTH_GET_SCHOOL_BY_EMAIL_FAILED'
export const AUTH_REGISTER_NEW_USER_PENDING = 'AUTH_REGISTER_NEW_USER_PENDING'
export const AUTH_REGISTER_NEW_USER_SUCCESS = 'AUTH_REGISTER_NEW_USER_SUCCESS'
export const AUTH_REGISTER_NEW_USER_FAILED = 'AUTH_REGISTER_NEW_USER_FAILED'


export const authSetUserEmail = email => dispatch => {
    dispatch({
        type: AUTH_SET_USER_EMAIL,
        payload: email
    });
};

export const authGetSchoolByEmail = email => dispatch => {
    dispatch({
        type: AUTH_GET_SCHOOL_BY_EMAIL_PENDING,
    });
    return authAPI.findSchoolByEmail(email)
        .then( response => {
            try {
                if (response && response.data) {
                    dispatch({
                        type: AUTH_GET_SCHOOL_BY_EMAIL_SUCCESS,
                        payload: response.data,
                    });
                } else if (response.response) {
                    dispatch({
                        type: AUTH_GET_SCHOOL_BY_EMAIL_FAILED,
                        payload: response.response.data.message,
                    });
                } else {
                    dispatch({
                        type: AUTH_GET_SCHOOL_BY_EMAIL_FAILED,
                        payload: response.data,
                    });
                }
            } catch (error) {
                dispatch({
                    type: AUTH_GET_SCHOOL_BY_EMAIL_FAILED,
                    payload: error,
                });
            }
            
            
        } )
};

export const authGetSchoolByEmailLite = email => dispatch => {
    dispatch({
        type: AUTH_GET_SCHOOL_BY_EMAIL_PENDING,
    });
    return authAPI.findSchoolLite(email)
        .then( response => {
            try {
                if (response && response.data) {
                    dispatch({
                        type: AUTH_GET_SCHOOL_BY_EMAIL_SUCCESS,
                        payload: response.data,
                    });
                } else if (response.response) {
                    dispatch({
                        type: AUTH_GET_SCHOOL_BY_EMAIL_FAILED,
                        payload: response.response.data.message,
                    });
                } else {
                    dispatch({
                        type: AUTH_GET_SCHOOL_BY_EMAIL_FAILED,
                        payload: response.data,
                    });
                }
            } catch (error) {
                dispatch({
                    type: AUTH_GET_SCHOOL_BY_EMAIL_FAILED,
                    payload: error,
                });
            }
            
            
        } )
};

export const authRegisterNewUser = payload => dispatch => {
    dispatch({
        type: AUTH_REGISTER_NEW_USER_PENDING,
    });
    return authAPI.registerNewUser(payload)
        .then(response => {
                if (response && (response.statusCode === 201 || response.status === 200 || (response.data && response.data.statusCode === 201))) {
                    dispatch({
                        type: AUTH_REGISTER_NEW_USER_SUCCESS,
                        payload: response._id,
                    });
                } else if (response.response && response.response.data) {
                    dispatch({
                        type: AUTH_REGISTER_NEW_USER_FAILED,
                        payload: response.response.data.message || response.response.data.errors,
                    });
                }
        })
}