import React, { Component } from "react";
import styled from "styled-components";

const TooltipUI = styled.div`
  position: absolute;
  top: 0;
  ${({ dir }) =>
    dir === "right" ? `right: -355px;` : `left: -355px;`} transition: 0.4s;
  width: 340px;
  transform: scale(${({ isShow }) => (isShow ? 1 : 0)});
  background-color: #fff;
  transform-origin: 0 0;
  font-size: 16px;
  color: #000;

  table {
    width: 100%;
  }
  th {
    background-color: #f5f5f5;
  }
  tr {
    padding: 10px;
    text-align: left;
  }
  th,
  td {
    padding: 5px 10px;
    text-align: left;
  }
  tr:nth-child(even) {
    background-color: #f5f5f5;
  }
  div.tooltip {
    /* tooltip content styling in here; nothing to do with arrows */
  }

  /* shared with before and after */
  &:before,
  div.tooltip:after {
    content: " ";
    height: 0;
    position: absolute;
    width: 0;
    border: 15px solid transparent; /* arrow size */
  }

  /* these arrows will point up */

  /* top-stacked, smaller arrow */
  &:before {
    border-bottom-color: #f5f5f5; /* arrow color */

    /* positioning */
    position: absolute;
    top: 0;
    left: -30px;
    z-index: 2;
    transform: rotate(-90deg);
  }

  /* arrow which acts as a background shadow */
  &:after {
    border-bottom-color: #333; /* arrow color */
    /* positioning */
    position: absolute;
    top: -24px;
    left: 255px;
    z-index: 1;
  }
`;

export default class Tooltip extends Component {
  state = {
    show: false
  };

  showTooltip = () => {
    this.setState({ show: true });
  };

  hideTooltip = () => {
    this.setState({ show: false });
  };
  render() {
    const { dir = "right", icon, children } = this.props;
    const { show } = this.state;
    return (
      <div
        style={{ display: "inline-block", position: "relative", cursor: 'pointer' }}
        onMouseEnter={this.showTooltip}
        onMouseLeave={this.hideTooltip}
      >
        {icon}
        <TooltipUI dir={dir} isShow={show}>
          <div>{children}</div>
        </TooltipUI>
      </div>
    );
  }
}
