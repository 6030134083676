import React, { Component } from "react";
import authAPI from "../../api/authAPI";
import remove from "../../assets/icons/SVG/delete.svg";
import { withRouter } from "react-router";
import constants from "../../constants";
import addToFolder from "../../assets/icons/SVG/add-to-folder.svg";
import { Grid, Col, Row, Image } from "react-bootstrap";
import "../../assets/styles/mobile.scss";
import addToLibrary from "../../assets/icons/SVG/icon-library.svg";
import brokenImage from "../../assets/images/notFound.webp";
import AddToFolderModal from "../../components/AddToFolder/AddToFolderModal";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import {
  DeleteConfirmationModal,
  CheckBox,
  SnackBarModule,
} from "../../components/Shared";
import ReadMore from "../../components/ReadMore";

class ResponsiveResourcesNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFullText: false,
      isHoveringFirst: false,
      isHoveringSecond: false,
      isHovering2First: false,
      isHovering2Second: false,
      isAddToFolderModalOpen: false,
      addToLibrarySuccess: false,
      isErrorOpen: false,
      removeFromLibrarySuccess: false,
      removeFromLibraryError: false,
      addToFolderSuccess: false,
      addToFolderError: false,
      addToFolderErrorMsg: "",
      isDeleteModalOpen: false,
    };
  }

  handleModalOpen = () => {
    this.setState({ isAddToFolderModalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ isAddToFolderModalOpen: false });
  };

  handleAddToLibrary = (id) => {
    this.props
      .postAddUnitToLibraryAction(id)
      .then((res) => {
        if (res.statusCode === 200) {
          this.setState({ addToLibrarySuccess: true });
          setTimeout(() => {
            this.setState({ addToLibrarySuccess: false });
          }, 3000);
        } else {
          this.setState({ isErrorOpen: true });
          setTimeout(() => {
            this.setState({ isErrorOpen: false });
          }, 3000);
        }
      })
      .catch(() => {
        this.setState({ isErrorOpen: true });
        setTimeout(() => {
          this.setState({ isErrorOpen: false });
        }, 3000);
      });
  };

  removeFromLibrary = (id) => {
    let strand = this.props?.strand_number;
    authAPI
      .removeLibraryItem("units", id)
      .then((response) => {
        if (response.status === 200) {
          if (strand) {
            this.setState({ removeFromLibrarySuccess: true });
            setTimeout(() => {
              this.setState({ removeFromLibrarySuccess: false });
            }, 3000);
            this.props.getCustomLibraryFiltersAndDecrementAction(
              strand,
              "units",
              this.props.query
            );
          } else {
            this.setState({ removeFromLibrarySuccess: true });
            setTimeout(() => {
              this.setState({ removeFromLibrarySuccess: false });
            }, 3000);
            this.props.getMyLibraryAction(constants.normalizeTypeName("units"));
          }
        } else {
          this.setState({ removeFromLibraryError: true });
          setTimeout(() => {
            this.setState({ removeFromLibraryError: false });
          }, 3000);
        }
      })
      .catch((error) => {
        console.log("exception occured");
      });
  };

  handleSuccessSnackBar = () => {
    this.setState({ addToFolderSuccess: true });
    setTimeout(() => {
      this.setState({ addToFolderSuccess: false });
    }, 3000);
  };

  handleErrorSnackBar = (msg) => {
    this.setState({
      addToFolderError: true,
      addToFolderErrorMsg: msg,
    });
    setTimeout(() => {
      this.setState({ addToFolderError: false });
    }, 3000);
  };

  handleDeleteModalOpen = () => {
    this.setState({ isDeleteModalOpen: true });
  };

  handleDeleteModalClose = () => {
    this.setState({ isDeleteModalOpen: false });
  };

  smartText = (text) => {
    let formatedText = text.includes("**HIGH-FREQUENCY WORDS**")
      ? text.replace("**HIGH-FREQUENCY WORDS**", "HIGH-FREQUENCY WORDS: ")
      : text.includes("**HIGH-FREQUENCY WORD**")
      ? text.replace("**HIGH-FREQUENCY WORD**", "HIGH-FREQUENCY WORDS: ")
      : text.includes("**HIGH-FREQUENCY WORDS **")
      ? text.replace("**HIGH-FREQUENCY WORDS **", "HIGH-FREQUENCY WORDS: ")
      : text;
    return (
      <p
        className="new-single-item-right-text"
        dangerouslySetInnerHTML={{ __html: formatedText }}
      ></p>
    );
  };

  handleSelect = () => {
    const item = this.props.item;
    this.props.multiSelectMode
      ? this.props.handleMultiSelect(item)
      : window.location.pathname.includes("library")
      ? this.props.handleClickForResourcesThumbnail(item)
      : this.props.handleSelectResourceClick(item);
  };

  render() {
    let item = this.props.item;
    let i = this.props.i;

    let handleMoreClick = (e) => {
      e.stopPropagation();
      this.setState({ showFullText: !this.state.showFullText });
    };

    const handleMouseEnterFirst = () => {
      this.setState({ isHoveringFirst: true });
    };

    const handleMouseLeaveFirst = () => {
      this.setState({ isHoveringFirst: false });
    };

    const handleMouseEnterSecond = () => {
      this.setState({ isHoveringSecond: true });
    };

    const handleMouseLeaveSecond = () => {
      this.setState({ isHoveringSecond: false });
    };

    return (
      <>
        <div
          className="hover-cursor-pointer"
          key={`${item?.type}-${i}`}
          onClick={this.handleSelect}
        >
          {/*Desktop component*/}
          <Col
            xsHidden
            smHidden
            className={
              this.props.multiSelectMode &&
              !this.props.selectedItems.some((e) => e.item_id === item?._id)
                ? "new-resources-single-item-container-grayed"
                : this.props.active
                ? "new-resources-single-item-container-active"
                : "new-resources-single-item-container"
            }
          >
            <div className={"new-single-item-left"}>
              <div className={"flex-row"}>
                <div className={"new-single-item-image"}>
                  <img
                    className="resource-product"
                    onError={(event) => (event.target.src = brokenImage)}
                    src={
                      constants.filesUrl +
                      (item?.image?.replace("../", "") || "")
                    }
                    alt=""
                  />
                </div>
                <div>
                  <h3 className={"new-single-item-title"}>
                    {item?.name ?? "No Title"}
                  </h3>
                </div>
              </div>
              <div className="color-container purple">
                <div className="icons-left-container">
                  {this.props.multiSelectMode && (
                    <CheckBox
                      key={this.props.selectedItems}
                      id={item?._id}
                      isChecked={this.props.selectedItems.some(
                        (e) => e.item_id === item?._id
                      )}
                      onChange={this.handleSelect}
                    />
                  )}
                </div>
                {this.props.multiSelectMode ? (
                  <></>
                ) : (
                  <div className="icons-right-container">
                    <p
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ isAddToFolderModalOpen: true });
                      }}
                      style={{
                        backgroundColor: this.state.isHoveringFirst
                          ? "#FFFFFF80"
                          : "",
                      }}
                      onMouseEnter={handleMouseEnterFirst}
                      onMouseLeave={handleMouseLeaveFirst}
                    >
                      <img src={addToFolder} alt="" /> Add To Folder
                    </p>
                    {this.props?.type === "library" ? (
                      <p
                        style={{
                          backgroundColor: this.state.isHoveringSecond
                            ? "#FFFFFF80"
                            : "",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleDeleteModalOpen();
                        }}
                        onMouseEnter={handleMouseEnterSecond}
                        onMouseLeave={handleMouseLeaveSecond}
                      >
                        <img src={remove} alt="" /> Remove
                      </p>
                    ) : this.props?.isFolder ? (
                      <p
                        style={{
                          backgroundColor: this.state.isHoveringSecond
                            ? "#FFFFFF80"
                            : "",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.removeFromFolder(item);
                        }}
                        onMouseEnter={handleMouseEnterSecond}
                        onMouseLeave={handleMouseLeaveSecond}
                      >
                        <img src={remove} alt="" /> Remove
                      </p>
                    ) : (
                      <p
                        style={{
                          backgroundColor: this.state.isHoveringSecond
                            ? "#FFFFFF80"
                            : "",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleAddToLibrary(item?._id);
                        }}
                        onMouseEnter={handleMouseEnterSecond}
                        onMouseLeave={handleMouseLeaveSecond}
                      >
                        <img src={addToLibrary} alt="" /> Add To Library
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
            {!Array.isArray(item?.introduction) ? (
              <div className={"new-single-item-right"}>
                {/* <Collapse in={this.state.showFullText} collapsedSize={65}>
                  <p
                    className="new-single-item-right-text"
                    dangerouslySetInnerHTML={{
                      __html: item?.introduction?.text ?? "No Description",
                    }}
                  ></p>
                </Collapse> */}
                <ReadMore
                  text={item?.introduction?.text || item?.introduction[0]?.text}
                  maxLength={150}
                />
                {/* {item?.introduction?.text?.length > 180 && (
                  <span onClick={handleMoreClick}>
                    {this.state.showFullText ? "Read Less" : "Read More"}
                  </span>
                )} */}
              </div>
            ) : (
              <div className={"new-single-item-right"}>
                {/* <Collapse in={this.state.showFullText} collapsedSize={65}>
                  <p
                    className="new-single-item-right-text"
                    dangerouslySetInnerHTML={{
                      __html: item?.introduction[0]?.text ?? "No Description",
                    }}
                  ></p>
                </Collapse>
                {item?.introduction[0]?.text.length > 180 && (
                  <span onClick={handleMoreClick}>
                    {this.state.showFullText ? "Read Less" : "Read More"}
                  </span>
                )} */}
                <ReadMore text={item?.introduction[0]?.text} maxLength={150} />
              </div>
            )}
          </Col>
          {/*Mobile component*/}
          <Grid className="hidden-md hidden-lg">
            <Col mdHidden lgHidden>
              <Row>
                <Col
                  xs={2}
                  onClick={() =>
                    this.props.handleClickForResourcesThumbnail(item)
                  }
                  className="img-2-col"
                  style={{ boxShadow: "0px 0px 2px 2px #f5f5f5" }}
                >
                  <Image
                    responsive
                    src={`${constants.filesUrl}${
                      item?.image?.replace("../", "") || ""
                    }`}
                  />
                </Col>
                <Col
                  xs={8}
                  onClick={() =>
                    this.props.handleClickForResourcesThumbnail(item)
                  }
                  className="data-8-col"
                >
                  <Row>
                    <Col
                      xs={12}
                      className="resources-text-mobile"
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "100%",
                        paddingRight: "0px",
                      }}
                    >
                      {item?.name}
                    </Col>
                    <Col
                      xs={12}
                      className="resources-subtext-mobile"
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "100%",
                        paddingRight: "0px",
                      }}
                    >
                      {item?.description && item?.description.text}
                    </Col>
                  </Row>
                </Col>
                {<Col xs={2} />}
              </Row>
              <div
                className="browse-separator"
                style={{
                  borderBottom: "1px solid #CCD2DE",
                  marginTop: "4px",
                  marginBottom: "-4px",
                  marginLeft: "62px",
                }}
              ></div>
            </Col>
          </Grid>
        </div>

        {this.state.isDeleteModalOpen ? (
          <DeleteConfirmationModal
            handleRemoveFromLibrary={() => this.removeFromLibrary(item?._id)}
            handleDeleteModalClose={this.handleDeleteModalClose}
          />
        ) : (
          <></>
        )}

        {this.state.isAddToFolderModalOpen && (
          <AddToFolderModal
            handleClose={this.handleModalClose}
            handleSuccessSnackbar={this.handleSuccessSnackBar}
            handleErrorSnackbar={this.handleErrorSnackBar}
            itemClicked={item}
          />
        )}

        <SnackBarModule
          addToLibrarySuccess={this.state.addToLibrarySuccess}
          isErrorOpen={this.state.isErrorOpen}
          removeFromLibrarySuccess={this.state.removeFromLibrarySuccess}
          removeFromLibraryError={this.state.removeFromLibraryError}
          addToFolderSuccess={this.state.addToFolderSuccess}
          addToFolderError={this.state.addToFolderError}
          addToFolderErrorMsg={this.state.addToFolderErrorMsg}
        />
      </>
    );
  }
}

const mapStateToProps = ({ CustomFilters, FilterScale }) => ({
  strand_number: CustomFilters.strand,
  query: FilterScale.query,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(ResponsiveResourcesNew));
