import React,{Component} from  'react';
import {Row, Col, Modal, Alert} from 'react-bootstrap'
import {markdownHelper} from '../../markdown/MarkdownHelper'
import {annotationsHelper} from '../../annotations/AnnotationsHelper';
import CollapsibleComponent from '../../containers/Details/CollapsibleComponent';
import TextComponent from '../../containers/Details/TextContent';
import ImageDetailsComponent from '../../containers/Details/ImageDetailsComponent';
import constants from '../../constants/index'
import mySolidArrowRight from '../../assets/icons/SVG/my-solid-arrow-right.png';
import mySolidArrow from '../../assets/icons/SVG/my-solid-arrow.png'

class PreparationComponent extends Component{
    constructor(){
        super();
        this.state={
            // modal:false,
            // videoId:''
        };
        // this.modalController = this.modalController.bind(this);
        // this.modalCloseController = this.modalCloseController.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    // modalController(videoUrl){
    //    let videoUrlArr = videoUrl.split("/");
    //    let videoId = videoUrlArr[videoUrlArr.length-1];
    //     if(!this.state.modal){
    //         this.setState({
    //           modal:true,
    //           videoId:videoId
    //         })
    //     }else{
    //         this.setState({
    //           modal:false,
    //           videoId:videoId
    //         })
    //     }
        
    // }

    // modalCloseController(){
    //   if(!this.state.modal){
    //         this.setState({
    //           modal:true
    //         })
    //     }else{
    //         this.setState({
    //           modal:false
    //         })
    //     }
    // }

    openModal(videoUrl){
      this.props.modalController(videoUrl);
    }

    

    render(){
        console.log(markdownHelper);
        console.log(this.props.content);        
        let showSlideThumbnail = (this.props.content.lessonType == 'Slide Presentation: Mini-Lesson' || this.props.content.lessonType == 'vBook: Mini-Lesson' || this.props.content.lessonType == 'Slide Presentation: Interactive Read-Aloud' || this.props.content.lessonType == 'vBook: Interactive Read-Aloud') ? true : false;  
        //let clickableThumbnail = (this.props.content.lessonType == 'Slide Presentation: Mini-Lesson' ||  this.props.content.lessonType == 'Slide Presentation: Interactive Read-Aloud')? true : false;
        let showAdditionalMaterials = false;
        if(this.props.content.materials && this.props.content.materials.text && this.props.content.materials.text !==""){
          showAdditionalMaterials = true;
        }else if(showSlideThumbnail){
          showAdditionalMaterials = true;
        }

        //objs.sort((a, b) => a.last_nom.localeCompare(b.last_nom));
        let appendices = this.props.content.appendices;
       
        let mentorTexts = this.props.content.mentorTexts;
        let imageNotFound = window.location.protocol + "//" + window.location.hostname+'/image-not-found.png';

        return(
            <div className="list-of-items">
                <CollapsibleComponent collapsed={false} idText={'preparation-row'} header="TEXTS" headerStyle={{fontSize: "17px",letterSpacing:1.5,height:20.5, fontWeight:'bold', color: "#4A4A4A", borderBottom: "1px solid rgba(151, 151, 151,0.5)", paddingBottom: "35px", paddingTop: "15px",fontFamily:"ProximaNova" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                    {this.props.content.mentorTexts ? (
                        <TextComponent requestType='lessons' content={{mentorTexts:mentorTexts}}/>
                    ) : (
                        <div className="text-in-collapsible-component">There are no texts for this lesson</div>
                    )}
                </CollapsibleComponent>
                {appendices &&
                    <CollapsibleComponent collapsed={false} idText={'preparation-row'} header="APPENDICES" headerStyle={{fontSize: "17px",letterSpacing:1.5,height:20.5, fontWeight:'bold', color: "#4A4A4A", borderBottom: "1px solid rgba(151, 151, 151,0.5)", paddingBottom: "35px", paddingTop: "15px",fontFamily:"ProximaNova" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        <ImageDetailsComponent componentData={appendices} componentType='lessons' markdownHelper={markdownHelper} annotationsHelper={annotationsHelper}/>
                    </CollapsibleComponent>
                }
                {/*{this.props.content.materials && this.props.content.materials.text !=="" &&*/}
                  { showAdditionalMaterials &&  
                    <CollapsibleComponent collapsed={false} idText={'preparation-row'} header="ADDITIONAL MATERIALS" headerStyle={{fontSize: "17px",letterSpacing:1.5,height:20.5, fontWeight:'bold', color: "#4A4A4A", borderBottom: "1px solid rgba(151, 151, 151,0.5)", paddingBottom: "35px", paddingTop: "15px",fontFamily:"ProximaNova" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        {showSlideThumbnail && this.props.content.lesson.isSilde && 
                            <a href={this.props.content.lesson.isSilde.text} target="_blank" style={{"margin":"15px"}}>
                            <img 
                              src={this.props.content.slidepresent_thumb ? constants.filesUrl + this.props.content.slidepresent_thumb.replace("../",'') : imageNotFound}
                              style={{width:"10rem",height:"10rem",cursor:"pointer"}}
                              />
                            </a>
                        }
                        
                        {/*{showSlideThumbnail && !clickableThumbnail && this.props.content.lesson.isVBook && 
                            
                          <a href={this.props.content.lesson.isVBook.text} target="_blank">
                            <img 
                              src={this.props.content.vbook_thumb ? constants.filesUrl + this.props.content.vbook_thumb : imageNotFound}
                              style={{width:"10rem",cursor:"pointer"}}
                              />
                          </a>

                         }*/}
                         {showSlideThumbnail && this.props.content.lesson.isVBook && 
                          <img 
                              onClick={()=>this.openModal(this.props.content.lesson.isVBook.text)}
                              src={this.props.content.vbook_thumb ? constants.filesUrl + this.props.content.vbook_thumb.replace("../",'') : imageNotFound}
                              style={{width:"10rem",cursor:"pointer","margin":"15px"}}

                              />
                         }
                         {this.props.content.materials && this.props.content.materials.text && this.props.content.materials.text !=="" &&
                          <div dangerouslySetInnerHTML={{__html: markdownHelper.toHTML(this.props.content.materials && this.props.content.materials.text != undefined ?this.props.content.materials.text:'No data found')}}/>
                         }
                     </CollapsibleComponent>
                }
                
            </div>
        )
    }
}

export default PreparationComponent
