import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import { browserHistory } from "react-router";
import authAPI from "../../api/authAPI";
import constants from "../../constants";
import { BrowsePageContent } from "../shared/BrowsePageContent";
import ResponsiveNavBtns from "./ResponsiveNavBtns";
import ResponsiveResourcesNew from "./ResponsiveResourcesNew";
import UnattachedPopover from "../shared/UnattachedPopover";
import _ from "lodash";
import ResourceHeading from "../../components/ResourceHeading";
import AddToFolderModal from "../../components/AddToFolder/AddToFolderModal";
import { SnackBarModule } from "../../components/Shared";

let invertMap = [
  "Featured",
  "Reading",
  "Writing",
  "Speaking & Listening",
  "Language",
];

class LessonsContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: constants.caseMap[this.props?.params?.strand ?? ""],
      isFeatured: false,
      DataSource: this.props,
      loadingMore: false,
      pageNumber: 1,
      data: [],
      searchedData: [],
      lessonsDetail: "",
      hasMore: true,
      total: 0,
      searchTotal: 0,
      filter: false,
      queryValue: "",
      multiSelectMode: false,
      multipleItems: [],
      areAllSelected: false,
      selectedItems: [],
      addedMultipleItemsToLibrary: false,
      addedMultipleItemsToFolder: false,
      isBackButtonPressed: false,
      selectedResourceId: "",
      isAddToFolderModalOpen: false,
      addToFolderSuccess: false,
      addToFolderError: false,
      addToFolderErrorMsg:"",
      addToLibrarySuccess: false,
      addToLibraryError: false,
      searchActive: false,
      getSearchNextPage: false,
    };
    window.sessionStorage.setItem("selectedFilters", JSON.stringify([]));
  }

  handlingClick(buttonValue) {
    this.setState({
      buttonClicked: buttonValue,
    });
  }

  _handleAPI(pageNumber, strand) {
    this.props.getAllLessonsAction(pageNumber, strand);
    this.setState({
      isFeatured: false,
    });
  }

  componentWillMount() {
    this._handleAPI(this.state.pageNumber, this.state.path);
  }

  componentWillReceiveProps(next) {
    window.onpopstate = (e) => {
      this.setState({
        isBackButtonPressed: true,
      });
    };
    let path = constants.caseMap[next.params.strand];
    if (
      !this.state.searchActive &&
      next.lessons.data &&
      next.lessons.asyncStatus === "SUCCESS"
    ) {
      console.log("line 90");

      if (next.finalFilters.filterList.length === 0) {
        this.setState({
          filter: false,
          loadingMore: false,
          data: next.lessons.data?.results,
          total:
            next.lessons.data?.total ?? next.units?.lessons?.results?.length,
        });
      }
    }
    if (this.props.params.strand !== next.params.strand) {
      console.log("line 101");
      this.setState(
        {
          path,
          data: [],
          pageNumber: 1,
          queryValue: "",
        },
        () =>
          this._handleAPI(
            this.state.pageNumber,
            constants.caseMap[next.params.strand]
          )
      );
    } else if (
      next.finalFilters &&
      next.finalFilters.filterList &&
      this.state.path !== 0
    ) {
      console.log("line 120");

      let queryString = constants.getQueryStringFromFilterList(
        next.finalFilters.filterList
      );
      let filterInfinite = this.state.path;
      if (this.state.queryValue !== queryString && queryString) {
        authAPI
          .getLessons(
            1,
            filterInfinite,
            queryString,
            this.state.isBackButtonPressed
          )
          .then((resp) => {
            this.setState({
              data: resp.results ? resp.results : [],
              total: resp?.total ?? resp?.results?.length,
              pageNumber: 1,
              filter: true,
              queryValue: queryString,
              hasMore: true,
            });
          });
      }
    }

    if (next.lessonsDetail) {
      if (next.lessonsDetail.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next.lessonsDetail.asyncStatus === "FAILURE") {
        this.setState({ lessonsDetail: "FAILURE" });
      } else if (
        next.lessonsDetail.asyncStatus === "SUCCESS" &&
        next.lessonsDetail.data.status === "FAILURE FOR PRINT ONLY USER"
      ) {
        this.setState({ status: "FAILURE FOR PRINT ONLY USER" });
      } else if (
        next.lessonsDetail.asyncStatus === "SUCCESS" &&
        next.lessonsDetail.data.status === "FAILURE FOR NOT APPROVED"
      ) {
        this.setState({ status: "FAILURE FOR NOT APPROVED" });
      } else if (next.lessonsDetail.asyncStatus === "SUCCESS") {
        this.setState({
          lessonsDetail: next.lessonsDetail.data,
          status: "SUCCESS",
          selectedResourceId: next.lessonsDetail.data._id,
        });
      }
    }
  }

  _loadMore() {
    console.log("load more called");
    if (
      this.state.searchActive &&
      !this.state.getSearchNextPage &&
      this.state.nextPage !== -1 &&
      this.state.hasMore
    ) {
      console.log("in if");
      setTimeout(() => {
        this.setState({ getSearchNextPage: true });
      }, 1000);
    } else if(window.location.href.indexOf("all") != -1){
      console.log("line 187")
      authAPI
          .getAllLessons(this.state.pageNumber + 1, this.state?.path ?? "")
      .then((resp) => {
        console.log(this.state.total);
        if (resp?.results) {
          this.setState({
            // total: resp.total,
            data: _.concat(this.state.data, resp.results),
            pageNumber: Number(resp.currentPage),
            loadingMore: this.state.total > this.state?.data?.length ? true : false,
            filter: false,
            total: this.state.total
          });
        }
      });
    }else if (this.props.lessons?.asyncStatus === "SUCCESS") {
      console.log("in else if");
      setTimeout(() => {
        if (this.state.total > this.state?.data?.length) {
          let filtersSet =
            this.props.finalFilters &&
            this.props.finalFilters.filterList &&
            this.props.finalFilters.filterList.length > 0;
          let eitherFiltersSetOrQueryEmpty =
            (filtersSet && this.state.queryValue !== "") ||
            (!filtersSet && this.state.queryValue === "");
          if (
            this.props?.params?.strand !== "featured" &&
            eitherFiltersSetOrQueryEmpty
          ) {
            console.log("I am called");
            authAPI
              .getLessons(this.state.pageNumber + 1, this.state?.path ?? "")
              .then((resp) => {
                if (resp?.results) {
                  this.setState({
                    // total: resp.total,
                    data: _.concat(this.state.data, resp.results),
                    pageNumber: Number(resp.currentPage),
                    loadingMore: false,
                    filter: false,
                  });
                }
              });
          }
        } else {
          console.log("in else");
          this.setState({
            loadingMore: false,
            hasMore: false,
          });
        }
      }, 1000);
    }
  }

  addMultipleItems = (aid) => {
    this.state.multipleItems.push(aid);
    this.setState({ multipleItems: this.state.multipleItems });
  };

  deleteItem = (aid) => {
    let idx = this.state.multipleItems.indexOf(aid);
    this.state.multipleItems.splice(idx, 1);
    this.setState({ multipleItems: this.state.multipleItems });
  };

  addMultipleItemsToLibrary = () => {
    for (let i = 0; i < this.state.multipleItems.length; i++) {
      this.props
        .postAddAssessmentToLibraryAction(this.state.multipleItems[i])
        .then((response) => {
          if (response.statusCode === 200) {
            console.log("Item added to library", this.state.multipleItems[i]);
          } else {
            console.log(
              "Error ! Item not added to library",
              this.state.multipleItems[i]
            );
          }
        })
        .catch(function (error) {
          console.log("Exception! Item not added to library");
        });
    }
  };

  addMultipleItemToFolder = () => {
    let itemTypes = [];
    for (let i = 0; i < this.state.multipleItems.length; i++) {
      itemTypes.push("Assessment");
    }

    browserHistory.push({
      pathname: "/library/addfolder",
      state: {
        libraryItemId: this.state.multipleItems,
        libraryItemType: itemTypes,
        isLibrary: false,
      },
    });
    this.props.saveAssignmentItemAction(itemTypes, this.state.multipleItems);
  };

  //This function expects to be bound in a child component's onClick
  handleClickForResourcesThumbnail = function (item) {
    if (this.props.multiSelectMode) {
      if (this.props.multipleItems.indexOf(item._id) === -1) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        this.props.deleteItem(item._id, item.type);
      }
    } else {
      browserHistory.push(`/lessons/${item._id}`);
    }
  };

  //This function expects to be bound in a child component's onClick
  handleClickForResourcesAddBtn = function (item) {
    if (this.props.multiSelectMode) {
      if (this.props.multipleItems.indexOf(item._id) === -1) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        this.props.deleteItem(item._id, item.type);
      }
    } else {
      //This replaces "showTooltip" in popover.
      this.setState({
        isTooltipActive: true,
        success: false,
        showDatePicker: false,
      });
    }
  };

  handleAddMultiple = function (desktop) {
    this.addMultipleItemsToLibrary();
    this.setState(
      {
        multiSelectMode: !this.state.multiSelectMode,
        addedMultipleItemsToLibrary: true,
      },
      () => {
        if (desktop)
          setTimeout(
            () =>
              this.setState({
                addedMultipleItemsToLibrary: false,
                multipleItems: [],
              }),
            3000
          );
      }
    );
  };

  handleAddMultipleToFolder = function (desktop) {
    this.addMultipleItemToFolder();
    this.setState(
      {
        multiSelectMode: !this.state.multiSelectMode,
        addedMultipleItemsToFolder: true,
      },
      () => {
        if (desktop)
          setTimeout(
            () =>
              this.setState({
                addedMultipleItemsToFolder: false,
                multipleItems: [],
              }),
            3000
          );
      }
    );
  };

  handleSelectResourceClick = (item) => {
    if (item?._id) {
      this.props.getLessonsDetailedAction(item._id);
    }
  };

  updateFromSearch = (data, total, clearSearch, nextPage) => {
    this.setState({
      searchTotal: total ?? data?.length ?? 0,
      searchedData: data ?? [],
      searchActive: clearSearch ? false : true,
      hasMore: nextPage !== -1 ? true : false,
    });
  };

  handleSuccessSnackBar = () => {
    this.handleCancel();
    this.setState({ isAddToFolderModalOpen: false });
    this.setState({ addToFolderSuccess: true });
    setTimeout(() => {
      this.setState({ addToFolderSuccess: false });
    }, 3000);
  };

  handleErrorSnackBar = (msg) => {
    this.handleCancel();
    this.setState({ isAddToFolderModalOpen: false });
    this.setState({ 
      addToFolderError: true,
      addToFolderErrorMsg: msg
    });
    setTimeout(() => {
      this.setState({ addToFolderError: false });
    }, 3000);
  };

  handleSuccessSnackBarLibrary = () => {
    this.handleCancel();
    this.setState({ addToLibrarySuccess: true });
    setTimeout(() => {
      this.setState({ addToLibrarySuccess: false });
    }, 3000);
  };

  handleErrorSnackBarLibrary = () => {
    this.handleCancel();
    this.setState({ addToLibraryError: true });
    setTimeout(() => {
      this.setState({ addToLibraryError: false });
    }, 3000);
  };

  handleBulkAddToFolderModalOpen = () => {
    this.setState({ isAddToFolderModalOpen: true });
  };

  handleBulkAddToFolderModalClose = () => {
    this.setState({ isAddToFolderModalOpen: false });
  };

  handleCancel = () => {
    this.handleDeselectAll();
    this.setState({ multiSelectMode: !this.state.multiSelectMode });
  };

  handleMultiSelect = (item) => {
    const copyArr = [...this.state.selectedItems];
    if (copyArr.length !== 0) {
      if (!copyArr.filter((e) => e.item_id === item._id).length > 0) {
        copyArr.push({
          item_id: item._id,
          item_name: item.name,
          item_type: "Lesson",
        });
        let uniqueItems = [
          ...new Map(copyArr.map((v) => [v.item_id, v])).values(),
        ];
        this.setState({ selectedItems: uniqueItems });
      } else {
        copyArr.splice(
          copyArr.findIndex((x) => x.item_id === item._id),
          1
        ); //deleting
        this.setState({ selectedItems: copyArr });
      }
    } else {
      copyArr.push({
        item_id: item._id,
        item_name: item.name,
        item_type: "Lesson",
      });
      let uniqueItems = [
        ...new Map(copyArr.map((v) => [v.item_id, v])).values(),
      ];
      this.setState({ selectedItems: uniqueItems });
    }
  };

  handleSelectAll = () => {
    this.setState({ areAllSelected: true });
    const copyArr = [];
    this.state?.data?.forEach((item) => {
      copyArr.push({
        item_id: item._id,
        item_name: item.name,
        item_type: "Lesson",
      });
    });
    this.setState({ selectedItems: copyArr });
  };

  handleDeselectAll = () => {
    this.setState({ areAllSelected: false });
    this.setState({ selectedItems: [] });
  };

  handleOpenMultiselect = () => {
    this.setState({ multiSelectMode: !this.state.multiSelectMode });
  };

  handleAddToLibrary = () => {
    try {
      authAPI.bulkAddToLibrary(this.state.selectedItems).then((res) => {
        if (res.status === 200) {
          this.handleSuccessSnackBarLibrary();
        } else {
          this.handleErrorSnackBarLibrary();
        }
      });
    } catch (err) {
      console.log("Error when adding to library");
    }
  };

  getResources = () => {
    const resources = this.state?.searchActive
      ? this.state?.searchedData
      : this.state?.data;
    return resources?.length >= 0
      ? resources.map((item, i) => (
          <ResponsiveResourcesNew
            item={item}
            key={`lesson-resource-${i}`}
            i={i}
            handleClickForResourcesThumbnail={
              this.handleClickForResourcesThumbnail
            }
            handleClickForResourcesAddBtn={this.handleClickForResourcesAddBtn}
            handleSelectResourceClick={this.handleSelectResourceClick}
            multiSelectMode={this.state.multiSelectMode}
            addMultipleItems={this.addMultipleItems}
            deleteItem={this.deleteItem}
            multipleItems={this.state.multipleItems}
            scheduleTask={this.props.postScheduledTasksAction}
            active={
              parseInt(item?._id) === parseInt(this.state.selectedResourceId)
            }
            handleMultiSelect={this.handleMultiSelect}
            selectedItems={this.state.selectedItems}
          />
        ))
      : [];
  };

  render() {
    const navButtons = (
      <ResponsiveNavBtns
        displayableSelectedStrand={
          invertMap[constants.caseMap[this.props?.params?.strand]]
        }
        onSelectMenuItem={this.onSelectMenuItem}
        selectedStrand={this.props?.params?.strand}
        btnOptions={[
          "featured",
          "reading",
          "writing",
          "language",
          "speaking-listening",
        ]}
      />
    );

    const beforeNonFeaturedContent = this.state.addedMultipleItemsToLibrary &&
      this.state.multipleItems.length !== 0 && (
        <UnattachedPopover
          hideTooltip={() => {
            this.setState({ addedMultipleItemsToLibrary: false });
          }}
        >
          <div className="selected-checkbox-icon-add-multiple icon-icon-checkbox" />
          <span style={{ float: "left" }}>
            Successfully added&nbsp;
            <strong>
              {this.state.multipleItems.length}&nbsp;
              {this.state.multipleItems.length === 1 ? (
                <span>item</span>
              ) : (
                <span>items</span>
              )}
            </strong>
            &nbsp;to library
          </span>
          <div>
            <span>
              <button
                className="go-to-my-library-add-multiple"
                onClick={() => browserHistory.push("/library/assessments")}
              >
                GO TO MY LIBRARY
              </button>
            </span>
          </div>
        </UnattachedPopover>
      );

    return (
      <>
        <BrowsePageContent
          name="Lessons"
          isFeatured={this.state.isFeatured}
          resources={this.getResources()}
          navButtons={navButtons}
          resourceHeading={
            <ResourceHeading
              multiSelectMode={this.state.multiSelectMode}
              areAllSelected={this.state.areAllSelected}
              handleOpenMultiselect={this.handleOpenMultiselect}
              handleCancel={this.handleCancel}
              handleDeselectAll={this.handleDeselectAll}
              handleSelectAll={this.handleSelectAll}
              handleAddToLibrary={this.handleAddToLibrary}
              total={
                this.state.searchActive
                  ? this.state?.searchTotal
                  : this.state.total ?? 0
              }
              data={this.state.data}
              handleBulkAddToFolderModal={this.handleBulkAddToFolderModalOpen}
            />
          }
          beforeNonFeaturedContent={beforeNonFeaturedContent}
          hasMore={this.state.hasMore}
          loadMore={this._loadMore.bind(this)}
          updateFromSearch={this.updateFromSearch}
          getSearchNextPage={this.state.getSearchNextPage}
          setNoSearchNextPage={() =>
            this.setState({ getSearchNextPage: false })
          }
          asyncStatus={this.props?.lessons?.asyncStatus}
          location={this.props.location}
          selectedResource={
            this.state.lessonsDetail?.id || this.state.lessonsDetail?._id
              ? this.state.lessonsDetail
              : ""
          }
          selectedResourceType={"lessonsAll"}
        />
        {this.state.isAddToFolderModalOpen && (
          <AddToFolderModal
            handleClose={this.handleBulkAddToFolderModalClose}
            handleSuccessSnackbar={this.handleSuccessSnackBar}
            handleErrorSnackbar={this.handleErrorSnackBar}
            selectedItems={this.state.selectedItems}
          />
        )}

        <SnackBarModule
          addToFolderSuccess={this.state.addToFolderSuccess}
          addToFolderError={this.state.addToFolderError}
          addToFolderErrorMsg={this.state.addToFolderErrorMsg}
          addToLibrarySuccess={this.state.addToLibrarySuccess}
          addToLibraryError={this.state.addToLibraryError}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lessons: state.Lessons,
    finalFilters: state.FilterScale,
    lessonsDetail: state.LessonsDetail,
  };
};

export default connect(mapStateToProps, actions)(LessonsContent);
