import React from "react";
import { Row } from "react-bootstrap";
import PropTypes from "prop-types";
import filterSvg from "../../assets/icons/SVG/icon-filter-dropdown.svg";

export default class CollapsibleComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: props.collapsed || false,
      counter: props.counter,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.collapsed !== newProps.collapsed) {
      this.setState({ collapsed: newProps.collapsed });
    }
  }

  toggle() {
    if ((this.props.index || this.props.index === 0) && this.state.collapsed) {
      this.props.onToggleShow();
    }
    this.setState({ collapsed: !this.state.collapsed });
  }

  render() {
    // const toggleButton = !this.state.collapsed
    //     ? this.props.closeToggleButton
    //     : this.props.openToggleButton;
    let idText = this.props.idText ? this.props.idText : "";
    return (
      <div
        className={idText}
        style={
          !this.props.children || this.props.children.length === 0
            ? { opacity: "0.3", pointerEvents: "none" }
            : {}
        }
      >
        {this.props?.type === "filter" ? (
          <Row
            style={
              !this.state.collapsed
                ? this.props.headerStyleCollapsed
                : this.props.headerStyle
            }
            onClick={this.toggle}
            className={"collapsible-dropdown"}
          >
            {/* Conditionally render filter icon if passed as prop, used on all browse dropdown filters */}
            {this.props.filterIcon ? (
              <div style={{ margin: "0 5px" }}>
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={filterSvg}
                  alt="Dropdown filter icon"
                />
              </div>
            ) : (
              <></>
            )}

            {/* Filter dropdown Text */}
            {this.props.header}

            {/* Number of selected filters */}
            {this.props.standardCount ? (
              <span>
                {" "}
                ({this.props.occurance}/{this.props.standardCount})
              </span>
            ) : (
              ""
            )}

            <div style={{ position: "absolute", right: "20px" }}>
              {/* Counter */}
              {this.props.counter && this.props.counter > 0 ? (
                <div className="filter-number-background">
                  <div className="filter-number">{this.props.counter}</div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div
              style={{ position: "absolute", right: "5px", color: "#a29e9e" }}
            >
              {/* Chevron icon */}
              <span
                style={{
                  borderLeft: "1px solid #9B9B9B",
                  padding: "3px 5px 0 5px",
                  fontSize: "6px",
                }}
                className={"collapsible-arrow-right icon-icon-chevron-down"}
              />
            </div>
          </Row>
        ) : (
          <Row style={this.props.headerStyle || {}} onClick={this.toggle}>
            {this.props.header}
            {this.props.standardCount ? (
              <span>
                {" "}
                ({this.props.occurance}/{this.props.standardCount})
              </span>
            ) : (
              ""
            )}
            <span
              className={
                this.state.collapsed
                  ? "collapsible-arrow-right icon-arrow-solid-right"
                  : "collapsible-arrow-down icon-arrow-solid-down"
              }
            />
            {this.props.counter && this.props.counter > 0 ? (
              <div className="filter-number-background">
                <div className="filter-number">{this.props.counter}</div>
              </div>
            ) : (
              ""
            )}
          </Row>
        )}

        <div
          style={
            !this.state.collapsed && this.props?.type === "filter"
              ? {
                  padding: "5px",
                  border: "1px solid #33afca",
                  borderTop: 0,
                  borderBottomLeftRadius: "6px",
                  borderBottomRightRadius: "6px",
                  marginBottom: "10px",
                  marginTop: "-10px",
                  maxHeight: 145,
                  overflowY: "scroll",
                }
              : {}
          }
        >
          {!this.state.collapsed && this.props.children}
        </div>
      </div>
    );
  }
}

CollapsibleComponent.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node,
  collapsed: PropTypes.bool,
  headerStyle: PropTypes.object,
  openToggleButton: PropTypes.node,
  closeToggleButton: PropTypes.node,
  filterIcon: PropTypes.bool,
};
