import React , {Component} from 'react'

import LoginComponent from  '../../components/LoginComponent';


class Login extends Component{
    render(){

        // Fetch the domain from the route param. Defined when we navigate to /login/:domain
        let domain = this.props.routeParams.domain;
        return(
            <div>
                <LoginComponent domain={domain} />
            </div>
        );
    }
}

export default Login;