import React , {Component} from 'react';
import SSOAuthComponent from '../../components/SSOAuthComponent/';


class SSOAuthenticate extends Component{
    render(){
        return(
            <div>
            	<SSOAuthComponent />
            </div>
        );
    }
}

export default SSOAuthenticate;