import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import * as actions from "../../redux/actions";
import authAPI from "../../api/authAPI";
import constants from "../../constants";
import BrowsePageContent from "../shared/BrowsePageContent";
import steps from "./stepsTR";
import _ from "lodash";
import ResponsiveResourcesNew from "./ResponsiveResourcesNew";
import ResponsiveNavBtns from "./ResponsiveNavBtns";
import "../../assets/styles/mobile.scss";
import ResourceHeading from "../../components/ResourceHeading";
import AddToFolderModal from "../../components/AddToFolder/AddToFolderModal";
import { SnackBarModule } from "../../components/Shared";

const invertMap = [
  "Featured",
  "Reading",
  "Writing",
  "Speaking & Listening",
  "Language",
];

class LibraryResourcesContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: constants.caseMap[this.props?.params?.strand],
      isFeatured: false,
      DataSource: this.props,
      loadingMore: false,
      pageNumber: 1,
      lessonsDetail: {},
      resourceDetails: {},
      correlationDetails: {},
      hasMore: true,
      total: 0,
      filter: false,
      queryValue: "",
      multiSelectMode: false,
      multipleItems: {
        Resource: [],
        Lesson: [],
        Correlations: [],
      },
      areAllSelected: false,
      selectedItems: [],
      addedMultipleItemsToLibrary: false,
      addedMultipleItemsToFolder: false,
      isJoyrideRunning: false,
      isBackButtonPressed: false,
      selectedResourceId: "",
      removeFromLibrarySuccess: false,
      removeFromLibraryError: false,
      addToFolderSuccess: false,
      addToFolderError: false,
      addToFolderErrorMsg:""
    };

    this.continueTour = _.once(() => this.props.resetJoyride(steps.steps, 2));
    this.loadSteps = _.once(() => this.props.joyrideLoadSteps(steps.steps));
    window.sessionStorage.setItem("selectedFilters", JSON.stringify([]));
  }

  componentWillMount() {
    this.loadSteps();
  }

  millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  componentWillReceiveProps(next) {
    window.onpopstate = (e) => {
      this.setState({
        isBackButtonPressed: true,
      });
    };

    if (
      next?.resourceDetails?.asyncStatus &&
      (this.state.selectedDetailsType === "Resource" ||
        this.state.selectedDetailsType === "resources")
    ) {
      if (next?.resourceDetails?.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next?.resourceDetails?.asyncStatus === "FAILURE") {
        this.setState({ status: "FAILURE" });
      } else if (next?.resourceDetails?.data.status === "RESOURCE NOT FOUND") {
        this.setState({ status: "RESOURCE NOT FOUND" });
      } else if (next?.resourceDetails.asyncStatus === "SUCCESS") {
        this.setState({
          correlationDetails: {},
          lessonsDetail: {},
          resourceDetails: next?.resourceDetails.data,
          selectedResourceId: next.resourceDetails.data._id,
          status: "SUCCESS",
        });
      }
    } else if (
      next?.lessonsDetail?.asyncStatus &&
      (this.state.selectedDetailsType === "Lesson" ||
        this.state.selectedDetailsType === "lessons")
    ) {
      if (next.lessonsDetail.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next?.lessonsDetail?.asyncStatus === "FAILURE") {
        this.setState({ status: "FAILURE" });
      } else if (
        next?.lessonsDetail?.asyncStatus === "SUCCESS" &&
        next.lessonsDetail?.data?.status === "FAILURE FOR PRINT ONLY USER"
      ) {
        this.setState({ status: "FAILURE FOR PRINT ONLY USER" });
      } else if (
        next?.lessonsDetail?.asyncStatus === "SUCCESS" &&
        next.lessonsDetail?.data?.status === "FAILURE FOR NOT APPROVED"
      ) {
        this.setState({ status: "FAILURE FOR NOT APPROVED" });
      } else if (next?.lessonsDetail?.asyncStatus === "SUCCESS") {
        this.setState({
          correlationDetails: {},
          resourceDetails: {},
          lessonsDetail: next?.lessonsDetail.data,
          status: "SUCCESS",
          selectedResourceId: next.lessonsDetail.data._id,
        });
      }
    } else if (next?.correlationDetails?.asyncStatus) {
      if (next.correlationDetails.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next.correlationDetails.asyncStatus === "FAILURE") {
        this.setState({ correlationDetails: "FAILURE" });
      } else if (
        next.correlationDetails.asyncStatus === "SUCCESS" &&
        next.correlationDetails.data.status === "FAILURE FOR PRINT ONLY USER"
      ) {
        this.setState({ status: "FAILURE FOR PRINT ONLY USER" });
      } else if (
        next.correlationDetails.asyncStatus === "SUCCESS" &&
        next.correlationDetails.data.status === "FAILURE FOR NOT APPROVED"
      ) {
        this.setState({ status: "FAILURE FOR NOT APPROVED" });
      } else if (next.correlationDetails.asyncStatus === "SUCCESS") {
        let data = {
          id: next.correlationDetails.data._id,
          name: next.correlationDetails.data.name,
          publish: next.correlationDetails.data.publish,
          texts: next.correlationDetails.data.mentorTexts,
          grades: next.correlationDetails.data.grades.join(", "),
          forms: next.correlationDetails.data.files.map((file) => ({
            file,
            image: file + ".thumb.jpg",
          })),
          description: "",
          image: "",
          printable: next.correlationDetails.data.printable,
        };

        if (next.correlationDetails.data.description.description) {
          data.description =
            next.correlationDetails.data.description.description;
        } else if (next.correlationDetails.data.description.text) {
          data.description = next.correlationDetails.data.description.text;
        }

        if (next.correlationDetails.data.image) {
          data.image = next.correlationDetails.data.image;
        }

        this.setState({
          correlationDetails: data,
          status: "SUCCESS",
          selectedResourceId: next.correlationDetails.data._id,
        });
      }
    }
  }

  _loadMore() {
    setTimeout(() => {
      if (this.props?.myLibrary?.data?.teachingResources?.nextPage !== -1) {
        let strandInfinite = constants.caseMap[this.props?.params?.strand],
          filtersSet =
            this.props.finalFilters &&
            this.props.finalFilters.filterList &&
            this.props.finalFilters.filterList.length > 0,
          eitherFiltersSetOrQueryEmpty =
            (filtersSet && this.state.queryValue !== "") ||
            (!filtersSet && this.state.queryValue === "");

        if (
          this.props?.params?.strand !== "featured" &&
          eitherFiltersSetOrQueryEmpty
        ) {
          authAPI
            .getResources(
              this.state.pageNumber + 1,
              strandInfinite,
              this.state.queryValue
            )
            .then((resp) => {
              if (resp.total && resp?.results) {
                this.setState({
                  total: resp.total,
                  pageNumber: Number(resp.currentPage),
                  data: _.concat(this.state.data, resp.results),
                  loadingMore: false,
                  filter: false,
                });
              }
            });
        }
      } else {
        this.setState({
          loadingMore: false,
          hasMore: false,
        });
      }
    }, 1000);
  }

  addMultipleItems = (tid, type = {}) => {
    if (type === "Resource" || type === "resources") {
      this.state.multipleItems["Resource"].push(tid);
    } else if (type === "Correlations" || type === "correlations") {
      this.state.multipleItems["Correlations"].push(tid);
    } else {
      this.state.multipleItems["Lesson"].push(tid);
    }
    this.setState({ multipleItems: this.state.multipleItems });
  };

  deleteItem = (tid, type = "") => {
    if (type === "Resource") {
      let idx = this.state.multipleItems["Resource"].indexOf(tid);
      this.state.multipleItems["Resource"].splice(idx, 1);
    } else if (type === "Correlations") {
      let idx = this.state.multipleItems["Correlations"].indexOf(tid);
      this.state.multipleItems["Correlations"].splice(idx, 1);
    } else {
      let idx = this.state.multipleItems["Lesson"].indexOf(tid);
      this.state.multipleItems["Lesson"].splice(idx, 1);
    }

    this.setState({ multipleItems: this.state.multipleItems });
  };

  addMultipleItemsToLibrary = () => {
    for (let i = 0; i < this.state.multipleItems["Resource"].length; i++) {
      this.props
        .postAddTeachingResourceToLibraryAction(
          this.state.multipleItems["Resource"][i]
        )

        .then((response) => {
          if (response.statusCode === 200) {
            console.log(
              "Item added to library",
              this.state.multipleItems["Resource"][i]
            );
          } else {
            console.log(
              "Error ! Item not added to library",
              this.state.multipleItems["Resource"][i]
            );
          }
        })
        .catch(function (error) {
          console.log("Exception! Item not added to library");
        });
    }
    for (let i = 0; i < this.state.multipleItems["Lesson"].length; i++) {
      this.props
        .postAddLessonToLibraryAction(this.state.multipleItems["Lesson"][i])

        .then((response) => {
          if (response.statusCode === 200) {
            console.log(
              "Item added to library",
              this.state.multipleItems["Lesson"][i]
            );
          } else {
            console.log(
              "Error ! Item not added to library",
              this.state.multipleItems["Lesson"][i]
            );
          }
        })
        .catch(function (error) {
          console.log("Exception! Item not added to library");
        });
    }
    for (let i = 0; i < this.state.multipleItems["Correlations"].length; i++) {
      this.props
        .postAddCorrelationToLibraryAction(
          this.state.multipleItems["Correlations"][i]
        )

        .then((response) => {
          if (response.statusCode === 200) {
            console.log(
              "Item added to library",
              this.state.multipleItems["Correlations"][i]
            );
          } else {
            console.log(
              "Error ! Item not added to library",
              this.state.multipleItems["Correlations"][i]
            );
          }
        })
        .catch(function (error) {
          console.log("Exception! Item not added to library");
        });
    }
  };

  addMultipleItemsToFolder = () => {
    let items = [];
    let itemResources = [];
    for (let i = 0; i < this.state.multipleItems["Resource"].length; i++) {
      items.push(this.state.multipleItems["Resource"][i]);
      itemResources.push("Resource");
    }
    for (let i = 0; i < this.state.multipleItems["Lesson"].length; i++) {
      items.push(this.state.multipleItems["Lesson"][i]);
      itemResources.push("Lesson");
    }
    for (let i = 0; i < this.state.multipleItems["Correlations"].length; i++) {
      items.push(this.state.multipleItems["Correlations"][i]);
      itemResources.push("Correlations");
    }

    browserHistory.push({
      pathname: "/library/addfolder",
      state: {
        libraryItemId: items,
        libraryItemType: itemResources,
        isLibrary: false,
      },
    });
    this.props.saveAssignmentItemAction(itemResources, items);
  };

  handleAddMultiple = function (desktop) {
    this.addMultipleItemsToLibrary();
    this.setState(
      {
        multiSelectMode: !this.state.multiSelectMode,
        addedMultipleItemsToLibrary: true,
      },
      () => {
        if (desktop)
          setTimeout(
            () =>
              this.setState({
                addedMultipleItemsToLibrary: false,
                multipleItems: {
                  Resource: [],
                  Lesson: [],
                  Correlations: [],
                },
              }),
            3000
          );
      }
    );
  };

  handleAddMultipleToFolder = function (desktop) {
    this.addMultipleItemsToFolder();
    this.setState(
      {
        multiSelectMode: !this.state.multiSelectMode,
        addedMultipleItemsToFolder: true,
      },
      () => {
        if (desktop)
          setTimeout(
            () =>
              this.setState({
                addedMultipleItemsToFolder: false,
                multipleItems: {
                  Resource: [],
                  Lesson: [],
                  Correlations: [],
                },
              }),
            3000
          );
      }
    );
  };

  onSelectMenuItem = (ekey) => {
    browserHistory.replace(`/browse/teaching-resources/${ekey}`);
  };

  //This function expects to be bound in a child component's onClick
  handleClickForResourcesThumbnail = function (item) {
    if (this.props?.multiSelectMode) {
      if (
        (item.type === "Resource" &&
          this.props.multipleItems["Resource"].indexOf(item._id) === -1) ||
        (item.type === "Lesson" &&
          this.props.multipleItems["Lesson"].indexOf(item._id) === -1)
      ) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        item.type !== "Correlations" &&
          this.props.deleteItem(item._id, item.type);
      }
    } else {
      let id = item?._id ?? item?.id;
      switch (item.type) {
        case "Resource":
          browserHistory.push("/resources/" + id);
          return;
        case "resources":
          browserHistory.push("/resources/" + id);
          return;
        case "Correlations":
          browserHistory.push("/correlations/" + id);
          return;
        case "correlations":
          browserHistory.push("/correlations/" + id);
          return;
        default:
          browserHistory.push("/lessons/" + id);
          return;
      }
    }
  };

  //This function expects to be bound in a child component's onClick
  handleClickForResourcesAddBtn = function (item) {
    if (this.props.multiSelectMode) {
      if (
        (item.type === "Resource" &&
          this.props.multipleItems["Resource"].indexOf(item._id) === -1) ||
        (item.type === "Lesson" &&
          this.props.multipleItems["Lesson"].indexOf(item._id) === -1) ||
        (item.type === "Correlations" &&
          this.props.multipleItems["Correlations"].indexOf(item._id) === -1)
      ) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        this.props.deleteItem(item._id, item.type);
      }
    } else {
      //This replaces "showTooltip" in popover.
      this.setState({
        isTooltipActive: true,
        success: false,
        showDatePicker: false,
      });
    }
  };

  handleSelectResourceClick = (item) => {
    if (item.type) {
      this.setState({
        selectedDetailsType: item.type,
        selectedResourceId: "",
        selectedResourceName: "",
      });
      if (item.type === "Resource" || item.type === "resources") {
        this.props.getResourceDetailsAction(item._id);
      } else if (item.type === "Correlations" || item.type === "correlations") {
        this.props.getCorrelationDetailsAction(item._id);
      } else {
        this.props.getLessonsDetailedAction(item._id);
      }
    }
  };

  updateFromSearch = (data, total) => {
    this.setState({
      total: total ?? data?.length ?? 0,
      data: data ?? [],
      pageNumber: 1,
      filter: false,
      hasMore: false,
    });
  };

  handleCancel = () => {
    this.handleDeselectAll();
    this.setState({ multiSelectMode: !this.state.multiSelectMode });
  };

  handleMultiSelect = (item) => {
    const copyArr = [...this.state.selectedItems];
    if (copyArr.length !== 0) {
      if (!copyArr.filter((e) => e.item_id === item._id).length > 0) {
        copyArr.push({
          item_id: item._id,
          item_name: item.name,
          item_type: item.type,
        });
        let uniqueItems = [
          ...new Map(copyArr.map((v) => [v.item_id, v])).values(),
        ];
        this.setState({ selectedItems: uniqueItems });
      } else {
        copyArr.splice(
          copyArr.findIndex((x) => x.item_id === item._id),
          1
        ); //deleting
        this.setState({ selectedItems: copyArr });
      }
    } else {
      copyArr.push({
        item_id: item._id,
        item_name: item.name,
        item_type: item.type,
      });
      let uniqueItems = [
        ...new Map(copyArr.map((v) => [v.item_id, v])).values(),
      ];
      this.setState({ selectedItems: uniqueItems });
    }
  };

  handleSelectAll = () => {
    this.setState({ areAllSelected: true });
    const copyArr = [];
    this.props?.myLibrary?.data?.teachingResources?.results?.forEach((item) => {
      copyArr.push({
        item_id: item._id,
        item_name: item.name,
        item_type: item.type,
      });
    });
    this.setState({ selectedItems: copyArr });
  };

  handleDeselectAll = () => {
    this.setState({ areAllSelected: false });
    this.setState({ selectedItems: [] });
  };

  handleOpenMultiselect = () => {
    this.setState({ multiSelectMode: !this.state.multiSelectMode });
  };

  handleRemoveFromLibrary = () => {
    authAPI.bulkRemoveFromLibrary(this.state.selectedItems).then((resp) => {
      if (resp?.statusCode === 200) {
        this.setState({ multiSelectMode: false });
        this.setState({ removeFromLibrarySuccess: true });
        this.props.getCustomLibraryFiltersAction(
          this.props.strand,
          constants.normalizeTypeName("teachingResources"),
          this.props.query
        );
        setTimeout(() => {
          this.setState({ removeFromLibrarySuccess: false });
        }, 3000);
      } else {
        this.setState({ removeFromLibraryError: true });
        setTimeout(() => {
          this.setState({ removeFromLibraryError: false });
        }, 3000);
      }
    });
  };

  handleSuccessSnackBar = () => {
    this.handleCancel();
    this.setState({ isAddToFolderModalOpen: false });
    this.setState({ addToFolderSuccess: true });
    setTimeout(() => {
      this.setState({ addToFolderSuccess: false });
    }, 3000);
  };

  handleErrorSnackBar = (msg) => {
    this.handleCancel();
    this.setState({ isAddToFolderModalOpen: false });
    this.setState({ 
      addToFolderError: true,
      addToFolderErrorMsg:msg
    });
    setTimeout(() => {
      this.setState({ addToFolderError: false });
    }, 3000);
  };

  handleBulkAddToFolderModalOpen = () => {
    this.setState({ isAddToFolderModalOpen: true });
  };

  handleBulkAddToFolderModalClose = () => {
    this.setState({ isAddToFolderModalOpen: false });
  };

  getResources = () => {
    const resources = this.props?.myLibrary?.data?.teachingResources?.results;
    const startE = new Date().getTime();
    return resources?.length >= 0
      ? resources.map((item, i) => (
          <ResponsiveResourcesNew
            item={item}
            i={i}
            key={i}
            handleClickForResourcesThumbnail={
              this.handleClickForResourcesThumbnail
            }
            handleClickForResourcesAddBtn={this.handleClickForResourcesAddBtn}
            handleSelectResourceClick={this.handleSelectResourceClick}
            multiSelectMode={this.state.multiSelectMode}
            addMultipleItems={this.addMultipleItems}
            deleteItem={this.deleteItem}
            multipleItems={this.state.multipleItems}
            scheduleTask={this.props.postScheduledTasksAction}
            addToLibrary={
              item.type === "Resource"
                ? this.props.postAddTeachingResourceToLibraryAction
                : item.type === "Correlations"
                ? this.props.postAddCorrelationToLibraryAction
                : this.props.postAddLessonToLibraryAction
            }
            addLessonToLibrary={this.props.postAddLessonToLibraryAction}
            startE={startE}
            active={
              parseInt(item?._id) === parseInt(this.state.selectedResourceId)
            }
            type={"library"}
            handleMultiSelect={this.handleMultiSelect}
            selectedItems={this.state.selectedItems}
          />
        ))
      : [];
  };

  render() {
    const navButtons = (
      <ResponsiveNavBtns
        displayableSelectedStrand={
          invertMap[constants.caseMap[this.props?.params?.strand]]
        }
        onSelectMenuItem={this.onSelectMenuItem}
        selectedStrand={this.props?.params?.strand}
        btnOptions={[
          "featured",
          "reading",
          "writing",
          "language",
          "speaking-listening",
        ]}
        nextJoyrideStep={this.props.nextJoyrideStep}
        isJoyrideRunning={this.props.isJoyrideRunning}
      />
    );

    return (
      <>
        <BrowsePageContent
          name="Teaching Resources"
          asyncStatus={this.props?.myLibrary?.asyncStatus ?? ""}
          resources={this.getResources()}
          selectedResource={
            this.state.resourceDetails?.id || this.state.resourceDetails?._id
              ? this.state.resourceDetails
              : this.state.lessonsDetail?.id || this.state.lessonsDetail?._id
              ? this.state.lessonsDetail
              : this.state.correlationDetails?.id ||
                this.state.correlationDetails?._id
              ? this.state.correlationDetails
              : ""
          }
          selectedResourceType={
            this.state.resourceDetails?.id || this.state.resourceDetails?._id
              ? "resource"
              : this.state.lessonsDetail?.id || this.state.lessonsDetail?._id
              ? "lesson"
              : this.state.correlationDetails?.id ||
                this.state.correlationDetails?._id
              ? "correlation"
              : ""
          }
          handleClickForResourcesThumbnail={
            this.handleClickForResourcesThumbnail
          }
          navButtons={navButtons}
          isFeatured={this.state.isFeatured}
          hasMore={this.state.hasMore}
          loadMore={this._loadMore.bind(this)}
          updateFromSearch={this.updateFromSearch}
          location={this.props.location}
          type={"library"}
          resourceHeading={
            <ResourceHeading
              multiSelectMode={this.state.multiSelectMode}
              areAllSelected={this.state.areAllSelected}
              handleOpenMultiselect={this.handleOpenMultiselect}
              handleCancel={this.handleCancel}
              handleDeselectAll={this.handleDeselectAll}
              handleSelectAll={this.handleSelectAll}
              handleRemoveFromLibrary={this.handleRemoveFromLibrary}
              handleBulkAddToFolderModal={this.handleBulkAddToFolderModalOpen}
            />
          }
        />
        {this.state.isAddToFolderModalOpen && (
          <AddToFolderModal
            handleClose={this.handleBulkAddToFolderModalClose}
            handleSuccessSnackbar={this.handleSuccessSnackBar}
            handleErrorSnackbar={this.handleErrorSnackBar}
            selectedItems={this.state.selectedItems}
          />
        )}

        <SnackBarModule
          addToFolderSuccess={this.state.addToFolderSuccess}
          addToFolderError={this.state.addToFolderError}
          addToFolderErrorMsg={this.state.addToFolderErrorMsg}
          removeFromLibrarySuccess={this.state.removeFromLibrarySuccess}
          removeFromLibraryError={this.state.removeFromLibraryError}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    myLibrary: state.MyLibrary,
    strand: state.CustomFilters?.strand,
    query: state.FilterScale.query,
    finalFilters: state.FilterScale,
    isJoyrideRunning: state.JoyrideReducer.joyrideShouldRun,
    resourceDetails: state.ResourceDetails,
    lessonsDetail: state.LessonsDetail,
    correlationDetails: state.CorrelationDetails,
  };
};

export default connect(mapStateToProps, actions)(LibraryResourcesContent);
