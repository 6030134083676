import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap'
import {Link} from 'react-router'

// Renders the content of a single item in the list view for Units/Assessment and Units/Texts
// The implementation shows an image on the left, an eye on the right, and content in the center

// props:
//  - detailURL - url where user is directed when they click the image on the left
//  - imageSrc - src of image to be displayed
//  - contentHeading (optional) - content heading, displayed at the top, usually the name
//  - rowId - id of the rendered row

//  children - content inserted under heading
class DetailsListViewItem extends Component {
    render() {
        console.log(this.props.itemType);
        console.log(this.props.buyBorrowData);
        let buyBorrowData = this.props.buyBorrowData;
        return (
            <div>
                {this.props.rowId > 0 && <div className='row-seperator'/>}
                <Row key={this.props.rowId} className='text-component-row-padding'>
                    {/*Image*/}
                    <Col xs={2} sm={2} lg={1} className='xs-image-border'>
                        
                        {this.props.itemType && this.props.itemType == "units" &&                           
                            <Link to={{pathname: this.props.detailURL, state: {itemType: "units", buyBorrowData: buyBorrowData}}}>
                                <div className='image-border-text'>
                                    <img src={this.props.imageSrc} style={{width: "100%"}}/>
                                </div>
                            </Link>                           
                        }
                        {!this.props.itemType && 
                            <a href={this.props.detailURL}>
                                <div className='image-border-text'>
                                    <img src={this.props.imageSrc} style={{width: "100%"}}/>
                                </div>
                            </a>        
                        }
                        
                    </Col>
                    {/*Text and icon*/}
                    <Col xs={10} sm={10} lg={11}>
                        <Row>
                            {/*Text*/}
                            <Col xs={12} sm={10} md={11} className='xs-left-no-padding'>
                                {/* Inner content */}
                                <div>
                                    <div className="content-heading xs-hide-content text-component-row-heading">
                                        {this.props.contentHeading &&
                                        <a href={this.props.detailURL} className="detail-link">
                                            {this.props.contentHeading}
                                        </a>
                                        }
                                    </div>
                                    <div className='xs-hide-content'>
                                        {this.props.children}
                                    </div>
                                </div>
                            </Col>
                            {/*View Icon*/}
                            <Col sm={2} md={1} className='hidden-xs'>
                                <span>
                                    <a href={this.props.detailURL}>
                                        <div className="view-icon-in-text icon--view"/>
                                    </a>
                                </span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default DetailsListViewItem