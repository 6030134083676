import React,{Component} from 'react'
import {Row, Col} from 'react-bootstrap'
import authAPI from '../../api/authAPI'
import Datetime from 'react-datetime'

class TextBox extends Component{
    render(){
        return(
            <div className='textBox-wrapper'>
                <h3 dangerouslySetInnerHTML={{__html: this.props.valueType}} />
                <input className={(this.props.errorValue === "")? "add-user-form" : "add-user-form add-user-form-error" }
                name={this.props.name}
                type={this.props.type || 'text'}
                value={this.props.value}
                placeholder={this.props.placeholder}
                onChange={this.props.onChange}/>
                {this.props.errorValue !== "" &&
                    <div className="add-user-error">
                        {this.props.errorValue}
                    </div>
                }
            </div>
        );
    }
}

class UserForm extends Component{
    constructor(props) {
       super(props);
       this.state = {
           form: {
               firstName: "",
               lastName: "",
               email: "",
               schoolId: "",
               userType: "",
               isActive: true,
               schoolDistrictId:"",
               password: "",
               confirmPassword: "",
               expirationDate: "",
               showDatePicker: false
           },
           errorMessage: "",
           firstNameValid: "",
           lastNameValid: "",
           emailValid: "",
           schoolIdValid: "",
           passwordValid: "",
           confirmPasswordValid: "",
           userTypeValid: "",
           expirationDateValid: "",
           adminOptions: ["School Admin", "Unlimited", "Print Only", "Text Only"],
           schoolOptions: ["System Admin","Content Editor"],
           schoolDistrictOptions:[],
           schoolDistrictIdValid:""
       };

       if (this.props.userData) {
           this.state.form.firstName = this.props.userData.first_name;
           this.state.form.lastName = this.props.userData.last_name;
           this.state.form.email = this.props.userData.username;
           this.state.form.schoolId = this.props.userData.user_id_number ? this.props.userData.user_id_number : '';
           this.state.form.userType = this.props.userData.user_type;
           this.state.form.isActive = this.props.userData.is_active;
           this.state.form.schoolDistrictId = this.props.userData.district_id;
           this.state.form.expirationDate = this.props.userData.expiration_date;
       }
       else {
           this.state.form.schoolDistrictId = this.props.districtId;
           this.state.form.userType = this.props.userType;
       }

       this.onChange = this.onChange.bind(this);
       this.onActiveChange = this.onActiveChange.bind(this);
    }

    componentDidMount() {
        if(this.state.form.userType === 'admin' || this.state.form.userType === "System Admin" || this.state.form.userType === "Content Editor") {
            authAPI.getDistricts("", 1, 999999).then((response) => {
                if (response) {
                    let items = [];

                    for(let i = 0; i<response.results.length; i++) {
                        items.push({
                            _id: response.results[i]._id,
                            name: response.results[i].name
                        });
                    }

                    this.setState({
                        schoolDistrictOptions: items,
                    });
                }
            });
        }
    }

    onChange(event) {
        if (event.target.type === "select-one" ) {
            if(event.target.value === "Select Type") {
                return;
            }
        }

        this.state.form[event.target.name] = event.target.value;
        this.setState({form: this.state.form});
        
    }

    handleDate(date){
        this.state.form.expirationDate = date._d;
        this.setState({form: this.state.form});
    };

    onActiveChange(event) {
        this.state.form.isActive = event.target.checked;
        this.setState({form: this.state.form});
    }

    validateInput() {
        let isGood = true;
        // True if this UserForm is performing an update
        let isSysAdmin = localStorage.user_type === "System Admin" || localStorage.user_type === "School Admin";

        if(this.state.form.firstName === "") {
            this.setState({firstNameValid: "First Name cannot be blank."});
            isGood = false;
        }
        else if (this.setState.firstNameValid !== ""){
            this.setState({firstNameValid: ""});
        }

        if(this.state.form.lastName === "") {
            this.setState({lastNameValid: "Last Name cannot be blank."});
            isGood = false;
        }
        else if (this.setState.lastNameValid !== ""){
            this.setState({lastNameValid: ""});
        }

        if(this.state.form.userType!=='Student'){
            if(this.state.form.userType === "" || this.state.form.userType==='combinedList' || this.state.form.userType==='admin') {
                this.setState({userTypeValid: "User Type is mandatory"});
                isGood = false;
            }
            // validation to check if licenses are available.
            else if(!this.props.userData && (this.state.form.userType === 'Unlimited') && this.props.unlimited_available === 0) {
                this.setState({userTypeValid: "There are no Unlimited licenses available"});
                isGood = false;
            }
            else if(!this.props.userData && (this.state.form.userType === 'Print Only') && this.props.print_only_available === 0) {
                this.setState({userTypeValid: "There are no Print Only licenses available"});
                isGood = false;
            }
            else if (this.setState.userTypeValid !== ""){
                this.setState({userTypeValid: ""});
                isGood = true;
            }
        }

        if(this.state.form.userType === 'admin' || this.state.form.userType === "System Admin" || this.state.form.userType === "Content Editor"){
            console.log(this.state.form.schoolDistrictId);
            if(this.state.form.schoolDistrictId == undefined || this.state.form.schoolDistrictId === ""){
                this.setState({schoolDistrictIdValid: "School district is mandatory"});
                isGood = false;
            }
        }

        if(this.state.form.email === "") {
            this.setState({emailValid: "Email cannot be blank."});
            isGood = false;
        }
        else {
            // eslint-disable-next-line
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(re.test(this.state.form.email)){
                if (this.setState.emailValid !== ""){
                    this.setState({emailValid: ""});
                }
            }
            else{
                this.setState({emailValid: "Please enter a valid email address"});
                isGood = false;
            }
        }

        let passwordRegEx =/(?=^.{8,}$)(?=(?:.*?\d){1})(?=.*[a-z])(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%^&*]*$/;
        if(this.state.form.password !== "" || this.state.form.confirmPassword !== "") {
            if(this.state.form.password !== this.state.form.confirmPassword) {
               this.setState({confirmPasswordValid: "Passwords must match.", passwordValid: ""});
               isGood = false;
            } else {
                // Password is always valid if:
                // 1. Sys admin and we are doing an up update
                let passwordAccepted =
                    // Password is accepted if the user is a system admin and we're doing an update
                    //(isSysAdmin) ||
                        // or it passes the regex test
                        passwordRegEx.test(this.state.form.password);

              if(passwordAccepted){
                  this.setState({confirmPasswordValid: "", passwordValid: ""});
              } else {
                  this.setState({confirmPasswordValid: "", passwordValid: "Passwords must be at least 8 characters and include at least one UPPERCASE, one lowercase, one number, and one of the following: !@#$%^&*"});
                  isGood = false;
              }
            }
        } else {
          this.setState({confirmPasswordValid: "", passwordValid: ""});
        }

        return isGood;
    }

    getData() {
        return {
            firstName: this.state.form.firstName.trim(),
            lastName: this.state.form.lastName.trim(),
            email: this.state.form.email.trim(),
            password: this.state.form.password,
            schoolId: this.state.form.schoolId,
            userType: this.state.form.userType,
            districtId: this.props.schoolDistrictId ? this.props.schoolDistrictId : (this.state.form.schoolDistrictId === "" ? undefined : this.state.form.schoolDistrictId),
            isActive: this.state.form.isActive,
            expirationDate: this.state.form.expirationDate
        }
    }

    clearError() {
        if(this.state.errorMessage !== "") {
            this.setState({errorMessage: ""});
        }
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    render() {
        return(
            <div className='form-wrapper-outer'>
                <div className='form-wrapper-inner'>
                    <form>
                        <Row>
                            <Col xs={6} md={6}>
                                <TextBox name='firstName' errorValue={this.state.firstNameValid} value={this.state.form.firstName} placeholder="FIRST NAME" valueType="FIRST NAME<span class='required-asterisk'>*</span>" onChange={this.onChange}/>
                            </Col>
                            <Col xs={6} md={6}>
                                <TextBox name='lastName' errorValue={this.state.lastNameValid} value={this.state.form.lastName} placeholder="LAST NAME" valueType="LAST NAME<span class='required-asterisk'>*</span>" onChange={this.onChange}/>
                            </Col>
                        </Row>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <TextBox name='email' errorValue={this.state.emailValid} value={this.state.form.email} placeholder="EMAIL" valueType="EMAIL<span class='required-asterisk'>*</span>" onChange={this.onChange}/>
                        </Row>
                        {this.state.form.userType !== 'admin' && this.state.form.userType !== "System Admin" && this.state.form.userType !== "Content Editor" &&
                            <Row style={{paddingLeft: '15px', paddingRight: '15px'}}>
                                <TextBox name='schoolId' errorValue={this.state.schoolIdValid}
                                         value={this.state.form.schoolId} placeholder="SCHOOL ID NUMBER" valueType="SCHOOL ID NUMBER"
                                         onChange={this.onChange}/>
                            </Row>
                        }
                        {this.state.form.userType === 'Student' &&
                          <Row style={{paddingLeft: '15px', paddingRight: '15px'}}>
                              <TextBox
                                  name='password'
                                  type="password"
                                  Instruction="Passwords must be at least 8 characters and include at least one UPPERCASE, one lowercase, one number, and one of the following: !@#$%^&*"
                                  errorValue={this.state.passwordValid}
                                  value={this.state.form.password}
                                  valueType="PASSWORD"
                                  onChange={this.onChange}
                                  />
                          </Row>
                        }
                        {this.state.form.userType === 'Student' &&
                          <Row style={{paddingLeft: '15px', paddingRight: '15px'}}>
                              <TextBox
                                  name='confirmPassword'
                                  type="password"
                                  Instruction="Passwords must match."
                                  errorValue={this.state.confirmPasswordValid}
                                  value={this.state.form.confirmPassword}
                                  valueType="CONFIRM PASSWORD"
                                  onChange={this.onChange}
                                  />
                          </Row>
                        }
                        
                        {/* Changed Teacher to Unlimited and added '|| this.state.form.userType === 'Print Only' */}
                        {(this.state.form.userType === 'combinedList' || this.state.form.userType === 'admin' || this.state.form.userType === 'School Admin' || this.state.form.userType === 'Unlimited'|| this.state.form.userType === 'Text Only' || this.state.form.userType === 'Print Only' || this.state.form.userType === 'System Admin' || this.state.form.userType === 'Content Editor') &&
                            <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                                <Col xs={6} md={6} style={{paddingTop: 20,paddingLeft:0}}>
                                    <p style={{fontFamily: "ProximaNova", fontSize: 12, fontWeight: "bold", lineHeight: "20px"}}>
                                        USER TYPE<span className='required-asterisk'>*</span>
                                    </p>
                                    <select className={(this.state.userTypeValid === "")? "add-user-form" : "add-user-form add-user-form-error" } type="text" name='userType' onChange={this.onChange} value={this.state.form.userType}>
                                        <option selected>Select Type</option>
                                        {/* Changed Teacher to Unlimited and added '|| this.state.form.userType === 'Print Only' */}
                                        {(this.state.form.userType === 'combinedList' || this.state.form.userType === 'School Admin' || this.state.form.userType === 'Unlimited' || this.state.form.userType === 'Print Only' || this.state.form.userType === 'Text Only')&& this.state.adminOptions.map((item, i) => (
                                            <option key={i} value={item}>{item}</option>
                                        ))}
                                        {(this.state.form.userType === 'admin' || this.state.form.userType === 'System Admin' || this.state.form.userType === 'Content Editor') && this.state.schoolOptions.map((item, i) => (
                                            <option key={i} value={item}>{item}</option>
                                        ))}
                                    </select>
                                    {this.state.userTypeValid &&
                                        <div className="add-user-error">{this.state.userTypeValid}</div>
                                    }
                                </Col>

                                {/* {(this.state.form.userType === 'Unlimited' && this.state.form.expirationDate !== null && this.props.userData)  && <Col xs={6} md={6} style={{paddingTop: 20,paddingLeft:0}}>
                                    <p style={{fontFamily: "ProximaNova", fontSize: 12, fontWeight: "bold", lineHeight: "20px"}}>
                                        EXPIRATION DATE<span className='required-asterisk'>*</span>
                                    </p>
                                    <Datetime className='mobile-datetime user-modal-form' 
                                            input={true} 
                                            closeOnSelect={true} 
                                            defaultValue={new Date(this.state.form.expirationDate)}
                                            dateFormat={true}
                                            timeFormat={false}
                                            inputProps={{required:true, readOnly: true, name: 'expirationDate'}}
                                            onChange={this.handleDate.bind(this)}/>
                                </Col>
                                } */}

                            </Row>
                        }
                        {(this.state.form.userType === 'admin' || this.state.form.userType === "System Admin" || this.state.form.userType === "Content Editor") &&
                            <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                                <Col xs={6} md={6} style={{paddingTop: 20,paddingLeft:0}}>
                                    <p style={{fontFamily: "ProximaNova", fontSize: 12, fontWeight: "bold", lineHeight: "20px"}}>
                                        SCHOOL DISTRICT<span className='required-asterisk'>*</span>
                                    </p>
                                    <select className="add-user-form" type="text" name='schoolDistrictId' onChange={this.onChange} value={this.state.form.schoolDistrictId}>
                                        <option key="" value="">None</option>
                                        {this.state.schoolDistrictOptions.map((item, i) => (
                                            <option key={i._id} value={item._id}>{item.name}</option>
                                        ))}
                                    </select>
                                    {this.state.schoolDistrictIdValid &&
                                        <div className="add-user-error">{this.state.schoolDistrictIdValid}</div>
                                    }
                                </Col>
                            </Row>
                        }
                        {this.props.userData &&
                            <Row style={{paddingLeft: '15px', paddingRight: '15px'}}>
                                <div className='textBox-wrapper'>
                                    <h3> IS ACTIVE: <input type="checkbox" checked={this.state.form.isActive} onChange={this.onActiveChange} /></h3>
                                </div>
                            </Row>
                        }
                        {this.state.errorMessage !== "" &&
                            <Row style={{paddingLeft: '15px', paddingRight: '15px'}}>
                                <div className="add-user-error">
                                    {this.state.errorMessage}
                                </div>
                            </Row>
                        }
                    </form>
                </div>
            </div>
        );
    }
}

export default UserForm
