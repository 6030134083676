import React,{Component} from  'react'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import BrowsePage from "../shared/BrowsePage"

export class Units extends Component{

    render(){
        return(
            <BrowsePage
                name="Units"
                typeOfResource="units" strandValue={this.props.params.strand}
            >
                {this.props.children}
            </BrowsePage>
        );
    }
}

export default connect(null, actions)(Units)