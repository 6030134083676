import React,{Component} from 'react'
import {Row,Col,Panel} from 'react-bootstrap'
import {browserHistory,Link} from 'react-router'
import LessonPopOver from './LessonPopOver'
import LessonPopOverMobile from './LessonPopOverMobile'
import {markdownHelper} from '../../markdown/MarkdownHelper';
import {annotationsHelper} from '../../annotations/AnnotationsHelper';
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

class OverviewContent extends Component{
    render(){
        let overview = this.props.content.lessons;
        // if(overview && overview.length > 0){
        //     overview.sort((a,b)=>a.name.replace(/\s/g, '').localeCompare(b.name.replace(/\s/g, ''), 'en', { numeric: true }));
        // }
        return(
            <div className="unit-lessons">
                {(this.props.content && overview) ? "" :
                    <div className="featured-reading" style={{textAlign: "center"}}>
                        This Unit is Temporarily Unavailable, Please Check Back Later.
                    </div>
                }
                {overview && overview.map((lesson, i)=>(
                    <div id="my-panel" className="panel-lesson-content">
                        <Link className="panel-click-area anchor-color" to= {'/lessons/'+lesson._id} target="_blank">
                            <div className="panel-item panel-item-name icon-flex-right">
                                <div >
                                    <Row className="heading-format-in-panel">
                                        TITLE
                                    </Row>
                                    {lesson.name &&
                                        <Row>
                                            {lesson.name}
                                        </Row>
                                    }
                                </div>
                            </div>
                            <div className="panel-item panel-item-type icon-flex-right">
                                <Row className="heading-format-in-panel">
                                    TYPE
                                </Row>
                                {lesson.lessonType &&
                                    <Row>
                                        {lesson.lessonType}
                                    </Row>
                                }
                            </div>
                            <div className="panel-item panel-item-rationale icon-flex-right">
                                <Row className="heading-format-in-panel">
                                    RATIONALE
                                </Row>
                                <Row>
                                    <span dangerouslySetInnerHTML={{__html: annotationsHelper.annotate(markdownHelper.toHTML(lesson.rationale.text))}}></span>
                                </Row>
                                {/* <Row>
                                    {lesson.focusedStandards &&
                                        <span>
                                            {lesson.focusedStandards.map((focusedStandard,i)=> (
                                                <span style={{fontWeight: "700"}}>
                                                    {i===0?focusedStandard:(", ").concat(focusedStandard)}
                                                </span>
                                            ))}
                                        </span>
                                    }
                                    {lesson.focusedStandards && lesson.focusedStandards[0] && lesson.otherStandards && lesson.otherStandards[0] &&
                                        <span>, </span>
                                    }
                                    {lesson.otherStandards &&
                                        <span>
                                            {lesson.otherStandards.map((otherStandard,i)=> (
                                                <span>
                                                    {i===0?otherStandard:(", ").concat(otherStandard)}
                                                </span>
                                            ))}
                                        </span>
                                    }
                                </Row> */}
                            </div>
                        </Link>
                        <div className="panel-item panel-view-icon">
                            {window.innerWidth < 768 ?
                              <LessonPopOverMobile
                                  stopPropagation={true}
                                  preventDefault={true}
                                  scheduleTask={this.props.scheduleTask}
                                  lesson_name={lesson.name}
                                  lid={lesson._id}
                                  addToLibrary={this.props.addToLibrary}
                                  popOverId={"popOverM"+i}
                                  /> :
                              <LessonPopOver
                                  stopPropagation={true}
                                  preventDefault={true}
                                  scheduleTask={this.props.scheduleTask}
                                  lesson_name={lesson.name}
                                  lid={lesson._id}
                                  addToLibrary={this.props.addToLibrary}
                                  popOverId={"popOver"+i}
                                  />
                            }
                        </div>
                        <Link to={{pathname:'/lessons/'+lesson._id}} target="_blank" className="panel-item panel-view-icon xs-remove">
                            <div className="demo-icon-view  icon-icon-chevron-right"/>
                        </Link>
                    </div>
                ))}
            </div>
        );
    }
}

// const mapStateToProps = (state) => {
//     return {
//         myLibrary : state.MyLibrary

//     }
// }

export default connect(null, actions)(withRouter(OverviewContent))
