import {
    GET_FILTER_STANDARDS_REQUEST,
    GET_FILTER_STANDARDS_SUCCESS,
    GET_FILTER_STANDARDS_FAILURE
} from '../actions';


const initialState = {};

const filterStandardsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FILTER_STANDARDS_REQUEST:
            return Object.assign({},state, {asyncStatus:  'PENDING'});
        case GET_FILTER_STANDARDS_SUCCESS:
            return Object.assign({},state, {asyncStatus:  'SUCCESS',data:{...state.data, [action.request]: action.payload}});
        case GET_FILTER_STANDARDS_FAILURE:
            return Object.assign({},state, {asyncStatus:  'FAILURE', data: action.payload});
        default:
            return state;
    }
};

export default filterStandardsReducer;