import React,{Component} from  'react';
import CollapsibleComponent from '../../containers/Details/CollapsibleComponent';
import TextComponent from '../../containers/Details/TextContent';
import ImageDetailsComponent from '../../containers/Details/ImageDetailsComponent'
import {Row,Col} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {browserHistory} from 'react-router';
import InfiniteScroll from 'react-infinite-scroll-component'
import debounce from 'debounce'
import constants from '../../constants'
import * as actions from '../../redux/actions';
import authAPI from '../../api/authAPI';

class AddLessonToUnitComponent extends Component{
    constructor (props) {
        super(props);
        this.state = { searchTerm:'',unitIds:[],errorMessage:''};
        this.search = debounce(this.search,1000);
    }

    componentDidMount(){
        this.props.getCustomUnitsAction(this.props.params.id,"",1,0);
    }

    search=(val)=>{
        this.props.getCustomUnitsAction(this.props.params.id,val,1,0);
        this.setState({searchTerm : val});
    };

    changeFunction= (event)=>{
        let val = event.target.value;
        this.search(val);
    };

    AddUnits=(id)=>{
        let unitId = id;
        let unitIdsArray = this.state.unitIds;
        let unitIdIndex = unitIdsArray.indexOf(unitId);
        if(unitIdIndex >-1){
            unitIdsArray.splice(unitIdIndex,1);
            this.setState({
                unitIds:unitIdsArray
            });
        }
        else{
            unitIdsArray.push(unitId);
            this.setState({
                unitIds:unitIdsArray
            });
        }
        if(this.state.errorMessage){
            this.setState({errorMessage:''})
        }
    }

    saveUnits=()=>{
        let self= this;
        authAPI.addUnitsToLesson(this.props.params.id,this.state.unitIds)
        .then((response)=>{
            if(response.status === 200){
                this.props.lessonAddedSuccessAction(this.props.location.state && this.props.location.state.lessonName,true)
                setTimeout(()=> {
                    this.props.lessonAddedSuccessAction(this.props.location.state && this.props.location.state.lessonName,false)
                }, 3000) 
               browserHistory.goBack()
            }
        }).catch(function (error) {
            if(error.response.data.status===400){
                self.setState({errorMessage:'Please select at least one unit.'});
            }else{
                self.setState({errorMessage:'Unable to add lesson to units.'});
            }
        });
    }

    render(){
        let height=window.innerHeight;
        let width =window.innerWidth;
        let mobileWidth='576';
       return(
            <div>
                <div className='lesson-to-unit-header'>
                    <div className='lesson-to-unit-heading'>
                        ADD TO UNITS
                    </div>
                </div>
                {this.state.errorMessage?this.state.errorMessage:''}
                <div className='lesson-to-unit-content'>
                    <div className='searchbar-heading'>
                        CHOOSE UNIT
                    </div>
                    <div className='searchbar-unit'>
                        <div>
                            <div className="icon-icon-search lesson-icon-search"/>
                        </div>
                        <input type='text'  className='lesson-unit-searchbar' placeholder='Search Units' onChange={this.changeFunction}/>
                    </div>
                    <div className='lesson-units-number'>
                        <strong style={{color:'#666666'}}>{this.props.customUnits.data.total}</strong> Units
                    </div>
                    <div className="add-user-error" style={{fontSize:'13px'}}>
                        {this.state.errorMessage? this.state.errorMessage:''}
                    </div>
                    <InfiniteScroll  height={height-270+'px'}
                                next={()=>this.props.getCustomUnitsAction(this.props.params.id,this.state.searchTerm,this.props.customUnits.page +1)}
                                hasMore={this.props.customUnits.hasMore}
                                loader={<div>Loading...</div>}
                                 dataLength={this.props?.customUnits?.data?.results?.length ?? 0}
                        >
                        {this.props?.customUnits?.data?.results?.length && this.props.customUnits.data.results.map((unitItem,j)=>(
                            <Row>
                                <Row className={j ==0? "":"lesson-unit-border"}/>
                                <img src={constants.filesUrl+(unitItem.image?.replace("../",'')||'')} style={{width:'78px',height:'54px',borderRadius:'6px' }} className="image-size-in-text-content" />
                                <Col md={9} sm={8} xs={7} className="add-padding-to-each-item text-overflow-wrap">
                                    <Row key={unitItem+j} >
                                        {unitItem.name &&
                                            <Col className='content-heading'>
                                                {unitItem.name}
                                            </Col>
                                        }
                                    </Row>
                                    <Row key={j}>
                                        {unitItem.introduction && unitItem.introduction.text &&
                                            <Col className="author-text-format" style={{overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',width:'100px'}}>
                                                {unitItem.introduction.text}
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                                <Row style={{marginTop:'15px'}}>
                                    <span className={(this.state.unitIds).indexOf(unitItem._id)>-1?
                                        'lesson-search-delete-icon icon-icon-close-circle-solid':
                                        'lesson-search-add-icon icon-icon-add-circle-solid'} 
                                        style={{float:'right'}} 
                                        onClick={()=>this.AddUnits(unitItem._id)}
                                        />
                                </Row>
                            </Row>
                        ))}
                    </InfiniteScroll>
                </div>
                <div className='add-unit-footer'>
                    <div className='button-regular-left'>
                        <button className='cancel-lesson-unit' onClick={browserHistory.goBack} >
                            CANCEL
                        </button>
                    </div>
                    <div onClick={this.saveUnits} className='button-regular-right'>
                        <button className='assign-lesson-unit'>
                            ADD
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        customUnits : state.CustomUnits
    });
}

export default connect(mapStateToProps, actions)(withRouter(AddLessonToUnitComponent))
