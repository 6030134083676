import React, {Component} from 'react'
import {Row, Col, Modal} from 'react-bootstrap'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import authAPI from '../../api/authAPI'
import debounce from 'debounce'
import InfiniteScroll from 'react-infinite-scroll-component'
import {browserHistory} from 'react-router'

class EditGroup extends Component {

    constructor(props){
        super(props);
        this.state={searchTerm: "",addStudents: [],additionalStudents:[],modal:false,errMsg:"",userData:[],addedStudents: [],groupName: this.props.editGroupDetails.name,groupNameValid: "",deleteStudents: []};
        this.search = debounce(this.search,100);
    }

    addToGroupProxy = (groupToAddTo, sid)=>{
      //Takes a string of which proxy group to add sid to. Returns a state object with modified arrays.
      let groupToRemoveFrom = groupToAddTo === 'addStudents' ? 'deleteStudents' : 'addStudents';
      let returnState = {};
      if(this.state[groupToAddTo].indexOf(sid) === -1){
        this.state[groupToAddTo].push(sid);
        returnState[groupToAddTo] = this.state[groupToAddTo];

        let removeFromIndex = this.state[groupToRemoveFrom].indexOf(sid);
        if(removeFromIndex !== -1){
          this.state[groupToRemoveFrom].splice(removeFromIndex, 1);
          returnState[groupToRemoveFrom] = this.state[groupToRemoveFrom];
        }
      }
      return returnState;
    };

    addToGroup = (sid)=>{
      let newState = {};
      if(this.isStudentInOriginalGroup(sid) === true){
        //if user is already part of the group, just remove them from the Delete group.
        let sidInDeleteStudentsIndex = this.state.deleteStudents.indexOf(sid);
        if(sidInDeleteStudentsIndex === -1)
          return;
        this.state.deleteStudents.splice(sidInDeleteStudentsIndex, 1);
        newState.deleteStudents = this.state.deleteStudents;
      } else {
        newState = this.addToGroupProxy('addStudents', sid);
      }
      this.setState(newState);
    };

    deleteFromGroup = (sid)=>{
      let newState = {};
      if(this.isStudentInOriginalGroup(sid) === false){
        //if user is already not part of the group, just remove them from the Add group.
        let sidInAddStudentsIndex = this.state.addStudents.indexOf(sid);
        if(sidInAddStudentsIndex === -1)
          return;
        this.state.addStudents.splice(sidInAddStudentsIndex, 1);
        newState.addStudents = this.state.addStudents;
      } else {
        newState = this.addToGroupProxy('deleteStudents', sid);
      }
      this.setState(newState);
    };

    editGroup = ()=>{
        if(this.state.groupName === "") {
            this.setState({groupNameValid: "Group name cannot be blank."});
            return;
        }
        else if (this.setState.groupNameValid !== ""){
            this.setState({groupNameValid: ""});
        }

        if(this.state.groupName !== this.props.editGroupDetails.name) {
            this.props.putGroupAction(this.props.editGroupDetails._id,{"name": this.state.groupName})
                .then((response)=>{

                    if(response.statusCode === 200){
                        console.log("group name changed");
                        this.props.setHideEditGroup();

                    }else{
                        console.log("error occured");

                    }
                })
                .catch(function (error) {
                    console.log("exception occured");
                });
        }


        if(this.state.addStudents.length){
            let addObject={
                "user_ids": this.state.addStudents};
            this.props.addUserToGroupAction(this.props.editGroupDetails._id,addObject)
                .then((response)=>{

                    if(response.statusCode === 200){
                        console.log("added to group");
                        this.props.setHideEditGroup();

                    }else{
                        console.log("add error occured");

                    }
                })
                .catch(function (error) {
                    console.log("add exception occured");
                });
        }

        if(this.state.deleteStudents.length){
            let deleteObject={
                "user_ids": this.state.deleteStudents}

            console.log("delete Object",deleteObject);
            this.props.removeUserFromGroupAction(this.props.editGroupDetails._id,deleteObject)
                .then((response)=>{

                    if(response.statusCode === 200){
                        console.log("deleted from group");
                        this.props.setHideEditGroup();

                    }else{
                        console.log("delete error occured");

                    }
                })
                .catch(function (error) {
                    console.log("delete exception occured");
                });
        }


            this.props.setHideEditGroup();
        browserHistory.push('/my-class');
    };

    changeFunction= (event)=>{
        let val = event.target.value;
        this.setState({searchTerm: val});
        this.search(val);
    };

    setGroupName= (event)=>{
        this.setState({groupName: event.target.value});
    };

    search=(val)=>{
        this.props.getUsersAction("Student",val,0,1,0,0);
    };

    componentDidMount(){
        this.props.getUsersAction("Student","",0,1,0,0);
    }

    isStudentInOriginalGroup=(sid)=>{
      for(let user of this.props.editGroupDetails.users)
        if(user._id === sid)
          return true;
      return false;
    };

    isStudentInGroup=(sid)=> {
        if(this.state.addStudents.indexOf(sid) !== -1){
            return true;
        }
        for (let i = 0; i < this.props.editGroupDetails.users.length; i++) {
            if (sid === this.props.editGroupDetails.users[i]._id) {
                if(this.state.deleteStudents.indexOf(sid) === -1){
                    return true;
                }
            }
        }
        return false;
    };

    deleteStudent = (sid) =>{        
        this.studentDelete(sid);
        // let self = this;
        // confirmAlert({
        //   customUI: ({ onClose }) => {
        //     return (
        //       <div className='custom-ui'>
        //         <h1>Deleting student will permanetly delete record and that can not be reverted.</h1>
        //         <button
        //           onClick={() => {
        //             onClose();
        //             self.studentDelete(sid);                        
        //           }}
        //         >
        //           Yes, Delete it!
        //         </button>
        //         <button onClick={onClose}>Cancel</button>
        //       </div>
        //     );
        //   }
        // });              
    };

    studentDelete = (sid)=>{
        let self = this;
        authAPI.deleteStudentUser(sid).then((resp)=>{
            self.props.getUsersAction("Student","",0,1,0,0);
            self.setState({
                searchTerm:""
            }) 
        });
    };

    addAdditionalStudent = ()=>{
        this.setState({
            modal:true
        })
    };

    modalController = ()=>{
        this.setState({
            modal:false
        })
    };

    getInputValue = (event)=>{
        this.setState({
            studentEmail : event.target.value
        })
        console.log(this.state.studentEmail);
    };
    setAdditionalStudentData = () =>{
        let studentFirstName = document.getElementById('studentFirstName').value;
        let studentLastName = document.getElementById('studentLastName').value;
        let emailId = document.getElementById('studentEmail').value;
        let additionalStudents = this.state.additionalStudents;
        if(studentFirstName == '' || studentLastName == '' || emailId == ''){
            this.setState({
                errMsg : "All fields must be filled out"
            });
        }else if(emailId.includes("\,")){
            this.setState({
                errMsg : "Multiple entries not allowed"
            });
        }else if(additionalStudents.some(el => el.email_id === emailId)){
            this.setState({
                errMsg : emailId+" email Id already used for another user"
            });
        }else{
            this.setState({
                errMsg : ""
            });
            document.getElementById('studentFirstName').value = "";
            document.getElementById('studentLastName').value = "";
            document.getElementById('studentEmail').value = "";

            
            if(studentFirstName != '' && studentLastName != '' && emailId != ''){
                additionalStudents.push({
                    first_name : studentFirstName,
                    last_name : studentLastName,
                    email_id: emailId
                });
                this.setState({
                    additionalStudents : additionalStudents
                });
            }    
        }

        console.log(this.state.errMsg);
        
    };

    removeStudentEmail = (e,emailId) =>{
        let modifiedStudentArr = [];
        let self = this;
        let additionalStudents = this.state.additionalStudents;
        additionalStudents.forEach((stuData,index)=>{
            if(stuData.email_id !== emailId){
                modifiedStudentArr.push({
                    first_name : stuData.first_name,
                    last_name : stuData.last_name,
                    email_id: stuData.email_id
                })
            }

            if(index == additionalStudents.length -1){
                self.setState({
                    additionalStudents : modifiedStudentArr
                })
            }

        })
    };

    setAdditionalStudent = () =>{
        if(this.state.additionalStudents.length > 0){
            //save the students for this user for this district id
            authAPI.postImportStudents(this.state.additionalStudents).then((resp)=>{
                if(resp.status == 200){
                    this.setState({
                        modal:false,
                        success:true,
                        existingStudents :resp.data.existingEmailIds
                    });
                    let existingStudentsArr = this.state.existingStudents.split(',');
                    let notExistingStudents = [];
                    if(existingStudentsArr.length > 0){
                        //exclude these email id from additional students
                        let addlStu = this.state.additionalStudents;
                        addlStu.forEach((el)=>{
                            if(!existingStudentsArr.includes(el.email_id)){
                                notExistingStudents.push({
                                    first_name:el.first_name,
                                    last_name:el.last_name,
                                    email_id:el.email_id
                                });
                            }
                        });
                    }                    

                    this.setState({
                        addedStudents: notExistingStudents
                    });
                    setTimeout(()=>{this.setState({
                        success:false,
                        additionalStudents:[],
                        addedStudents:[]
                    })},5000)
                }
            })
        }
    };

    render() {
        let height=window.innerHeight;
        let width =window.innerWidth; 
        let mobileWidth='576';
        return (
            <div>
                <Col md={12} sm={12} className="add-group-header">
                    <div className="add-group">
                        EDIT GROUP
                    </div>
                </Col>
                <div>
                    <div>
                        <Col md={3} sm={3}/>
                        <Col  md={6} sm={6} className="inner-add-group-content">
                            <div style={{display: "inine-block",marginBottom: 10}}>
                                GROUP NAME <span style={{color:"orange"}}>*</span>
                            </div>
                            <div>
                                <input type="text" onChange={this.setGroupName} value={this.state.groupName} className={this.state.groupNameValid === ""?"group-name":"add-user-form add-user-form-error"} />
                            </div>
                            {this.state.groupNameValid !== "" &&
                                <div className="add-group-error">{this.state.groupNameValid}</div>
                            }
                            {/*DESCRIPTION:<div ><textarea type="text" style={{width: "100%",marginBottom: 20}}></textarea></div>*/}
                            <div style={{display: "inine-block",marginBottom: 10}}> CHOOSE STUDENT </div>
                            <div>
                                <span className="search-student icon-icon-search"/>
                                <input type="text"  placeholder="Search for Student" onChange={this.changeFunction} value={this.state.searchTerm} className="search-by-student" />
                            </div>
                            <Row>
                                <Col xs={1} md={2} sm={1}/>
                                <Col md={8} sm={10}>
                                    <div style={{textAlign: "center"}}>
                                        <button className="button-students">
                                            STUDENTS
                                        </button>
                                        {(localStorage.user_type == 'Individual Teacher' || localStorage.user_type == 'Unlimited' || localStorage.user_type == 'Print Only' || localStorage.user_type === 'System Admin') && 
                                    <button className="button-additional" onClick={this.addAdditionalStudent}>
                                        ADD ADDITIONAL STUDENTS
                                    </button>}
                                    {/*<button className="button-groups">GROUPS</button>*/}
                                    </div>
                                </Col>
                                <Col xs={1} md={2} sm={1}/>
                            </Row>

                            <InfiniteScroll  height={height-385+'px'}
                                            next={()=>this.props.getUsersAction("Student",this.state.searchTerm,0,this.props.users.page +1,0)}
                                            hasMore={this.props.users.hasMore["Student"]}
                                            loader={<Row>Loading...</Row>}
                                            dataLength={this.props?.users?.data?.Student?.results?.length ?? 0}

                                >
                                {this.props?.users?.data?.Student?.results?.length && this.props.users.data.Student.results.map((student,i)=>
                                    <Row key={student+i}>
                                        <span ><i className="icon-icon-user my-class-add-group-user-icon" /></span>
                                        {i!=0 && <div className="my-class-add-group-border"/>}
                                        <Col md={8} sm={9} xs={8}>
                                            <Row  className="student-name">{student.first_name && <span>{student.first_name}</span>}
                                                {student.last_name && <span> {student.last_name}</span>}
                                            </Row>
                                            <Row style={{marginTop: 3}} className="student-sub-text">
                                                {student.username && student.username !== "" && student.username !== " " && 
                                                    <span>USERNAME: {student.username} </span>
                                                }
                                                <br className="visible-xs visible-sm visible-md"/>
                                                {student.user_id_number && student.user_id_number !== "" && student.user_id_number !== " " && 
                                                    <span className="hidden-xs hidden-sm hidden-md">&nbsp;&#8226;&nbsp;</span>
                                                }
                                                {student.user_id_number && student.user_id_number !== "" && student.user_id_number !== " " &&
                                                    <span> ID: {student.user_id_number}</span>
                                                }
                                            </Row>
                                        </Col>
                                        {this.isStudentInGroup(student._id) ?
                                            <span>
                                                <i onClick={()=>{this.deleteFromGroup(student._id)}} className="remove-student-solid-icon icon-icon-close-circle-solid" title="Unassign Student"/>
                                            </span>:
                                            <span>
                                                <i onClick={()=>{this.addToGroup(student._id)}}
                                                    className="add-student-solid-icon icon-icon-add-circle-solid"/>
                                            </span>
                                        }
                                        {student.created_by == localStorage.getItem('user_id') &&
                                            <span>
                                                <i onClick={() => { if (window.confirm('Are you sure you wish to delete this student?')) this.deleteStudent(student._id) } } title="Delete Student" className="remove-student-solid-icon icon-icon-close-circle-solid"/>
                                            </span>
                                        }
                                    </Row>
                                )}
                            </InfiniteScroll>
                        </Col>
                        <Col md={3} sm={3}/>
                        <Modal id="start-tour-modal" bsSize="lg" show={this.state.modal} style={{'position':'relative','top':'23rem'}}>
                       <Modal.Body>
                            {/*<Row className='custom-modal' style={{'position':'relative','bottom':'4rem','left':'0'}}>                                   
                                <button type="button" className="close" aria-label="Close" onClick={this.modalController}><span aria-hidden="true">&times;</span></button>
                            </Row>*/}
                            <Row style={{marginTop: 3}}>
                                <i className="my-class-add-user icon-blue icon-icon-add-user-circle-outline" style={{"float":"left","marginTop":"-10px","fontSize":"55px"}}></i>
                                <span style={{"color":"#000","fontFamily":"ProximaNova","fontSize":"14px"}}>Add Students</span><br/><span style={{"color":"#000","fontFamily":"ProximaNova","fontSize":"12px"}}>For the bulk addition of students, please speak with your Fundamentals School Administrator or contact Schoolwide’s Customer Success team at <a href="mailto:customersuccess@schoolwide.com">customersuccess@schoolwide.com.</a></span>
                                {this.state.errMsg !="" &&
                                    <div id="errMsg" style={{"color":"red","fontFamily":"ProximaNova","fontSize":"22px","marginLeft":"38px"}}>{this.state.errMsg}</div>
                                }
                                <div style={{"clear":"both"}}>
                                    <input type="text" id="studentFirstName" style={{"border":"none","background":"#ddd",padding:"5px","borderBottom":"7px solid #279bbd","width":"40%","marginTop": "25px","marginLeft": "35px"}} placeholder="First Name"/>
                                    <input type="text" id="studentLastName" style={{"border":"none","background":"#ddd",padding:"5px","borderBottom":"7px solid #279bbd","width":"45%","marginTop": "25px","marginLeft": "35px"}} placeholder="Last Name"/>
                                </div>
                                <div>
                                    <input type="text" id="studentEmail" style={{"border":"none","background":"#ddd",padding:"5px","borderBottom":"7px solid #279bbd","width":"90%","marginTop": "25px","marginLeft": "35px"}} placeholder="Email Address"/>
                                    <i className="add-student-solid-icon icon-icon-add-circle-solid" onClick={this.setAdditionalStudentData} style={{marginTop:"28px"}}></i>
                                </div>
                                <div style={{height:"300px",marginTop:"11px",marginLeft:"35px",marginRight:"50px","background":"#ddd","overflowY":"scroll"}}>
                                    <ul className="additionalStudentList" style={{"listStyleType":"none"}}>
                                        {this.state.additionalStudents && this.state.additionalStudents.map((user)=>
                                            <li style={{padding: "4px"}}>
                                                <span style={{color: "#6c6c6c",fontSize:"17px",marginLeft: "-36px"}}>{user.first_name}&nbsp;{user.last_name}</span>
                                                <span style={{color: "#6c6c6c",fontSize:"17px","marginLeft":"220px"}}>{user.email_id}</span>
                                                <span style={{borderRadius:"2px solid red",background:"red",color:"#fff",float:"right",cursor:"pointer",borderRadius:"50%",paddingLeft:"8px",paddingTop:"3px",height:"25px",width:"25px"}} onClick={(e)=>this.removeStudentEmail(e,user.email_id)}>X</span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                {/*<textarea id="studentEmail" style={{"border":"none","background":"#ddd",padding:"5px","borderBottom":"7px solid #279bbd","width":"90%","marginTop": "25px","marginLeft": "35px","height": "350px","resize":"none"}} placeholder="Add students to add to class"></textarea>*/}
                                <button onClick={this.modalController} className="cancel-add-group">
                                    CANCEL
                                </button>
                                <button className="add-add-group" onClick={this.setAdditionalStudent}>
                                    ADD
                                </button>
                            </Row>
                       </Modal.Body>
                    </Modal>
                    </div>
                </div>
                <div className="add-group-footer">
                    <button onClick={()=>{browserHistory.push('/my-class');this.props.setHideEditGroup()}} className="cancel-add-group">
                        CANCEL
                    </button>
                    <button onClick={()=>{this.editGroup()}} className="add-add-group">
                        SAVE
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return ( {users : state.Users,
        groups: state.Groups,
        getGroupById: state.GetGroupById
    });
};

export default connect(mapStateToProps, actions)(EditGroup)