import {
    GET_BOOK_ELEARNING_SUCCESS, GET_BOOK_ELEARNING_FAILURE, GET_BOOK_ELEARNING_REQUEST,
    EREADER_SET_TOTAL_PAGES_EVENT, EREADER_SET_CURRENT_PAGE_EVENT, EREADER_SET_PAGE_MODE_EVENT
} from "../actions/index";
import * as _ from "lodash"
import {
    SHOW_WHAT_YOU_KNOW_SUBMIT_RESPONSE, SHOW_WHAT_YOU_KNOW_SUBMIT_REVIEW,
    SHOW_WHAT_YOU_KNOW_SWITCH_TABS
} from "../actions/ShowWhatYouKnowActions";

//
// Book Details
//

const eLearningBookDetailsInitialState = {
    id: undefined,
    total_pages: 0,
    first_displayed_page: undefined,
    last_displayed_page: undefined,
    mode: 'BookView'
};

const eLearningBookDetailsReducer = (state = eLearningBookDetailsInitialState, action)=> {
    switch(action.type) {
        case GET_BOOK_ELEARNING_REQUEST:
            return eLearningBookDetailsInitialState;
        case EREADER_SET_CURRENT_PAGE_EVENT:
            const newCurrentPage = action.currentPage;
            switch(state.mode) {
                case 'BookView':
                    return (action.currentPage === 1)?
                        Object.assign({}, state, {first_displayed_page: 1, last_displayed_page: 1}):
                        Object.assign({}, state, {
                            first_displayed_page: action.currentPage - 1,
                            // Display the last page
                            last_displayed_page: action.currentPage > state.total_pages? action.currentPage - 1: action.currentPage
                        });
                case 'SinglePage':
                    return Object.assign({}, state,
                        {first_displayed_page: newCurrentPage, last_displayed_page: newCurrentPage});
                default:
                    return state;
            }
        case EREADER_SET_TOTAL_PAGES_EVENT:
            return Object.assign({}, state, {
                total_pages: action.totalPages
            });
        case EREADER_SET_PAGE_MODE_EVENT:
            return Object.assign({}, state, {
                mode: action.pageMode
            });
        default:
            return state;
    }
};

//
// ELearning
//

const eLearningELearningInitialState = {
    teaching_points: {
        displayed: {
            pages: [],
            data: {
                // Mapping of page -> array of teaching points
            }
        },
        data: {
            // Mapping of page_number ->
            // {
            //    teaching_point: (rendered html),
            //    page_number: (page num),
            //    skill_ids: [],
            //    audio_file
            // }
        }
    }
};

const eLearningELearningReducer = (state = eLearningELearningInitialState, displayedPages, action)=> {
    switch(action.type) {
        case GET_BOOK_ELEARNING_REQUEST:
            return eLearningELearningInitialState;
        case EREADER_SET_CURRENT_PAGE_EVENT:
            // Displayed data, create an object with the
            let updatedPageData = {};
            _.each(displayedPages, (displayedPage) => {
                updatedPageData[displayedPage] = state.teaching_points.data[displayedPage];
            });

            return Object.assign({}, state, {
                teaching_points: {
                    displayed: {
                        pages: displayedPages,
                        data: updatedPageData
                    },
                    data: state.teaching_points.data
                }
            });

        case GET_BOOK_ELEARNING_SUCCESS:

            // Filter teaching points
            const teaching_points = _.chain(action.payload.books_elearnings)

                // Filter teaching points
                .filter((elearning) => elearning.item.teaching_point !== undefined)

                // Map to state format
                .map((teaching_point) => {
                    const item = teaching_point.item;
                    return {
                        teaching_point: item.teaching_point.text,
                        id: item.teaching_point._id,
                        skills_ids: item.skill_ids,
                        audio_file: item.audio_file,
                        page_number: teaching_point.page_number
                    }
                })
                .value();

            // Group teaching points by page
            const teachingPointPageData = _.groupBy(teaching_points, (teaching_point) => teaching_point.page_number);

            // Displayed data, create an object with the
            let displayedData = {};
            _.each(displayedPages, (displayedPage) => {
                displayedData[displayedPage] = teachingPointPageData[displayedPage]
            });

            return Object.assign({}, state, {
                teaching_points: {
                    displayed: {
                       pages: displayedPages,
                       data: displayedData
                    },
                    data: teachingPointPageData
                }
            });
        default:
            return state;
    }
};

//
// Show what you know
//


// Multiple choice
const eLearningShowWhatYouKnowMultipleChoiceInitialState =  {

    // User cannot change answers if submitted
    submitted: false,

    // The current question the user is on
    current_question: 0,
    questions: [
        // Array of
        // {
        //     _id,
        //     skill_id,
        //     question_order,
        //     question_text
        //     book_answers: [{
        //           _id,
        //          answer_text,
        //          is_correct
        //          user_answer: (boolean, if user has answered)
        //     }]
        // }
    ]
};
const eLearningShowWhatYouKnowMultipleChoiceReducer  = (state = eLearningShowWhatYouKnowMultipleChoiceInitialState, action)=> {
    switch(action.type) {
        case GET_BOOK_ELEARNING_REQUEST:
            return eLearningShowWhatYouKnowMultipleChoiceInitialState;
        case 'SUBMITTED_ELEARNING_ANSWERS':
            return Object.assign({}, state, {
                submitted: true
            });
        case GET_BOOK_ELEARNING_SUCCESS:
            let elearningData = action.payload;
            let submitted = false;

            // Check for a user answer
            // TODO: track user submission on backend
            _.each(elearningData.books_questions, (bookQuestion) => {
                _.each(bookQuestion.books_answers, (bookAnswer) => {
                    if(bookAnswer.user_answer === true) {
                        submitted = true;
                    }
                });
            });

            return Object.assign({}, state, {
                submitted: submitted,
                questions: elearningData.books_questions
            });
        default:
            return state;
    }
};

// Reader Response
const eLearningShowWhatYouKnowReaderResponseInitialState = [
    // Array of
    // {
    //    question_order:
    //    question_text
    //    user_response
    // }
];
const eLearningShowWhatYouKnowReaderResponseReducer = (state = eLearningShowWhatYouKnowReaderResponseInitialState, action) => {
    switch(action.type) {
        case GET_BOOK_ELEARNING_REQUEST:
            return eLearningShowWhatYouKnowReaderResponseInitialState;
        case SHOW_WHAT_YOU_KNOW_SUBMIT_RESPONSE:
            let newResponses = state.splice(0);
            for(let i = 0;i<newResponses.length;i++) {
                if(newResponses[i]._id === action.payload.id) {
                    newResponses[i].user_response = action.payload.response;
                    break;
                }
            }
           return newResponses;
        case GET_BOOK_ELEARNING_SUCCESS:
            return action.payload.books_response_questions;
        default:
            return state;
    }
};

const eLearningShowWhatYouKnowReaderReviewInitialState = {
    response: ''
};
const eLearningShowWhatYouKnowReaderReviewReducer = (state = eLearningShowWhatYouKnowReaderReviewInitialState, action) => {
    switch(action.type) {
        case SHOW_WHAT_YOU_KNOW_SUBMIT_REVIEW:
            return Object.assign({}, state, {
                response: action.payload
            });
        case GET_BOOK_ELEARNING_REQUEST:
            return eLearningShowWhatYouKnowReaderReviewInitialState;
        case GET_BOOK_ELEARNING_SUCCESS:
            // Only render review text if books_user_review is defined
            return action.payload.books_user_reviews?
                Object.assign({}, state, {
                    response: action.payload.books_user_reviews
                }) : state;
        default:
            return state;
    }
};

const eLearningShowWhatYouKnowInitialState = {
    reader_response: eLearningShowWhatYouKnowReaderResponseInitialState,
    reader_review: eLearningShowWhatYouKnowReaderReviewInitialState,
    multiple_choice: eLearningShowWhatYouKnowMultipleChoiceInitialState
};
const eLearningEShowWhatYouKnowReducer = (state = eLearningShowWhatYouKnowInitialState, action)=> {
    switch(action.type) {
        case GET_BOOK_ELEARNING_REQUEST:
            return eLearningShowWhatYouKnowInitialState;
        case GET_BOOK_ELEARNING_SUCCESS:
            return Object.assign({}, state, {
                multiple_choice: eLearningShowWhatYouKnowMultipleChoiceReducer(state.multiple_choice, action),
                reader_response: eLearningShowWhatYouKnowReaderResponseReducer(state.reader_response, action),
                reader_review: eLearningShowWhatYouKnowReaderReviewReducer(state.reader_review, action)
            });
        case SHOW_WHAT_YOU_KNOW_SWITCH_TABS:
            return Object.assign({}, state, {
                displayedTab: action.payload
            });
        case SHOW_WHAT_YOU_KNOW_SUBMIT_REVIEW:
            return Object.assign({}, state, {
                reader_review: eLearningShowWhatYouKnowReaderReviewReducer(state.reader_review, action)
            });
        case SHOW_WHAT_YOU_KNOW_SUBMIT_RESPONSE:
            return Object.assign({}, state, {
                reader_response: eLearningShowWhatYouKnowReaderResponseReducer(state.reader_response, action)
            });
        default:
            return state;
    }
};

//
// Audio
//
const audioInitialState = {
    // Current audio files
    current: [],

    library: {
        // Page number to audio file mapping
    }
};

const audioReducer = (state = audioInitialState, displayedPages, action) => {
    switch(action.type) {
        case EREADER_SET_CURRENT_PAGE_EVENT:
           return {
               current:  _.compact(_.map(displayedPages, (displayedPage) => {
                   let displayedPageFile = (displayedPage < 10? "0" + displayedPage : displayedPage) + ".mp3";
                   return _.find(state.library, (audioLocation) => {
                       return audioLocation.endsWith(displayedPageFile);
                   });
               })),
               library: state.library
           };

        case GET_BOOK_ELEARNING_SUCCESS:
            return {
                current: _.compact([_.find(action.payload.audio_files, (audioLocation) => {
                        return audioLocation.endsWith("01.mp3");
                    })])
                ,
                library: action.payload.audio_files
            };
        default:
            return state;
    }
};

//
// State
//
const initialState = {
    is_book_loaded: false,
    book_details: eLearningBookDetailsInitialState,
    e_learning: eLearningELearningInitialState,
    show_what_you_know: eLearningShowWhatYouKnowInitialState,
    audio: audioInitialState
};

// Returns an array of selected pages
const pageRange = (first, last) => {
    const first_page = first || 0;
    const last_page = last || 0;
    let pageArray = [];
    for(let i = first_page;i<=last_page;i++) {
        pageArray.push(i);
    }
    return pageArray;
};

const ELearningReducer = (state = initialState, action) => {
     switch (action.type) {
        case GET_BOOK_ELEARNING_REQUEST:
            return Object.assign({},state, {
                    is_book_loaded: false,
                    e_learning: eLearningELearningReducer(state.e_learning,[], action),
                    show_what_you_know: eLearningEShowWhatYouKnowReducer(state.show_what_you_know, action)
                }
            );
        case EREADER_SET_CURRENT_PAGE_EVENT:
            state = Object.assign({}, state, {
                book_details: eLearningBookDetailsReducer(state.book_details, action)
            });
            const displayedPages = pageRange(state.book_details.first_displayed_page, state.book_details.last_displayed_page);
            return Object.assign({},state, {
                    e_learning: eLearningELearningReducer(state.e_learning, displayedPages, action),
                    audio: audioReducer(state.audio, displayedPages, action),
                }
            );

        case GET_BOOK_ELEARNING_SUCCESS:
            return Object.assign({},state, {
                    is_book_loaded: true,
                    book_details: eLearningBookDetailsReducer(state.book_details, action),
                    e_learning: eLearningELearningReducer(state.e_learning, [1],  action),
                    show_what_you_know: eLearningEShowWhatYouKnowReducer(state.show_what_you_know, action),
                    audio: audioReducer(state.audio, [1], action),
                }
            );
        case GET_BOOK_ELEARNING_FAILURE:
            return Object.assign({},state, {asyncStatus:  'FAILURE', data: action.payload});
        default:
            return Object.assign({},state, {
                    book_details: eLearningBookDetailsReducer(state.book_details, action),
                    show_what_you_know: eLearningEShowWhatYouKnowReducer(state.show_what_you_know, action),
                }
            );
    }
};



export default ELearningReducer;
