import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import * as actions from "../redux/actions";
import authAPI from "../api/authAPI";
import constants from "../constants";
import BrowsePageContent from "./shared/BrowsePageContent";
import _ from "lodash";
import ResponsiveResourcesNew from "./TeachingResources/ResponsiveResourcesNew";
import ResponsiveNavBtns from "./TeachingResources/ResponsiveNavBtns";
import "../assets/styles/mobile.scss";
import ResourceHeading from "../components/ResourceHeading";
import AddToFolderModal from "../components/AddToFolder/AddToFolderModal";
import { SnackBarModule } from "../components/Shared";

const invertMap = [
  "Featured",
  "Reading",
  "Writing",
  "Speaking & Listening",
  "Language",
];

class LibraryHomeContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: constants.caseMap[this.props?.params?.strand],
      isFeatured: false,
      DataSource: this.props,
      loadingMore: false,
      pageNumber: 1,
      lessonsDetail: {},
      resourceDetails: {},
      correlationDetails: {},
      singleUnitDetails: {},
      bookDetails: {},
      assessmentDetails: {},
      hasMore: true,
      total: 0,
      filter: false,
      queryValue: "",
      multiSelectMode: false,
      areAllSelected: false,
      selectedItems: [],
      multipleItems: {
        Resource: [],
        Lesson: [],
        Correlations: [],
      },
      addedMultipleItemsToLibrary: false,
      addedMultipleItemsToFolder: false,
      isJoyrideRunning: false,
      isBackButtonPressed: false,
      selectedResourceId: "",
      selectedDetailsType: "",
      selectedResourceName: "",
      removeFromLibrarySuccess: false,
      removeFromLibraryError: false,
      addToFolderError: false,
      addToFolderErrorMsg:""
    };
    window.sessionStorage.setItem("selectedFilters", JSON.stringify([]));
  }

  millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  componentWillReceiveProps(next) {
    window.onpopstate = (e) => {
      this.setState({
        isBackButtonPressed: true,
      });
    };

    if (
      next?.resourceDetails?.asyncStatus &&
      this.state.selectedDetailsType === "Resource"
    ) {
      if (next?.resourceDetails?.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next?.resourceDetails?.asyncStatus === "FAILURE") {
        this.setState({ status: "FAILURE" });
      } else if (next?.resourceDetails?.data.status === "RESOURCE NOT FOUND") {
        this.setState({ status: "RESOURCE NOT FOUND" });
      } else if (next?.resourceDetails.asyncStatus === "SUCCESS") {
        this.setState({
          correlationDetails: {},
          lessonsDetail: {},
          singleUnitDetails: {},
          bookDetails: {},
          assessmentDetails: {},
          resourceDetails: next?.resourceDetails.data,
          selectedResourceId: next.resourceDetails.data._id,
          status: "SUCCESS",
        });
      }
    } else if (
      next?.lessonsDetail?.asyncStatus &&
      this.state.selectedDetailsType === "Lesson"
    ) {
      if (next.lessonsDetail.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next?.lessonsDetail?.asyncStatus === "FAILURE") {
        this.setState({ status: "FAILURE" });
      } else if (
        next?.lessonsDetail?.asyncStatus === "SUCCESS" &&
        next.lessonsDetail?.data?.status === "FAILURE FOR PRINT ONLY USER"
      ) {
        this.setState({ status: "FAILURE FOR PRINT ONLY USER" });
      } else if (
        next?.lessonsDetail?.asyncStatus === "SUCCESS" &&
        next.lessonsDetail?.data?.status === "FAILURE FOR NOT APPROVED"
      ) {
        this.setState({ status: "FAILURE FOR NOT APPROVED" });
      } else if (next?.lessonsDetail?.asyncStatus === "SUCCESS") {
        this.setState({
          correlationDetails: {},
          resourceDetails: {},
          lessonsDetail: next?.lessonsDetail.data,
          singleUnitDetails: {},
          bookDetails: {},
          assessmentDetails: {},
          status: "SUCCESS",
          selectedResourceId: next.lessonsDetail.data?._id,
        });
      }
    } else if (next?.correlationDetails?.asyncStatus) {
      if (next.correlationDetails.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next.correlationDetails.asyncStatus === "FAILURE") {
        this.setState({ correlationDetails: "FAILURE" });
      } else if (
        next.correlationDetails.asyncStatus === "SUCCESS" &&
        next.correlationDetails.data.status === "FAILURE FOR PRINT ONLY USER"
      ) {
        this.setState({ status: "FAILURE FOR PRINT ONLY USER" });
      } else if (
        next.correlationDetails.asyncStatus === "SUCCESS" &&
        next.correlationDetails.data.status === "FAILURE FOR NOT APPROVED"
      ) {
        this.setState({ status: "FAILURE FOR NOT APPROVED" });
      } else if (next.correlationDetails.asyncStatus === "SUCCESS") {
        let data = {
          id: next.correlationDetails.data._id,
          name: next.correlationDetails.data.name,
          publish: next.correlationDetails.data.publish,
          texts: next.correlationDetails.data.mentorTexts,
          grades: next.correlationDetails.data.grades.join(", "),
          forms: next.correlationDetails.data.files.map((file) => ({
            file,
            image: file + ".thumb.jpg",
          })),
          description: "",
          image: "",
          printable: next.correlationDetails.data.printable,
        };

        if (next.correlationDetails.data.description.description) {
          data.description =
            next.correlationDetails.data.description.description;
        } else if (next.correlationDetails.data.description.text) {
          data.description = next.correlationDetails.data.description.text;
        }

        if (next.correlationDetails.data.image) {
          data.image = next.correlationDetails.data.image;
        }

        this.setState({
          correlationDetails: data,
          status: "SUCCESS",
          selectedResourceId: next.correlationDetails.data._id,
        });
      }
    } else if (
      next?.assessmentDetails?.asyncStatus &&
      this.state.selectedDetailsType === "Assessment"
    ) {
      if (next.assessmentDetails.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next?.assessmentDetails?.asyncStatus === "FAILURE") {
        this.setState({ status: "FAILURE" });
      } else if (
        next?.assessmentDetails?.asyncStatus === "SUCCESS" &&
        next.assessmentDetails?.data?.status === "FAILURE FOR PRINT ONLY USER"
      ) {
        this.setState({ status: "FAILURE FOR PRINT ONLY USER" });
      } else if (
        next?.assessmentDetails?.asyncStatus === "SUCCESS" &&
        next.assessmentDetails?.data?.status === "FAILURE FOR NOT APPROVED"
      ) {
        this.setState({ status: "FAILURE FOR NOT APPROVED" });
      } else if (next?.assessmentDetails?.asyncStatus === "SUCCESS") {
        this.setState({
          correlationDetails: {},
          resourceDetails: {},
          lessonsDetail: {},
          singleUnitDetails: {},
          bookDetails: {},
          assessmentDetails: next?.assessmentDetails.data,
          status: "SUCCESS",
          selectedResourceId: next.assessmentDetails.data?._id,
        });
      }
    } else if (
      next?.bookDetails?.asyncStatus &&
      this.state.selectedDetailsType === "Book"
    ) {
      if (next.bookDetails.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next?.bookDetails?.asyncStatus === "FAILURE") {
        this.setState({ status: "FAILURE" });
      } else if (
        next?.bookDetails?.asyncStatus === "SUCCESS" &&
        next.bookDetails?.data?.status === "FAILURE FOR PRINT ONLY USER"
      ) {
        this.setState({ status: "FAILURE FOR PRINT ONLY USER" });
      } else if (
        next?.bookDetails?.asyncStatus === "SUCCESS" &&
        next.bookDetails?.data?.status === "FAILURE FOR NOT APPROVED"
      ) {
        this.setState({ status: "FAILURE FOR NOT APPROVED" });
      } else if (next?.bookDetails?.asyncStatus === "SUCCESS") {
        this.setState({
          correlationDetails: {},
          resourceDetails: {},
          lessonsDetail: {},
          singleUnitDetails: {},
          bookDetails: next?.bookDetails.data,
          assessmentDetails: {},
          status: "SUCCESS",
          selectedResourceId: next.bookDetails.data._id,
        });
      }
    } else if (
      next?.singleUnitDetails?.asyncStatus &&
      this.state.selectedDetailsType === "Unit"
    ) {
      if (next.singleUnitDetails.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next?.singleUnitDetails?.asyncStatus === "FAILURE") {
        this.setState({ status: "FAILURE" });
      } else if (
        next?.singleUnitDetails?.asyncStatus === "SUCCESS" &&
        next.singleUnitDetails?.data?.status === "FAILURE FOR PRINT ONLY USER"
      ) {
        this.setState({ status: "FAILURE FOR PRINT ONLY USER" });
      } else if (
        next?.singleUnitDetails?.asyncStatus === "SUCCESS" &&
        next.singleUnitDetails?.data?.status === "FAILURE FOR NOT APPROVED"
      ) {
        this.setState({ status: "FAILURE FOR NOT APPROVED" });
      } else if (next?.singleUnitDetails?.asyncStatus === "SUCCESS") {
        this.setState({
          correlationDetails: {},
          resourceDetails: {},
          lessonsDetail: {},
          singleUnitDetails: next?.singleUnitDetails.data,
          bookDetails: {},
          assessmentDetails: {},
          status: "SUCCESS",
          selectedResourceId: next.singleUnitDetails.data?.unit_id,
        });
      }
    }
  }

  _loadMore() {
    setTimeout(() => {
      if (this.state.total > this.state?.data?.length) {
        let strandInfinite = constants.caseMap[this.props?.params?.strand],
          filtersSet =
            this.props.finalFilters &&
            this.props.finalFilters.filterList &&
            this.props.finalFilters.filterList.length > 0,
          eitherFiltersSetOrQueryEmpty =
            (filtersSet && this.state.queryValue !== "") ||
            (!filtersSet && this.state.queryValue === "");

        if (
          this.props?.params?.strand !== "featured" &&
          eitherFiltersSetOrQueryEmpty
        ) {
          authAPI
            .getResources(
              this.state.pageNumber + 1,
              strandInfinite,
              this.state.queryValue
            )
            .then((resp) => {
              if (resp.total && resp?.results) {
                this.setState({
                  total: resp.total,
                  data: _.concat(this.state.data, resp.results),
                  pageNumber: Number(resp.currentPage),
                  loadingMore: false,
                  filter: false,
                });
              }
            });
        }
      } else {
        this.setState({
          loadingMore: false,
          hasMore: false,
        });
      }
    }, 1000);
  }

  addMultipleItems = (tid, type = {}) => {
    if (type === "Resource") {
      this.state.multipleItems["Resource"].push(tid);
    } else if (type === "Correlations") {
      this.state.multipleItems["Correlations"].push(tid);
    } else {
      this.state.multipleItems["Lesson"].push(tid);
    }
    this.setState({ multipleItems: this.state.multipleItems });
  };

  deleteItem = (tid, type = "") => {
    if (type === "Resource") {
      let idx = this.state.multipleItems["Resource"].indexOf(tid);
      this.state.multipleItems["Resource"].splice(idx, 1);
    } else if (type === "Correlations") {
      let idx = this.state.multipleItems["Correlations"].indexOf(tid);
      this.state.multipleItems["Correlations"].splice(idx, 1);
    } else {
      let idx = this.state.multipleItems["Lesson"].indexOf(tid);
      this.state.multipleItems["Lesson"].splice(idx, 1);
    }

    this.setState({ multipleItems: this.state.multipleItems });
  };

  addMultipleItemsToLibrary = () => {
    for (let i = 0; i < this.state.multipleItems["Resource"].length; i++) {
      this.props
        .postAddTeachingResourceToLibraryAction(
          this.state.multipleItems["Resource"][i]
        )
        .then((response) => {
          if (response.statusCode !== 200) {
            throw new Error();
          }
        })
        .catch((error) => {
          console.log("Item not added to library");
        });
    }
    for (let i = 0; i < this.state.multipleItems["Lesson"].length; i++) {
      this.props
        .postAddLessonToLibraryAction(this.state.multipleItems["Lesson"][i])

        .then((response) => {
          if (response.statusCode !== 200) {
            throw new Error();
          }
        })
        .catch((error) => {
          console.log("Item not added to library");
        });
    }
    for (let i = 0; i < this.state.multipleItems["Correlations"].length; i++) {
      this.props
        .postAddCorrelationToLibraryAction(
          this.state.multipleItems["Correlations"][i]
        )

        .then((response) => {
          if (response.statusCode !== 200) {
            throw new Error();
          }
        })
        .catch((error) => {
          console.log("Exception! Item not added to library");
        });
    }
  };

  addMultipleItemsToFolder = () => {
    let items = [];
    let itemResources = [];
    for (let i = 0; i < this.state.multipleItems["Resource"].length; i++) {
      items.push(this.state.multipleItems["Resource"][i]);
      itemResources.push("Resource");
    }
    for (let i = 0; i < this.state.multipleItems["Lesson"].length; i++) {
      items.push(this.state.multipleItems["Lesson"][i]);
      itemResources.push("Lesson");
    }
    for (let i = 0; i < this.state.multipleItems["Correlations"].length; i++) {
      items.push(this.state.multipleItems["Correlations"][i]);
      itemResources.push("Correlations");
    }

    browserHistory.push({
      pathname: "/library/addfolder",
      state: {
        libraryItemId: items,
        libraryItemType: itemResources,
        isLibrary: false,
      },
    });
    this.props.saveAssignmentItemAction(itemResources, items);
  };

  handleAddMultiple = function (desktop) {
    this.addMultipleItemsToLibrary();
    this.setState(
      {
        multiSelectMode: !this.state.multiSelectMode,
        addedMultipleItemsToLibrary: true,
      },
      () => {
        if (desktop)
          setTimeout(
            () =>
              this.setState({
                addedMultipleItemsToLibrary: false,
                multipleItems: {
                  Resource: [],
                  Lesson: [],
                  Correlations: [],
                },
              }),
            3000
          );
      }
    );
  };

  handleAddMultipleToFolder = function (desktop) {
    this.addMultipleItemsToFolder();
    this.setState(
      {
        multiSelectMode: !this.state.multiSelectMode,
        addedMultipleItemsToFolder: true,
      },
      () => {
        if (desktop)
          setTimeout(
            () =>
              this.setState({
                addedMultipleItemsToFolder: false,
                multipleItems: {
                  Resource: [],
                  Lesson: [],
                  Correlations: [],
                },
              }),
            3000
          );
      }
    );
  };

  onSelectMenuItem = (ekey) => {
    browserHistory.replace(`/browse/teaching-resources/${ekey}`);
  };

  //This function expects to be bound in a child component's onClick
  handleClickForResourcesThumbnail = function (item) {
    if (this.props?.multiSelectMode) {
      if (
        (item.type === "Resource" &&
          this.props.multipleItems["Resource"].indexOf(item._id) === -1) ||
        (item.type === "Lesson" &&
          this.props.multipleItems["Lesson"].indexOf(item._id) === -1)
      ) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        item.type !== "Correlations" &&
          this.props.deleteItem(item._id, item.type);
      }
    } else {
      let id = item?._id ?? item?.id;
      switch (item.type) {
        case "Resource":
          browserHistory.push("/resources/" + id);
          return;
        case "Unit":
          browserHistory.push("/units/" + id);
          return;
        case "Assessment":
          browserHistory.push("/assessments/" + id);
          return;
        case "Lessons":
          browserHistory.push("/lessons/" + id);
          return;
        case "Lesson":
          browserHistory.push("/lessons/" + id);
          return;
        default:
          browserHistory.push("/books/" + id);
          return;
      }
    }
  };

  //This function expects to be bound in a child component's onClick
  handleClickForResourcesAddBtn = function (item) {
    if (this.props.multiSelectMode) {
      if (
        (item.type === "Resource" &&
          this.props.multipleItems["Resource"].indexOf(item._id) === -1) ||
        (item.type === "Lesson" &&
          this.props.multipleItems["Lesson"].indexOf(item._id) === -1) ||
        (item.type === "Correlations" &&
          this.props.multipleItems["Correlations"].indexOf(item._id) === -1)
      ) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        this.props.deleteItem(item._id, item.type);
      }
    } else {
      //This replaces "showTooltip" in popover.
      this.setState({
        isTooltipActive: true,
        success: false,
        showDatePicker: false,
      });
    }
  };

  handleSelectResourceClick = (item) => {
    if (item.type) {
      this.setState({
        selectedDetailsType: item.type,
        selectedResourceId: "",
        selectedResourceName: "",
      });
      if (item.type === "Resource") {
        this.props.getResourceDetailsAction(item._id);
      } else if (item.type === "Correlation") {
        this.props.getCorrelationDetailsAction(item._id);
      } else if (item.type === "Unit") {
        this.setState({ selectedResourceId: item._id });
        this.props.getSingleUnitDetailsAction(item._id);
      } else if (item.type === "Assessment") {
        this.props.getAssessmentDetailsAction(item._id);
      } else if (item.type === "Book") {
        this.setState({ selectedResourceName: item.name });
        this.props.getBookDetailsAction(item._id);
      } else {
        this.props.getLessonsDetailedAction(item._id);
      }
    }
  };

  updateFromSearch = (data, total) => {
    this.setState({
      total: total ?? data?.length ?? 0,
      data: data ?? [],
      pageNumber: 1,
      filter: false,
      hasMore: false,
    });
  };

  handleCancel = () => {
    this.handleDeselectAll();
    this.setState({ multiSelectMode: !this.state.multiSelectMode });
  };

  handleMultiSelect = (item) => {
    const copyArr = [...this.state.selectedItems];
    if (copyArr.length !== 0) {
      if (!copyArr.filter((e) => e.item_id === item._id).length > 0) {
        copyArr.push({
          item_id: item._id,
          item_name: item.name,
          item_type: item.type,
        });
        let uniqueItems = [
          ...new Map(copyArr.map((v) => [v.item_id, v])).values(),
        ];
        this.setState({ selectedItems: uniqueItems });
      } else {
        copyArr.splice(
          copyArr.findIndex((x) => x.item_id === item._id),
          1
        ); //deleting
        this.setState({ selectedItems: copyArr });
      }
    } else {
      copyArr.push({
        item_id: item._id,
        item_name: item.name,
        item_type: item.type,
      });
      let uniqueItems = [
        ...new Map(copyArr.map((v) => [v.item_id, v])).values(),
      ];
      this.setState({ selectedItems: uniqueItems });
    }
  };

  handleSelectAll = () => {
    this.setState({ areAllSelected: true });
    const copyArr = [];
    this.props?.myLibrary?.data?.recents?.results?.forEach((item) => {
      copyArr.push({
        item_id: item._id,
        item_name: item.name,
        item_type: item.type,
      });
    });
    this.setState({ selectedItems: copyArr });
  };

  handleDeselectAll = () => {
    this.setState({ areAllSelected: false });
    this.setState({ selectedItems: [] });
  };

  handleOpenMultiselect = () => {
    this.setState({ multiSelectMode: !this.state.multiSelectMode });
  };

  handleRemoveFromLibrary = () => {
    authAPI.bulkRemoveFromLibrary(this.state.selectedItems).then((resp) => {
      if (resp?.statusCode === 200) {
        this.setState({ multiSelectMode: false });
        this.setState({ removeFromLibrarySuccess: true });
        this.props.getMyLibraryAction(constants.normalizeTypeName("recents"));
        setTimeout(() => {
          this.setState({ removeFromLibrarySuccess: false });
        }, 3000);
      } else {
        this.setState({ removeFromLibraryError: true });
        setTimeout(() => {
          this.setState({ removeFromLibraryError: false });
        }, 3000);
      }
    });
  };

  handleSuccessSnackBar = () => {
    this.handleCancel();
    this.setState({ isAddToFolderModalOpen: false });
    this.setState({ addToFolderSuccess: true });
    setTimeout(() => {
      this.setState({ addToFolderSuccess: false });
    }, 3000);
  };

  handleErrorSnackBar = (msg) => {
    this.handleCancel();
    this.setState({ isAddToFolderModalOpen: false });
    this.setState({ 
      addToFolderError: true,
      addToFolderErrorMsg:msg 
    });
    setTimeout(() => {
      this.setState({ addToFolderError: false });
    }, 3000);
  };

  handleBulkAddToFolderModalOpen = () => {
    this.setState({ isAddToFolderModalOpen: true });
  };

  handleBulkAddToFolderModalClose = () => {
    this.setState({ isAddToFolderModalOpen: false });
  };

  getResources = () => {
    const resources = this.props?.myLibrary?.data?.recents?.results;
    const startE = new Date().getTime();
    return resources?.length >= 0
      ? resources.map((resource, i) => (
          <ResponsiveResourcesNew
            item={resource}
            i={i}
            key={i}
            handleClickForResourcesThumbnail={
              this.handleClickForResourcesThumbnail
            }
            handleClickForResourcesAddBtn={this.handleClickForResourcesAddBtn}
            handleSelectResourceClick={this.handleSelectResourceClick}
            multiSelectMode={this.state.multiSelectMode}
            addMultipleItems={this.addMultipleItems}
            deleteItem={this.deleteItem}
            multipleItems={this.state.multipleItems}
            scheduleTask={this.props.postScheduledTasksAction}
            addToLibrary={
              resource.type === "Resource"
                ? this.props.postAddTeachingResourceToLibraryAction
                : resource.type === "Correlations"
                ? this.props.postAddCorrelationToLibraryAction
                : this.props.postAddLessonToLibraryAction
            }
            addLessonToLibrary={this.props.postAddLessonToLibraryAction}
            startE={startE}
            active={
              parseInt(resource._id) ===
                parseInt(this.state.selectedResourceId) ||
              parseInt(resource.unit_id) ===
                parseInt(this.state.selectedResourceId) ||
              resource.name === this.state.selectedResourceName
            }
            type={"library"}
            removeRecents={true}
            handleMultiSelect={this.handleMultiSelect}
            selectedItems={this.state.selectedItems}
          />
        ))
      : [];
  };

  render() {
    const navButtons = (
      <ResponsiveNavBtns
        displayableSelectedStrand={
          invertMap[constants.caseMap[this.props?.params?.strand]]
        }
        onSelectMenuItem={this.onSelectMenuItem}
        selectedStrand={this.props?.params?.strand}
        btnOptions={[
          "featured",
          "reading",
          "writing",
          "language",
          "speaking-listening",
        ]}
        nextJoyrideStep={this.props.nextJoyrideStep}
        isJoyrideRunning={this.props.isJoyrideRunning}
      />
    );

    return (
      <>
        <BrowsePageContent
          name="Teaching Resources"
          asyncStatus={this.props?.myLibrary?.asyncStatus ?? ""}
          resources={this.getResources()}
          resourceHeading={
            <ResourceHeading
              multiSelectMode={this.state.multiSelectMode}
              areAllSelected={this.state.areAllSelected}
              handleOpenMultiselect={this.handleOpenMultiselect}
              handleCancel={this.handleCancel}
              handleDeselectAll={this.handleDeselectAll}
              handleSelectAll={this.handleSelectAll}
              handleRemoveFromLibrary={this.handleRemoveFromLibrary}
              handleBulkAddToFolderModal={this.handleBulkAddToFolderModalOpen}
            />
          }
          selectedResource={
            this.state.resourceDetails?.id || this.state.resourceDetails?._id
              ? this.state.resourceDetails
              : this.state.lessonsDetail?.id || this.state.lessonsDetail?._id
              ? this.state.lessonsDetail
              : this.state.correlationDetails?.id ||
                this.state.correlationDetails?._id
              ? this.state.correlationDetails
              : this.state.singleUnitDetails?.unit_id
              ? this.state.singleUnitDetails
              : this.state.assessmentDetails?.id ||
                this.state.assessmentDetails?._id
              ? this.state.assessmentDetails
              : this.state.bookDetails?.id ||
                this.state.bookDetails?._id ||
                this.state.bookDetails?.name
              ? this.state.bookDetails
              : ""
          }
          selectedResourceType={
            this.state.resourceDetails?.id || this.state.resourceDetails?._id
              ? "resource"
              : this.state.lessonsDetail?.id || this.state.lessonsDetail?._id
              ? "lesson"
              : this.state.correlationDetails?.id ||
                this.state.correlationDetails?._id
              ? "correlation"
              : this.state.singleUnitDetails?.id ||
                this.state.singleUnitDetails?.unit_id
              ? "units"
              : this.state.assessmentDetails?.id ||
                this.state.assessmentDetails?._id
              ? "assessment"
              : this.state.bookDetails?.id ||
                this.state.bookDetails?._id ||
                this.state.bookDetails?.name
              ? "book"
              : ""
          }
          handleClickForResourcesThumbnail={
            this.handleClickForResourcesThumbnail
          }
          navButtons={navButtons}
          isFeatured={this.state.isFeatured}
          hasMore={this.state.hasMore}
          loadMore={this._loadMore.bind(this)}
          updateFromSearch={this.updateFromSearch}
          location={this.props.location}
          type={"library"}
        />
        {this.state.isAddToFolderModalOpen && (
          <AddToFolderModal
            handleClose={this.handleBulkAddToFolderModalClose}
            handleSuccessSnackbar={this.handleSuccessSnackBar}
            handleErrorSnackbar={this.handleErrorSnackBar}
            selectedItems={this.state.selectedItems}
          />
        )}

        <SnackBarModule
          addToFolderSuccess={this.state.addToFolderSuccess}
          addToFolderError={this.state.addToFolderError}
          addToFolderErrorMsg={this.state.addToFolderErrorMsg}
          removeFromLibrarySuccess={this.state.removeFromLibrarySuccess}
          removeFromLibraryError={this.state.removeFromLibraryError}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    myLibrary: state.MyLibrary,
    finalFilters: state.FilterScale,
    isJoyrideRunning: state.JoyrideReducer.joyrideShouldRun,
    resourceDetails: state.ResourceDetails,
    lessonsDetail: state.LessonsDetail,
    correlationDetails: state.CorrelationDetails,
    singleUnitDetails: state.SingleUnitDetails,
    bookDetails: state.BookDetails,
    assessmentDetails: state.AssessmentDetails,
  };
};

export default connect(mapStateToProps, actions)(LibraryHomeContent);
