import {
    GET_BOOKS_REQUEST,
    GET_BOOKS_SUCCESS,
    GET_BOOKS_FAILURE
} from '../actions';


const initialState = {};

const GetBooksReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BOOKS_REQUEST:
            return Object.assign({},state, {asyncStatus:'PENDING'});
        case GET_BOOKS_SUCCESS:
            return Object.assign({},state, {asyncStatus:'SUCCESS', data: action.payload});
        case GET_BOOKS_FAILURE:
            return Object.assign({},state, {asyncStatus:'FAILURE', data: action.payload});
        default:
            return state;
    }
};

export default GetBooksReducer;