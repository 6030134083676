import React, {Component} from 'react'
import {Row, Col, Modal, Alert} from 'react-bootstrap'
import {Link} from 'react-router'
import '../../assets/styles/index.scss'
import authAPI from '../../api/authAPI';
import avatar from '../../assets/images/Avatar.svg';

class TourStartButton extends Component {

    render() {
        console.log(this.props)
        let iconStyle = {
            fontSize: '5em',
            color: '#009BBD',
            width: '100%',
            display: 'inline-block',
            filter: this.props.lite ? 'grayscale(100%)' : '',
            height: '1.5em'
        };
        let disabledIconStyle = {
            fontSize: '5em',
            color: '#7e898b',
            width: '100%',
            display: 'inline-block'
        };
        return (<Row style={{height: '10em', pointerEvents: 'auto'}}>
                    <Col sm={12}>
                          <Row>
                              <Col sm={12} style={{height: '8em'}}>
                                  <div style={{textAlign: 'center',marginLeft: '1.5em', marginRight: '1.5em', marginTop:'1em', borderRadius: 10}}>
                                    <a href={this.props.linkObj} target="_blank">
                                    {this.props.disabled ?
                                        <i className={this.props.icon} style={disabledIconStyle}/> :
                                        <img src={require(`../../assets/icons/SVG/${this.props.icon}.svg`).default} style={iconStyle} />
                                      }
                                  </a>
                                  </div>
                                  {/*<a href={this.props.linkObj} target="_blank">
                                    {this.props.disabled ?
                                        <i className={this.props.icon} style={disabledIconStyle}/> :
                                        <img src={require(`../../assets/icons/SVG/${this.props.icon}.svg`).default} style={iconStyle} />
                                      }
                                  </a>*/}
                              </Col>
                          </Row>
                          <Row>
                              <Col sm={12} style={{height: "5em", float: 'right'}}>
                                  {/*<Link onClick={this.props.onClick} to={this.props.linkObj}>
                                    <div style={{textAlign: 'center', paddingLeft:'2.5em', paddingRight: '2.5em', paddingTop: '.5em'}}>
                                      {this.props.buttonText}
                                    </div>
                                  </Link>*/}
                                   <a href={this.props.linkObj} target="_blank">
                                    <div style={{textAlign: 'center', fontSize: '0.8em',paddingLeft:'2.5em', paddingRight: '2.5em', paddingTop: '.5em'}}>
                                      {this.props.buttonText}
                                    </div>
                                   </a>
                              </Col>
                          </Row>
                    </Col>
                </Row>
            )
    }
}

//  Expects boolean prop showTourModal and function prop hideTourModal which modifies showTourModal.
class JoyrideTourModal extends Component {
  constructor(props){
    super(props);
    this.state={
      displayTourNotReadyMsg: false,
      liteUserMsg: false
    }
  }

  componentWillReceiveProps=(props)=>{
    this.isAssignTourReady();
    this.setState({
      ...props,
      displayTourNotReadyMsg: false,
      liteUserMsg: false
    });
  }

  displayTourNotReady=(e)=>{
    e.preventDefault();
    this.setState({displayTourNotReadyMsg: true, liteUserMsg: false});
  }

  displayLiteUserMsg=(e)=>{
    e.preventDefault();
    this.setState({liteUserMsg: true, displayTourNotReadyMsg: false});
  }

  isAssignTourReady=()=>{
    //We aren't saving these to state, we're just taking a quick peek.
    // authAPI.checkHasBookInLibrary()
    // .then(resp => {
    //   if(resp && resp.total >= 1)
    //     this.setState({hasBooks: true});
    //   else
    //     this.setState({hasBooks: false});
    // })
    // .catch(() => this.setState({hasBooks: false}));

    authAPI.getGroups()
    .then(resp => {
      let hasStudentsTemp = false;
      if(resp && resp.length > 0){
        for(let i=0; i<resp.length; i++){
          if(resp[i].users && resp[i].users.length > 0){
            hasStudentsTemp = true;
            break;
          }
        }
      }
      this.setState({hasStudents: hasStudentsTemp});
    })
  }

  render(){
    return (
        <Modal id="start-tour-modal" bsSize="lg" show={this.props.showTourModal}>
            <Modal.Body>
                <Row>
                    <Col xs={3} className="pull-right">
                        <div onClick={this.props.hideTourModalJoyride} className="btn trialModalContactUsButton"
                            style={{
                            width: '12em',
                            position: 'relative',
                            top: '20px',
                            padding: '13px'
                            }}>
                            Explore on my own!
                        </div>
                    </Col>
                    {/* <Col sm={3}>
                        <div style={{height:'17em', width: '8em', float:'right'}}>
                            <img style={{height: '175px', marginTop: '30px'}} src={avatar} alt="assistant"/>
                        </div>
                    </Col> */}
                    <Col sm={7}>
                    {localStorage.first_time_login === "true" ?
                      <div style={{marginTop: '50px'}}>
                        <h1>Congratulations!<br />{"You're all set."}</h1>
                        <p>Thank you! See, I told you it was easy!</p>
                        <p>Now, I am so excited to show you the power of Fundamentals Unlimited!</p>
                      </div> :
                      <div style={{marginTop: '50px'}}>
                        {/*<h1>Fundamental Tours</h1>*/}
                        <h1>Tutorial Videos</h1>
                        <p>I am so excited to show you the power of Fundamentals Unlimited. Click one of the tours below to learn more!</p>
                      </div>
                    }
                    </Col>
                </Row>
                <Row>
                        <Col md={3}>
                            <TourStartButton
                                icon="intro"
                                buttonText="Introduction and Overview"
                                linkObj='https://vimeo.com/411418423'
                            />
                        </Col>


                        <Col md={3}>
                            <TourStartButton
                                icon="navigatingunitsofstudy"
                                buttonText="Navigating Units of Study"
                                  onClick={this.props.hideTourModalJoyride}
                                  linkObj='https://vimeo.com/411420658'

                            />
                        </Col>

                        <Col md={3}>
                            <TourStartButton
                                icon="creatinggroups"
                                buttonText="My Class Part 1: Creating Groups"
                              onClick={this.props.hideTourModalJoyride}
                              linkObj='https://vimeo.com/411421857'
                            />
                        </Col>

                          <Col md={3}>
                          <TourStartButton
                            icon="assigningstudent"
                            buttonText="My Class Part 2: Assigning Students Texts and Resources from my Library"
                            onClick={this.props.hideTourModalJoyride}
                            linkObj='https://vimeo.com/411421997'
                          />
                      </Col>
                </Row>
                <Row>
                  
                  <Col md={3}>
                    <TourStartButton
                      icon="studentfacingside"
                      buttonText="My Class Part 3: Student Facing Side"
                      onClick={this.props.hideTourModalJoyride}
                      linkObj='https://vimeo.com/414834047'
                    />
                  </Col>

                  <Col md={3}>
                    <TourStartButton
                      icon="teachingresources"
                      buttonText="Navigating Teaching Resources"
                      onClick={this.props.hideTourModalJoyride}
                      linkObj='https://vimeo.com/413611082'
                    />
                  </Col>

                  <Col md={3}>
                    <TourStartButton
                      icon="digitallibrary"
                      buttonText="Building and Organizing My Digital Library"
                      onClick={this.props.hideTourModalJoyride}
                      linkObj='https://vimeo.com/413979916'
                    />
                  </Col>


                  <Col md={3}>
                    <TourStartButton
                      icon="browsingassessments"
                      buttonText="Navigating Assessments"
                      onClick={this.props.hideTourModalJoyride}
                      linkObj='https://vimeo.com/manage/videos/413607952'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={3}></Col>

                  <Col md={3}>
                    <TourStartButton
                      icon="projecticon"
                      buttonText="Navigating Projects: Part 1"
                      linkObj='https://vimeo.com/415253333'
                    />
                  </Col>

                  <Col md={3}>
                    <TourStartButton
                      icon="projecticon"
                      buttonText="Navigating Projects: Part 2"
                      linkObj='https://vimeo.com/415253142'
                    />
                  </Col>

                  <Col md={3}></Col>

                </Row>

                <Row>
                  <Col style={{marginLeft: '66px', width: '736px'}}>
                      {this.state.displayTourNotReadyMsg &&
                          <Alert bsStyle="info" style={{marginTop: '20px', marginBottom: '0px'}} >
                            This feature is only available with a Fundamentals Unlimited subscription. <br/>
                              <a href="https://www.schoolwide.com/contact.php?t=nothing" target='_blank'>Contact us</a> for more information.
                          </Alert>
                      }
                      {this.state.liteUserMsg &&
                          <Alert bsStyle="info" style={{marginTop: '20px', marginBottom: '0px'}} >
                              This feature is only available with a Fundamentals Unlimited subscription. <br/>
                              <a href="https://www.schoolwide.com/contact.php?t=nothing" target='_blank'>Contact us</a> for more information.
                          </Alert>
                      }
                  </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Row>
              <Col xs={9}>
                {/*<p style={{float: 'left'}} className="trialModalFooterContactUsText">
                  Need more help?  Contact us at <span style={{"text-decoration": "underline"}}><strong>help@schoolwide.com</strong></span>, and we will be happy to answer any of your questions.
                </p>*/}
                <p style={{float: 'left'}} className="trialModalFooterContactUsText">
                  For more resources please visit our <a href='https://www.schoolwide.com/fundamentals-unlimited-support/?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz-8BM48CzUg-2ypC4jYppCF2Iiu1VIMhqan5W4V7BOgzbvZlxYsEy61xUBJ_RViYMGmES_sp' target="_blank"><span style={{"text-decoration": "underline","color":"#4A90E2"}}>support site</span></a>. If you need further help please feel free to contact us via our chat tool.
                </p>
              </Col>
              <Col xs={3}>
                <div onClick={this.props.hideTourModalJoyride} className="btn trialModalContactUsButton"
                    style={{
                      width: '12em',
                      position: 'relative',
                      top: '20px',
                      padding: '13px'
                    }}>
                    {/* Explore on my own! */}
                    Close
                </div>
              </Col>
            </Row>
          </Modal.Footer>
      </Modal>
    )
  }
}

export default JoyrideTourModal
