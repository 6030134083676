import React,{Component} from  'react'
import CollapsibleComponent from '../../containers/Details/CollapsibleComponent'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import constants from '../../constants'
import authAPI from '../../api/authAPI'
import _ from 'lodash'
import mySolidArrowRight from '../../assets/icons/SVG/my-solid-arrow-right.png';
import mySolidArrow from '../../assets/icons/SVG/my-solid-arrow.png';

require('../../assets/styles/newUnit.scss');

class InfoComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            description:'',
            nameErrorClass:'',
            descErrorClass:'',
            previousName:'',
        };
        this.changeHeading= this.changeHeading.bind(this);
        this.ChangeDescription = this.ChangeDescription.bind(this);
        this.showSidebarJoyride = _.once(this.props.nextJoyrideStep);
    }

    changeHeading(e){
        // if(this.state.previousName){
        //     if(e.target.value===''){
        //         this.nameValue.focus();
        //         this.setState({
        //             nameErrorClass:"error"
        //         });
        //     }
        //     else{
        //         this.setState({
        //             nameErrorClass:""
        //         });
        //     }
        // }
        this.setState({name:e.target.value});
        this.props.changeUnitHeadingAction(e.target.value);
    }

    saveName=()=>{
        let self=this;
        if(self.props.location.pathname!=='/new-unit'){
            // ----required field validation---------
            // if(this.props.newUnit.heading===''){
            //     this.nameValue.focus();
            //     this.setState({
            //         nameErrorClass:"error"
            //     });
            // }
            // else{
            //     this.setState({
            //         nameErrorClass:""
            //     });
            // }
            // if(self.state.previousName && self.props.newUnit.heading===''){
            //     this.props.changeUnitHeadingAction(self.state.previousName);
            //     authAPI.updateUnitName(this.props.params.uid,self.state.previousName);
            // }else{
            //     authAPI.updateUnitName(this.props.params.uid,this.props.newUnit.heading);
            // }
            if(this.props.newUnit.heading!==''){
                authAPI.updateUnitName(this.props.params.uid,this.props.newUnit.heading);
            }
        }else{
            if(self.props.newUnit.heading!==''){
                if(self.props.newUnit && (self.props.newUnit.newId===undefined ||self.props.newUnit.newId ==="")){
                    authAPI.saveNewUnit(self.props.newUnit.heading)
                    .then((response)=>{
                        if(response.status === 201){
                            self.props.saveNewUnitIdAction(response.data._id);
                        }else{
                            console.log('failure',response);
                            this.setState({success:false});
                        }
                    })
                }else{
                    authAPI.updateUnitName(this.props.newUnit.newId,this.props.newUnit.heading);
                }
            }
        }
    }

    saveDescription=()=>{
         let introduction={
                'text':this.props.newUnit.description
            };
        if(this.props.location.pathname!=='/new-unit'){
            authAPI.updateUnitIntroduction(this.props.params.uid,introduction);
        }else{
            authAPI.updateUnitIntroduction(this.props.newUnit.newId,introduction);
        }
    }

    ChangeDescription(e){
        //-----this is for required field validation
        // if(e.target.value===''){
        //     this.setState({
        //         descErrorClass:"error"
        //     });
        // }
        // else{
        //     this.setState({
        //         descErrorClass:""
        //     });
        // }
        this.setState({description:e.target.value});
        this.props.changeUnitDescriptionAction(e.target.value);
        if(this.props.newUnit.heading===''){
            this.props.checkUnitHeadingAction(false);
            this.nameValue.focus();
            this.props.changeUnitDescriptionAction('');

        }
    }

    componentDidMount(){
        let self = this;
        this.nameValue.focus();
        if(self.props.newUnit && self.props.newUnit.heading){
            this.setState({previousName:self.props.newUnit.heading});
        }
        if(this.props.checkUnitStatus.headingStatus===false){
            this.setState({
                nameErrorClass:"error"
            });
        }
        //---required filed validation-----
        // if(this.props.checkUnitStatus.descriptionStatus===false){
        //     this.setState({
        //         descErrorClass:"error"
        //     });
        // }
    }

    checkUnitNameStatus=()=>{
        if(this.props.newUnit.heading===''){
            this.props.checkUnitHeadingAction(false);
            this.nameValue.focus();
        }
    }
    componentWillReceiveProps(next){
        if(this.props.location.pathname==='/new-unit'){
            if(!next.newUnit.heading && next.checkUnitStatus.headingStatus===false){
                this.nameValue.focus();
                this.setState({
                    nameErrorClass:"error"
                });
            }
            else{
                this.setState({
                    nameErrorClass:""
                });
            }
        }
        //   if(!next.newUnit.description && next.checkUnitStatus.descriptionStatus===false){
        //       this.setState({
        //             descErrorClass:"error"
        //       });
        //   }

        //---removing error message when custom unit is mounted
        if(this.state.nameErrorClass==='error'){
          this.setState({nameErrorClass:''});
        }
        if(this.props.isJoyrideRunning)
            this.showSidebarJoyride();
    }

    render(){

        return(
            <div className='sidebar-items'>
                <CollapsibleComponent collapsed={false} header="DETAILS" headerStyle={{fontSize: "17px",letterSpacing:1.5,height:20.5, fontWeight:'bold', color: "#4A4A4A", paddingBottom: "35px", paddingTop: "15px",fontFamily:"ProximaNova" }} openToggleButton={<img src={mySolidArrowRight} style={{marginTop:'3px'}}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow} style={{marginTop:'3px'}}  className="arrow-icon  rss opacity"/>}>
                        <div className='detail-box'>
                            <img src={constants.filesUrl +'images/unit-custom.png'} className="custom-unit-image-size"/>
                        </div>
                        <div id="joyride-selector-unit-sidebar">
                            <div className='sidebar-items-text'>UNIT NAME
                                <span className='sidebar-star'>* </span>{this.state.nameErrorClass?<span style={{color:'#d01e1f',fontWeight:'normal'}}> Required</span>:''}
                            </div>
                            <div>
                                <input type='text'  ref={(input) => { this.nameValue = input;}}  className={this.state.nameErrorClass ?'red-border sidebar-textbox':'sidebar-textbox'} value={this.props.newUnit.heading} onChange={this.changeHeading} onBlur={this.saveName}/>
                            </div>
                            <div style={{paddingTop:'10px'}}>
                                <div className='sidebar-items-text'>DESCRIPTION
                                    {/*<span className='sidebar-star'>* </span>{this.state.descErrorClass?<span style={{color:'#d01e1f',fontWeight:'normal'}}> Required</span>:''}*/}
                                </div>
                                <div>
                                    <textarea type='text' value={this.props.newUnit.description} ref={(desc) => { this.descriptionValue = desc;}}
                                    className={this.state.descErrorClass ?'red-border sidebar-textarea':'sidebar-textarea'} onChange={this.ChangeDescription}  onBlur={this.saveDescription}/>
                                </div>
                            </div>
                        </div>
                </CollapsibleComponent>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
      newUnit : state.NewUnit,
      checkUnitStatus:state.CheckUnit,
      isJoyrideRunning: state.JoyrideReducer.joyrideShouldRun
    });
};

export default connect(mapStateToProps, actions)(withRouter(InfoComponent))
