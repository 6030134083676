import React,{Component} from  'react'
import {Button,ButtonGroup } from 'react-bootstrap'
import {withRouter} from 'react-router'

class UserButtonGroup extends Component{

    render(){
        return(
            <div>
                <ButtonGroup id="nav-buttons" className="align-button-grp"  style={{marginTop:'0px',width:'100%'}}>
                    <Button className='lesson-button'
                            style={{backgroundColor:this.props.selectedTab==="INFO"? "#009BBD":'#FFFFFF'}}
                            onClick={() => {this.props.selectTab("INFO"); }}
                        >
                        <span className="lesson-button-text" style={{ color : this.props.selectedTab === "INFO" ? '#FFFFFF': '#9B9B9B'}}>
                            INFO
                        </span>
                    </Button>
                   
                    <Button className='lesson-button'
                            style={{backgroundColor:this.props.selectedTab==="PASSWORD"? "#009BBD":'#FFFFFF'}}
                            onClick={() => {this.props.selectTab("PASSWORD");}}
                        >
                        <span className="lesson-button-text" style={{ color : this.props.selectedTab === "PASSWORD" ? '#FFFFFF': '#9B9B9B'}}>
                            PASSWORD
                        </span>
                    </Button>
                </ButtonGroup>
            </div>
        );
    }
}

export default withRouter(UserButtonGroup);