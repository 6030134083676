import {
    ADD_ANNOTATION_REQUEST,
    ADD_ANNOTATION_SUCCESS,
    ADD_ANNOTATION_FAILURE
} from '../actions';


const initialState = {};

const AddAnnotationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ANNOTATION_REQUEST:
            return Object.assign({},state, {asyncStatus:  'PENDING'});
        case ADD_ANNOTATION_SUCCESS:
            return Object.assign({},state, {asyncStatus:  'SUCCESS', data: action.payload, annotationType: action.annotationType, itemType: action.itemType});
        case ADD_ANNOTATION_FAILURE:
            return Object.assign({},state, {asyncStatus:  'FAILURE', data: action.payload, annotationType: action.annotationType, itemType: action.itemType});
        default:
            return state;
    }
};

export default AddAnnotationReducer;