import React from 'react'
import Datetime from 'react-datetime'
import PropsMultiStateIcon from "./PropsMultiStateIcon"

export default class InformationBar extends React.Component {
     constructor(props){
        super(props);
        this.state={
            showDatePicker:false
        };
     }

    handleClick=()=>{
        this.props.showSearchResource(false);
        if(this.props.itemType === "Planer") {
            this.setState({
                showDatePicker:false
            }, () => {
                this.props.setItemType("globalSearch");
            });
        } else {
            this.setState({
                showDatePicker:!this.state.showDatePicker
            }, () => {
                this.props.setItemType("Planer");
            });
        }
    };

    handleDate=(date)=>{
        this.setState({
            showDatePicker:false
        },() => {
            this.props.setItemType("Planer");
            this.props.showSearchResource(true,date)
        });
    };

    showGlobalSearch=()=>{
        this.setState({
            showDatePicker:false
        },() => {
            if(this.props.itemType === 'globalSearch') {
                this.props.showSearchResource(false,new Date());
                this.props.setItemType("Planer");
            } else {
                this.props.showSearchResource(true,new Date());
                this.props.setItemType("globalSearch");
            }
        });
    };

    render() {
        return (
            <div id="info-bar">
                    <div style={{display: "inline-block"}}>
                        <i className="schoolwide-demo-icon icon-icon-schoolwide-silo"/>
                    </div>
                    <div style={{display: "inline-block", position: "absolute", top:"0%"}} className="dashboard-text-format">
                        Planner
                    </div>
                    <div style={{float: "right", marginTop: 10, marginRight:15}}>
                        <PropsMultiStateIcon enabled={true}
                                        active={this.props.panelStatus && this.props.itemType === 'globalSearch'}
                                        onClick={this.showGlobalSearch}
                                        sharedClasses="header-icon-my-library"
                                        activeClasses="icon-icon-search-circle-solid"
                                        inactiveClasses="icon-icon-search-circle-outline icon-blue"
                                        disabledClasses="icon-icon-search-circle-outline"
                        />
                    </div>
                    <div style={{float: 'right',cursor:'pointer'}}>
                        <PropsMultiStateIcon enabled={true}
                                        active={this.state.showDatePicker || (this.props.panelStatus && this.props.itemType === 'Planer')}
                                        onClick={this.handleClick}
                                        sharedClasses="dashboard-add-demo-icon"
                                        activeClasses="icon-icon-add-circle-solid"
                                        inactiveClasses="icon-icon-add-cricle-outline icon-blue"
                                        disabledClasses="icon-icon-search-circle-outline">
                        </PropsMultiStateIcon>
                        <span>
                            {this.state.showDatePicker &&
                            <div className="mobile-datetime-wrapper">
                                <div className="datetime-close-x"
                                     onClick={this.handleClick}>
                                    X
                                </div>
                                <Datetime open={true} className='mobile-datetime' onChange={this.handleDate}/>
                            </div>}
                        </span>
                    </div>
                    {/*<span style={{float: 'right'}}><i className="dashboard-search-demo-icon icon-icon-search-circle-outline"></i></span>*/}
                    </div>
        );
    }
}
