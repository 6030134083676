import React, { Component } from "react";
import CollapsibleComponent from "./Details/CollapsibleComponent";
import * as actions from "../redux/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import CheckedFilterItem from "../components/CheckedFilterItem";

const Slider = require("rc-slider");
const style = { margin: "0px 17px 30px 8px" };
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

class FilterCollapsible extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: [],
      counter: 0,
      filteredValues: [],
      min: 0,
      max: 2000,
      updateContent: props.updateContent,
      tgrades: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  handleChange = (e) => {
    let grade = {};
    grade.query = "lexileLevel";
    grade._id = "lexileLevel";
    grade.value = "Lexile Level";
    grade.name = "Lexile Level";
    grade.checked = true;
    grade.min = e[0];
    grade.max = e[1];
    this.props.filterFunction(grade, true);
  };

  handleClearFilters() {
    this.setState({
      content: [],
      counter: 0,
    });
  }

  changeValues = (e) => {
    this.setState({
      min: e[0],
      max: e[1],
    });
  };

  handleClick(checked, index, grade) {
    grade.name = this.props.tagName;
    grade.index = index;
    grade.query = this.props.queryString;
    this.props.filterFunction(grade, checked);

    this.setState({
      counter: this.state.counter + (checked ? 1 : -1),
    });
  }

  componentWillReceiveProps(next) {
    this.setState({
      counter: next.content.filter((item) => item.checked).length,
      min: next.minRange
        ? 0
        : next.content && next.content[0]
        ? next.content[0].min
        : this.state.min,
      max: next.maxRange
        ? 2000
        : next.content && next.content[0]
        ? next.content[0].max
        : this.state.max,
    });

    if (next.shouldReset) {
      this.handleClearFilters();
    }
  }

  getContent() {
    return this.props.content;
  }

  render() {
    return (
      <div>
        <CollapsibleComponent
          collapsed={!this.props.joyrideNeedsThisOpen}
          header={this.props.header}
          counter={
            this.state.min > 0 || this.state.max < 2000 ? 1 : this.state.counter
          }
          filterIcon={true}
          type={"filter"}
          index={this.props.index}
          onToggleShow={
            this.props.index || this.props?.index === 0
              ? this.props.onToggleShow
              : ""
          }
          headerStyle={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "14px",
            color: "#828282",
            paddingBottom: "5px",
            paddingTop: "5px",
            fontFamily: "Open Sans",
            cursor: "pointer",
            border: "1px solid #33afca",
            borderBottom: "1px solid #33afca",
            borderRadius: "6px",
            marginBottom: "10px",
          }}
          headerStyleCollapsed={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "14px",
            color: "#828282",
            paddingBottom: "5px",
            paddingTop: "5px",
            fontFamily: "Open Sans",
            cursor: "pointer",
            border: "1px solid #33afca",
            borderBottom: "0.5px solid #33afca",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            marginBottom: "10px",
          }}
        >
          {this.getContent() &&
            this.getContent().map(
              (grade, j) =>
                grade.value !== undefined && (
                  <div
                    key={"filter_" + grade.value}
                    className="dropdown-labels-container"
                  >
                    {this.props.header === "Lexile Level" ? (
                      <div>
                        <div style={style}>
                          <Range
                            allowCross={false}
                            min={0}
                            max={2000}
                            step={1}
                            defaultValue={[this.state.min, this.state.max]}
                            value={[this.state.min, this.state.max]}
                            onChange={this.changeValues}
                            handleStyle={[
                              {
                                backgroundColor: "#F87622",
                                borderColor: "#F87622",
                                height: "21px",
                                width: "21px",
                                marginTop: "-9px",
                              },
                            ]}
                            trackStyle={[{ backgroundColor: "#F87622" }]}
                            onAfterChange={this.handleChange}
                          />

                          <div>
                            <span style={{ float: "left", marginLeft: "0" }}>
                              {this.state.min}
                            </span>
                            <span
                              style={{ float: "right", marginRight: "-16px" }}
                            >
                              {this.state.max}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <CheckedFilterItem
                        show={grade.show}
                        checked={grade.checked || false}
                        handleClick={this.handleClick}
                        value={grade.value}
                        option={grade}
                        index={j}
                        tagName={this.props.tagName}
                      />
                    )}
                  </div>
                )
            )}
        </CollapsibleComponent>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    joyrideShouldRun: state.JoyrideReducer.joyrideShouldRun,
    joyrideStepIndex: state.JoyrideReducer.joyrideStepIndex,
    joyrideIntendsToStart: state.JoyrideReducer.joyrideIntendsToStart,
  };
};

export default connect(mapStateToProps, actions)(withRouter(FilterCollapsible));
