import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import rocketShip from "../../assets/images/rocket_ship.jpg";
import fundamentalsLogo from "../../assets/images/Fundamentals_Logo.png";

export default class PrintOnlyModal extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.handleClose}>
        <Modal.Header closeButton>
          <div className="modal-content-custom">
            <div className="modal-top-wrapper">
              <img
                src={rocketShip}
                alt="rocket ship"
                className="rocket-image-modal"
              />
              <div className="modal-top-right-panel">
                <img src={fundamentalsLogo} alt="fundamentals logo" />
                <h1>UPGRADE</h1>
                <h4 className="modal-main-text">
                  Upgrade your Fundamentals Unlimited license to access
                  thousands more quality resources.
                </h4>
              </div>
            </div>
            <div className="modal-footer-wrapper">
              <a href="https://www.schoolwide.com/fundamentals-unlimited/">
                <h2>Learn More</h2>
              </a>
              <a href="https://www.schoolwide.com/contact-us/">
                <h2>REQUEST FREE TRIAL</h2>
              </a>
            </div>
          </div>
        </Modal.Header>
      </Modal>
    );
  }
}
