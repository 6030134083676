import React , {Component} from 'react'
import Footer from './Footer'
import "../assets/styles/mobile.scss"
class Root extends Component{

    render(){
      let className = "root_without_overflowy";
      let path = window.location.pathname;

       if (path.indexOf('/browse/') > -1) {
           className = "root_without_overflowy";
       } else if (path.indexOf('/library') > -1 || path.indexOf('/lessons/') > -1 || path.indexOf('/units/') > -1) {
         //Library and detail pages for lessons and units have a dropup menu at the bottom on mobile.
           className += " root_mobile_responsive_btns"
       }

        return(
             <div style={{height: '100%'}}>
                <div className={className}>
                      {this.props.children}
                </div>
                <Footer />
            </div>
        );
    }
}

export default Root
