import React from 'react';
import {Row,Col} from 'react-bootstrap'
import {Link} from 'react-router'
import constants from '../constants'
import authAPI from '../api/authAPI'
import RemovePopOver from './Shared/RemoveItemPopOver'
import "../assets/styles/agendaEventComponent.scss"

//Not used for now
//const weekdays=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

const AgendaEventComponent = (getScheduledTasksAction, props) => {

    const removeItem=(event)=>{
       authAPI.removePlannerItem(event._id)
       .then((response) => {
            if (response.status === 200) {
                getScheduledTasksAction("grid",event.start);
            }
        })
        .catch((error)=>{
            console.log("exception occured ",error);
        });
    };

    const date = typeof props.event.start !== "string" ? props.event.start : new Date(props.event.start);
    let lesson_link ='/home';
    if(props.event.title === 'Add Item'){
        lesson_link = '/home';
    }
    else if(props.event.item_type === "Book" || props.event.item_type === "Assessment") {
        lesson_link = '/' + props.event.item_type.toLowerCase() + 's/' + props.event.item_id;
    }
    else if(props.event.item_type === "Correlations") {
        lesson_link = '/' + props.event.item_type.toLowerCase() + '/' + props.event.item_id;
    }
    else {
        lesson_link = '/' + props.event.item_type.toLowerCase() + 's/' + props.event.item_id;
    }

   if (props.event && props.event.__collapsed[date.toDateString()]) {
       return null;
   }
    return (
        <div>
            {props.event.title !== "Add Item" &&
                <Link to={lesson_link}>
                    <Row className="event-cell" style={{display: 'flex'}}>
                        <Col xs={3} style={{paddingLeft: '10px', margin: 'auto', flex: '0 2 4%'}}>
                            <img src={constants.filesUrl + (props.event.image||'')}
                                 className="image-size-in-list-view" />
                        </Col>
                        <Col xs={8} md={10} sm={8} style={{margin: 'auto', flex: '1 1 auto'}}>
                            <Row>
                                {props.event.title &&
                                    <Col className="event-title-agenda">
                                        {props.event.title}
                                    </Col>
                                }
                            </Row>
                            <Row>
                                {props.event.desc &&
                                    <Col className="event-desc-agenda">
                                        {props.event.desc}
                                    </Col>
                                    }
                            </Row>
                        </Col>
                        <Col xs={1} style={{paddingRight: 0, paddingLeft: 0}}>
                        <RemovePopOver
                            stopPropagation={true}
                            preventDefault={true}
                            className='planner-remove-list'
                            popOverId={"popOverRemoveM"+props.event._id}
                            removeObj={props.event}
                            remove={removeItem}
                            />
                        </Col>
                    </Row>
                </Link>
            }
        </div>
    )
}

export default AgendaEventComponent
