import {FILTER_SCALING} from '../actions'


const initialState={
	loading:false
};

const FilterScalingReducer=(state=initialState,action)=>{
    switch(action.type){

        case FILTER_SCALING:
            return Object.assign({}, state, {filterList:action.payload, loading:false, query: action.queryObject});

        default:
            return state;
    }
}

export default FilterScalingReducer;
