import React, {Component} from 'react';
import {Row,Col} from 'react-bootstrap'
import '../../assets/styles/layout.scss'
import MyMenu from "../../containers/MyMenu";


// props
// heroText - optional, renders hero
// header
// sidebar
// isSidebarOpen
// isGlobalSearch - if global search is on page, pass a false bool to prevent
//                  double scrolling when it is open.
// main
export default class Layout extends Component {

    componentWillReceiveProps(props) {
        if(this.props.isSidebarOpen != props.isSidebarOpen) {
            this.refs.right.show();
        }
    }
    render() {

        return (
            <div id="layout">
                <Row id="layoutHeader">
                    <Col xs={12} style={{paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0}}>
                        {this.props.header}
                    </Col>
                </Row>

                {/*Hero if heroText is provided*/}
                {this.props.heroText !== undefined &&
                    <Row id="layoutHero">
                        <Col sm={12} xsHidden>
                            <div className="hero">
                                <div className="teaching-resources">{this.props.heroText}</div>
                            </div>
                        </Col>
                    </Row>
                }
                <Row id="layoutBody">
                    <div id="joyride-filter-menu" style={{height: '100%'}}>
                        <MyMenu ref="right" alignment="right" showMenu={!this.props.isSidebarOpen} showScroll={!this.props.isGlobalSearch}>
                            {this.props.sidebar}
                        </MyMenu>
                    </div>
                    <Col xs={12}>
                        <div className={this.props.isSidebarOpen? 'md9class' : ''}>
                        {this.props.main}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
