import React,{Component} from  'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Cookies from 'universal-cookie';
import SearchBar from './SearchBar';
import ButtonGrp from './ButtonGrp';
import DetailedDescription from '../../containers/Details/DetailedDescription'
import OverviewContent from './OverviewContent';
import LessonsContent from './LessonsContent';
import TextContent from '../../containers/Details/TextContent';
import Vocabulary from './Vocabulary';
import Assessments from './Assessments';
import Correlations from './Correlations';
import Appendices from './Appendicies';
import * as actions from '../../redux/actions';
import authAPI from '../../api/authAPI'
import constants from '../../constants'
import {connect} from 'react-redux';
import {withRouter, browserHistory} from 'react-router';
import GlobalSearch from '../../components/GlobalSearchComponent';
import MyMenu from '../../containers/MyMenu';
import _ from 'lodash';
import steps from './stepsUnitDetails';
import ResponsiveDetailButtonGroup from '../../components/Shared/ResponsiveDetailButtonGroup';
import "../../assets/styles/unitsDetailedView.scss";
import videoPlaceHolderImage from './video_thumbnail.png';
import closeImage from './close.png';
import newIcon from './new.png'
import schoolwideIcon from './messageSchoolwideLogo.jpeg'
import bottomBorder from './popup_message_bottom_background.png'


const componentMap = {
    "OVERVIEW": OverviewContent,
    "LESSONS": LessonsContent,
    "TEXT": TextContent,
    "VOCABULARY": Vocabulary,
    "APPENDICES":Appendices,
    "ASSESSMENTS": Assessments,
    "CORRELATIONS": Correlations
};

const caseMap = {
    "overview": "OVERVIEW",
    "lessons": "LESSONS",
    "texts":"TEXT",
    "vocabulary":"VOCABULARY",
    "appendices":"APPENDICES",
    "assessments": "ASSESSMENTS",
    "correlations": "CORRELATIONS"
};

class UnitsDetailedView extends Component{
    constructor (props) {
        super(props);
        this.state = {
            selectedTab: caseMap[this.props.params.tab || "overview"],
            render: false,
            infoClicked: window.innerWidth < 768 ? false : true,
            searchClicked: false,
            load: "initial",
            showPopOver:false,
            showPopOverMobile:false,
            AddedToLibrary:false,
            eLearning:false,
            showAppendices:true,
            showAssessments:true,
            appendices: []
        };
        this.selectTab = this.selectTab.bind(this);
        this.onInfoClick = this.onInfoClick.bind(this);
        this.continueTour = _.once(()=>this.props.resetJoyride(steps.steps, 2));
        this.loadSteps = _.once(()=>this.props.joyrideLoadSteps(steps.steps));
        this.startJoyrideAtIndex = _.once(()=>this.props.startJoyrideAtIndex(1, 5));
    }

    componentDidMount(){
        this.props.getUnitsDetailAction(this.props.params.uid);
        setTimeout(()=>this.setState({render: true}), 300);
        this.loadSteps();
        if(window.innerWidth<678){
            this.setState({load:'',infoClicked:false})
        }
        authAPI.validateElearningUnits(this.props.params.uid).then((resp)=>{
            this.setState({
                eLearning:resp.elearning,
                showAppendices:resp.is_appendices,
                showAssessments:resp.is_assessments,
            })
        });

        this.props.getAppendicesDetailsbyId(this.props.params.uid).then(res=>{
            let outObject = res.reduce(function(a, e) {
              let estKey = (e['type']); 

              (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
              return a;
            }, {});
            this.setState({
                appendices : outObject
            })
        });
    }

    onInfoClick(infoClicked = !this.state.infoClicked){
        if(infoClicked) {
            this.onSearchClick(false);
        }
        this.setState({infoClicked: infoClicked, load: ""});
    }

    onSearchClick=(searchClicked = !this.state.searchClicked)=>{
        if(searchClicked) {
            this.onInfoClick(false);
        }
        this.setState({searchClicked: searchClicked, load: ""},()=>{
            this.refs.right.show()
        });
    };

    selectTab (tab) {
        let uuid = localStorage.getItem("uuid");
        console.log("checksession uuid now:"+uuid);
        let isActiveSession = true;
        let userException = ["dejital1@gmail.com","ladiadavies@gmail.com"];

        authAPI.validateUserSessionByUUID(uuid).then((resp)=>{
            console.log("resp received");
            console.log(resp);
            isActiveSession = resp[0].active;
            console.log("in student home");
            if(isActiveSession == false && !userException.includes(localStorage.getItem("username"))){
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                        <div className='custom-ui'>
                            <h1>User session has ended. You have logged in through another device.</h1>
                            <button
                            onClick={() => {
                                document.cookie = "sessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    
                                localStorage.clear();
                                browserHistory.push(constants.PATHS.ROOT);
                    
                                setTimeout(function(){window.location.reload()},100);
                            }}
                            >
                            Continue
                            </button>               
                        </div>
                        );
                    }
                });
            }
        });
        this.setState({ selectedTab: tab});
    }

    addToLibraryStatus=()=>{
        this.setState({AddedToLibrary:true})
    }

    componentWillReceiveProps(next){
        let width = window.innerWidth
        if(width>678){
            if(this.state.selectedTab !== next.params.tab){
                this.setState({selectedTab: caseMap[next.params.tab || "overview"]})
            }
        }
        // success pop over should be visible after creating a new unit from the sw-unit
        if(this.state.AddedToLibrary){
            this.setState({
              showPopOver: true,
              showPopOverMobile: true,
              AddedToLibrary:false
            },()=>{setTimeout(()=>{this.setState({showPopOver:false})},3000)});
        }
        if(this.props.params.uid!==next.params.uid){
            this.props.getUnitsDetailAction(next.params.uid);
        }
        if(this.props.isJoyrideRunning && next.unitsDetail.asyncStatus === 'SUCCESS')
                setTimeout(this.continueTour, 300);
        else if(this.props.location.state && this.props.location.state.unitTourPartTwo)
                setTimeout(this.startJoyrideAtIndex, 1000);

    }

    hideTooltip=()=>{
      this.setState({
        showPopOverMobile: false
      })
    }

    render(){
        const ContentComponent = componentMap[this.state.selectedTab];
        /**Show Temporary message for unit overview change*/
        /**closed as asked by Deji on 20.05.2024 */
        // const cookies = new Cookies();
        // let date = new Date();
        // date.setDate(date.getDate()+500000);
        // let unitOverviewMessageCookie = cookies.get('unitOverviewMessageSeen');
        // cookies.set('unitOverviewMessageSeen', 'y', { path: '/',expires: date });
        // if(unitOverviewMessageCookie === '' || unitOverviewMessageCookie == null){
        //   confirmAlert({
        //     customUI: ({ onClose }) => {
        //       return (
        //         <div className='custom-ui' style={{"backgroundColor":"white","fontFamily": "ProximaNova","color":"#000","width":"661px","padding":"0","margin":"0"}}>
        //           <button
        //             onClick={() => {
        //               cookies.set('unitOverviewMessageSeen', 'y', { path: '/', expires: date });                      
        //               onClose();
        //             }}
        //           style={{"textAlign":"right","width": "48px","marginLeft":"91%","color":"#000"}}>
        //             <img src={closeImage} height="25px" width="25px" style={{"marginLeft":"20%"}} alt=""/><br/>close
        //           </button>
        //           <img src={schoolwideIcon} height="150px" width="160px" style={{"marginTop":"-87px"}} alt=""/>
        //           <h3 style={{"textAlign":"center","fontSize":"25px","fontWeight":"bold","marginTop":"-7px"}}>HAVE YOU SEEN OUR NEW UNIT</h3>
        //           <h3 style={{"textAlign":"center","fontSize":"25px","fontWeight":"bold","marginTop":"-7px"}}>OVERVIEW PAGE?</h3>
        //           <p style={{"textAlign":"center","fontWeight":"normal"}}>Click on any Unit of Study to view.</p>
        //           <div style={{"width":"350px","marginLeft":"33%"}}>
        //             <img src={videoPlaceHolderImage} style={{"display":"block","width": "100%","height":"auto"}} alt=""/>
        //           </div>
        //           <div style={ { backgroundImage: "url("+bottomBorder+")","padding":"25px","color":"#fff" } }>
        //             <h4 style={{"textAlign":"center","fontWeight":"bold","lineHeight":"23px"}}>We are excited to welcome you to the NEW</h4>
        //             <h4 style={{"textAlign":"center","fontWeight":"bold","lineHeight":"0px"}}>Fundamentals Unlimited Unit Overview Page!</h4>
        //             <h3 style={{"fontSize":"20px"}}>JUST A FEW OF THE FANTASTIC NEW RESOURCES YOU WILL FIND:</h3>                    
        //                 <p style={{"textAlign":"center","fontWeight":"normal","lineHeight":"10px"}}>Sample Lesson Videos</p>
        //                 <p style={{"textAlign":"center","fontWeight":"normal","lineHeight":"10px","position":"relative"}}>
        //                   Unit Planner and Pacing Guides  <img src={newIcon} height="50px" width="50px" style={{"marginLeft":"10px","marginTop":"-20px","position":"fixed"}} alt=""/>
        //                 </p>
        //                 <p style={{"textAlign":"center","fontWeight":"normal","lineHeight":"10px"}}>Classroom Library Checklist</p>
        //                 {/* <img src={newIcon} height="50px" width="50px" style={{"position":"fixed","top":"88%","left":"62%"}}/> */}
        //                 <p style={{"textAlign":"center","fontWeight":"normal"}}>...plus more engaging videos coming soon!</p>
        //           </div>                
        //         </div>
        //       );
        //     }
        //   });    
        // }
        /**Show Temporary message for unit overview change*/


        return(
            <div className="detail-wrapper">
                {this.state.render && (this.props.unitsDetail.asyncStatus === 'SUCCESS'? (<div style={{height:"100%"}}>
                {this.props.unitsDetail.asyncStatus === 'SUCCESS' && this.props.unitsDetail.data[""+ this.props.params.uid] && this.props.unitsDetail.data[""+ this.props.params.uid].name &&
                (<SearchBar
                    infoClick={this.onInfoClick}
                    onSearchClick={this.onSearchClick}
                    strand={this.props.params.strand}
                    customStatus={this.props.unitsDetail.data[""+ this.props.params.uid].is_custom}
                    name={this.props.unitsDetail.data[""+ this.props.params.uid].name}
                    unitData={this.props.unitsDetail.data[""+ this.props.params.uid]}
                    popOverStatus={this.state.showPopOver}
                    popOverStatusMobile={this.state.showPopOverMobile}
                    addToLibraryStatus={this.addToLibraryStatus}
                    addToLibrary={this.props.postAddUnitToLibraryAction}
                    hideTooltip={this.hideTooltip}
					infoClicked={this.state.infoClicked}
                    searchClicked={this.state.searchClicked}
                    unitId={this.props.params.uid}/>
                )}
                <div className={((this.state.infoClicked || this.state.searchClicked)?"some-area fill-height-or-more":"fill-height-or-more some-area-without-info")} >
                    <div style={{paddingTop:window.innerWidth<678?'65px':'',marginBottom:window.innerWidth<678?'35px':''}}>
                        <ButtonGrp id={this.props.params.uid} selectTab={this.selectTab} eLearning={this.state.eLearning} showAppendicesTab={this.state.showAppendices} showAssessmentsTab={this.state.showAssessments} selectedTab={this.state.selectedTab}/>
                        {this.props.unitsDetail.asyncStatus === 'SUCCESS' && this.props.unitsDetail.data[""+ this.props.params.uid] &&
                        (<div className="unit-detail-component" >
                            <ContentComponent scheduleTask={this.props.postScheduledTasksAction} addToLibrary={this.props.postAddUnitToLibraryAction} requestType='units' content={this.props.unitsDetail.data[""+ this.props.params.uid]} unit_id={this.props.params.uid} appendices_content={this.state.appendices}/>
                        </div>)}
                        {this.props.unitsDetail.asyncStatus === 'SUCCESS' && this.props.unitsDetail.data[""+ this.props.params.uid] &&
                        (<div >
                            <ResponsiveDetailButtonGroup itemType='units' id={this.props.params.uid} selectTab={this.selectTab} selectedTab={this.state.selectedTab}/>
                        </div>)}
                    </div>
                    <div>
                        {this.props.unitsDetail.asyncStatus === 'SUCCESS' && this.props.unitsDetail.data[""+ this.props.params.uid] &&
                        (<DetailedDescription  image={this.props.unitsDetail.data[""+ this.props.params.uid].image} detailType='units' printable={this.props.unitsDetail.data[""+ this.props.params.uid].printable} printableFile={this.props.unitsDetail.data[""+ this.props.params.uid].unit_doc} fileName={this.props.unitsDetail.data[""+ this.props.params.uid].unit_doc} name={this.props.unitsDetail.data[""+ this.props.params.uid].name} description={this.props.unitsDetail.data[""+ this.props.params.uid].introduction} lessonContent={this.props.unitsDetail.data[""+ this.props.params.uid]} slideUrl={this.props.unitsDetail.data[""+ this.props.params.uid].slide_url}> No detail </DetailedDescription>)}
                    </div>
                </div>
            </div>) : <div/>)}
                {this.state.render && (this.props.unitsDetail.asyncStatus === 'PENDING'? <div>Loading....</div>:<div/>)}
                {this.state.render && (this.props.unitsDetail.asyncStatus === 'FAILURE'? <div className="featured-reading" style={{textAlign: "center"}}>You do not have permission to view this</div>:<div/>)}
                <div style={{marginTop:'61px'}}>
                    <MyMenu ref="right" alignment="right" showMenu={this.state.searchClicked} showScroll={false}>
                        <GlobalSearch focus={this.state.searchClicked && window.innerWidth > 767} itemType='Unit'/>
                    </MyMenu>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
      unitsDetail : state.UnitsDetail,
      isJoyrideRunning: state.JoyrideReducer.joyrideShouldRun
    });
};

export default connect(mapStateToProps, actions)(withRouter(UnitsDetailedView))
