import React,{Component} from 'react'
import authAPI from '../api/authAPI'
import constants from '../constants'
import * as actions from '../redux/actions'
import {connect} from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'
import {browserHistory} from 'react-router'
import {Row, Col, Media, Image} from 'react-bootstrap'
import '../assets/styles/globalSearchComponent.scss'
import CreateFolderDialog from './MyLibrary/CreateFolderDialog'
import pubIcon from '../assets/icons/PNG/pub.png'

class TextBox extends Component{
    render(){
        return(
            <div className='textBox-wrapper'>
                <input className={(this.props.errorValue === "")? "global-unit-searchbar" : "global-unit-searchbar add-user-form-error" }
                name={this.props.name}
                type={this.props.type || 'text'}
                value={this.props.value}
                placeholder={this.props.placeholder}
                onChange={this.props.onChange}/>
                {this.props.errorValue !== "" &&
                    <div className="add-user-error">
                        {this.props.errorValue}
                    </div>
                }
            </div>
        );
    }
}

class FolderCreationComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            form: {
                folderName:''
            },
            createFolderInput: false,
            folderNameValid: "",
            errorMessage: "",
            searchString:'',
            resources:[],
            pageNumber:1,
            queryString:'',
            deletePopUp:false,
            respRows:true,
            showRenameFolder: {},
            orderByName : 'DESC',
            orderByName : 'ASC',
        };
        this.pageLoad = true;
        this.handleClick = this.handleClick.bind(this);
        this.showCreateFolderInput = this.showCreateFolderInput.bind(this);
        this.addfolderName = this.addfolderName.bind(this);
        this.sortByNameOrder = this.sortByNameOrder.bind(this);
        this.sortByDateOrder = this.sortByDateOrder.bind(this);
        this.createNewFolder = this.createNewFolder.bind(this);
        this.fetchFolders = this.fetchFolders.bind(this);
        this.validateInput = this.validateInput.bind(this);
        this.deleteFolder = this.deleteFolder.bind(this);
        this.showError = this.showError.bind(this);
        this.searchString = '';
    }

    validateInput() {
        let isGood = true;
        if(this.state.form.folderName.length == 0 || !this.state.form.folderName) {
            this.setState({folderNameValid: "Folder Name cannot be blank."});
            isGood = false;
        } else {
            this.setState({folderNameValid: ""});
        }

        return isGood;
    }

    handleClick(e,i,item) {
       let selectedItem = this.state.queryItems;
       let value=e.target.checked;
       let stateQuery = item.query;
       let currentQuery = this.state.queryString;
       if(value){
            item.checked= true;
            if(this.props.itemType==='Planer' && currentQuery=== '&item_type=Lesson&item_type=Resource&item_type=Book&item_type=Assessment'){
                currentQuery= '';
                selectedItem='';
            }
            if(selectedItem===''){
                selectedItem = selectedItem+item.name;
            }
            else{
                selectedItem = selectedItem+', '+item.name;
            }
            currentQuery = currentQuery+'&item_type='+item.query;

       }
       else{
            item.checked= false;
            let queryRe = new RegExp('&item_type='+item.query);
            currentQuery = currentQuery.replace(queryRe,'');
            let re = new RegExp(', '+item.name);
            selectedItem = selectedItem.replace(re,'');
            if(selectedItem===this.state.queryItems){
                let re = new RegExp(item.name+', ');
                selectedItem = selectedItem.replace(re,'');
            }
            if(this.state.queryItems===item.name){
                selectedItem='';
            }
            if(this.props.itemType==='Planer' && currentQuery===''){
                currentQuery= '&item_type=Lesson&item_type=Resource&item_type=Book&item_type=Assessment';
                selectedItem='Assessments, Books, Resources, Lessons';
            }
       }
       this.setState({queryItems:selectedItem, stateQuery:item, queryString:currentQuery, pageNumber:1, selectedFilter:true}, ()=>{this.fetchFolders(this.state.searchString)});
    }

    fetchFolders(searchString,orderByDate,orderByName){
            let self=this;
            console.log("search string ",searchString);
            console.log("order by date ",orderByDate);
            console.log("order by name ",orderByName);
            self.setState({hasMore : false});
            if(self.state.selectedFilter===true){
                self.state.resources = [];
                self.setState({selectedFilter:false});
            }

            if((orderByDate != undefined && self.state.orderByDate != orderByDate) || (orderByName != undefined && self.state.orderByName != orderByName)){
               self.state.pageNumber = 1;
            }
            const resourceSearchPromise = authAPI.getFolders(self.state.pageNumber,searchString,self.state.queryString,localStorage.is_lite, orderByDate, orderByName);

            resourceSearchPromise.then((resp) => {
                
                if((orderByDate != undefined && self.state.orderByDate != orderByDate) || (orderByName != undefined && self.state.orderByName != orderByName)){
                    self.state.resources = [];
                    if(orderByDate !=undefined){
                        self.state.orderByDate = orderByDate;
                    }

                    if(orderByName !=undefined){
                        self.state.orderByName = orderByName;
                    }
                }
                
                console.log(resp);
                if(!resp){
                    let props = {
                            resources:[],
                            totalCount:0
                        };
                        self.state.resources=[];
                        self.state.totalCount=0;
                        self.setState(props);
                    return false;
                }
                if(searchString !== self.state.searchString){
                    self.state.resources = [];
                    self.state.pageNumber = 1;
                }
                self.state.searchString = searchString;
                self.state.totalCount = resp.count;
                if(resp.rows.length > 0){
                    self.setState({
                        respRows : true
                    })

                    resp.rows.map(function(item){
                        self.state.resources.push(item);
                    });
                    console.log("--");
                    console.log(self.state.resources);

                    if(self.state.orderByDate!= null  || self.state.orderByName != null){
                       console.log("i am here");
                       let sortedResources = self.state.resources.sort(function(a, b) {                       

                        // Use toUpperCase() to ignore character casing
                          const bandA = a.name.toUpperCase();
                          const bandB = b.name.toUpperCase();

                          let comparison = 0;
                          if (bandA > bandB) {
                            comparison = 1;
                          } else if (bandA < bandB) {
                            comparison = -1;
                          }
                          if((self.state.orderByDate!= null && self.state.orderByDate == 'DESC') || (self.state.orderByName!= null && self.state.orderByName == 'DESC')){
                            return comparison*-1;
                          }else{
                            return comparison;
                          }
                          
                        });

                       console.log("sorted resources");
                       self.state.resources = sortedResources;
                       let ids = self.state.resources.map(o => o.name)
                       let filtered = self.state.resources.filter(({name}, index) => !ids.includes(name, index + 1))
                       self.setState({
                        resources: filtered
                       })
                    }
                    
                }else{
                    self.setState({
                        respRows : false
                    })
                }
                

                let props = {
                            resources:self.state.resources,
                            totalCount:resp.count,
                            searchString:searchString
                        };
                        self.setState(props);
                if(!self.pageLoad){
                setTimeout(function(){
                    if(self.state.respRows && self.state.totalCount > self.state.resources.length){
                        self.setState({hasMore : true});
                    }else{
                        self.setState({hasMore : false});
                    }
                },500);
            }
            self.pageLoad = false;
            
            
            
        })
    }

    navigateToDetails=(folder)=>{
        browserHistory.push('/folders/'+folder._id);
    };

    searchFolders(e){
        let searchString = e.target.value;
        this.searchString = searchString;
        this.fetchFolders(searchString);
    }

    componentDidMount() {
        if(this.pageLoad){
            this.fetchFolders(this.state.searchString);
            this.pageLoad = false;
        }
    }

    _loadMore(){
        
        setTimeout(() => {
            console.log(this.state);
            if(this.state.respRows && this.state.totalCount > this.state.resources.length){
                this.state.pageNumber++;
                this.fetchFolders(this.state.searchString);
            }
            else{
                this.state.pageNumber = this.state.pageNumber >1 ? this.state.pageNumber-- : 1;
                this.fetchFolders(this.state.searchString);
            }
        },1000);

        if(this.pageLoad){
            this.fetchFolders(this.state.searchString);
            this.pageLoad = false;
        }
    }
    renderFilter = () => {
        if (localStorage.is_lite === 'true') {
            return (
                <div>
                   {/* <img src="icon-search-folder.png" /> */}
                   <i className='icon-blue icon-icon-search-circle-solid input-search-icon'></i>
                </div>
            )
        } else {
            return (
                <div style={{cursor: 'pointer'}} onClick={() => this.optionToggle()}>
                    <i className='icon-blue icon-icon-search-circle-solid input-search-icon'></i>
                    {/* <img src="../icon-search-folder.png" /> */}
                </div>
            )
        }
    }

    addfolderName = (event) => {
        this.state.form[event.target.name] = event.target.value;
        this.setState({form: this.state.form});
    }

    sortByNameOrder = (event)=>{
        console.log("called");
        console.log(event.target.dataset.order);
        let orderByName = event.target.dataset.order;
        this.setState({
            orderByDate : null,
            orderByName : orderByName
        })
        this.fetchFolders(this.searchString,'',orderByName);
        
        event.target.dataset.order = event.target.dataset.order == 'DESC' ? 'ASC' : 'DESC';
    }

    sortByDateOrder = (event)=>{
        console.log("called");
        console.log(event.target.dataset.order);
        let orderByDate = event.target.dataset.order;
        this.setState({
            orderByDate : orderByDate,
            orderByName : null
        })
        this.fetchFolders(this.searchString,orderByDate,'');
        event.target.dataset.order = event.target.dataset.order == 'DESC' ? 'ASC' : 'DESC';
    }

    showCreateFolderInput = () => {
        this.setState({
            createFolderInput: true
        });
    }

    createNewFolder = () => {
        if (this.validateInput()) {
            const postNewFolder = authAPI.postFolder({
                name: this.state.form.folderName
            });

            postNewFolder
                .then((response)=>{
                if(response.statusCode === 201 || response.statusCode === 200){
                    this.setState({
                        form: {
                            folderName:''
                        },
                        createFolderInput: false,
                        folderNameValid: "",
                        resources: [],
                        pageNumber: 1
                    });
                    this.fetchFolders(this.state.searchString);
                }
                else{
                    this.showError(response.message);
                }
            })
            .catch(function (error) {
                console.log("exception occurred");
            });
        } 
    }

    cancelPopUp=()=>{
        this.setState({deletePopUp:false})
    }

    deleteFolder = (id, name) => {
        this.setState({deletePopUp:true, folderId: id, folderName: name});
    }

    deleteConfirm = () => {
        const folderId = this.state.folderId;
        const deleteFolderData = authAPI.deleteFolders(folderId);
        deleteFolderData.then((response)=>{
            if(response.status === 201 || response.status === 200){
                this.state.resources = [];
                this.state.pageNumber = 1;
                this.fetchFolders(this.state.searchString);
                if(this.state.totalCount == 1){
                    //browserHistory.push('/library/');
                }
                let msg = response.data.message;
                this.showSuccess(msg);
                setTimeout(() => {
                    this.clearSuccess();
                }, 2000);
                this.setState({deletePopUp:false})
            }
            else{
                console.log("error occurred");
            }
        })
        .catch(function (error) {
            console.log("exception occurred");
        });
    }

    updateFolder = (folderId, index) => {
        const renameFolderData = authAPI.putFolders(folderId, this.state.form.folderName);
        renameFolderData.then((response) => {
            if(response.status === 201 || response.status === 200){
                this.state.resources = [];
                this.state.pageNumber = 1;
                this.fetchFolders(this.state.searchString);
                this.showRenameFolder(index);
                let msg = response.data.message;
                this.showSuccess(msg);
                setTimeout(() => {
                    this.clearSuccess();
                }, 2000);
            }else{
                console.log("error occurred");
            }
        })
        .catch((error) => {
            console.log('Unable to rename the folder. Please try again later', error);
        });
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    showSuccess(message) {
        this.setState({successMessage: message});
    }

    clearError() {
        if(this.state.errorMessage !== "") {
            this.setState({errorMessage: ""});
        }
    } 

    clearSuccess() {
        if(this.state.successMessage !== "") {
            this.setState({successMessage: ""});
        }
    }
    
    showRenameFolder = (index) => {
        if(!this.state.showRenameFolder[index]){
            this.setState({
                showRenameFolder : {
                    [index]: true
                },
                form: {
                    folderName: this.state.resources[index].name
                }
            });
        }else{
            this.setState({
                showRenameFolder : {
                    [index]: false
                },
                form: {
                    folderName: ''
                }
            });
        }
    }

    publishDATAToSAML = (folder_id) =>{
        //this.props.postSAMLData(folder_id,"folder");
        window.open("/schoolwidefun/api/v1/users/samlp?item_id="+folder_id+"&item_type=folder","_blank")
    };
    
    redirectXanedu=()=>{
        //window.open('https://www.xanedu.com','_blank');
        window.open('https://my.sharedbook.com/serve/nm/schoolwide/sso/acs.html','_blank');
    }

     render(){
        let height = window.innerHeight;
        const searchItems= localStorage.is_lite === 'true' ? ['Resource'] : ['Assessment','Resource','Book','Lesson','Unit'];
        return(
            <div>
                {this.state.deletePopUp &&
                    <CreateFolderDialog onCancel={this.cancelPopUp} onOk={this.deleteConfirm} title="DELETE GROUP" okText="DELETE" okStyle="dialog-delete-button">
                        <div className="dialog-spacer">
                            Are you sure you want to delete <b>{this.state.folderName}</b>?
                        </div>
                    </CreateFolderDialog>
                }
                <div  className='global-search-component'>
                    <div className='global-folder-header'>MY FOLDERS</div>
                    <div className='global-searchbar-textbox'>
                        {localStorage.user_type !== "Student" && this.renderFilter()}
                        <span 
                            className="create-folder-icon"
                            onClick={this.showCreateFolderInput}    
                        >
                            <i className="icon-icon-add-circle-solid" />
                            Create a new folder
                        </span>
                        <input
                            type='text'
                            ref={(e)=> this.gscInput = e}
                            className='global-unit-searchbar'
                            onChange={this.searchFolders.bind(this)}
                        />
                    </div>
                    {this.state.createFolderInput &&
                        <div className="create-new-folder-input global-searchbar-textbox">
                            <TextBox 
                                type='text'
                                placeholder='Enter Folder Name'
                                name='folderName'
                                errorValue={this.state.folderNameValid}
                                onChange={this.addfolderName}
                            />
                            <i className='icon-icon-check' onClick={this.createNewFolder}></i>
                        </div>
                    }

                    {this.state.errorMessage !== "" &&
                        <Row style={{paddingLeft: '15px', paddingRight: '15px'}}>
                            <div className="add-user-error">
                                {this.state.errorMessage}
                            </div>
                        </Row>
                    }
                    {this.state.successMessage !== "" &&
                        <Row style={{paddingLeft: '15px', paddingRight: '15px'}}>
                            <div className="add-user-success delete-folder-success text-center">
                                {this.state.successMessage}
                            </div>
                        </Row>
                    }
                    <div className='search-result-count'>
                        {this.state.totalCount?this.state.totalCount+' RESULTS':'NO RESULTS'}
                        <span className="order-by-az folderFilter" data-order="DESC" onClick={this.sortByNameOrder}>Name</span>
                        <span className="order-by-date folderFilter" data-order="DESC" onClick={this.sortByDateOrder}>Date</span> |
                        <label className="sortLabel">Sort By </label>&nbsp;
                    </div>
                    <div className='global-lesson-search-results folder-result-display' style={{height:(height-250)+'px', overflowY: 'scroll'}}>
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={this._loadMore.bind(this)}
                            hasMore={this.state.hasMore}
                            loader={<div className="loader" style={{textAlign:'center'}}>Loading ...</div>}
                            useWindow={false}
                        >
                            <div>
                                {this.state.resources && this.state.resources.map((folder,i)=>(
                                    <Row key={folder+i} bsClass='global-search-row'>
                                        <Media>
                                            <Media.Left align="middle" className="col-md-7 folder-actions">

                                                {
                                                    this.state.showRenameFolder[i] &&
                                                    <div className="editFolderContainer">
                                                    <TextBox
                                                        type='text'
                                                        value={this.state.form.folderName}
                                                        placeholder='Enter Folder Name'
                                                        name='folderName'
                                                        errorValue={this.state.folderNameValid}
                                                        onChange={this.addfolderName}
                                                    />
                                                    <i className='icon-icon-check' onClick={() => this.updateFolder(folder._id, i)}></i>
                                                    <i className='icon-icon-close-circle-solid' onClick={()=>this.showRenameFolder(i)}></i>
                                                    </div>
                                                    ||
                                                    <div className='global-search-result-text' onClick={()=>this.navigateToDetails(folder)}>
                                                        {folder.name}
                                                    </div>
                                                }
                                            </Media.Left>
                                            {
                                                !this.state.showRenameFolder[i] &&
                                                <Media.Right align="middle" className="folder-actions col-md-5 text-right">
                                                    <div className={'inline-icons'} onClick={()=>this.publishDATAToSAML(folder._id)}><img src={pubIcon} width='23' height='10' style={{cursor:'pointer'}}/></div>
                                                    <div className="icon-icon-pen-circle-solid inline-icons" onClick={()=>this.showRenameFolder(i)}></div>
                                                    <div className={'icon-trash-fadded-icon icon-icon-trash inline-icons'} onClick={() => this.deleteFolder(folder._id, folder.name)}/>
                                                </Media.Right>
                                            }
                                        </Media>
                                    </Row>
                                ))}
                            </div>
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null,actions)(FolderCreationComponent)
