import React, { Component } from 'react'
import {Row, Col, Dropdown, Glyphicon, MenuItem, ButtonGroup} from 'react-bootstrap'
import StrandButton from '../../components/Strand/StrandButton'
import constants from '../../constants'
import '../../assets/styles/mobile.scss'

const invertMap=["Featured","Reading","Writing","Language","Speaking/Listening"];

class ResponsiveNavBtns extends Component {
  render(){
    return (
      <div>
        <Col xsHidden smHidden>
          <Row>
            <Col xsHidden smHidden>
            <ButtonGroup id='nav-buttons'>
              {this.props.btnOptions && this.props.btnOptions.map( (strand, i) => (
                <StrandButton
                    key={`nav-btn-desktop-${i}`}
                    value={i}
                    name={invertMap[i]}
                    selected={this.props.selectedStrand===strand}
                    path={`/browse/assessments/${strand}`}
                    />
                  )
                )
              }
              </ButtonGroup>
            </Col>
          </Row>
        </Col>

        <Col id="nav-button-alternative" xs={12} mdHidden lgHidden  style={{display: 'block', width: '100%'}} >
          <Dropdown style={{display: 'block', position: 'fixed', bottom: '70px', width: '100%', left: 0, right: 0}} dropup id="strand-btn-responsive">
            <Dropdown.Toggle className="dropdown-toggle-styles">
              <span style={{float: 'left', fontWeight: 'bold', fontFamily: 'ProximaNova', lineHeight: '20px'}}>{this.props.displayableSelectedStrand.toUpperCase()}</span>
              {/*<span style={{float: 'right', marginTop: '8px'}} id="force-display-caret" className="caret"/>*/}
              <span style={{float: 'right', paddingRight: '10px', fontSize: '9px', lineHeight: '20px'}} className="icon-icon-chevron-up"/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.props.btnOptions && this.props.btnOptions.map( (strand, i) => (
                <MenuItem
                    eventKey={strand}
                    key={`nav-btn-${i}`}
                    onSelect={this.props.onSelectMenuItem}>
                    <StrandButton
                        value={i}
                        name={invertMap[i]}
                        selected={this.props.selectedStrand===strand}
                        path={`/browse/assessments/${strand}`}
                        className="full-width-btn"/>
                </MenuItem>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </div>
  )
  }
}

export default ResponsiveNavBtns
