import React from "react";
import Snackbar from "./Snackbar";

export const SnackBarModule = ({
  isErrorOpen,
  declineFolderSuccess,
  declineFolderError,
  deleteFromFolderSuccess,
  deleteFromFolderError,
  createFolderSuccess,
  createFolderError,
  addToLibrarySuccess,
  addToLibraryError,
  removeFromLibrarySuccess,
  removeFromLibraryError,
  addToFolderSuccess,
  addToFolderError,
  addToFolderErrorMsg,
  sendEmailSuccess,
  sendEmailError,
  renameFolderSuccess,
  renameFolderError,
  folderNameExistsError,
}) => {
  return (
    <>
      {addToLibrarySuccess && (
        <Snackbar
          message="Successfully Added To Library"
          open={addToLibrarySuccess}
          isSuccess={true}
        />
      )}

      {addToLibraryError && (
        <Snackbar
          message="Error While Adding To Library"
          open={addToLibraryError}
          isError={true}
        />
      )}

      {removeFromLibrarySuccess && (
        <Snackbar
          message="Successfully Removed From Library"
          open={removeFromLibrarySuccess}
          isSuccess={true}
        />
      )}

      {removeFromLibraryError && (
        <Snackbar
          message="Error While Removing From Library"
          open={removeFromLibraryError}
          isError={true}
        />
      )}

      {declineFolderSuccess && (
        <Snackbar
          message="Folder Successfully Declined"
          open={declineFolderSuccess}
          isSuccess={true}
        />
      )}

      {declineFolderError && (
        <Snackbar
          message="Error While Declining Folder"
          open={declineFolderError}
          isError={true}
        />
      )}

      {renameFolderSuccess && (
        <Snackbar
          message="Folder Successfully Renamed"
          open={renameFolderSuccess}
          isSuccess={true}
        />
      )}

      {renameFolderError && (
        <Snackbar
          message="Error While Renaming Folder"
          open={renameFolderError}
          isError={true}
        />
      )}

      {createFolderSuccess && (
        <Snackbar
          message="Successfully Created Folder"
          open={createFolderSuccess}
          isSuccess={true}
        />
      )}

      {createFolderError && (
        <Snackbar
          message="Error While Creating Folder"
          open={createFolderError}
          isError={true}
        />
      )}

      {addToFolderSuccess && (
        <Snackbar
          message="Successfully Added To Folder"
          open={addToFolderSuccess}
          isSuccess={true}
        />
      )}

      {addToFolderError && (
        <Snackbar
          message={
            addToFolderErrorMsg
              ? addToFolderErrorMsg
              : "This item is already in your folder"
          }
          open={addToFolderError}
          isError={true}
        />
      )}

      {deleteFromFolderSuccess && (
        <Snackbar
          message="Successfully Removed From Folder"
          open={deleteFromFolderSuccess}
          isSuccess={true}
        />
      )}

      {deleteFromFolderError && (
        <Snackbar
          message="Error While Removing From Folder"
          open={deleteFromFolderError}
          isError={true}
        />
      )}

      {folderNameExistsError && (
        <Snackbar
          message="Error Folder Already Exists"
          open={folderNameExistsError}
          isError={true}
        />
      )}

      {sendEmailSuccess && (
        <Snackbar
          message="Email Sent Successfully"
          open={sendEmailSuccess}
          isSuccess={true}
        />
      )}

      {sendEmailError && (
        <Snackbar
          message="Error While Sending Email"
          open={sendEmailError}
          isError={true}
        />
      )}

      {isErrorOpen && (
        <Snackbar
          message="This item is already in your Library"
          open={isErrorOpen}
          isError={true}
        />
      )}
    </>
  );
};
