import React,{Component} from  'react';
import constants from '../../constants/index';
import { gapi } from 'gapi-script';
import { markdownHelper } from '../../markdown/MarkdownHelper';
import { annotationsHelper } from '../../annotations/AnnotationsHelper';
import authAPI from '../../api/authAPI';
import shareGoogleClassroom from '../../assets/images/sharegoogleclassroom.png';
import iconReaderInvert from '../../assets/icons/SVG/icon-reader-invert.svg';
require('../../assets/styles/slideOut.scss');
require('../../assets/styles/lessonsDetailedView.scss');

class ImageDetailsComponent extends Component{

    openFile = (url)=>{
      window.open("../../../schoolwidefun/api/v1/files/" + url);
    };


    classroomShare = (file) =>{
        let videoLessonUrl = '';
        if(file !='' && file != ''){
            authAPI.validateClassromFileLink(file).then((resp)=>{
                if(resp.data !== ""){
                  videoLessonUrl = resp.data; 
                  window.open("https://classroom.google.com/share?url="+window.location.hostname+ constants.filesUrl +videoLessonUrl) 
                }
            })    
        }
           
    }

    render(){
        //console.log('this.props.componentData',this.props.componentData);
        let linkBase = "/";
        let self = this;
        console.log(this.props.markdownHelper);
        console.log(this.props.annotationsHelper);
        if (this.props.componentType === "units") {
            linkBase += "assessments/";
        }
        else if (this.props.componentType === "lessons") {
            linkBase += "resources/";
        }
        else if (this.props.componentType === "appendicies") {
            linkBase += "appendices/";
        }

        return(
             <div>
              {this.props.componentData? "" :
                  <div className='lesson-text-padding'> {this.props.componentType === "units"?'There are no assessments in this unit.':'No Data Found'}</div>
              }
              {this.props.componentData && this.props.componentData[0] && this.props.componentData.map((assessmentsArray,i)=>(
                    <div className='assessment-flex' key={i}>
                        <div className='assessment-image-content'>
                            {this.props.componentType !== 'files' && this.props.componentType !== 'appendicies' &&
                                <a href={linkBase + assessmentsArray._id}>
                                    <img src={constants.filesUrl + (assessmentsArray.image || '')} className="assessment-image"/>
                                </a>
                            }
                             { this.props.componentType === 'appendicies' &&
                                <a href={linkBase + assessmentsArray._id + '/unit_id/' + this.props.unit_id}>
                                    <img src={constants.filesUrl + (assessmentsArray.image?.replace("../",'') || '')} className="assessment-image"/>
                                </a>
                            }
                            {this.props.componentType === 'files' &&
                                <img onClick={()=>this.openFile(assessmentsArray.file)} src={constants.filesUrl + (assessmentsArray.image?.replace("../",'') || '')} className="assessment-image"/>
                            }
                            {this.props.componentType === 'files' &&
                              <img onClick={()=>this.openFile(assessmentsArray.file)} src={iconReaderInvert} className="books-detail-zing-overlay-icon-small"/>
                            }
                            {/*{this.props.componentType === 'appendicies' &&
                                <img src={constants.filesUrl + (assessmentsArray.image || '')} className="assessment-image"/>
                            }*/}
                        </div>
                        <div className='assessment-description' style={{borderBottomColor : this.props.componentData.length-1===i?'rgba(1,1,1,0)': '#CCD2DE'}}>
                            <div className='assessment-flex-style'>
                                <div className='assessment-flex-heading'>
                                    <div className='assessment-name-content'>
                                        {this.props.componentType !== 'files' && this.props.componentType !== 'appendicies' &&
                                            <a href={linkBase + assessmentsArray._id} className="detail-link">
                                                {assessmentsArray.name ? assessmentsArray.name : null}
                                            </a>
                                        }
                                        {this.props.componentType === 'appendicies' &&
                                            // <p>{assessmentsArray.name ? assessmentsArray.name : null}</p>
                                            <a href={linkBase + assessmentsArray._id + '/unit_id/' + this.props.unit_id} className="detail-link">
                                                {assessmentsArray.name ? assessmentsArray.name : null}
                                            </a>
                                        }
                                    </div>
                                    {this.props.componentType !== 'files' && this.props.componentType !== 'appendicies' &&
                                        <div className='assessment-sub-name'>
                                            <a href={linkBase + assessmentsArray._id} className="detail-link">
                                                {assessmentsArray.description && (
                                                     this.props.componentType === 'units' ? (
                                                    assessmentsArray.description.description ? <span dangerouslySetInnerHTML={{__html: annotationsHelper.annotate(markdownHelper.toHTML(assessmentsArray.description.description))}}></span> : null
                                                    ) : (
                                                        assessmentsArray.description.text ? <span dangerouslySetInnerHTML={{__html: annotationsHelper.annotate(markdownHelper.toHTML(assessmentsArray.description.text))}}></span> : null
                                                    )
                                                  )
                                                }
                                            </a>
                                            
                                        </div>
                                    }
                                    {this.props.componentType === 'appendicies' &&
                                        <div className='assessment-sub-name'>
                                            <a href={linkBase + assessmentsArray._id + '/unit_id/' + this.props.unit_id} className="detail-link">
                                                {assessmentsArray.description && (
                                                     this.props.componentType === 'units' ? (
                                                    assessmentsArray.description.description ? <span dangerouslySetInnerHTML={{__html: annotationsHelper.annotate(markdownHelper.toHTML(assessmentsArray.description.description))}}></span> : null
                                                    ) : (
                                                        assessmentsArray.description.text ? <span dangerouslySetInnerHTML={{__html: annotationsHelper.annotate(markdownHelper.toHTML(assessmentsArray.description.text))}}></span> : null
                                                    )
                                                  )
                                                }
                                            </a>
                                            
                                        </div>
                                    }
                                    {
                                      /*  This was the "Open File" button on assessments.
                                        this.props.componentType === 'files' &&
                                        <a href={"../../../schoolwidefun/api/v1/files/" + assessmentsArray.file} target="_blank">
                                            <button className='open-file-button'>OPEN FILE</button>
                                        </a>
                                        */
                                    }
                                    
                                </div>
                                <div className='assessment-flex-sub-heading'>
                                    {this.props.componentType !== 'files' && this.props.componentType !== 'appendicies' &&
                                        <a href={linkBase + assessmentsArray._id}>
                                            <div className="view-icon-in-text icon--view"/>
                                        </a>
                                    }
                                    {this.props.componentType === 'appendicies' &&
                                     <a href={linkBase + assessmentsArray._id + '/unit_id/' + this.props.unit_id}>
                                        <div className="view-icon-in-text icon--view"/>
                                      </a>  
                                    }
                                </div>
                            </div>
                            
                            {i>0 && this.props.requestType == "unitAssessments" && assessmentsArray.file != '' && assessmentsArray.file!= null &&
                                <div style={{minWidth:"100%","display":"flex"}}>
                                    <p style={{"cursor":"pointer","textAlign": "center","margin": "7px 0px"}} onClick={()=>this.classroomShare(assessmentsArray.file)}>
                                      <img src={shareGoogleClassroom} height="42"/>
                                    </p>
                                </div>
                            }
                        </div>
                        
                    </div>
                ))}
             </div>
        );
    }}

export default ImageDetailsComponent
