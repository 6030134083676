import React, {Component} from 'react';
import folderIcon from '../../assets/icons/SVG/icon-folder-common.svg'

class AddToFolderSingleItem extends Component {
    render() {
        return (
                <div
                    onClick={ (e) => {
                        e.stopPropagation();
                        this.props.handleActive(this.props.id)
                    }}
                    className={this.props.isLast ? 'add-to-folder-modal-single-item add-to-folder-modal-single-item-last' : 'add-to-folder-modal-single-item'}
                    style={this.props.isSelected ? {backgroundColor:"#33aeca", color:"white"} : {}}
                    key={this.props.id}>
                        <p style={{display:"flex", alignItems:"center", justifyContent:"center", margin:0}}>
                            <span><img src={folderIcon}  style={{paddingRight:"8px"}} alt="Folder Icon"/></span>{this.props.title}
                        </p>
                        <p>Edited: {this.props.edited.substring(0, 10)}</p>
                </div>
        );
    }
}

export default AddToFolderSingleItem;