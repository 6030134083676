import React,{Component} from 'react'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import DetailedHeader from '../../containers/Details/DetailedHeader'
import DetailedDescription from '../../containers/Details/DetailedDescription'
import ResourcesOverview from './ResourcesOverview'

class ResourcesDetailedView extends Component{

    constructor (props) {
        super(props);
        this.state = {
            selectedTab: "SUMMARY",
            infoClicked: window.innerWidth < 768 ? false : true,
            searchClicked: false,
            load: "initial",
            status:"",
            data: {
                id:"",
                image: "",
                name: "",
                description: "",
                grades: "",
                resourceType: "",
                skills: [],
                file: "",
                google_form_link:"",
                printable: false
            }
        };
        this.selectTab = this.selectTab.bind(this);
        this.onInfoClick = this.onInfoClick.bind(this);
    }

    componentDidMount() {
        this.props.getResourceDetailsAction(this.props.params.uid);
    }

    componentWillReceiveProps(next) {
        if (next.resourceDetails) {
            if (next.resourceDetails.asyncStatus === "PENDING") {
                this.setState({status: "PENDING"});
            }
            else if (next.resourceDetails.asyncStatus === "FAILURE") {
                this.setState({status: "FAILURE"});
            }
            else if (next.resourceDetails.data.status === "RESOURCE NOT FOUND") {
                this.setState({status: "RESOURCE NOT FOUND"});
            }
            else if (next.resourceDetails.asyncStatus === "SUCCESS") {
                console.log(next.resourceDetails.data);
                this.setState({
                    data: {
                        id: next.resourceDetails.data._id,
                        name: next.resourceDetails.data.name,
                        description: next.resourceDetails.data.description != null ?  next.resourceDetails.data.description.text : '',
                        image: next.resourceDetails.data.image,
                        resourceType: next.resourceDetails.data.type,
                        file: next.resourceDetails.data.file,
                        skills: next.resourceDetails.data.skills,
                        publish: next.resourceDetails.data.publish,
                        printable: next.resourceDetails.data.printable || false,
                        printable_file: next.resourceDetails.data.file,
                        google_form_link: next.resourceDetails.data.google_form_link,
                        showClassroomShareButtonLink: next.resourceDetails.data.showClassroomShareButtonLink
                    }
                })
                

                let tempGrades = "";
                for(let i = 0; i<next.resourceDetails.data.grades.length; i++) {
                    if (i === 0) {
                        tempGrades += next.resourceDetails.data.grades[i];
                    }
                    else {
                        tempGrades += ", " + next.resourceDetails.data.grades[i];
                    }
                }
                this.state.data.grades = tempGrades;

                this.setState({status: "SUCCESS"});
            }
        }
        if(next.params.uid!==this.props.params.uid){
            this.props.getResourceDetailsAction(next.params.uid);
        }
    }

    onInfoClick(infoClicked = !this.state.infoClicked){
        if(infoClicked) {
            this.onSearchClick(false);
        }
        return new Promise((resolve, reject) => {
            this.setState({infoClicked: infoClicked, load: ""}, () => {
                resolve();
            });
        });

    }

    onSearchClick=(searchClicked = !this.state.searchClicked)=>{
        if(searchClicked) {
            this.onInfoClick(false);
        }
        return new Promise((resolve, reject) => {
            this.setState({searchClicked: searchClicked, load: ""}, () => {
                resolve();
            });
        });
    };

    selectTab (tab) {

        this.setState({selectedTab: tab});
    }

    render(){
        return(
            <div className="detail-wrapper">
                <DetailedHeader file={this.state.data.file} publish={this.state.data.publish} name={this.state.data.name} onSearchClick={this.onSearchClick} infoClick={this.onInfoClick} itemType="Resource" itemId={this.state.data.id} infoClicked={this.state.infoClicked} searchClicked={this.state.searchClicked}/>
                <div className={((this.state.infoClicked || this.state.searchClicked)?"some-area fill-height-or-more":"fill-height-or-more some-area-without-info")}>
                    <div className="detail-holder">
                        {this.state.status === "PENDING" &&
                            <div style={{paddingTop: "10px", textAlign: "center"}}>Loading....</div>
                        }
                        {this.state.status  === "FAILURE" &&
                            <div style={{paddingTop: "10px", textAlign: "center"}}>Error Loading Data</div>
                        }
                        {this.state.status  === "RESOURCE NOT FOUND" &&
                            <div style={{paddingTop: "10px", textAlign: "center"}}>This resource is not available</div>
                        }
                        {this.state.status === "SUCCESS" &&
                            <ResourcesOverview description={{text: this.state.data.description}} grades={this.state.data.grades} resourceType={this.state.data.resourceType} skills={this.state.data.skills}/>
                        }
                    </div>
                    <div>
                        <DetailedDescription 
                            name={this.state.data.name} 
                            image={this.state.data.image} 
                            file={this.state.data.file} 
                            printable={this.state.data.printable} 
                            printableFile={this.state.data.printable_file} 
                            googleFormLink={this.state.data.google_form_link} 
                            detailType='resources' 
                            resourceType={this.state.data.resourceType}
                            showClassroomShareButtonLink={this.state.data.showClassroomShareButtonLink} 
                        />
                    </div>
                </div>
            </div>
        );
    };

}

const mapStateToProps = (state) => {
    return ({
        resourceDetails : state.ResourceDetails
    });
};

export default connect(mapStateToProps, actions)(withRouter(ResourcesDetailedView))
