import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import ResourceHeading from "../components/ResourceHeading";
import * as actions from "../redux/actions";
import authAPI from "../api/authAPI";
import constants from "../constants";
import NewHeader from "./NewHeader";
import BrowsePageContent from "./shared/BrowsePageContent";
import _ from "lodash";
import ResponsiveResourcesNew from "./TeachingResources/ResponsiveResourcesNew";
import ResponsiveNavBtns from "./TeachingResources/ResponsiveNavBtns";
import "../assets/styles/mobile.scss";

const invertMap = [
  "Featured",
  "Reading",
  "Writing",
  "Speaking & Listening",
  "Language",
];

class LibrarySingleFolderContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFolder: false,
      folderData: [],
      resouceData: [],
      resources: [],
      resourceDetails: "",
      heading: "",
      multipleItemsSelected: false,
      multipleItems: [],
      searchValue: "",
    };
    window.sessionStorage.setItem("selectedFilters", JSON.stringify([]));
  }

  componentWillMount() {
    this.setState({
      folderData: [],
      resouceData: [],
    });
    if (this.props.params.id) {
      this.fetchAllItems(this.props.params.id);
    } else {
      browserHistory.push("/library");
    }
  }

  componentWillReceiveProps(next) {
    window.onpopstate = (e) => {
      this.setState({
        isBackButtonPressed: true,
      });
    };
  }

  fetchAllItems(folder_id) {
    authAPI
      .getItemsInFolder(folder_id)
      .then((response) => {
        if (response.statusCode === 404) {
          this.setState({ heading: response.statusText });
        } else {
          this.fetchAllItemsDetails(response.resource_ids);
          this.setState({
            folderData: response,
            resouceData: response.resource_ids,
          });
          let totalResource = this.state.resouceData
            ? this.state.resouceData.length
            : "0";
          let heading = this.state.folderData.name + " (" + totalResource + ")";
          this.setState({ heading: heading });
        }
      })
      .catch((err) => {
        console.log("ERR: ", err);
      });
  }

  async fetchAllItemsDetails(resourcesList) {
    const resourceTypeMap = {
      Assessment: authAPI.getAssessmentDetailsForFolder,
      Assessments: authAPI.getAssessmentDetailsForFolder,
      assessment: authAPI.getAssessmentDetailsForFolder,
      assessments: authAPI.getAssessmentDetailsForFolder,
      Resource: authAPI.getResourceDetailsForFolder,
      teaching_resource: authAPI.getResourceDetailsForFolder,
      Unit: authAPI.getUnitsDetailForFolder,
      Book: authAPI.getBookDetailsForFolder,
      books: authAPI.getBookDetailsForFolder,
      Lessons: authAPI.getLessonsDetailForFolder,
      Lesson: authAPI.getLessonsDetailForFolder,
      Appendecies: authAPI.getResourceDetailsForFolder, // Corrected spelling of "Appendices"
    };

    const promises = resourcesList.map(async (resource) => {
      const apiFunction = resourceTypeMap[resource.type];
      if (apiFunction) {
        try {
          const response = await apiFunction(resource.id);
          let updatedResource = { ...response, type: resource.type };
          if (resource.type === "Unit") {
            updatedResource._id = response?.unit_id;
          } else if (resource.type === "Book") {
            updatedResource._id = resource?.id;
          }
          return updatedResource;
        } catch (error) {
          console.error(
            `Error fetching details for ${resource.type}: ${error.message}`
          );
          return null;
        }
      } else {
        console.error(
          `API function not found for resource type: ${resource.type}`
        );
        return null;
      }
    });

    const results = await Promise.all(promises);
    this.setState({
      resources: this.state.resources.concat(results.filter(Boolean)),
    });
  }

  refreshResources = () => {
    this.setState({
      folderData: [],
      resouceData: [],
      resources: [],
      resourceDetails: "",
    });
    this.fetchAllItems(this.props.params.id);
  };

  millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  setLibrarySearchValue = (searchValue) => {
    this.setState({ searchValue });
  };

  _loadMore() {
    setTimeout(() => {
      if (this.state.total > this.state?.data?.length) {
        let strandInfinite = constants.caseMap[this.props?.params?.strand],
          filtersSet =
            this.props.finalFilters &&
            this.props.finalFilters.filterList &&
            this.props.finalFilters.filterList.length > 0,
          eitherFiltersSetOrQueryEmpty =
            (filtersSet && this.state.queryValue !== "") ||
            (!filtersSet && this.state.queryValue === "");

        if (
          this.props?.params?.strand !== "featured" &&
          eitherFiltersSetOrQueryEmpty
        ) {
          authAPI
            .getResources(
              this.state.pageNumber + 1,
              strandInfinite,
              this.state.queryValue
            )
            .then((resp) => {
              if (resp.total && resp?.results) {
                this.setState({
                  total: resp.total,
                  data: _.concat(this.state.data, resp.results),
                  pageNumber: Number(resp.currentPage),
                  loadingMore: false,
                  filter: false,
                });
              }
            });
        }
      } else {
        this.setState({
          loadingMore: false,
          hasMore: false,
        });
      }
    }, 1000);
  }

  addMultipleItems = (tid, type = {}) => {
    if (type === "Resource") {
      this.state.multipleItems["Resource"].push(tid);
    } else if (type === "Correlations") {
      this.state.multipleItems["Correlations"].push(tid);
    } else {
      this.state.multipleItems["Lesson"].push(tid);
    }
    this.setState({ multipleItems: this.state.multipleItems });
  };

  deleteItem = (tid, type = "") => {
    if (type === "Resource") {
      let idx = this.state.multipleItems["Resource"].indexOf(tid);
      this.state.multipleItems["Resource"].splice(idx, 1);
    } else if (type === "Correlations") {
      let idx = this.state.multipleItems["Correlations"].indexOf(tid);
      this.state.multipleItems["Correlations"].splice(idx, 1);
    } else {
      let idx = this.state.multipleItems["Lesson"].indexOf(tid);
      this.state.multipleItems["Lesson"].splice(idx, 1);
    }

    this.setState({ multipleItems: this.state.multipleItems });
  };

  addMultipleItemsToLibrary = () => {
    for (let i = 0; i < this.state.multipleItems["Resource"].length; i++) {
      this.props
        .postAddTeachingResourceToLibraryAction(
          this.state.multipleItems["Resource"][i]
        )

        .then((response) => {
          if (response.statusCode === 200) {
            console.log(
              "Item added to library",
              this.state.multipleItems["Resource"][i]
            );
          } else {
            console.log(
              "Error ! Item not added to library",
              this.state.multipleItems["Resource"][i]
            );
          }
        })
        .catch(function (error) {
          console.log("Exception! Item not added to library");
        });
    }
    for (let i = 0; i < this.state.multipleItems["Lesson"].length; i++) {
      this.props
        .postAddLessonToLibraryAction(this.state.multipleItems["Lesson"][i])

        .then((response) => {
          if (response.statusCode === 200) {
            console.log(
              "Item added to library",
              this.state.multipleItems["Lesson"][i]
            );
          } else {
            console.log(
              "Error ! Item not added to library",
              this.state.multipleItems["Lesson"][i]
            );
          }
        })
        .catch(function (error) {
          console.log("Exception! Item not added to library");
        });
    }
    for (let i = 0; i < this.state.multipleItems["Correlations"].length; i++) {
      this.props
        .postAddCorrelationToLibraryAction(
          this.state.multipleItems["Correlations"][i]
        )

        .then((response) => {
          if (response.statusCode === 200) {
            console.log(
              "Item added to library",
              this.state.multipleItems["Correlations"][i]
            );
          } else {
            console.log(
              "Error ! Item not added to library",
              this.state.multipleItems["Correlations"][i]
            );
          }
        })
        .catch(function (error) {
          console.log("Exception! Item not added to library");
        });
    }
  };

  addMultipleItemsToFolder = () => {
    let items = [];
    let itemResources = [];
    for (let i = 0; i < this.state.multipleItems["Resource"].length; i++) {
      items.push(this.state.multipleItems["Resource"][i]);
      itemResources.push("Resource");
    }
    for (let i = 0; i < this.state.multipleItems["Lesson"].length; i++) {
      items.push(this.state.multipleItems["Lesson"][i]);
      itemResources.push("Lesson");
    }
    for (let i = 0; i < this.state.multipleItems["Correlations"].length; i++) {
      items.push(this.state.multipleItems["Correlations"][i]);
      itemResources.push("Correlations");
    }

    browserHistory.push({
      pathname: "/library/addfolder",
      state: {
        libraryItemId: items,
        libraryItemType: itemResources,
        isLibrary: false,
      },
    });
    this.props.saveAssignmentItemAction(itemResources, items);
  };

  handleAddMultiple = function (desktop) {
    this.addMultipleItemsToLibrary();
    this.setState(
      {
        multiSelectMode: !this.state.multiSelectMode,
        addedMultipleItemsToLibrary: true,
      },
      () => {
        if (desktop)
          setTimeout(
            () =>
              this.setState({
                addedMultipleItemsToLibrary: false,
                multipleItems: {
                  Resource: [],
                  Lesson: [],
                  Correlations: [],
                },
              }),
            3000
          );
      }
    );
  };

  handleAddMultipleToFolder = function (desktop) {
    this.addMultipleItemsToFolder();
    this.setState(
      {
        multiSelectMode: !this.state.multiSelectMode,
        addedMultipleItemsToFolder: true,
      },
      () => {
        if (desktop)
          setTimeout(
            () =>
              this.setState({
                addedMultipleItemsToFolder: false,
                multipleItems: {
                  Resource: [],
                  Lesson: [],
                  Correlations: [],
                },
              }),
            3000
          );
      }
    );
  };

  onSelectMenuItem = (ekey) => {
    browserHistory.replace(`/browse/teaching-resources/${ekey}`);
  };

  //This function expects to be bound in a child component's onClick
  handleClickForResourcesThumbnail = function (item) {
    if (this.props?.multiSelectMode) {
      if (
        (item.type === "Resource" &&
          this.props.multipleItems["Resource"].indexOf(item._id) === -1) ||
        (item.type === "Lesson" &&
          this.props.multipleItems["Lesson"].indexOf(item._id) === -1)
      ) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        item.type !== "Correlations" &&
          this.props.deleteItem(item._id, item.type);
      }
    } else {
      let id = item?._id ?? item?.id;
      switch (item.type) {
        case "Resource":
          browserHistory.push("/resources/" + id);
          return;
        case "Unit":
          browserHistory.push("/units/" + id);
          return;
        case "Assessment":
          browserHistory.push("/assessments/" + id);
          return;
        case "Lessons":
          browserHistory.push("/lessons/" + id);
          return;
        case "Appendices":
          browserHistory.push("/resources/" + id);
          return;
        default:
          browserHistory.push("/books/" + id);
          return;
      }
    }
  };

  //This function expects to be bound in a child component's onClick
  handleClickForResourcesAddBtn = function (item) {
    if (this.props.multiSelectMode) {
      if (
        (item.type === "Resource" &&
          this.props.multipleItems["Resource"].indexOf(item._id) === -1) ||
        (item.type === "Lesson" &&
          this.props.multipleItems["Lesson"].indexOf(item._id) === -1) ||
        (item.type === "Correlations" &&
          this.props.multipleItems["Correlations"].indexOf(item._id) === -1)
      ) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        this.props.deleteItem(item._id, item.type);
      }
    } else {
      //This replaces "showTooltip" in popover.
      this.setState({
        isTooltipActive: true,
        success: false,
        showDatePicker: false,
      });
    }
  };

  handleSelectResourceClick = (item) => {
    console.log(
      "%chandleSelectResourceClick: ",
      "color: green; background: yellow; font-size: 24px",
      item?.type
    );
    this.setState({ resourceDetails: item });
  };

  updateFromSearch = (data, total) => {
    this.setState({
      total: total ?? data?.length ?? 0,
      data: data ?? [],
      pageNumber: 1,
      filter: false,
      hasMore: false,
    });
  };

  getResources = () => {
    const startE = new Date().getTime();
    const resources = this.state?.resources;
    return resources?.length >= 0
      ? resources.map((resource, i) => (
          <ResponsiveResourcesNew
            item={resource}
            i={i}
            key={i}
            handleClickForResourcesThumbnail={
              this.handleClickForResourcesThumbnail
            }
            handleClickForResourcesAddBtn={this.handleClickForResourcesAddBtn}
            handleSelectResourceClick={this.handleSelectResourceClick}
            multiSelectMode={this.state.multiSelectMode}
            addMultipleItems={this.addMultipleItems}
            deleteItem={this.deleteItem}
            multipleItems={this.state.multipleItems}
            scheduleTask={this.props.postScheduledTasksAction}
            addToLibrary={
              resource.type === "Resource"
                ? this.props.postAddTeachingResourceToLibraryAction
                : resource.type === "Correlations"
                ? this.props.postAddCorrelationToLibraryAction
                : this.props.postAddLessonToLibraryAction
            }
            addLessonToLibrary={this.props.postAddLessonToLibraryAction}
            startE={startE}
            active={
              parseInt(resource._id) ===
                parseInt(this.state.selectedResourceId) ||
              parseInt(resource.unit_id) ===
                parseInt(this.state.selectedResourceId) ||
              resource.name === this.state.selectedResourceName
            }
            type={resource.type ?? "library"}
            folderId={this.props.params.id}
            isFolder={true}
            refreshResources={this.refreshResources}
          />
        ))
      : [];
  };

  render() {
    const navButtons = (
      <ResponsiveNavBtns
        displayableSelectedStrand={
          invertMap[constants.caseMap[this.props?.params?.strand]]
        }
        onSelectMenuItem={this.onSelectMenuItem}
        selectedStrand={this.props?.params?.strand}
        btnOptions={[
          "featured",
          "reading",
          "writing",
          "language",
          "speaking-listening",
        ]}
        nextJoyrideStep={this.props.nextJoyrideStep}
        isJoyrideRunning={this.props.isJoyrideRunning}
      />
    );

    return (
      <div style={{ height: "100%", width: "100%" }}>
        <NewHeader headerType={"library"} typeOfResource={"singleFolder"} />
        <BrowsePageContent
          name="Teaching Resources"
          asyncStatus={this.props?.resourcesData?.asyncStatus ?? ""}
          resources={
            this.state.searchValue !== ""
              ? this.getResources()?.filter((x) => {
                  return x.props?.item?.name
                    ?.toLowerCase()
                    .includes(this.state.searchValue.toLowerCase());
                })
              : this.getResources()
          }
          resourceHeading={
            <ResourceHeading
              multiSelectMode={this.state.multiSelectMode}
              areAllSelected={this.state.areAllSelected}
              handleOpenMultiselect={this.handleOpenMultiselect}
              handleCancel={this.handleCancel}
              handleDeselectAll={this.handleDeselectAll}
              handleSelectAll={this.handleSelectAll}
              handleRemoveFromLibrary={this.handleBulkRemoveFolder}
              refreshLibrary={this.props.refreshLibrary}
              folderName={this.state?.folderData?.name ?? ""}
            />
          }
          selectedResource={this.state?.resourceDetails}
          selectedResourceType={
            this.state?.resourceDetails?.type === "Resource"
              ? "resource"
              : this.state?.resourceDetails?.type === "Lessons"
              ? "lesson"
              : this.state?.resourceDetails?.type === "Correlation"
              ? "correlation"
              : this.state?.resourceDetails?.type === "Unit"
              ? "units"
              : this.state?.resourceDetails?.type === "Assessment"
              ? "assessment"
              : this.state?.resourceDetails?.type === "Book"
              ? "book"
              : this.state?.resourceDetails?.type === "Appendices"
              ? "appendices"
              : ""
          }
          handleClickForResourcesThumbnail={
            this.handleClickForResourcesThumbnail
          }
          navButtons={navButtons}
          isFeatured={this.state.isFeatured}
          setLibrarySearchValue={this.setLibrarySearchValue}
          hasMore={this.state.hasMore}
          loadMore={this._loadMore.bind(this)}
          location={this.props.location}
          type={"library"}
          isFolder={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    myLibrary: state.MyLibrary,
    finalFilters: state.FilterScale,
    isJoyrideRunning: state.JoyrideReducer.joyrideShouldRun,
    resourceDetails: state.ResourceDetails,
    lessonsDetail: state.LessonsDetail,
    correlationDetails: state.CorrelationDetails,
    singleUnitDetails: state.SingleUnitDetails,
    bookDetails: state.BookDetails,
    assessmentDetails: state.AssessmentDetails,
  };
};

export default connect(mapStateToProps, actions)(LibrarySingleFolderContent);
