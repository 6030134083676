import React,{Component} from 'react'
import CollapsibleComponent from '../../containers/Details/CollapsibleComponent'
import {connect} from 'react-redux'
import * as actions from '../../redux/actions'
import authAPI from '../../api/authAPI'

class Standards extends Component{

    render(){
        return(
            <CollapsibleComponent collapsed={true} header={this.props.Header} occurance={this.props.standardOccurance || 0} standardCount={this.props.standardsData.length} headerStyle={{fontSize: "17px",letterSpacing:1.5,height:20.5, fontWeight:'bold', color: "#4A4A4A", paddingBottom: "35px", paddingTop: "15px",fontFamily:"ProximaNova" }} >
                    {this.props.standardsData.map((standard,i)=>(
                        <div key={standard+i}>
                            <div className='standard-item'>
                               {standard.count>0  ? <span  style={{fontWeight:'bold'}} className='standard-item-text'>{standard.name}</span>:
                               <span  className='standard-item-text'>{standard.name}</span>}
                               {standard.count && standard.count>0?<span className='standard-item-count'>{standard.count}</span>:<span className='standard-item-img icon-icon-browse'/>}</div>
                        </div>
                    ))
                }
            </CollapsibleComponent>
        );
    }
}

class StandardsComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
           data:{}
        };
        this.MakingQuery = this.MakingQuery.bind(this)
        // this.countCalculation = this.countCalculation.bind(this)
    }

    MakingQuery(){
        let self = this;
        let queryString='';
        if(this.props.standardsForLesson!==[]){
            this.props.standardsForLesson.map((lesson,i)=>{
                queryString=queryString+'&lessons='+lesson._id
            });
            queryString=queryString.substr(1)
        }

        authAPI.getCustomStandards(queryString).then(function(resp){
            self.setState({data: resp})
        });
    }

    // countCalculation(){
    //      var readingOccurances = this.state.data && this.state.data.Reading && this.state.data.Reading.filter(n=>n.count>0)
    //             console.log(readingOccurances)
    //             console.log(readingOccurances?readingOccurances.length:'')
    // }

    componentDidMount(){
        // this.props.getFilterStandardsAction('Reading','units');
        // this.props.getFilterStandardsAction('Writing','units');
        // this.props.getFilterStandardsAction('Language','units');
        // this.props.getFilterStandardsAction(encodeURIComponent("Speaking & Listening"),'units');
        this.MakingQuery()
    }
    
    render(){
        return(
              <div className='sidebar-items'>
                  {this.state.data && this.state.data.Reading &&
                      <Standards Header={'READING'} standardsData={this.state.data.Reading} standardOccurance={this.state.data.Reading.filter(n=>n.count>0).length}/>
                  }
                  {this.state.data && this.state.data.Writing && 
                      <Standards Header={'WRITING'} standardsData={this.state.data.Writing} standardOccurance={this.state.data.Writing.filter(n=>n.count>0).length}/>
                  }
                  {this.state.data && this.state.data['Speaking & Listening'] &&
                      <Standards Header={'SPEAKING/LISTENING'} standardsData={this.state.data['Speaking & Listening']} standardOccurance={this.state.data['Speaking & Listening'].filter(n=>n.count>0).length}/>
                  }
                  {this.state.data && this.state.data.Language &&
                      <Standards Header={'LANGUAGE'} standardsData={this.state.data.Language} standardOccurance={this.state.data.Language.filter(n=>n.count>0).length}/>
                  }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filterStandards : state.StandardsFilter
    }
};

export default connect(mapStateToProps, actions)(StandardsComponent)