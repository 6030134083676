import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import authAPI from "../../api/authAPI";
import constants from "../../constants";
import NewHeader from "../../containers/NewHeader";
import ResponsiveResourcesNew from "../../containers/TeachingResources/ResponsiveResourcesNew";
import BrowsePageContent from "../../containers/shared/BrowsePageContent";
import steps from "../../containers/TeachingResources/stepsTR";
import * as actions from "../../redux/actions";
import ResourceHeading from "../../components/ResourceHeading";
import AddToFolderModal from "../../components/AddToFolder/AddToFolderModal";
import { SnackBarModule } from "../../components/Shared";

class Browse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: constants.caseMap[this.props.params.strand],
      isFeatured: false,
      DataSource: this.props,
      loadingMore: false,
      pageNumber: 1,
      data: [],
      searchedData: [],
      selectedDetailsType: "",
      lessonsDetail: {},
      resourceDetails: {},
      correlationDetails: {},
      unitsDetail: {},
      assessmentDetails: {},
      hasMore: true,
      total: 0,
      searchTotal: 0,
      filter: false,
      queryValue: "",
      multiSelectMode: false,
      areAllSelected: false,
      selectedItems: [],
      multipleItems: {
        Resource: [],
        Lesson: [],
        Correlations: [],
      },
      addedMultipleItemsToLibrary: false,
      addedMultipleItemsToFolder: false,
      isJoyrideRunning: false,
      isBackButtonPressed: false,
      selectedResourceId: "",
      selectedResourceName: "",
      isAddToLibrarySuccessOpen: false,
      isAddToLibraryErrorOpen: false,
      isAddToFolderModalOpen: false,
      addToFolderSuccess: false,
      addToFolderError: false,
      addToFolderErrorMsg: "",
      searchActive: false,
      getSearchNextPage: false,
    };

    this.continueTour = _.once(() => this.props.resetJoyride(steps.steps, 2));
    this.loadSteps = _.once(() => this.props.joyrideLoadSteps(steps.steps));
    window.sessionStorage.setItem("selectedFilters", JSON.stringify([]));
  }

  _handleAPI(pageNumber, handle) {
    if (handle === 0) {
      for (let i = 1; i <= 4; i++)
        this.props.getFeaturedTeachingResourcesAction(i);
      this.setState({
        isFeatured: true,
      });
    } else {
      this.props.getResourcesAction(pageNumber, handle);
      this.setState({
        isFeatured: false,
      });
    }
  }

  componentDidMount() {
    this.props.getHomeResourcesAction();
  }

  componentWillReceiveProps(next) {
    if (
      !this.state.searchActive &&
      next.homeResources.data &&
      next.homeResources.asyncStatus === "SUCCESS"
    ) {
      if (next?.homeResources?.data?.length) {
        this.setState({
          data: next.homeResources.data,
          total: next.homeResources.data.length,
        });
      }
    }

    if (
      next?.resourceDetails?.asyncStatus &&
      (this.state.selectedDetailsType === "teaching_resource" ||
        this.state.selectedDetailsType === "Resource")
    ) {
      if (next?.resourceDetails?.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next?.resourceDetails?.asyncStatus === "FAILURE") {
        this.setState({ status: "FAILURE" });
      } else if (next?.resourceDetails?.data.status === "RESOURCE NOT FOUND") {
        this.setState({ status: "RESOURCE NOT FOUND" });
      } else if (next?.resourceDetails.asyncStatus === "SUCCESS") {
        this.setState({
          correlationDetails: {},
          lessonsDetail: {},
          unitsDetail: {},
          assessmentDetails: {},
          resourceDetails: next?.resourceDetails.data,
          selectedResourceId: next.resourceDetails.data._id,
        });
      }
    } else if (
      next?.lessonsDetail?.asyncStatus &&
      (this.state.selectedDetailsType === "lessons" ||
        this.state.selectedDetailsType === "Lesson")
    ) {
      if (next.lessonsDetail.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next?.lessonsDetail?.asyncStatus === "FAILURE") {
        this.setState({ status: "FAILURE" });
      } else if (
        next?.lessonsDetail?.asyncStatus === "SUCCESS" &&
        next.lessonsDetail?.data?.status === "FAILURE FOR PRINT ONLY USER"
      ) {
        this.setState({ status: "FAILURE FOR PRINT ONLY USER" });
      } else if (
        next?.lessonsDetail?.asyncStatus === "SUCCESS" &&
        next.lessonsDetail?.data?.status === "FAILURE FOR NOT APPROVED"
      ) {
        this.setState({ status: "FAILURE FOR NOT APPROVED" });
      } else if (next?.lessonsDetail?.asyncStatus === "SUCCESS") {
        this.setState({
          correlationDetails: {},
          resourceDetails: {},
          unitsDetail: {},
          assessmentDetails: {},
          lessonsDetail: next?.lessonsDetail.data,
          status: "SUCCESS",
          selectedResourceId: next.lessonsDetail.data._id,
        });
      }
    } else if (
      next?.singleUnitDetails?.asyncStatus &&
      (this.state.selectedDetailsType === "units" ||
        this.state.selectedDetailsType === "Unit")
    ) {
      if (next.singleUnitDetails.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next?.singleUnitDetails?.asyncStatus === "FAILURE") {
        this.setState({ status: "FAILURE" });
      } else if (
        next?.singleUnitDetails?.asyncStatus === "SUCCESS" &&
        next.singleUnitDetails?.data?.status === "FAILURE FOR PRINT ONLY USER"
      ) {
        this.setState({ status: "FAILURE FOR PRINT ONLY USER" });
      } else if (
        next?.singleUnitDetails?.asyncStatus === "SUCCESS" &&
        next.singleUnitDetails?.data?.status === "FAILURE FOR NOT APPROVED"
      ) {
        this.setState({ status: "FAILURE FOR NOT APPROVED" });
      } else if (next?.singleUnitDetails?.asyncStatus === "SUCCESS") {
        this.setState({
          correlationDetails: {},
          resourceDetails: {},
          lessonsDetail: {},
          assessmentDetails: {},
          unitsDetail: next?.singleUnitDetails?.data,
          status: "SUCCESS",
          selectedResourceName: next.singleUnitDetails.data.name,
        });
      }
    } else if (
      next?.assessmentDetails?.asyncStatus &&
      (this.state.selectedDetailsType === "assessments" ||
        this.state.selectedDetailsType === "Assessment")
    ) {
      if (next.assessmentDetails.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next?.assessmentDetails?.asyncStatus === "FAILURE") {
        this.setState({ status: "FAILURE" });
      } else if (
        next?.assessmentDetails?.asyncStatus === "SUCCESS" &&
        next.assessmentDetails?.data?.status === "FAILURE FOR PRINT ONLY USER"
      ) {
        this.setState({ status: "FAILURE FOR PRINT ONLY USER" });
      } else if (
        next?.assessmentDetails?.asyncStatus === "SUCCESS" &&
        next.assessmentDetails?.data?.status === "FAILURE FOR NOT APPROVED"
      ) {
        this.setState({ status: "FAILURE FOR NOT APPROVED" });
      } else if (next?.assessmentDetails?.asyncStatus === "SUCCESS") {
        this.setState({
          correlationDetails: {},
          resourceDetails: {},
          lessonsDetail: {},
          unitsDetail: {},
          assessmentDetails: next?.assessmentDetails?.data,
          status: "SUCCESS",
          selectedResourceId: next.assessmentDetails.data._id,
        });
      }
    } else if (
      next?.bookDetails?.asyncStatus &&
      (this.state.selectedDetailsType === "books" ||
        this.state.selectedDetailsType === "Book")
    ) {
      if (next.bookDetails.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next?.bookDetails?.asyncStatus === "FAILURE") {
        this.setState({ status: "FAILURE" });
      } else if (
        next?.bookDetails?.asyncStatus === "SUCCESS" &&
        next.bookDetails?.data?.status === "FAILURE FOR PRINT ONLY USER"
      ) {
        this.setState({ status: "FAILURE FOR PRINT ONLY USER" });
      } else if (
        next?.bookDetails?.asyncStatus === "SUCCESS" &&
        next.bookDetails?.data?.status === "FAILURE FOR NOT APPROVED"
      ) {
        this.setState({ status: "FAILURE FOR NOT APPROVED" });
      } else if (next?.bookDetails?.asyncStatus === "SUCCESS") {
        this.setState({
          correlationDetails: {},
          resourceDetails: {},
          lessonsDetail: {},
          unitsDetail: {},
          assessmentDetails: {},
          bookDetails: next?.bookDetails?.data,
          status: "SUCCESS",
          selectedResourceId: next.bookDetails.data._id,
        });
      }
    } else if (
      next?.correlationDetails?.asyncStatus &&
      this.state.selectedDetailsType === "correlations"
    ) {
      if (next.correlationDetails.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next.correlationDetails.asyncStatus === "FAILURE") {
        this.setState({ correlationDetails: "FAILURE" });
      } else if (
        next.correlationDetails.asyncStatus === "SUCCESS" &&
        next.correlationDetails.data.status === "FAILURE FOR PRINT ONLY USER"
      ) {
        this.setState({ status: "FAILURE FOR PRINT ONLY USER" });
      } else if (
        next.correlationDetails.asyncStatus === "SUCCESS" &&
        next.correlationDetails.data.status === "FAILURE FOR NOT APPROVED"
      ) {
        this.setState({ status: "FAILURE FOR NOT APPROVED" });
      } else if (next.correlationDetails.asyncStatus === "SUCCESS") {
        let data = {
          id: next.correlationDetails.data._id,
          name: next.correlationDetails.data.name,
          publish: next.correlationDetails.data.publish,
          texts: next.correlationDetails.data.mentorTexts,
          grades: next.correlationDetails.data.grades.join(", "),
          forms: next.correlationDetails.data.files.map((file) => ({
            file,
            image: file + ".thumb.jpg",
          })),
          description: "",
          image: "",
          printable: next.correlationDetails.data.printable,
        };

        if (next.correlationDetails.data.description.description) {
          data.description =
            next.correlationDetails.data.description.description;
        } else if (next.correlationDetails.data.description.text) {
          data.description = next.correlationDetails.data.description.text;
        }

        if (next.correlationDetails.data.image) {
          data.image = next.correlationDetails.data.image;
        }

        this.setState({
          correlationDetails: data,
          status: "SUCCESS",
          selectedResourceId: next.correlationDetails.data._id,
        });
      }
    }
  }

  handleClickForResourcesThumbnail = function (item) {
    if (this.props?.multiSelectMode) {
      if (
        (item.type === "Resource" &&
          this.props.multipleItems["Resource"].indexOf(item._id) === -1) ||
        (item.type === "Lesson" &&
          this.props.multipleItems["Lesson"].indexOf(item._id) === -1)
      ) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        item.type !== "Correlations" &&
          this.props.deleteItem(item._id, item.type);
      }
    } else {
      let id = item?._id ?? item?.id;
      switch (item.type) {
        case "teaching_resource":
          browserHistory.push("/resources/" + id);
          return;
        case "Resource":
          browserHistory.push("/resources/" + id);
          return;
        case "correlations":
          browserHistory.push("/correlations/" + id);
          return;
        case "Correlation":
          browserHistory.push("/correlations/" + id);
          return;
        case "units":
          browserHistory.push(`/units/${id}`);
          return;
        case "Unit":
          browserHistory.push(`/units/${id}`);
          return;
        case "assessments":
          browserHistory.push(`/assessments/${id}`);
          return;
        case "Assessment":
          browserHistory.push(`/assessments/${id}`);
          return;
        case "books":
          browserHistory.push(`/books/${id}`);
          return;
        case "Book":
          browserHistory.push(`/books/${id}`);
          return;
        default:
          browserHistory.push("/lessons/" + id);
          return;
      }
      // item.type === 'Resource' ? browserHistory.push('/resources/' + item._id) : browserHistory.push('/lessons/' + item._id);
    }
  };

  handleClickForResourcesAddBtn = function (item) {
    if (this.props.multiSelectMode) {
      if (
        (item.type === "Resource" &&
          this.props.multipleItems["Resource"].indexOf(item._id) === -1) ||
        (item.type === "Lesson" &&
          this.props.multipleItems["Lesson"].indexOf(item._id) === -1) ||
        (item.type === "Correlations" &&
          this.props.multipleItems["Correlations"].indexOf(item._id) === -1)
      ) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        this.props.deleteItem(item._id, item.type);
      }
    } else {
      //This replaces "showTooltip" in popover.
      this.setState({
        isTooltipActive: true,
        success: false,
        showDatePicker: false,
      });
    }
  };

  updateFromSearch = (data, total, clearSearch, nextPage) => {
    this.setState({
      searchTotal: total ?? data?.length ?? 0,
      searchedData: data ?? [],
      searchActive: clearSearch ? false : true,
      hasMore: nextPage !== -1 ? true : false,
    });
  };

  handleSelectResourceClick = (item) => {
    if (this.props.multiSelectMode) {
      if (
        (item.type === "Resource" &&
          this.props.multipleItems["Resource"].indexOf(item._id) === -1) ||
        (item.type === "Lesson" &&
          this.props.multipleItems["Lesson"].indexOf(item._id) === -1)
      ) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        item.type !== "Correlations" &&
          this.props.deleteItem(item._id, item.type);
      }
    } else {
      if (item.type) {
        this.setState({ selectedDetailsType: item.type });
        if (item.type === "teaching_resource" || item.type === "Resource") {
          this.props.getResourceDetailsAction(item._id);
        } else if (
          item.type === "correlations" ||
          item.type === "Correlation"
        ) {
          this.props.getCorrelationDetailsAction(item._id);
        } else if (item.type === "units" || item.type === "Unit") {
          this.setState({ selectedResourceId: item._id });
          this.props.getSingleUnitDetailsAction(item._id);
        } else if (item.type === "assessments" || item.type === "Assessment") {
          this.props.getAssessmentDetailsAction(item._id);
        } else if (
          item.type === "books" ||
          item.type === "Books" ||
          item.type === "Book"
        ) {
          this.props.getBookDetailsAction(item._id);
        } else {
          this.props.getLessonsDetailedAction(item._id);
        }
      }
    }
  };

  handleSuccessSnackBar = () => {
    this.handleCancel();
    this.setState({ isAddToFolderModalOpen: false });
    this.setState({ addToFolderSuccess: true });
    setTimeout(() => {
      this.setState({ addToFolderSuccess: false });
    }, 3000);
  };

  handleErrorSnackBar = (msg) => {
    console.log("tesnnin");
    console.log(`msg: ${msg}`);
    this.handleCancel();
    this.setState({ isAddToFolderModalOpen: false });
    this.setState({
      addToFolderError: true,
      addToFolderErrorMsg: msg,
    });
    setTimeout(() => {
      this.setState({ addToFolderError: false });
    }, 3000);
  };

  handleCancel = () => {
    this.handleDeselectAll();
    this.setState({ multiSelectMode: !this.state.multiSelectMode });
  };

  handleMultiSelect = (item) => {
    const copyArr = [...this.state.selectedItems];
    let type = "";
    switch (item.type) {
      case "teaching_resource":
        type = "Resource";
        break;
      case "assessments":
        type = "Assessment";
        break;
      case "books":
        type = "Book";
        break;
      case "lessons":
        type = "Lesson";
        break;
      case "units":
        type = "Unit";
        break;
      default:
        break;
    }

    if (copyArr.length !== 0) {
      if (!copyArr.filter((e) => e.item_id === item._id).length > 0) {
        copyArr.push({
          item_id: item._id,
          item_name: item.name,
          item_type: type,
        });
        let uniqueItems = [
          ...new Map(copyArr.map((v) => [v.item_id, v])).values(),
        ];
        this.setState({ selectedItems: uniqueItems });
      } else {
        copyArr.splice(
          copyArr.findIndex((x) => x.item_id === item._id),
          1
        ); //deleting
        this.setState({ selectedItems: copyArr });
      }
    } else {
      copyArr.push({
        item_id: item._id,
        item_name: item.name,
        item_type: type,
      });
      let uniqueItems = [
        ...new Map(copyArr.map((v) => [v.item_id, v])).values(),
      ];
      this.setState({ selectedItems: uniqueItems });
    }
  };

  handleSelectAll = () => {
    this.setState({ areAllSelected: true });
    const copyArr = [];
    this.state.data.forEach((item) => {
      let type = "";
      switch (item.type) {
        case "teaching_resource":
          type = "Resource";
          break;
        case "assessments":
          type = "Assessment";
          break;
        case "books":
          type = "Book";
          break;
        case "lessons":
          type = "Lesson";
          break;
        case "units":
          type = "Unit";
          break;
        default:
          break;
      }
      copyArr.push({
        item_id: item._id,
        item_name: item.name,
        item_type: type,
      });
    });
    this.setState({ selectedItems: copyArr });
  };

  handleDeselectAll = () => {
    this.setState({ areAllSelected: false });
    this.setState({ selectedItems: [] });
  };

  handleOpenMultiselect = () => {
    this.setState({ multiSelectMode: !this.state.multiSelectMode });
  };

  handleBulkAddToFolderModalOpen = () => {
    this.setState({ isAddToFolderModalOpen: true });
  };

  handleBulkAddToFolderModalClose = () => {
    this.setState({ isAddToFolderModalOpen: false });
  };

  handleAddToLibrary = () => {
    try {
      authAPI.bulkAddToLibrary(this.state.selectedItems).then((resp) => {
        if (resp?.status === 200) {
          this.setState({ multiSelectMode: false });
          this.setState({ isAddToLibrarySuccessOpen: true });
          this.props.getHomeResourcesAction();
          setTimeout(() => {
            this.setState({ isAddToLibrarySuccessOpen: false });
          }, 3000);
        } else {
          this.setState({ isAddToLibraryErrorOpen: true });
          setTimeout(() => {
            this.setState({ isAddToLibraryErrorOpen: false });
          }, 3000);
        }
      });
    } catch (err) {
      console.log("Error while adding to library");
    }
  };

  _loadMore = () => {
    if (
      this.state.searchActive &&
      !this.state.getSearchNextPage &&
      this.state.nextPage !== -1 &&
      this.state.hasMore
    ) {
      setTimeout(() => {
        this.setState({ getSearchNextPage: true });
      }, 1000);
    }
  };

  getResources = () => {
    const startE = new Date().getTime();
    const resources = this.state?.searchActive
      ? this.state?.searchedData
      : this.state?.data;
    return resources?.length >= 0
      ? resources.map((resource, i) => (
          <ResponsiveResourcesNew
            item={resource}
            i={i}
            handleClickForResourcesThumbnail={
              this.handleClickForResourcesThumbnail
            }
            handleClickForResourcesAddBtn={this.handleClickForResourcesAddBtn}
            handleSelectResourceClick={this.handleSelectResourceClick}
            multiSelectMode={this.state.multiSelectMode}
            addMultipleItems={this.addMultipleItems}
            deleteItem={this.deleteItem}
            multipleItems={this.state.multipleItems}
            scheduleTask={this.props.postScheduledTasksAction}
            addToLibrary={
              resource.type === "Resource"
                ? this.props.postAddTeachingResourceToLibraryAction
                : resource.type === "Correlations"
                ? this.props.postAddCorrelationToLibraryAction
                : this.props.postAddLessonToLibraryAction
            }
            addLessonToLibrary={this.props.postAddLessonToLibraryAction}
            startE={startE}
            active={
              parseInt(resource?._id) ===
              parseInt(this.state.selectedResourceId)
            }
            handleMultiSelect={this.handleMultiSelect}
            selectedItems={this.state.selectedItems}
          />
        ))
      : [];
  };

  render() {
    return (
      <div>
        <NewHeader typeOfResource="featured" />
        <BrowsePageContent
          name=""
          resources={this.getResources()}
          resourceHeading={
            <ResourceHeading
              multiSelectMode={this.state.multiSelectMode}
              areAllSelected={this.state.areAllSelected}
              handleOpenMultiselect={this.handleOpenMultiselect}
              handleCancel={this.handleCancel}
              handleDeselectAll={this.handleDeselectAll}
              handleSelectAll={this.handleSelectAll}
              handleAddToLibrary={this.handleAddToLibrary}
              total={
                this.state.searchActive
                  ? this.state?.searchTotal
                  : this.state.total ?? 0
              }
              data={this.state.data}
              handleBulkAddToFolderModal={this.handleBulkAddToFolderModalOpen}
              typeOfResource="Featured"
              searchActive={this.state.searchActive}
            />
          }
          selectedResource={
            this.state.resourceDetails?.id || this.state.resourceDetails?._id
              ? this.state.resourceDetails
              : this.state.lessonsDetail?.id || this.state.lessonsDetail?._id
              ? this.state.lessonsDetail
              : this.state.correlationDetails?.id ||
                this.state.correlationDetails?._id
              ? this.state.correlationDetails
              : this.state.unitsDetail?.name
              ? this.state.unitsDetail
              : this.state.assessmentDetails?.id ||
                this.state.assessmentDetails?._id
              ? this.state.assessmentDetails
              : this.state.bookDetails?.id || this.state.bookDetails?._id
              ? this.state.bookDetails
              : ""
          }
          selectedResourceType={
            this.state.resourceDetails?.id || this.state.resourceDetails?._id
              ? "resource"
              : this.state.lessonsDetail?.id || this.state.lessonsDetail?._id
              ? "lesson"
              : this.state.correlationDetails?.id ||
                this.state.correlationDetails?._id
              ? "correlation"
              : this.state.unitsDetail?.id ||
                this.state.unitsDetail?._id ||
                this.state.unitsDetail?.unit_id
              ? "units"
              : this.state.assessmentDetails?.id ||
                this.state.assessmentDetails?._id
              ? "assessment"
              : this.state.bookDetails?.id || this.state.bookDetails?._id
              ? "book"
              : ""
          }
          handleClickForResourcesThumbnail={
            this.handleClickForResourcesThumbnail
          }
          isFeatured={this.state.isFeatured}
          hasMore={this.state.hasMore}
          loadMore={this._loadMore}
          updateFromSearch={this.updateFromSearch}
          getSearchNextPage={this.state.getSearchNextPage}
          setNoSearchNextPage={() =>
            this.setState({ getSearchNextPage: false })
          }
          location={this.props.location}
        />

        {this.state.isAddToFolderModalOpen && (
          <AddToFolderModal
            handleClose={this.handleBulkAddToFolderModalClose}
            handleSuccessSnackbar={this.handleSuccessSnackBar}
            handleErrorSnackbar={this.handleErrorSnackBar}
            selectedItems={this.state.selectedItems}
          />
        )}

        <SnackBarModule
          addToLibrarySuccess={this.state.isAddToLibrarySuccessOpen}
          addToLibraryError={this.state.isAddToLibraryErrorOpen}
          addToFolderSuccess={this.state.addToFolderSuccess}
          addToFolderError={this.state.addToFolderError}
          addToFolderErrorMsg={this.state.addToFolderErrorMsg}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    homeResources: state.HomeResources,
    finalFilters: state.FilterScale,
    isJoyrideRunning: state.JoyrideReducer.joyrideShouldRun,
    resourceDetails: state.ResourceDetails,
    lessonsDetail: state.LessonsDetail,
    correlationDetails: state.CorrelationDetails,
    singleUnitDetails: state.SingleUnitDetails,
    assessmentDetails: state.AssessmentDetails,
    bookDetails: state.BookDetails,
  };
};

export default connect(mapStateToProps, actions)(Browse);
