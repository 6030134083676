import React, { Component } from "react";
import closeButtonIcon from "../../assets/icons/SVG/my-close-button.png";
import iconShare from "../../assets/icons/SVG/icon-share.svg";
import checkMarkWhite from "../../assets/icons/SVG/icon-check.svg";
import authAPI from "../../api/authAPI";
import user from "../../assets/icons/SVG/icon-add-user.svg";
import Autocomplete from "../Shared/Autocomplete";

class ShareFolderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      studentsPost: [],
      showConfirm: false,
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.handleModalShareClose();
    }
  };

  loadAllStudents = async () => {
    const districtID = localStorage.getItem("district_id");
    const response = await authAPI.getUsers(
      "Student",
      "",
      districtID,
      1,
      1,
      0,
      0,
      1
    );
    this.setState({
      students: response?.results,
    });
  };

  handleAddStudent = async (item) => {
    let arr = [];
    item.forEach((el) => {
      arr.push({
        user_id: el._id,
        user_email: el.username,
        user_name: `${el.first_name ?? ""} ${el.last_name ?? ""}`,
      });
    });

    let uniqueItems = [...new Map(arr.map((v) => [v.user_id, v])).values()];

    if (uniqueItems.length > 0) {
      this.setState({
        studentsPost: uniqueItems,
        showConfirm: true,
      });
    } else {
      this.setState({
        studentsPost: [],
        showConfirm: false,
      });
    }
  };

  handleClear = async () => {
    this.setState({ studentsPost: [] });
  };

  componentDidMount() {
    this.loadAllStudents();
  }

  render() {
    return (
      <div style={{ zIndex: 99999 }} className="add-to-folder-modal-overlay">
        {this.state.students.length && (
          <div className="share-modal-wrapper" ref={this.wrapperRef}>
            <div className="add-to-folder-modal-header">
              <div>
                <img src={iconShare} alt="Share" /> Share{" "}
              </div>
              <div
                className="add-to-folder-modal-close-btn"
                onClick={() => this.props.handleModalShareClose()}
              >
                <img src={closeButtonIcon} alt="Close Button" />
                <div>Cancel</div>
              </div>
            </div>
            <div className="share-modal-content">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <img
                  src={user}
                  style={{
                    width: "32px",
                    height: "32px",
                    marginRight: "15px",
                  }}
                  alt="Close Button"
                />

                <Autocomplete
                  suggestions={this.state.students}
                  handleAddStudent={this.handleAddStudent}
                />
              </div>

              <div className="share-modal-input-container">
                <p>Copy Link:</p>
                <input
                  type="text"
                  value={`https://${window.location.host}/folders/${this.props.itemClicked._id}`}
                  name="copyLink"
                  id="copyLink"
                  readOnly="readonly"
                />
              </div>

              <div
                key={this.state.studentsPost.length}
                className={
                  this.state.showConfirm === false
                    ? "share-modal-confirm-button-disabled"
                    : "share-modal-confirm-button"
                }
                onClick={() =>
                  this.props.handleSendEmail(
                    this.props.itemClicked._id,
                    this.state.studentsPost,
                    `https://${window.location.host}/folders/${this.props.itemClicked._id}`
                  )
                }
              >
                <img src={checkMarkWhite} alt="Confirm Button" /> Confirm
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ShareFolderModal;
