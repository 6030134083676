/**
 * Created by ThomasL on 6/27/17.
 */
import * as queryStringLibrary from "query-string";

let constants = {};
constants.apiUrl = "/schoolwidefun/api/v1/";
constants.filesUrl = constants.apiUrl + "files/";
constants.convertToStrandNumber = function (name) {
  return isNaN(name) ? this.caseMap[this.normalizeTypeName(name)] : name;
};
constants.caseMap = {
  featured: 0,
  reading: 1,
  writing: 2,
  "speaking-listening": 3,
  "speaking & listening": 3,
  grammar: 3,
  language: 3,
  foundational: 4,
  "e-learning": 5,
  all: 100,
  books: "books",
};

constants.PATHS = {
  ROOT: "/",
  HOME: "/home",
  LOGIN: "/login",
  SIGNUP: "/signup",
  BROWSE: "/browse",
  PASSWORD: "/password",
  TEACHING_RESOURCES: "/browse/teaching-resources/reading",
  NEWHOME: "/browse/teaching-resources/reading",
  TEXT: "/browse/books",
};
// Defaults for student and non student login redirection
constants.DEFAULT_STUDENT_PATH = constants.PATHS.HOME;
// Changed BROWSE to TEACHING_RESOURCES
constants.DEFAULT_NON_STUDENT_PATH = constants.PATHS.TEACHING_RESOURCES;

// Default for lite user. Unlimited user will use default non student login redirection
constants.DEFAULT_LITE_USER_PATH = constants.PATHS.TEACHING_RESOURCES;
// Default for print-only users
constants.DEFAULT_PRINT_ONLY_USER_PATH = constants.PATHS.BROWSE; //constants.PATHS.TEACHING_RESOURCES;

// Default for unlimited users
// constants.DEFAULT_UNLIMITED_USER_PATH = constants.PATHS.BROWSE;
constants.DEFAULT_UNLIMITED_USER_PATH = constants.PATHS.BROWSE; //constants.PATHS.NEWHOME;

constants.DEFAULT_TEXT_ONLY_USER_PATH = constants.PATHS.TEXT;

constants.strandsToURL = {
  Reading: "reading",
  Writing: "writing",
  Language: "language",
  "Speaking & Listening": "speaking-listening",
  Grammar: "grammar",
};
constants.resourceLibraryMap = {
  Resource: "resources",
  Assessment: "assessments",
  Book: "books",
  Lesson: "resources",
  Correlations: "resources",
  Units: "units",
};
constants.normalizeTypeName = (name) => {
  if (typeof name !== "string") return name;
  switch (name.toLowerCase()) {
    case "resource":
    case "resources":
    case "teachingresource":
    case "teachingresources":
    case "teaching resources":
    case "teaching resource":
      return "teachingResources";
    case "book":
    case "books":
      return "books";
    case "speaking-listening":
    case "speaking & listening":
    case "speaking/listening":
      return "speaking-listening";
    case "assessment":
      return "assessments";
    case "lesson":
      return "lessons";
    case "unit":
      return "units";
    default:
      return name.toLowerCase();
  }
};
constants.getQueryStringFromFilterList = (filterList) => {
  let queryStringObject = {};
  if (filterList != null && filterList.length > 0) {
    queryStringObject = filterList.reduce((accum, filter) => {
      switch (filter.query) {
        case "audiobook":
          accum.audiobook = filter.checked;
          return accum;
        case "lexileLevel":
          accum.minLexileLevel = filter.min;
          accum.maxLexileLevel = filter.max;
          return accum;
        default:
          if (accum[filter.query]) accum[filter.query].push(filter._id);
          else accum[filter.query] = [filter._id];
          return accum;
      }
    }, {});
    return "&" + queryStringLibrary.stringify(queryStringObject);
  } else return "";
};
export default constants;
