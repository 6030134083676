import React,{Component} from 'react'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import ImportModal from "../Shared/ImportModal"

export class BulkAddUser extends Component{

    constructor (props) {
        super(props);
        this.state=
        {
            uploadSuccess: false,
            fileString: '',
            uploadType: '',
            districtId: '',
            headerText: 'BULK ADD '
        };

        this.state.districtId = this.props.districtId;
        this.state.uploadType = this.props.bulkAddType;
//Changed Teacher to Unlimited
        if(this.state.uploadType === "Users") {
            this.state.headerText += "TEACHERS";
        }
        else if(this.state.uploadType === "Student") {
            this.state.headerText += "STUDENTS";
        }
    }

    errorHandler(evt)
    {
        if(evt.target.error.name === "NotReadableError")
        {
            alert("Error reading csv file!");
        }
    }

    render(){
        return(
            <ImportModal
                headerText={this.state.headerText}
                templateUrl="../schoolwidefun/api/v1/users/import"
                style={{top:this.props.userType==='Student'?'650px':'15%'}}
                id={this.props.districtId}
                type={this.state.uploadType}
                onCancel={this.props.onCancel}
                refresh={this.props.refreshUsers}
                importAction={this.props.importUsersAction}
            />
        );
    }
}

//
const mapStateToProps = (state) => {
    return {importUsers: state.ImportUsers}
};

export default connect(mapStateToProps, actions)(BulkAddUser)