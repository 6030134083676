import React,{Component} from 'react'
import {Row,Col,Panel} from 'react-bootstrap'
import {browserHistory,Link,withRouter} from 'react-router'
import LessonPopOver from './LessonPopOver'
import LessonPopOverMobile from './LessonPopOverMobile'
import {markdownHelper} from '../../markdown/MarkdownHelper';
import {annotationsHelper} from '../../annotations/AnnotationsHelper';
import constants from "../../constants";
import CollapsibleComponent from '../../containers/Details/CollapsibleComponent';
import mySolidArrowRight from '../../assets/icons/SVG/my-solid-arrow-right.png';
import mySolidArrow from '../../assets/icons/SVG/my-solid-arrow.png';

import * as actions from '../../redux/actions'
import {connect} from 'react-redux'

//temp static data for demo

class OverviewContent extends Component{

    render(){
        let unitDescription = this.props.content.introduction != null ? (this.props.content.introduction.text ? this.props.content.introduction.text : this.props.content.introduction[0].htmlText) : null;
        console.log(this.props);
        let overviewMentorTexts = [];
        if(this.props.content.mentorTexts){
            for(let k=0;k<this.props.content.mentorTexts.length;k++){
                if(k<4){
                    overviewMentorTexts.push(this.props.content.mentorTexts[k]);
                }
            }
        }
        
      
        const SmartText = ({ text, length = 350 }) => {
            const [showLess, setShowLess] = React.useState(true);
          
            if (text.length < length) {
              return <p dangerouslySetInnerHTML={{__html: text}}></p>;
            }
          
            return (
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: showLess ? `${text.slice(0, length)}...` : text,
                  }}
                ></p>
                <a
                  style={{ "color":"#337ab7", "fontWeight":"bold",cursor: "pointer" }}
                  onClick={() => setShowLess(!showLess)}
                >
                  {showLess ? "READ MORE" : "READ LESS"}
                </a>
              </div>
            );
          };
          const SmartDescriptionText = ({ text, length = 350 }) => {
            const [showLess, setShowLess] = React.useState(true);
          
            if (text.length < length) {
              return <p dangerouslySetInnerHTML={{__html: markdownHelper.toHTML(text)}}></p>;
            }
          
            return (
              <div className='general-word-style'>
                <p
                  dangerouslySetInnerHTML={{
                    __html: showLess ? `${(markdownHelper.toHTML(text)).slice(0, length)}...` : text,
                  }}
                ></p>
                <a
                  style={{ "color":"#337ab7", "fontWeight":"bold",cursor: "pointer" }}
                  onClick={() => setShowLess(!showLess)}
                >
                  {showLess ? "READ MORE" : "READ LESS"}
                </a>
              </div>
            );
          };
        const htmlText =this.props.content.student_outcomes != null ? this.props.content.student_outcomes[0].text : '';
        const unitDescriptionText = unitDescription != null ? unitDescription : '';
        return(
            <div className="unit-overview" style={{"margin":"0 40px"}}>
                {(this.props.content && unitDescription) ? "" :
                    <div className="featured-reading" style={{textAlign: "center"}}>
                        This Unit is Temporarily Unavailable, Please Check Back Later.
                    </div>
                }
                {/*{overviewMentorTexts && overviewMentorTexts.length > 0 && 
                    <CollapsibleComponent collapsed={false} header="" headerStyle={{fontSize: "18px",fontWeight: 700, color: "#4A4A4A", borderBottom: "1px solid #CCD2DE", paddingBottom: "15px", paddingTop: "15px" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        <Row>
                            <ul style={{"listStyleType":"none","display":"inline-flex","padding":"0"}}>
                                {overviewMentorTexts.map((mentorTextDetail,i)=>(
                                    <li style={{"margin":"10px 0","padding":"10px","height": "100%","display": "inline","verticalAlign": "top","width": "auto"}}>
                                        <Link to={{pathname: 'books/'+mentorTextDetail._id, state: {itemType: "units", buyBorrowData: mentorTextDetail.buy_borrow}}}>
                                            <img src={mentorTextDetail.image != null ? constants.filesUrl+mentorTextDetail.image.replace('../',''): ''} style={{ "height": "125px","minHeight":"100%"}}/>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                            <p><a href={window.location+'/texts'} target="_blank" style={{"color":"#337ab7","fontWeight": "bold"}}>View all unit texts</a></p>
                        </Row>
                    </CollapsibleComponent>
                }*/}
                {unitDescription &&
                    <CollapsibleComponent collapsed={false} header="UNIT DESCRIPTION" headerStyle={{fontSize: "18px",fontWeight: 700, color: "#4A4A4A", borderBottom: "1px solid #CCD2DE", paddingTop: "15px" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        <Row style={{ "paddingTop": "15px","height": "auto"}}>
                            {/* <div style={{"wordWrap":"break-word"}}>{unitDescription}</div> */}
                            <div style={{"wordWrap":"break-word"}}>
                                <SmartText text={unitDescriptionText} />
                            </div>
                        </Row>
                    </CollapsibleComponent>
                }
                {this.props.content.student_outcomes != null &&
                    <CollapsibleComponent collapsed={false} header="STUDENT OUTCOMES" headerStyle={{marginTop:"50px", fontSize: "18px",fontWeight: 700, color: "#4A4A4A", borderBottom: "1px solid #CCD2DE", paddingTop: "15px" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        <Row style={{ "paddingTop": "15px","height": "auto"}}>
                        <div style={{"wordWrap":"break-word"}}>
                            <SmartText text={htmlText} />
                        </div>                       
                        </Row>
                    </CollapsibleComponent>
                }
                {(this.props.content.unit_planner_image != null || (this.props.content.unit_planner_file != null && this.props.content.unit_planner_file != '')) &&
                <CollapsibleComponent collapsed={false} header="UNIT PLANNER AND PACING GUIDE" headerStyle={{marginTop:"50px", fontSize: "18px",fontWeight: 700, color: "#4A4A4A", borderBottom: "1px solid #CCD2DE", paddingBottom: "15px", paddingTop: "15px" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                    <Row style={{ "paddingTop": "15px","minHeight": "100px"}}>
                        {this.props.content.unit_planner_image != null && 
                            <div style={{"width":"250px"}}>
                                <img src={constants.filesUrl + this.props.content.unit_planner_image.replace('../','')} style={{"display":"block","width": "100%","height":"auto"}}/>    
                            </div>
                        }
                        {this.props.content.unit_planner_file != null && this.props.content.unit_planner_file != '' && <p style={{"cursor" : "pointer","margin":"30px 0 0 0", "color":"#337ab7","fontWeight": "bold"}}><a href={this.props.content.unit_planner_file} target="_blank" style={{"color":"#337ab7","fontWeight": "bold"}}>{this.props.content.unit_planner_title}</a></p>}
                    </Row>
                </CollapsibleComponent>}
                {((this.props.content.instructional_model_thumbnails &&  this.props.content.instructional_model_thumbnails.length > 0) || (this.props.content.instructional_model_resources && this.props.content.instructional_model_resources.length > 0))&&
                    <CollapsibleComponent collapsed={false} header="INSTRUCTIONAL MODEL" headerStyle={{marginTop:"50px", fontSize: "18px",fontWeight: 700, color: "#4A4A4A", borderBottom: "1px solid #CCD2DE", paddingBottom: "15px", paddingTop: "15px" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        <Row style={{ "paddingTop": "15px","minHeight": "100px"}}>
                            {this.props.content.instructional_model_thumbnails &&  this.props.content.instructional_model_thumbnails.length > 0 &&
                            <ul style={{"listStyleType":"none","display":"inline-flex","marginLeft":"-39px"}}>
                                {this.props.content.instructional_model_thumbnails.map((instructionalModelThumbnail,i)=>(
                                    <li style={{"margin":"10px 0","paddingRight":"60px","height": "100%","display": "inline","verticalAlign": "top","width": "auto"}}>
                                        <a href={instructionalModelThumbnail.videoUrl} target="_blank">
                                            <img src={constants.filesUrl + instructionalModelThumbnail.imageUrl.replace('../','')} style={{ "height": "125px","minHeight":"100%"}}/>
                                        </a>
                                        <p style={{"margin":"10px 0","width": "75%"}}>{instructionalModelThumbnail.title}</p>
                                    </li>
                                ))}
                            </ul>}
                            {this.props.content.instructional_model_resources &&
                            <ul style={{"listStyleType":"none","marginLeft":"-39px"}}>
                                {this.props.content.instructional_model_resources.map((instructionalModelResource,i)=>(
                                    <li style={{"color":"#337ab7","fontWeight": "bold"}}>
                                        <a href={instructionalModelResource.url} target="_blank" style={{"color":"#337ab7","fontWeight": "bold"}}>{instructionalModelResource.title}</a>
                                    </li>
                                ))}
                            </ul>}
                        </Row>
                    </CollapsibleComponent>
                }

                {((this.props.content.mentor_shared_thumbnails && this.props.content.mentor_shared_thumbnails.length > 0) || (this.props.content.mentor_shared_resources && this.props.content.mentor_shared_resources.length > 0)) &&
                    <CollapsibleComponent collapsed={false} header="GETTING TO KNOW YOUR MENTOR AND SHARED TEXTS" headerStyle={{marginTop:"50px", fontSize: "18px",fontWeight: 700, color: "#4A4A4A", borderBottom: "1px solid #CCD2DE", paddingBottom: "15px", paddingTop: "15px" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        <Row style={{ "paddingTop": "15px","minHeight": "100px"}}>
                            {this.props.content.mentor_shared_thumbnails && this.props.content.mentor_shared_thumbnails.length > 0 &&
                            <ul style={{"listStyleType":"none","display":"inline-flex","marginLeft":"-39px"}}>
                                {this.props.content.mentor_shared_thumbnails.map((mentorSharedThumbnail,i)=>(
                                    <li style={{"margin":"10px 0","paddingRight":"60px","height": "100%","display": "inline","verticalAlign": "top","width": "auto"}}>
                                        <a href={mentorSharedThumbnail.videoUrl} target="_blank"><img src={constants.filesUrl + mentorSharedThumbnail.imageUrl.replace('../','')} style={{ "height": "125px","minHeight":"100%"}}/></a>
                                        <p style={{"margin":"10px 0","width": "75%"}}>{mentorSharedThumbnail.title}</p>
                                    </li>
                                ))}
                            </ul>}
                            {this.props.content.mentor_shared_resources && <ul style={{"listStyleType":"none","marginLeft":"-39px"}}>
                                {this.props.content.mentor_shared_resources.map((mentorSharedResource,i)=>(
                                    <li style={{"color":"#337ab7","fontWeight": "bold"}}>
                                        <a href={mentorSharedResource.url} target="_blank" style={{"color":"#337ab7","fontWeight": "bold"}}>{mentorSharedResource.title}</a>
                                    </li>
                                ))}
                            </ul>}
                        </Row>
                    </CollapsibleComponent>
                }

                {((this.props.content.literacy_thumbnails && this.props.content.literacy_thumbnails.length > 0) || (this.props.content.literacy_resources && this.props.content.literacy_resources.length > 0)) &&
                    <CollapsibleComponent collapsed={false} header="CREATING A LITERACY RICH ENVIRONMENT" headerStyle={{marginTop:"50px", fontSize: "18px",fontWeight: 700, color: "#4A4A4A", borderBottom: "1px solid #CCD2DE", paddingBottom: "15px", paddingTop: "15px" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        <Row style={{ "paddingTop": "15px","minHeight": "100px"}}>
                            {this.props.content.literacy_thumbnails && this.props.content.literacy_thumbnails.length > 0 &&
                            <ul style={{"listStyleType":"none","display":"inline-flex","marginLeft":"-39px"}}>
                                {this.props.content.literacy_thumbnails.map((literacyThumbnail,i)=>(
                                    <li style={{"margin":"10px 0","paddingRight":"60px","height": "100%","display": "inline","verticalAlign": "top","width": "auto"}}>
                                        <a href={literacyThumbnail.videoUrl} target="_blank"><img src={constants.filesUrl + literacyThumbnail.imageUrl.replace('../','')} style={{ "height": "125px","minHeight":"100%"}}/></a>
                                        <p style={{"margin":"10px 0","width": "75%"}}>{literacyThumbnail.title}</p>
                                    </li>
                                ))}
                            </ul>}
                            {this.props.content.literacy_resources && <ul style={{"listStyleType":"none","marginLeft":"-39px"}}>
                                {this.props.content.literacy_resources.map((literacyResource,i)=>(
                                    <li style={{"color":"#337ab7","fontWeight": "bold"}}>
                                        <a href={literacyResource.url} target="_blank" style={{"color":"#337ab7","fontWeight": "bold"}}>{literacyResource.title}</a>
                                    </li>
                                ))}
                            </ul>}
                        </Row>
                    </CollapsibleComponent>
                }

                {((this.props.content.student_notebook_thumbnails && this.props.content.student_notebook_thumbnails.length > 0) || (this.props.content.student_notebook_resources && this.props.content.student_notebook_resources.length > 0)) &&
                    <CollapsibleComponent collapsed={false} header="STUDENT NOTEBOOK" headerStyle={{marginTop:"50px", fontSize: "18px",fontWeight: 700, color: "#4A4A4A", borderBottom: "1px solid #CCD2DE", paddingBottom: "15px", paddingTop: "15px" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        <Row style={{ "paddingTop": "15px","minHeight": "100px"}}>
                            {this.props.content.student_notebook_thumbnails && this.props.content.student_notebook_thumbnails.length > 0 &&
                            <ul style={{"listStyleType":"none","display":"inline-flex","marginLeft":"-39px"}}>
                                {this.props.content.student_notebook_thumbnails.map((studentNotebookThumbnail,i)=>(
                                    <li style={{"margin":"10px 0","paddingRight":"60px","height": "100%","display": "inline","verticalAlign": "top","width": "auto"}}>
                                        <a href={studentNotebookThumbnail.videoUrl} target="_blank"><img src={constants.filesUrl + studentNotebookThumbnail.imageUrl.replace('../','')} style={{ "height": "125px","minHeight":"100%"}}/></a>
                                        <p style={{"margin":"10px 0","width": "75%"}}>{studentNotebookThumbnail.title}</p>
                                    </li>
                                ))}
                            </ul>}
                            {this.props.content.student_notebook_resources && <ul style={{"listStyleType":"none","marginLeft":"-39px"}}>
                                {this.props.content.student_notebook_resources.map((studentNotebookResource,i)=>(
                                    <li style={{"color":"#337ab7","fontWeight": "bold"}}>
                                        <a href={studentNotebookResource.url} target="_blank" style={{"color":"#337ab7","fontWeight": "bold"}}>{studentNotebookResource.title}</a>
                                    </li>
                                ))}
                            </ul>}
                        </Row>
                    </CollapsibleComponent>
                }
               
                {((this.props.content.assessment_thumbnails && this.props.content.assessment_thumbnails.length > 0) || (this.props.content.assessment_resources && this.props.content.assessment_resources.length > 0)) &&
                    <CollapsibleComponent collapsed={false} header="ASSESSMENTS" headerStyle={{marginTop:"50px", fontSize: "18px",fontWeight: 700, color: "#4A4A4A", borderBottom: "1px solid #CCD2DE", paddingBottom: "15px", paddingTop: "15px" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        <Row style={{ "paddingTop": "15px","minHeight": "100px"}}>
                            {this.props.content.assessment_thumbnails && this.props.content.assessment_thumbnails.length > 0 &&
                            <ul style={{"listStyleType":"none","display":"inline-flex","marginLeft":"-39px"}}>
                                {this.props.content.assessment_thumbnails.map((assessmentThumbnail,i)=>(
                                    <li style={{"margin":"10px 0","paddingRight":"60px","height": "100%","display": "inline","verticalAlign": "top","width": "auto"}}>
                                        <a href={assessmentThumbnail.videoUrl} target="_blank"><img src={constants.filesUrl + assessmentThumbnail.imageUrl.replace('../','')} style={{ "height": "125px","minHeight":"100%"}}/></a>
                                        <p style={{"margin":"10px 0","width": "75%"}}>{assessmentThumbnail.title}</p>
                                    </li>
                                ))}
                            </ul>}
                            {this.props.content.assessment_resources && <ul style={{"listStyleType":"none","marginLeft":"-39px"}}>
                                {this.props.content.assessment_resources.map((assessmentResource,i)=>(
                                    <li style={{"color":"#337ab7","fontWeight": "bold"}}>
                                        <a href={assessmentResource.url} target="_blank" style={{"color":"#337ab7","fontWeight": "bold"}}>{assessmentResource.title}</a>
                                    </li>
                                ))}
                            </ul>}
                        </Row>
                    </CollapsibleComponent>
                }

                {((this.props.content.additional_resources_thumbnails && this.props.content.additional_resources_thumbnails.length > 0) || (this.props.content.additional_resources && this.props.content.additional_resources.length > 0)) &&
                    <CollapsibleComponent collapsed={false} header="ADDITIONAL RESOURCES YOU MIGHT WANT TO USE" headerStyle={{marginTop:"50px", fontSize: "18px",fontWeight: 700, color: "#4A4A4A", borderBottom: "1px solid #CCD2DE", paddingBottom: "15px", paddingTop: "15px" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        <Row style={{ "paddingTop": "15px","minHeight": "100px"}}>
                            {this.props.content.additional_resources_thumbnails && this.props.content.additional_resources_thumbnails.length > 0 &&
                            <ul style={{"listStyleType":"none","display":"inline-flex","marginLeft":"-39px"}}>
                                {this.props.content.additional_resources_thumbnails.map((addlResourceThumbnail,i)=>(
                                    <li style={{"margin":"10px 0","paddingRight":"60px","height": "100%","display": "inline","verticalAlign": "top","width": "auto"}}>
                                        <a href={addlResourceThumbnail.videoUrl} target="_blank"><img src={constants.filesUrl + addlResourceThumbnail.imageUrl.replace('../','')} style={{ "height": "125px","minHeight":"100%"}}/></a>
                                        <p style={{"margin":"10px 0","width": "75%"}}>{addlResourceThumbnail.title}</p>
                                    </li>
                                ))}
                            </ul>}
                            {this.props.content.additional_resources && <ul style={{"listStyleType":"none","marginLeft":"-39px"}}>
                                {this.props.content.additional_resources.map((additionalResource,i)=>(
                                    <li style={{"color":"#337ab7","fontWeight": "bold"}}>
                                        <a href={additionalResource.url} target="_blank" style={{"color":"#337ab7","fontWeight": "bold"}}>{additionalResource.title}</a>
                                    </li>
                                ))}
                            </ul>}
                        </Row>
                    </CollapsibleComponent>
                }
            </div>
        );
    }
}

// const mapStateToProps = (state) => {
//     return {
//         myLibrary : state.MyLibrary

//     }
// }

export default connect(null, actions)(withRouter(OverviewContent))