import React, {Component} from 'react';
import avatar from '../../assets/images/Avatar.svg'

/*
  @prop tooltipText: The HTML to be rendered next to the assistant image inside the tooltip.
*/
class AssistantTooltipComponent extends Component {
  render(){
    return (
      <div>
        {/* <img width={this.props.imgWidth || 195} height={this.props.imgHeight || 220} style={{float: 'left'}} src={avatar}/> */}
        <div style={{overflow: 'hidden'}} dangerouslySetInnerHTML={{__html: this.props.tooltipText}}></div>
      </div>
    )
  }
}

export default AssistantTooltipComponent
