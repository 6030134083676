import React, { Component } from "react";
import "../../assets/styles/footer.scss";
import BulkAddUser from "../../components/AdminPortal/BulkAddUser";
import SingleFooterItem from "./SingleFooterItem";
import SchoolwideFooterItem from "./SchoolwideFooterItem";
import NotificationFooterItem from "./NotificationFooterItem";
import * as actions from "../../redux/actions";
import * as MackinActions from "../../redux/actions/mackin";
import { connect } from "react-redux";
import { withRouter, browserHistory } from "react-router";
import authAPI from "../../api/authAPI";
import "../../assets/styles/mobile.scss";
import evalIcon from "../../assets/icons/PNG/icon-logo-eval.png";
//import consultationIcon from "../../assets/icons/PNG/icon-consultation.png";
import manageIcon from "../../assets/icons/PNG/icon-manage.png";
import myBooksIcon from "../../assets/icons/PNG/mybooksicon.png";
import { confirmAlert } from "react-confirm-alert";
import Cookies from "universal-cookie";
import { Helmet } from "react-helmet";
import revampInfoImg from "../../assets/images/pop_up_6_JB.jpg";
import MackinBookModal from "../../components/Shared/MackinBookModal";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItemName: "",
      activeNotificationFooter: "",
      showTourModal: this.props.showTourModal || false,
      bulkAdd: false,
      mackin_id: null,
    };
    this.selectOneItem = this.selectOneItem.bind(this);
    this.selectNotificationFooter = this.selectNotificationFooter.bind(this);

    if (
      localStorage.first_time_login === "true" &&
      localStorage.user_type !== "Student"
    ) {
      this.props.launchTourModal();
    }

    this.user_type = localStorage.user_type;
    this.leftItems = [];
    this.centerItems = [];
    this.rightItems = [];

    // The url will be searched for each key, if found the value will be selected as the active page on the footer
    this.activeFooterMap = {
      projects: { name: "projects", isNotificationFooter: false },
      browse: { name: "browse", isNotificationFooter: false },
      library: { name: "library", isNotificationFooter: false },
      folders: { name: "library", isNotificationFooter: false },
      "sys-admin": { name: "sys-admin", isNotificationFooter: false },
      "my-class": { name: "my-class", isNotificationFooter: false },
      "school-admin": { name: "sys-admin", isNotificationFooter: false },
      admin: { name: "sys-admin", isNotificationFooter: false },
      home: { name: "home", isNotificationFooter: false },
      "user-profile": { name: "profile", isNotificationFooter: true },
    };

    if (
      localStorage.user_type === "Student" ||
      (this.props.location.state &&
        this.props.location.state.beginTour &&
        this.props.location.pathname === "/home")
    ) {
      this.leftItems.push({
        pageName: "user-profile",
        icon: "user",
        lite: "false",
        text: "Me",
      });

      if (localStorage.user_type === "Student") {
        this.centerItems.push({
          pageName: "browse/books",
          icon: "browse",
          text: "Browse",
        });
      } else {
        this.centerItems.push({
          pageName: "browse/books",
          icon: "home",
          text: "Home",
        });
      }

      this.centerItems.push({
        pageName: "home",
        icon: "schoolwide-silo",
        text: "Home",
      });

      this.centerItems.push({
        pageName: "library/books",
        icon: "books",
        text: "My Library",
      });

      this.rightItems.push({
        pageName: "user-profile",
        icon: "user",
        text: "MY ACCOUNT",
      });
    } else {
      if (localStorage.is_lite === "true" && localStorage.user_type !== 'Text Only') {
        this.centerItems.push({
          pageName: "browse/teaching-resources/all",
          icon: "home",
          text: "Home",
        });
      } else {
        this.leftItems.push({
          pageName: "planner",
          icon: "schoolwide-silo",
          text: "PROJECTS",
          lite: localStorage.is_lite,
        });

        this.centerItems.push({
          pageName: localStorage.user_type !== 'Text Only'? "browse": "browse/books",
          icon: "home",
          text: "Home",
        });
      }

      if (localStorage.is_lite !== "true") {
        this.centerItems.push({
          pageName: "library",
          icon: "books",
          text: "My Library",
          lite: localStorage.is_lite,
        });
      }

      this.centerItems.push({
        pageName: "my-class",
        icon: "group",
        text: "My Class",
        lite: localStorage.is_lite,
      });

      if (localStorage.user_type === "School Admin") {
        this.centerItems.push({
          pageName: "school-admin/" + localStorage.district_id,
          icon: "add-user",
          text: "ADMIN",
        });
      } else if (localStorage.user_type === "System Admin") {
        this.centerItems.push({
          pageName: "sys-admin",
          icon: "add-user",
          text: "ADMIN",
        });
      }

      this.rightItems.push({
        pageName: "user-profile",
        icon: "user",
        text: "MY ACCOUNT",
      });
    }
  }

  setActive = (props) => {
    // Search the active map for a key which is contained in the url. If found, then use the value in the map,
    // otherwise use "" to clear the footer.
    const selectedKey = Object.keys(this.activeFooterMap).filter(
      (key) => props.location.pathname.indexOf(key) !== -1,
      this
    )[0];
    if (selectedKey) {
      if (!this.activeFooterMap[selectedKey].isNotificationFooter) {
        this.selectOneItem(this.activeFooterMap[selectedKey].name);
      } else {
        this.selectNotificationFooter(this.activeFooterMap[selectedKey].name);
      }
    } else {
      this.setState({
        activeItemName: "",
        activeNotificationFooter: "",
      });
    }
  };

  getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    alert(ca[0]);
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
  };

  componentDidMount() {
    this.setActive(this.props);
    authAPI.getMySchoolDistrict().then((response) => {
      const district = response && response.data;
      if (district) {
        const { mackin_id } = district;
        this.setState({ mackin_id });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setActive(nextProps);
    if (nextProps.showTourModal) {
      this.showTourModal(); //Local displays modal. It will hide itself and state will be clear.
    } else {
      this.hideTourModal();
    }
  }

  selectOneItem(pageName) {
    this.setState({
      activeItemName: pageName,
      activeNotificationFooter: "",
    });
  }

  selectNotificationFooter(id) {
    this.setState({
      activeNotificationFooter: id,
      activeItemName: "",
    });
  }

  showTourModal = () => {
    this.props.allowClicksThruHere(true);
    this.props.launchTourModal();
    return this.setState({ showTourModal: true });
  };

  hideTourModal = () => {
    this.setState({ showTourModal: false });
    this.props.clearTourModal();
  };

  scheduleConsultation = () => {
    browserHistory.push("/Consultation");
  };

  importStudents = () => {
    browserHistory.push("/my-class/add-group");
  };

  byBook = (bookId) => {
    this.props.buyBookOnMackin(bookId);
  };

  clearMackinData = () => {
    this.props.clearMackinData();
  };

  onBulkAddUserCancel = () => {
    this.setState({ bulkAdd: false });
  };

  render() {
    const { book = {} } = this.props;
    const hostname = window.location.hostname;

    return (
      <div
        id="footer"
        className={localStorage.user_type === "Student" ? "student-footer" : ""}
      >
        <div
          className={
            localStorage.user_type === "Student"
              ? "student-footer left-footer-btns"
              : "left-footer-btns"
          }
        >
          {this.leftItems.map((item, i) => {
            return (
              <SchoolwideFooterItem
                key={i}
                text={item.text}
                icon={item.icon}
                pageName={item.pageName}
                lite={item.lite}
                activeItemName={this.state.activeItemName}
                selectOneItem={this.selectOneItem}
              />
            );
          })}
        </div>

        <div
          className={
            localStorage.user_type === "Student"
              ? "student-footer center-footer-btns"
              : "center-footer-btns"
          }
        >
          {this.centerItems.map((item, i) => {
            return (
              <SingleFooterItem
                key={i}
                text={item.text}
                icon={item.icon}
                pageName={item.pageName}
                lite={item.lite}
                activeItemName={this.state.activeItemName}
                selectOneItem={this.selectOneItem}
              />
            );
          })}
          {/* {localStorage.user_type === "Individual Teacher" ? (
            <div
              className="footer-btn-container"
              data-toggle="tooltip"
              data-html="true"
              title="Consultation"
              onClick={this.scheduleConsultation}
            >
              <div className="footer-schoolwide-image">
                <img
                  src={consultationIcon}
                  style={{ height: "23px", marginLeft: "4%", marginTop: "5%" }}
                  alt=""
                />
              </div>
              <div
                className="footer-schoolwide-text"
                style={{ marginTop: "12px" }}
              >
                CONSULTATION
              </div>
            </div>
          ) : null} */}
          {this.state.mackin_id != null &&
            localStorage.user_type === "Print Only" && (
              <>
                <div
                  className="footer-btn-container"
                  data-html="true"
                  title="My Books"
                  onClick={() => this.byBook(1)}
                >
                  <div className="footer-schoolwide-image">
                    <img
                      src={myBooksIcon}
                      style={{
                        height: "23px",
                        marginLeft: "4%",
                        marginTop: "5%",
                      }}
                      alt=""
                    />
                  </div>
                  <div
                    className="footer-schoolwide-text"
                    style={{ marginTop: "12px" }}
                  >
                    My Books
                  </div>
                </div>
                <MackinBookModal onClose={this.clearMackinData} book={book} />
              </>
            )}
          {localStorage.user_type !== "Student" &&
          localStorage.user_type !== "Individual Teacher" && localStorage.user_type !== "Text Only" ? (
            <a href={(hostname.includes("stg700")?'https://eval-stg700.schoolwide.com/eval/?sessionId='+localStorage.user_id:'https://prodcms.schoolwide.com/eval/?sessionId='+localStorage.user_id)}
            title="Link to eval.schoolwide.com" target="_blank">
              <img
                src={evalIcon}
                style={{ height: "60px", marginTop: "11px" }}
                alt=""
              />
            </a>
          ) : null}
          {this.state.bulkAdd && (
            <BulkAddUser
              onCancel={this.onBulkAddUserCancel}
              userType={this.props.userType}
              districtId={this.props.districtId}
              bulkAddType={this.props.bulkAddType}
              refreshUsers={this.refreshSearch}
            />
          )}
          {localStorage.user_type === "System Admin" ? (
            <a
              href={(hostname.includes("stg700")?'https://stagecms.schoolwide.com/manage/?sessionId='+localStorage.user_id:'https://prodcms.schoolwide.com/manage/?sessionId='+localStorage.user_id)}
              target="_blank"
              title="Link to manage"
              style={{ textAlign: "center", margin: "auto 10px" }}
              rel={"noreferrer"}
            >
              <div>
                <img src={manageIcon} style={{ height: "25px" }} alt="" />
              </div>
              <span
                style={{
                  height: "11px",
                  color: "#FFFFFF",
                  fontFamily: "ProximaNova",
                  fontSize: "8px",
                  fontWeight: "600",
                  lineHeight: "2px",
                  textAlign: "center",
                }}
              >
                TRIAL USER
              </span>
            </a>
          ) : null}
        </div>

        <div className="right-footer-btns">
          {this.rightItems.map((item, ind) => {
            return (
              <NotificationFooterItem
                key={ind}
                item={item}
                activeNotificationFooter={this.state.activeNotificationFooter}
                pageName={item.pageName}
                selectNotificationFooter={this.selectNotificationFooter}
                showTourModal={this.showTourModal}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showTourModal: state.JoyrideReducer.displayTourModal,
    book: state.Mackin.book,
    errors: state.Mackin.errors,
  };
};

export default connect(mapStateToProps, { ...actions, ...MackinActions })(
  withRouter(Footer)
);
