import React, { Component } from "react";
import FilterCollapsible from "../../containers/FilterCollapsible";
import constants from "../../constants/index";
import { cloneDeep, debounce, without } from "lodash";

export class FilterMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      joyrideResetFilters: false,
      noMinValue: false,
      noMaxValue: false,
      selectedFilters: { filterList: [] },
      filtersOpenedList: [],
    };
    this.getFiltered = this.getFiltered.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props?.filters?.length !== this.state?.filtersOpenedList?.length &&
      this.props?.filters?.length &&
      !this.state.filtersOpenedList.length
    ) {
      let filtersOpenedList = [];
      this.props.filters.map((filter) => filtersOpenedList.push(false));
      this.setState({ filtersOpenedList });
    }
    if (
      this.props?.closeAllFilters &&
      this.state.filtersOpenedList.find((item) => item === true)
    ) {
      this.props.updateCloseAllFilters();
      let filtersOpenedList = [];
      this.state.filtersOpenedList.map((filter) =>
        filtersOpenedList.push(false)
      );
      this.setState({ filtersOpenedList });
    } else if (this.props?.closeAllFilters) {
      this.props.updateCloseAllFilters();
    }
  }

  toggleShow = (index) => {
    if (!this.state.filtersOpenedList[index]) {
      let filtersOpenedList = [...this.state.filtersOpenedList];
      this.state.filtersOpenedList.map((item, i) =>
        i === index
          ? (filtersOpenedList[i] = true)
          : (filtersOpenedList[i] = false)
      );
      this.setState({ filtersOpenedList });
    }
  };

  filterLoadShouldStartJoyrideIfState = () => {
    //If joyride isn't running and should when filters are done loading, do this.
    if (
      this.state.joyrideResetFilters &&
      this.state.showFilter &&
      !this.props.joyrideShouldRun
    ) {
      debounce(this.props.nextJoyrideStep, 300, {
        leading: false,
        trailing: true,
      })();
      debounce(this.props.startJoyride, 500, {
        leading: false,
        trailing: true,
      })();
      this.setState({ joyrideResetFilters: false });
    }
    return true;
  };

  getSelectedFilters() {
    let x = this.props.selectedFilters.filterList
      ? this.props.selectedFilters.filterList
      : this.props.selectedFilters;

    if (
      JSON.parse(window.sessionStorage.getItem("selectedFilters")) != null &&
      JSON.parse(window.sessionStorage.getItem("selectedFilters")).length > 0
    ) {
      x = JSON.parse(window.sessionStorage.getItem("selectedFilters"));
    }

    return x || [];
  }

  getFiltered(filterObj, isChecked, isRemove) {
    let queryObj = cloneDeep(this.props.queryObj);
    if (queryObj[filterObj.query] === undefined) queryObj[filterObj.query] = [];
    if (filterObj.query !== "audiobook" && filterObj.query !== "lexileLevel") {
      if (isChecked) {
        queryObj[filterObj.query].push(filterObj._id);
      } else {
        queryObj[filterObj.query] = without(
          queryObj[filterObj.query],
          filterObj._id
        );
      }
    } else if (filterObj.query === "audiobook") {
      queryObj.audiobook = [isChecked];
    } else {
      queryObj.minLexileLevel = [filterObj.min];
      queryObj.maxLexileLevel = [filterObj.max];
    }
    let buttonClickValue = constants.convertToStrandNumber(this.props.strand);
    this.props.onFilterChange(
      filterObj,
      isChecked,
      isRemove,
      buttonClickValue,
      this.props.typeOfResource,
      queryObj
    );
  }

  removeLexileLevel = (remove, filterValue) => {
    if (remove === "min") {
      filterValue.min = 0;
      this.setState({ noMinValue: true }, () =>
        setTimeout(() => this.setState({ noMinValue: false }), 2000)
      );
    }
    if (remove === "max") {
      filterValue.max = 2000;
      this.setState({ noMaxValue: true }, () =>
        setTimeout(() => this.setState({ noMaxValue: false }), 2000)
      );
    }
    this.getFiltered(filterValue, false, "removeItem");
  };

  render() {
    return (
      <div>
        {this.props?.filters?.length &&
          this.filterLoadShouldStartJoyrideIfState() && (
            <div
              className={`filter-options ${
                this.props.location.pathname.endsWith("all")
                  ? "filters-disabled"
                  : ""
              }`}
            >
              {this.props.filters.map((optionValue, i) => {
                const mappedFilters = optionValue?.filters.map((filter) => {
                  return {
                    ...filter,
                    show: true,
                  };
                });
                return (
                  <div key={"filterCollapsible_" + optionValue.tagName}>
                    <FilterCollapsible
                      header={optionValue.header}
                      shouldReset={this.props.shouldReset}
                      tagName={optionValue.tagName}
                      queryString={optionValue.queryString}
                      filterFunction={this.getFiltered}
                      content={mappedFilters}
                      minRange={this.state.noMinValue}
                      maxRange={this.state.noMaxValue}
                      getSelectedFilters={this.getSelectedFilters}
                      joyrideNeedsThisOpen={this.state.filtersOpenedList[i]}
                      index={i}
                      onToggleShow={() => this.toggleShow(i)}
                    />
                  </div>
                );
              })}
            </div>
          )}
      </div>
    );
  }
}
