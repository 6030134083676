import React,{Component} from  'react'
import CollapsibleComponent from '../../containers/Details/CollapsibleComponent'
import TextContent from '../../containers/Details/TextContent'
import ImageDetails from '../../containers/Details/ImageDetailsComponent'
import {markdownHelper} from '../../markdown/MarkdownHelper'

class CorrelationsOverview extends Component{
    constructor (props) {
        super(props);
        this.state = {
            texts: this.props.texts,
            forms: this.props.forms,
            description: this.props.description,
            grades: this.props.grades
        }
    }

    render(){
        return(
            <div className="list-of-items">
                {this.state.description && this.state.description.text.length > 0 &&
                   <CollapsibleComponent collapsed={false} header="DESCRIPTION" headerStyle={{fontSize: "17px",letterSpacing:1.5,height:20.5, fontWeight:'bold', color: "#4A4A4A", borderBottom: "1px solid rgba(151, 151, 151,0.5)", paddingBottom: "35px", paddingTop: "15px",fontFamily:"ProximaNova" }} openToggleButton={<img src={require('../../assets/icons/SVG/my-solid-arrow-right.png')}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={require('../../assets/icons/SVG/my-solid-arrow.png')}  className="arrow-icon  rss opacity"/>}>
                       <div className="text-in-collapsible-component" dangerouslySetInnerHTML={{__html: markdownHelper.toHTML(this.state.description.text)}} />
                   </CollapsibleComponent>
                }
                {this.state.grades && this.state.grades.length > 0 &&
                   <CollapsibleComponent collapsed={false} header="GRADES" headerStyle={{fontSize: "17px",letterSpacing:1.5,height:20.5, fontWeight:'bold', color: "#4A4A4A", borderBottom: "1px solid rgba(151, 151, 151,0.5)", paddingBottom: "35px", paddingTop: "15px",fontFamily:"ProximaNova" }} openToggleButton={<img src={require('../../assets/icons/SVG/my-solid-arrow-right.png')}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={require('../../assets/icons/SVG/my-solid-arrow.png')}  className="arrow-icon  rss opacity"/>}>
                        <div className="text-in-collapsible-component">
                          {this.state.grades}
                        </div>
                   </CollapsibleComponent>
                }
                {this.state.texts && this.state.texts.length > 0 &&
                   <CollapsibleComponent collapsed={false} header="TEXTS" headerStyle={{fontSize: "17px",letterSpacing:1.5,height:20.5, fontWeight:'bold', color: "#4A4A4A", borderBottom: "1px solid rgba(151, 151, 151,0.5)", paddingBottom: "35px", paddingTop: "15px",fontFamily:"ProximaNova" }} openToggleButton={<img src={require('../../assets/icons/SVG/my-solid-arrow-right.png')}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={require('../../assets/icons/SVG/my-solid-arrow.png')}  className="arrow-icon  rss opacity"/>}>
                        <TextContent requestType='assessments' content={{mentorTexts: this.state.texts}} />
                   </CollapsibleComponent>
                }
                {this.state.forms && this.state.forms.length > 0 &&
                    <CollapsibleComponent collapsed={false} header="FORMS" headerStyle={{fontSize: "17px",letterSpacing:1.5,height:20.5, fontWeight:'bold', color: "#4A4A4A", borderBottom: "1px solid rgba(151, 151, 151,0.5)", paddingBottom: "35px", paddingTop: "15px",fontFamily:"ProximaNova" }} openToggleButton={<img src={require('../../assets/icons/SVG/my-solid-arrow-right.png')}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={require('../../assets/icons/SVG/my-solid-arrow.png')}  className="arrow-icon  rss opacity"/>}>
                        <ImageDetails componentData={this.state.forms} componentType='files' />
                    </CollapsibleComponent>
                }
            </div>
        );
    };
}

export default CorrelationsOverview
