import {
    SAVE_UNIT_REQUEST,
    SAVE_UNIT_SUCCESS,
    SAVE_UNIT_FAILURE
} from '../actions';


const initialState = {};

const SaveUnitReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_UNIT_REQUEST:
            return Object.assign({},state, {asyncStatus:  'PENDING'});
        case SAVE_UNIT_SUCCESS:
            return Object.assign({},state, {asyncStatus:  'SUCCESS', data: action.payload});
        case SAVE_UNIT_FAILURE:
            return Object.assign({},state, {asyncStatus:  'FAILURE', data: action.payload});
        default:
            return state;
    }
};

export default SaveUnitReducer;