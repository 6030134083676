import React,{Component} from  'react'
import {Button,ButtonGroup } from 'react-bootstrap'
import {withRouter} from 'react-router'


// Not used for now
/*const ButtonItems =[
    {
        name : "INFO"
    },
    {
        name : "STANDARDS"
    },
    {
        name : "PROGRESS"
    }
];*/

class NewUnitButtonGroup extends Component{

    render(){
        //console.log(this.props.selectedTab);
        
        return(
            <div>
                <ButtonGroup id="nav-buttons" className="align-button-grp">
                    
                  
                    <Button className='lesson-button'
                            style={{backgroundColor:this.props.selectedTab==="INFO"? "#9B9B9B":'#FFFFFF'}}
                            onClick={() => {this.props.selectTab("INFO"); }}>
                            <span className="lesson-button-text" style={{ color : this.props.selectedTab === "INFO" ? '#FFFFFF': '#9B9B9B'}}>
                                INFO
                            </span>
                    </Button>
                   
                    <Button className='lesson-button'
                            style={{backgroundColor:this.props.selectedTab==="STANDARDS"? "#9B9B9B":'#FFFFFF'}}
                            onClick={() => {this.props.selectTab("STANDARDS");}} >
                            <span className="lesson-button-text" style={{ color : this.props.selectedTab === "STANDARDS" ? '#FFFFFF': '#9B9B9B'}}>
                                STANDARDS
                            </span>
                    </Button>

                    {/*<Button className='lesson-button'
                            style={{backgroundColor:this.props.selectedTab=="PROGRESS"? "#9B9B9B":'#FFFFFF'}}
                            onClick={() => {this.props.selectTab("PROGRESS"); }} >
                            <span className="lesson-button-text"
                                  style={{ color : this.props.selectedTab == "PROGRESS" ? '#FFFFFF': '#9B9B9B'}}
                            >PROGRESS</span></Button>*/}
                </ButtonGroup>
                 
            </div>
        );
    }
}

export default withRouter(NewUnitButtonGroup)