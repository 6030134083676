class AnnotationsHelper {

    /***
     * Takes html and adds annotations and highlights
     * @param html HTML to format
     * @param section Name of section
     * @param anotations Array of Annotation Objects
     * @returns {string} Formatted html
     */
    annotate(html, section, anotations) {
        let out = html;
        if (anotations !== undefined) {
            for (let i = 0; i < anotations.length; i++) {
                if (anotations[i].data.section === section) {
                    if (anotations[i].annotation_type === "Highlight" || anotations[i].annotation_type === "Note") {
                        // eslint-disable-next-line
                        let parser = new htmlParser();
                        parser.parseHTML(out);
                        //console.log("GO");
                        //console.log(anotations[i].data.target_text);
                        //console.log(parser.tree);
                        parser.annotate(anotations[i].data.target_text, anotations[i].data.repeat, anotations[i].annotation_type, anotations[i].data.color, anotations[i]._id);
                        //console.log(parser.doc.body);
                        //console.log("Done")
                        out = parser.toHTML();
                    }
                }
            }
        }

        return out;
    }

    /***
     * Get the parent node with the given class name.
     * @param node {Node} HTML node to start at.
     * @param className {string} Class name to find
     * @returns {Node} HTML parent node with given class name
     */
    getRoot(node, className) {
        if (node.parentNode !== null) {
            if(node.parentNode.classList.contains(className) === false) {
                return this.getRoot(node.parentNode,className);
            }
            else {
                return node.parentNode;
            }
        }
        else {
            return null;
        }
    }

    /***
     * Finds the ocurrance of the selected text inside the root node.
     * @param selectedText {string} Selected text to annotate.
     * @param rootNode {Node} Selection root node.
     * @param anchorNode {Node} Selection anchor node.
     * @param anchorOffset {number} Selection anchor offset.
     * @returns {number} Annotation repeat number.
     */
    findOcurrance(selectedText, rootNode, anchorNode, anchorOffset) {
        // eslint-disable-next-line
        let parser = new htmlParser();

        //Quick Test
        if (parser.findLocs(selectedText, rootNode.innerText).length === 1) {
            return 0;
        }
        else {
            parser.parseNode(rootNode);
            return parser.findOcurrance(selectedText, anchorNode, anchorOffset);
        }
    }

    /***
     * Cleans text for annotation selection.
     * @param text {string} Text to clean.
     * @returns {string} Cleaned text.
     */
    cleanString(text) {
        if (text.charAt(0) === "\n") {
            text = text.replace("\n", "");
        }

        // eslint-disable-next-line
        text = text.replace(/(\n+)/gm," ").replace(/(  +)/gm," ");
        return text;
    }
}

class htmlParser {
    constructor() {
        this.parser = new DOMParser();
        this.doc = null;
        this.tree = [];
    }

    parseHTML(html) {
        this.doc = this.parser.parseFromString(html, "text/html");
        this.buildTree();
    }

    parseNode(node) {
        this.doc = node;
        this.buildTree(node);
    }

    buildTree(node = null) {
        if (node === null) {
            this.tree = [];
            node = this.doc.body;
        }

        if (node.hasChildNodes()) {
            //console.log(node.childNodes.length);
            for (let i = 0; i < node.childNodes.length; i++) {
                //console.log(node.childNodes[i]);
                this.buildTree(node.childNodes[i]);
            }
        }
        else {
            //console.log("HI")
            //console.log(node)
            if (node.nodeValue !== null) {
                this.tree.push({
                    node: node,
                    text: node.nodeValue
                });
            }
        }
    }

    annotate(text, repeat, type, color, id) {
        let found = false;
        let nodesToEdit = [];

        //console.log(text);
        //console.log("Quick Pass Start");

        //Quick Pass
        let quickFound = 0;
        for(let i = 0; i< this.tree.length; i++) {
            if (this.tree[i].text.indexOf(text) >= 0) {
                //console.log(this.tree[i].node);

                let locs = this.findLocs(text, this.tree[i].text);
                if (locs.length > 0 && repeat < locs.length + quickFound) {
                    nodesToEdit.push({
                        node: this.tree[i],
                        start: locs[repeat - quickFound],
                        end: locs[repeat - quickFound] + text.length
                    });
                    found = true;
                    break;
                }
                else {
                    quickFound += locs.length;
                }
            }
        }

        //console.log("Quick Pass Done");

        //Full Pass
        if (found === false) {
            let fullText = "";
            for(let i = 0; i< this.tree.length; i++) {
                fullText += this.tree[i].text;
            }

            //console.log(fullText);

            //fullText = fullText.replace(/(\r)/gm,"");
            fullText = fullText.replace(/(\n+)/gm," ");
            // eslint-disable-next-line
            fullText = fullText.replace(/(  +)/gm," ");
            let locs = this.findLocs(text, fullText);
            if(locs.length > 0 && repeat < locs.length) {
                //console.log("Got Text");

                let temp = "";
                for(let i = 0; i< this.tree.length; i++) {
                    // eslint-disable-next-line
                    let tempNew = this.tree[i].text.replace(/(\n+)/gm," ").replace(/(  +)/gm," ");
                    temp += tempNew;
                    // eslint-disable-next-line
                    temp = temp.replace(/(  +)/gm," ");
                    if(temp.length >= locs[repeat] && temp.length - tempNew.length <= locs[repeat] + text.length) {

                        let start = 0;
                        let end = 0;

                        if (locs[repeat] < (temp.length - tempNew.length))
                        {
                            start = 0;
                        }
                        else {
                            start = locs[repeat] - (temp.length - tempNew.length);
                        }

                        if (locs[repeat] + text.length <= temp.length) {
                            end =  locs[repeat] + text.length - (temp.length - tempNew.length);
                        }
                        else {
                            end = tempNew.length;
                        }

                        nodesToEdit.push({
                            node: this.tree[i],
                            start: start,
                            end: end
                        });

                        //console.log("Got Node");
                        //console.log(this.tree[i].text);
                    }
                }

                found = true;
            }
            else {
                console.log("Can't Find");
                console.log(text);
                console.log(fullText);
            }
        }

        //console.log("Full Pass Done");

        // Format HTML
        if (found) {
            for(let i = 0; i<nodesToEdit.length; i++) {
                let oldText = nodesToEdit[i].node.text;

                let node = nodesToEdit[i].node.node;
                let start = nodesToEdit[i].start;
                let end = nodesToEdit[i].end;
                let parentNode = node.parentNode;

                //console.log("Start: " + start);
                //console.log("  End: " + end);

                if (start > 0) {
                    parentNode.insertBefore(document.createTextNode(oldText.substr(0, start)), node);
                }

                if (type === "Highlight") {
                    let span = document.createElement("span");
                    span.className = "editable-annotation highlight-" + color;
                    span.textContent = oldText.substr(start, end - start);
                    span.setAttribute("data-id", id);
                    parentNode.insertBefore(span, node);
                }
                else if (type === "Note") {

                    if (i === 0) {
                        let icon = document.createElement("i");
                        icon.className = "editable-annotation icon-note icon-icon-add-note";
                        icon.setAttribute("data-id", id);
                        parentNode.insertBefore(icon, node);
                    }

                    let span = document.createElement("span");
                    span.className = "editable-annotation note-style";
                    span.textContent = oldText.substr(start, end - start);
                    span.setAttribute("data-id", id);
                    parentNode.insertBefore(span, node);
                }


                if (end - start < oldText.length) {
                    parentNode.insertBefore(document.createTextNode(oldText.substr(end)), node);
                }

                parentNode.removeChild(node);
            }
        }

        //console.log("Format HTML");

    }

    findOcurrance(selectedText, anchorNode, anchorOffset) {
        let preText = "";
        let repeat = -1;
        for (let i = 0; i < this.tree.length; i++) {
            if (this.tree[i].node === anchorNode) {
                break;
            }
            else {
                // eslint-disable-next-line
                let temp = this.tree[i].text.replace(/(\n+)/gm, " ").replace(/(  +)/gm, " ");
                preText += temp;
                // eslint-disable-next-line
                preText = preText.replace(/(  +)/gm, " ");
            }
        }

        let preLocs = this.findLocs(selectedText, preText);
        repeat += preLocs.length;

        let  locs = this.findLocs(selectedText, anchorNode.nodeValue);

        for (let i = 0; i < locs.length; i++) {
            if (locs[i] <= anchorOffset) {
                repeat += 1;
            }
        }

        if (repeat === -1) {
            repeat = 0;
        }

        return repeat;
    }

    findLocs(textToFind, allText) {
        let locs = [];
        if (textToFind !== "") {
            for (let loc = allText.indexOf(textToFind); loc !== -1; loc = allText.indexOf(textToFind, loc + 1)) {
                locs.push(loc);
            }
        }
        return locs;
    }

    toHTML() {
        return this.doc.body.innerHTML
    }


}

export let annotationsHelper = new AnnotationsHelper();