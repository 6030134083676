import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import SimpleSlider from "../../containers/carousel";
import { browserHistory } from "react-router";
import authAPI from "../../api/authAPI";
import constants from "../../constants";
import BrowsePageContent from "../shared/BrowsePageContent";
import _ from "lodash";
import steps from "./stepsUnits";
import ResponsiveNavBtns from "./ResponsiveNavBtns";
import ResponsiveResourcesNew from "./ResponsiveResourcesNew";
import ResourceHeading from "../../components/ResourceHeading";
import AddToFolderModal from "../../components/AddToFolder/AddToFolderModal";
import { SnackBarModule } from "../../components/Shared";

let invertMap = [
  "Featured",
  "Reading",
  "Writing",
  "Grammar",
  "Foundational",
  "E-Learning",
];

class UnitsContent extends Component {
  constructor(props) {
    super(props);
    this._loadMore = this._loadMore.bind(this);
    this.state = {
      isFeatured: false,
      isElearning: false,
      isFoundational: false,
      searchedData: [],
      hasMore: true,
      searchTotal: 0,
      multiSelectMode: false,
      areAllSelected: false,
      selectedItems: [],
      queryValue: "",
      isBackButtonPressed: false,
      selectedResourceId: "",
      singleUnitDetails: "",
      isAddToFolderModalOpen: false,
      addToFolderSuccess: false,
      addToFolderError: false,
      addToFolderErrorMsg:"",
      addToLibrarySuccess: false,
      addToLibraryError: false,
      searchActive: false,
      getSearchNextPage: false,
    };

    this.continueTour = _.once(() => this.props.resetJoyride(steps.steps, 1));
    this.loadSteps = _.once(() => this.props.joyrideLoadSteps(steps.steps));
    this.asyncNextStep = _.once(() =>
      setTimeout(this.props.nextJoyrideStep, 300)
    );
    window.sessionStorage.setItem("selectedFilters", JSON.stringify([]));
  }

  handlingClick(buttonValue) {
    this.setState({
      buttonClicked: buttonValue,
    });
  }

  _handleAPI(pageNumber, handle) {
    let strandFromUrl = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );
    if (handle === 0) {
      this.setState({
        isFeatured: true,
        isElearning: false,
        isFoundational: false,
      });
      for (let i = 1; i <= 3; i++) this.props.getFeaturedUnitsActions(i);
    } else if (
      this.props.params.strand === "e-learning" &&
      strandFromUrl === "e-learning"
    ) {
      this.setState({
        isElearning: true,
        isFoundational: false,
        isFeatured: false,
      });
      this.props.getElearningUnitsActions({ strand: 4 });
    } else if (
      this.props.params.strand === "foundational" &&
      strandFromUrl === "foundational"
    ) {
      this.setState({
        isElearning: false,
        isFoundational: true,
        isFeatured: false,
      });
      this.props.getFoundationalUnitsActions({ strand: 4 });
    } else {
      this.setState({
        isFeatured: false,
        isElearning: false,
        isFoundational: false,
      });
      this.props.getUnitsAction(pageNumber, constants.caseMap[strandFromUrl]);
    }
  }

  componentWillMount() {
    this.loadSteps();
  }

  componentDidMount() {
    this._handleAPI(1, constants.caseMap[this.props.params.strand]);
  }

  componentWillReceiveProps(next) {
    if (this.state.searchActive && next.units.asyncStatus === "SUCCESS") {
      const { results, total, nextPage } = next.units?.data;
      this.updateFromSearch(results, total, false, nextPage);
    } 

    if(!this.state.searchActive  && next.units.asyncStatus === "SUCCESS") {
      this.setState({
        hasMore: next.units?.data?.nextPage !== -1 ? true : false,
        nextPage: next.units?.data?.nextPage
      })
    }

    window.onpopstate = (e) => {
      this.setState({
        isBackButtonPressed: true,
      });
    };
    let path = constants.caseMap[next.params.strand];

    if (this.props.params.strand !== next.params.strand) {
      this.props.saveLocation("/browse/units/" + next.params.strand);
      this.setState(
        {
          path,
          queryValue: "",
        },
        () => this._handleAPI(1, constants.caseMap[next.params.strand])
      );
    } else if (
      next.finalFilters &&
      next.finalFilters.filterList &&
      constants.caseMap[this.props.params.strand] !== 0
    ) {
      let queryString = constants.getQueryStringFromFilterList(
        next.finalFilters.filterList
      );
      if (this.state.queryValue !== queryString) {
        let filterInfinite = constants.caseMap[this.props.params.strand];
        let strandFromUrl = window.location.pathname.substring(
          window.location.pathname.lastIndexOf("/") + 1
        );

        if (
          this.props.params.strand === "e-learning" &&
          strandFromUrl === "e-learning"
        ) {
          this.setState({
            queryValue: queryString,
            isElearning: true,
            isFoundational: false,
            isFeatured: false,
          });
          this.props.getElearningUnitsActions({
            strand: 4,
            query: queryString,
            isBackButtonPressed: this.state.isBackButtonPressed,
          });
          if (this.props.isJoyrideRunning) this.asyncNextStep();
        } else if (
          this.props.params.strand === "foundational" &&
          strandFromUrl === "foundational"
        ) {
          this.setState({
            queryValue: queryString,
            isElearning: false,
            isFoundational: true,
            isFeatured: false,
          });
          this.props.getElearningUnitsActions({
            strand: 4,
            query: queryString,
            isBackButtonPressed: this.state.isBackButtonPressed,
          });
          if (this.props.isJoyrideRunning) this.asyncNextStep();
        } else {
          this.props.getUnitsAction(
            1,
            filterInfinite,
            queryString,
            this.state.isBackButtonPressed
          );
          this.setState({
            queryValue: queryString,
            isElearning: false,
            isFoundational: true,
            isFeatured: false,
          });
          if (this.props.isJoyrideRunning) this.asyncNextStep();
        }
      }
    }

    if (next.location.state && next.location.state.beginTour) {
      this.setState({ beginTour: false });
      this.continueTour();
    }

    if (next.singleUnitDetails) {
      if (next.singleUnitDetails.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next.singleUnitDetails.asyncStatus === "FAILURE") {
        this.setState({ singleUnitDetails: "FAILURE" });
      } else if (
        next.singleUnitDetails.asyncStatus === "SUCCESS" &&
        next.singleUnitDetails.data.status === "FAILURE FOR PRINT ONLY USER"
      ) {
        this.setState({ status: "FAILURE FOR PRINT ONLY USER" });
      } else if (
        next.singleUnitDetails.asyncStatus === "SUCCESS" &&
        next.singleUnitDetails.data.status === "FAILURE FOR NOT APPROVED"
      ) {
        this.setState({ status: "FAILURE FOR NOT APPROVED" });
      } else if (next.singleUnitDetails.asyncStatus === "SUCCESS") {
        this.setState({
          singleUnitDetails: next.singleUnitDetails.data,
          status: "SUCCESS",
          selectedResourceName: next.singleUnitDetails.data.name,
        });
      }
    }
  }

  _loadMore() {
    if (
      this.state.searchActive &&
      !this.state.getSearchNextPage &&
      this.state.nextPage !== -1 &&
      this.state.hasMore
    ) {
      setTimeout(() => {
        this.setState({ getSearchNextPage: true });
      }, 1000);
    } else {

      if (this.props.units?.asyncStatus === "SUCCESS") {
        setTimeout(() => {
          if (
            this.props.units?.data?.total >
            this.props.units?.data?.results?.length
          ) {
            let strandInfinite = constants.caseMap[this.props?.params?.strand],
              filtersSet =
                this.props.finalFilters &&
                this.props.finalFilters.filterList &&
                this.props.finalFilters.filterList.length > 0,
              eitherFiltersSetOrQueryEmpty =
                (filtersSet && this.state.queryValue !== "") ||
                (!filtersSet && this.state.queryValue === "");

            let strandFromUrl = window.location.pathname.substring(
              window.location.pathname.lastIndexOf("/") + 1
            );

            if (
              this.props.params.strand !== "featured" &&
              strandFromUrl !== "featured" &&
              strandFromUrl !== "e-learning" &&
              strandFromUrl !== "foundational" &&
              eitherFiltersSetOrQueryEmpty
            ) {
              this.props.getMoreUnitsAction({
                pageNumber: this.props.units.data.currentPage + 1,
                strand: strandInfinite,
                query: this.state.queryValue,
              });
            } else if (
              this.props.params.strand === "e-learning" &&
              strandFromUrl === "e-learning"
            ) {
              this.props.getMoreElearningUnitsAction({
                pageNumber: this.props.units.data.currentPage + 1,
                strand: strandInfinite,
                query: this.state.queryValue,
              });
              this.setState({
                isElearning: true,
                isFoundational: false,
                isFeatured: false,
              });
            } else if (
              this.props.params.strand === "foundational" &&
              strandFromUrl === "foundational"
            ) {
              this.props.getMoreFoundationalUnitsAction({
                pageNumber: this.props.units.data.currentPage + 1,
                strand: strandInfinite,
                query: this.state.queryValue,
              });
              this.setState({
                isElearning: false,
                isFoundational: true,
                isFeatured: false,
              });
            }
          }
        }, 1000);
      }
    }
  }

  handleClickForResourcesThumbnail = (item) => {
    browserHistory.push(
      `/units/${item?._id ?? item?.unit_id ?? this.state.selectedResourceId}`
    );
  };

  addMultipleItems = (aid) => {
    this.state.multipleItems.push(aid);
    this.setState({ multipleItems: this.state.multipleItems });
  };

  deleteItem = (aid) => {
    let idx = this.state.multipleItems.indexOf(aid);
    this.state.multipleItems.splice(idx, 1);
    this.setState({ multipleItems: this.state.multipleItems });
  };

  handleClickForResourcesAddBtn = function (item) {
    if (this.props.multiSelectMode) {
      if (this.props.multipleItems.indexOf(item._id) === -1) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        this.props.deleteItem(item._id, item.type);
      }
    } else {
      //This replaces "showTooltip" in popover.
      this.setState({
        isTooltipActive: true,
        success: false,
        showDatePicker: false,
      });
    }
  };

  handleSelectResourceClick = (item) => {
    if (this.props.multiSelectMode) {
    } else if (item?._id) {
      this.props.getSingleUnitDetailsAction(item._id);
      this.setState({ selectedResourceId: item._id });
    }
  };

  updateFromSearch = (data, total, clearSearch, nextPage) => {
    this.setState({
      searchTotal: total ?? data?.length ?? 0,
      searchedData: data ?? [],
      searchActive: clearSearch ? false : true,
      hasMore: nextPage !== -1 ? true : false,
    });
  };

  handleSuccessSnackBar = () => {
    this.handleCancel();
    this.setState({ isAddToFolderModalOpen: false });
    this.setState({ addToFolderSuccess: true });
    setTimeout(() => {
      this.setState({ addToFolderSuccess: false });
    }, 3000);
  };

  handleErrorSnackBar = (msg) => {
    this.handleCancel();
    this.setState({ isAddToFolderModalOpen: false });
    this.setState({ 
      addToFolderError: true,
      addToFolderErrorMsg: msg
    });
    setTimeout(() => {
      this.setState({ addToFolderError: false });
    }, 3000);
  };

  handleSuccessSnackBarLibrary = () => {
    this.handleCancel();
    this.setState({ addToLibrarySuccess: true });
    setTimeout(() => {
      this.setState({ addToLibrarySuccess: false });
    }, 3000);
  };

  handleErrorSnackBarLibrary = () => {
    this.handleCancel();
    this.setState({ addToLibraryError: true });
    setTimeout(() => {
      this.setState({ addToLibraryError: false });
    }, 3000);
  };

  handleBulkAddToFolderModalOpen = () => {
    this.setState({ isAddToFolderModalOpen: true });
  };

  handleBulkAddToFolderModalClose = () => {
    this.setState({ isAddToFolderModalOpen: false });
  };

  handleCancel = () => {
    this.handleDeselectAll();
    this.setState({ multiSelectMode: !this.state.multiSelectMode });
  };

  handleMultiSelect = (item) => {
    const copyArr = [...this.state.selectedItems];
    if (copyArr.length !== 0) {
      if (!copyArr.filter((e) => e.item_id === item._id).length > 0) {
        copyArr.push({
          item_id: item._id,
          item_name: item.name,
          item_type: "Unit",
        });
        let uniqueItems = [
          ...new Map(copyArr.map((v) => [v.item_id, v])).values(),
        ];
        this.setState({ selectedItems: uniqueItems });
      } else {
        copyArr.splice(
          copyArr.findIndex((x) => x.item_id === item._id),
          1
        ); //deleting
        this.setState({ selectedItems: copyArr });
      }
    } else {
      copyArr.push({
        item_id: item._id,
        item_name: item.name,
        item_type: "Unit",
      });
      let uniqueItems = [
        ...new Map(copyArr.map((v) => [v.item_id, v])).values(),
      ];
      this.setState({ selectedItems: uniqueItems });
    }
  };

  handleSelectAll = () => {
    this.setState({ areAllSelected: true });
    const copyArr = [];
    this.props?.units?.data?.results?.forEach((item) => {
      copyArr.push({
        item_id: item._id,
        item_name: item.name,
        item_type: "Unit",
      });
    });
    this.setState({ selectedItems: copyArr });
  };

  handleDeselectAll = () => {
    this.setState({ areAllSelected: false });
    this.setState({ selectedItems: [] });
  };

  handleOpenMultiselect = () => {
    this.setState({ multiSelectMode: !this.state.multiSelectMode });
  };

  handleAddToLibrary = () => {
    try {
      authAPI.bulkAddToLibrary(this.state.selectedItems).then((res) => {
        if (res.status === 200) {
          this.handleSuccessSnackBarLibrary();
        } else {
          this.handleErrorSnackBarLibrary();
        }
      });
    } catch (err) {
      console.log("Bulk add to library failed");
    }
  };

  getResources = () => {
    const resources = this.state?.searchActive
      ? this.state?.searchedData
      : this.props.units?.data?.results;
    return resources?.length >= 0
      ? resources.map((resource, i) => (
          <ResponsiveResourcesNew
            item={resource}
            key={`units-resource-${i}`}
            i={i}
            handleClickForResourcesThumbnail={
              this.handleClickForResourcesThumbnail
            }
            handleClickForResourcesAddBtn={this.handleClickForResourcesAddBtn}
            handleSelectResourceClick={this.handleSelectResourceClick}
            multiSelectMode={this.state.multiSelectMode}
            addMultipleItems={this.addMultipleItems}
            deleteItem={this.deleteItem}
            multipleItems={this.state.multipleItems}
            scheduleTask={this.props.postScheduledTasksAction}
            active={resource?.name === this.state.selectedResourceName}
            resourceType={"Unit"}
            handleMultiSelect={this.handleMultiSelect}
            selectedItems={this.state.selectedItems}
          />
        ))
      : [];
  };

  render() {
    let strandFromUrl = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );
    let selectedStrand =
      strandFromUrl === this.props.params.strand
        ? this.props.params.strand
        : strandFromUrl;
    const navButtons = (
      <ResponsiveNavBtns
        displayableSelectedStrand={
          invertMap[constants.caseMap[this.props.params.strand]]
        }
        onSelectMenuItem={this.onSelectMenuItem}
        selectedStrand={selectedStrand}
        btnOptions={[
          "featured",
          "reading",
          "writing",
          "grammar",
          "foundational",
          "e-learning",
        ]}
        isJoyrideRunning={this.props.isJoyrideRunning}
      />
    );

    const featuredSliders = (
      <div style={{ height: "100%" }}>
        {this.props.featuredUnits.asyncStatus === "SUCCESS" && (
          <div className="bottom-margin-browse">
            {this.props.featuredUnits.data[1] && (
              <SimpleSlider
                name={"Featured Reading Fundamentals"}
                carouselItem="units"
                items={this.props.featuredUnits.data[1].results}
                viewAllUrl="/browse/units/reading"
              />
            )}
            {this.props.featuredUnits.data[2] && (
              <SimpleSlider
                name={"Featured Writing Fundamentals"}
                carouselItem="units"
                items={this.props.featuredUnits.data[2].results}
                viewAllUrl="/browse/units/writing"
              />
            )}
            {this.props.featuredUnits.data[3] && (
              <SimpleSlider
                name={"Featured Fundamentals of Grammar"}
                carouselItem="units"
                items={this.props.featuredUnits.data[3].results}
                viewAllUrl="/browse/units/grammar"
              />
            )}
          </div>
        )}
      </div>
    );

    const beforeNonFeaturedContent = <div />;
    return (
      <>
        <BrowsePageContent
          name="Units"
          isFeatured={this.state.isFeatured}
          isElearning={this.state.isElearning}
          isFoundational={this.state.isFoundational}
          resources={this.getResources()}
          navButtons={navButtons}
          resourceHeading={
            <ResourceHeading
              multiSelectMode={this.state.multiSelectMode}
              areAllSelected={this.state.areAllSelected}
              handleOpenMultiselect={this.handleOpenMultiselect}
              handleCancel={this.handleCancel}
              handleDeselectAll={this.handleDeselectAll}
              handleSelectAll={this.handleSelectAll}
              handleAddToLibrary={this.handleAddToLibrary}
              total={
                this.state.searchActive
                  ? this.state?.searchTotal
                  : this.props.units?.data?.total ?? 0
              }
              data={this.props.units?.data?.results}
              handleBulkAddToFolderModal={this.handleBulkAddToFolderModalOpen}
              typeTab="units"
            />
          }
          asyncStatus={this.props.units ? this.props.units.asyncStatus : ""}
          beforeNonFeaturedContent={beforeNonFeaturedContent}
          addToLibrary={this.props.postAddUnitToLibrary}
          hasMore={this.state.hasMore}
          loadMore={this._loadMore}
          featuredSliders={featuredSliders}
          location={this.props.location}
          selectedResource={
            this.state.singleUnitDetails?.name
              ? this.state.singleUnitDetails
              : ""
          }
          handleClickForResourcesThumbnail={
            this.handleClickForResourcesThumbnail
          }
          selectedResourceType={"units"}
          selectedResourceId={this.state.selectedResourceId}
          updateFromSearch={this.updateFromSearch}
          getSearchNextPage={this.state.getSearchNextPage}
          setNoSearchNextPage={() =>
            this.setState({ getSearchNextPage: false })
          }
          strand={this.props.params?.strand}
        />

        {this.state.isAddToFolderModalOpen && (
          <AddToFolderModal
            handleClose={this.handleBulkAddToFolderModalClose}
            handleSuccessSnackbar={this.handleSuccessSnackBar}
            handleErrorSnackbar={this.handleErrorSnackBar}
            selectedItems={this.state.selectedItems}
          />
        )}

        <SnackBarModule
          addToFolderSuccess={this.state.addToFolderSuccess}
          addToFolderError={this.state.addToFolderError}
          addToFolderErrorMsg={this.state.addToFolderErrorMsg}
          addToLibrarySuccess={this.state.addToLibrarySuccess}
          addToLibraryError={this.state.addToLibraryError}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    units: state.Units,
    featuredUnits: state.FeaturedUnits,
    finalFilters: state.FilterScale,
    isJoyrideRunning: state.JoyrideReducer.joyrideShouldRun,
    singleUnitDetails: state.SingleUnitDetails,
  };
};

export default connect(mapStateToProps, actions)(UnitsContent);
