import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import { withRouter } from "react-router";
import "../../assets/styles/elearningPopup.scss";

class MagicBoxReaderHeader extends Component {
  render() {
    return (
      <nav className="navbar navbar-default e-reader-header-2 mobile-e-reader-header">
        <div className="container-fluid">
          <div className="navbar-header">
            <a className="navbar-brand e-reader">
              <div style={{ width: "100%" }}>
                <span className="book-title">{this.props.bookName}</span>
              </div>
            </a>
          </div>
        </div>
      </nav>
    );
  }
}

export default connect(null, actions)(withRouter(MagicBoxReaderHeader));
