import {
    GET_GROUPS_REQUEST,
    GET_GROUPS_SUCCESS,
    GET_GROUPS_FAILURE
} from '../actions';


const initialState = {};

const GroupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GROUPS_REQUEST:
            return Object.assign({},state, {asyncStatus:  'PENDING'});
        case GET_GROUPS_SUCCESS:
            return Object.assign({},state, {asyncStatus:  'SUCCESS', data: action.payload});
        case GET_GROUPS_FAILURE:
            return Object.assign({},state, {asyncStatus:  'FAILURE', data: action.payload});
        default:
            return state;
    }
};

export default GroupsReducer;