import React, { Component } from "react";
import NewGlobalSearchComponent from "../../components/NewGlobalSearchComponent";
import * as actions from "../../redux/actions";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import NewFilterMenuContainer from "../NewFilterMenu";
import NewResponsiveNavBtns from "../TeachingResources/NewResponsiveNavBtns";
import { BrowsePageRightContent } from "./BrowsePageRightContent";
import checkMarkDark from "../../assets/icons/SVG/my-check-image.svg";
import XIcon from "../../assets/icons/SVG/icon-dark-x.svg";
import libraryEmpty from "../../assets/images/library-empty.jpg";
import authAPI from "../../api/authAPI";
import { browserHistory, withRouter } from "react-router";
import { SnackBarModule } from "../../components/Shared";
import constants from "../../constants";

class TextBox extends Component {
  render() {
    return (
      <div className="add-to-folder-modal-input-textfield">
        <input
          className={
            this.props.errorValue === ""
              ? "add-user-form"
              : "add-user-form add-user-form-error"
          }
          name={this.props.name}
          type={this.props.type ? this.props.type : "text"}
          value={this.props.value}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
        />

        {this.props.errorValue !== "" && (
          <div className="add-user-error">{this.props.errorValue}</div>
        )}
        {this.props.errorValue === "" && (
          <div className="password-validation-messages">
            {this.props.Instruction}
          </div>
        )}
      </div>
    );
  }
}

export class BrowsePageContent extends Component {
  state = {
    isInputOpen: false,
    isInputOpenBottom: false,
    form: {
      folderName: "",
    },
    folderNameValid: "",
    trigger: false,
    shouldReset: false,
    multiSelectMode: true,
    addToLibrarySuccess: false,
    addToLibraryError: false,
    libraryStrand: "",
    libraryStrandName: "",
    closeAllFilters: false,
    errorMessage: "",
    successMessage: "",
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.strand !== prevProps.strand && this.props.strand === "all") {
      this.clearAndCloseFilters();
    }
  };

  componentDidMount = () => {
    const pathname = this.props.location.pathname;

    if (pathname.includes("library")) {
      this.props.getLibraryFolderNotification();
    }
  };

  handleOpen = () => {
    this.setState({ isInputOpen: true });
  };

  handleClose = () => {
    this.setState({ isInputOpen: false });
  };

  handleFolderName = (event) => {
    this.setState({
      form: {
        ...this.state.form,
        [event.target.name]: event.target.value,
      },
    });
  };

  // Check if input is empty
  validateInput() {
    let isGood = true;
    if (
      this.state.form.folderName.length === 0 ||
      !this.state.form.folderName
    ) {
      this.setState({ folderNameValid: "Folder Name cannot be blank." });
      isGood = false;
    } else {
      this.setState({ folderNameValid: "" });
    }
    return isGood;
  }

  handleOpenBottom = () => {
    this.setState({ isInputOpenBottom: true });
  };

  handleCloseBottom = () => {
    this.setState({ isInputOpenBottom: false });
  };

  handleCreateFolderSnackbar = ({ isError = false }) => {
    if (isError) {
      this.setState({ addToLibraryError: true });
      setTimeout(() => {
        this.setState({ addToLibraryError: false });
      }, 3000);
    } else {
      this.setState({ addToLibrarySuccess: true });
      setTimeout(() => {
        this.setState({ addToLibrarySuccess: false });
      }, 3000);
    }
  };

  // POST request for creating new folder
  handleCreatingNewFolder = () => {
    if (this.validateInput()) {
      const postNewFolder = authAPI.postFolder({
        name: this.state.form.folderName,
      });

      postNewFolder
        .then((response) => {
          if (response.statusCode === 201 || response.statusCode === 200) {
            this.setState({
              form: {
                folderName: "",
              },
              createFolderInput: false,
              folderNameValid: "",
              createFolder: false,
            });
            this.handleClose();
            this.handleCloseBottom();
            this.handleCreateFolderSnackbar({ isError: false });
            this.props.refreshLibrary();
          } else {
            this.showError(response.message);
            this.handleCreateFolderSnackbar({ isError: true });
            setTimeout(() => {
              this.clearError();
            }, 3000);
          }
        })
        .catch(function (error) {
          console.log("Creating new folder failed");
        });
    }
  };

  clearAndCloseFilters = () => {
    const pathname = this.props.location.pathname;
    const resource = pathname.split("/")[2];

    this.clearAllFilters();
    this.closeAllFilters();

    if (pathname.includes("library")) {
      if (resource) {
        this.props.getCustomLibraryFiltersAction(
          this.state.libraryStrand,
          resource
        );
      }
    }
    if (pathname.includes("units")) {
      const strandFromUrl = pathname.substring(pathname.lastIndexOf("/") + 1);
      if (strandFromUrl) {
        this.props.getUnitsAction(1, constants.caseMap[strandFromUrl]);
      }
    }
  };

  clearAllFilters = () => {
    if (!this.state.shouldReset) {
      this.setState({ shouldReset: true });
    }
  };

  closeAllFilters = () => {
    if (!this.state.closeAllFilters) {
      this.setState({ closeAllFilters: true });
    }
  };

  showError(message) {
    this.setState({ errorMessage: message });
  }

  showSuccess(message) {
    this.setState({ successMessage: message });
  }

  clearError() {
    if (this.state.errorMessage !== "") {
      this.setState({ errorMessage: "" });
    }
  }

  clearSuccess() {
    if (this.state.successMessage !== "") {
      this.setState({ successMessage: "" });
    }
  }
  render() {
    let contentHeader = (
      <div>
        <div className="hero hidden-xs hidden-sm">
          <div className="teaching-resources">{this.props.name}</div>
        </div>
        <div className="content-resource">
          <div className="toggle-resource">{this.props.navButtons}</div>
          {this.props.children}
        </div>
      </div>
    );

    let nonFeaturedContent = (
      <div style={{ height: "auto" }}>
        {this.props.resourceHeading}
        <div
          style={
            window.location.pathname !== "/library/folders" ||
            !this.props?.resources?.length
              ? {
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  margin: "0",
                  borderRadius: 9,
                }
              : {
                  display: "grid",
                  gap: "10px",
                  gridTemplateColumns: "repeat(2, minmax(0,1fr))",
                  backgroundColor: "#f8f8f8",
                }
          }
        >
          {(this.props.resources?.length || this.props?.type !== "library") &&
            this.props.resources}

          {!this.props.resources?.length &&
            this.props.asyncStatus === "SUCCESS" &&
            this.props.type === "library" &&
            (this.props.selectedResourceType === "folder" ? (
              this.props.setLibrarySearchValue &&
              this.props.setLibrarySearchValue != "" ? (
                <div className="library-empty-container grey">
                  <h2 style={{ textAlign: "center" }}>
                    The searched folder not found
                  </h2>
                </div>
              ) : (
                <div className="library-empty-container grey">
                  <h2 style={{ textAlign: "center" }}>
                    There are no folders, let's create some folders 1234
                  </h2>
                </div>
              )
            ) : this.props?.isFolder ? (
              <div className="library-empty-container">
                <h2 style={{ textAlign: "center" }}>This folder is empty</h2>
              </div>
            ) : (
              <div className="library-empty-container">
                {!this.props.isSearchActive ? (
                  <>
                    <h2 style={{ textAlign: "center" }}>
                      Your library is empty, let’s add some items!
                    </h2>
                    <img src={libraryEmpty} alt="" />
                    <button
                      className="cta-button"
                      onClick={() => browserHistory.push("/browse")}
                    >
                      Search for Content
                    </button>
                  </>
                ) : (
                  <>
                    <h2 style={{ textAlign: "center" }}>
                      <p>Sorry, No Results Found</p>
                    </h2>
                  </>
                )}
              </div>
            ))}
          {!this.props.resources?.length &&
            this.props.asyncStatus === "SUCCESS" &&
            this.props.type !== "library" && (
              <>
                <h2 style={{ textAlign: "center" }}>Sorry, No Results Found</h2>
                <p style={{ textAlign: "center" }}>
                  Please try changing your search filters
                </p>
              </>
            )}
        </div>
      </div>
    );

    let featuredContent = (
      <div style={{ overflowY: "scroll", height: "100%" }}>
        <div style={{ height: "100%" }}>
          {contentHeader}
          {this.props.featuredSliders}
        </div>
      </div>
    );
    return (
      <div className="add-bottom-height-mobile" style={{ height: "100%" }}>
        {this.props.isFeatured ? (
          <div style={{ height: "100%" }}>{featuredContent}</div>
        ) : (
          <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
            <div
              className="sidebar-filter-menu"
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                paddingTop: 10,
                overflowY: "scroll",
                height: "70vh",
              }}
            >
              <NewGlobalSearchComponent
                updateFromSearch={this.props.updateFromSearch}
                setLibrarySearchValue={this.props.setLibrarySearchValue}
                clearAndCloseFilters={this.clearAndCloseFilters}
                isLibrary={this.props?.type === "library"}
                isFolder={
                  this.props?.selectedResourceType === "folder" ||
                  this.props?.isFolder
                }
                libraryStrand={this.state.libraryStrand}
                getSearchNextPage={this.props.getSearchNextPage}
                setNoSearchNextPage={this.props.setNoSearchNextPage}
              />
              <NewResponsiveNavBtns
                type={this.props.name}
                location={this.props.location}
                isLibrary={this.props?.type === "library"}
                isFolder={this.props?.isFolder}
                updateLibraryStrand={(strand, strandName) =>
                  this.setState({
                    libraryStrand: strand,
                    libraryStrandName: strandName,
                  })
                }
                clearAllFilters={this.clearAllFilters}
                closeAllFilters={this.closeAllFilters}
              />
              <div
                style={{
                  "border-bottom": "2px solid #c6c7c6",
                  margin: "0 15px 5px 15px",
                }}
              ></div>
              <NewFilterMenuContainer
                shown={true}
                typeOfResource={
                  this.props.typeOfResource === "texts"
                    ? "books"
                    : this.props.typeOfResource
                }
                shouldReset={this.state.shouldReset}
                updateShouldReset={(value) =>
                  this.setState({ shouldReset: value })
                }
                type={this.props.type}
                getInitialData={this.props.getInitialData}
                fetchBooks={this.props?.fetchBooks}
                isLibrary={this.props?.type === "library"}
                libraryStrand={this.state.libraryStrand}
                libraryStrandName={this.state.libraryStrandName}
                closeAllFilters={this.state.closeAllFilters}
                updateCloseAllFilters={() =>
                  this.setState({ closeAllFilters: false })
                }
              />
            </div>
            {
              <>
                <div
                  style={{
                    display: "flex",
                    flex: 4,
                    overflowY: "scroll",
                    marginLeft: 0,
                    marginRight: 0,
                    maxHeight: "calc(100vh - 230px)",
                  }}
                >
                  {this.props.beforeNonFeaturedContent}

                  <InfiniteScroll
                    id="loaderDiv"
                    pageStart={0}
                    loadMore={this.props?.loadMore?.bind(this)}
                    hasMore={this.props.hasMore}
                    threshold={1}
                    useWindow={false}
                    loader={<div> </div>}
                    initialLoad={true}
                  >
                    {nonFeaturedContent}
                    {window.location.pathname === "/library/folders" ? (
                      <>
                        {this.state.isInputOpenBottom ? (
                          <div className="add-to-folder-modal-input-bottom">
                            {this.state.errorMessage && (
                              <div style={{ color: "red" }}>
                                {this.state.errorMessage}
                              </div>
                            )}
                            {this.state.successMessage && (
                              <div style={{ color: "green" }}>
                                {this.state.successMessage}
                              </div>
                            )}
                            <TextBox
                              type="text"
                              placeholder="Enter Folder Name"
                              name="folderName"
                              errorValue={this.state.folderNameValid}
                              onChange={this.handleFolderName}
                            />

                            <div
                              style={{ margin: "0 10px" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                this.handleCreatingNewFolder();
                              }}
                            >
                              <img
                                src={checkMarkDark}
                                style={{ height: "20px", width: "20px" }}
                                alt="OK"
                              />
                            </div>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                this.handleCloseBottom();
                              }}
                            >
                              <img
                                src={XIcon}
                                style={{ height: "25px", width: "25px" }}
                                alt="CLOSE"
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className="add-to-folder-modal-input-bottom no-border"
                            onClick={() => this.handleOpenBottom()}
                          >
                            <span
                              style={{ fontSize: "25px", margin: "0 10px" }}
                            >
                              +
                            </span>{" "}
                            Create A New Folder
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </InfiniteScroll>
                </div>
              </>
            }
            <BrowsePageRightContent
              selectedResource={this.props.selectedResource}
              selectedResourceType={this.props.selectedResourceType}
              selectedResourceId={this.props?.selectedResourceId ?? ""}
              handleClickForResourcesThumbnail={
                this.props.handleClickForResourcesThumbnail
              }
            />
            <SnackBarModule
              addToLibrarySuccess={this.state.addToLibrarySuccess}
              addToLibraryError={this.state.addToLibraryError}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSearchActive: state.MyLibrary?.isSearchActive,
  };
};

export default connect(mapStateToProps, actions)(withRouter(BrowsePageContent));
