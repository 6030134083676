import {
    DELETE_ANNOTATION_REQUEST,
    DELETE_ANNOTATION_SUCCESS,
    DELETE_ANNOTATION_FAILURE
} from '../actions';


const initialState = {};

const DeleteAnnotationReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_ANNOTATION_REQUEST:
            return Object.assign({},state, {asyncStatus:  'PENDING'});
        case DELETE_ANNOTATION_SUCCESS:
            return Object.assign({},state, {asyncStatus:  'SUCCESS', data: action.payload});
        case DELETE_ANNOTATION_FAILURE:
            return Object.assign({},state, {asyncStatus:  'FAILURE', data: action.payload});
        default:
            return state;
    }
};

export default DeleteAnnotationReducer;