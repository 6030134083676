import React,{Component} from 'react'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import Header from '../Header'
import NewHeader from '../NewHeader';

export class BrowsePage extends Component {
    constructor(){
        super();
        this.state={
            buttonClicked:'',
            className:""
        };
        this.handlingClick= this.handlingClick.bind(this);
        this.setContainerWidth = this.setContainerWidth.bind(this);
    }

    handlingClick(buttonValue){
        this.setState({
            buttonClicked:buttonValue
        });
    }

    setContainerWidth(isMenuVisible){
        if(isMenuVisible){
            this.setState({
                className:"md9class"
            });
        }
        else{
            this.setState({
                className:""
            });
        }
    }

    render() {
        return(
            <div style={{height: '100%', width: '100%'}}>
                {/*<Header name={this.props.name} notifyMenuStatus={this.setContainerWidth} buttonClicked={this.props.strandValue} typeOfResource={this.props.typeOfResource}/>*/}
                <NewHeader name={this.props.name} notifyMenuStatus={this.setContainerWidth} typeOfResource={this.props.typeOfResource}/>
                <div className={this.state.className} style={{height: '100%'}}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default connect(null, actions)(BrowsePage)