export const getDescription = (item) => {
    if(Array.isArray(item?.introduction)){
        return item?.introduction[0]?.text ?? 'No Description'
    } 
    
    if(Array.isArray(item?.rationale)){
        return item?.rationale[0]?.text ?? 'No Description'
    } 

    if(Array.isArray(item?.description)){
        return item?.description[0]?.text ?? 'No Description'
    } 
    
    return item?.introduction?.text 
        ?? item?.rationale?.text
        ?? item?.description?.text 
        ?? item?.description 
        ?? 'No Description'
    
}

export const isDescriptionTooLong = (item) => {
    const description = getDescription(item)
    return description.length > 180
}