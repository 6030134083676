import React from "react";
import ReactDOM from "react-dom/client";
import Navigation from "./navigation";

import "./assets/styles/teachingResources.scss";
import "./assets/styles/myStyles.scss";
import "./assets/styles/printOnlyModal.scss";
import "bootstrap-sass/assets/stylesheets/_bootstrap.scss";

// This will suppress alerts that are used in the application
// This is needed because flowpaper sometimes has alerts that come up
window.alert = (text) => {
  console.log("[ALERT]: " + text);
  return true;
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Navigation />);