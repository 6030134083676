import React,{Component} from  'react'
import {Row,Col} from 'react-bootstrap'
import constants from '../../constants'

class NewUnitTextContent extends Component{
    constructor (props) {
        super(props);
        this.state = {
            texts : []
        };

        this.props.lessons.map((lesson) => {
            if(lesson.mentorTexts && lesson.mentorTexts.length>0){
               this.state.texts= this.state.texts.concat(lesson.mentorTexts);
            }
        });

        if(this.props.mentorTexts && this.props.mentorTexts.length >0 ){
            this.props.mentorTexts.map((lesson) => {
               this.state.texts= this.state.texts.concat(lesson);
            });
        }

    }

    render(){
        return(
             <div className='list-of-items items-background'>
                {!this.state.texts||this.state.texts.length === 0? <div> No data found</div> : ""}
                {this.state.texts && this.state.texts!==null && this.state.texts[0] && this.state.texts.map((mentorText,j)=>(
                    <Row>
                        <Row className={j === 0? "":"add-border"}/>
                        <img src={constants.filesUrl+(mentorText.image?.replace("../",'')||'')} className="image-size-in-text-content" />
                        <Col md={10} sm={8}  className="add-padding-to-each-item">
                            <Row key={mentorText+j} >{mentorText.name && mentorText.name!==null && <Col className='content-heading'>{mentorText.name}</Col>}</Row>
                            <Row key={j}>{mentorText.author &&  mentorText.author!==null && 
                                <Col className="author-text-format"> by {mentorText.author.substring(mentorText.author.indexOf(",")+1)} {mentorText.author.substring(0,mentorText.author.indexOf(","))}
                                    <span>
                                        {!mentorText.is_digital && <button className="print-only">PRINT-ONLY</button>}
                                    </span>
                                </Col>}
                            </Row>
                        </Col>
                        <span className="view-icon-in-text icon--view"/>
                    </Row>
                ))}
            </div>
        );
    }}

export default NewUnitTextContent