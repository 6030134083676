import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as actions from "../../redux/actions/index";
import Header from "../../containers/Header";
import Footer from "../../containers/Footer";
import MagicBoxReader from "./MagicBoxReader";
import MagicBoxReaderHeader from "./MagicBoxReaderHeader";

class EReader extends Component {
  constructor(props) {
    super(props);
    this.pStyle = {
      fontSize: "15px",
      textAlign: "center",
      position: "fixed",
      top: "50%",
      left: "50%",
      width: "30em",
      height: "18em",
      marginTop: "-9em",
      marginLeft: "-15em",
    };
  }

  componentDidMount() {
    this.props.getBookDetailsAction(this.props.params.uid);
  }

  render() {
    let eReaderWholeStructure = null;

    if (this.props.bookDetails.asyncStatus === "SUCCESS") {
      eReaderWholeStructure = (
        <div>
          <MagicBoxReaderHeader bookName={this.props.bookDetails.data.name} />
          <MagicBoxReader
            bookName={this.props.bookDetails.data.name}
            bookId={this.props.bookDetails.data._id}
          />
        </div>
      );
    } else if (this.props.bookDetails.asyncStatus === "PENDING") {
      eReaderWholeStructure = (
        <div>
          <Header />
          <div>
            <p style={this.pStyle}>Loading please wait!</p>
          </div>
          <Footer />
        </div>
      );
    } else if (this.props.bookDetails.asyncStatus === "FAILURE") {
      eReaderWholeStructure = (
        <div>
          <Header />
          <div>
            <p style={this.pStyle}>Sorry, this book is no longer available</p>
          </div>
          <Footer />
        </div>
      );
    }
    return <div>{eReaderWholeStructure}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    bookDetails: state.BookDetails,
  };
};

export default connect(mapStateToProps, actions)(withRouter(EReader));
