import React,{Component} from  'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import "../assets/styles/reactMenu.scss";

class AnnotationMenuComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            highlightMode: "Add",
        };
        this.contextMenuTrigger = null;
        this.itemId = null;
    }

    setModeAddHighlight() {
        this.setState({highlightMode:"Add"});
    }

    setModeDeleteHighlight() {
        this.setState({highlightMode:"Delete"});
    }

    setItemId(id) {
        this.itemId = id;
    }

    getItemId() {
        return this.itemId;
    }

    handleContextClick(e) {
        this.contextMenuTrigger.handleContextClick(e);
    }

    getHighlightClasses() {
        let highlightClasses = "context-menu-icon icon-icon-pen-circle-outline";
        if (this.state.highlightMode === "Delete") {
            highlightClasses += " delete-highlight";
        }
        return highlightClasses;
    }

    getMode() {
        return this.state.highlightMode;
    }

    handleShow = (e) => {
        this.props.onShow(e, this);
    };

    handleHide = (e) => {
        this.props.onHide(e, this);
    };

    onHighlight = (e, data) => {
        if (this.state.highlightMode === "Add") {
            this.props.onAddHighlight(e, data);
        }
        else if (this.state.highlightMode === "Delete") {
            this.props.onDeleteHighlight(e, data, this.itemId);
        }
    };

    onNote = (e, data) => {
        this.props.onAddNote(e, data);
    };

    render() {

        return(
            <div>
                <ContextMenu id={this.props.id} onShow={this.handleShow} onHide={this.handleHide}>
                    <MenuItem data={this.props.highlightData} onClick={this.onHighlight}>
                        <i className={this.getHighlightClasses()}/>
                    </MenuItem>
                    <MenuItem data={this.props.noteData} onClick={this.onNote}>
                        <i className="context-menu-icon icon-icon-add-note-circle-outline"/>
                    </MenuItem>
                </ContextMenu>
                <ContextMenuTrigger ref={(el) => { this.contextMenuTrigger = el }} id={this.props.id} >
                    {this.props.children}
                </ContextMenuTrigger>
            </div>
        )
    }
}

export default AnnotationMenuComponent