import React, {Component} from 'react'

export default class UnattachedPopover extends Component {
  render(){
    return (
      <div className="multi-select-popover">
        <div className="mobile-x" onClick={this.props.hideTooltip}>
          x
        </div>
        {this.props.children}
      </div>
    );
  }
}
