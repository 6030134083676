import React from 'react'
import AssistantTooltipComponent from '../../components/JoyrideCustomComponent/AssistantTooltipComponent'

const styleObject = {
  mainColor: '#CACACA',
  width: '650px',
  height: '50px',
  overflow: 'wrap',
  borderRadius: 11,
  backgroundColor: 'white',
  beacon: {
    inner: '#a350f0',
    outer: '#a350f0',
  },
  back: {
    display: 'none'
  },
  close: {
    display: 'none'
  },
  button: {
    display: 'none'
  },
  skip: {
    marginTop: 20,
  }
}

export default {
  steps: [
      {
        title: "Selecting a Strand",
          text: <AssistantTooltipComponent
                    imgWidth={100}
                    imgHeight={130}
                    tooltipText={`
                          <p>Welcome to the Units of Study page. Here, you will find hundreds of comprehensive Units of Study in the areas of Reading, Writing, and Grammar.</p>
                          <p>All units have been explicitly designed to support a Workshop Model of instruction. The units are organized by 3 core strands.</p>
                          <p>Let's click <strong>Writing</strong> to begin narrowing our search results.</p>
                      `} />,
          selector: '#joyrider-units-selector',
          position: 'bottom',
          style: {
            ...styleObject,
          }
      },
      {
          title: "Browsing Units",
          text: <AssistantTooltipComponent
                    imgWidth={100}
                    imgHeight={130}
                    tooltipText={`
                          <p>
                            Excellent! You are now viewing all of the Writing Units of Study currently available.
                            You may drill down further by using the Filter icon in the upper right.
                          </p>
                          <p>
                            The Filter icon will allow you to narrow your search by grade level, genre, and text type.
                            For now, let's open the first <strong>Unit of Study</strong> and I will show you how to customize.
                          </p>
                      `} />,
          selector: '#joyride-selector-unit-item-0',
          position: 'right',
          style: styleObject
      }
    ]
  }
