import React from 'react'
import AssistantTooltipComponent from '../../components/JoyrideCustomComponent/AssistantTooltipComponent'

const styleObject = {
  mainColor: '#CACACA',
  width: '650px',
  height: '50px',
  overflow: 'wrap',
  borderRadius: 11,
  backgroundColor: 'white',
  beacon: {
    inner: '#a350f0',
    outer: '#a350f0',
  },
  close: {
    display: 'none'
  },
  button: {
    display: 'none'
  },
  skip: {
    marginTop: 20,
  },
  back: {
    display: 'none'
  }
}
const styleObjectWithExitBtn = {
  mainColor: '#CACACA',
  width: '650px',
  height: '50px',
  overflow: 'wrap',
  borderRadius: 11,
  backgroundColor: 'white',
  beacon: {
    inner: '#a350f0',
    outer: '#a350f0',
  },
  close: {
    display: 'none'
  },
  button: {
    display: 'none'
  },
  skip: {
    marginTop: 20,
    borderRadius: 8,
    backgroundColor: '#009BBD',
    color: 'white'
  },
  back: {
    display: 'none'
  }
}

const styleObjectWithNextBtn = {
  mainColor: '#CACACA',
  width: 600,
  height: '100px',
  overflow: 'wrap',
  borderRadius: 4,
  backgroundColor: 'white',
  beacon: {
    inner: '#a350f0',
    outer: '#a350f0',
  },
  close: {
    display: 'none'
  },
  button: {
    color: '#333333',
    marginTop: '20px',
    backgroundColor: 'white'
  },
  skip: {
    marginTop: 20
  },
  back: {
    display: 'none'
  }
}

export default {
  steps: [
    {
      title: "Assign Overview",
        text: <AssistantTooltipComponent
                  imgWidth={100}
                  imgHeight={130}
                  tooltipText={`
                    <p>Fundamentals Unlimited allows educators to assign specific tasks and eBooks to individual students, groups of students, or an entire class!
                    Very powerful!</p>
                    <p>To assign a task or eBook, click the <strong>Assign</strong> icon.</p>
                    `} />,
        selector: '.resource-content-margin-xs-12 > :first-child .icon-icon-add-user',
        position: 'right',
        style: {
          ...styleObject,
          hole: {
            marginLeft: '3px'
          }
        }
    },
      {
          title: "Assign - Date",
          text: <AssistantTooltipComponent
                    imgWidth={100}
                    imgHeight={130}
                    tooltipText={`
                      <p>
                        Select the date you would like to make the assignment.
                        Assignments are presented to your students in sequential order from oldest to newest.
                        To familiarize yourself with the Student Portal, check out the <strong>Student View</strong> tour.
                      </p>
                      `} />,
          selector: '#joyride-selector-assign-date',
          position: 'bottom',
          style: styleObject
      },
      {
          title: "Assign - Filters",
          text: <AssistantTooltipComponent
                    imgWidth={100}
                    imgHeight={130}
                    tooltipText={`
                      <p>This toggle is a quick filter that will display either a listing of individual students or a listing of groups that you have created.</p>
                      <p>
                        What exactly are groups? Great question! The groups feature is a convenient way for you to make assignments to more than one student.
                        A group may be an entire class, in the case of teachers with multiple sections.
                        Other examples of groups include guided reading groups or book clubs.
                        There is no limit to the number of groups you can create.
                      </p>
                      `} />,
          selector: '#joyride-select-group-btn',
          position: 'bottom',
          style: {
            ...styleObjectWithNextBtn,
            arrow: {
              left: '50%',
              right: '50%'
            },
            hole: {
              paddingLeft:'122px',
              paddingRight:'122px'
            }
          }
      },
      {
          title: "Assign - Searching",
          text: <AssistantTooltipComponent
                    imgWidth={100}
                    imgHeight={130}
                    tooltipText={`
                      <p>
                        If you know the name of the student or group to whom you would like to make the assignment, you will type it here.
                      </p>
                      `} />,
          selector: '#joyride-selector-search-by-student',
          position: 'bottom',
          style: {
            ...styleObjectWithNextBtn,
            arrow: {
              left: '50%',
              right: '50%'
            }
          }
      },
      {
          title: "Assign - Adding",
          text: <AssistantTooltipComponent
                    imgWidth={100}
                    imgHeight={130}
                    tooltipText={`
                      <p>
                        Click here to assign the task or eBook to a student or a group.
                        After a successful Add, the <i style="float: none; margin-right: 2px" class="add-student-solid-icon icon-icon-add-circle-solid can-hover"></i> will change to <i style="float: none; margin-right: 2px" class="remove-student-solid-icon icon-icon-close-circle-solid"></i>.
                      </p>
                      `} />,
          selector: '.joyride-icon-selector:first-of-type',
          position: 'bottom',
          style: styleObject
      },
      {
          title: "Assign - Finish",
          text: <AssistantTooltipComponent
                    imgWidth={100}
                    imgHeight={130}
                    tooltipText={`
                      <p>To complete your assignment, you will click <strong>Assign</strong>.</p>

                      <p>
                        That's it for this tour! I certainly hope it was helpful and informative.
                        Tours can be revisited and accessed at any time by clicking the <strong>Help</strong> icon in the lower right-hand corner of the screen.
                      </p>
                      `} />,
          selector: '.add-add-group',
          position: 'top-right',
          style: styleObjectWithExitBtn
      }
    ]
  }
