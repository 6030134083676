import {
    GET_FEATURED_UNITS_REQUEST,
    GET_FEATURED_UNITS_SUCCESS,
    GET_FEATURED_UNITS_FAILURE
} from '../actions';


const initialState = {};

const FeaturedUnitsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FEATURED_UNITS_REQUEST:
            return Object.assign({},state, {asyncStatus:  'PENDING'});
        case GET_FEATURED_UNITS_SUCCESS:
            return Object.assign({},state, {asyncStatus:  'SUCCESS', data: {...state.data, [action.request]: action.payload}});
        case GET_FEATURED_UNITS_FAILURE:
            return Object.assign({},state, {asyncStatus:  'FAILURE', data: action.payload});
        default:
            return state;
    }
};

export default FeaturedUnitsReducer;