import { combineReducers } from 'redux';
import TeachingResources from './TeachingResourcesReducer';
import UnitsDetail from './UnitsDetailReducer';
import Resources from './ResourcesReducer';
import HomeResources from './HomeResourcesReducer';
import FeaturedUnits from './FeaturedUnitsReducer'
import Units from './UnitsReducer'
import Lessons from './LessonsReducer'
import LessonsDetail from './LessonsDetailedReducer'
import FilterOptions from './FilterOptionsReducer'
import StandardsFilter from './FilterStandardsReducer'
import MyLibraryRecents from './MyLibraryRecentsReducer'
import MyLibrary from './MyLibraryReducer'
import NewUnit from './NewUnitReducer'
import SaveUnit from './SaveUnitReducer'
import UpdateUnit from './UpdateUnitReducer'
import FilterScale from './FilterScalingReducer'
import Annotations from './AnnotationsReducer'
import AddAnnotation from './AddAnnotationsReducer'
import EditAnnotation from './EditAnnotationsReducer'
import DeleteAnnotation from './DeleteAnnotationsReducer'
import ScheduledTasks from './ScheduledTasksReducer'
import CustomUnits from './CustomUnitsReducer'
import UncheckOptions from './RemoveFilterOptionReducer'
import CheckUnit from './CheckUnitReducer'
import OrderScheduledTasks from './OrderScheduledTasksReducer'
import Users from './UsersReducer'
import Districts from './DistrictsReducer'
import ImportUsers from './ImportUsersReducer'
import MyDetails from './GetMyDetailsReducer'
import ResourceDetails from './ResourceDetailsReducer'
import BookDetails from './BookDetailsReducer'
import AssessmentDetails from './AssessmentDetailsReducer'
import CorrelationDetails from './CorrelationDetailsReducer'
import Groups from './GroupsReducer'
import UserById from './UserByIdReducer'
import Books from './GetBooksReducer'
import FeaturedAssessments from './FeaturedAssessmentsReducer'
import Assessments from './AsssessmentsReducer'
import GetGroupById from './GetGroupByIdReducer'
import RecommendedForYou from './RecommendedForYouReducer'
import Location from './SaveLocationReducer'
import Assignments from './GetUserAssignmentsReducer'
import GetUserDetails from './GetUserDetailsByIdReducer'
import LoginReducer from './LoginReducer'
import AssignmentItem from './AssignmentItemReducer'
import SetPicturePassword from './SetPicturePasswordReducer'
import CustomFilters from './GetCustomFiltersReducer'
import NewCustomFilters from './GetNewCustomFiltersReducer'
import GoalTypes from './GetGoalTypesReducer'
import ReferralTypes from './GetReferralTypesReducer'
import JoyrideReducer from './JoyrideReducer'
import Definitions from './GetDefinitionReducer'
import EReaderReducer from './EReaderReducer'
import ShowWhatYouKnowReducer from './ShowWhatYouKnowReducer'
import User from './User'
import ELearningReducer from "./ELearningReducer";
import LessonAddedSuccess from './LessonSuccessReducer'
import ElearningSkills from './ElearningSkillsReducer'
import PlannerDate from './LessonSuccessReducer'
import SingleUnitDetails from './SingleUnitDetailsReducer'


import Auth from './auth'
import Mackin from './mackin'

export default combineReducers({
    User,
    Resources,
    HomeResources,
    TeachingResources,
    FeaturedUnits,
    Units,
    UnitsDetail,
    SingleUnitDetails,
    Lessons,
    LessonsDetail,
    FilterOptions,
    StandardsFilter,
    NewUnit,
    MyLibrary,
    MyLibraryRecents,
    SaveUnit,
    UpdateUnit,
    ScheduledTasks,
    Annotations,
    AddAnnotation,
    EditAnnotation,
    DeleteAnnotation,
    FilterScale,
    CustomUnits,
    UncheckOptions,
    CheckUnit,
    OrderScheduledTasks,
    Users,
    Districts,
    ImportUsers,
    MyDetails,
    ResourceDetails,
    BookDetails,
    AssessmentDetails,
    CorrelationDetails,
    Books,
    Groups,
    UserById,
    FeaturedAssessments,
    Assessments,
    GetGroupById,
    RecommendedForYou,
    Location,
    Assignments,
    GetUserDetails,
    AssignmentItem,
    LoginReducer,
    SetPicturePassword,
    CustomFilters,
    NewCustomFilters,
    GoalTypes,
    ReferralTypes,
    JoyrideReducer,
    Definitions,
    EReaderReducer,
    ELearningReducer,
    ShowWhatYouKnowReducer,
    LessonAddedSuccess,
    ElearningSkills,
    PlannerDate,

    Auth,
    Mackin
});
