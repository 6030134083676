import React, {Component} from 'react'

/**
 * Component which keeps an aspect ratio of it's contained child
 *
 * Props:
 * width-unit - width aspect of the ratio
 * height-unit - height aspect of the ratio
 * min-height - min height of contained
 *
 */
export default class AspectRatioComponent extends Component {
    render() {
        const ratioPercent = (this.props['height-unit']/this.props['width-unit']) * 100;
        const wrapperStyle = {
            width: '100%',
            minHeight: this.props['min-height'] || 0,
            paddingBottom: ratioPercent + '%'
        };
        return (
            <div style={wrapperStyle}>
                {this.props.children}
            </div>
        );
    }
}
