import {
    ADD_LESSON_SUCCESS,
    SAVE_PLANNER_DATE
} from '../actions';


const initialState = {};

const LessonSuccessReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_LESSON_SUCCESS:
            state = initialState;
            return Object.assign({},state, {data: action.payload});
        case SAVE_PLANNER_DATE:
            state = initialState;
            return Object.assign({},state, {data: action.payload});
                
        default:
            return state;
    }
};

export default LessonSuccessReducer;