import React,{Component} from  'react';
import BooksDetailedView from './BooksDetailedView';

class BooksComponent extends Component{
    render(){
        return(
            <div>
                <BooksDetailedView />
            </div>
            );
        }
}

export default BooksComponent;
