// Accordion Component
import React, { useState } from "react";
import ArrowDropDownIcon from "./ArrowDropDownIcon";
import ArrowDropUpIcon from "./ArrowDropUpIcon";

const Accordion = ({ title, points }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      style={{
        marginBottom: "10px",
        border: "none",
        backgroundColor: "rgb(248, 248, 248)",
      }}
    >
      <div
        onClick={toggle}
        style={{
          cursor: "pointer",
          padding: "10px",
          backgroundColor: "rgb(242, 250, 251)",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {title}
        {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </div>
      {isOpen && (
        <ul
          style={{
            listStyleType: "disc",
            margin: "10px 20px",
            padding: "10px",
          }}
        >
          {points?.map((point) => point)}
        </ul>
      )}
    </div>
  );
};

export default Accordion;
