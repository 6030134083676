import React, {Component} from 'react'
import {Button} from 'react-bootstrap'
import {browserHistory} from 'react-router'
import _ from 'lodash'

export default class StrandButton extends Component {
    constructor(props){
      super(props);

      if(this.props.nextJoyrideStep)
          this.nextJStep = _.debounce(this.props.nextJoyrideStep, 800);
    }

    onClick() {
        browserHistory.push(this.props.path);
        if(this.props.onClick) {
            this.props.onClick(this.props.name);
        }
        if(this.props.joyrideShouldDisableThis===false && this.props.joyrideShouldRun)
            this.nextJStep();
    }

    render() {
        return (
            <Button
            onTouchMove={e=>{e.preventDefault(); e.stopPropagation()}}
                id={this.props.id}
                className={this.props.className || 'rectangle-2'}
                style={{backgroundColor: this.props.selected? "#009BBD" : 'white'}}
                onClick={() => this.onClick()}
                disabled={this.props.joyrideShouldDisableThis}>
                    <span className="language" style={{color: this.props.selected? '#FFFFFF': '#9B9B9B'}}>
                        {this.props.name.toUpperCase()}
                    </span>
            </Button>
        );
    }
}
