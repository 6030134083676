import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowsePage } from "../../containers/shared/BrowsePage";
import * as actions from "../../redux/actions";
import authAPI from "../../api/authAPI";
import constants from "../../constants";
import { BrowsePageContent } from "../../containers/shared/BrowsePageContent";
import ResponsiveNavBtns from "../../containers/TeachingResources/NewResponsiveNavBtns";
import ResponsiveResourcesNew from "../../containers/TeachingResources/ResponsiveResourcesNew";
import UnattachedPopover from "../../containers/shared/UnattachedPopover";
import { browserHistory } from "react-router";
import "../../assets/styles/myLibrary.scss";
import "../../assets/styles/mobile.scss";
import "../../assets/styles/index.scss";
import ResourceHeading from "../ResourceHeading";
import AddToFolderModal from "../AddToFolder/AddToFolderModal";
import { SnackBarModule } from "../Shared";

class NewBooksComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonClicked: "",
      className: "",
      modal: false,
      total: 0,
      searchTotal: 0,
      bookResources: [],
      searchedData: [],
      pageNumber: 1,
      searchString: "",
      hasMore: true,
      filterValues: [],
      firstLoad: false,
      multiSelectMode: false,
      multipleItems: [],
      areAllSelected: false,
      selectedItems: [],
      addedMultipleItemsToLibrary: false,
      addedMultipleItemsToFolder: false,
      isBackButtonPressed: false,
      queryValue: "",
      selectedBookName: "",
      bookDetails: "",
      isAddToFolderModalOpen: false,
      addToFolderSuccess: false,
      addToFolderError: false,
      addToLibrarySuccess: false,
      addToLibraryError: false,
      searchActive: false,
      getSearchNextPage: false,
    };
    window.sessionStorage.setItem("selectedFilters", JSON.stringify([]));
    this.setContainerWidth = this.setContainerWidth.bind(this);
    this.modalController = this.modalController.bind(this);
  }

  setContainerWidth(isMenuVisible) {
    if (isMenuVisible) {
      this.setState({ className: "md9class" });
    } else {
      this.setState({ className: "" });
    }
    this.setState({ className: this.state.className });
  }

  handlingClick(buttonValue) {
    this.setState({
      buttonClicked: buttonValue,
    });
  }

  fetchBooksCallback(resp, pageNumber) {
    if (!resp.results || resp.results.length < 1) {
      this.setState({
        hasMore: false,
        total: pageNumber === 1 ? 0 : this.state.total,
        bookResources: pageNumber === 1 ? [] : this.state.bookResources,
      });
      if (pageNumber === 1) this.props.updateFilterOptions(resp.filters);
      return false;
    }

    this.setState({
      total: resp?.total ?? resp?.results?.length,
      bookResources: pageNumber === 1 ? resp.results : _.concat(this.state.bookResources, resp.results),
    });
    if (pageNumber === 1) this.props.updateFilterOptions(resp.filters);

    if (!this.pageLoad) {
      setTimeout(() => {
        if (this.state.total > this.state.bookResources.length) {
          this.setState({ hasMore: true });
        }
      }, 500);
    }
  }

  fetchBooks = (searchString) => {
    window.onpopstate = (e) => {
      this.setState({
        isBackButtonPressed: true,
      });
    };

    if (
      searchString === "" &&
      this.props.finalFilters &&
      this.props.finalFilters.filterList &&
      this.props.finalFilters.filterList.length > 0
    )
      return false;

    if (this.state.isBackButtonPressed === true && searchString === "") {
      searchString = constants.getQueryStringFromFilterList(
        JSON.parse(window.sessionStorage.getItem("selectedFilters"))
      );
    }
    let iPromiseThatStateIsCleared = new Promise((resolve, reject) => {
      let pageNumber = this.state.pageNumber;
      if (searchString !== this.state.searchString) {
        pageNumber = 1;
        this.setState(
          {
            pageNumber: 1,
            searchString,
          },
          () => resolve(pageNumber)
        );
      } else resolve(pageNumber);
    });

    iPromiseThatStateIsCleared.then((pageNumber) =>
      authAPI
        .getBooks(pageNumber, searchString, this.state.isBackButtonPressed)
        .then((resp) => this.fetchBooksCallback(resp, pageNumber))
    );
  };

  componentWillMount() {
    if (
      this.props.finalFilters &&
      this.props.finalFilters.filterList &&
      this.props.finalFilters.filterList.length > 0
    ) {
      authAPI.getBooks(1, this.props.finalFilters.query).then((resp) => {
        if (resp) {
          this.pageLoad = false;
          this.setState(
            {
              firstLoad: true,
              filterList: this.props.finalFilters.filterList,
              total: resp.total,
              bookResources: resp.results,
            },
            () => this.componentWillReceiveProps(this.props)
          );
        }
      });
    } else {
      authAPI.getBooks(1, "").then((resp) => {
        if (resp) {
          this.setState({
            total: resp.total,
            firstLoad: true,
            bookResources: resp.results,
          });
        }
      });
    }
  }

  componentWillReceiveProps(next) {
    if (next.finalFilters && next.finalFilters.filterList) {
      const queryString = constants.getQueryStringFromFilterList(
        next.finalFilters.filterList
      );
      if (
        this.state.queryValue !== queryString &&
        (queryString || this.state.queryValue)
      ) {
        if (next.finalFilters.filterList.length === 0) {
          this.setState({ filter: false });
        }
        if (this.state.filter) {
        } else {
          this.setState(
            {
              filterValues: next.finalFilters.filterList,
              queryValue: queryString,
            },
            () => {
              if (this.state.firstLoad) {
                this.fetchBooks(queryString);
              }
            }
          );
        }
      }
    }
    if (next.bookDetails) {
      if (next.bookDetails.asyncStatus === "PENDING") {
        this.setState({ status: "PENDING" });
      } else if (next.bookDetails.asyncStatus === "FAILURE") {
        this.setState({ bookDetails: "FAILURE" });
      } else if (
        next.bookDetails.asyncStatus === "SUCCESS" &&
        next.bookDetails.data.status === "FAILURE FOR PRINT ONLY USER"
      ) {
        this.setState({ status: "FAILURE FOR PRINT ONLY USER" });
      } else if (
        next.bookDetails.asyncStatus === "SUCCESS" &&
        next.bookDetails.data.status === "FAILURE FOR NOT APPROVED"
      ) {
        this.setState({ status: "FAILURE FOR NOT APPROVED" });
      } else if (next.bookDetails.asyncStatus === "SUCCESS") {
        this.setState({
          bookDetails: next.bookDetails.data,
          status: "SUCCESS",
          selectedBookName: next.bookDetails.data.name,
        });
      }
    }
  }

  modalController() {
    if (!this.state.modal) {
      this.setState({ modal: true });
    } else {
      this.setState({ modal: false });
    }
  }

  _loadMore() {
    if (
      this.state.searchActive &&
      !this.state.getSearchNextPage &&
      this.state.nextPage !== -1 &&
      this.state.hasMore
    ) {
      setTimeout(() => {
        this.setState({ getSearchNextPage: true });
      }, 1000);
    } else if (this.state.hasMore) {
      setTimeout(() => {
        if (
          this.state.total > this.state.bookResources.length &&
          this.state.bookResources.length !== 0
        ) {
          this.setState(
            {
              pageNumber: this.state.pageNumber + 1,
            },
            () => this.fetchBooks(this.state.searchString)
          );
        } else {
          this.setState({
            hasMore: false,
          });
        }
      }, 1000);
    }
    if (this.pageLoad) {
      this.fetchBooks(this.state.searchString);
      this.pageLoad = false;
    }
  }

  addMultipleItems = (bid) => {
    this.state.multipleItems.push(bid);
    this.setState({ multipleItems: this.state.multipleItems });
  };

  deleteItem = (bid) => {
    let idx = this.state.multipleItems.indexOf(bid);
    this.state.multipleItems.splice(idx, 1);
    this.setState({ multipleItems: this.state.multipleItems });
  };

  addMultipleItemsToLibrary = () => {
    for (let i = 0; i < this.state.multipleItems.length; i++) {
      this.props
        .postAddBookToLibraryAction(this.state.multipleItems[i])
        .then((response) => {
          if (response.statusCode === 200) {
          } else {
          }
        })
        .catch(function (error) {
          console.log("Exception! Item not added to library");
        });
    }
  };

  addMultipleItemsToFolder = () => {
    let itemTypes = [];
    for (let i = 0; i < this.state.multipleItems.length; i++) {
      itemTypes.push("Book");
    }
    browserHistory.push({
      pathname: "/library/addfolder",
      state: {
        libraryItemId: this.state.multipleItems,
        libraryItemType: itemTypes,
        isLibrary: false,
      },
    });
    this.props.saveAssignmentItemAction(itemTypes, this.state.multipleItems);
  };

  //This function expects to be bound in a child component's onClick
  handleClickForResourcesThumbnail = function (item) {
    if (this.state?.multiSelectMode) {
      if (this.state.multipleItems.indexOf(item._id) === -1) {
        this.addMultipleItems(item._id, item.type);
      } else {
        this.deleteItem(item._id, item.type);
      }
    } else if (item.file) {
      if (item.printable) {
        window.location.href =
          constants.filesUrl + item?.file?.replace("../", "");
        localStorage.setItem("preview", false);
      } else if (item._id) {
        browserHistory.push("/books/" + item._id + "/reader");
      }
    }
  };

  //This function expects to be bound in a child component's onClick
  handleClickForResourcesAddBtn = function (item) {
    if (this.props.multiSelectMode) {
      if (this.props.multipleItems.indexOf(item._id) === -1) {
        this.props.addMultipleItems(item._id, item.type);
      } else {
        this.props.deleteItem(item._id, item.type);
      }
    } else {
      //This replaces "showTooltip" in popover.
      this.setState({
        isTooltipActive: true,
        success: false,
        showDatePicker: false,
      });
    }
  };

  handleAddMultiple = function (desktop) {
    this.addMultipleItemsToLibrary();
    this.setState(
      {
        multiSelectMode: !this.state.multiSelectMode,
        addedMultipleItemsToLibrary: true,
      },
      () => {
        if (desktop)
          setTimeout(
            () =>
              this.setState({
                addedMultipleItemsToLibrary: false,
                multipleItems: [],
              }),
            3000
          );
      }
    );
  };

  handleAddMultipleToFolder = function (desktop) {
    this.addMultipleItemsToFolder();
    this.setState(
      {
        multiSelectMode: !this.state.multiSelectMode,
        addedMultipleItemsToFolder: true,
      },
      () => {
        if (desktop)
          setTimeout(
            () =>
              this.setState({
                addedMultipleItemsToFolder: false,
                multipleItems: [],
              }),
            3000
          );
      }
    );
  };

  handleSelectResourceClick = (item) => {
    if (this.props.multiSelectMode) {
    } else if (item?._id) {
      this.props.getBookDetailsAction(item._id);
    }
  };

  updateFromSearch = (data, total, clearSearch, nextPage) => {
    this.setState({
      searchTotal: total ?? data?.length ?? 0,
      searchedData: data ?? [],
      searchActive: clearSearch ? false : true,
      hasMore: nextPage !== -1 ? true : false,
    });
  };

  // !  New logic for bulk select //////////////////
  handleSuccessSnackBar = () => {
    this.handleCancel();
    this.setState({ isAddToFolderModalOpen: false });
    this.setState({ addToFolderSuccess: true });
    setTimeout(() => {
      this.setState({ addToFolderSuccess: false });
    }, 3000);
  };

  handleErrorSnackBar = () => {
    this.handleCancel();
    this.setState({ isAddToFolderModalOpen: false });
    this.setState({ addToFolderError: true });
    setTimeout(() => {
      this.setState({ addToFolderError: false });
    }, 3000);
  };

  handleSuccessSnackBarLibrary = () => {
    this.handleCancel();
    this.setState({ addToLibrarySuccess: true });
    setTimeout(() => {
      this.setState({ addToLibrarySuccess: false });
    }, 3000);
  };

  handleErrorSnackBarLibrary = () => {
    this.handleCancel();
    this.setState({ addToLibraryError: true });
    setTimeout(() => {
      this.setState({ addToLibraryError: false });
    }, 3000);
  };

  handleBulkAddToFolderModalOpen = () => {
    this.setState({ isAddToFolderModalOpen: true });
  };

  handleBulkAddToFolderModalClose = () => {
    this.setState({ isAddToFolderModalOpen: false });
  };

  handleCancel = () => {
    this.handleDeselectAll();
    this.setState({ multiSelectMode: !this.state.multiSelectMode });
  };

  handleMultiSelect = (item) => {
    const copyArr = [...this.state.selectedItems];
    if (copyArr.length !== 0) {
      if (!copyArr.filter((e) => e.item_id === item._id).length > 0) {
        copyArr.push({
          item_id: item._id,
          item_name: item.name,
          item_type: "Book",
        });
        let uniqueItems = [
          ...new Map(copyArr.map((v) => [v.item_id, v])).values(),
        ];
        this.setState({ selectedItems: uniqueItems });
      } else {
        copyArr.splice(
          copyArr.findIndex((x) => x.item_id === item._id),
          1
        ); //deleting
        this.setState({ selectedItems: copyArr });
      }
    } else {
      copyArr.push({
        item_id: item._id,
        item_name: item.name,
        item_type: "Book",
      });
      let uniqueItems = [
        ...new Map(copyArr.map((v) => [v.item_id, v])).values(),
      ];
      this.setState({ selectedItems: uniqueItems });
    }
  };

  handleSelectAll = () => {
    this.setState({ areAllSelected: true });
    const copyArr = [];
    this.state.bookResources?.forEach((item) => {
      copyArr.push({
        item_id: item._id,
        item_name: item.name,
        item_type: "Book",
      });
    });
    this.setState({ selectedItems: copyArr });
  };

  handleDeselectAll = () => {
    this.setState({ areAllSelected: false });
    this.setState({ selectedItems: [] });
  };

  handleOpenMultiselect = () => {
    this.setState({ multiSelectMode: !this.state.multiSelectMode });
  };

  handleAddToLibrary = () => {
    try {
      authAPI.bulkAddToLibrary(this.state.selectedItems).then((res) => {
        if (res.status === 200) {
          this.handleSuccessSnackBarLibrary();
        } else {
          this.handleErrorSnackBarLibrary();
        }
      });
    } catch (err) {
      console.log("Bulk add to library failed");
    }
  };

  getResources = () => {
    const resources = this.state?.searchActive
      ? this.state?.searchedData
      : this.state?.bookResources;
    return resources?.length >= 0
      ? resources.map((resource, i) => (
          <ResponsiveResourcesNew
            item={resource}
            key={`book-resource-${i}`}
            i={i}
            handleClickForResourcesThumbnail={
              this.handleClickForResourcesThumbnail
            }
            handleClickForResourcesAddBtn={this.handleClickForResourcesAddBtn}
            handleSelectResourceClick={this.handleSelectResourceClick}
            multiSelectMode={this.state.multiSelectMode}
            addMultipleItems={this.addMultipleItems}
            deleteItem={this.deleteItem}
            multipleItems={this.state.multipleItems}
            active={resource?.name === this.state.selectedBookName}
            resourceType={"Book"}
            handleMultiSelect={this.handleMultiSelect}
            selectedItems={this.state.selectedItems}
            testProp={"testing"}
            bookId={resource?._id}
          />
        ))
      : [];
  };

  render() {
    const navButtons = (
      <ResponsiveNavBtns
        onSelectMenuItem={this.onSelectMenuItem}
        selectedStrand={this.props.params.strand}
        btnOptions={[
          "featured",
          "reading",
          "writing",
          "language",
          "speaking-listening",
        ]}
      />
    );

    const beforeNonFeaturedContent = this.state.addedMultipleItemsToLibrary &&
      this.state.multipleItems.length !== 0 && (
        <UnattachedPopover
          hideTooltip={() => {
            this.setState({ addedMultipleItemsToLibrary: false });
          }}
        >
          <div className="selected-checkbox-icon-add-multiple icon-icon-checkbox" />
          <span style={{ float: "left" }}>
            Successfully added&nbsp;
            <strong>
              {this.state.multipleItems.length}&nbsp;
              {this.state.multipleItems.length === 1 ? (
                <span>item</span>
              ) : (
                <span>items</span>
              )}
            </strong>
            &nbsp;to library
          </span>
          <div>
            <span>
              <button
                className="go-to-my-library-add-multiple"
                onClick={() => browserHistory.push("/library/assessments")}
              >
                GO TO MY LIBRARY
              </button>
            </span>
          </div>
        </UnattachedPopover>
      );

    return (
      <>
        <BrowsePage
          name="Texts"
          typeOfResource="books"
          strandValue={this.props.params.strand}
        >
          <BrowsePageContent
            name="Books"
            isFeatured={this.state.isFeatured}
            resources={this.getResources()}
            navButtons={navButtons}
            resourceHeading={
              <ResourceHeading
                multiSelectMode={this.state.multiSelectMode}
                areAllSelected={this.state.areAllSelected}
                handleOpenMultiselect={this.handleOpenMultiselect}
                handleCancel={this.handleCancel}
                handleDeselectAll={this.handleDeselectAll}
                handleSelectAll={this.handleSelectAll}
                handleAddToLibrary={this.handleAddToLibrary}
                total={
                  this.state.searchActive
                    ? this.state?.searchTotal
                    : this.state.total ?? 0
                }
                data={this.state.data}
                handleBulkAddToFolderModal={this.handleBulkAddToFolderModalOpen}
              />
            }
            asyncStatus={
              this.props.book.asyncStatus || this.props.filtersAsyncStatus
            }
            beforeNonFeaturedContent={beforeNonFeaturedContent}
            hasMore={this.state.hasMore}
            loadMore={this._loadMore.bind(this)}
            updateFromSearch={this.updateFromSearch}
            getSearchNextPage={this.state.getSearchNextPage}
            setNoSearchNextPage={() =>
              this.setState({ getSearchNextPage: false })
            }
            location={this.props.location}
            selectedResource={
              this.state.bookDetails?.name ? this.state.bookDetails : ""
            }
            selectedResourceType={"Books"}
            fetchBooks={this.fetchBooks}
          />

          <SnackBarModule
            addToFolderSuccess={this.state.addToFolderSuccess}
            addToFolderError={this.state.addToFolderError}
            addToLibrarySuccess={this.state.addToLibrarySuccess}
            addToLibraryError={this.state.addToLibraryError}
          />
        </BrowsePage>

        {this.state.isAddToFolderModalOpen && (
          <AddToFolderModal
            handleClose={this.handleBulkAddToFolderModalClose}
            handleSuccessSnackbar={this.handleSuccessSnackBar}
            handleErrorSnackbar={this.handleErrorSnackBar}
            selectedItems={this.state.selectedItems}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    book: state.Books,
    finalFilters: state.FilterScale,
    bookDetails: state.BookDetails,
    filtersAsyncStatus: state.CustomFilters?.asyncStatus,
  };
};

export default connect(mapStateToProps, actions)(NewBooksComponent);
