import React, { useState } from "react";

const ReadMore = ({ text, maxLength }) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  const truncatedText =
    text.length > maxLength
      ? `${text.substring(0, maxLength).trim()}...`
      : text;

  // Additional styles to ensure the container can expand and contract as needed
  const containerStyle = {
    marginBottom: "3rem", // Ensure there's space below the container
    overflow: "hidden", // Hide overflow content when not showing full text
    height: showFullText ? "auto" : "100px", // Adjust the height dynamically
    transition: "height 0.3s ease", // Smooth transition for height adjustment
  };

  return (
    <div style={containerStyle}>
      <div
        dangerouslySetInnerHTML={{
          __html: showFullText ? text : truncatedText,
        }}
      />
      <span
        onClick={toggleFullText}
        style={{
          cursor: "pointer",
          color: "grey",
          display: "block",
          marginTop: "1.5rem",
        }}
      >
        {showFullText ? "Read Less" : "Read More"}
      </span>
    </div>
  );
};

export default ReadMore;
