import React,{Component} from 'react'
import authAPI from '../api/authAPI'
import constants from '../constants'
import InfiniteScroll from 'react-infinite-scroller'
import {browserHistory} from 'react-router'
import {Row, Media, Image} from 'react-bootstrap'
import '../assets/styles/globalSearchComponent.scss'

class GlobalSearchComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
           totalCount:0,
           resources:[],
           pageNumber:1,
           searchString:'',
           hasMore:true,
           lessonIds:[],
           selectedFilter:false,
           showOptions:false,
           queryItems:"",
           Assessment:{},
           Book:{},
           Lesson:{},
           Resource:{},
           Unit:{},
           queryString:'',
           timeout:300
        };
        this.pageLoad = true;
        this.fetchResources = this.fetchResources.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    setFocus=()=>{
      if(window.innerWidth >= 768)
          this.gscInput.focus();
    }

    handleClick(e,i,item) {
       let selectedItem = this.state.queryItems;
       let value=e.target.checked;
       let stateQuery = item.query;
       let currentQuery = this.state.queryString;
       if(value){
            item.checked= true;
            if(this.props.itemType==='Planer' && currentQuery=== '&item_type=Lesson&item_type=Resource&item_type=Book&item_type=Assessment'){
                currentQuery= '';
                selectedItem='';
            }
            if(selectedItem===''){
                selectedItem = selectedItem+item.name;
            }
            else{
                selectedItem = selectedItem+', '+item.name;
            }
            currentQuery = currentQuery+'&item_type='+item.query;

       }
       else{
            item.checked= false;
            let queryRe = new RegExp('&item_type='+item.query);
            currentQuery = currentQuery.replace(queryRe,'');
            let re = new RegExp(', '+item.name);
            selectedItem = selectedItem.replace(re,'');
            if(selectedItem===this.state.queryItems){
                let re = new RegExp(item.name+', ');
                selectedItem = selectedItem.replace(re,'');
            }
            if(this.state.queryItems===item.name){
                selectedItem='';
            }
            if(this.props.itemType==='Planer' && currentQuery===''){
                currentQuery= '&item_type=Lesson&item_type=Resource&item_type=Book&item_type=Assessment';
                selectedItem='Assessments, Books, Resources, Lessons';
            }
       }

        if(document.querySelectorAll(':checked').length === 0){
            this.setState({
                selectedFilter:false,
                resources: [],
                totalCount: 0
            });
        }else{
            this.setState({queryItems:selectedItem, stateQuery:item, queryString:currentQuery, pageNumber:1, selectedFilter:true}, ()=>{this.fetchResources(this.state.searchString)});
        }
       
    }

    fetchResources(searchString){
        setTimeout(() => {
                let self=this;
                
                if(self.state.selectedFilter===true){
                    self.state.resources = [];
                    self.setState({selectedFilter:false});
                }
                // Use /books endpoint if the logged in user is a student
                const resourceSearchPromise = (localStorage.user_type === "Student")?
                    authAPI.getBooks(self.state.pageNumber,'&search=' + searchString):
// Added is_lite parameter to global search query
// Sends this info to authAPI line 646
                    authAPI.globalSearch(self.state.pageNumber,searchString,self.state.queryString,localStorage.is_lite);

                resourceSearchPromise.then((resp) => {
                    console.log("hello");
                    self.state.totalCount = 0;
                    if(!resp.results){
                        let props = {
                                resources:[],
                                totalCount:0
                            };
                            self.state.resources=[];
                            self.state.totalCount=0;
                            self.setState(props);
                        return false;
                    }
                    if(searchString !== self.state.searchString){
                        self.state.resources = [];
                        self.state.pageNumber = 1;
                    }
                    self.state.searchString = searchString;
                    self.state.totalCount = resp.total;
                    resp.results.map(function(item){
                        self.state.resources.push(item);
                    });
                    let props = {
                                resources:self.state.resources,
                                totalCount:resp.total,
                                searchString:searchString
                            };
                            self.setState(props);
                    if(!self.pageLoad){
                    setTimeout(function(){
                        if(self.state.totalCount > self.state.resources.length){
                            self.setState({hasMore : true});
                        }
                    },500);
                }
                //self.pageLoad = false;
            })
        },this.state.timeout)
    }

    componentDidMount() {
// Added logic for lite users
        if(localStorage.is_lite === 'true'){
            this.setState({
                Assessment:{disabled:false,name:'Assessments',checked:false,query:'Assessment'},
                Book:{disabled:false,name:'Books',checked:false,query:'Book'},
                Lesson:{disabled:false,name:'Lessons',checked:false,query:'Lesson'},
                Resource:{disabled:false,name:'Resources',checked:true,query:'Resource'},
                Unit:{disabled:true,name:'Units',checked:false,query:'Unit'},
                queryItems:"Resources",
                queryString:'&item_type=Resource'
            });
        }
        else if(this.props.itemType==='Teaching Resources'){
            this.setState({
                Assessment:{disabled:false,name:'Assessments',checked:false,query:'Assessment'},
                Book:{disabled:false,name:'Books',checked:false,query:'Book'},
                Lesson:{disabled:false,name:'Lessons',checked:true,query:'Lesson'},
                Resource:{disabled:false,name:'Resources',checked:true,query:'Resource'},
                Unit:{disabled:false,name:'Units',checked:false,query:'Unit'},
                queryItems:"Lessons, Resources",
                queryString:'&item_type=Lesson&item_type=Resource'
            });
        }
        else if(this.props.itemType==='Units'){
            this.setState({
                Assessment:{disabled:false,name:'Assessments',checked:false,query:'Assessment'},
                Book:{disabled:false,name:'Books',checked:false,query:'Book'},
                Lesson:{disabled:false,name:'Lessons',checked:false,query:'Lesson'},
                Resource:{disabled:false,name:'Resources',checked:false,query:'Resource'},
                Unit:{disabled:false,name:'Units',checked:true,query:'Unit'},
                queryItems:"Units",
                queryString:'&item_type=Unit'
            });
        }
        else if(this.props.itemType==='Books'){
            this.setState({
                Assessment:{disabled:false,name:'Assessments',checked:false,query:'Assessment'},
                Book:{disabled:false,name:'Books',checked:true,query:'Book'},
                Lesson:{disabled:false,name:'Lessons',checked:false,query:'Lesson'},
                Resource:{disabled:false,name:'Resources',checked:false,query:'Resource'},
                Unit:{disabled:false,name:'Units',checked:false,query:'Unit'},
                queryItems:"Books",
                queryString:'&item_type=Book'
            });
        }
        else if(this.props.itemType==='Assessments'){
            this.setState({
                Assessment:{disabled:false,name:'Assessments',checked:true,query:'Assessment'},
                Book:{disabled:false,name:'Books',checked:false,query:'Book'},
                Lesson:{disabled:false,name:'Lessons',checked:false,query:'Lesson'},
                Resource:{disabled:false,name:'Resources',checked:false,query:'Resource'},
                Unit:{disabled:false,name:'Units',checked:false,query:'Unit'},
                queryItems:"Assessments",
                queryString:'&item_type=Assessment'
            });
        }
        else if(this.props.itemType==='Planer'){
            this.setState({
                Assessment:{disabled:false,name:'Assessments',checked:true,query:'Assessment'},
                Book:{disabled:false,name:'Books',checked:true,query:'Book'},
                Lesson:{disabled:false,name:'Lessons',checked:true,query:'Lesson'},
                Resource:{disabled:false,name:'Resources',checked:true,query:'Resource'},
                Unit:{disabled:true,name:'Units',checked:false,query:'Unit'},
                queryItems:"Assessments, Books, Resources, Lessons",
                queryString:'&item_type=Lesson&item_type=Resource&item_type=Book&item_type=Assessment'
            });
        }
        else if(this.props.itemType==='Library' && localStorage.user_type === "Student"){
            this.setState({
                Assessment:{disabled:false,name:'Assessments',checked:true,query:'Assessment'},
                Book:{disabled:false,name:'Books',checked:true,query:'Book'},
                Lesson:{disabled:false,name:'Lessons',checked:true,query:'Lesson'},
                Resource:{disabled:false,name:'Resources',checked:true,query:'Resource'},
                Unit:{disabled:false,name:'Units',checked:false,query:'Unit'},
                queryItems:"Books",
                queryString:'item_type=Book'
            });
        }
        else{
           this.setState({
                Assessment:{disabled:false,name:'Assessments',checked:true,query:'Assessment'},
                Book:{disabled:false,name:'Books',checked:true,query:'Book'},
                Lesson:{disabled:false,name:'Lessons',checked:true,query:'Lesson'},
                Resource:{disabled:false,name:'Resources',checked:true,query:'Resource'},
                Unit:{disabled:false,name:'Units',checked:true,query:'Unit'},
                queryItems:"Assessments, Books, Resources, Lessons, Units",
                queryString:'&item_type=Lesson&item_type=Resource&item_type=Unit&item_type=Book&item_type=Assessment'
            });
        }
        this.setState({timeout:0});
        //this.refs.textData.focus()
       //this.fetchResources("","init");
    }

// This method isn't necessary if the checkboxes aren't being used
// Greyed out right now because I disabled the click
    optionToggle=()=>{
        this.setState({showOptions:!this.state.showOptions});
    };

    toggleLesson=(lesson)=>{
        let lessonId = lesson._id;
        let lessonIdArray = this.state.lessonIds;
        let lessonIdIndex = lessonIdArray.indexOf(lessonId);
        if(lessonIdIndex >-1){
            lessonIdArray.splice(lessonIdIndex,1);
            this.setState({
                lessonIds:lessonIdArray
            });
        }
        else{
            lessonIdArray.push(lessonId);
            this.setState({
                lessonIds:lessonIdArray
            });
        }
        this.props.sendResourceDetails(lesson._id,lesson.type);
    };

    componentWillReceiveProps(next){
        if(!next.closeStatus){
            this.setState({lessonIds:[]});
        }
        if(next.focus){
          this.setFocus();
        }
    }

    navigateToDetails=(lesson)=>{
        if(lesson.type==='Resource'){
            browserHistory.push('/resources/'+lesson._id);
        }
        else if(lesson.type==='Book'){
            browserHistory.push('/books/'+lesson._id);
        }
        else if(lesson.type==='Lesson'){
            browserHistory.push('/lessons/'+lesson._id);
        }
        else if(lesson.type==='Unit'){
            browserHistory.push('/units/'+lesson._id);
        }
        else if(lesson.type==='Assessment'){
            browserHistory.push('/assessments/'+lesson._id);
        }
        else{
            browserHistory.push('/')
        }
    };

    searchLessons(e){
        let searchString = e.target.value;
        this.fetchResources(searchString);
    }

    _loadMore(){
        setTimeout(() => {
            if(this.state.totalCount > this.state.resources.length){
                this.state.pageNumber++;
                this.fetchResources(this.state.searchString);
            }
            else{
                this.setState({
                    hasMore: false
                });
            }
        },1000);

        if(this.pageLoad){
            this.fetchResources(this.state.searchString);
            this.pageLoad = false;
        }
    }

// Conditional rendering of clickable filter menu or Teaching resources only.
    // {/*Changed from <div className={localStorage.user_type !== "Student" ? "icon-icon-dropdownsearch global-textbox-icon-search" : "icon-icon-search global-textbox-icon-search"} style={{color: this.state.showOptions ? '#009BBD' : '#9B9B9B'}}/>*/}
    // Deleted style={{cursor: 'pointer'}}
    // Deleted onClick={() => this.optionToggle()}
    renderFilter = () => {
        if (localStorage.is_lite === 'true') {
            return (
                <div>
                    <div className="icon-icon-search global-textbox-icon-search" style={{color: '#9B9B9B'}}/>
                </div>
            )
        } else {
            return (
                <div style={{cursor: 'pointer'}} onClick={() => this.optionToggle()}>
                    <div className={localStorage.user_type !== "Student" ? "icon-icon-dropdownsearch global-textbox-icon-search" : "icon-icon-search global-textbox-icon-search"} style={{color: this.state.showOptions ? '#009BBD' : '#9B9B9B'}}/>
                </div>
            )
        }
    }

     render(){
        let height = window.innerHeight;
        const searchItems= localStorage.is_lite === 'true' ? ['Resource'] : ['Assessment','Resource','Book','Lesson','Unit'];
        return(
            <div>
                <div  className='global-search-component' >
                    <div className='global-searchbar-textbox'>
                        {localStorage.user_type !== "Student" && this.renderFilter()}
                        {localStorage.user_type === "Student" &&
                            <div>
                                <div className="icon-icon-search global-textbox-icon-search" style={{color: this.state.showOptions ? '#009BBD' : '#9B9B9B'}}/>
                            </div>
                        }
                        <input
                            type='text'
                            ref={(e)=> this.gscInput = e}
                            className='global-unit-searchbar'
                            placeholder={(this.state.queryItems.length<39)?this.state.queryItems:"Search Everything"}
                            onChange={this.searchLessons.bind(this)}
                            />
                        {/*{this.state.showOptions &&
                            <div className='dropdown-select'>
                                {searchItems.map((item, i) => (
                                    <div id='selectId' className={this.state[item].disabled ? 'disabled-checkbox' : ''}>
                                        <div className='checkbox-option' key={item + i}>
                                            <input ref={item + i}
                                                   style={{cursor: this.state[item].disabled ? '' : 'pointer'}}
                                                   type="checkbox"
                                                   onChange={(e) => this.handleClick(e, i, this.state[item])}
                                                   disabled={this.state[item].disabled}
                                                   checked={this.state[item].checked}/>
                                            <label className='checkbox-option-text' value={item}>
                                                {this.state[item].name}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }*/}
                        {(this.state.showOptions && localStorage.user_type !== "Student") &&
                            <div className='dropdown-select'>
                                {searchItems.map((item,i)=>(
                                    <div id='selectId' className={this.state[item].disabled?'disabled-checkbox':''} >
                                        <div className='checkbox-option' key={item+i}>
                                            <input
                                                ref={item+i}
                                                style={{cursor:this.state[item].disabled?'':'pointer'}}
                                                type="checkbox"
                                                onChange={(e) => this.handleClick(e,i,this.state[item])}
                                                disabled={this.state[item].disabled}
                                                checked={this.state[item].checked}
                                                />
                                            <label className='checkbox-option-text' value={item}>
                                                {this.state[item].name}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>

                    <div className='search-result-count'>
                        {this.state.totalCount?this.state.totalCount+' RESULTS':'NO RESULTS'}
                    </div>
                    <div className='global-lesson-search-results' style={{height:(height-250)+'px', overflowY: 'scroll'}}>
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={this._loadMore.bind(this)}
                            hasMore={this.state.hasMore}
                            loader={<div className="loader" style={{textAlign:'center'}}>Loading ...</div>}
                            useWindow={false}
                        >
                            <div>
                                {this.state.resources && this.state.resources.map((lesson,i)=>(
                                    <Row key={lesson+i} bsClass='global-search-row'>
                                        <Media>
                                            <Media.Left align="middle">
                                                <Image src={constants.filesUrl+((lesson.image != null && lesson.image.includes("{") ? (lesson.image.replace("{","")).replace("}","").split(",")[0]+'.thumb.jpg':lesson.image)||'')}
                                                       onClick={()=>this.navigateToDetails(lesson)}
                                                       thumbnail={true}
                                                       responsive
                                                />
                                            </Media.Left>
                                            <Media.Body>
                                                {lesson.name &&
                                                    <div className='global-search-result-text'
                                                         onClick={()=>this.navigateToDetails(lesson)}>
                                                        {lesson.name}
                                                    </div>
                                                }
                                            </Media.Body>
                                            <Media.Right align="middle">
                                                {this.props.itemType==='Planer'?
                                                    <div className={(this.state.lessonIds).indexOf(lesson._id)>-1?'lesson-search-fadded-icon icon-icon-add-circle-solid':'lesson-search-add-icon icon-icon-add-circle-solid'}
                                                         onClick={(this.state.lessonIds).indexOf(lesson._id)>-1?'':()=>this.toggleLesson(lesson)}
                                                    />:''
                                                }
                                            </Media.Right>
                                        </Media>
                                    </Row>
                                ))}
                            </div>
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        )
    }
}

export default GlobalSearchComponent
