import React from "react";
import trashCan from "../../assets/icons/SVG/delete.svg";
import closeButtonIcon from "../../assets/icons/SVG/my-close-button.png";
import checkMarkWhite from "../../assets/icons/SVG/icon-check.svg";

export const DeleteConfirmationModal = ({ handleRemoveFromLibrary, handleDeleteModalClose }) => {
    return (
        <div style={{ zIndex: 99999 }} className='add-to-folder-modal-overlay'>
            <div className='add-to-folder-modal-wrapper'>
                <div className='add-to-folder-modal-header'>
                    <div><img src={trashCan} alt="Delete" /> Remove Confirmation </div>
                    <div className='add-to-folder-modal-close-btn' onClick={() => handleDeleteModalClose()}>
                        <img src={closeButtonIcon} alt="Close Button" />
                        <div>Cancel</div>
                    </div>
                </div>
                <div className="delete-modal-content">
                    <div style={{ marginBottom: 20 }}>
                        Are You Sure You Want to Remove? This Action is Irreversible.
                    </div>
                    <div className='delete-modal-confirm-button'>
                        <span onClick={() => {
                            handleRemoveFromLibrary()
                            handleDeleteModalClose()
                        }}>
                            <img src={checkMarkWhite} alt="Confirm Button" /> Confirm Remove
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}