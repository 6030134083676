import React, {Component} from 'react'
import MyToolbar from './MyToolbar'
import MyEventWrapper from './MyEventWrapper'
import InformationBar from  './InformationBar'
import AgendaDateComponent from  './AgendaDateComponent'
import AgendaEventComponent from './AgendaEventComponent'
import '../assets/styles/myStyles.scss'
import * as actions from '../redux/actions'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {DragDropContext} from 'react-dnd'
// import BigCalendar from 'react-big-calendar'
import {Row, Col} from 'react-bootstrap';
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment'
import GlobalSearch from '../components/GlobalSearchComponent'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import Layout from "./Shared/Layout"
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch' 
import { TouchBackend } from 'react-dnd-touch-backend'

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

// BigCalendar.momentLocalizer(moment);
// const DragAndDropCalendar = withDragAndDrop(BigCalendar, {backend: MultiBackend(HTML5toTouch)});

function modifyEvents(scheduledTasks,collapseList){
    let arrBeforeSorting = scheduledTasks.data;
    let arrAfterSorting =[];

    // sort events according to day order
    for(let a=0; a< arrBeforeSorting.length ; a++){
        let eventsInaDay = arrBeforeSorting[a].scheduledTasks;
        for(let b=0;b< eventsInaDay.length; b++){
            eventsInaDay.sort(function(a,b){
                return( a.day_order - b.day_order);
            });
        }
        arrAfterSorting[a] = {"scheduledTasks": eventsInaDay};
    }

    let arr = scheduledTasks.data;

    let newEvents = [];
    let j,k,dayOrder = 0;
    for (let i = 0; i < arr.length; i++) {
        for (j = 0, k=0; j < arr[i].scheduledTasks.length; j++, k=k+2.5) {
            let desc = "";
            if (arr[i].scheduledTasks[j].item_type !== "Book") {
                if (arr[i].scheduledTasks[j].item.description) {
                    if (arr[i].scheduledTasks[j].item.description.text) {
                        desc = arr[i].scheduledTasks[j].item.description.text;
                    }
                    else if (arr[i].scheduledTasks[j].item.description.description) {
                        desc = arr[i].scheduledTasks[j].item.description.description;
                    }
                }
                else if (arr[i].scheduledTasks[j].item.rationale) {
                    if (arr[i].scheduledTasks[j].item.rationale.text) {
                        desc = arr[i].scheduledTasks[j].item.rationale.text;
                    }
                }
            }
            else {
                desc = arr[i].scheduledTasks[j].item.description.text;
            }

            /*Push all the scheduled Tasks into newEvents array*/
            newEvents.push({
                'title': arr[i].scheduledTasks[j].item.name,
                'desc':  desc,
                'item_id': arr[i].scheduledTasks[j].item._id,
                'item_type': arr[i].scheduledTasks[j].item_type,
                'day_order':j+1,
                '_id': arr[i].scheduledTasks[j].scheduled_task_id,
                'start': new Date(new Date(arr[i].date).getTime() + ((j)*3600*1000)),
                'end': new Date(new Date(arr[i].date).getTime() + ((j+1)*3600*1000)),
                'image':arr[i].scheduledTasks[j].item.image
            });
           dayOrder = arr[i].scheduledTasks[j].day_order;
        }

            dayOrder = 0;

    }

    return newEvents.map(e => {
        return Object.assign({}, e, {
            __collapsed: collapseList
        })
    });
}

class HomeComponent extends Component {
    constructor () {
        super();
        this.state = {
            collapsed: {},
            myDefaultDate: new Date(),
            stateEvents: [],
            isSidebarOpen:false,
            resourceId:'',
            resourceType:'',
            date:'',
            itemType: 'Planer'
        };
        this.toggleEvents = this.toggleEvents.bind(this);
        this.moveEvent = this.moveEvent.bind(this);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.scheduledTasks) {
            if(nextProps.scheduledTasks.asyncStatus === "SUCCESS") {
                this.setState({stateEvents: modifyEvents(nextProps.scheduledTasks,this.state.collapsed) })
            }
        }
    }

    componentDidMount(){
        let plannerDate = this.props.plannerDate && this.props.plannerDate.data && this.props.plannerDate.data.date;
        if(plannerDate!==undefined){
            this.setState({myDefaultDate:plannerDate},()=>this.props.getScheduledTasksAction("grid",this.state.myDefaultDate))
        }else{
            this.props.getScheduledTasksAction("grid",this.state.myDefaultDate)
        }
    }

    componentDidUpdate(){
       setTimeout(() => { let divs = document.getElementsByClassName("rbc-event");
           for(let i=0; i < divs.length; i++)
               divs[i].title =""; }, 0)
    }

    toggleEvents=(someDate)=>{
        this.state.collapsed[someDate ] = !this.state.collapsed[someDate];
        this.setState({collapsed : this.state.collapsed})
    };

    moveEvent({ event, start, end }) {
        if(event.title === "Add Item")
            return;

        /* Moving the scheduled tasks to different days in a week*/
        if(event.start.getDay() !== start.getDay()){

            let newDayOrder =  parseInt(end.getHours());
            start= new Date(new Date(start).getTime() + ((newDayOrder)*3600*1000));
            end = new Date(new Date(start).getTime() + ((newDayOrder +1)*3600*1000));

            const { stateEvents } = this.state;
            const scheduled_task_id = event._id;

            const idx = stateEvents.indexOf(event);
            const updatedEvent = { ...event, start, end };

            const nextEvents = [...stateEvents];

            nextEvents.splice(idx, 1, updatedEvent);

            /*Ordering the tasks on a day from which a task is moved to different day*/
           for(let i=0,j=1;i<nextEvents.length;i++){
                if(nextEvents[i].start.getDay() === event.start.getDay()){
                    nextEvents[i].day_order = j;
                    nextEvents[i].start = new Date(new Date(event.start.setHours(0,0,0,0)).getTime() + ((j-1)*3600*1000));
                    nextEvents[i].end = new Date(new Date(event.start.setHours(0,0,0,0)).getTime() + ((j)*3600*1000));
                    j++;
                }
           }

           /*Ordering the tasks on the day on to which a task is moved to*/
           let eventDayOrder = newDayOrder,tempDayOrder = 1, flag=0;
            for(let i=0,j=1;i<nextEvents.length;i++){
                if(nextEvents[i].start.getDay() === start.getDay() && nextEvents[i]._id !== scheduled_task_id ){
                   if(nextEvents[i].day_order > newDayOrder || nextEvents[i].day_order === newDayOrder) {
                       nextEvents[i].day_order = nextEvents[i].day_order +1 ;
                       nextEvents[i].start = new Date(new Date(start.setHours(0,0,0,0)).getTime() + ((nextEvents[i].day_order-1)*3600*1000));
                       nextEvents[i].end = new Date(new Date(start.setHours(0,0,0,0)).getTime() + ((nextEvents[i].day_order)*3600*1000));
                        flag=2;
                   }
                   if(flag !== 2)
                   flag=1;
                   if(tempDayOrder <= nextEvents[i].day_order)
                   tempDayOrder = nextEvents[i].day_order +1;
                }
            }

            /*Conditionally assigning the day_order based on the new task being appended to the end of existing tasks on a day, new task being the
            only task on the day, new task is added in the middle of the existing tasks*/
            for(let i=0;i<nextEvents.length;i++){
                if(nextEvents[i]._id === scheduled_task_id && flag === 1){
                    nextEvents[i].day_order = tempDayOrder;
                    nextEvents[i].start = new Date(new Date(start.setHours(0,0,0,0)).getTime() + ((nextEvents[i].day_order-1)*3600*1000));
                    nextEvents[i].end = new Date(new Date(start.setHours(0,0,0,0)).getTime() + ((nextEvents[i].day_order)*3600*1000));
                }
                if(nextEvents[i]._id === scheduled_task_id && flag === 2){
                    nextEvents[i].day_order = eventDayOrder;
                    nextEvents[i].start = new Date(new Date(start.setHours(0,0,0,0)).getTime() + ((nextEvents[i].day_order-1)*3600*1000));
                    nextEvents[i].end = new Date(new Date(start.setHours(0,0,0,0)).getTime() + ((nextEvents[i].day_order)*3600*1000));
                }
                if(nextEvents[i]._id === scheduled_task_id && flag === 0){
                    nextEvents[i].day_order = 1 ;
                    nextEvents[i].start = new Date(new Date(start.setHours(0,0,0,0)).getTime() + ((nextEvents[i].day_order-1)*3600*1000));
                    nextEvents[i].end = new Date(new Date(start.setHours(0,0,0,0)).getTime() + ((nextEvents[i].day_order)*3600*1000));
                }
            }

            /*Creating the post object to send post request to order the tasks*/
            let postObject=[];

            for(let i=0,j=0;i < nextEvents.length;i++){
                    if(nextEvents[i].start.getDay() === event.start.getDay() || nextEvents[i].start.getDay() === start.getDay()){
                    if(nextEvents[i]._id === scheduled_task_id){
                        postObject[j] = {"_id": nextEvents[i]._id,"day_order":nextEvents[i].day_order,"day" : nextEvents[i].start} ;
                    }
                    else{
                        postObject[j] = {"_id": nextEvents[i]._id,"day_order":nextEvents[i].day_order} ;
                    }
                    j++;
                    }
            }

            /*Making a backend call to order the tasks*/
            this.props.orderScheduledTasksAction(JSON.stringify(postObject));

            /*setting the state of the component with updated events array*/
            this.setState({
                stateEvents: nextEvents
            })
        }
        /* Reordering the scheduled tasks within a day*/
        else{
            let newDayOrder =  parseInt(start.getHours()) + 1;
            newDayOrder = newDayOrder - event.day_order;
            start= new Date(new Date(event.start).getTime() + ((newDayOrder)*3600*1000));
            end = new Date(new Date(event.start).getTime() + ((newDayOrder +1)*3600*1000));

            const { stateEvents } = this.state;
            const idx = stateEvents.indexOf(event);
            const updatedEvent = { ...event, start, end };
            const nextEvents = [...stateEvents]
            nextEvents.splice(idx, 1, updatedEvent)

            /*Push the tasks on the day on which we are reordering to a temporary array*/
            let tempArr =[];
            for(let i=0; i < nextEvents.length;i++){
               if (start.getDay() === nextEvents[i].start.getDay() && nextEvents[i].title !== 'Add Item'){
                   tempArr.push(nextEvents[i]) ;
               }
            }

            /*Sort the temporary array based on the slot*/
            tempArr.sort(function(a,b){
               return( a.start.getTime() - b.start.getTime());
            });

            for(let i=0;i<tempArr.length-1;i++){
                if (tempArr[i].start.getTime() === tempArr[i+1].start.getTime()) {
                    let temp = tempArr[i];
                    tempArr[i] = tempArr[i + 1];
                    tempArr[i + 1] = temp;
                }
            }

            /*Assign the day_order according to the slot*/
            tempArr.map((e,i) => Object.assign({},e,e.day_order=i+1));
            let tempDate = new Date(tempArr[0].start.setHours(0,0,0,0));
            for(let i=0;i< tempArr.length;i++){
                tempArr[i].start = new Date(tempDate.getTime() + ((tempArr[i].day_order -1)*3600*1000));
                tempArr[i].end = new Date(tempDate.getTime() + ((tempArr[i].day_order)*3600*1000));
            }

            /*Creating the post object to send post request to order the tasks*/
            let postObject=[];
            for(let i=0,j=0;i < tempArr.length;i++){
                if (tempArr[i].title !== 'Add Item'){
                    postObject[j] = {"_id": tempArr[i]._id,"day_order":tempArr[i].day_order} ;
                    j++;
                }
            }
            this.props.orderScheduledTasksAction(JSON.stringify(postObject));

            /*Update the tasks in events array with the new reordered tasks in the temporary array*/
            for(let i=0,j=0;i<nextEvents.length;i++){
               if (start.getDay() === nextEvents[i].start.getDay() && nextEvents[i].title !== 'Add Item'){
                   nextEvents.splice(i, 1, tempArr[j]);
                   j++;
               }
            }

            /*setting the state of the component with updated events array*/
            this.setState({
                stateEvents: nextEvents
            });
        }
    }

    showSearchPanel=(showStatus,date)=>{
        this.setState({isSidebarOpen:showStatus,date:date && date._d});
    };

    setItemType=(type)=>{
        this.setState({itemType: type})
    };

    AddtoPlaner= (id,typeOfResource) => {
        this.setState({
            resourceId: id,
            resourceType: typeOfResource
        }, () => {
            this.props.postScheduledTasksAction(this.state.date, this.state.resourceId, this.state.resourceType)
                .then((response) => {
                    if (response.status === 201) {
                        this.props.getScheduledTasksAction("grid",this.state.date);
                    } else {
                       console.log('failure');

                    }
                })
                .catch(function (error) {
                    console.log("exception occured");
                });
        });
    };

    renderDragAndDropCalendar() {
       let plannerDate = this.props.plannerDate && this.props.plannerDate.data && this.props.plannerDate.data.date
        const dragAndDropSharedProps = {
            events: this.state.stateEvents,
            components: {
                work_week: {
                    event: MyEventWrapper
                },
                toolbar: MyToolbar,
                agenda: {
                    date: AgendaDateComponent.bind(this, this.toggleEvents, this.state.collapsed),
                    event: AgendaEventComponent.bind(this, this.props.getScheduledTasksAction)
                }
            },
            startAccessor: 'start',
            endAccessor: 'end',
            formats: {
                agendaTimeFormat: ' ',
                timeGutterFormat: ' ',
                dayFormat: 'ddd DD',
                agendaDateFormat: 'dddd MMMM DD, YYYY'
            },
            messages: {
                allDay: '',
                agenda: 'LIST',
                work_week: 'CALENDAR',
                week: 'Grid',
                today: 'TODAY'
            },
            onEventDrop: this.moveEvent,
            defaultDate: ((plannerDate!==undefined || plannerDate!=='')?plannerDate:this.state.myDefaultDate),
            selectable: false,
            longPressThreshold: 0,
            timeslots: 1,
            step: 60
        };
       return (
           <Row>
               <Col sm={12} xsHidden>
                   {/*<DnDCalendar*/}
                       {/*{...dragAndDropSharedProps}*/}
                       {/*toolbar*/}
                       {/*defaultView='work_week'*/}
                       {/*views={["work_week","agenda"]}*/}
                       {/*style={{ height: "100vh" }}*/}
                   {/*/>*/}
                    {/*<DragAndDropCalendar*/}
                        {/*{...dragAndDropSharedProps}*/}
                        {/*toolbar*/}
                        {/*defaultView='work_week'*/}
                        {/*views={["work_week","agenda"]}*/}
                    {/*/>*/}
               </Col>
               <Col xs={12} smHidden mdHidden lgHidden>
                   {/*<DnDCalendar*/}
                       {/*/!*{...dragAndDropSharedProps}*!/*/}
                       {/*toolbar*/}
                       {/*defaultView='agenda'*/}
                       {/*views={['agenda']}*/}
                       {/*style={{ height: "100vh" }}*/}
                   {/*/>*/}
                   {/*<DragAndDropCalendar*/}
                       {/*{...dragAndDropSharedProps}*/}
                       {/*toolbar*/}
                       {/*defaultView='agenda'*/}
                       {/*views={['agenda']}*/}
                   {/*/>*/}
               </Col>
           </Row>);
    }

    render() {

        const header = (
            <InformationBar
                setItemType={this.setItemType}
                showSearchResource={this.showSearchPanel}
                panelStatus={this.state.isSidebarOpen}
                itemType={this.state.itemType}
            />
        );

        const main = (
            <div className='planner-container'>
                {/*{this.renderDragAndDropCalendar()}*/}
            </div>
        );

        const sidebar = (
            <GlobalSearch itemType={this.state.itemType}
                          sendResourceDetails={this.AddtoPlaner}
                          closeStatus={this.state.isSidebarOpen}
                          focus={this.state.isSidebarOpen && window.innerWidth > 767}
            />
        );

        return (
            <DndProvider backend={TouchBackend} options={HTML5toTouch}>
                <Layout
                    header={header}
                    main={main}
                    sidebar={sidebar}
                    isGlobalSearch={true}
                    isSidebarOpen={this.state.isSidebarOpen}
                />
            </DndProvider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        scheduledTasks : state.ScheduledTasks,
        orderScheduledTasks : state.OrderScheduledTasks,
        plannerDate : state.PlannerDate
    }
};

export default connect(mapStateToProps, actions)(withRouter(HomeComponent));
