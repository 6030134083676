import React, {Component} from 'react'
import constants from '../../constants/index'
import DetailsListViewItem from "./DetailsListViewItem";

class TextContent extends Component {
    render() {
        let mentorTexts = this.props.content.mentorTexts;
        // if(mentorTexts && mentorTexts.length > 0){
        //     mentorTexts.sort((a,b)=>a.name.replace(/\s/g, '').localeCompare(b.name.replace(/\s/g, ''),'en',{numeric:true}));    
        // }
        
        return (
            <div className={this.props.requestType === 'units' ? 'list-of-items unit-texts' : 'lessons-padding'}>
                {!mentorTexts || mentorTexts === 0 ?
                    <div className="featured-reading" style={{textAlign: "center"}}> No data found</div> : ""}
                {mentorTexts && mentorTexts[0] && mentorTexts.map((mentorText, j) => (
                    <DetailsListViewItem
                        detailURL={"/books/" + mentorText._id}
                        imageSrc={constants.filesUrl + ((mentorText.image ? mentorText.image.replace('../','/') : '') || '')}
                        contentHeading={mentorText.name}
                        rowId={j}
                        itemType={"units"}
                        buyBorrowData={mentorText.buy_borrow}
                    >
                        <div>
                            {mentorText.author && <a href={"/books/" + mentorText._id} className="detail-link">
                                by {mentorText.author.substring(mentorText.author.indexOf(",") + 1)} {mentorText.author.substring(0, mentorText.author.indexOf(","))}
                                {/*{this.props.requestType === 'units' ?
                                    <span>
                                            {!mentorText.is_digital &&
                                            <button className="print-only">PRINT-ONLY</button>}
                                            </span> : null}*/}
                            </a>
                            }
                        </div>
                    </DetailsListViewItem>
                ))}
            </div>
        );
    }
}

export default TextContent
