import {REMOVE_FILTER_CHECK,CLEAR_FILTERS} from '../actions'


const initialState={};

const RemoveFilterOptionsReducer=(state=initialState,action)=>{
    switch(action.type){
       
        case REMOVE_FILTER_CHECK:
            return Object.assign({}, state, {checkedStatus:action.payload});
        case CLEAR_FILTERS:
            return Object.assign({}, state, {cleaFilterStatus:action.payload});
        default:
            return state;
    }
}

export default RemoveFilterOptionsReducer;

