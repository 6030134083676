import React,{Component} from  'react'
import Select from 'react-select'
import 'react-select/dist/react-select.css'

class MultipleSelection extends Component{

    constructor(props){
        super(props);

        this.state = {
           list: [
               {label: 'Pre-K', value: "Pre-K"}, 
               {label:'K', value: "K"}, 
               {label: '1', value: "1"}, 
               {label: '2', value: "2"},
               {label:'3', value: "3"}, 
               {label: '4', value: "4"}, 
               {label: '5', value: "5"},
               {label:'6', value: "6"}, 
               {label: '7', value: "7"}, 
               {label: '8', value: "8"}
           ]
        }
    }

    updateState(grades) {
        this.props.gradeUpdate(grades);
    }

    render(){
        return(
            <Select
                name="GRADE SELECTION"
                value={this.props.gradeList}
                options={this.state.list}
                multi={true}
                onChange={this.updateState.bind(this)}
            />
        );
    }
}

export default MultipleSelection;