import React, { Component } from "react";
import {
  Row,
  Col,
} from "react-bootstrap";
import DistrictFormLicensesInfo from "./DistrictFormLicensesInfo";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Datetime from "react-datetime";
import moment from "moment";
import Tabs from "../../Tabs";
class TextBox extends Component {
  render() {
    const { helperTextStyl = {}, valueType } = this.props
    return (
      <div className="textBox-wrapper">
        <h3 style={helperTextStyl} dangerouslySetInnerHTML={{ __html: valueType }} />
        <input
          className={
            this.props.errorValue === ""
              ? "add-user-form"
              : "add-user-form add-user-form-error"
          }
          name={this.props.name}
          type="text"
          value={this.props.value}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
        />
        {this.props.errorValue !== "" && (
          <div className="add-user-error">{this.props.errorValue}</div>
        )}
      </div>
    );
  }
}

class DistrictForm extends Component {
  constructor(props) {
    super(props);
    const {districtData = {}} = props
    this.state = {
      form: {
        districtName: districtData ? districtData.name : "",
        email: "",
        schoolDistrictType: districtData ? districtData.districtType : "",
        country: districtData ? "US" : "",
        region: districtData.state || "",
        address: "",
        zip: districtData.zip_code || "",
        phoneNumber: districtData.phone_number || "",
        contactName: districtData.contact_name || "",
        // licenseCount:"",
        unlimitedCount: districtData.unlimited_count || "",
        printOnlyCount: districtData.print_only_count || "",
        textOnlyCount: districtData.text_only_count || "",
        licenseStart: "",
        licenseEnd: "",
        districtId: districtData._id || "",
        city: districtData.city || "",
        mackinId: districtData.mackin_id || null,
        ...districtData
      },
      districtNameValid: "",
      emailValid: "",
      schoolDistrictTypeValid: "",
      addressValid: "",
      zipValid: "",
      phoneNumberValid: "",
      contactNameValid: "",
      // licenseCountValid:"",
      unlimitedCountValid: "",
      printOnlyCountValid: "",
      textOnlyCountValid: "",
      countryValid: "",
      regionValid: "",
      cityValid: "",
      country: "",
      mackinIdValid: "",
      region: "",
      showStartDatePicker: false,
      showEndDatePicker: false
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (!this.props.districtData) {
      this.state.form.licenseStart = moment().format("L");
      this.state.form.licenseEnd = moment()
        .add(1, "year")
        .format("L");
      this.setState({ form: this.state.form });
    }
  }

  selectCountry(val) {
    this.setState({ country: val, region: null });
    this.state.form["country"] = val;
    this.state.form["region"] = null;
  }

  selectRegion(val) {
    this.setState({ region: val });
    this.state.form["region"] = val;
  }

  setSchoolDistrictType = e => {
    this.state.form["schoolDistrictType"] = e.target.value;
  };

  onChange(event) {
    this.state.form[event.target.name] = event.target.value;
    this.setState({ form: this.state.form });
  }

  handleChangeControl = event => {
    const { value, name } = event.target
    this.setState(state => ({ form: { ...state.form, [name]: value } }) )
    console.log(this.state.form)
  }

  validateInput() {
    let isGood = true;

    if (this.state.form.districtName === "") {
      this.setState({ districtNameValid: "Name cannot be blank." });
      isGood = false;
    } else if (this.setState.districtNameValid !== "") {
      this.setState({ districtNameValid: "" });
    }

    if (!this.state.form.address) {
      this.setState({ addressValid: "Address cannot be blank." });
      isGood = false;
    } else if (this.setState.addressValid !== "") {
      this.setState({ addressValid: "" });
    }

    if (!this.state.form.phoneNumber) {
      this.setState({ phoneNumberValid: "Phone number cannot be blank." });
      isGood = false;
    } else if (this.state.phoneNumberValid !== "") {
      this.setState({ phoneNumberValid: "" });
    }

    if (!this.state.form.contactName) {
      this.setState({ contactNameValid: "Contact name cannot be blank." });
      isGood = false;
    } else if (this.state.contactNameValid !== "") {
      this.setState({ contactNameValid: "" });
    }

    if (!this.state.form.zip) {
      this.setState({ zipValid: "Zip Code cannot be blank." });
      isGood = false;
    } else if (/^\d{5}(-\d{4})?$/.test(this.state.form.zip) === false) {
      this.setState({ zipValid: "Please enter a valid zipcode." });
      isGood = false;
    } else if (this.state.zipValid !== "") {
      this.setState({ zipValid: "" });
    }

    if (!this.state.form.city) {
      this.setState({ cityValid: "City cannot be blank." });
      isGood = false;
    } else if (this.state.cityValid !== "") {
      this.setState({ cityValid: "" });
    }

    if (!this.state.form.schoolDistrictType) {
      this.setState({
        schoolDistrictTypeValid: "School District Type cannot be blank."
      });
      isGood = false;
    } else if (this.state.schoolDistrictTypeValid !== "") {
      this.setState({ schoolDistrictTypeValid: "" });
    }

    if (!this.state.form.country) {
      this.setState({ countryValid: "Country cannot be blank." });
      isGood = false;
    } else if (this.state.countryValid !== "") {
      this.setState({ countryValid: "" });
    }

    if (!this.state.form.region) {
      this.setState({ regionValid: "State cannot be blank." });
      isGood = false;
    } else if (this.state.regionValid !== "") {
      this.setState({ regionValid: "" });
    }

    // if(!this.state.form.licenseCount) {
    //     this.setState({licenseCountValid: "License count cannot be blank."});
    //     isGood = false;
    //     // eslint-disable-next-line
    // } else if(/^(\-|\+)?([0-9]+|Infinity)$/.test(this.state.form.licenseCount) === false) {
    //     this.setState({licenseCountValid: "License count must be an integer."});
    //     isGood = false;
    // } else if (this.state.licenseCountValid !== ""){
    //     this.setState({licenseCountValid: ""});
    // }

    if(!(this.state.form.unlimitedCount + this.state.form.printOnlyCount + this.state.textOnlyCount)){
      if (!this.state.form.unlimitedCount) {
        this.setState({ unlimitedCountValid: "License count cannot be blank." });
        isGood = false;
        // eslint-disable-next-line
      } else if (
        /^(\-|\+)?([0-9]+|Infinity)$/.test(this.state.form.unlimitedCount) ===
        false
      ) {
        this.setState({
          unlimitedCountValid: "License count must be an integer."
        });
        isGood = false;
      } else if (this.state.unlimitedCountValid !== "") {
        this.setState({ unlimitedCountValid: "" });
      }
  
      if (!this.state.form.printOnlyCount) {
        this.setState({ printOnlyCountValid: "License count cannot be blank." });
        isGood = false;
        // eslint-disable-next-line
      } else if (
        /^(\-|\+)?([0-9]+|Infinity)$/.test(this.state.form.printOnlyCount) ===
        false
      ) {
        this.setState({
          printOnlyCountValid: "License count must be an integer."
        });
        isGood = false;
      } else if (this.state.printOnlyCountValid !== "") {
        this.setState({ printOnlyCountValid: "" });
      }else if (!this.state.form.textOnlyCount) {
        this.setState({ textOnlyCountValid: "License count cannot be blank." });
        isGood = false;
        // eslint-disable-next-line
      } else if (
        /^(\-|\+)?([0-9]+|Infinity)$/.test(this.state.form.textOnlyCount) ===
        false
      ) {
        this.setState({
          textOnlyCountValid: "License count must be an integer."
        });
        isGood = false;
      } else if (this.state.textOnlyCountValid !== "") {
        this.setState({ textOnlyCountValid: "" });
      }
    } 

    if (!this.state.form.email) {
      this.setState({ emailValid: "Email cannot be blank." });
      isGood = false;
    } else {
      // eslint-disable-next-line
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(this.state.form.email)) {
        if (this.state.emailValid !== "") {
          this.setState({ emailValid: "" });
        }
      } else {
        this.setState({ emailValid: "Please Enter valid Email" });
        isGood = false;
      }
    }

    return isGood;
  }

  getData() {
    let data = {
      name: this.state.form.districtName,
      email: this.state.form.email,
      address: this.state.form.address,
      zip_code: this.state.form.zip,
      state: this.state.form.region,
      country: this.state.form.country,
      phone_number: this.state.form.phoneNumber,
      contact_name: this.state.form.contactName,
      school_district_type: this.state.form.schoolDistrictType,
      city: this.state.form.city,
      mackin_id: this.state.form.mackinId || null
    };
    if (!this.props.districtData) {

      data = {
        ...data,
        unlimited_count: this.state.form.unlimitedCount,
        print_only_count: this.state.form.printOnlyCount,
        text_only_count: this.state.form.textOnlyCount,
        license_start: this.state.form.licenseStart,
        license_end: this.state.form.licenseEnd
      };
    }
    console.log('data', data)
    return data;
  }

  selectDate(event, selectedPicker) {
    if (selectedPicker === "start") {
      this.state.form.licenseStart = event.format("L");
    } else if (selectedPicker === "end") {
      this.state.form.licenseEnd = event.format("L");
    }
    this.setState({
      form: this.state.form,
      showStartDatePicker: false,
      showEndDatePicker: false
    });
  }

  render() {
    return (
      <div className="form-wrapper-outer-district">
        <div className="form-wrapper-inner">
          {
            <Tabs
              tabs={{
                tabs_title: this.props.districtData ? ["District Info", "Licenses"] : ["District Info"],
                tabs_content: [
                  <form>
                    <Row>
                      <Col xs={4} md={4}>
                        <TextBox
                          name="districtName"
                          errorValue={this.state.districtNameValid}
                          value={this.state.form.districtName}
                          placeholder="DISTRICT NAME"
                          valueType="DISTRICT NAME<span class='required-asterisk'>*</span>"
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col xs={4} md={4} style={{ paddingTop: 20 }}>
                        <p
                          style={{
                            fontFamily: "ProximaNova",
                            fontSize: 12,
                            fontWeight: "bold",
                            lineHeight: "20px"
                          }}
                        >
                          {" "}
                          DISTRICT TYPE<span className="required-asterisk">
                            *
                          </span>{" "}
                        </p>
                        <select
                          ref="districtType"
                          className={
                            this.state.schoolDistrictTypeValid === ""
                              ? "add-user-form"
                              : "add-user-form add-user-form-error"
                          }
                          type="text"
                          defaultValue={this.state.form.schoolDistrictType}
                          onChange={e => this.setSchoolDistrictType(e)}
                        >
                          <option value="0">Select District Type</option>
                          <option value="Public">Public</option>
                          <option value="Private">Private</option>
                          <option value="Charter">Charter</option>
                          <option value="Diocese">Diocese</option>
                          <option value="DODA">DODA</option>
                          <option value="State">State</option>
                          <option value="Home-School">Home-School</option>
                        </select>
                        {this.state.schoolDistrictTypeValid !== "" && (
                          <div className="add-user-error">
                            {this.state.schoolDistrictTypeValid}
                          </div>
                        )}
                      </Col>
                      {/*<Col xs={4} md={4}>*/}
                      {/*<TextBox name='licenseCount' errorValue={this.state.licenseCountValid} value={this.state.form.licenseCount} placeholder="LICENSE COUNT" valueType="LICENSE COUNT<span class='required-asterisk'>*</span>" onChange={this.onChange}/>*/}
                      {/*</Col>*/}
                    </Row>
                    {/* Added Row for unlimited and print only licenses */}
                    {/* Hide license counts and dates for edit district */}
                    {!this.props.districtData && (
                      <div>
                        <Row>
                          <Col xs={4} md={4}>
                            <TextBox
                              name="unlimitedCount"
                              errorValue={this.state.unlimitedCountValid}
                              value={this.state.form.unlimitedCount}
                              placeholder="UNLIMITED LICENSE COUNT"
                              valueType="UNLIMITED LICENSE COUNT<span class='required-asterisk'>*</span>"
                              onChange={this.onChange}
                            />
                          </Col>
                          <Col xs={4} md={4}>
                            <TextBox
                              name="printOnlyCount"
                              errorValue={this.state.printOnlyCountValid}
                              value={this.state.form.printOnlyCount}
                              placeholder="PRINT ONLY LICENSE COUNT"
                              valueType="PRINT ONLY LICENSE COUNT<span class='required-asterisk'>*</span>"
                              onChange={this.onChange}
                            />
                          </Col>
                          <Col xs={4} md={4}>
                            <TextBox
                              name="textOnlyCount"
                              errorValue={this.state.textOnlyCountValid}
                              value={this.state.form.textOnlyCount}
                              placeholder="Text Only LICENSE COUNT"
                              valueType="TEXT ONLY LICENSE COUNT<span class='required-asterisk'>*</span>"
                              onChange={this.onChange}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={4} md={4}>
                            <h3
                              style={{
                                fontFamily: "ProximaNova",
                                fontSize: 12,
                                fontWeight: "bold",
                                lineHeight: "20px"
                              }}
                            >
                              {" "}
                              LICENSES START DATE<span className="required-asterisk">
                                *
                              </span>{" "}
                            </h3>
                            <input
                              ref="license-start"
                              name="licenseStart"
                              className={
                                this.state.schoolDistrictTypeValid === ""
                                  ? "add-user-form"
                                  : "add-user-form add-user-form-error"
                              }
                              type="text"
                              value={this.state.form.licenseStart}
                              onFocus={() =>
                                this.setState({ showStartDatePicker: true })
                              }
                              onChange={this.onChange}
                            />
                            <Datetime
                              value={moment()}
                              open={this.state.showStartDatePicker}
                              onChange={e => this.selectDate(e, "start")}
                            />
                          </Col>
                          <Col xs={4} md={4}>
                            <h3
                              style={{
                                fontFamily: "ProximaNova",
                                fontSize: 12,
                                fontWeight: "bold",
                                lineHeight: "20px"
                              }}
                            >
                              {" "}
                              LICENSES END DATE<span className="required-asterisk">
                                *
                              </span>{" "}
                            </h3>
                            <input
                              ref="license-end"
                              name="licenseEnd"
                              className={
                                this.state.schoolDistrictTypeValid === ""
                                  ? "add-user-form"
                                  : "add-user-form add-user-form-error"
                              }
                              type="text"
                              value={this.state.form.licenseEnd}
                              onFocus={() =>
                                this.setState({ showEndDatePicker: true })
                              }
                              onChange={this.onChange}
                            />
                            <Datetime
                              value={moment().add(1, "year")}
                              open={this.state.showEndDatePicker}
                              onChange={e => this.selectDate(e, "end")}
                              onBlur={() =>
                                this.setState({ showEndDatePicker: false })
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                    <Row>
                      <Col xs={4} md={4}>
                        <TextBox
                          name="phoneNumber"
                          errorValue={this.state.phoneNumberValid}
                          value={this.state.form.phoneNumber}
                          placeholder="PHONE NUMBER"
                          valueType="PHONE NUMBER<span class='required-asterisk'>*</span>"
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col xs={4} md={4}>
                        <TextBox
                          name="email"
                          errorValue={this.state.emailValid}
                          value={this.state.form.email}
                          placeholder="EMAIL"
                          valueType="EMAIL<span class='required-asterisk'>*</span>"
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col xs={4} md={4}>
                        <TextBox
                          name="contactName"
                          errorValue={this.state.contactNameValid}
                          value={this.state.form.contactName}
                          placeholder="CONTACT NAME"
                          valueType="CONTACT NAME<span class='required-asterisk'>*</span>"
                          onChange={this.onChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={4} md={4}>
                        <TextBox
                          name="address"
                          errorValue={this.state.addressValid}
                          value={this.state.form.address}
                          placeholder="ADDRESS"
                          valueType="ADDRESS<span class='required-asterisk'>*</span>"
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col xs={4} md={4}>
                        <TextBox
                          name="city"
                          errorValue={this.state.cityValid}
                          value={this.state.form.city}
                          placeholder="CITY"
                          valueType="CITY<span class='required-asterisk'>*</span>"
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col xs={4} md={4}>
                        <TextBox
                          name="zip"
                          errorValue={this.state.zipValid}
                          value={this.state.form.zip}
                          placeholder="ZIP CODE"
                          valueType="ZIP CODE<span class='required-asterisk'>*</span>"
                          onChange={this.onChange}
                        />
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: 12 }}>
                      <Col xs={4} md={4}>
                        <p
                          style={{
                            fontFamily: "ProximaNova",
                            fontSize: 12,
                            fontWeight: "bold",
                            lineHeight: "20px"
                          }}
                        >
                          {" "}
                          COUNTRY<span className="required-asterisk">
                            *
                          </span>{" "}
                        </p>
                        <CountryDropdown
                          ref="country"
                          value={this.state.form.country}
                          classes={
                            this.state.countryValid === ""
                              ? "add-user-form"
                              : "add-user-form add-user-form-error"
                          }
                          valueType="short"
                          onChange={val => this.selectCountry(val)}
                        />
                        {this.state.countryValid !== "" && (
                          <div className="add-user-error">
                            {this.state.countryValid}
                          </div>
                        )}
                      </Col>
                      <Col xs={4} md={4}>
                        <p
                          style={{
                            fontFamily: "ProximaNova",
                            fontSize: 12,
                            fontWeight: "bold",
                            lineHeight: "20px"
                          }}
                        >
                          {" "}
                          STATE<span className="required-asterisk">*</span>{" "}
                        </p>
                        <RegionDropdown
                          value={this.state.form.region}
                          ref="region"
                          classes={
                            this.state.regionValid === ""
                              ? "add-user-form"
                              : "add-user-form add-user-form-error"
                          }
                          valueType="short"
                          countryValueType="short"
                          country={
                            this.state.form.country
                              ? this.state.form.country
                              : ""
                          }
                          onChange={val => this.selectRegion(val)}
                        />
                        {this.state.regionValid !== "" && (
                          <div className="add-user-error">
                            {this.state.regionValid}
                          </div>
                        )}
                      </Col>
                      <Col xs={4} md={4}>
                        <TextBox
                          name="mackinId"
                          helperTextStyl={{ marginTop: 0 }}
                          errorValue={this.state.mackinIdValid}
                          value={this.state.form.mackinId}
                          placeholder="Mackin Via ID"
                          valueType="Mackin Via ID <span class='required-asterisk'></span>"
                          onChange={this.handleChangeControl}
                        />
                      </Col>
                    </Row>
                  </form>,
                  <DistrictFormLicensesInfo
                    districtId={this.state.form.districtId}
                  />
                ]
              }}
            />
          }
        </div>
      </div>
    );
  }
}

export default DistrictForm;
