import React, {Component} from 'react'
import {Navbar} from 'react-bootstrap'
import '../assets/styles/teachingResources.scss'
import '../assets/styles/header.scss'
import authAPI from '../api/authAPI';
import PopOver from '../components/PopOver'
import MyMenu from './MyMenu'
import FilterMenuContainer from './FilterMenu'
import * as actions from '../redux/actions'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import constants from '../constants'
import {NavDropdown, Nav} from 'react-bootstrap'
import GlobalSearch from '../components/GlobalSearchComponent'
import FolderCreation from '../components/FolderCreationComponent'
import MultiStateIcon from '../components/MultiStateIcon'
import BrowseDropdownContents from "../components/BrowseDropdownContents"
import _ from 'lodash'
import * as queryStringLibrary from 'query-string'

class Header extends Component {

    constructor(props) {
        super(props);
        const showFilter = props.finalFilters && props.finalFilters.filterList && props.finalFilters.filterList.length > 0;
        this.state = {
            clicked: '',
            currentColor: '',
            filterRecieved: false,
            filterList: [],
            showSearch: false,
            showFolder:false,
            showFilter: showFilter,
            showMenu: false,
            searchColor: '#9B9B9B',
            joyrideResetFilters: false
        };
        this.showRight = this.showRight.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.multiStateIconShouldPauseJoyride = this.multiStateIconShouldPauseJoyride.bind(this);
        if(showFilter) {
            setTimeout(() => { this.showRight() }, 200);
        }
    }

    showRight = () => {
        if (this.state.showSearch) {
            this.setState({showFilter: false,showFolder:false});
            this.setState({showMenu: true}, () => this.refs.right.show());
            this.state.searchColor = '#009BBD';
            this.state.currentColor = '#9B9B9B';
        } else if (this.state.showFilter && this.props.buttonClicked !== 'featured' && this.props.params.strand !== 'featured') {
            this.setState({showSearch: false,showFolder:false});
            this.state.searchColor = '#9B9B9B';
            this.setState({showMenu: true}, () => this.refs.right.show());
            if (this.state.currentColor !== '#009BBD') {
                this.setState({currentColor: '#009BBD'});
            } else {
                this.setState({currentColor: '#9B9B9B'});
            }
        } else if(this.state.showFolder){
            this.setState({showFilter: false,showSearch:false});
            this.setState({showMenu: true}, () => this.refs.right.show());
            this.state.searchColor = '#9B9B9B';
            this.state.currentColor = '#9B9B9B';
        }else {
            this.setState({currentColor: '#9B9B9B', searchColor: '#9B9B9B'});
            this.setState({showMenu: false, showFilter: false}, () => this.refs.right.hide());
        }
    };

    closeRight() {
        this.setState({currentColor: '#9B9B9B', showFilter: false});
        this.refs.right.hide();
    }

    componentDidMount() {
      let oldFilterQueryString = '';
      if(this.props.location.action === "POP" && this.props.finalFilters && this.props.finalFilters.filterList){
        if(this.props.finalFilters.filterList>0){
            oldFilterQueryString = constants.getQueryStringFromFilterList(this.props.finalFilters.filterList);
        }else if(window.sessionStorage.getItem('selectedFilters')!=null){
            oldFilterQueryString = constants.getQueryStringFromFilterList(JSON.parse(window.sessionStorage.getItem('selectedFilters')));
        }
        
      }else {
        this.props.addingFiltersAction([]);
        this.setState({showFilter: false});
      }
      if (this.props.typeOfResource === 'books') {
          this.props.getCustomFiltersAction("books", this.props.typeOfResource, oldFilterQueryString);
      } else {
          let strand_to_get = this.props.buttonClicked ? constants.caseMap[this.props.buttonClicked] : constants.caseMap[this.props.params.strand];
          if (strand_to_get !== 0)
              this.props.getCustomFiltersAction(strand_to_get, this.props.typeOfResource, oldFilterQueryString);
      }
    }

    filterLoadShouldStartJoyrideIfState=()=>{
        //If joyride isn't running and should when filters are done loading, do this.
        if(this.state.joyrideResetFilters && this.state.showFilter && !this.props.joyrideShouldRun){
            this.props.allowClicksThruHere(false);
            _.debounce(this.props.nextJoyrideStep, 300, {leading: false, trailing: true})();
            _.debounce(this.props.startJoyride, 500, {leading: false, trailing: true})();
            this.setState({joyrideResetFilters: false});
        }
        return true;
    }

    componentWillReceiveProps(nextProps) {
        let buttonValue = constants.caseMap[nextProps.buttonClicked];
        if (this.props.buttonClicked !== nextProps.buttonClicked && buttonValue !== 0) {
            this.props.getCustomFiltersAction(buttonValue, this.props.typeOfResource);
            this.props.addingFiltersAction([]);
            this.setState({filterList: []});
        }
        if (nextProps.buttonClicked === 'featured' || this.props.params.strand === 'featured') {
            this.setState({currentColor: '#9B9B9B', searchColor: '#9B9B9B'});
            this.closeRight();
        }

        this.setState({
            clicked: nextProps.buttonClicked
        });

        let sidebarShouldNotShow = this.props.joyrideIntendsToStart || nextProps.joyrideIntendsToStart || this.props.joyrideShouldRun || nextProps.joyrideShouldRun;
        let sidebarShouldShowWithFilters = this.props.finalFilters && this.props.finalFilters.filterList && this.props.finalFilters.filterList.length > 0 && this.props.finalFilters.filterList === nextProps.finalFilters.filterList;

        //If joyride is starting at index 0, we should close the sidebar.
        if (nextProps.joyrideIntendsToStart) {
            this.closeRight();
            this.props.joyrideIntentToStart(false);
            _.debounce(() => this.props.resetJoyride(null, 2), 300, {leading: false, trailing: true})();
        }

        // Show the right panel if it is enabled
        if (!sidebarShouldNotShow && sidebarShouldShowWithFilters) {
            this.setState({filterRecieved: true, showFilter: true});
            this.showRight();
        }
    }

    clearFilters() {
        this.setState({
            filterList: []
        });
        this.props.addingFiltersAction([]);
        this.props.clearAllFiltersAction(true);
    }

    filterDisplay = () => {
        let self = this;
        this.setState({showFilter: !self.state.showFilter}, (() => this.showRight()));
        this.setState({showFolder: false});
        this.setState({showSearch: false});
    };

    searchDisplay = () => {
        let self = this;
        this.setState({showSearch: !self.state.showSearch}, (() => this.showRight()));
        this.setState({showFolder: false});
        this.setState({showFilter: false});
    };

    showFolder = () =>{
        let self = this;
        this.setState({showFolder: !self.state.showFolder}, (() => this.showRight()));
        this.setState({showSearch: false});
        this.setState({showFilter: false});  
    }

    multiStateIconShouldPauseJoyride = () => {
        this.props.stopJoyride();
        this.setState({joyrideResetFilters: true});
    };

    renderDropdown = () => {
        if (localStorage.is_lite === 'true') {
            return (
                <div className="containerDropdown">
                    <div className="header-teaching-resource fixed">{this.props.name}</div>
                </div>
            )
        } else {
            return (
                <NavDropdown style={{float: 'left'}} title={
                    <div className="containerDropdown">
                        <div className="header-teaching-resource fixed">{this.props.name}</div>
                        <div className='header-chevron icon-icon-chevron-down fixed-item'/>
                    </div>
                }>
                    <BrowseDropdownContents/>
                </NavDropdown>
            )
        }
    };

    render() {

        // Check whether we are on the featured strand
        const isFeatured = (this.props.params.strand === 'featured' || this.props.buttonClicked === 'featured');

        return (
            <div>
                <div>
                    <Navbar id="header-width" fixedTop fluid defaultExpanded >
                        <Navbar.Header>
                            <Navbar.Brand>
                                <Nav id='browse-nav-custom'>
                                    <div>
                                        {/*<div className='header-browse-icon-height'>
                                            <span id='header-browse' className='icon-icon-browse'/>
                                        </div>*/}
                                        <div className='header-text'>
                                            <span>
                                                {/*localStorage.user_type !== "Student" &&
                                                    <a id="browse" href='/browse'>
                                                        Browse /&nbsp;
                                                    </a>
                                                */}
                                                {localStorage.user_type === "Student" &&
                                                <span id="browse">
                                                        Browse
                                                    </span>
                                                }
                                            </span>
                                        </div>
                                        {/* This is where the dropdown comes from */}
{/* This runs the function above and determines what kind of header to put in the top left corner */}
                                        {localStorage.user_type !== "Student" && this.renderDropdown()}
                                    </div>
                                </Nav>
                            </Navbar.Brand>
                        </Navbar.Header>
                        <Navbar.Collapse>
                            <div className="two-header-btns" style={{position: 'absolute', top: 5, right: 5}}>
                                <MultiStateIcon iconId='filterIcon'
                                                facilitateHeaderJoyride={this.multiStateIconShouldPauseJoyride}
                                                joyrideShouldRun={this.props.joyrideShouldRun}
                                                stopJoyride={this.props.stopJoyride}
                                                startJoyride={this.props.startJoyride}
                                                nextJoyrideStep={this.props.nextJoyrideStep}
                                                enabled={!isFeatured}
                                                active={this.state.showFilter}
                                                onClick={isFeatured ? '' : this.filterDisplay}
                                                onMouseOver={() => this.setState({showPopOver: isFeatured})}
                                                onMouseLeave={() => this.setState({showPopOver: false})}
                                                sharedClasses="header-icon-test"
                                                activeClasses="icon-blue icon-icon-filter-circle-solid"
                                                inactiveClasses="icon-blue icon-icon-filter-circle-outline"
                                                disabledClasses="icon-icon-filter-circle-outline"
                                                tooltipText="Filters"
                                />
                                <MultiStateIcon enabled={true}
                                                active={this.state.showSearch}
                                                onClick={this.searchDisplay}
                                                sharedClasses="header-icon-test"
                                                activeClasses="icon-blue icon-icon-search-circle-solid"
                                                inactiveClasses="icon-blue icon-icon-search-circle-outline"
                                                disabledClasses="icon-icon-search-circle-outline"
                                                tooltipText="Search"
                                />
                                {localStorage.user_type !== 'Student' &&
                                    <MultiStateIcon iconId='folderIcon'
                                        enabled={true}
                                        active={this.state.showFolder}
                                        onClick={this.showFolder}
                                        activeClasses="header-right-icon active"
                                        inactiveClasses="header-right-icon inactive"
                                        disabledClasses="header-right-icon disabled"
                                        imageIcon="../../folder.png"
                                        tooltipText="My Folders"
                                    />
                                }
                                
                            </div>
                            <PopOver isTooltipActive={this.state.showPopOver}
                                     count="filterIcon" ref={c => {this.popover = c;}}
                                     height="100px"
                                     width="200px"
                            >
                                <div>
                                    <div style={{
                                        paddingTop: "20px",
                                        color: "#999999",
                                        fontFamily: "ProximaNova",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        letterSpacing: "1px",
                                        lineHeight: "14px"
                                    }}>
                                        {this.props.typeOfResource === 'books' ? "Please select a tab other than Featured to enable filtering" : "You must select a standard strand to enable filter options."}
                                    </div>
                                </div>
                            </PopOver>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
                {
                    this.props.lesssonAddedSuccess && this.props.lesssonAddedSuccess.data &&this.props.lesssonAddedSuccess.data.success &&
                        <div className='bottom-margin-browse'>
                            <div className="unit-success-popover">
                                <div className="popover-success-icon icon-icon-checkbox"/>
                                <div className="popover-success-text">
                                    <strong>{this.props.lesssonAddedSuccess.data.lessonName}</strong> was successfully added to your unit(s)!
                                </div>
                            </div>
                        </div>
                }
                <MyMenu ref="right"
                        alignment="right"
                        menuShowHide={this.props.notifyMenuStatus}
                        showMenu={this.state.showMenu}
                        showScroll={!this.state.showSearch}
                >
                    {this.state.showSearch && <GlobalSearch focus={this.state.showSearch && window.innerWidth > 767} itemType={this.props.name}/>}
                    {this.state.showFilter && this.filterLoadShouldStartJoyrideIfState() &&
                        <FilterMenuContainer
                            shown={!this.state.showSearch}
                            typeOfResource={this.props.typeOfResource === 'texts' ? 'books' : this.props.typeOfResource}
                            strand={this.props.buttonClicked}
                        />}
                    {this.state.showFolder && <FolderCreation
                        focus={this.state.showFolder && window.innerWidth > 767}
                        closeStatus={this.state.showFolder && !this.state.showFolder}
                    />}
                </MyMenu>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        finalFilters:state.FilterScale,
        lesssonAddedSuccess:state.LessonAddedSuccess,
        joyrideShouldRun: state.JoyrideReducer.joyrideShouldRun,
        joyrideStepIndex: state.JoyrideReducer.joyrideStepIndex,
        joyrideIntendsToStart: state.JoyrideReducer.joyrideIntendsToStart
    });
};

export default connect(mapStateToProps, actions)(withRouter(Header))
