import React,{Component} from 'react'
import {Col,Row} from 'react-bootstrap'

class SysAdminItem extends Component {

    getIconStyle=()=> {
        if(this.props.n.is_active === true) {
            if (this.props.n.user_type === "System Admin") {
                return {
                    backgroundColor: "#9012FE"
                }
            }
            else if (this.props.n.user_type === "Content Editor") {
                return {
                    backgroundColor: "#3498DB"
                }
            }
            else {
                return {};
            }
        }
        else {
            return {
                backgroundColor: "#CCCCCC",
            };
        }
    };

    getTextStyle=()=> {
        if(this.props.n.is_active === true) {
            return {};
        }
        else {
            return {
                color: "#CCCCCC",
            };
        }
    };

    render(){
        //Future
        //<img src={require('../../assets/images/book-1.jpg')} className="list-item-image" />

        return(
            <div>
                <span className="list-item-user-icon" style={this.getIconStyle()}>
                    <i className="icon-icon-user list-item-user-img"/>
                </span>
                <Col md={8} sm={8} >
                    <Row style={this.getTextStyle()}>
                        {this.props.n.first_name &&
                            <span>
                                {this.props.n.first_name}&nbsp;
                            </span>
                        }
                        {this.props.n.last_name &&
                            <span>
                                {this.props.n.last_name}
                            </span>
                        }
                    </Row>
                    <Row className="item-sub-text" style={this.getTextStyle()}>
                        {/*this.props.n.is_active &&
                            <span className="non-breaking-span">
                                USER ACTIVE: {this.props.n.is_active.toString()}
                            {(this.props.n.user_id_number || this.props.n.username) &&
                                <span>
                                    &nbsp;&#8226;&nbsp;
                                </span>
                            }
                            </span>
                        */}
                        {this.props.n.username &&
                            <span className="non-breaking-span">
                                EMAIL: {this.props.n.username}
                                {this.props.n.user_type &&
                                    <span>&nbsp;&#8226;&nbsp;</span>
                                }
                            </span>
                        }
                        {this.props.n.user_type &&
                            <span className="non-breaking-span">
                                ROLE: {this.props.n.user_type}
                            </span>
                        }
                    </Row>
                </Col>
            </div>
        );
    }
}

export default SysAdminItem;
