import React,{Component} from  'react'
import {Button,ButtonGroup } from 'react-bootstrap'
import {withRouter} from 'react-router'

class MainButtonGroup extends Component{

    render(){
        //console.log(this.props.selectedTab);

        return(
            <div className="align-button-grp" >
                {/*<ButtonGroup id="nav-buttons" style={{paddingRight:'30px'}}>*/}
                <ButtonGroup id="nav-buttons" >
                    
                    <Button className="rectangle-2"
                            style={{backgroundColor:this.props.selectedTab==="OVERVIEW"? "#009BBD":'white'}}
                            onClick={() => {this.props.selectTab("OVERVIEW"); }}
                    >
                            <span className="language" style={{ color : this.props.selectedTab === "OVERVIEW" ? '#FFFFFF': '#9B9B9B'}}>
                                OVERVIEW
                            </span>
                    </Button>

                    <Button className="rectangle-2"
                            style={{backgroundColor:this.props.selectedTab==="TEXTS"? "#009BBD":'white'}}
                            onClick={() => {this.props.selectTab("TEXTS");}} 
                    >
                            <span className="language" style={{ color : this.props.selectedTab === "TEXTS" ? '#FFFFFF': '#9B9B9B'}}>
                                TEXTS
                            </span>
                    </Button>

                    <Button className="rectangle-2"
                            style={{backgroundColor:this.props.selectedTab==="VOCABULARY"? "#009BBD":'white'}}
                            onClick={() => {this.props.selectTab("VOCABULARY"); }} 
                    >
                            <span className="language" style={{ color : this.props.selectedTab === "VOCABULARY" ? '#FFFFFF': '#9B9B9B'}}>
                                VOCABULARY
                            </span>
                    </Button>

                    {this.props.location.pathname!=='/new-unit' && 
                        <Button className="rectangle-2"
                                style={{backgroundColor:this.props.selectedTab==="ASSESSMENTS"? "#009BBD":'white'}}
                                onClick={() => {this.props.selectTab("ASSESSMENTS"); }} 
                        >
                                <span className="language" style={{ color : this.props.selectedTab === "ASSESSMENTS" ? '#FFFFFF': '#9B9B9B'}}>
                                    ASSESSMENTS
                                </span>
                        </Button>
                    }
                </ButtonGroup>
                 {/*<button className='format-customize-button'>ADD LESSON</button>*/}
            </div>
        );
    }
}

export default withRouter(MainButtonGroup)