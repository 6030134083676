import React, {Component} from 'react'
import StudentTeacherListItem from './StudentTeacherListItem'

const STUDENT_TEACHER_LIST_HEADER_TEXT = "My Teachers";
export default class StudentTeacherList extends Component {

    // Props:
    // teachers - list of objects with ._id, last_name
    // onSelectedTeachersChanged - function which accepts a list of selected teacher ids
    constructor(props) {
        super(props);

        // Copy teacher list
        const teacherList = props.teachers.splice(0);

        // Set checked to true for each
        for(let i = 0;i<teacherList.length;i++) {
            teacherList[i].isChecked = true;
        }

        this.state = {
            // Whether show all is selected
            isShowAllChecked: true,

            // Copied prop list with additional value "isChecked" used for rendering
            teachers: teacherList
        };

        this.onTeacherChanged = this.onTeacherChanged.bind(this);
        this.onShowAllChanged = this.onShowAllChanged.bind(this);
        this.onSelectedTeachersChanged = this.onSelectedTeachersChanged.bind(this);
    };

    getTeacherList() {
        return this.state.teachers;
    }

    // Called after the list of selected teachers is changed
    onSelectedTeachersChanged() {
        const teacherList = this.getTeacherList();
        let selectedTeacherIds = [];
        for(let i = 0;i<teacherList.length;i++) {
            if(teacherList[i].isChecked) {
                selectedTeacherIds.push(teacherList[i]._id);
            }
        }
        this.props.onSelectedTeachersChanged(selectedTeacherIds);
    }

    isShowAllChecked() {
        return this.state.isShowAllChecked;
    }

    onShowAllChanged(newShowAllValue) {
        // If show all is being set to true, then select all teachers.
        if(newShowAllValue === true) {
            // Copy teacher list
            const teacherList = this.getTeacherList().splice(0);

            // Set checked to true for each
            for(let i = 0;i<teacherList.length;i++) {
                teacherList[i].isChecked = true;
            }
            this.setState({
                isShowAllChecked: newShowAllValue,
                teachers: teacherList
            }, () => {
                this.onSelectedTeachersChanged();
            });
        } else {
            this.setState({
                isShowAllChecked: newShowAllValue
            });
        }
    }

    /**
     * @param teacher_index - the index in the teacher array that was changed
     * @param newValue - the new value
     */
    onTeacherChanged(teacher_index, newValue) {
        let newTeachers = this.getTeacherList().splice(0);
        newTeachers[teacher_index].isChecked = newValue;
        this.setState({
            // isShowAllChecked retains it's value if we are setting a teacher to true,
            // if we are setting a teacher to false then isShowAllChecked is false.
            isShowAllChecked: newValue? this.isShowAllChecked(): false,
            teachers: newTeachers
        }, () => {
            this.onSelectedTeachersChanged();
        });
    }

    render() {
        return (
            <div className="teachers-list">
                <div className="my-teachers">
                    {STUDENT_TEACHER_LIST_HEADER_TEXT}
                </div>
                <div>
                    <StudentTeacherListItem
                        checked={this.isShowAllChecked()}
                        onClick={()=> {this.onShowAllChanged(!this.isShowAllChecked())}}
                        text="Show All"
                    />
                    {this.getTeacherList().map((teacher, i)=>
                        <StudentTeacherListItem
                            key={'teacher_' + i}
                            checked={teacher.isChecked}
                            onClick={()=> this.onTeacherChanged(i, !teacher.isChecked)}
                            text={teacher.last_name}
                        />
                    )}
                </div>
            </div>
        );
    }
}
