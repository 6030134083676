import React from 'react'
import PopOver from '../../components/PopOver'
import AddPopOverMobile from '../../containers/shared/AddPopOverMobile'

let timer;

class RemoveItemPopOver extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showPopOver: false
        };
    }

    removeItem=(obj)=>{
        this.props.remove(obj);
        this.setState({showPopOver:false, showPopOverMobile: false});
    };

    showTooltip=(e)=>{
        e.preventDefault();
        e.stopPropagation();
        this.setState({showPopOver: true, showPopOverMobile: true});
    };

    hideMobileTooltip=()=>{
      this.setState({showPopOverMobile: false});
    };

    timeOutFunction=()=>{
      timer=setTimeout(() => this.setState({showPopOver : false}),3000)
    };

    clearTimeoutFunction=()=>{
        clearTimeout(timer)
    };

    componentWillReceiveProps(next) {
        if(next.popOverId){
            this.setState({showPopOver:false});
        }
    }

    // Whether the mobile popover should be used
    shouldUseMobilePopover=()=>{

        let width = window.innerWidth;
        let isThinDevice = (width <= 576);

        // Always use on iPad
        let isIPad = navigator.userAgent.match(/iPad/i) !== null;

        // Use on safari for desktop and mobile, as desktop version of safari has issues rendering
        // the desktop popover.

        // Note: Chrome on Mac contains Safari user agent so it will use the mobile as well.
        let isSafari = navigator.userAgent.match(/Safari/) !== null;

        let isChrome = navigator.userAgent.match(/Chrome/) !== null;

        let useMobile = isIPad || isThinDevice||
            // Chrome lists Safari as a user agent, but Safari does not list
            // Chrome.
            (isSafari && !isChrome);

        return useMobile;
    };

    render() {

      let useMobile = this.shouldUseMobilePopover();
      let confirmDiv =
          <div onClick={()=>this.removeItem(this.props.removeObj)}
               style={{
                   cursor: "pointer",
                   color: "#d01e1f",
                   fontFamily: "ProximaNova",
                   fontSize: "15px"
               }}>
                Confirm Removal
          </div>;
      return (
                <div>
                    <div onClick={this.showTooltip} id={this.props.popOverId} className={this.props.className+" icon-icon-trash"}/>
                    {this.state.showPopOver && !useMobile &&
                        <PopOver isTooltipActive={this.state.showPopOver} arrow="center" width='140px' height='auto' count={this.props.popOverId}>
                            <div onMouseLeave={this.timeOutFunction} onMouseEnter={this.clearTimeoutFunction}>
                                {confirmDiv}
                            </div>
                        </PopOver>
                    }
                    {this.state.showPopOverMobile && useMobile &&
                        <AddPopOverMobile
                            isTooltipActive={this.state.showPopOverMobile}
                            arrow="center"
                            myId={this.props.popOverId}
                            position='bottom'
                            tooltipStyle={{ paddingLeft: '10px', width: 180}}
                            hideTooltip={this.hideMobileTooltip}
                        >
                            {confirmDiv}
                        </AddPopOverMobile>
                    }
                </div>
          );
    }
}

export default RemoveItemPopOver;
