import React,{Component} from  'react'
import * as actions from '../../redux/actions';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import DetailedHeader from '../../containers/Details/DetailedHeader';
import DetailedDescription from '../../containers/Details/DetailedDescription'
import BooksOverview from './BooksOverview';
import AssistantTooltipComponent from '../JoyrideCustomComponent/AssistantTooltipComponent'
import _ from 'lodash'

let libraryTourSteps = [
  {
    title: "Adding to Your Library",
    text: <AssistantTooltipComponent
              imgWidth={100}
              imgHeight={130}
              tooltipText={`
                <p>
                  Once you have located a teaching resource, eBook, assessment, or unit of study that you like, you can add it to your personal digital library by clicking the <strong>Add</strong> icon in the upper right corner.
                  For more information about browsing and searching, check out the Browsing Tour!
                </p>
                <p>
                  Building your very own personal digital library has many benefits, including:
                </p>
                <ul>
                  <li>Providing convenient access to self-selected and targeted resources</li>
                  <li>Creating a customized eBook classroom library that your students may access both in school and at home</li>
                  <li>The ability to easily assign resources or eBooks to students</li>
                  <li>The ability to conveniently schedule a resource using the Planner</li>
                </ul>
                <p>
                  That's it for this tour!
                  Tours can be accessed at any time by clicking the <strong>Help</strong> icon on the lower right-hand corner of the screen!
                </p>
                `} />,
    selector: '#i-show-add-icon',
    position: 'bottom-right',
    style: {
      mainColor: '#CACACA',
      width: 600,
      height: '100px',
      overflow: 'wrap',
      borderRadius: 4,
      backgroundColor: 'white',
      beacon: {
        inner: '#a350f0',
        outer: '#a350f0',
      },
      close: {
        display: 'none'
      },
      button: {
        display: 'none'
      },
      skip: {
        marginTop: 20,
        borderRadius: 8,
        backgroundColor: '#009BBD',
        color: 'white'
      }
    }
  }
]


class BooksDetailedView extends Component{

    constructor (props) {
        super(props);
        this.state = {
            selectedTab: "SUMMARY",
            infoClicked: window.innerWidth < 768 ? false : true,
            searchClicked: false,
            load: "initial",
            status:"",
            data: {
                image: "",
                name: "",
                description: "",
                author: "",
                publisher:"",
                printable_file: "",
                printable: false,
                is_digital:false,
                pages: "",
                grades: "",
                levels: "",
                genres: [],
                topics: [],
                themes: [],
                royalty:0,
                google_form_link:"",
                showClassroomShareButton:false,
                showClassroomShareButtonLink:""
            },
            preview:false
        };
        this.selectTab = this.selectTab.bind(this);
        this.onInfoClick = this.onInfoClick.bind(this);

        this.continueTour = _.debounce(()=>this.props.resetJoyride(libraryTourSteps, 0), 1000, {leading: true, trailing: false});
        this.loadSteps = _.once(()=>this.props.joyrideLoadSteps(libraryTourSteps));
    }

    componentWillMount() {
        this.props.getBookDetailsAction(this.props.params.uid);
        this.props.getBookPreviewStatusAction(this.props.params.uid).then((previewState)=>{
            this.setState({
                preview : previewState
            })
        });
        this.loadSteps();
    }

    componentWillReceiveProps(next) {
        if (next.bookDetails) {
            if (next.bookDetails.asyncStatus === "PENDING") {
                this.setState({status: "PENDING"});
            }
            else if (next.bookDetails.asyncStatus === "FAILURE") {
                this.setState({status: "FAILURE"});
            }
            else if (next.bookDetails.asyncStatus === "SUCCESS" && next.bookDetails.data.status === "FAILURE FOR PRINT ONLY USER") {
                this.setState({status: "FAILURE FOR PRINT ONLY USER"});
            }
            else if (next.bookDetails.asyncStatus === "SUCCESS" && next.bookDetails.data.status === "FAILURE FOR NOT APPROVED") {
                this.setState({status: "FAILURE FOR NOT APPROVED"});
            }
            else if (next.bookDetails.asyncStatus === "SUCCESS") { 
                let data = {
                    name: next.bookDetails?.data?.name || "",
                    author:  next.bookDetails?.data?.author || "",
                    publisher: next.bookDetails?.data?.publisher || "",
                    pages:  next.bookDetails?.data?.page_count || "",
                    genres: next.bookDetails?.data?.genres || [],
                    topics: next.bookDetails?.data?.topics || [],
                    themes: next.bookDetails?.data?.themes || [],
                    printable_file: next.bookDetails?.data?.file || "",
                    printable: next.bookDetails?.data?.printable || false,
                    description: next.bookDetails?.data?.description.text || "",
                    is_digital: next.bookDetails?.data?.is_digital || false,
                    buy_borrow: next.bookDetails?.data?.buy_borrow || [],
                    royalty: next.bookDetails?.data?.royalty || 0,
                    google_form_link: next.bookDetails?.data?.google_form_link ||"",
                    showClassroomShareButton: next.bookDetails?.data?.showClassroomShareButton || false,
                    showClassroomShareButtonLink: next.bookDetails?.data?.showClassroomShareButtonLink || "",   
                } 
                
                if (next.bookDetails?.data?.image) {
                    data = {
                        ...data,
                        image: next.bookDetails.data.image
                    }
                }

                let tempGrades = "";
                for(let i = 0; i<next.bookDetails.data.grades.length; i++) {
                    if (i === 0) {
                        tempGrades += next.bookDetails.data.grades[i];
                    }
                    else {
                        tempGrades += ", " + next.bookDetails.data.grades[i];
                    }
                }
                data = {
                    ...data,
                    grades: tempGrades,
                }

                let tempLevels = "";
                for(let i = 0; i<next.bookDetails.data.levels.length; i++) {
                    if (i === 0) {
                        tempLevels += next.bookDetails.data.levels[i];
                    }
                    else {
                        tempLevels += ", " + next.bookDetails.data.levels[i];
                    }
                }
                data = {
                    ...data,
                    levels: tempLevels,
                }


                this.setState({
                    status: "SUCCESS",
                    data: data,
                    publish: next.bookDetails?.data?.publish || ""
                });

                if(this.props?.location?.state?.beginTour){
                  this.continueTour();
                }
            }
        }
        if(next.params.uid !== this.props.params.uid){
            this.props.getBookDetailsAction(next.params.uid);
        }
    }

    onInfoClick(infoClicked = !this.state.infoClicked){
        if(infoClicked) {
            this.onSearchClick(false);
        }
        return new Promise((resolve, reject) => {
            this.setState({infoClicked: infoClicked, load: ""}, () => {
                resolve();
            });
        });

    }

    onSearchClick=(searchClicked = !this.state.searchClicked)=>{
        if(searchClicked) {
            this.onInfoClick(false);
        }
        return new Promise((resolve, reject) => {
            this.setState({searchClicked: searchClicked, load: ""}, () => {
                resolve();
            });
        });
    };

    selectTab (tab) {

        this.setState({selectedTab: tab});
    }

    render(){
        let bookPriceText = this.state.publish && (this.state.data.pages * this.state.data.royalty)>0 ? '(Fee: $'+parseFloat(this.state.data.pages * this.state.data.royalty).toFixed(2)+')' : '(Free)';
        return(
            <div className="detail-wrapper">
                <DetailedHeader printableFile={this.state.data.printable_file} publish={this.state.publish} name={this.state.data.name} onSearchClick={this.onSearchClick} infoClick={this.onInfoClick} itemType="Book" itemId={this.props.params.uid} infoClicked={this.state.infoClicked} searchClicked={this.state.searchClicked} bookId={this.props.params.uid} bookPriceText={bookPriceText} preview={this.state.preview}/>
                <div className={((this.state.infoClicked || this.state.searchClicked)?"some-area fill-height-or-more":"fill-height-or-more some-area-without-info")}>
                    <div className="detail-holder">
                        {this.state.status === "PENDING" &&
                            <div style={{paddingTop: "10px"}}>Loading....</div>
                        }
                        {this.state.status  === "FAILURE" &&
                            <div style={{paddingTop: "10px", textAlign: "center"}}>Sorry, this book is no longer available</div>
                        }
                        {this.state.status  === "FAILURE FOR PRINT ONLY USER" &&
                            <div style={{paddingTop: "10px", textAlign: "center"}}>
                                This resource is not approved for viewing on Fundamentals Unlimited. Please contact us at 
                                    <a href="mailto:help@schoolwide.com" target="_blank" rel="noreferrer">help@schoolwide.com</a> 
                                for more information.
                            </div>
                        }
                        {this.state.status  === "FAILURE FOR NOT APPROVED" &&
                            <div style={{paddingTop: "10px", textAlign: "center"}}>
                                This resource is not approved for viewing on Fundamentals Unlimited. Please contact us at 
                                    <a href="mailto:help@schoolwide.com" target="_blank" rel="noreferrer">help@schoolwide.com</a> 
                                for more information.
                            </div>
                        }
                        {this.state.status === "SUCCESS" &&
                            <BooksOverview
                                grades={this.state.data.grades}
                                levels={this.state.data.levels}
                                genres={this.state.data.genres}
                                topics={this.state.data.topics}
                                themes={this.state.data.themes}
                                pages={this.state.data.pages}
                                description={{text: this.state.data.description}}
                                by={this.state.data.author}
                                publisher={this.state.data.publisher}
                                is_digital={this.state.data.is_digital}
                            />
                        }
                    </div>
                    <div>
                        <DetailedDescription
                            image={this.state.data.image}
                            name={this.state.data.name}
                            printableFile={this.state.data.printable_file}
                            printable={this.state.data.printable}
                            is_digital={this.state.data.is_digital ?? this.state.is_digital}
                            bookId={this.props.params.uid}
                            googleFormLink={this.state.data.google_form_link}
                            unitDetailType={this.props.location.state !== undefined && this.props.location.state.itemType ? this.props.location.state.itemType : 'books'}
                            unitTextBuyBorrowData = {this.state.data.buy_borrow != null && (this.state.data.buy_borrow).length > 0 ? this.state.data.buy_borrow : ""}
                            detailType='texts'
                        />
                    </div>
                </div>
            </div>
        );
    };

}

const mapStateToProps = (state) => {
    return ({
        bookDetails : state.BookDetails
    });
};

export default connect(mapStateToProps, actions)(withRouter(BooksDetailedView))
