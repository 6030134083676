import React, {Component} from 'react'
import {browserHistory} from 'react-router'

export default class NotificationFooterItem extends Component{
    constructor(){
        super();
        this.state = {
            amIactive : false
        }
    }

    componentWillReceiveProps(next){
        //console.log(next.activeNotificationFooter)
        //console.log(next.item.pageName)
        if(next.activeNotificationFooter === next.item.pageName){
            this.setState({
                amIactive : true
            });
        } else {
            this.setState({
                amIactive : false
            });
        }
    }

    handleClick(){
      if(this.props.pageName){
        this.props.selectNotificationFooter(this.props.item.pageName);
        let target = "/" + this.props.pageName;
        browserHistory.push(target);
      } else {
        this.props.showTourModal();
      }
    }

    generateClassName = () => {
      let className = "footer-btn-container ";
      if(this.props.item.pageName === "notification")
        className += " badge1";
      if(this.props.item.icon === "help-circle-outline")
        className += " hidden-xs";
      else
        className += " make-profile-icon-look-like-others";
      return className;
    }

    render(){
        return(
            <div onClick={() => this.handleClick()}
                className={this.generateClassName()}
                data-badge={this.props.item.pageName === "notification" ? this.props.item.count : null }
                style={{
                  backgroundColor : this.state.amIactive ? 'rgba(255,255,255,0.2)' : '#2C1A3C',
                  borderTop: this.state.amIactive ? '5px solid #529ed0' : '5px solid #2C1A3C',
                  borderLeft: this.state.amIactive ? '1px solid black' : 0,
                  borderRight: this.state.amIactive ? '1px solid black' : 0
                }}
                >
                <div className="footer-schoolwide-image">
                    <div className={"footer-icon icon-icon-"+this.props.item.icon}
                         style={{color:this.state.amIactive?'#009BBD':'#FFFFFF'}}
                         />
                    <div className="footer-schoolwide-text">
                        {this.props.item.text}
                    </div>
                  </div>
            </div>
        );
    }
}
