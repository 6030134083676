import React,{Component} from 'react'
import {Col,Row} from 'react-bootstrap'
import moment from 'moment'

class SchoolAdminItem extends Component {

    getIconStyle=()=> {
        if(this.props.n.is_active === true) {
            if (this.props.n.user_type === "School Admin") {
                return {
                    backgroundColor: "#9012FE"
                }
            }
// Changed Teacher to Unlimited
            else if (this.props.n.user_type === "Unlimited") {
                return {
                    backgroundColor: "#3498DB"
                }
            }
            else {
                return {};
            }
        }
        else {
            return {
                backgroundColor: "#CCCCCC",
            };
        }
    };

    getTextStyle=()=> {
        if(this.props.n.is_active === true) {
            return {};
        }
        else {
            return {
                color: "#CCCCCC",
            };
        }
    };

    render(){
        //Future
        //<img src={require('../../assets/images/book-1.jpg')} className="list-item-image" />
        return(
            <div>
                <span className="list-item-user-icon" style={this.getIconStyle()}>
                    <i className="icon-icon-user list-item-user-img" />
                </span>
                <Col md={8} sm={8} >
                    <Row style={this.getTextStyle()}>
                        {this.props.n.first_name &&
                            <span>
                                {this.props.n.first_name}&nbsp;
                            </span>
                        }
                        {this.props.n.last_name &&
                            <span>
                                {this.props.n.last_name}
                            </span>
                        }
                        </Row>
                    <Row className="item-sub-text" style={this.getTextStyle()}>
                        {/*this.props.n.is_active !== undefined && this.props.n.is_active !== null &&
                            <span className="non-breaking-span">
                                USER ACTIVE: {this.props.n.is_active.toString()}
                                {(this.props.n.username || this.props.n.user_type) &&
                                    <span>&nbsp;&#8226;&nbsp;</span>
                                }
                            </span>
                        */}
                        {this.props.n.username &&
                            <span className="non-breaking-span">
                                EMAIL: {this.props.n.username}
                                {this.props.n.user_type &&
                                    <span>&nbsp;&#8226;&nbsp;</span>
                                }
                            </span>
                        }
                        {this.props.n.user_type &&
                            <span className="non-breaking-span">
{/* changed ROLE to USER TYPE */}
                                USER TYPE: {this.props.n.user_type}
                            </span>
                        }
                    </Row>
                    {this.props.n.expiration_date &&
                        <Row className="item-sub-text" style={this.getTextStyle()}>
                            <span className="non-breaking-span">
                                LICENSE EXPIRES: {this.props.n.user_type === 'Print Only' ?
                                    'No Expiration' :
                                    moment(this.props.n.expiration_date).format('L')}
                            </span>
                        </Row>
                    }
                </Col>
            </div>
        );
    }
}

export default SchoolAdminItem;