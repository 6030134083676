import React,{Component} from 'react'
import {BrowsePage} from "../shared/BrowsePage"
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'

export class Assessments extends Component {

    render(){
        return(
            <BrowsePage
                name="Assessments"
                typeOfResource="assessments" strandValue={this.props.params.strand}
            >
                {this.props.children}
            </BrowsePage>
         );
    }
}

export default connect(null, actions)(Assessments)