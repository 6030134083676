import {
  GET_MY_LIBRARY_REQUEST,
  GET_MY_LIBRARY_SUCCESS,
  GET_MY_LIBRARY_FAILURE,
  DECREMENT_CUSTOM_FILTERS_STRAND_TOTAL,
  QUIETLY_INITIALIZE_STRANDLESS_COUNT,
  GET_FOLDER_NOTIFICATION_REQUEST,
  GET_FOLDER_NOTIFICATION_FAILURE,
  GET_FOLDER_NOTIFICATION_SUCCESS,
  POST_FOLDER_NOTIFICATION_REQUEST,
  POST_FOLDER_NOTIFICATION_FAILURE,
  POST_FOLDER_NOTIFICATION_SUCCESS,
} from "../actions";

const initialState = {
  data: {
    recents: {
      results: [],
    },
    teachingResources: {
      results: [],
    },
    books: {
      results: [],
    },
    assessments: {
      results: [],
    },
    units: {
      results: [],
    },
    folders: {
      results: [],
    },
    notifications: {
      results: [],
    },
  },
};

const MyLibraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_LIBRARY_REQUEST:
    case GET_FOLDER_NOTIFICATION_REQUEST:
    case POST_FOLDER_NOTIFICATION_REQUEST:
      return Object.assign({}, state, { asyncStatus: "PENDING" });
    case GET_MY_LIBRARY_SUCCESS:
      // If data already exists append it
      if (state.data[action.request]) {
        // Combine data to request

        if (
          action?.payload?.currentPage === 1 ||
          action?.payload?.currentPage === "1" ||
          !action?.payload?.currentPage
        ) {
          let newPayload = Object.assign({}, action.payload, {
            results: action.payload.results,
          });
          return Object.assign({}, state, {
            isSearchActive: action.isSearchActive,
            asyncStatus: "SUCCESS",
            strand_total:
              action.strand_total !== null && action.strand_total > -1
                ? action.strand_total
                : state.strand_total,
            strandless_total:
              action.strandless_total !== null && action.strandless_total > -1
                ? action.strandless_total
                : state.strandless_total,
            data: { ...state.data, [action.request]: newPayload },
          });
        } else {
          const oldData = state.data[action.request].results;
          let newPayload = Object.assign({}, action.payload, {
            results: oldData.concat(action.payload.results),
          });
          return Object.assign({}, state, {
            isSearchActive: action.isSearchActive,
            asyncStatus: "SUCCESS",
            strand_total:
              action.strand_total !== null && action.strand_total > -1
                ? action.strand_total
                : state.strand_total,
            strandless_total:
              action.strandless_total !== null && action.strandless_total > -1
                ? action.strandless_total
                : state.strandless_total,
            data: { ...state.data, [action.request]: newPayload },
          });
        }
      } else {
        // Otherwise just add the ky
        return Object.assign({}, state, {
          isSearchActive: action.isSearchActive,
          asyncStatus: "SUCCESS",
          strand_total:
            action.strand_total !== null && action.strand_total > -1
              ? action.strand_total
              : state.strand_total,
          strandless_total:
            action.strandless_total !== null && action.strandless_total > -1
              ? action.strandless_total
              : state.strandless_total,
          data: { ...state.data, [action.request]: action.payload },
        });
      }
    case GET_FOLDER_NOTIFICATION_SUCCESS:
      return {
        data: {
          ...state.data,
          notifications: {
            results: action.payload,
          },
        },
      };
    case POST_FOLDER_NOTIFICATION_SUCCESS:
      state.data.notifications.results.filter(
        (item) => item._id === action.payload[0]
      );
      return {
        data: {
          ...state.data,
          notifications: {
            results: state.data.notifications.results.filter(
              (item) => item._id === action.payload[0]
            ),
          },
        },
      };
    case GET_MY_LIBRARY_FAILURE:
    case GET_FOLDER_NOTIFICATION_FAILURE:
    case POST_FOLDER_NOTIFICATION_FAILURE:
      return Object.assign({}, state, {
        asyncStatus: "FAILURE",
        data: action.payload,
      });
    case DECREMENT_CUSTOM_FILTERS_STRAND_TOTAL:
      return Object.assign({}, state, {
        strand_total: parseInt(state.strand_total) - 1,
      });
    case QUIETLY_INITIALIZE_STRANDLESS_COUNT:
      return Object.assign({}, state, {
        strandless_total: action.strandless_total,
      });
    default:
      return state;
  }
};

export default MyLibraryReducer;
