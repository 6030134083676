import React , {Component} from 'react'
import HomeComponent from '../../components/HomeComponent';
import TrialModal from "../../components/TrailAccount/TrialModal";
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {Helmet} from 'react-helmet'
class Home extends Component {

    componentDidMount() {
        this.props.getMyDetailsAction();
    }

    render(){
        console.log(this.props.myDetails);
        return(
            <div style={{height:'calc(100% - 90px)'}}>
                <HomeComponent />
                {this.props.myDetails &&
                    this.props.myDetails.asyncStatus === "SUCCESS" &&
                <TrialModal user_name={this.props.myDetails.data && this.props.myDetails.data.first_name}/>}
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        myDetails: state.MyDetails,
        assignments : state.Assignments,
    }
};

export default connect(mapStateToProps, actions)(withRouter(Home));
