import React,{Component} from  'react'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import NewUnitSidebar from './NewUnitSidebar'
import NewUnitHeader from './NewUnitHeader'
import MainButtonGroup from './MainButtonGroup'
import OverviewContent from './NewUnitOverviewContent'
import TextContent from './NewUnitTextContent'
import Vocabulary from './NewUnitVocabularyContent'
import authAPI from '../../api/authAPI'
import SearchLesson from '../../components/SearchComponent'
import Background from '../../assets/images/stripe_bg.png'
import Assessment from './NewUnitAssessments'

// const componentMap = {
//     "OVERVIEW": OverviewContent,
//     "TEXTS": TextContent,
//     "VOCABULARY": Vocabulary,
// };

class NewUnitDetailedView extends Component{
    constructor (props) {
        super(props);
        this.state = {
            infoClicked: true,
            load: "initial",
            mentorTexts:[],
            vocabulary:[],
            dialog:true,
            assessements:[],
            selectedTab: "OVERVIEW",
            editObject:{},
            filterClicked:false,
            sortedList:[],
            selectedLessons:[],
            finalLessonIds:[],
            finalListForIds:[],
            removedLesson:[],
            isChanged:false
         }

        this.onInfoClick = this.onInfoClick.bind(this);
        this.onfilterClick = this.onfilterClick.bind(this);
        this.selectTab = this.selectTab.bind(this);
        this.getSelectedLesson = this.getSelectedLesson.bind(this);
        this.finalSortList = this.finalSortList.bind(this);
    }

    componentWillMount(){
      // this.props.allowClicksThruHere(false);
    }

    // componentDidMount(){

    //     this.props.getLessonsDetailedAction(this.props.params.uid);
    //     setTimeout(()=>this.setState({render: true}), 500);

    // }

    onInfoClick(){
        if(this.state.filterClicked===true){
             this.setState({ filterClicked: !this.state.filterClicked});
        }else{
            this.setState({ infoClicked: !this.state.infoClicked, load: ""});
        }
    }

    onfilterClick(status){
        this.setState({filterClicked:status, load: ""});
    }

    // onCancel=()=>{
    //     this.setState({
    //         dialog:false
    //     })
    // }

    selectTab (tab) {
        this.setState({selectedTab: tab});
    }

    finalSortList(list){
       this.setState({sortedList:list});
       if(list.length>0){
            let idList= [];
            list.map((lesson) => {
                        idList= idList.concat(lesson._id);
                    });
            if(this.props.location.pathname==='/new-unit'){
                authAPI.updateUnitLessons(this.props.newUnit && this.props.newUnit.newId,idList);
            }else{
                authAPI.updateUnitLessons(this.props.params.uid,idList);
            }
       }
    }

    componentDidMount=()=>{
        if(this.props.params.uid){
        authAPI.getUnitsDetail(this.props.params.uid)
        .then((response)=>{
                if(response){
                    this.setState({
                        success:true,
                        editObject:response,
                        selectedLessons:response.lessons,
                        mentorTexts:response.mentorTexts,
                        assessments:response.assessments

                    });
                    this.props.changeUnitHeadingAction(response.name);
                    this.props.changeUnitDescriptionAction(response && response.introduction && response.introduction.text);
                }else{
                    // this.setState({success:false})
                }
            })
            }else{
                 this.props.changeUnitHeadingAction('');
                 this.props.changeUnitDescriptionAction('');
            }
    };

    backgroundStyle=()=>{
        return{
             background:this.props.location.pathname==='/new-unit'?'':`url(${Background})`
        }
    };

    removeLessonFromSearch=(obj)=>{
        let listOfLessons = this.state.removedLesson;
        listOfLessons.push(obj);
        this.setState({removedLesson:listOfLessons});
    }

    clearingRemovedLessons=()=>{
        this.setState({removedLesson:[]});
    }

    isChangedStatus=(changedStatus)=>{
        this.setState({isChanged:changedStatus});
    }

    componentWillUnmount(){
        this.props.saveNewUnitIdAction('')
    }

    getSelectedLesson(obj){
        let self = this;
        let finalIdsList=[];
        let idList= [];
        if(obj.isAdd){
            authAPI.getLesssonsForCustomUnit(obj.lesson._id).then(function(resp){
                if(resp){
                    if(self.state.selectedLessons.length===0){
                        self.state.selectedLessons.push(resp);
                    }else{
                        self.state.selectedLessons.unshift(resp);
                    }
                    self.setState({selectedLessons:self.state.selectedLessons,isChanged:true},
                    ()=>{
                        self.state.selectedLessons.map((lesson) => {
                            idList= idList.concat(lesson._id);
                        });
                        let newCustomId=(self.props.newUnit && self.props.newUnit.newId);
                        if(self.props.location.pathname==='/new-unit'){
                            authAPI.updateUnitLessons(newCustomId,idList);
                        }else{
                            authAPI.updateUnitLessons(self.props.params.uid,idList);
                        };
                    });
                }
            });
        }else{
            let indexToRemove;
            this.state.selectedLessons.map((lesson,index) => {
                if(lesson._id === obj.lesson._id){
                    indexToRemove = index;
                }
            });
            this.state.selectedLessons.splice(indexToRemove,1);
            this.setState({selectedLessons:this.state.selectedLessons,isChanged:true},
            ()=>{
                self.state.selectedLessons.map((lesson) => {
                    idList= idList.concat(lesson._id);
                });
                    if(self.props.location.pathname!=='/new-unit'){
                        authAPI.updateUnitLessons(self.props.params.uid,idList);
                    }
                });
            }
    }

    render(){
        let componentMap={}
        if(this.props.location.pathname==='/new-unit'){
            componentMap = {
                    "OVERVIEW": OverviewContent,
                    "TEXTS": TextContent,
                    "VOCABULARY": Vocabulary,
                };
        }else{
            componentMap = {
                    "OVERVIEW": OverviewContent,
                    "TEXTS": TextContent,
                    "VOCABULARY": Vocabulary,
                    "ASSESSMENTS":Assessment
                };
        }
        const ContentComponent = componentMap[this.state.selectedTab];
        return(
            <div style={{position: "absolute", top: "0", right: "0", bottom: "0", left:"0",overflowX:'hidden'}}>
                {<div >
                {<NewUnitHeader infoClick={this.onInfoClick} customUnitdata={this.state.editObject} checkInfoStatus={this.state.infoClicked} checkFilterStatus={this.state.filterClicked} filterClick={this.onfilterClick} lessons={this.state.sortedList.length>0?this.state.sortedList:this.state.selectedLessons}/>}

                <div className={this.state.load === "initial" ? "new-unit-some-area-initial fill-height-or-more":(this.state.infoClicked?"some-area-new-unit fill-height-or-more":"fill-height-or-more new-unit-some-area-without-info")} >
                    <div style={this.backgroundStyle()}>
                       <MainButtonGroup selectTab={this.selectTab} selectedTab={this.state.selectedTab}/>
                       <div>
                           <ContentComponent assessments={this.state.assessments} newLesson={this.state.isChanged} changeNewLessonStatus={this.isChangedStatus} lessons={this.state.selectedLessons} url={this.props.location.pathname} removeLessons={this.getSelectedLesson} removeFromSearch={this.removeLessonFromSearch} mentorTexts={this.state.mentorTexts} sortLessons={this.finalSortList} sortedLessons={this.state.sortedList}/>
                       </div>
                    </div>
                    <div  style={{width:'385px'}}>
                        {this.state.filterClicked ?(
                            <SearchLesson prevLessons={this.state.selectedLessons} updateLessonRemoval={this.clearingRemovedLessons} lessonRemoval={this.state.removedLesson} addLessonsToSummary={this.getSelectedLesson}>
                                No detail
                            </SearchLesson>
                        ):(
                            <NewUnitSidebar finalLessons={this.state.selectedLessons} customUnitdata={this.state.editObject}>
                                No detail
                            </NewUnitSidebar>
                        )}
                    </div>

                </div>
                </div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return ( {
        newUnit:state.NewUnit,
        unitsDetail:state.UnitsDetail
    });
};

export default connect(mapStateToProps, actions)(withRouter(NewUnitDetailedView))
