import React,{Component} from 'react'
import authAPI from '../api/authAPI'
import constants from '../constants'
import InfiniteScroll from 'react-infinite-scroller'

let lessonRemoval ={};

class SearchComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
           totalCount:0,
           resources:[],
           pageNumber:1,
           searchString:'',
           hasMore:true,
           lessonIds:[]
        };
        this.pageLoad = true;
        this.fetchLessons = this.fetchLessons.bind(this);
        this.toggleLesson = this.toggleLesson.bind(this);
        this.props.prevLessons.map((lesson)=>{
            this.state.lessonIds.push(lesson._id)
        });
    }

    fetchLessons(searchString){
        let self=this;
        if(searchString !== this.state.searchString){
            self.state.resources = [];
            self.state.pageNumber = 1;
        }
        authAPI.getLessons(this.state.pageNumber,searchString).then(function(resp){
            if(!resp.results){
                let props = {
                        resources:[],
                        totalCount:0
                    };
                    self.setState(props);
                return false;
            }
            self.state.searchString = searchString;
            self.state.totalCount = resp.total;
            resp.results.map(function(item){
                self.state.resources.push(item);
            });
            let props = {
                        resources:self.state.resources,
                        totalCount:resp.total
                    };
                    self.setState(props);
            if(!self.pageLoad){
            setTimeout(function(){
                if(self.state.totalCount > self.state.resources.length){
                    self.setState({hasMore : true});
                }
            },500);
        }
        //self.pageLoad = false;  
        })
    }

    componentDidMount(){
        let self = this;
        if(this.props.lessonRemoval!=={}){
            let lessonIdArray = this.state.lessonIds;
            this.props.lessonRemoval.map((lessonId,i)=>{
            let lessonIdIndex = lessonIdArray.indexOf(lessonId._id);
                if(lessonIdIndex >-1){
                    lessonIdArray.splice(lessonIdIndex,1);
                }
            })
            this.setState({
                lessonIds:lessonIdArray
            });
            this.props.updateLessonRemoval();
        }
    }

    searchLessons(e){
        let searchString = e.target.value;
        this.fetchLessons(searchString);
    }

    componentWillReceiveProps(next) {
        if(next.lessonRemoval.length===1){
            let lessonId = next.lessonRemoval[0]._id;
            let lessonIdArray = this.state.lessonIds;
            let lessonIdIndex = lessonIdArray.indexOf(lessonId);
            if(lessonIdIndex >-1){
                lessonIdArray.splice(lessonIdIndex,1);
                this.setState({
                    lessonIds:lessonIdArray
                });
            }
            this.props.updateLessonRemoval();
        }
    }

    toggleLesson(e,lesson,index){
        let lessonId = lesson._id;
        let lessonIdArray = this.state.lessonIds;
        let lessonIdIndex = lessonIdArray.indexOf(lessonId);
        if(lessonIdIndex >-1){
            lessonIdArray.splice(lessonIdIndex,1);
            this.setState({
                lessonIds:lessonIdArray
            });
        }else{
            lessonIdArray.push(lessonId);
            this.setState({
                lessonIds:lessonIdArray
            });
        }
        const className = e.target.className;
        let isAdd = true;
        if(className.indexOf("remove-icon") !== -1){
            isAdd = false;
        }
        this.props.addLessonsToSummary({isAdd:isAdd,lesson:lesson});
    }

    _loadMore(){
        setTimeout(() => {
        if(this.state.totalCount > this.state.resources.length){
            this.state.pageNumber++;
            this.fetchLessons(this.state.searchString);
        }else{
            this.setState({
                hasMore: false
            })
        }
        },1000);
        if(this.pageLoad){
             this.fetchLessons(this.state.searchString);
             this.pageLoad = false;
        }
    }
   
     render(){
        let height = window.innerHeight;
        return(
            <div>
                <div  className='search-component' style={{marginTop:'50px'}}>
                    <div className='searchbar-textbox'>
                        <span className='textbox-icon-search icon-icon-search'/>
                        <input type='text'  className='unit-searchbar' placeholder='Search Lessons' onChange={this.searchLessons.bind(this)}/>
                    </div>
                    <div className='search-result-count'>{this.state.totalCount?this.state.totalCount+' RESULTS':'NO RESULTS'}</div>
                    <div className='lesson-search' style={{height:(height-260)+'px'}}>
                        <InfiniteScroll
                                pageStart={0}
                                loadMore={this._loadMore.bind(this)}
                                hasMore={this.state.hasMore}
                                loader={<div className="loader" style={{textAlign:'center'}}>Loading ...</div>}
                                useWindow={false}>
                                dataLength={this.state?.resources?.length ?? 0}
                            <div>
                                {this.state?.resources?.length && this.state.resources.map((lesson,i)=>(
                                    <div key={lesson+i}>
                                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',paddingBottom:'8px', paddingTop:'8px'}}>
                                            <div className="lesson-search-image"  ><img src={constants.filesUrl+(lesson.image||'')} style={{width:'57px'}}/></div>
                                            <div style={{paddingLeft:'17px',display:'flex'}}>  
                                                {lesson.name && <div className='lesson-name-search'>{lesson.name}</div>}
                                                    {/*<div  className='lesson-description-search'>Hello</div>*/}
                                                <a href={"/lessons/"+lesson._id} target="_blank" ><div className='lesson-search-view-icon icon--view'/></a>
                                                <div className={(this.state.lessonIds).indexOf(lesson._id)>-1?'lesson-search-remove-icon icon-icn-close':'lesson-search-add-icon icon-icon-add-circle-solid'} onClick={(e)=>this.toggleLesson(e,lesson,i)}/>
                                            </div>
                                        </div>
                                        {i<this.state.totalCount-1?<div className='lesson-searchbar-seperator'/>:''}
                                    </div>
                                    ))
                                }   
                            </div>
                        </InfiniteScroll>
                    </div>   
                </div>
            </div>
        )
    }
}

export default SearchComponent
