import {
    GET_RECOMMENDED_FOR_YOU_REQUEST,
    GET_RECOMMENDED_FOR_YOU_SUCCESS,
    GET_RECOMMENDED_FOR_YOU_FAILURE
} from '../actions';


const initialState = {};

const RecommendedForYouReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RECOMMENDED_FOR_YOU_REQUEST:
            return Object.assign({},state, {asyncStatus:  'PENDING'});
        case GET_RECOMMENDED_FOR_YOU_SUCCESS:
            return Object.assign({},state, {asyncStatus:  'SUCCESS', data: action.payload});
        case GET_RECOMMENDED_FOR_YOU_FAILURE:
            return Object.assign({},state, {asyncStatus:  'FAILURE', data: action.payload});
        default:
            return state;
    }
};

export default RecommendedForYouReducer;