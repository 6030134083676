import React , {Component} from 'react'
import LiteComponent from  '../../components/LiteComponent';
import {Helmet} from 'react-helmet'

class Lite extends Component{
    render(){

        // Fetch the domain from the route param. Defined when we navigate to /login/:domain
        return(
            <div>
                <LiteComponent  />
            </div>
        );
    }
}

export default Lite;