import React,{Component} from 'react'

class Dialog extends Component{
    dailogHeight(){
        return{
            top:(this.props.userType==='Student')?'650px': (this.props.userType==='admin'?'440px':'15%')
        }
    }
    
    render(){

        let dialogStyle = 'dialog-modal';

        if (this.props.dialogStyle) {
            dialogStyle += " " + this.props.dialogStyle;
        }

        return(
            <div>
                <div className='background-disable'/>
                    <div className={dialogStyle} /*style={this.dailogHeight()}*/ >
                        <div className='dialog-header'>
                            <div className='lesson-to-unit-heading'>
                                {this.props.title}
                            </div>
                        </div>
                        {this.props.children}
                        <div className='dialog-footer'>
                            <div className='button-regular-left'>
                                {!this.props.isListUser &&   
                                    <button onClick={this.props.onCancel} className='dialog-cancel-button'>
                                        CANCEL
                                    </button>
                                }
                                {this.props.isListUser && 
                                    <button onClick={this.props.onCancel} className='dialog-cancel-button btn-cancel-blue'>
                                        CLOSE
                                    </button>
                                }
                            </div>
                            <div className='button-regular-right'>
                                <button onClick={this.props.onOk} className={this.props.okStyle}>
                                    {this.props.okText}
                                </button>
                            </div>
                        </div>
                </div>
            </div>
        );
    }
}

export default Dialog