import React,{Component} from  'react';
import {Row, Col} from 'react-bootstrap'
import constants from '../../constants'
import authAPI from '../../api/authAPI'
import {connect} from 'react-redux'
import * as actions from '../../redux/actions'

class vBookVideoComponent extends Component{
	constructor(props){
		super(props)
		this.state = {
			vimeoId: ""
		}
	}

	componentDidMount(){
		let currentPath = window.location.pathname;
        if (currentPath != '/') {
           localStorage.setItem('prevPathLoc',currentPath);
        }
		authAPI.getLessonsDetail(this.props.params.uid).then((resp)=>{
			let vimeoUrl = resp.lesson.isVBook.text;			
			let videoUrlArr = vimeoUrl.split("/");
		    let videoId = videoUrlArr[videoUrlArr.length-1];
            this.setState({
              vimeoId:videoId
            })
            		console.log(videoId);
            localStorage.removeItem('prevPathLoc');
		});
	}

    render(){
        return(
        	<div>
        		{this.state.vimeoId != '' &&
	            <div style={{"marginTop":"10%"}}>
	                <iframe src={"https://player.vimeo.com/video/"+this.state.vimeoId} width="100%" height="450px" style={{"padding": "0px","overflow": "hidden", "border": "none"}} allowFullScreen></iframe>
	            </div>
	            }
	            <div style={{"position": "fixed", "bottom": "0","width": "100%"}}>
	            	<img src={require('../../assets/icons/SVG/icon-schoolwide.svg')} alt="Avatar" className="avatar"/>
	            </div>
	        </div>
        );
    }
}

export default connect('', actions)(vBookVideoComponent)
