let marked = require('./marked/marked');

class MarkdownHelper {

    constructor() {
        marked.setOptions({
            sanitize: true
        });
    }

    toHTML(markdown) {
        return marked(markdown);
    }
}

export let markdownHelper = new MarkdownHelper();