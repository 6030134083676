import React from 'react'
import AssistantTooltipComponent from '../../components/JoyrideCustomComponent/AssistantTooltipComponent'

const styleObject = {
  mainColor: '#CACACA',
  width: '650px',
  height: '50px',
  overflow: 'wrap',
  borderRadius: 11,
  backgroundColor: 'white',
  beacon: {
    inner: '#a350f0',
    outer: '#a350f0',
  },
  close: {
    display: 'none'
  },
  button: {
    display: 'none'
  },
  skip: {
    marginTop: 20,
  },
  back: {
    display: 'none'
  }
}
const styleObjectWithExitBtn = {
  mainColor: '#CACACA',
  width: '650px',
  height: '50px',
  overflow: 'wrap',
  borderRadius: 11,
  backgroundColor: 'white',
  beacon: {
    inner: '#a350f0',
    outer: '#a350f0',
  },
  close: {
    display: 'none'
  },
  button: {
    display: 'none'
  },
  skip: {
    marginTop: 20,
    borderRadius: 8,
    backgroundColor: '#009BBD',
    color: 'white'
  },
  back: {
    display: 'none'
  }
}

export default {
  steps: [
    {
      title: "Teaching Resources",
        text: <AssistantTooltipComponent
                  imgWidth={100}
                  imgHeight={130}
                  tooltipText={`
                      <p>
                        Welcome to the Teaching Resources page. Here, you will find thousands of lessons, guided reading plans, graphic organizers, charts, professional articles, photos, videos, and more.
                      </p>
                      <p>
                        The teaching resources are organized by 4 core strands: Reading, Writing, Language, and Speaking & Listening.
                      </p>
                      <p>
                        Let's click <strong>Reading</strong> to begin narrowing our search results.
                      </p>
        `} />,
        selector: '#nav-buttons',
        position: 'bottom-left',
        style: {
          ...styleObject,
          arrow: {
            left: '30%'
          }
        }
    },
      {
          title: "Teaching Resources - Reading",
          text: <AssistantTooltipComponent
                    imgWidth={100}
                    imgHeight={130}
                    tooltipText={`
                      <p>
                        Excellent! You are now viewing all of the Reading resources currently available. It's a lot! Let's drill down further by using the <strong>Filter</strong> icon in the upper right.
                      </p>
                      <p>
                        The <strong>Filter</strong> icon will allow you to narrow your search by grade level, standards, skills & strategies, resource type, and units of study.
                      </p>
          `} />,
          selector: '#filterIcon',
          position: 'bottom-right',
          style: styleObject
      },
      {
        title: "Filter Options",
        text: <AssistantTooltipComponent
                  tooltipText={`
                      <p>
                        Fundamentals Unlimited has empowered you with a robust set of filters to assist you in locating exactly the right resources to meet your specific needs.
                      </p>
                      <p>
                        You may select as many, or as few, filters as necessary. With each selection you will see your search results get smaller and smaller.
                      </p>
                      <p>
                        That's it for this tour! I certainly hope it was helpful and informative. Tours can be revisited and accessed at any time by clicking the <strong>Help</strong> icon in the lower right-hand corner of the screen.
                      </p>
        `} />,
        selector: '#joyride-filter-menu',
        position: 'left',
        isFixed: true,
        style: {
          ...styleObjectWithExitBtn,
          arrow: {
            top: "50%"
          }
        }
      }
    ]
  }
