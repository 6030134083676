import React from "react";

// This icon is slightly larger than the DropdownIcon at 6x12 pixels
const ArrowDropUpIcon = ({ width = "24", height = "24", className }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" className={className}>
    <path d="M6 15l6-6 6 6H6z" />
  </svg>
);

export default ArrowDropUpIcon;
