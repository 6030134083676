import React, {Component} from 'react'
import TopBar from './TopBar'
import ClassRooms from './ClassRooms'
import '../../assets/styles/myClass.scss'
import AddGroup from './AddGroup'
import EditGroup from './EditGroup'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import ImportModal from "../Shared/ImportModal"
import GlobalSearch from '../../components/GlobalSearchComponent'
import MyMenu from '../../containers/MyMenu'
class MyClass extends Component {
    constructor(props){
        super(props);
        this.state = {
            showAddGroup: false,
            showEditGroup: false,
            importGroup: false,
            importGroupId: 0,
            editGroupId: "",
            showMenu: false
        };
        this.setShowAddGroup = this.setShowAddGroup.bind(this);
        this.setShowEditGroup = this.setShowEditGroup.bind(this);
        this.importGroupClicked = this.importGroupClicked.bind(this);
        this.onCancelImport = this.onCancelImport.bind(this);
    }

    importGroupClicked(group) {
        this.setState({
            importGroup: true,
            importGroupId: group
        });
    }

    componentDidMount(){
        this.props.getGroupsAction();
    }

    refresh=()=>{
        this.props.getGroupsAction();
    };

    setShowAddGroup(){
        this.setState({showAddGroup: !this.state.showAddGroup});
        this.props.getGroupsAction();
    }

    setShowEditGroup(gid){
        this.props.getGroupByIdAction(gid);
        this.setState({editGroupId: gid});
        this.setState({showEditGroup: true});
    }

    setHideEditGroup=()=>{
        this.props.getGroupsAction();
        this.setState({showEditGroup: false});
    };

    onCancelImport() {
        this.setState({
            importGroup: false,
            importGroupId: 0
        });
    }

    showSearch=()=>{
        this.setState({showMenu:!this.state.showMenu},this.refs.right.show())
    };

    render() {
        let updatedGroups = this.props.groups;
        return (
            <div>
                {this.state.importGroup &&
                    <ImportModal
                        headerText="Import User"
                        templateUrl="../schoolwidefun/api/v1/groups/import-students"
                        style={{top: '10%'}}
                        refresh={this.refresh}
                        id={this.state.importGroupId}
                        type=''
                        onCancel={this.onCancelImport}
                        importAction={this.props.putGroupImportStudents}
                    />
                }
                {!this.state.showAddGroup && !this.state.showEditGroup &&
                    <div>
                        <TopBar showSearch={this.showSearch} setShowAddGroup={this.setShowAddGroup}/>
                        {updatedGroups.data && updatedGroups.data.length === 0 &&
                            <div className="no-groups">
                                You currently have no Groups or Students.
                            </div>
                        }
                        <ClassRooms
                            refresh={this.refresh}
                            setShowEditGroup={this.setShowEditGroup}
                            importGroupClicked={this.importGroupClicked}
                            groups={updatedGroups}
                            showMenu={this.state.showMenu}/>
                        {/*<Footer />*/}
                    </div>
                }
                {this.state.showAddGroup &&
                    <AddGroup setShowAddGroup={this.setShowAddGroup}/>
                }
                {this.state.showEditGroup && this.props.getGroupById.asyncStatus === "SUCCESS" && this.props.getGroupById.data &&
                    <EditGroup editGroupDetails={this.props.getGroupById.data} gid={this.state.editGroupId} setHideEditGroup={this.setHideEditGroup} setShowEditGroup={this.setShowEditGroup}/>
                }
                <div>
                    <MyMenu ref="right" alignment="right" showMenu={!this.state.showMenu} showScroll={false}>
                        <GlobalSearch focus={this.state.showMenu && window.innerWidth > 767} itemType='Library'/>
                    </MyMenu>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        groups : state.Groups,
        getGroupById: state.GetGroupById
    });
};

export default connect(mapStateToProps, actions)(MyClass)
