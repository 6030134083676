import React, { Component } from "react";
import { browserHistory } from "react-router";
import folderBigIcon from "../../assets/icons/SVG/folder-big.svg";
import constants from "../../constants";
import * as actions from "../../redux/actions";
import { connect } from "react-redux";
import selectImage from "../../assets/icons/SVG/Select_arrow2.svg";
import DetailedDescription from "../Details/DetailedDescription";
import brokenImage from "../../assets/images/notFound.webp";
import Accordion from "../../components/Shared/Accordion";

export class BrowsePageRightContent extends Component {
  linkComponent = (text, goTo, linkId) => {
    let item = this.props.selectedResource;
    let type =
      this.props.selectedResourceType === "lesson" ||
      this.props.selectedResourceType === "lessonsAll"
        ? "lessons"
        : this.props.selectedResourceType === "units"
        ? "units"
        : "";
    let id =
      item?.id ?? item?._id ?? item?.unit_id ?? this.props.selectedResourceId;
    return goTo === "appendices" ? (
      <li
        className="link-component"
        onClick={() =>
          browserHistory.push("/appendices/" + linkId + "/unit_id/" + id)
        }
      >
        {text}
      </li>
    ) : !linkId ? (
      <li
        className="link-component"
        onClick={() => browserHistory.push("/" + type + "/" + id + "/" + goTo)}
      >
        {text}
      </li>
    ) : (
      <li
        className="link-component"
        onClick={() => browserHistory.push("/" + goTo + "/" + linkId)}
      >
        {text}
      </li>
    );
  };

  render() {
    let item =
      !window.location.pathname.includes("library") &&
      this.props.selectedResource;
    return (
      <div className="browse-page-right-container">
        {item?.id || item?._id || item?.name ? (
          this.props.selectedResourceType === "lessonsAll" ? (
            <div>
              <h4 className="browse-page-right-title">{item.name}</h4>
              <img
                className="resource-product"
                onError={(event) => (event.target.src = brokenImage)}
                src={constants.filesUrl + (item?.image ?? "")}
                alt=""
                onClick={() => {
                  browserHistory.push("/lessons/" + item._id);
                }}
              />
              <div
                className="browse-page-right-visit-button"
                onClick={() => {
                  console.log(
                    "%cLOG: ",
                    "color:white; background: #41AE75; font-size: 24px",
                    item
                  );
                  this.props.handleClickForResourcesThumbnail({
                    ...item,
                    type: "Lesson",
                  });
                }}
              >
                <p
                  onClick={() => {
                    browserHistory.push("/lessons/" + item._id);
                  }}
                >
                  Visit Lesson Overview
                </p>
              </div>

              <Accordion
                title={"Skills & Strategies"}
                points={
                  item?.skills?.length &&
                  item?.skills?.map((skill) => this.linkComponent(skill, ""))
                }
              />

              <Accordion
                title={"Texts"}
                points={
                  item?.mentorTexts?.length &&
                  item?.mentorTexts?.map((text) =>
                    this.linkComponent(text.name, "books", text._id)
                  )
                }
              />

              <Accordion
                title={"Appendices"}
                points={
                  item?.appendices?.length &&
                  item?.appendices?.map((item) =>
                    this.linkComponent(item.name, "appendices", item._id)
                  )
                }
              />

              <Accordion
                title={"What's Next"}
                points={[].concat(
                  item?.whats_next?.assessment?.text &&
                    this.linkComponent("Assessment", "whats-next"),
                  item?.whats_next?.suggestedText?.text &&
                    this.linkComponent("Suggested Text", "whats-next"),
                  item?.whats_next?.guidedPractice?.text &&
                    this.linkComponent("Guided Practice", "whats-next"),
                  item?.whats_next?.homeSchoolConnections?.text &&
                    this.linkComponent("Home/School Connections", "whats-next"),
                  item?.whats_next?.independentApplications?.text &&
                    this.linkComponent(
                      "Independent Reading/Writing",
                      "whats-next"
                    ),
                  item?.whats_next?.readingWritingConnections?.text &&
                    this.linkComponent(
                      "Reading/Writing Connections",
                      "whats-next"
                    ),
                  item?.whats_next?.structuresRoutinesResources?.text &&
                    this.linkComponent(
                      "Structures/Routines Resources",
                      "whats-next"
                    )
                )}
              />
            </div>
          ) : this.props.selectedResourceType === "resource" ||
            this.props.selectedResourceType === "lesson" ? (
            <div>
              <h4 className="browse-page-right-title">{item.name}</h4>
              <DetailedDescription
                name={item.name}
                image={item?.image ?? ""}
                file={item?.file ?? item?.lesson_files}
                printable={item?.printable}
                printableFile={item?.printable_file ?? item?.file}
                is_digital={item?.is_digital}
                googleFormLink={item?.google_form_link}
                showClassroomShareButtonLink={
                  item?.showClassroomShareButtonLink
                }
                detailType={
                  this.props.selectedResourceType === "resource"
                    ? "resources"
                    : "lessons"
                }
                resourceType={
                  item?.type !== undefined
                    ? item.type
                    : this.props.selectedResourceType
                }
              />
            </div>
          ) : this.props.selectedResourceType === "assessment" ? (
            <div>
              <h4 className="browse-page-right-title">{item.name}</h4>
              <DetailedDescription
                name={item.name}
                image={item?.image ?? ""}
                file={item?.file ?? item?.files?.length ? item?.files[0] : ""}
                printable={item?.printable}
                printableFile={
                  item?.file ?? item?.files?.length ? item?.files[0] : ""
                }
                is_digital={item?.is_digital}
                googleFormLink={item?.google_form_link}
                showClassroomShareButtonLink={
                  item?.showClassroomShareButtonLink
                }
                detailType="assessments"
              />
            </div>
          ) : this.props.selectedResourceType === "units" ||
            this.props.selectedResourceType === "unit" ? (
            <div>
              <h4 className="browse-page-right-title">{item.name}</h4>
              <img
                className="resource-product"
                onError={(event) => (event.target.src = brokenImage)}
                src={constants.filesUrl + (item?.image ?? "")}
                alt=""
                onClick={() => browserHistory.push("/units/" + item.unit_id)}
              />
              <div
                className="browse-page-right-visit-button"
                onClick={() => browserHistory.push("/units/" + item.unit_id)}
              >
                <p>Visit Unit Overview</p>
              </div>

              <Accordion
                title={"Lessons"}
                points={item?.lessons?.map((lesson) =>
                  this.linkComponent(lesson.name, "lessons", lesson._id)
                )}
              />

              <Accordion
                title={"Texts"}
                points={item?.mentorTexts?.map((item) =>
                  this.linkComponent(item.name, "books", item._id)
                )}
              />

              <Accordion
                title={"Appendices"}
                points={item?.appendices?.map((item) =>
                  this.linkComponent(item.name, "appendices", item._id)
                )}
              />

              <Accordion
                title={"Assessments"}
                points={item?.assessments?.map((item) =>
                  this.linkComponent(item.name, "assessments", item._id)
                )}
              />

              <Accordion
                title={"Correlations"}
                points={item?.correlations?.map((item) =>
                  this.linkComponent(item.name, "correlations", item._id)
                )}
              />
            </div>
          ) : this.props.selectedResourceType === "folder" ? (
            <div>
              <h4 className="browse-page-right-title">{item.name}</h4>
              <img
                className="resource-product"
                onError={(event) => (event.target.src = brokenImage)}
                src={
                  item?.image
                    ? constants.filesUrl + (item.image || "")
                    : folderBigIcon
                }
                alt=""
              />
              <div
                className="browse-page-right-visit-button"
                onClick={() =>
                  this.props.handleClickForResourcesThumbnail(item)
                }
              >
                <p>View Folder Items</p>
              </div>
            </div>
          ) : this.props.selectedResourceType === "Books" || "book" ? (
            <div>
              <h4 className="browse-page-right-title">{item.name}</h4>
              <DetailedDescription
                name={item.name}
                image={item?.image ?? ""}
                file={item?.file ?? item?.lesson_files}
                printable={item?.printable}
                printableFile={item?.printable_file ?? item?.file}
                is_digital={item?.is_digital}
                googleFormLink={item?.google_form_link}
                detailType="books"
                showClassroomShareButtonLink={
                  item?.showClassroomShareButtonLink
                }
                resourceType={item?.type !== undefined ? item.type : "books"}
                bookId={item?._id}
              />
            </div>
          ) : (
            <div>
              <h4 className="browse-page-right-title">{item.name}</h4>
              <DetailedDescription
                name={item.name}
                image={item?.image ?? ""}
                detailType="resources"
                resourceType={item?.type}
              />
            </div>
          )
        ) : item?.zing_id ? (
          <div>
            <h4 className="browse-page-right-title">{item.name}</h4>
            <img
              className="resource-product"
              onError={(event) => (event.target.src = brokenImage)}
              src={constants.filesUrl + (item?.image ?? "")}
              alt=""
            />
          </div>
        ) : (
          <div className="browse-page-right-none">
            <img src={selectImage} alt="" />
            <p>Select an item to see its details</p>
          </div>
        )}
      </div>
    );
  }
}
export default connect(null, actions)(BrowsePageRightContent);
