import React,{Component} from 'react'
import {Button, ButtonGroup} from 'react-bootstrap'
import {withRouter,browserHistory} from 'react-router'

import "../../assets/styles/mobile.scss"

const urlMap = ['summary','preparation','lesson','whats-next']
class LessonsButton extends Component {
    render() {
        return (
            <Button
                    id={this.props.id}
                    className='lesson-button'
                    style={{backgroundColor: this.props.isSelected? "#009BBD":'#FFFFFF'}}
                    onClick={this.props.onClick}
            >
                <span className="lesson-button-text" style={{color: this.props.isSelected? '#FFFFFF': '#9B9B9B'}}>
                    {this.props.label}
                </span>
            </Button>
        );
    }
}

class LessonsButtonGroup extends Component{

    render(){
        let NameMap=["SUMMARY","PREPARATION","LESSON","WHAT'S NEXT"];
        return(
            <div>
                    <ButtonGroup id="nav-buttons" className="align-button-grp hidden-xs">
                        <LessonsButton
                            label="SUMMARY"
                            isSelected={this.props.selectedTab === "SUMMARY"}
                            onClick={() => {this.props.selectTab("SUMMARY"); browserHistory.replace('/lessons/'+this.props.id)}}
                        />
                        <LessonsButton
                            label="PREPARATION"
                            isSelected={this.props.selectedTab === "PREPARATION"}
                            onClick={() => {this.props.selectTab("PREPARATION");browserHistory.replace('/lessons/'+this.props.id +'/preparation')}}
                        />
                        <LessonsButton
                            label="LESSON"
                            isSelected={this.props.selectedTab === "LESSON"}
                            onClick={() => {this.props.selectTab("LESSON");browserHistory.replace('/lessons/'+this.props.id +'/lesson') }}
                            id='joyride-selector-lesson-button'
                        />
                        <LessonsButton
                            label="WHAT'S NEXT"
                            isSelected={this.props.selectedTab === "WHAT'S NEXT"}
                            onClick={() => {this.props.selectTab("WHAT'S NEXT");browserHistory.replace('/lessons/'+this.props.id +'/whats-next') }}
                        />
                    </ButtonGroup>        
            </div>
        );
    }
}

export default withRouter(LessonsButtonGroup)
