import React from "react";

export const CheckBox = ({ isChecked,onChange, id }) => {
    return (
        <div className={'checkbox-multiselect-wrapper'}>
            <input
                className={'checkbox-multiselect'}
                key={id}
                type="checkbox"
                checked={isChecked}
                value={id}
                onChange={onChange}
            />
            <p>{isChecked ? "Selected" : ''}</p>
        </div>
    )
};