import authAPI from "../../api/authAPI";
import * as _ from "lodash";

export const GET_TEACHING_RESOURCES_REQUEST = "GET_TEACHING_RESOURCES_REQUEST";
export const GET_TEACHING_RESOURCES_SUCCESS = "GET_TEACHING_RESOURCES_SUCCESS";
export const GET_TEACHING_RESOURCES_FAILURE = "GET_TEACHING_RESOURCES_FAILURE";

export const ORDER_SCHEDULED_TASKS_REQUEST = "ORDER_SCHEDULED_TASKS_REQUEST";
export const ORDER_SCHEDULED_TASKS_SUCCESS = "ORDER_SCHEDULED_TASKS_SUCCESS";
export const ORDER_SCHEDULED_TASKS_FAILURE = "ORDER_SCHEDULED_TASKS_FAILURE";


export const GET_RESOURCES_REQUEST = "GET_RESOURCES_REQUEST";
export const GET_RESOURCES_SUCCESS = "GET_RESOURCES_SUCCESS";
export const GET_RESOURCES_FAILURE = "GET_RESOURCES_FAILURE";
export const GET_HOME_RESOURCES_REQUEST = "GET_HOME_RESOURCES_REQUEST";
export const GET_HOME_RESOURCES_SUCCESS = "GET_HOME_RESOURCES_SUCCESS";
export const GET_HOME_RESOURCES_FAILURE = "GET_HOME_RESOURCES_FAILURE";

export const GET_FEATURED_UNITS_REQUEST = "GET_FEATURED_UNITS_REQUEST";
export const GET_FEATURED_UNITS_SUCCESS = "GET_FEATURED_UNITS_SUCCESS";
export const GET_FEATURED_UNITS_FAILURE = "GET_FEATURED_UNITS_FAILURE";

export const GET_ELEARNING_UNITS_REQUEST = "GET_ELEARNING_UNITS_REQUEST";
export const GET_ELEARNING_UNITS_SUCCESS = "GET_ELEARNING_UNITS_SUCCESS";
export const GET_ELEARNING_UNITS_FAILURE = "GET_ELEARNING_UNITS_FAILURE";

export const GET_FOUNDATIONAL_UNITS_REQUEST = "GET_FOUNDATIONAL_UNITS_REQUEST";
export const GET_FOUNDATIONAL_UNITS_SUCCESS = "GET_FOUNDATIONAL_UNITS_SUCCESS";
export const GET_FOUNDATIONAL_UNITS_FAILURE = "GET_FOUNDATIONAL_UNITS_FAILURE";

export const GET_UNITS_REQUEST = "GET_UNITS_REQUEST";
export const GET_UNITS_SUCCESS = "GET_UNITS_SUCCESS";
export const GET_UNITS_FAILURE = "GET_UNITS_FAILURE";

export const GET_MORE_UNITS_REQUEST = "GET_MORE_UNITS_REQUEST";
export const GET_MORE_UNITS_SUCCESS = "GET_MORE_UNITS_SUCCESS";
export const GET_MORE_UNITS_FAILURE = "GET_MORE_UNITS_FAILURE";

export const GET_MORE_FOUNDATIONAL_UNITS_REQUEST =
  "GET_MORE_FOUNDATIONAL_UNITS_REQUEST";
export const GET_MORE_FOUNDATIONAL_UNITS_SUCCESS =
  "GET_MORE_FOUNDATIONAL_UNITS_SUCCESS";
export const GET_MORE_FOUNDATIONAL_UNITS_FAILURE =
  "GET_MORE_FOUNDATIONAL_UNITS_FAILURE";

export const GET_MORE_ELEARNING_UNITS_REQUEST =
  "GET_MORE_ELEARNING_UNITS_REQUEST";
export const GET_MORE_ELEARNING_UNITS_SUCCESS =
  "GET_MORE_ELEARNING_UNITS_SUCCESS";
export const GET_MORE_ELEARNING_UNITS_FAILURE =
  "GET_MORE_ELEARNING_UNITS_FAILURE";

export const ADMIN_RESET_PASSWORD_REQUEST = "ADMIN_RESET_PASSWORD_REQUEST";
export const ADMIN_RESET_PASSWORD_SUCCESS = "ADMIN_RESET_PASSWORD_SUCCESS";
export const ADMIN_RESET_PASSWORD_FAILURE = "ADMIN_RESET_PASSWORD_FAILURE";

export const GET_FEATURED_ASSESSMENTS_REQUEST =
  "GET_FEATURED_ASSESSMENTS_REQUEST";
export const GET_FEATURED_ASSESSMENTS_SUCCESS =
  "GET_FEATURED_ASSESSMENTS_SUCCESS";
export const GET_FEATURED_ASSESSMENTS_FAILURE =
  "GET_FEATURED_ASSESSMENTS_FAILURE";
export const GET_ASSESSMENTS_REQUEST = "GET_ASSESSMENTS_REQUEST";
export const GET_ASSESSMENTS_SUCCESS = "GET_ASSESSMENTS_SUCCESS";
export const GET_ASSESSMENTS_FAILURE = "GET_ASSESSMENTS_FAILURE";

export const GET_LESSONS_DETAILS_FOR_APPENDICES = "GET_LESSONS_DETAIL_REQUEST";

export const GET_DETAILS_FOR_APPENDICES = "GET_DETAILS_FOR_APPENDICES";

export const GET_RESOURCE_TYPE_FOR_APPENDICES =
  "GET_RESOURCE_TYPE_FOR_APPENDICES";

export const GET_LESSONS_DETAIL_REQUEST = "GET_LESSONS_DETAIL_REQUEST";
export const GET_LESSONS_DETAIL_SUCCESS = "GET_LESSONS_DETAIL_SUCCESS";
export const GET_LESSONS_DETAIL_FAILURE = "GET_LESSONS_DETAIL_FAILURE";

export const GET_ALL_LESSONS_REQUEST = "GET_ALL_LESSONS_REQUEST";
export const GET_ALL_LESSONS_SUCCESS = "GET_ALL_LESSONS_SUCCESS";
export const GET_ALL_LESSONS_FAILURE = "GET_ALL_LESSONS_FAILURE";

export const GET_FILTER_OPTIONS_REQUEST = "GET_FILTER_OPTIONS_REQUEST";
export const GET_FILTER_OPTIONS_SUCCESS = "GET_FILTER_OPTIONS_SUCCESS";
export const GET_FILTER_OPTIONS_FAILURE = "GET_FILTER_OPTIONS_FAILURE";
export const GET_CUSTOM_FILTERS_REQUEST = "GET_CUSTOM_FILTERS_REQUEST";
export const GET_CUSTOM_FILTERS_SUCCESS = "GET_CUSTOM_FILTERS_SUCCESS";
export const GET_CUSTOM_FILTERS_FAILURE = "GET_CUSTOM_FILTERS_FAILURE";
export const GET_NEW_CUSTOM_FILTERS_REQUEST = "GET_NEW_CUSTOM_FILTERS_REQUEST";
export const GET_NEW_CUSTOM_FILTERS_SUCCESS = "GET_NEW_CUSTOM_FILTERS_SUCCESS";
export const GET_NEW_CUSTOM_FILTERS_FAILURE = "GET_NEW_CUSTOM_FILTERS_FAILURE";
export const GET_FILTER_STANDARDS_REQUEST = "GET_FILTER_STANDARDS_REQUEST";
export const GET_FILTER_STANDARDS_SUCCESS = "GET_FILTER_STANDARDS_SUCCESS";
export const GET_FILTER_STANDARDS_FAILURE = "GET_FILTER_STANDARDS_FAILURE";
export const UPDATE_FILTER_OPTIONS = "UPDATE_FILTER_OPTIONS";
export const UPDATE_NEW_FILTER_OPTIONS = "UPDATE_NEW_FILTER_OPTIONS";
export const POST_ADD_LESSON_TO_LIBRARY_REQUEST =
  "POST_ADD_LESSON_TO_LIBRARY_REQUEST";
export const POST_ADD_LESSON_TO_LIBRARY_SUCCESS =
  "POST_ADD_LESSON_TO_LIBRARY_SUCCESS";
export const POST_ADD_LESSON_TO_LIBRARY_FAILURE =
  "POST_ADD_LESSON_TO_LIBRARY_FAILURE";
export const POST_ADD_CORRELATION_TO_LIBRARY_REQUEST =
  "POST_ADD_CORRELATION_TO_LIBRARY_REQUEST";
export const POST_ADD_CORRELATION_TO_LIBRARY_SUCCESS =
  "POST_ADD_CORRELATION_TO_LIBRARY_SUCCESS";
export const POST_ADD_CORRELATION_TO_LIBRARY_FAILURE =
  "POST_ADD_CORRELATION_TO_LIBRARY_FAILURE";
export const DELETE_ITEM_FROM_FOLDER_REQUEST =
  "DELETE_ITEM_FROM_FOLDER_REQUEST";
export const DELETE_ITEM_FROM_FOLDER_SUCCESS =
  "DELETE_ITEM_FROM_FOLDER_SUCCESS";
export const DELETE_ITEM_FROM_FOLDER_FAILURE =
  "DELETE_ITEM_FROM_FOLDER_FAILURE";
export const GET_HEADING = "GET_HEADING";
export const GET_DESCRIPTION = "GET_DESCRIPTION";
export const NEW_UNIT_ID = "NEW_UNIT_ID";
export const SAVE_UNIT_REQUEST = "SAVE_UNIT_REQUEST";
export const SAVE_UNIT_SUCCESS = "SAVE_UNIT_SUCCESS";
export const SAVE_UNIT_FAILURE = "SAVE_UNIT_FAILURE";
export const UPDATE_UNIT_REQUEST = "UPDATE_UNIT_REQUEST";
export const UPDATE_UNIT_SUCCESS = "UPDATE_UNIT_SUCCESS";
export const UPDATE_UNIT_FAILURE = "UPDATE_UNIT_FAILURE";
export const POST_ADD_TEACHING_RESOURCE_TO_LIBRARY_REQUEST =
  "POST_ADD_TEACHING_RESOURCE_TO_LIBRARY_REQUEST";
export const POST_ADD_TEACHING_RESOURCE_TO_LIBRARY_SUCCESS =
  "POST_ADD_TEACHING_RESOURCE_TO_LIBRARY_SUCCESS";
export const POST_ADD_TEACHING_RESOURCE_TO_LIBRARY_FAILURE =
  "POST_ADD_TEACHING_RESOURCE_TO_LIBRARY_FAILURE";
export const POST_ADD_UNIT_TO_LIBRARY_REQUEST =
  "POST_ADD_UNIT_TO_LIBRARY_REQUEST";
export const POST_ADD_UNIT_TO_LIBRARY_SUCCESS =
  "POST_ADD_UNIT_TO_LIBRARY_SUCCESS";
export const POST_ADD_UNIT_TO_LIBRARY_FAILURE =
  "POST_ADD_UNIT_TO_LIBRARY_FAILURE";
export const POST_ADD_BOOK_TO_LIBRARY_REQUEST =
  "POST_ADD_BOOK_TO_LIBRARY_REQUEST";
export const POST_ADD_BOOK_TO_LIBRARY_SUCCESS =
  "POST_ADD_BOOK_TO_LIBRARY_SUCCESS";
export const POST_ADD_BOOK_TO_LIBRARY_FAILURE =
  "POST_ADD_BOOK_TO_LIBRARY_FAILURE";
export const POST_ADD_ASSESSMENT_TO_LIBRARY_REQUEST =
  "POST_ADD_ASSESSMENT_TO_LIBRARY_REQUEST";
export const POST_ADD_ASSESSMENT_TO_LIBRARY_SUCCESS =
  "POST_ADD_ASSESSMENT_TO_LIBRARY_SUCCESS";
export const POST_ADD_ASSESSMENT_TO_LIBRARY_FAILURE =
  "POST_ADD_ASSESSMENT_TO_LIBRARY_FAILURE";

export const GET_FOLDER_NOTIFICATION_REQUEST =
  "GET_FOLDER_NOTIFICATION_REQUEST";
export const GET_FOLDER_NOTIFICATION_SUCCESS =
  "GET_FOLDER_NOTIFICATION_SUCCESS";
export const GET_FOLDER_NOTIFICATION_FAILURE =
  "GET_FOLDER_NOTIFICATION_FAILURE";

export const POST_FOLDER_NOTIFICATION_REQUEST =
  "POST_FOLDER_NOTIFICATION_REQUEST";
export const POST_FOLDER_NOTIFICATION_SUCCESS =
  "POST_FOLDER_NOTIFICATION_SUCCESS";
export const POST_FOLDER_NOTIFICATION_FAILURE =
  "POST_FOLDER_NOTIFICATION_FAILURE";

export const GET_MY_LIBRARY_REQUEST = "GET_MY_LIBRARY_REQUEST";
export const GET_MY_LIBRARY_SUCCESS = "GET_MY_LIBRARY_SUCCESS";
export const GET_MY_LIBRARY_FAILURE = "GET_MY_LIBRARY_FAILURE";
export const GET_MY_LIBRARY_RECENTS_REQUEST = "GET_MY_LIBRARY_RECENTS_REQUEST";
export const GET_MY_LIBRARY_RECENTS_SUCCESS = "GET_MY_LIBRARY_RECENTS_SUCCESS";
export const GET_MY_LIBRARY_RECENTS_FAILURE = "GET_MY_LIBRARY_RECENTS_FAILURE";
export const POST_FILTER_REQUEST = "POST_FILTER_REQUEST";
export const POST_FILTER_SUCCESS = "POST_FILTER_SUCCESS";
export const POST_FILTER_FAILURE = "POST_FILTER_FAILURE";
export const GET_ANNOTATIONS_REQUEST = "GET_ANNOTATIONS_REQUEST";
export const GET_ANNOTATIONS_SUCCESS = "GET_ANNOTATIONS_SUCCESS";
export const GET_ANNOTATIONS_FAILURE = "GET_ANNOTATIONS_FAILURE";
export const ADD_ANNOTATION_REQUEST = "ADD_ANNOTATION_REQUEST";
export const ADD_ANNOTATION_SUCCESS = "ADD_ANNOTATION_SUCCESS";
export const ADD_ANNOTATION_FAILURE = "ADD_ANNOTATION_FAILURE";
export const EDIT_ANNOTATION_REQUEST = "EDIT_ANNOTATION_REQUEST";
export const EDIT_ANNOTATION_SUCCESS = "EDIT_ANNOTATION_SUCCESS";
export const EDIT_ANNOTATION_FAILURE = "EDIT_ANNOTATION_FAILURE";
export const DELETE_ANNOTATION_REQUEST = "DELETE_ANNOTATION_REQUEST";
export const DELETE_ANNOTATION_SUCCESS = "DELETE_ANNOTATION_SUCCESS";
export const DELETE_ANNOTATION_FAILURE = "DELETE_ANNOTATION_FAILURE";
export const FILTER_SCALING = "FILTER_SCALING";
export const REMOVE_FILTER_CHECK = "REMOVE_FILTER_CHECK";
export const GET_SCHEDULED_TASKS_REQUEST = "GET_SCHEDULED_TASKS_REQUEST";
export const GET_SCHEDULED_TASKS_SUCCESS = "GET_SCHEDULED_TASKS_SUCCESS";
export const GET_SCHEDULED_TASKS_FAILURE = "GET_SCHEDULED_TASKS_FAILURE";
export const GET_CUSTOM_UNITS_REQUEST = "GET_CUSTOM_UNITS_REQUEST";
export const GET_CUSTOM_UNITS_SUCCESS = "GET_CUSTOM_UNITS_SUCCESS";
export const GET_CUSTOM_UNITS_FAILURE = "GET_CUSTOM_UNITS_FAILURE";
export const CHECK_HEADING = "CHECK_HEADING";
export const CHECK_DESCRIPTION = "CHECK_DESCRIPTION";
export const CLEAR_FILTERS = "CLEAR_FILTERS";

export const SAVE_LOCATION = "SAVE_LOCATION";
export const POST_SCHEDULED_TASK_REQUEST = "POST_SCHEDULED_TASK_REQUEST";
export const POST_SCHEDULED_TASK_SUCCESS = "POST_SCHEDULED_TASK_SUCCESS";
export const POST_SCHEDULED_TASK_FAILURE = "POST_SCHEDULED_TASK_FAILURE";

export const GET_UNITS_DETAIL_REQUEST = "GET_UNITS_DETAIL_REQUEST";
export const GET_UNITS_DETAIL_SUCCESS = "GET_UNITS_DETAIL_SUCCESS";
export const GET_UNITS_DETAIL_FAILURE = "GET_UNITS_DETAIL_FAILURE";

export const GET_SINGLE_UNIT_DETAILS_REQUEST = "GET_UNITS_DETAIL_REQUEST";
export const GET_SINGLE_UNIT_DETAILS_SUCCESS = "GET_UNITS_DETAIL_SUCCESS";
export const GET_SINGLE_UNIT_DETAILS_FAILURE = "GET_UNITS_DETAIL_FAILURE";

export const ORDER_SCHEDULED_TASK_REQUEST = "ORDER_SCHEDULED_TASK_REQUEST";
export const ORDER_SCHEDULED_TASK_SUCCESS = "ORDER_SCHEDULED_TASK_SUCCESS";
export const ORDER_SCHEDULED_TASK_FAILURE = "ORDER_SCHEDULED_TASK_FAILURE";

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const GET_FOLDERS_REQUEST = "GET_FOLDERS_REQUEST";
export const GET_FOLDERS_SUCCESS = "GET_FOLDERS_SUCCESS";
export const GET_FOLDERS_FAILURE = "GET_FOLDERS_FAILURE";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const GET_DISTRICTS_REQUEST = "GET_DISTRICTS_REQUEST";
export const GET_DISTRICTS_SUCCESS = "GET_DISTRICTS_SUCCESS";
export const GET_DISTRICTS_FAILURE = "GET_DISTRICTS_FAILURE";

export const DELETE_DISTRICT_REQUEST = "DELETE_DISTRICT_REQUEST";
export const DELETE_DISTRICT_SUCCESS = "DELETE_DISTRICT_SUCCESS";
export const DELETE_DISTRICT_FAILURE = "DELETE_DISTRICT_FAILURE";

export const POST_USER_REQUEST = "POST_USER_REQUEST";
export const POST_USER_SUCCESS = "POST_USER_SUCCESS";
export const POST_USER_FAILURE = "POST_USER_FAILURE";

export const PUT_USER_REQUEST = "PUT_USER_REQUEST";
export const PUT_USER_SUCCESS = "PUT_USER_SUCCESS";
export const PUT_USER_FAILURE = "PUT_USER_FAILURE";

export const POST_DISTRICT_REQUEST = "POST_DISTRICT_REQUEST";
export const POST_DISTRICT_SUCCESS = "POST_DISTRICT_SUCCESS";
export const POST_DISTRICT_FAILURE = "POST_DISTRICT_FAILURE";

export const PUT_DISTRICT_REQUEST = "PUT_DISTRICT_REQUEST";
export const PUT_DISTRICT_SUCCESS = "PUT_DISTRICT_SUCCESS";
export const PUT_DISTRICT_FAILURE = "PUT_DISTRICT_FAILURE";

export const IMPORT_USERS_REQUEST = "IMPORT_USERS__REQUEST";
export const IMPORT_USERS_SUCCESS = "IMPORT_USERS__SUCCESS";
export const IMPORT_USERS_FAILURE = "IMPORT_USERS__FAILURE";

export const GET_MY_DETAILS_REQUEST = "GET_MY_DETAILS_REQUEST";
export const GET_MY_DETAILS_SUCCESS = "GET_MY_DETAILS_SUCCESS";
export const GET_MY_DETAILS_FAILURE = "GET_MY_DETAILS_FAILURE";

export const GET_RESOURCES_DETAILS_REQUEST = "GET_RESOURCES_DETAILS_REQUEST";
export const GET_RESOURCES_DETAILS_SUCCESS = "GET_RESOURCES_DETAILS_SUCCESS";
export const GET_RESOURCES_DETAILS_FAILURE = "GET_RESOURCES_DETAILS_FAILURE";

export const GET_BOOK_DETAILS_REQUEST = "GET_BOOK_DETAILS_REQUEST";
export const GET_BOOK_DETAILS_SUCCESS = "GET_BOOK_DETAILS_SUCCESS";
export const GET_BOOK_DETAILS_FAILURE = "GET_BOOK_DETAILS_FAILURE";

export const GET_BOOK_PREVIEW_REQUEST = "GET_BOOK_PREVIEW_REQUEST";
export const GET_BOOK_PREVIEW_SUCCESS = "GET_BOOK_PREVIEW_SUCCESS";
export const GET_BOOK_PREVIEW_FAILURE = "GET_BOOK_PREVIEW_FAILURE";

export const GET_ASSESSMENT_DETAILS_REQUEST = "GET_ASSESSMENT_DETAILS_REQUEST";
export const GET_ASSESSMENT_DETAILS_SUCCESS = "GET_ASSESSMENT_DETAILS_SUCCESS";
export const GET_ASSESSMENT_DETAILS_FAILURE = "GET_ASSESSMENT_DETAILS_FAILURE";

export const GET_CORRELATION_DETAILS_REQUEST =
  "GET_CORRELATION_DETAILS_REQUEST";
export const GET_CORRELATION_DETAILS_SUCCESS =
  "GET_CORRELATION_DETAILS_SUCCESS";
export const GET_CORRELATION_DETAILS_FAILURE =
  "GET_CORRELATION_DETAILS_FAILURE";

export const GET_GROUPS_REQUEST = "GET_GROUPS_REQUEST";
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_FAILURE = "GET_GROUPS_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const GET_BOOKS_REQUEST = "GET_BOOKS_REQUEST";
export const GET_BOOKS_SUCCESS = "GET_BOOKS_SUCCESS";
export const GET_BOOKS_FAILURE = "GET_BOOKS_FAILURE";

export const POST_GROUP_REQUEST = "POST_GROUP_REQUEST";
export const POST_GROUP_SUCCESS = "POST_GROUP_SUCCESS";
export const POST_GROUP_FAILURE = "POST_GROUP_FAILURE";

export const GET_GROUP_BY_ID_REQUEST = "GET_GROUP_BY_ID_REQUESTT";
export const GET_GROUP_BY_ID_SUCCESS = "GET_GROUP_BY_ID_SUCCESS";
export const GET_GROUP_BY_ID_FAILURE = "GET_GROUP_BY_ID_FAILURE";

export const PUT_GROUP_REQUEST = "PUT_GROUP_REQUEST";
export const PUT_GROUP_SUCCESS = "PUT_GROUP_SUCCESS";
export const PUT_GROUP_FAILURE = "PUT_GROUP_FAILURE";

export const ADD_USER_TO_GROUP_REQUEST = "ADD_USER_TO_GROUP_REQUEST";
export const ADD_USER_TO_GROUP_SUCCESS = "ADD_USER_TO_GROUP_SUCCESS";
export const ADD_USER_TO_GROUP_FAILURE = "ADD_USER_TO_GROUP_FAILURE";

export const REMOVE_USER_FROM_GROUP_REQUEST = "REMOVE_USER_FROM_GROUP_REQUEST";
export const REMOVE_USER_FROM_GROUP_SUCCESS = "REMOVE_USER_FROM_GROUP_SUCCESS";
export const REMOVE_USER_FROM_GROUP_FAILURE = "REMOVE_USER_FROM_GROUP_FAILURE";

export const GET_RECOMMENDED_FOR_YOU_REQUEST =
  "GET_RECOMMENDED_FOR_YOU_REQUEST";
export const GET_RECOMMENDED_FOR_YOU_SUCCESS =
  "GET_RECOMMENDED_FOR_YOU_SUCCESS";
export const GET_RECOMMENDED_FOR_YOU_FAILURE =
  "GET_RECOMMENDED_FOR_YOU_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const DELETE_GROUP_REQUEST = "DELETE_GROUP_REQUEST";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAILURE = "DELETE_GROUP_FAILURE";

export const REVIEW_TOKEN_REQUEST = "REVIEW_TOKEN_REQUEST";
export const REVIEW_TOKEN_SUCCESS = "REVIEW_TOKEN_SUCCESS";
export const REVIEW_TOKEN_FAILURE = "REVIEW_TOKEN_FAILURE";

export const RECOVER_PASSWORD_REQUEST = "RECOVER_PASSWORD_REQUEST";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_FAILURE = "RECOVER_PASSWORD_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const GET_USER_ASSIGNMENTS_REQUEST = "GET_USER_ASSIGNMENTS_REQUEST";
export const GET_USER_ASSIGNMENTS_SUCCESS = "GET_USER_ASSIGNMENTS_SUCCESS";
export const GET_USER_ASSIGNMENTS_FAILURE = "GET_USER_ASSIGNMENTS_FAILURE";

export const DELETE_USER_ASSIGNMENTS_REQUEST =
  "DELETE_USER_ASSIGNMENTS_REQUEST";
export const DELETE_USER_ASSIGNMENTS_SUCCESS =
  "DELETE_USER_ASSIGNMENTS_SUCCESS";
export const DELETE_USER_ASSIGNMENTS_FAILURE =
  "DELETE_USER_ASSIGNMENTS_FAILURE";

export const COMPLETE_ASSIGNMENT_REQUEST = "COMPLETE_ASSIGNMENT_REQUEST";
export const COMPLETE_ASSIGNMENT_SUCCESS = "COMPLETE_ASSIGNMENT_SUCCESS";
export const COMPLETE_ASSIGNMENT_FAILURE = "COMPLETE_ASSIGNMENT_FAILURE";

export const ASSIGNMENT_ITEM = "ASSIGNMENT_ITEM";
export const ADD_LESSON_SUCCESS = "ADD_LESSON_SUCESS";
export const SAVE_PLANNER_DATE = "SAVE_PLANNER_DATE";

export const GET_USER_DETAILS_BY_ID_REQUEST = "GET_USER_DETAILS_BY_ID_REQUEST";
export const GET_USER_DETAILS_BY_ID_SUCCESS = "GET_USER_DETAILS_BY_ID_SUCCESS";
export const GET_USER_DETAILS_BY_ID_FAILURE = "GET_USER_DETAILS_BY_ID_FAILURE";

export const RESET_USER_PASSWORD_REQUEST = "RESET_USER_PASSWORD_REQUEST";
export const RESET_USER_PASSWORD_SUCCESS = "RESET_USER_PASSWORD_SUCCESS";
export const RESET_USER_PASSWORD_FAILURE = "RESET_USER_PASSWORD_FAILURE";

export const POST_ASSIGNMENT_REQUEST = "POST_ASSIGNMENT_REQUEST";
export const POST_ASSIGNMENT_SUCCESS = "POST_ASSIGNMENT_SUCCESS";
export const POST_ASSIGNMENT_FAILURE = "POST_ASSIGNMENT_FAILURE";

export const GET_DEFINITION_REQUEST = "GET_DEFINITION_REQUEST";
export const GET_DEFINITION_SUCCESS = "GET_DEFINITION_SUCCESS";
export const GET_DEFINITION_FAILURE = "GET_DEFINITION_FAILURE";

export const GET_BOOK_ELEARNING_REQUEST = "GET_BOOK_ELEARNING_REQUEST";
export const GET_BOOK_ELEARNING_SUCCESS = "GET_BOOK_ELEARNING_SUCCESS";
export const GET_BOOK_ELEARNING_FAILURE = "GET_BOOK_ELEARNING_SUCCESS";

export const CHECK_PASSWORD_TYPE = "CHECK_PASSWORD_TYPE";
export const PICTURE_PASSWORD = "PICTURE_PASSWORD";
export const STANDARD_PASSWORD = "STANDARD_PASSWORD";

export const GET_GOAL_TYPES_REQUEST = "GET_GOAL_TYPES_REQUEST";
export const GET_GOAL_TYPES_SUCCESS = "GET_GOAL_TYPES_SUCCESS";
export const GET_GOAL_TYPES_FAILURE = "GET_GOAL_TYPES_FAILURE";

export const GET_REFERRAL_TYPES_REQUEST = "GET_REFERRAL_TYPES_REQUEST";
export const GET_REFERRAL_TYPES_SUCCESS = "GET_REFERRAL_TYPES_SUCCESS";
export const GET_REFERRAL_TYPES_FAILURE = "GET_REFERRAL_TYPES_FAILURE";

export const START_JOYRIDE = "START_JOYRIDE";
export const JOYRIDE_INTENT = "JOYRIDE_INTENT";
export const RESET_JOYRIDE = "RESET_JOYRIDE";
export const JOYRIDE_STOP = "JOYRIDE_STOP";
export const JOYRIDE_NEXT_STEP = "JOYRIDE_NEXT_STEP";
export const JOYRIDE_INSERT_STEP = "JOYRIDE_INSERT_STEP";
export const JOYRIDE_SET_STEPS_AND_START = "JOYRIDE_SET_STEPS_AND_START";
export const JOYRIDE_START_AT_INDEX = "JOYRIDE_START_AT_INDEX";
export const DISPLAY_TOUR_MODAL = "DISPLAY_TOUR_MODAL";
export const HIDE_TOUR_MODAL = "HIDE_TOUR_MODAL";
export const JOYRIDE_LOAD_STEPS = "JOYRIDE_LOAD_STEPS";
export const RESET_RESET_JOYRIDE = "RESET_RESET_JOYRIDE";
export const ENABLE_JOYRIDE_CLICKS = "ENABLE_JOYRIDE_CLICKS";

export const GET_ALL_PICTURE_PASSWORDS_REQUEST =
  "GET_ALL_PICTURE_PASSWORDS_REQUEST";
export const GET_ALL_PICTURE_PASSWORDS_SUCCESS =
  "GET_ALL_PICTURE_PASSWORDS_SUCCESS";
export const GET_ALL_PICTURE_PASSWORDS_FAILURE =
  "GET_ALL_PICTURE_PASSWORDS_FAILURE";

export const EREADER_MODE_EVENT = "EREADER_MODE_EVENT";
export const EREADER_DELETE_EVENT = "EREADER_DELETE_EVENT";

export const EREADER_GET_BOOKMARKS_EVENT = "EREADER_GET_BOOKMARKS_EVENT";
export const EREADER_ADD_BOOKMARK_EVENT = "EREADER_ADD_BOOKMARK_EVENT";
export const EREADER_DELETE_BOOKMARK_EVENT = "EREADER_DELETE_BOOKMARK_EVENT";

export const GET_ELEARNING_SKILLS_REQUEST = "GET_ELEARNING_SKILLS_REQUEST";
export const GET_ELEARNING_SKILLS_SUCCESS = "GET_ELEARNING_SKILLS_SUCCESS";
export const GET_ELEARNING_SKILLS_FAILURE = "GET_ELEARNING_SKILLS_FAILURE";

export const EREADER_SET_CURRENT_PAGE_EVENT = "EREADER_SET_CURRENT_PAGE_EVENT";
export const EREADER_SET_TOTAL_PAGES_EVENT = "EREADER_SET_TOTAL_PAGES_EVENT";
export const EREADER_SET_PAGE_MODE_EVENT = "EREADER_SET_PAGE_MODE_EVENT";
export const EREADER_SET_BOOK_LOADED = "EREADER_SET_BOOK_LOADED";

export const DECREMENT_CUSTOM_FILTERS_STRAND_TOTAL =
  "DECREMENT_CUSTOM_FILTERS_STRAND_TOTAL";
export const QUIETLY_INITIALIZE_STRANDLESS_COUNT =
  "QUIETLY_INITIALIZE_STRANDLESS_COUNT";

export const POST_SAML_DATA = "POST_SAML_DATA";
export const POST_SAML_DATA_SUCCESS = "POST_SAML_DATA_SUCCESS";
export const POST_SAML_DATA_FAILURE = "POST_SAML_DATA_FAILURE";

export const getAllPicturePasswordsAction = (token) => {
  return (dispatch) => {
    dispatch({ type: GET_ALL_PICTURE_PASSWORDS_REQUEST });
    authAPI
      .getAllPicturePasswords(token)
      .then((response) => {
        dispatch({
          type: GET_ALL_PICTURE_PASSWORDS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_PICTURE_PASSWORDS_FAILURE,
          payload: err,
        });
      });
  };
};

export const loginWithUsernameAction = (username) => {
  return (dispatch) => {
    dispatch({ type: CHECK_PASSWORD_TYPE });
    authAPI
      .loginWithUsername(username)
      .then((response) => {
        dispatch({
          type: PICTURE_PASSWORD,
          payload: {
            username,
            data: response.data,
            error: response.status !== 200,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: PICTURE_PASSWORD,
          payload: {
            error: true,
            errorMsg: err,
            data: [],
          },
        });
      });
  };
};

export const login = (username, password) => {
  return (dispatch) => {
    authAPI
      .loginWithPassword(username, password)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "LOGIN_SUCCESS",
            payload: {
              userData: response.data,
            },
          });
        } else {
          dispatch({
            type: "LOGIN_FAILED",
            payload: {
              error: response,
            },
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: "LOGIN_FAILED",
            payload: {
              error: error.response,
            },
          });
        }
      });
  };
};

export const ssologin = (code) => {
  return (dispatch) => {
    authAPI
      .validateSSOLogin(code)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "LOGIN_SUCCESS",
            payload: {
              userData: response.data,
            },
          });
        } else {
          dispatch({
            type: "LOGIN_FAILED",
            payload: {
              error: response,
            },
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: "LOGIN_FAILED",
            payload: {
              error: error.response,
            },
          });
        }
      });
  };
};

export const googleSsologin = (email) => {
  return (dispatch) => {
    authAPI
      .studentLoginWithGoogle(email)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "LOGIN_SUCCESS",
            payload: {
              userData: response,
            },
          });
        } else {
          dispatch({
            type: "LOGIN_FAILED",
            payload: {
              error: response,
            },
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: "LOGIN_FAILED",
            payload: {
              error: error.response,
            },
          });
        }
      });
  };
};

export const getELearningDataAction = (book) => {
  return (dispatch) => {
    dispatch({ type: GET_BOOK_ELEARNING_REQUEST });
    authAPI
      .getELearningData(book)
      .then((response) => {
        dispatch({
          type: GET_BOOK_ELEARNING_SUCCESS,
          payload: response,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_BOOK_ELEARNING_FAILURE,
          payload: error,
        });
      });
  };
};

export const submitELearningBookAnswers = (bookId, answers) => {
  return (dispatch) =>
    Promise.all(
      _.map(Object.keys(answers), (key) =>
        authAPI.submitELearningAnswer(bookId, key, answers[key])
      )
    ).then(() => {
      dispatch({ type: "SUBMITTED_ELEARNING_ANSWERS" });
    });
};

export const getFeaturedTeachingResourcesAction = (strand) => {
  return (dispatch) => {
    dispatch({ type: GET_TEACHING_RESOURCES_REQUEST });
    authAPI
      .getTeachingResources(strand)
      .then((response) => {
        dispatch({
          type: GET_TEACHING_RESOURCES_SUCCESS,
          payload: response,
          request: strand,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_TEACHING_RESOURCES_FAILURE,
          payload: error,
        });
      });
  };
};

export const getResourcesAction = (pageNumber, strand) => {
  return (dispatch) => {
    dispatch({ type: GET_RESOURCES_REQUEST });
    authAPI
      .getResources(pageNumber, strand)
      .then((response) => {
        dispatch({
          type: GET_RESOURCES_SUCCESS,
          payload: response,
          request: strand,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_RESOURCES_FAILURE,
          payload: error,
        });
      });
  };
};

export const getHomeResourcesAction = (grade) => {
  return (dispatch) => {
    dispatch({ type: GET_HOME_RESOURCES_REQUEST });
    authAPI
      .getHomeResources(grade)
      .then((response) => {
        dispatch({
          type: GET_HOME_RESOURCES_SUCCESS,
          payload: response,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_HOME_RESOURCES_FAILURE,
          payload: error,
        });
      });
  };
};

export const getAllLessonsAction = (pageNumber, strand) => {
  return (dispatch) => {
    dispatch({ type: GET_ALL_LESSONS_REQUEST });
    if(strand == 100){
      authAPI
      .getAllLessons(1, strand)
      .then((response) => {
        dispatch({
          type: GET_ALL_LESSONS_SUCCESS,
          payload: response,
          request: strand,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_ALL_LESSONS_FAILURE,
          payload: error,
        });
      });
    }else if(strand != 100){
      authAPI
      .getLessons(1, strand)
      .then((response) => {
        dispatch({
          type: GET_ALL_LESSONS_SUCCESS,
          payload: response,
          request: strand,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_ALL_LESSONS_FAILURE,
          payload: error,
        });
      });
    }
    
  };
};

export const getFeaturedUnitsActions = (strand) => {
  return (dispatch) => {
    dispatch({ type: GET_FEATURED_UNITS_REQUEST });
    authAPI
      .getFeaturedUnits(strand)
      .then((response) => {
        dispatch({
          type: GET_FEATURED_UNITS_SUCCESS,
          payload: response,
          request: strand,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_FEATURED_UNITS_FAILURE,
          payload: error,
        });
      });
  };
};

export const getElearningUnitsActions = ({
  strand,
  query,
  isBackButtonPressed,
}) => {
  return (dispatch) => {
    dispatch({ type: GET_ELEARNING_UNITS_REQUEST });
    authAPI
      .getElearningUnits(strand, query, isBackButtonPressed)
      .then((response) => {
        dispatch({
          type: GET_ELEARNING_UNITS_SUCCESS,
          payload: response,
          request: strand,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_ELEARNING_UNITS_FAILURE,
          payload: error,
        });
      });
  };
};

export const getFoundationalUnitsActions = ({
  strand,
  query,
  isBackButtonPressed,
}) => {
  return (dispatch) => {
    dispatch({ type: GET_FOUNDATIONAL_UNITS_REQUEST });
    authAPI
      .getFoundationalUnits(strand, query, isBackButtonPressed)
      .then((response) => {
        dispatch({
          type: GET_FOUNDATIONAL_UNITS_SUCCESS,
          payload: response,
          request: strand,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_FOUNDATIONAL_UNITS_FAILURE,
          payload: error,
        });
      });
  };
};

export const getMoreUnitsAction = ({ pageNumber, strand, query }) => {
  return (dispatch) => {
    dispatch({ type: GET_MORE_UNITS_REQUEST });
    authAPI
      .getUnits(pageNumber, strand, query)
      .then((response) => {
        dispatch({
          type: GET_MORE_UNITS_SUCCESS,
          payload: response,
          request: strand,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_MORE_UNITS_FAILURE,
          payload: error,
        });
      });
  };
};

export const getMoreFoundationalUnitsAction = ({
  pageNumber,
  strand,
  query,
}) => {
  return (dispatch) => {
    dispatch({ type: GET_MORE_FOUNDATIONAL_UNITS_REQUEST });
    authAPI
      .getUnits(pageNumber, strand, query)
      .then((response) => {
        dispatch({
          type: GET_MORE_FOUNDATIONAL_UNITS_SUCCESS,
          payload: response,
          request: strand,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_MORE_FOUNDATIONAL_UNITS_FAILURE,
          payload: error,
        });
      });
  };
};

export const getMoreElearningUnitsAction = ({ pageNumber, strand, query }) => {
  return (dispatch) => {
    dispatch({ type: GET_MORE_ELEARNING_UNITS_REQUEST });
    authAPI
      .getUnits(pageNumber, strand, query)
      .then((response) => {
        dispatch({
          type: GET_MORE_ELEARNING_UNITS_SUCCESS,
          payload: response,
          request: strand,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_MORE_ELEARNING_UNITS_FAILURE,
          payload: error,
        });
      });
  };
};

export const getUnitsAction = (
  pageNumber,
  strand,
  query,
  isBackButtonPressed
) => {
  return (dispatch) => {
    dispatch({ type: GET_UNITS_REQUEST });
    authAPI
      .getUnits(pageNumber, strand, query, isBackButtonPressed)
      .then((response) => {
        dispatch({
          type: GET_UNITS_SUCCESS,
          payload: response,
          request: strand,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_UNITS_FAILURE,
          payload: error,
        });
      });
  };
};

export const getFeaturedAssessmentsActions = (strand) => {
  return (dispatch) => {
    dispatch({ type: GET_FEATURED_ASSESSMENTS_REQUEST });
    authAPI
      .getFeaturedAssessments(strand)
      .then((response) => {
        dispatch({
          type: GET_FEATURED_ASSESSMENTS_SUCCESS,
          payload: response,
          request: strand,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_FEATURED_ASSESSMENTS_FAILURE,
          payload: error,
        });
      });
  };
};

export const getAssessmentsAction = (pageNumber, strand) => {
  return (dispatch) => {
    dispatch({ type: GET_ASSESSMENTS_REQUEST });
    authAPI
      .getAssessments(pageNumber, strand)
      .then((response) => {
        dispatch({
          type: GET_ASSESSMENTS_SUCCESS,
          payload: response,
          request: strand,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_ASSESSMENTS_FAILURE,
          payload: error,
        });
      });
  };
};

export const getLessonsDetailedAction = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_LESSONS_DETAIL_REQUEST });
    authAPI
      .getLessonsDetail(id)
      .then((response) => {
        dispatch({
          type: GET_LESSONS_DETAIL_SUCCESS,
          payload: response,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_LESSONS_DETAIL_FAILURE,
          payload: error,
        });
      });
  };
};

export const getFilterOptionsAction = (strand, resourceType) => {
  return (dispatch) => {
    dispatch({ type: GET_FILTER_OPTIONS_REQUEST });
    authAPI
      .getFilterOptions(strand, resourceType)
      .then((response) => {
        dispatch({
          type: GET_FILTER_OPTIONS_SUCCESS,
          payload: response,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_FILTER_OPTIONS_FAILURE,
          payload: error,
        });
      });
  };
};

export const getCustomFiltersAction = (strand, resourceType, query) => {
  return (dispatch) => {
    dispatch({ type: GET_CUSTOM_FILTERS_REQUEST });
    authAPI
      .getCustomFilters(strand, resourceType, query)
      .then((response) => {
        dispatch({
          type: GET_CUSTOM_FILTERS_SUCCESS,
          payload: response,
          strand: strand,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_CUSTOM_FILTERS_FAILURE,
          payload: error,
        });
      });
  };
};

export const getNewCustomFiltersAction = () => {
  return (dispatch) => {
    dispatch({ type: GET_NEW_CUSTOM_FILTERS_REQUEST });
    authAPI
      .getNewCustomFilters()
      .then((response) => {
        dispatch({
          type: GET_NEW_CUSTOM_FILTERS_SUCCESS,
          payload: response,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_NEW_CUSTOM_FILTERS_FAILURE,
          payload: error,
        });
      });
  };
};

export const clearMyLibraryFiltersAction = () => {
  return (dispatch) =>
    dispatch({
      type: GET_CUSTOM_FILTERS_SUCCESS,
      payload: {},
      strand: null,
      strand_total: null,
      strandless_total: null,
    });
};

export const getCustomLibraryFiltersAction = (
  strand,
  resourceType,
  query,
  page = 1,
  isSearchActive
) => {
  return (dispatch) => {
    dispatch({ type: GET_CUSTOM_FILTERS_REQUEST });
    authAPI
      .getMyLibraryFilters(resourceType, strand, query, page)
      .then((response) => {
        if (response.results.length === 0)
          dispatch({
            type: GET_CUSTOM_FILTERS_SUCCESS,
            payload: response,
            strand: strand,
          });
        if (page === 1) {
          dispatch({
            type: GET_CUSTOM_FILTERS_SUCCESS,
            payload: response,
            strand: strand,
          });
        }
        dispatch({
          type: GET_MY_LIBRARY_SUCCESS,
          payload: response,
          request: resourceType,
          strand_total: !query ? response.total : null,
          isSearchActive,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_CUSTOM_FILTERS_FAILURE,
          payload: error,
        });
      });
  };
};

export const getCustomLibraryFiltersAndDecrementAction = (
  strand,
  resourceType,
  query,
  page = 1
) => {
  return (dispatch) => {
    dispatch({ type: GET_CUSTOM_FILTERS_REQUEST });
    authAPI
      .getMyLibraryFilters(resourceType, strand, query, page)
      .then((response) => {
        dispatch({
          type: GET_CUSTOM_FILTERS_SUCCESS,
          payload: response,
          strand: strand,
        });
        dispatch({
          type: GET_MY_LIBRARY_SUCCESS,
          payload: response,
          request: resourceType,
          strand_total: !query ? response.total : null,
        });
        if (query) dispatch({ type: DECREMENT_CUSTOM_FILTERS_STRAND_TOTAL });
      })
      .catch(function (error) {
        dispatch({
          type: GET_CUSTOM_FILTERS_FAILURE,
          payload: error,
        });
      });
  };
};

export const getFilterStandardsAction = (strand, resourceType) => {
  return (dispatch) => {
    dispatch({ type: GET_FILTER_STANDARDS_REQUEST });
    authAPI
      .getFilterOptions(strand, resourceType)
      .then((response) => {
        dispatch({
          type: GET_FILTER_STANDARDS_SUCCESS,
          payload: response,
          request: strand,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_FILTER_STANDARDS_FAILURE,
          payload: error,
        });
      });
  };
};

export const updateFilterOptions = (filters) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FILTER_OPTIONS,
      payload: filters,
    });
  };
};

export const postAddLessonToLibraryAction = (lid) => {
  return (dispatch) => {
    dispatch({ type: POST_ADD_LESSON_TO_LIBRARY_REQUEST });
    return authAPI
      .postAddLessonToLibrary(lid)
      .then((response) => {
        dispatch({
          type: POST_ADD_LESSON_TO_LIBRARY_SUCCESS,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: POST_ADD_LESSON_TO_LIBRARY_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      });
  };
};

export const postAddCorrelationToLibraryAction = (cid) => {
  return (dispatch) => {
    dispatch({ type: POST_ADD_CORRELATION_TO_LIBRARY_REQUEST });
    return authAPI
      .postAddCorrelationToLibrary(cid)
      .then((response) => {
        dispatch({
          type: POST_ADD_CORRELATION_TO_LIBRARY_SUCCESS,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: POST_ADD_CORRELATION_TO_LIBRARY_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      });
  };
};

export const removeItemFromFolder = (item, folderId) => {
  return (dispatch, getState) => {
    dispatch({ type: GET_MY_LIBRARY_REQUEST });
    return authAPI
      .removeFolderResource(item, folderId)
      .then((_response) => {
        const myLibrary = getState().MyLibrary;
        const mappedFolders = myLibrary?.data?.folders?.results?.map(
          (folder) => {
            if (folder._id.toString() === folderId.toString()) {
              const resourceIndex = folder?.resource_ids?.findIndex(
                (resource) => {
                  return resource.id.toString() === item._id.toString();
                }
              );
              if (resourceIndex !== -1) {
                folder.resource_ids.splice(resourceIndex, 1);
              }
              return folder;
            }
            return folder;
          }
        );
        dispatch({
          type: GET_MY_LIBRARY_SUCCESS,
          payload: {
            results: mappedFolders,
            total: mappedFolders.length,
          },
          request: "folders",
          strandless_total: myLibrary?.strandless_total || null,
        });
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch({
          type: GET_MY_LIBRARY_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      });
  };
};

export const postAddTeachingResourceToLibraryAction = (tid) => {
  return (dispatch) => {
    dispatch({ type: POST_ADD_TEACHING_RESOURCE_TO_LIBRARY_REQUEST });
    return authAPI
      .postAddTeachingResourceToLibrary(tid)
      .then((response) => {
        dispatch({
          type: POST_ADD_TEACHING_RESOURCE_TO_LIBRARY_SUCCESS,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: POST_ADD_TEACHING_RESOURCE_TO_LIBRARY_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      });
  };
};

export const postAddUnitToLibraryAction = (uid) => {
  return (dispatch) => {
    dispatch({ type: POST_ADD_UNIT_TO_LIBRARY_REQUEST });
    return authAPI.postAddUnitToLibrary(uid).then(
      (response) => {
        dispatch({
          type: POST_ADD_UNIT_TO_LIBRARY_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: POST_ADD_UNIT_TO_LIBRARY_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const postAddBookToLibraryAction = (uid) => {
  return (dispatch) => {
    dispatch({ type: POST_ADD_BOOK_TO_LIBRARY_REQUEST });
    return authAPI
      .postAddBookToLibrary(uid)
      .then((response) => {
        dispatch({
          type: POST_ADD_BOOK_TO_LIBRARY_SUCCESS,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: POST_ADD_BOOK_TO_LIBRARY_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      });
  };
};

export const postAddAssessmentToLibraryAction = (uid) => {
  return (dispatch) => {
    dispatch({ type: POST_ADD_ASSESSMENT_TO_LIBRARY_REQUEST });
    return authAPI
      .postAddAssessmentToLibrary(uid)
      .then((response) => {
        dispatch({
          type: POST_ADD_ASSESSMENT_TO_LIBRARY_SUCCESS,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: POST_ADD_ASSESSMENT_TO_LIBRARY_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      });
  };
};

export const getMyLibraryAction = (tab, query = "", page = 1) => {
  return (dispatch) => {
    dispatch({ type: GET_MY_LIBRARY_REQUEST });
    authAPI
      .getMyLibrary(tab, query, page)
      .then((response) => {
        dispatch({
          type: GET_MY_LIBRARY_SUCCESS,
          payload: response,
          request: tab,
          strandless_total: page === 1 ? response.total : null,
        });
        if (response.filters && page === 1)
          dispatch({
            type: GET_CUSTOM_FILTERS_SUCCESS,
            payload: response,
            strandless_total: response.total,
          });
      })
      .catch(function (error) {
        dispatch({
          type: GET_MY_LIBRARY_FAILURE,
          payload: error,
        });
      });
  };
};

export const globalLibrarySearchAction = (query = "", page) => {
  return (dispatch) => {
    dispatch({ type: GET_MY_LIBRARY_REQUEST });
    authAPI
      .libraryGlobalSearch(page, 48, query)
      .then((response) => {
        dispatch({
          type: GET_MY_LIBRARY_SUCCESS,
          payload: response,
          request: "recents",
          strandless_total: page === 1 ? response.total : null,
          isSearchActive: true,
        });
        if (response.filters && page === 1)
          dispatch({
            type: GET_CUSTOM_FILTERS_SUCCESS,
            payload: response,
            strandless_total: response.total,
          });
      })
      .catch(function (error) {
        dispatch({
          type: GET_MY_LIBRARY_FAILURE,
          payload: error,
        });
      });
  };
};

// While we preserve filters on back, we need to make sure any actions done after this
// data was loaded is accounted for. so we must reload it.
export const quietlyInitializeStrandlessAction = (tab) => {
  return (dispatch) => {
    authAPI.getMyLibrary(tab, "", 1).then((response) => {
      dispatch({
        type: QUIETLY_INITIALIZE_STRANDLESS_COUNT,
        strandless_total: response.total,
      });
    });
  };
};

export const getMyLibraryRecentsAction = () => {
  return (dispatch) => {
    dispatch({ type: GET_MY_LIBRARY_RECENTS_REQUEST });
    authAPI
      .getMyLibraryRecents()
      .then((response) => {
        dispatch({
          type: GET_MY_LIBRARY_RECENTS_SUCCESS,
          payload: response,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_MY_LIBRARY_RECENTS_FAILURE,
          payload: error,
        });
      });
  };
};

export const changeUnitHeadingAction = (heading) => {
  return (dispatch) => {
    dispatch({
      type: GET_HEADING,
      payload: heading,
    });
  };
};

export const changeUnitDescriptionAction = (description) => {
  return (dispatch) => {
    dispatch({
      type: GET_DESCRIPTION,
      payload: description,
    });
  };
};

export const saveNewUnitIdAction = (id) => {
  return (dispatch) => {
    dispatch({
      type: NEW_UNIT_ID,
      payload: id,
    });
  };
};

export const saveLocation = (path) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_LOCATION,
      payload: path,
    });
  };
};

export const saveUnitAction = (name, introduction, lessson_ids) => {
  return (dispatch) => {
    dispatch({ type: SAVE_UNIT_REQUEST });
    authAPI
      .saveUnit(name, introduction, lessson_ids)
      .then((response) => {
        if (response.status === 201) {
          dispatch({
            type: SAVE_UNIT_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: SAVE_UNIT_FAILURE,
            payload: {
              error: response,
            },
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: SAVE_UNIT_FAILURE,
          payload: error,
        });
      });
  };
};

export const updateUnitAction = (
  id,
  name,
  introduction,
  lessson_ids,
  image
) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_UNIT_REQUEST });
    authAPI
      .updateUnit(id, name, introduction, lessson_ids, image)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: UPDATE_UNIT_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: UPDATE_UNIT_FAILURE,
            payload: {
              error: response,
            },
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: UPDATE_UNIT_FAILURE,
          payload: error,
        });
      });
  };
};

export const postFiltersAction = (tab) => {
  return (dispatch) => {
    dispatch({ type: POST_FILTER_REQUEST });
    authAPI
      .getMyLibrary(tab)
      .then((response) => {
        dispatch({
          type: POST_FILTER_SUCCESS,
          payload: response,
          request: tab,
        });
      })
      .catch(function (error) {
        dispatch({
          type: POST_FILTER_FAILURE,
          payload: error,
        });
      });
  };
};

export const getDefinitionAction = (word) => {
  return (dispatch) => {
    dispatch({ type: GET_DEFINITION_REQUEST });
    authAPI
      .wordDefinition(word)
      .then((response) => {
        dispatch({
          type: GET_DEFINITION_SUCCESS,
          payload: response,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_DEFINITION_FAILURE,
          payload: error,
        });
      });
  };
};

export const getAnnotationsAction = (type, id) => {
  return (dispatch) => {
    dispatch({ type: GET_ANNOTATIONS_REQUEST });
    authAPI
      .getAnnotations(type, id)
      .then((response) => {
        dispatch({
          type: GET_ANNOTATIONS_SUCCESS,
          payload: response,
          request: "annotations",
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_ANNOTATIONS_FAILURE,
          payload: error,
        });
      });
  };
};

export const addAnnotationAction = (
  itemType,
  annotationType,
  id,
  data,
  cbId
) => {
  return (dispatch) => {
    dispatch({ type: ADD_ANNOTATION_REQUEST });
    authAPI
      .addAnnotation(itemType, annotationType, id, data)
      .then((response) => {
        if (response.status === 201) {
          dispatch({
            type: ADD_ANNOTATION_SUCCESS,
            payload: response,
            annotationType: annotationType,
            itemType: itemType,
          });

          if (cbId !== undefined) {
            cbId(response.data._id);
          }
        } else {
          dispatch({
            type: ADD_ANNOTATION_FAILURE,
            payload: {
              error: response,
            },
            annotationType: annotationType,
            itemType: itemType,
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: ADD_ANNOTATION_FAILURE,
          payload: error,
        });
      });
  };
};

export const editAnnotationAction = (id, data) => {
  return (dispatch) => {
    dispatch({ type: EDIT_ANNOTATION_REQUEST });
    authAPI
      .editAnnotation(id, data)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: EDIT_ANNOTATION_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: EDIT_ANNOTATION_FAILURE,
            payload: {
              error: response,
            },
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: EDIT_ANNOTATION_FAILURE,
          payload: error,
        });
      });
  };
};

export const deleteAnnotationAction = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_ANNOTATION_REQUEST });
    authAPI
      .deleteAnnotation(id)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: DELETE_ANNOTATION_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: DELETE_ANNOTATION_FAILURE,
            payload: {
              error: response,
            },
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_ANNOTATION_FAILURE,
          payload: error,
        });
      });
  };
};

export const getScheduledTasksAction = (view, myDate) => {
  return (dispatch) => {
    dispatch({ type: GET_SCHEDULED_TASKS_REQUEST });
    authAPI
      .getScheduledTasks(view, myDate)
      .then((response) => {
        dispatch({
          type: GET_SCHEDULED_TASKS_SUCCESS,
          payload: response,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_SCHEDULED_TASKS_FAILURE,
          payload: error,
        });
      });
  };
};

export const addingFiltersAction = (filter, queryObject = "") => {
  return (dispatch) => {
    dispatch({
      type: FILTER_SCALING,
      payload: filter,
      queryObject: queryObject,
    });
  };
};

export const getCustomUnitsAction = (id, query, page, flag = 1) => {
  return (dispatch) => {
    dispatch({ type: GET_CUSTOM_UNITS_REQUEST });
    authAPI.getCustomUnits(id, query, page).then(
      (response) => {
        dispatch({
          type: GET_CUSTOM_UNITS_SUCCESS,
          payload: response,
          query: query,
          flag: flag,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: GET_CUSTOM_UNITS_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const removableFilterOptions = (filterObj) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_FILTER_CHECK,
      payload: filterObj,
    });
  };
};

export const checkUnitHeadingAction = (headingValue) => {
  return (dispatch) => {
    dispatch({
      type: CHECK_HEADING,
      payload: headingValue,
    });
  };
};

export const checkUnitDescriptionAction = (descriptionValue) => {
  return (dispatch) => {
    dispatch({
      type: CHECK_DESCRIPTION,
      payload: descriptionValue,
    });
  };
};

export const postScheduledTasksAction = (myDate, id, type) => {
  return (dispatch) => {
    dispatch({ type: POST_SCHEDULED_TASK_REQUEST });
    return authAPI.postScheduledTasks(myDate, id, type).then(
      (response) => {
        dispatch({
          type: POST_SCHEDULED_TASK_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: POST_SCHEDULED_TASK_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const clearAllFiltersAction = (clearStatus) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_FILTERS,
      payload: clearStatus,
    });
  };
};

export const getUnitsDetailAction = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_UNITS_DETAIL_REQUEST });
    authAPI
      .getUnitsDetail(id)
      .then((response) => {
        dispatch({
          type: GET_UNITS_DETAIL_SUCCESS,
          payload: response,
          id: "" + id,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_UNITS_DETAIL_FAILURE,
          payload: error,
        });
      });
  };
};

export const getSingleUnitDetailsAction = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_SINGLE_UNIT_DETAILS_REQUEST });
    authAPI
      .getSingleUnitDetails(id)
      .then((response) => {
        dispatch({
          type: GET_SINGLE_UNIT_DETAILS_SUCCESS,
          payload: response,
          id: "" + id,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_SINGLE_UNIT_DETAILS_FAILURE,
          payload: error,
        });
      });
  };
};

export const orderScheduledTasksAction = (obj) => {
  return (dispatch) => {
    dispatch({ type: ORDER_SCHEDULED_TASK_REQUEST });
    return authAPI.orderScheduledTasks(obj).then(
      (response) => {
        dispatch({
          type: ORDER_SCHEDULED_TASK_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: ORDER_SCHEDULED_TASK_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const getUsersAction = (
  userType,
  search,
  districtId = 0,
  page,
  myStudents,
  flag = 1,
  itemType,
  itemId
) => {
  return (dispatch) => {
    dispatch({ type: GET_USERS_REQUEST });
    return authAPI
      .getUsers(
        userType,
        search,
        districtId,
        page,
        myStudents,
        itemType,
        itemId
      )
      .then(
        (response) => {
          let tmpUserType = userType;
          if (userType !== "Student") {
            tmpUserType = "combinedList";
          }
          if (userType === "System+Admin&userType=Content+Editor") {
            tmpUserType = "admin";
          }
          userType = tmpUserType;
          dispatch({
            type: GET_USERS_SUCCESS,
            payload: response,
            userType: userType,
            search: search,
            flag: flag,
          });
          return response;
        },
        function (error) {
          dispatch({
            type: GET_USERS_FAILURE,
            payload: error,
          });
          return error;
        }
      );
  };
};

export const getFoldersAction = (
  pageNumber,
  searchString,
  queryString,
  is_lite
) => {
  return (dispatch) => {
    dispatch({ type: GET_FOLDERS_REQUEST });
    return authAPI
      .getFolders(pageNumber, searchString, queryString, is_lite)
      .then(
        (response) => {
          return response;
        },
        function (error) {
          dispatch({
            type: GET_FOLDERS_FAILURE,
            payload: error,
          });
          return error;
        }
      );
  };
};

export const deleteUserAction = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_USER_REQUEST });
    return authAPI.deleteUser(id).then(
      (response) => {
        dispatch({
          type: DELETE_USER_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: DELETE_USER_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};
export const getDistrictsAction = (search, page = 1, flag = 1) => {
  return (dispatch) => {
    dispatch({ type: GET_DISTRICTS_REQUEST });
    return authAPI.getDistricts(search, page).then(
      (response) => {
        dispatch({
          type: GET_DISTRICTS_SUCCESS,
          payload: response,
          search: search,
          flag: flag,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: GET_DISTRICTS_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const deleteDistrictAction = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_DISTRICT_REQUEST });
    return authAPI.deleteDistrict(id).then(
      (response) => {
        dispatch({
          type: DELETE_DISTRICT_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: DELETE_DISTRICT_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const postUserAction = (userObj) => {
  return (dispatch) => {
    dispatch({ type: POST_USER_REQUEST });
    return authAPI.postUser(userObj).then(
      (response) => {
        dispatch({
          type: POST_USER_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: POST_USER_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const putLicenseAction = (user_id, userObj) => {
  return (dispatch) => {
    dispatch({ type: PUT_USER_REQUEST });
    return authAPI
      .putLicense(user_id, userObj)
      .then((response) => {
        if (response.message === "Update Successful") {
          dispatch({
            type: PUT_USER_SUCCESS,
            payload: response,
          });
          return response;
        } else {
          dispatch({
            type: PUT_USER_FAILURE,
            payload: response,
          });
        }
        return response;
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: PUT_USER_FAILURE,
            payload: {
              error: error.response.data,
            },
          });
          return error.response.data;
        }
      });
  };
};

// Update existing users license
export const updateLicenseAction = (userObj) => {
  return (dispatch) => {
    dispatch({ type: PUT_USER_REQUEST });
    return authAPI
      .updateLicense(userObj)
      .then((response) => {
        if (response.message === "Update Successful") {
          dispatch({
            type: PUT_USER_SUCCESS,
            payload: response,
          });
          return response;
        } else {
          dispatch({
            type: PUT_USER_FAILURE,
            payload: response,
          });
        }
        return response;
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: PUT_USER_FAILURE,
            payload: {
              error: error.response.data,
            },
          });
          return error.response.data;
        }
      });
  };
};

export const putUserAction = (user_id, userObj) => {
  return (dispatch) => {
    dispatch({ type: PUT_USER_REQUEST });
    return authAPI
      .putUser(user_id, userObj)
      .then((response) => {
        if (response.message === "Update Successful") {
          dispatch({
            type: PUT_USER_SUCCESS,
            payload: response,
          });
          return response;
        } else {
          dispatch({
            type: PUT_USER_FAILURE,
            payload: response,
          });
        }
        return response;
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: PUT_USER_FAILURE,
            payload: {
              error: error.response.data,
            },
          });
          return error.response.data;
        }
      });
  };
};

export const postDistrictAction = (districtObj) => {
  return (dispatch) => {
    dispatch({ type: POST_DISTRICT_REQUEST });
    return authAPI.postDistrict(districtObj).then(
      (response) => {
        dispatch({
          type: POST_DISTRICT_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: POST_DISTRICT_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const putDistrictAction = (district_id, districtObj) => {
  return (dispatch) => {
    dispatch({ type: PUT_DISTRICT_REQUEST });
    return authAPI.putDistrict(district_id, districtObj).then(
      (response) => {
        dispatch({
          type: PUT_DISTRICT_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: PUT_DISTRICT_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const importUsersAction = (userType, districtId, userData) => {
  return (dispatch) => {
    dispatch({ type: IMPORT_USERS_REQUEST });
    return authAPI.postImportUsers(userType, districtId, userData).then(
      (response) => {
        dispatch({
          type: IMPORT_USERS_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: IMPORT_USERS_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const getMyDetailsAction = () => {
  return (dispatch) => {
    dispatch({ type: GET_MY_DETAILS_REQUEST });
    return authAPI.getMyDetails().then(
      (response) => {
        dispatch({
          type: GET_MY_DETAILS_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: GET_MY_DETAILS_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const getResourceDetailsAction = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_RESOURCES_DETAILS_REQUEST });
    return authAPI.getResourceDetails(id).then(
      (response) => {
        dispatch({
          type: GET_RESOURCES_DETAILS_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: GET_RESOURCES_DETAILS_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const getBookDetailsAction = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_BOOK_DETAILS_REQUEST });
    return authAPI.getBookDetails(id).then(
      (response) => {
        dispatch({
          type: GET_BOOK_DETAILS_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: GET_BOOK_DETAILS_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const getBookPreviewStatusAction = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_BOOK_PREVIEW_REQUEST });
    return authAPI.getBookPreviewStatus(id).then(
      (response) => {
        dispatch({
          type: GET_BOOK_PREVIEW_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: GET_BOOK_PREVIEW_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const getAssessmentDetailsAction = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_ASSESSMENT_DETAILS_REQUEST });
    return authAPI.getAssessmentDetails(id).then(
      (response) => {
        dispatch({
          type: GET_ASSESSMENT_DETAILS_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: GET_ASSESSMENT_DETAILS_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const getCorrelationDetailsAction = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_CORRELATION_DETAILS_REQUEST });
    return authAPI.getCorrelationDetails(id).then(
      (response) => {
        dispatch({
          type: GET_CORRELATION_DETAILS_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: GET_CORRELATION_DETAILS_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const getBooksAction = (pageNumber, queryString) => {
  return (dispatch) => {
    dispatch({ type: GET_BOOKS_REQUEST });
    return authAPI.getBooks(pageNumber, queryString).then(
      (response) => {
        dispatch({
          type: GET_BOOKS_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: GET_BOOKS_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};
export const getGroupsAction = (search = "") => {
  return (dispatch) => {
    dispatch({ type: GET_GROUPS_REQUEST });
    return authAPI.getGroups(search).then(
      (response) => {
        dispatch({
          type: GET_GROUPS_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: GET_GROUPS_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};
export const getUserAction = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_USER_REQUEST });
    authAPI
      .getUser(id)
      .then((response) => {
        dispatch({
          type: GET_USER_SUCCESS,
          payload: response,
          id: "" + id,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_USER_FAILURE,
          payload: error,
        });
      });
  };
};

export const postGroupAction = (groupObj) => {
  return (dispatch) => {
    dispatch({ type: POST_GROUP_REQUEST });
    return authAPI.postGroup(groupObj).then(
      (response) => {
        dispatch({
          type: POST_GROUP_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: POST_GROUP_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const getGroupByIdAction = (gid) => {
  return (dispatch) => {
    dispatch({ type: GET_GROUP_BY_ID_REQUEST });
    authAPI
      .getGroupById(gid)
      .then((response) => {
        dispatch({
          type: GET_GROUP_BY_ID_SUCCESS,
          payload: response,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_GROUP_BY_ID_FAILURE,
          payload: error,
        });
      });
  };
};

// type is ignored
export const putGroupImportStudents = (type, groupId, groupObj) => {
  return (dispatch) => {
    dispatch({ type: IMPORT_USERS_REQUEST });
    return authAPI.putGroupImportStudents(groupId, groupObj).then(
      (response) => {
        dispatch({
          type: IMPORT_USERS_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: IMPORT_USERS_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const putGroupAction = (groupId, groupObj) => {
  return (dispatch) => {
    dispatch({ type: PUT_GROUP_REQUEST });
    return authAPI.putGroup(groupId, groupObj).then(
      (response) => {
        dispatch({
          type: PUT_GROUP_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: PUT_GROUP_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const addUserToGroupAction = (groupId, Obj) => {
  return (dispatch) => {
    dispatch({ type: ADD_USER_TO_GROUP_REQUEST });
    return authAPI.addUserToGroup(groupId, Obj).then(
      (response) => {
        dispatch({
          type: ADD_USER_TO_GROUP_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: ADD_USER_TO_GROUP_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const removeUserFromGroupAction = (groupId, Obj) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_USER_FROM_GROUP_REQUEST });
    return authAPI.removeUserFromGroup(groupId, Obj).then(
      (response) => {
        dispatch({
          type: REMOVE_USER_FROM_GROUP_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: REMOVE_USER_FROM_GROUP_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const getRecommendedForYouAction = () => {
  return (dispatch) => {
    dispatch({ type: GET_RECOMMENDED_FOR_YOU_REQUEST });
    authAPI
      .getRecommendedForYou()
      .then((response) => {
        dispatch({
          type: GET_RECOMMENDED_FOR_YOU_SUCCESS,
          payload: response,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_RECOMMENDED_FOR_YOU_FAILURE,
          payload: error,
        });
      });
  };
};

export const logoutAction = () => {
  return (dispatch) => {
    dispatch({ type: LOGOUT_REQUEST });
    return authAPI.logout().then(
      (response) => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: LOGOUT_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const getGoalTypesAction = () => {
  return (dispatch) => {
    dispatch({ type: GET_GOAL_TYPES_REQUEST });
    return authAPI.getGoalTypes().then(
      (response) => {
        dispatch({
          type: GET_GOAL_TYPES_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: GET_GOAL_TYPES_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const getReferralTypesAction = () => {
  return (dispatch) => {
    dispatch({ type: GET_REFERRAL_TYPES_REQUEST });
    return authAPI.getReferralTypes().then(
      (response) => {
        dispatch({
          type: GET_REFERRAL_TYPES_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: GET_REFERRAL_TYPES_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const deleteGroupAction = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_GROUP_REQUEST });
    return authAPI.deleteGroup(id).then(
      (response) => {
        dispatch({
          type: DELETE_GROUP_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: DELETE_GROUP_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const getElearningSkillsAction = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_ELEARNING_SKILLS_REQUEST });
    return authAPI.getSkills(id).then(
      (response) => {
        dispatch({
          type: GET_ELEARNING_SKILLS_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: GET_ELEARNING_SKILLS_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const reviewToken = (token) => {
  return (dispatch) => {
    dispatch({ type: REVIEW_TOKEN_REQUEST });
    return authAPI.reviewToken(token).then(
      (response) => {
        dispatch({
          type: REVIEW_TOKEN_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: REVIEW_TOKEN_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const recoverPasswordAction = (email) => {
  return (dispatch) => {
    dispatch({ type: RECOVER_PASSWORD_REQUEST });
    return authAPI.recoverPassword(email).then(
      (response) => {
        dispatch({
          type: RECOVER_PASSWORD_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: RECOVER_PASSWORD_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const resetPasswordAction = (password, token) => {
  return (dispatch) => {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    return authAPI.resetPassword(password, token).then(
      (response) => {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: RESET_PASSWORD_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const getUserAssignmentsAction = (
  user_id,
  pageNumber,
  isComplete,
  isDue,
  date,
  teacher_id = 0,
  flag = 1
) => {
  return (dispatch) => {
    dispatch({ type: GET_USER_ASSIGNMENTS_REQUEST });
    authAPI
      .getUserAssignments(
        user_id,
        pageNumber,
        isComplete,
        isDue,
        date,
        teacher_id
      )
      .then((response) => {
        dispatch({
          type: GET_USER_ASSIGNMENTS_SUCCESS,
          payload: response,
          flag: flag,
          isComplete: isComplete,
          isDue: isDue,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_USER_ASSIGNMENTS_FAILURE,
          payload: error,
        });
      });
  };
};

export const deleteUserAssignmentAction = (assignment_id, student_id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_USER_ASSIGNMENTS_REQUEST });

    authAPI
      .deleteUserAssignment(assignment_id, student_id)
      .then((response) => {
        dispatch({
          type: DELETE_USER_ASSIGNMENTS_SUCCESS,
          payload: response,
        });
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_USER_ASSIGNMENTS_FAILURE,
          payload: error,
        });
      });
  };
};

export const resetUserPasswordAction = (user_id, isPicturePassword) => {
  return (dispatch) => {
    dispatch({ type: RESET_USER_PASSWORD_REQUEST });
    return authAPI.resetUserPassword(user_id, isPicturePassword).then(
      (response) => {
        dispatch({
          type: RESET_USER_PASSWORD_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: RESET_USER_PASSWORD_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const getUserDetailsByIdAction = (user_id) => {
  return (dispatch) => {
    dispatch({ type: GET_USER_DETAILS_BY_ID_REQUEST });
    return authAPI.getUserDetailsById(user_id).then(
      (response) => {
        dispatch({
          type: GET_USER_DETAILS_BY_ID_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: GET_USER_DETAILS_BY_ID_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const completeAssignmentAction = (asst_id, is_complete) => {
  return (dispatch) => {
    dispatch({ type: COMPLETE_ASSIGNMENT_REQUEST });
    return authAPI.completeAssignment(asst_id, is_complete).then(
      (response) => {
        dispatch({
          type: COMPLETE_ASSIGNMENT_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: COMPLETE_ASSIGNMENT_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const postAssignmentAction = (sid, assign_object) => {
  return (dispatch) => {
    dispatch({ type: POST_ASSIGNMENT_REQUEST });
    return authAPI.postAssignment(sid, assign_object).then(
      (response) => {
        dispatch({
          type: POST_ASSIGNMENT_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: POST_ASSIGNMENT_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const postFolderAssignmentAction = (fid, assign_object) => {
  return (dispatch) => {
    dispatch({ type: POST_ASSIGNMENT_REQUEST });
    return authAPI.postFolderAssignment(fid, assign_object).then(
      (response) => {
        dispatch({
          type: POST_ASSIGNMENT_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: POST_ASSIGNMENT_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const saveAssignmentItemAction = (item_type, item_id) => {
  return (dispatch) => {
    dispatch({
      type: ASSIGNMENT_ITEM,
      payload: { type: item_type, id: item_id },
    });
  };
};

export const savePlannerDateAction = (date = "") => {
  return (dispatch) => {
    dispatch({
      type: SAVE_PLANNER_DATE,
      payload: { date: date },
    });
  };
};

export const lessonAddedSuccessAction = (lessonName, status = false) => {
  return (dispatch) => {
    dispatch({
      type: ADD_LESSON_SUCCESS,
      payload: { success: status, lessonName: lessonName },
    });
  };
};
/*  Load steps parameter into state. Useful on pageload to allow Footer or Header to start Joyride.
 */
export const joyrideLoadSteps = (steps) => {
  return (dispatch) => {
    dispatch({
      type: JOYRIDE_LOAD_STEPS,
      payload: steps,
    });
  };
};

/*  Start Joyride from index 0 to endIndex with stepArray provided.
    If steps is null, state.steps or default steps are used.
*/
export const resetJoyride = (steps, endIndex) => {
  return (dispatch) => {
    dispatch({
      type: RESET_JOYRIDE,
      steps: steps,
      endIndex: endIndex,
    });
  };
};

/*  This is primarily for the Footer to signal other components to prepare the UI for Joyride.
    Another component should check for the associated prop and call startJoyride.
*/
export const joyrideIntentToStart = (bool = true) => {
  return (dispatch) => {
    dispatch({
      type: JOYRIDE_INTENT,
      payload: bool,
    });
  };
};

export const startJoyride = () => {
  return (dispatch) => {
    dispatch({
      type: START_JOYRIDE,
    });
  };
};

export const stopJoyride = () => {
  return (dispatch) => {
    dispatch({
      type: JOYRIDE_STOP,
    });
  };
};

export const startJoyrideAtIndex = (start, end) => {
  return (dispatch) => {
    dispatch({
      type: JOYRIDE_START_AT_INDEX,
      startIndex: start,
      endIndex: end,
    });
  };
};

/*  Run Joyride with steps parameter from indexes start to end.
 */
export const setAndStartJoyride = (steps, start = 0, end = 0) => {
  return (dispatch) => {
    dispatch({
      type: JOYRIDE_SET_STEPS_AND_START,
      payload: steps,
      startIndex: start,
      endIndex: end,
    });
  };
};

/*  Increment Joyride's stepIndex in state.
 */
export const nextJoyrideStep = () => {
  return (dispatch) => {
    dispatch({
      type: JOYRIDE_NEXT_STEP,
    });
  };
};

export const insertJoyrideStep = (stepObject) => {
  return (dispatch) => {
    dispatch({
      type: JOYRIDE_INSERT_STEP,
      payload: stepObject,
    });
  };
};

export const launchTourModal = () => {
  return (dispatch) => {
    dispatch({
      type: DISPLAY_TOUR_MODAL,
    });
  };
};

export const clearTourModal = () => {
  return (dispatch) => {
    dispatch({
      type: HIDE_TOUR_MODAL,
    });
  };
};

/*  Clear Joyride's state. Intended for use in JoyrideCustomComponent only.
 */
export const resetResetJoyride = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_RESET_JOYRIDE,
    });
  };
};

export const allowClicksThruHere = (bool) => {
  return (dispatch) => {
    dispatch({
      type: ENABLE_JOYRIDE_CLICKS,
      payload: bool,
    });
  };
};

export const adminResetPassword = (user_id) => {
  return (dispatch) => {
    dispatch({ type: ADMIN_RESET_PASSWORD_REQUEST });
    return authAPI.adminResetPassword(user_id).then(
      (response) => {
        dispatch({
          type: ADMIN_RESET_PASSWORD_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: ADMIN_RESET_PASSWORD_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const getLibraryFolderNotification = () => {
  return (dispatch) => {
    dispatch({ type: GET_FOLDER_NOTIFICATION_REQUEST });
    return authAPI.getFolderNotifications().then(
      (response) => {
        dispatch({
          type: GET_FOLDER_NOTIFICATION_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: GET_FOLDER_NOTIFICATION_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const dismissLibraryFolderNotification = (folderIds) => {
  return (dispatch) => {
    dispatch({ type: POST_FOLDER_NOTIFICATION_REQUEST });
    return authAPI.dismissFolderNotifications(folderIds).then(
      (response) => {
        dispatch({
          type: POST_FOLDER_NOTIFICATION_SUCCESS,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: POST_FOLDER_NOTIFICATION_FAILURE,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const eReaderModeEventAction = (mode) => {
  return (dispatch) => {
    dispatch({
      type: EREADER_MODE_EVENT,
      mode: mode,
    });
  };
};

export const eReaderDeleteEventAction = () => {
  return (dispatch) => {
    dispatch({
      type: EREADER_DELETE_EVENT,
    });
  };
};

export const eReaderAddBookmarkEventAction = (bookId, pageNumber) => {
  return (dispatch) => {
    return authAPI.addBookmark(bookId, pageNumber).then(
      (response) => {
        dispatch({
          type: EREADER_ADD_BOOKMARK_EVENT,
          payload: response,
          pageNumber: pageNumber,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: EREADER_ADD_BOOKMARK_EVENT,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const eReaderDeleteBookmarkEventAction = (bookmarkId) => {
  return (dispatch) => {
    return authAPI.deleteBookmark(bookmarkId).then(
      (response) => {
        dispatch({
          type: EREADER_DELETE_BOOKMARK_EVENT,
          payload: response,
          bookmarkId: bookmarkId,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: EREADER_DELETE_BOOKMARK_EVENT,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const eReaderGetBookmarksEventAction = (bookId) => {
  return (dispatch) => {
    return authAPI.getBookmarks(bookId).then(
      (response) => {
        dispatch({
          type: EREADER_GET_BOOKMARKS_EVENT,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: EREADER_GET_BOOKMARKS_EVENT,
          payload: error,
        });
        return error;
      }
    );
  };
};

export const eReaderSetCurrentPageEventAction = (currentPage) => {
  return (dispatch) => {
    dispatch({
      type: EREADER_SET_CURRENT_PAGE_EVENT,
      currentPage: currentPage,
    });
  };
};

export const eReaderSetTotalPagesEventAction = (totalPages) => {
  return (dispatch) => {
    dispatch({
      type: EREADER_SET_TOTAL_PAGES_EVENT,
      totalPages: totalPages,
    });
  };
};

export const eReaderSetPageModeEventAction = (pageMode) => {
  return (dispatch) => {
    dispatch({
      type: EREADER_SET_PAGE_MODE_EVENT,
      pageMode: pageMode,
    });
  };
};

export const eReaderSetBookLoadedEventAction = (bookLoaded) => {
  return (dispatch) => {
    dispatch({
      type: EREADER_SET_BOOK_LOADED,
      bookLoaded: bookLoaded,
    });
  };
};

export const postSAMLData = (itemId, itemType) => {
  return (dispatch) => {
    dispatch({ type: POST_SAML_DATA });
    return authAPI
      .samlJSON(itemId, itemType)
      .then((response) => {
        dispatch({
          type: POST_SAML_DATA_SUCCESS,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: POST_SAML_DATA_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      });
  };
};
export const getResourceAppendicesDetails = (unit_id, lesson_id) => {
  return (dispatch) => {
    return authAPI.getResourceDetails(unit_id, lesson_id).then(
      (response) => {
        dispatch({
          type: GET_RESOURCE_TYPE_FOR_APPENDICES,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: GET_RESOURCE_TYPE_FOR_APPENDICES,
          payload: error,
        });
        return error;
      }
    );
  };
};
export const getAppendicesDetailsbyId = (unit_id) => {
  return (dispatch) => {
    return authAPI.getAppendicesDetailsbyId(unit_id).then(
      (response) => {
        dispatch({
          type: GET_DETAILS_FOR_APPENDICES,
          payload: response,
        });
        return response;
      },
      function (error) {
        dispatch({
          type: GET_DETAILS_FOR_APPENDICES,
          payload: error,
        });
        return error;
      }
    );
  };
};
