import React from 'react'
import AssistantTooltipComponent from '../../components/JoyrideCustomComponent/AssistantTooltipComponent'

const styleObject = {
  mainColor: '#CACACA',
  width: '650px',
  height: '50px',
  overflow: 'wrap',
  borderRadius: 11,
  backgroundColor: 'white',
  beacon: {
    inner: '#a350f0',
    outer: '#a350f0',
  },
  close: {
    display: 'none'
  },
  button: {
    display: 'none'
  },
  skip: {
    marginTop: 20,
  },
  back: {
    display: 'none'
  }
}
const styleObjectWithExitBtn = {
  mainColor: '#CACACA',
  width: '650px',
  height: '50px',
  overflow: 'wrap',
  borderRadius: 11,
  backgroundColor: 'white',
  beacon: {
    inner: '#a350f0',
    outer: '#a350f0',
  },
  close: {
    display: 'none'
  },
  button: {
    display: 'none'
  },
  skip: {
    marginTop: 20,
    borderRadius: 8,
    backgroundColor: '#009BBD',
    color: 'white'
  },
  back: {
    display: 'none'
  }
}

const styleObjectWithNextBtn = {
  mainColor: '#CACACA',
  width: 600,
  height: '100px',
  overflow: 'wrap',
  borderRadius: 4,
  backgroundColor: 'white',
  beacon: {
    inner: '#a350f0',
    outer: '#a350f0',
  },
  close: {
    display: 'none'
  },
  button: {
    color: '#333333',
    marginTop: '20px',
    backgroundColor: 'white'
  },
  skip: {
    marginTop: 20
  },
  back: {
    display: 'none'
  }
}

export default {
  steps: [
      {
        title: "Units - Adding to Library",
          text: <AssistantTooltipComponent
                    imgWidth={100}
                    imgHeight={130}
                    tooltipText={`
                      <p>
                        Welcome to the Unit Overview!
                        Here you can see the complete listing of lessons contained in this Unit of Study.
                      </p>
                      <p>
                        Fundamentals Unlimited also informs you of the standards addressed in each lesson.
                        Before customizing the unit, you will add it to your library.
                        To do so, click the <strong>Add</strong> icon in the upper right-hand corner.
                      </p>
                      `} />,
          selector: '#joyride-selector-add-unit',
          position: 'bottom-right',
          style: {
            ...styleObject,
          }
      },
      {
          title: "Units - Customizing",
          text: <AssistantTooltipComponent
                    imgWidth={100}
                    imgHeight={130}
                    tooltipText={`
                      <p>
                        Excellent! Fundamentals Unlimited provides enormous flexibility in customizing Units and allows you to personalize student-centered learning and differentiate instruction.
                      </p>
                      <p>You will be able to add and remove lessons, reorder and move lessons into different sequences, and schedule lessons and tasks on your planner. Any changes you make will be saved automatically.</p>
                      <p>To begin, click the <strong>Edit</strong> icon in the upper right-hand corner.</p>
                      `} />,
          selector: '#joyride-selector-add-unit',
          position: 'bottom-right',
          style: styleObject
      },
      {
          title: "Editing Details",
          text: <AssistantTooltipComponent
                    imgWidth={100}
                    imgHeight={130}
                    tooltipText={`
                      Here you will be able to rename this unit or modify its description.
                      `} />,
          selector: '#joyride-selector-unit-sidebar',
          position: 'left',
          style: styleObjectWithNextBtn,

      },
      {
          title: "Adding Lessons",
          text: <AssistantTooltipComponent
                    imgWidth={100}
                    imgHeight={130}
                    tooltipText={`
                      <p>
                        This button allows you to add lessons to this unit.
                        Adding lessons can truly allow you to optimize student-centered learning.
                        Do you need lessons at different grade or skill levels?
                        Would you like to reinforce or emphasize specific skills or strategies?
                      </p>
                      <p>
                        After you click the <strong>Add Lessons</strong> icon, you will be able to simply type what you are looking for into the search bar.
                        As you type, the predictive search will narrow your search results.
                        If you see something you like, you can use the <strong>Plus</strong> icon to add it.
                      </p>
                      `} />,
          selector: '#joyride-selector-unit-add-note',
          position: 'bottom-right',
          style: {
            ...styleObjectWithNextBtn,
            hole: {
              marginLeft: '10px'
            }
          },
          allowClicksThruHere: false
      },
      {
          title: "Reordering Lessons",
          text: <AssistantTooltipComponent
                    imgWidth={100}
                    imgHeight={130}
                    tooltipText={`
                      <p>The drag handle will allow you to reorder and re-sequence lessons.</p>
                      `} />,
          selector: '#joyride-select-child-sortable-units :first-child > .icon-icon-drag',
          position: 'bottom-left',
          style: styleObjectWithNextBtn,

      },
      {
          title: "Remove Lessons",
          text: <AssistantTooltipComponent
                    imgWidth={100}
                    imgHeight={130}
                    tooltipText={`
                      <p>This button will allow you to remove a lesson.</p>
                      `} />,
          selector: '#joyride-select-child-sortable-units :first-child > .icon-icon-trash',
          position: 'bottom-right',
          style: {
            ...styleObjectWithNextBtn,
            hole: {
              marginLeft: '3px'
            }
          },
      },
      {
          title: "Viewing Lessons",
          text: <AssistantTooltipComponent
                    imgWidth={100}
                    imgHeight={130}
                    tooltipText={`
                      <p>This lesson can be viewed by clicking on the view icon. The lesson will open in a new tab.</p>
                      `} />,
          selector: '#joyride-select-child-sortable-units :first-child > .icon--view',
          position: 'bottom-right',
          style: {
            ...styleObjectWithNextBtn,
            hole: {
              marginLeft: '10px'
            }
          },

      },
      {
          title: "Exiting Edit Mode",
          text: <AssistantTooltipComponent
                    imgWidth={100}
                    imgHeight={130}
                    tooltipText={`
                      <p>Once you are done with your customizations, you will click this button to return to viewing mode.</p>
                      <p>Note: You may customize your unit as often as you would like. In fact, we encourage you to do so. Responsive teaching is absolutely critical.</p>
                      <p>
                          That's it for this tour!
                          I certainly hope it was helpful and informative.
                          Tours can be revisited and accessed at any time by clicking the <strong>Help</strong> icon in the lower right-hand corner of the screen.
                      </p>
                      `} />,
          selector: '#joyride-selector-icon-checkbox',
          position: 'bottom-right',
          style: styleObjectWithExitBtn
      }
    ]
  }
