import React from "react";

export const FilterMenuButtons = ({
  newCustomFilters,
  customFilterClicked,
  checkActive,
  location,
  strand,
}) => {
  return (
    <div
      className={`custom-filters-container ${
        location.pathname.endsWith("all") ? "filters-disabled" : ""
      }`}
    >
      {newCustomFilters?.filter?.[strand]?.grades?.map((filter) => (
        <div
          onClick={() => customFilterClicked("grades", filter.value, filter.id)}
          key={filter.id}
          className={
            checkActive("grades", filter.id)
              ? "single-filter-container-active"
              : "single-filter-container"
          }
        >
          <p>Grade {filter?.value}</p>
        </div>
      ))}
      {newCustomFilters?.filter?.[strand]?.resourceTypes?.map((filter) => (
        <div
          onClick={() =>
            customFilterClicked("resourceTypes", filter.value, filter.id)
          }
          key={filter.id}
          className={
            checkActive("resourceTypes", filter.id)
              ? "single-filter-container-active"
              : "single-filter-container"
          }
        >
          <p>{filter?.value}</p>
        </div>
      ))}
      {newCustomFilters?.filter?.[strand]?.skills?.map((filter) => (
        <div
          onClick={() => customFilterClicked("skills", filter.value, filter.id)}
          key={filter.id}
          className={
            checkActive("skills", filter.id)
              ? "single-filter-container-active"
              : "single-filter-container"
          }
        >
          <p>{filter?.value}</p>
        </div>
      ))}
      {newCustomFilters?.filter?.[strand]?.standards?.map((filter) => (
        <div
          onClick={() =>
            customFilterClicked("standards", filter.value, filter.id)
          }
          key={filter.id}
          className={
            checkActive("standards", filter.id)
              ? "single-filter-container-active"
              : "single-filter-container"
          }
        >
          <p>{filter?.value}</p>
        </div>
      ))}
      {/* {newCustomFilters?.filter?.[strand]?.strand?.map(filter => <div
            onClick={() => customFilterClicked('strand', filter.value, filter.id)}
            key={filter.id}
            className={checkActive('strand', filter.id) ? "single-filter-container-active" : "single-filter-container"}>
            <p>{filter?.value}</p></div>)} */}
      {newCustomFilters?.filter?.[strand]?.genres?.map((filter) => (
        <div
          onClick={() => customFilterClicked("genres", filter.value, filter.id)}
          key={filter.id}
          className={
            checkActive("genres", filter.id)
              ? "single-filter-container-active"
              : "single-filter-container"
          }
        >
          <p>{filter?.value}</p>
        </div>
      ))}
      {newCustomFilters?.filter?.[strand]?.languages?.map((filter) => (
        <div
          onClick={() =>
            customFilterClicked("languages", filter.value, filter.id)
          }
          key={filter.id}
          className={
            checkActive("languages", filter.id)
              ? "single-filter-container-active"
              : "single-filter-container"
          }
        >
          <p>{filter?.value}</p>
        </div>
      ))}
      {newCustomFilters?.filter?.[strand]?.readingLevels?.map((filter) => (
        <div
          onClick={() =>
            customFilterClicked("readingLevels", filter.value, filter.id)
          }
          key={filter.id}
          className={
            checkActive("readingLevels", filter.id)
              ? "single-filter-container-active"
              : "single-filter-container"
          }
        >
          <p>{filter?.value}</p>
        </div>
      ))}
      {newCustomFilters?.filter?.[strand]?.themes?.map((filter) => (
        <div
          onClick={() => customFilterClicked("themes", filter.value, filter.id)}
          key={filter.id}
          className={
            checkActive("themes", filter.id)
              ? "single-filter-container-active"
              : "single-filter-container"
          }
        >
          <p>{filter?.value}</p>
        </div>
      ))}
      {newCustomFilters?.filter?.[strand]?.topics?.map((filter) => (
        <div
          onClick={() => customFilterClicked("topics", filter.value, filter.id)}
          key={filter.id}
          className={
            checkActive("topics", filter.id)
              ? "single-filter-container-active"
              : "single-filter-container"
          }
        >
          <p>{filter?.value}</p>
        </div>
      ))}
      {newCustomFilters?.filter?.[strand]?.unitsOfStudy?.map((filter) => (
        <div
          onClick={() =>
            customFilterClicked("unitsOfStudy", filter.value, filter.id)
          }
          key={filter.id}
          className={
            checkActive("unitsOfStudy", filter.id)
              ? "single-filter-container-active"
              : "single-filter-container"
          }
        >
          <p>{filter?.value}</p>
        </div>
      ))}
      {newCustomFilters?.filter?.[strand]?.standardType?.map((filter) => (
        <div
          onClick={() =>
            customFilterClicked("standardType", filter.value, filter.id)
          }
          key={filter.id}
          className={
            checkActive("standardType", filter.id)
              ? "single-filter-container-active"
              : "single-filter-container"
          }
        >
          <p>{filter?.value}</p>
        </div>
      ))}
      {newCustomFilters?.filter?.[strand]?.assessmentTypes?.map((filter) => (
        <div
          onClick={() =>
            customFilterClicked("assessmentTypes", filter.value, filter.id)
          }
          key={filter.id}
          className={
            checkActive("assessmentTypes", filter.id)
              ? "single-filter-container-active"
              : "single-filter-container"
          }
        >
          <p>{filter?.value}</p>
        </div>
      ))}
      {newCustomFilters?.filter?.[strand]?.lessonType?.map((filter) => (
        <div
          onClick={() =>
            customFilterClicked("lessonType", filter.value, filter.id)
          }
          key={filter.id}
          className={
            checkActive("lessonType", filter.id)
              ? "single-filter-container-active"
              : "single-filter-container"
          }
        >
          <p>{filter?.value}</p>
        </div>
      ))}
    </div>
  );
};
