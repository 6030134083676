import React, { Component } from "react";
import authAPI from "../../api/authAPI";
import { markdownHelper } from "../../markdown/MarkdownHelper";
import copy from "copy-to-clipboard";
import constants from "../../constants/index";
import { browserHistory } from "react-router";
import * as MackinActions from "../../redux/actions/mackin";
import * as actions from "../../redux/actions";

import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Collapsible from "react-collapsible";
import Cookies from "universal-cookie";
import sharegoogleclassroom from "../../assets/images/sharegoogleclassroom.png";
import iconPlayOptimized from "../../assets/icons/SVG/icon-play-optimized.svg";
import iconReaderInvert from "../../assets/icons/SVG/icon-reader-invert.svg";
import copyLink from "../../assets/images/copylink.png";
import openGoogle from "../../assets/images/opengoogle.png";
import downloadPdf from "../../assets/images/downloadpdf.png";
import openReader from "../../assets/images/openereader.png";
import openLibrary from "../../assets/images/open_library.png";
import amazon from "../../assets/images/amazon.png";
import mackin from "../../assets/images/mackin.png";
import brokenImage from "../../assets/images/notFound.webp";
require("../../assets/styles/books.scss");

class DetailedDescription extends Component {
  componentDidMount() { }

  handleOpening = async () => {
    if (this.props.bookId && this.props.is_digital) {
      await authAPI.logBookUserActivity(this.props.bookId);
      //browserHistory.push("/books/" + this.props.bookId + "/reader");
      window.open("/books/" + this.props.bookId + "/reader", "_blank");
    } else if (this.props.file) {
      if (
        this.props.resourceType &&
        this.props.resourceType.includes("Videos")
      ) {
        window.open(`https:${this.props.file}`, "_blank");
      } else {
        window.open(
          constants.filesUrl + this.props.file?.replace("../", ""),
          "_blank"
        );
      }
    } else {
      return;
    }
  };

  byBook = (bookId) => {
    this.props.buyBookOnMackin(bookId);
  };

  clearMackinData = () => {
    this.props.clearMackinData();
  };

  openGoogleForm = () => {
    if (this.props.forms) window.open(this.props.forms, "_blank");
    if (this.props.googleFormLink)
      window.open(this.props.googleFormLink, "_blank");
  };
  openPdf = async () => {
    if (this.props.bookId) {
      await authAPI.logBookUserActivity(this.props.bookId);
    }
    const cookies = new Cookies();
    let userAgree = cookies.get("showPdf");
    if (!userAgree) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>
                All texts are covered by copyright law and the fair use
                doctrine. They may not be printed or distributed beyond the good
                faith context of classroom instructional purposes based on your
                subscription to Fundamentals Unlimited.
              </h1>
              <ul>
                <li>
                  The texts should not be printed or distributed outside class
                  use.
                </li>
                <li>
                  The use of texts on Google Classrooms, or comparable
                  educational applications, should be limited to viewing by
                  students and teachers; the texts should not be downloadable or
                  printable by students.
                </li>
              </ul>
              <button
                onClick={() => {
                  cookies.set("showPdf", "y", { path: "/" });
                  window.open(
                    constants.filesUrl +
                    this.props?.printableFile?.replace("../", "")
                  );
                  onClose();
                }}
              >
                Yes, I agree
              </button>
              <button onClick={onClose}>No, I do not agree</button>
            </div>
          );
        },
      });
    } else if (userAgree === "y") {
      if (this.props.printableFile) {
        window.open(
          constants.filesUrl + this.props?.printableFile?.replace("../", "")
        );
      } else {
        window.open(constants.filesUrl + this.props?.file?.replace("../", ""));
      }
    }
  };

  classroomShare = () => {
    let videoLessonUrl = "";
    if (this.props.lessonData && this.props.lessonData?.isVBook?.text) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>IMPORTANT NOTE TO TEACHERS</h1>
              <p>
                For users to automatically access Schoolwide proprietary video
                lessons (vBooks) that you post to Google Classroom, their names
                and Gmail addresses must be registered. If your users are not
                yet registered, please contact our support team at
                help@schoolwide.com for assistance.
              </p>
              <button
                onClick={() => {
                  videoLessonUrl =
                    window.location.hostname + "/video/" + this.props.lessonid;
                  if (videoLessonUrl) {
                    window.open(
                      "https://classroom.google.com/share?url=" + videoLessonUrl
                    );
                  }
                  onClose();
                }}
              >
                OK
              </button>
            </div>
          );
        },
      });
    } else if (this.props.lessonData && this.props.lessonData?.isSilde?.text) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>IMPORTANT NOTE TO TEACHERS</h1>
              <p>
                For users to automatically access Schoolwide proprietary video
                lessons (vBooks) that you post to Google Classroom, their names
                and Gmail addresses must be registered. Please contact our
                support team atâ€š help@schoolwide.comâ€š for assistance in adding
                user information.
              </p>
              <button
                onClick={() => {
                  videoLessonUrl = this.props.lessonData.isSilde.text;
                  if (videoLessonUrl) {
                    window.open(
                      "https://classroom.google.com/share?url=" + videoLessonUrl
                    );
                  }
                  onClose();
                }}
              >
                OK
              </button>
            </div>
          );
        },
      });
    } else if (this.props.bookId && !this.props.printable) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>IMPORTANT NOTE TO TEACHERS</h1>
              <p>
                For users to automatically access proprietary texts from
                Schoolwide&rsquo;s publishing partners that you post to Google
                Classroom, the users&rsquo; names and Gmail addresses must be
                registered. If your users are not yet registered, please contact
                our support team at help@schoolwide.com for assistance.
              </p>
              <button
                onClick={() => {
                  videoLessonUrl =
                    window.location.hostname +
                    "/books/" +
                    this.props.bookId +
                    "/reader";
                  if (videoLessonUrl) {
                    window.open(
                      "https://classroom.google.com/share?url=" + videoLessonUrl
                    );
                  }
                  onClose();
                }}
              >
                OK
              </button>
            </div>
          );
        },
      });
    } else {
      let filePath = "";
      if (this.props.previewBook !== true && this.props.printable) {
        filePath = this.props.printableFile.replace("../", "");
      } else if (this.props.previewBook === true) {
        filePath = this.props.file.replace("../", "");
      } else if (this.props.detailType === "resources") {
        if (this.props.googleFormLink) {
          videoLessonUrl = this.props.googleFormLink;
          window.open(
            "https://classroom.google.com/share?url=" + videoLessonUrl
          );
        } else if (this.props.showClassroomShareButtonLink) {
          window.open(this.props.showClassroomShareButtonLink);
        } else if (this.props.file) {
          filePath = this.props.file.replace("../", "");
        }
      } else if (this.props.detailType === "assessments") {
        if (this.props.googleFormLink) {
          videoLessonUrl = this.props.googleFormLink;
          window.open(
            "https://classroom.google.com/share?url=" + videoLessonUrl
          );
        } else if (this.props.showClassroomShareButtonLink) {
          window.open(this.props.showClassroomShareButtonLink);
        } else if (this.props.file) {
          filePath = this.props.file.replace("../", "");
        }
      } else if (
        this.props.resourceType === "books" ||
        this.props.unitDetailType === "books"
      ) {
        if (this.props.googleFormLink && this.props.printable) {
          window.open(
            "https://classroom.google.com/share?url=" +
            this.props.googleFormLink
          );
        } else if (this.props.showClassroomShareButtonLink) {
          window.open(this.props.showClassroomShareButtonLink);
        } else if (this.props.file) {
          filePath = this.props.file.replace("../", "");
        }
      } else if (this.props.detailType === "texts") {
        if (this.props.googleFormLink) {
          videoLessonUrl = this.props.googleFormLink;
          window.open(
            "https://classroom.google.com/share?url=" + videoLessonUrl
          );
        } else if (this.props.showClassroomShareButtonLink) {
          window.open(this.props.showClassroomShareButtonLink);
        } else if (this.props.file) {
          filePath = this.props.file.replace("../", "");
        }
      } else if (this.props.detailType === "Unit_appendecies") {
        if (this.props.forms) {
          videoLessonUrl = this.props.forms;
          window.open(
            "https://classroom.google.com/share?url=" + videoLessonUrl
          );
        } else {
          filePath = this.props.printableFile.replace("../", "");
        }
      }

      if (filePath) {
        if (!filePath.includes("vimeo") && !filePath.includes("youtube")) {
          authAPI.validateClassromFileLink(filePath).then((resp) => {
            if (resp.data !== "") {
              videoLessonUrl = resp.data;
              window.open(
                "https://classroom.google.com/share?url=" +
                window.location.hostname +
                constants.filesUrl +
                videoLessonUrl
              );
            }
          });
        } else {
          window.open("https://classroom.google.com/share?url=" + filePath);
        }
      }
    }
  };

  copySharableLink = (e, url) => {
    let videoLessonUrl = "";
    if (this.props.lessonData && this.props.lessonData?.isVBook?.text) {
      videoLessonUrl =
        window.location.protocol +
        "//" +
        window.location.hostname +
        "/video/" +
        this.props.lessonid;
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>
                All texts are covered by copyright law and the fair use
                doctrine. They may not be printed or distributed beyond the good
                faith context of classroom instructional purposes based on your
                subscription to Fundamentals Unlimited.
              </h1>
              <ul>
                <li>
                  The texts should not be printed or distributed outside class
                  use.
                </li>
                <li>
                  The use of texts on Google Classrooms, or comparable
                  educational applications, should be limited to viewing by
                  students and teachers; the texts should not be downloadable or
                  printable by students.
                </li>
              </ul>
              <button
                onClick={() => {
                  copy(videoLessonUrl);

                  document.getElementById("copySuccessPopup").style.display =
                    "block";
                  setTimeout(() => {
                    document.getElementById("copySuccessPopup").style.display =
                      "none";
                  }, 5000);
                  onClose();
                }}
              >
                Yes, I agree
              </button>
              <button onClick={onClose}>No, I do not agree</button>
            </div>
          );
        },
      });
    } else if (this.props.lessonData && this.props.lessonData?.isSilde?.text) {
      videoLessonUrl = this.props.lessonData.isSilde.text;
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>
                All texts are covered by copyright law and the fair use
                doctrine. They may not be printed or distributed beyond the good
                faith context of classroom instructional purposes based on your
                subscription to Fundamentals Unlimited.
              </h1>
              <ul>
                <li>
                  The texts should not be printed or distributed outside class
                  use.
                </li>
                <li>
                  The use of texts on Google Classrooms, or comparable
                  educational applications, should be limited to viewing by
                  students and teachers; the texts should not be downloadable or
                  printable by students.
                </li>
              </ul>
              <button
                onClick={() => {
                  copy(videoLessonUrl);

                  document.getElementById("copySuccessPopup").style.display =
                    "block";
                  setTimeout(() => {
                    document.getElementById("copySuccessPopup").style.display =
                      "none";
                  }, 5000);
                  onClose();
                }}
              >
                Yes, I agree
              </button>
              <button onClick={onClose}>No, I do not agree</button>
            </div>
          );
        },
      });
    } else if (this.props.bookId && !this.props.printable) {
      videoLessonUrl =
        window.location.protocol +
        "//" +
        window.location.hostname +
        "/books/" +
        this.props.bookId +
        "/reader";
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>
                All texts are covered by copyright law and the fair use
                doctrine. They may not be printed or distributed beyond the good
                faith context of classroom instructional purposes based on your
                subscription to Fundamentals Unlimited.
              </h1>
              <ul>
                <li>
                  The texts should not be printed or distributed outside class
                  use.
                </li>
                <li>
                  The use of texts on Google Classrooms, or comparable
                  educational applications, should be limited to viewing by
                  students and teachers; the texts should not be downloadable or
                  printable by students.
                </li>
              </ul>
              <button
                onClick={() => {
                  copy(videoLessonUrl);

                  document.getElementById("copySuccessPopup").style.display =
                    "block";
                  setTimeout(() => {
                    document.getElementById("copySuccessPopup").style.display =
                      "none";
                  }, 5000);
                  onClose();
                }}
              >
                Yes, I agree
              </button>
              <button onClick={onClose}>No, I do not agree</button>
            </div>
          );
        },
      });
    } else {
      let filePath = "";
      if (this.props.previewBook !== true && this.props.printable) {
        filePath = this.props.printableFile.replace("../", "");
      } else if (this.props.previewBook === true) {
        filePath = this.props.file.replace("../", "");
      } else if (this.props.detailType === "resources") {
        if (this.props.googleFormLink) {
          videoLessonUrl = this.props.googleFormLink;
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui">
                  <h1>
                    All texts are covered by copyright law and the fair use
                    doctrine. They may not be printed or distributed beyond the
                    good faith context of classroom instructional purposes based
                    on your subscription to Fundamentals Unlimited.
                  </h1>
                  <ul>
                    <li>
                      The texts should not be printed or distributed outside
                      class use.
                    </li>
                    <li>
                      The use of texts on Google Classrooms, or comparable
                      educational applications, should be limited to viewing by
                      students and teachers; the texts should not be
                      downloadable or printable by students.
                    </li>
                  </ul>
                  <button
                    onClick={() => {
                      copy(videoLessonUrl);

                      document.getElementById(
                        "copySuccessPopup"
                      ).style.display = "block";
                      setTimeout(() => {
                        document.getElementById(
                          "copySuccessPopup"
                        ).style.display = "none";
                      }, 5000);
                      onClose();
                    }}
                  >
                    Yes, I agree
                  </button>
                  <button onClick={onClose}>No, I do not agree</button>
                </div>
              );
            },
          });
        } else if (this.props.file) {
          filePath = this.props.file.replace("../", "");
        }
      } else if (this.props.detailType === "assessments") {
        if (this.props.googleFormLink) {
          videoLessonUrl = this.props.googleFormLink;
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui">
                  <h1>
                    All texts are covered by copyright law and the fair use
                    doctrine. They may not be printed or distributed beyond the
                    good faith context of classroom instructional purposes based
                    on your subscription to Fundamentals Unlimited.
                  </h1>
                  <ul>
                    <li>
                      The texts should not be printed or distributed outside
                      class use.
                    </li>
                    <li>
                      The use of texts on Google Classrooms, or comparable
                      educational applications, should be limited to viewing by
                      students and teachers; the texts should not be
                      downloadable or printable by students.
                    </li>
                  </ul>
                  <button
                    onClick={() => {
                      copy(videoLessonUrl);

                      document.getElementById(
                        "copySuccessPopup"
                      ).style.display = "block";
                      setTimeout(() => {
                        document.getElementById(
                          "copySuccessPopup"
                        ).style.display = "none";
                      }, 5000);
                      onClose();
                    }}
                  >
                    Yes, I agree
                  </button>
                  <button onClick={onClose}>No, I do not agree</button>
                </div>
              );
            },
          });
        } else if (this.props.file) {
          filePath = this.props.file.replace("../", "");
        }
      } else if (this.props.detailType === "texts") {
        if (this.props.googleFormLink) {
          videoLessonUrl = this.props.googleFormLink;
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui">
                  <h1>
                    All texts are covered by copyright law and the fair use
                    doctrine. They may not be printed or distributed beyond the
                    good faith context of classroom instructional purposes based
                    on your subscription to Fundamentals Unlimited.
                  </h1>
                  <ul>
                    <li>
                      The texts should not be printed or distributed outside
                      class use.
                    </li>
                    <li>
                      The use of texts on Google Classrooms, or comparable
                      educational applications, should be limited to viewing by
                      students and teachers; the texts should not be
                      downloadable or printable by students.
                    </li>
                  </ul>
                  <button
                    onClick={() => {
                      copy(videoLessonUrl);

                      document.getElementById(
                        "copySuccessPopup"
                      ).style.display = "block";
                      setTimeout(() => {
                        document.getElementById(
                          "copySuccessPopup"
                        ).style.display = "none";
                      }, 5000);
                      onClose();
                    }}
                  >
                    Yes, I agree
                  </button>
                  <button onClick={onClose}>No, I do not agree</button>
                </div>
              );
            },
          });
        } else if (this.props.file) {
          filePath = this.props.file.replace("../", "");
        }
      } else if (this.props.resourceType === "books") {
        if (this.props.googleFormLink) {
          videoLessonUrl =
            window.location.protocol +
            "//" +
            window.location.hostname +
            "/books/" +
            this.props.bookId +
            "/reader";
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui">
                  <h1>
                    All texts are covered by copyright law and the fair use
                    doctrine. They may not be printed or distributed beyond the
                    good faith context of classroom instructional purposes based
                    on your subscription to Fundamentals Unlimited.
                  </h1>
                  <ul>
                    <li>
                      The texts should not be printed or distributed outside
                      class use.
                    </li>
                    <li>
                      The use of texts on Google Classrooms, or comparable
                      educational applications, should be limited to viewing by
                      students and teachers; the texts should not be
                      downloadable or printable by students.
                    </li>
                  </ul>
                  <button
                    onClick={() => {
                      copy(videoLessonUrl);

                      document.getElementById(
                        "copySuccessPopup"
                      ).style.display = "block";
                      setTimeout(() => {
                        document.getElementById(
                          "copySuccessPopup"
                        ).style.display = "none";
                      }, 5000);
                      onClose();
                    }}
                  >
                    Yes, I agree
                  </button>
                  <button onClick={onClose}>No, I do not agree</button>
                </div>
              );
            },
          });
        } else if (this.props.file) {
          filePath = this.props.file.replace("../", "");
        }
      } else if (this.props.detailType === "Unit_appendecies") {
        if (this.props.forms) {
          videoLessonUrl = this.props.forms;
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui">
                  <h1>
                    All texts are covered by copyright law and the fair use
                    doctrine. They may not be printed or distributed beyond the
                    good faith context of classroom instructional purposes based
                    on your subscription to Fundamentals Unlimited.
                  </h1>
                  <ul>
                    <li>
                      The texts should not be printed or distributed outside
                      class use.
                    </li>
                    <li>
                      The use of texts on Google Classrooms, or comparable
                      educational applications, should be limited to viewing by
                      students and teachers; the texts should not be
                      downloadable or printable by students.
                    </li>
                  </ul>
                  <button
                    onClick={() => {
                      copy(videoLessonUrl);

                      document.getElementById(
                        "copySuccessPopup"
                      ).style.display = "block";
                      setTimeout(() => {
                        document.getElementById(
                          "copySuccessPopup"
                        ).style.display = "none";
                      }, 5000);
                      onClose();
                    }}
                  >
                    Yes, I agree
                  </button>
                  <button onClick={onClose}>No, I do not agree</button>
                </div>
              );
            },
          });
        } else {
          filePath = this.props.printableFile.replace("../", "");
        }
      }
      if (filePath) {
        if (!filePath.includes("vimeo") && !filePath.includes("youtube")) {
          authAPI.validateClassromFileLink(filePath).then((resp) => {
            if (resp.data !== "") {
              videoLessonUrl =
                window.location.protocol +
                "//" +
                window.location.hostname +
                constants.filesUrl +
                resp.data;
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className="custom-ui">
                      <h1>
                        All texts are covered by copyright law and the fair use
                        doctrine. They may not be printed or distributed beyond
                        the good faith context of classroom instructional
                        purposes based on your subscription to Fundamentals
                        Unlimited.
                      </h1>
                      <ul>
                        <li>
                          The texts should not be printed or distributed outside
                          class use.
                        </li>
                        <li>
                          The use of texts on Google Classrooms, or comparable
                          educational applications, should be limited to viewing
                          by students and teachers; the texts should not be
                          downloadable or printable by students.
                        </li>
                      </ul>
                      <button
                        onClick={() => {
                          copy(videoLessonUrl);

                          document.getElementById(
                            "copySuccessPopup"
                          ).style.display = "block";
                          setTimeout(() => {
                            document.getElementById(
                              "copySuccessPopup"
                            ).style.display = "none";
                          }, 5000);
                          onClose();
                        }}
                      >
                        Yes, I agree
                      </button>
                      <button onClick={onClose}>No, I do not agree</button>
                    </div>
                  );
                },
              });
            }
          });
        } else {
          filePath = window.location.protocol + filePath;
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui">
                  <h1>
                    All texts are covered by copyright law and the fair use
                    doctrine. They may not be printed or distributed beyond the
                    good faith context of classroom instructional purposes based
                    on your subscription to Fundamentals Unlimited.
                  </h1>
                  <ul>
                    <li>
                      The texts should not be printed or distributed outside
                      class use.
                    </li>
                    <li>
                      The use of texts on Google Classrooms, or comparable
                      educational applications, should be limited to viewing by
                      students and teachers; the texts should not be
                      downloadable or printable by students.
                    </li>
                  </ul>
                  <button
                    onClick={() => {
                      copy(filePath);

                      document.getElementById(
                        "copySuccessPopup"
                      ).style.display = "block";
                      setTimeout(() => {
                        document.getElementById(
                          "copySuccessPopup"
                        ).style.display = "none";
                      }, 5000);
                      onClose();
                    }}
                  >
                    Yes, I agree
                  </button>
                  <button onClick={onClose}>No, I do not agree</button>
                </div>
              );
            },
          });
        }
      }
    }
  };

  render() {
    const { printable, unitTextBuyBorrowData, preview, googleFormLink } =
      this.props;
    let previewBook = preview;
    let borrow_data = [];
    let buy_data = [];
    let hasBorrowData = false;
    let hasBuyData = false;
    let showClassroomShareButton = false;

    // let display_book_overlay =
    //   this.props.is_digital || this.props.file || this.props.printableFile;
    let display_book_overlay = this.props.is_digital;
    if (this.props.lessonData?.isVBook?.text) {
      showClassroomShareButton = true;
    } else if (this.props.lessonData?.isSilde?.text) {
      showClassroomShareButton = true;
    } else if (
      this.props.detailType === "texts" &&
      this.props.printable &&
      this.props.bookId
    ) {
      showClassroomShareButton = true;
    } else if (
      previewBook !== true &&
      hasBorrowData === false &&
      hasBuyData === false &&
      this.props.bookId
    ) {
      if (this.props.file) {
        showClassroomShareButton = true;
      } else if (this.props.printableFile && this.props.googleFormLink) {
        showClassroomShareButton = true;
      }
    } else if (this.props.detailType === "Unit_appendecies") {
      showClassroomShareButton = true;
    } else if (
      this.props.detailType === "books" &&
      this.props.printable &&
      this.props.googleFormLink
    ) {
      showClassroomShareButton = true;
    } else if (
      this.props.detailType === "assessments" ||
      this.props.detailType === "resources"
    ) {
      if (
        this.props.googleFormLink ||
        this.props.showClassroomShareButtonLink
      ) {
        showClassroomShareButton = true;
      }
    } else {
      if (this.props.googleFormLink && this.props.printable) {
        showClassroomShareButton = true;
      }
    }
    if (unitTextBuyBorrowData && unitTextBuyBorrowData.length > 0) {
      for (let i = 0; i < unitTextBuyBorrowData.length; i++) {
        if (
          unitTextBuyBorrowData[i].buy === false &&
          unitTextBuyBorrowData[i].url !== ""
        ) {
          borrow_data.push({
            url: unitTextBuyBorrowData[i].url,
            imgSrc: unitTextBuyBorrowData[i].type,
          });
          hasBorrowData = true;
        }

        if (
          unitTextBuyBorrowData[i].buy === true &&
          unitTextBuyBorrowData[i].url !== ""
        ) {
          buy_data.push({
            url: unitTextBuyBorrowData[i].url,
            imgSrc: unitTextBuyBorrowData[i].type,
          });

          hasBuyData = true;
        }
      }
    }
    return (
      <div
        className="detailed-description"
        style={{
          height:
            unitTextBuyBorrowData && unitTextBuyBorrowData !== ""
              ? "100vh"
              : "",
        }}
      >
        <div className="units-detailed-image-center">
          {this.props.detailType !== "units" && this.props.file && this.props.resourceType &&
                  this.props.resourceType.includes("Videos") && (
            <img
              onClick={this.handleOpening}
              src={iconPlayOptimized}
              className="books-detail-zing-overlay-icon"
              onError={(event) => (event.target.src = brokenImage)}
              alt=""
            />
          )}
          {this.props.detailType !== "units" && this.props.is_digital && (
            <img
              onClick={this.handleOpening}
              src={iconReaderInvert}
              className="books-detail-zing-overlay-icon"
              onError={(event) => (event.target.src = brokenImage)}
              alt=""
            />
          )}
          {previewBook === true && (
            <div
              style={{
                textOrientation: "sideways",
                position: "absolute",
                top: "33%",
                left: "2%",
                fontWeight: "bold",
                fontSize: "65px",
                color: "#f2f2f2",
                borderTop: "4px solid",
                borderBottom: "4px solid",
                transform: "rotate(-35deg)",
              }}
            >
              PREVIEW
            </div>
          )}
          {
            <img
              style={display_book_overlay ? { cursor: "pointer" } : {}}
              onClick={display_book_overlay ? this.handleOpening : null}
              src={
                this.props.image
                  ? constants.filesUrl + this.props.image.replace("../", "")
                  : constants.filesUrl
              }
              className="image-size"
              onError={(event) => (event.target.src = brokenImage)}
              alt=""
            />
          }
        </div>
        {this.props.detailType === "books" && (
          <div
            className="browse-page-right-visit-button"
            onClick={() => browserHistory.push("/books/" + this.props.bookId)}
          >
            <p>View Text Details</p>
          </div>
        )}

        <div>
          {localStorage.user_type !== "Student" &&
            showClassroomShareButton &&
            hasBorrowData === false &&
            hasBuyData === false && (
              <span
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  margin: "7px 0px",
                }}
                onClick={this.classroomShare}
              >
                <img src={sharegoogleclassroom} height="95" alt="" />
              </span>
            )}
          {localStorage.user_type !== "Student" &&
            showClassroomShareButton &&
            hasBorrowData === false &&
            hasBuyData === false && (
              <span
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  margin: "7px 0px",
                }}
                onClick={this.copySharableLink}
              >
                <img src={copyLink} height="95" alt="" />
              </span>
            )}
          {this.props.detailType === "Unit_appendecies" && this.props.forms && (
            <span
              style={{
                cursor: "pointer",
                textAlign: "center",
                margin: "7px 0px",
              }}
              onClick={this.openGoogleForm}
            >
              <img src={openGoogle} height="95" alt="" />
            </span>
          )}
          {this.props.detailType === "assessments" &&
            this.props.googleFormLink && (
              <span
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  margin: "7px 0px",
                }}
                onClick={this.openGoogleForm}
              >
                <img src={openGoogle} height="95" alt="" />
              </span>
            )}
          {this.props.detailType === "resources" && googleFormLink && (
            <span
              style={{
                cursor: "pointer",
                textAlign: "center",
                margin: "7px 0px",
              }}
              onClick={this.openGoogleForm}
            >
              <img src={openGoogle} height="95" alt="" />
            </span>
          )}
          {!this.props?.resourceType?.includes("Videos") &&
            previewBook !== true &&
            hasBorrowData === false &&
            hasBuyData === false &&
            printable &&
            this.props.printableFile && (
              <span
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  margin: "7px 0px",
                }}
                onClick={this.openPdf}
              >
                <img src={downloadPdf} height="95" alt="" />
              </span>
            )}
          {!this.props?.resourceType?.includes("Videos") &&
            this.props.detailType === "Unit_appendecies" && (
              <span
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  margin: "7px 0px",
                }}
                onClick={this.openPdf}
              >
                <img src={downloadPdf} height="95" alt="" />
              </span>
            )}
          {!this.props?.resourceType?.includes("Videos") &&
            this.props.detailType === "Books" && (
              <span
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  margin: "7px 0px",
                }}
                onClick={this.openPdf}
              >
                <img src={downloadPdf} height="95" alt="" />
              </span>
            )}
          {!this.props?.resourceType?.includes("Videos") &&
            (this.props.detailType === "resources" ||
              this.props.detailType === "assessments") &&
            this.props.file && (
              <span
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  margin: "7px 0px",
                }}
                onClick={this.openPdf}
              >
                <img src={downloadPdf} height="95" alt="" />
              </span>
            )}

          {previewBook !== true &&
            hasBorrowData === false &&
            hasBuyData === false &&
            display_book_overlay &&
            this.props.bookId && (
              <span
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  margin: "7px 0px",
                }}
                onClick={this.handleOpening}
              >
                <img src={openReader} height="95" alt="" />
              </span>
            )}

          {previewBook === true &&
            display_book_overlay &&
            this.props.bookId && (
              <button
                className={`btn btn-primary mb-10 default_link_button e-reader-btn`}
                onClick={this.handleOpening}
              >
                Preview
              </button>
            )}
        </div>
        <div>
          {this.props.detailType === "lessons" && (
            <div className="lesson-description">RATIONALE</div>
          )}
          {this.props.detailType !== "units" &&
            this.props.description &&
            this.props.description.text && (
              <div
                className="general-word-style"
                dangerouslySetInnerHTML={{
                  __html: markdownHelper.toHTML(this.props.description.text),
                }}
              />
            )}
          <br />
          {this.props.detailType === "units" && this.props.fileName && (
            <a
              href={
                "../../../schoolwidefun/api/v1/files/" + this.props.fileName
              }
              target="_blank"
              rel="noreferrer"
            >
              <button className="preparing-to-teach">PREPARING TO TEACH</button>
            </a>
          )}
          <br />
        </div>
        {this.props.detailType !== "units" &&
          unitTextBuyBorrowData &&
          previewBook !== true && (
            <div>
              {hasBorrowData === true && (
                <Collapsible
                  trigger="BORROW"
                  className={`borrow`}
                  openedClassName="borrow-open"
                  transitionTime="200"
                >
                  {borrow_data.map((itm) => (
                    <a
                      href={itm.url}
                      target="_blank"
                      style={{
                        padding: "5px 23px",
                        marginBottom: "12px",
                        display: "block",
                        textAlign: "center",
                      }}
                      rel="noreferrer"
                    >
                      <img
                        src={
                          itm.imgSrc === "mackin"
                            ? mackin
                            : itm.imgSrc === "open_library"
                              ? openLibrary
                              : itm.imgSrc === "open_library"
                                ? openLibrary
                                : amazon
                        }
                        alt="borrow_image"
                        style={{ width: "45%" }}
                      />
                    </a>
                  ))}
                </Collapsible>
              )}
              {hasBuyData === true && (
                <Collapsible
                  trigger="BUY"
                  className={`buy`}
                  openedClassName="buy-open"
                  transitionTime="200"
                >
                  {buy_data.map((itm) => (
                    <a
                      href={itm.url}
                      target="_blank"
                      style={{
                        padding: "5px 23px",
                        marginBottom: "12px",
                        display: "block",
                        textAlign: "center",
                      }}
                      rel="noreferrer"
                    >
                      <img
                        src={
                          itm.imgSrc === "mackin"
                            ? mackin
                            : itm.imgSrc === "open_library"
                              ? openLibrary
                              : itm.imgSrc === "open_library"
                                ? openLibrary
                                : amazon
                        }
                        alt="buy_image"
                        style={{ width: "45%" }}
                      />
                    </a>
                  ))}
                </Collapsible>
              )}
            </div>
          )}

        <div
          className="single-select-popover folder-success-popver"
          id="copySuccessPopup"
          style={{ width: "12%", display: "none", left: "40%" }}
        >
          <div
            className="item-added"
            style={{ lineHeight: "26px", marginBottom: "10px" }}
          >
            <div
              style={{ marginBottom: 10 }}
              className="selected-checkbox-icon-add-multiple icon-icon-checkbox"
            />
            <div style={{ textAlign: "left" }}>
              <strong>Link Copied</strong>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Mackin, Users }) => ({
  book: Mackin.book,
  errors: Mackin.errors,
  userPermission: Users.hasMore,
});

export default connect(mapStateToProps)(DetailedDescription);