import React from 'react'
import ToolTip from 'react-portal-tooltip'

class PopOver extends React.Component {
    constructor(props) {
        super(props);
        this.style1 = {
            style: {
                // height: "125px",
                // width: "200px",
                border: "1px solid #F5F5F5",
                borderRadius: "8px",
                backgroundColor: "rgba(255,255,255)",
                //boxShadow: "0 2px 12px 0 rgba(0,0,0,0.5)",
                padding: "10px 10px 10px 10px",
                filter: "drop-shadow(0px 2px 12px rgba(0,0,0,0.5))",
                zIndex: 1000
            },
            arrowStyle: {
                color: "white",
                borderColor: "#F5F5F5",
                //filter: "drop-shadow(0px 2px 12px rgba(0,0,0,0.5))"
            }
        };
    }

    // state = {
    //     isTooltipActive: this.props.isTooltipActive
    // }
    // showTooltip() {
    //     this.setState({isTooltipActive: true})
    // }
    // hideTooltip() {
    //     this.setState({isTooltipActive: false})
    // }
    //
    // componentWillReceiveProps(nextProps){
    //   if(nextProps.isTooltipActive != this.state.isTooltipActive) {
    //     this.setState({isTooltipActive : nextProps.isTooltipActive})  ;
    //   }
    // }
    render() {
        if (this.props.height) {
            this.style1.style.height = this.props.height;
        }

        if (this.props.width) {
            this.style1.style.width = this.props.width;
        }

        if (this.props.padding) {
            this.style1.style.padding = this.props.padding;
        }

        let parent="#" + this.props.count;
        return (
            <ToolTip active={this.props.isTooltipActive} position={this.props.position ||"bottom"} arrow={this.props.arrow} parent={parent} style={this.style1}>
                {this.props.children}
            </ToolTip>
        )
    }
}

export default PopOver;
