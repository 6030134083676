import React , {Component} from 'react'
import BrowseComponent from '../../components/NewBrowseComponent';
import {Helmet} from 'react-helmet'
class NewHome extends Component{
    render(){
        return(
            <div>
                <BrowseComponent location={this.props.location}/>
            </div>

        );
    }
}

export default NewHome;
