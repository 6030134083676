import React from 'react';

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const weekdays=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

const AgendaDateComponent = (collapse, collapsed, props)=> {
    let collapseEvents=()=>{
        collapse(props.day.toDateString());
    }

    const date = typeof props.date !== "string" ? props.day : new Date(props.day);
    let clName = collapsed[props.day.toDateString()] === true ?"list-view-arrow-right icon-arrow-solid-right" :"list-view-arrow-down icon-arrow-solid-down";
    return (
        <div className="date-cell">
            <span onClick={collapseEvents} style={{float: "left",cursor: "pointer"}}>
                <i  className={clName}/>
            </span>
            {weekdays[date.getDay()]} {months[date.getMonth()]} {date.getDate()}, {date.getFullYear()}
        </div>
    )
}

export default AgendaDateComponent
