import React,{Component} from  'react';
import CorrelationsDetailedView from './CorrelationsDetailedView';

class CorrelationComponent extends Component{

    render(){

        return(
            <div>
                <CorrelationsDetailedView/>
            </div>
        );
    }
}



export default CorrelationComponent;
