import React, { Component } from "react";
import { browserHistory } from "react-router";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import homeIcon from "../../assets/icons/SVG/home_white_icon.svg";
import libraryIcon from "../../assets/icons/SVG/library_white_icon.svg";
import browseIcon from "../../assets/icons/SVG/icon-browse.svg";

export default class SingleFooterItem extends Component {
  constructor() {
    super();
    this.state = {
      amIactive: false,
    };
  }

  componentWillReceiveProps(next) {
    this.setState({
      amIactive:
        next.activeItemName !== "" &&
        next.pageName.indexOf(next.activeItemName) >= 0,
    });
  }

  handleClick() {
    console.log("testtsttsttst");
    this.props.selectOneItem(this.props.pageName);
    let target = this.props.pageName;
    browserHistory.push("/" + target);
    if (target == "library/resources") {
      setTimeout(function () {
        //window.location.reload();
      }, 100);
    }
  }

  generateClassName = () => {
    let className = "footer-btn-container ";
    if (this.props.icon === "add-user") className += "hidden-xs ";
    if (this.state.amIactive) className += "active-footer-btn ";
    return className;
  };

  render() {
    return this.props.lite === "true" ? (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="top"
        overlay={
          this.props.lite === "true" && (
            <Tooltip id="lite-user-popup">
              This feature is only available with a Fundamentals Unlimited
              subscription. <br />
              <a
                href="https://www.schoolwide.com/contact.php?t=nothing"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Contact us
              </a>{" "}
              for more information.
            </Tooltip>
          )
        }
      >
        <div
          className={"footer-btn-container disabled-footer-btn"}
          data-toggle="tooltip"
          data-html="true"
          title={this.props.text}
        >
          <div className="footer-schoolwide-image">
            {this.props.icon === "home" ? (
              <img style={{ marginBottom: 8 }} src={homeIcon} alt="" />
            ) : this.props.icon === "books" ? (
              <img style={{ marginBottom: 8 }} src={libraryIcon} alt="" />
            ) : (
              <div
                className={"footer-icon icon-icon-" + this.props.icon}
                style={{ color: this.state.amIactive ? "#009BBD" : "#FFFFFF" }}
              />
            )}
          </div>
          <div className="footer-schoolwide-text">{this.props.text}</div>
        </div>
      </OverlayTrigger>
    ) : (
      <div
        onClick={() => this.handleClick()}
        className={this.generateClassName()}
        data-toggle="tooltip"
        data-html="true"
        title={this.props.text}
      >
        <div className="footer-schoolwide-image">
          {this.props.icon === "home" ? (
            <img style={{ marginBottom: 8 }} src={homeIcon} alt="" />
          ) : this.props.icon === "books" ? (
            <img style={{ marginBottom: 8 }} src={libraryIcon} alt="" />
          ) : (
            <div
              className={"footer-icon icon-icon-" + this.props.icon}
              style={{ color: this.state.amIactive ? "#009BBD" : "#FFFFFF" }}
            />
          )}
        </div>
        <div className="footer-schoolwide-text">{this.props.text}</div>
      </div>
    );
  }
}
