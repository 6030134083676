import React, { Component } from "react";

export default class CheckedFilterItem extends Component {
  constructor(props) {
    super(props);
    this.onToggledHandler = this.onToggledHandler.bind(this);
  }

  onToggledHandler() {
    this.props.handleClick(
      !this.props.checked,
      this.props.index,
      this.props.option
    );
  }

  isDisabled() {
    return (
      !(this.props.show === true || this.props.show === undefined) &&
      !this.props.checked
    );
  }

  render() {
    return (
      <label
        style={{ opacity: this.isDisabled() ? "0.3" : "" }}
        className="dropdown-labels control control--checkbox"
      >
        <p>{this.props.value}</p>
        <input
          ref={this.props.tagName + this.props.index}
          type="checkbox"
          onChange={this.onToggledHandler}
          disabled={this.isDisabled()}
          checked={this.props.checked}
        />
        <div className="control__indicator" />
      </label>
    );
  }
}
