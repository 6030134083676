import React, {Component} from 'react'
import Assignment from './Assignment'
import DateComponent from './DateComponent'
import InfiniteScroll from 'react-infinite-scroll-component'

export default class StudentTodoList extends Component {

    getAssignments() {
        return (this.props.assignments &&
        this.props.assignments.data &&
        this.props.assignments.data.results)? this.props.assignments.data.results: [];

    }
    render() {
        let checkDate = 0;
        let userType = localStorage.user_type;
        return (
            <div>
                <div style={{"textAlign": "center", marginTop:"20px"}} id="student-todo-button-group">
                    <button onClick={this.props.onTodoSelected}
                            className={this.props.selectedTab === "TO-DO"?"to-do-active":"to-do"}>
                        TO-DO
                    </button>
                    <button onClick={this.props.onDueAssignmentSelected}
                            className={this.props.selectedTab === "PAST-DUE-ASSIGNMENT"?"due-assignment-button-active":"due-assignment-button"}>
                        PAST-DUE-ASSIGNMENT
                    </button>
                    <button onClick={this.props.onDoneSelected}
                            className={this.props.selectedTab === "DONE"?"done-button-active":"done-button"}>
                        DONE
                    </button>                    
                </div>

                {this.getAssignments().length > 0 ?
                    // Render if there are assignments
                    <InfiniteScroll
                        next={this.props.fetchAssignments}
                        hasMore={this.props.assignments.hasMore}
                        loader={<div style={{float: "left"}}>Loading...</div>}
                        dataLength={this.getAssignments()?.length ?? 0}
                    >
                        {this.getAssignments().map((assignment, i) => {
                                const componentProps = {
                                    selectedTab: this.props.selectedTab,
                                    day: assignment.day,
                                    collapseDateList: this.props.collapseDateList
                                };
                                return (
                                    <div key={'assignment_' + assignment._id}>
                                        <DateComponent {...componentProps}
                                                       checkDate={checkDate}
                                                       i={i}
                                                       userType={userType}
                                                       collapseAssignments={this.props.collapseAssignments}
                                        />
                                        <div style={{display: "none"}}>
                                            {checkDate = new Date(assignment.day).getDate()}
                                        </div>
                                        <Assignment {...componentProps}
                                                    refresh={this.props.refresh}
                                                    completeAssignment={this.props.completeAssignmentAction}
                                                    assignment={assignment}
                                                    deleteAssignment={this.props.deleteAssignment}
                                        />
                                    </div>
                                )
                            }
                        )}
                    </InfiniteScroll> :
                    // Render if no assignments
                    <div id="student-view-empty-list">
                        {this.props.selectedTab === "TO-DO"?
                            "Hooray! You have no upcoming assignments." :
                            "There are currently no completed assignments. Check the To-Do tab to see your upcoming assignments."
                        }
                    </div>
                }
            </div>
        );
    }
}
