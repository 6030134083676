import React, {Component} from 'react'
import { Button, Grid, Row, Col, Alert } from 'react-bootstrap'
import "../../assets/styles/login.scss"
import {getAllPicturePasswordsAction} from '../../redux/actions'
import {connect} from 'react-redux'
import {browserHistory,Link, Redirect} from 'react-router'
import authAPI from '../../api/authAPI.js'
import "../../assets/styles/mobile.scss"

class PresentAllPicturePasswords extends Component {
  constructor(props){
    super(props);
  }

  render(){
    return (
      <div>
          <Grid className={"set-picture-grid " + this.props.className} style={this.props.style}>
              <Row className="set-picture-row">
                  {this.props.pictureData.map(pictureData => {
                      return <Col md={2} sm={3} xs={4} key={pictureData.uuid} style={{textAlign: 'center'}}>
                                <img alt={pictureData.uuid}
                                    src={pictureData.path}
                                    height={128} width={128}
                                    style={this.props.selectedPicture === pictureData.uuid ?
                                          {padding: '1em', borderRadius: 4, border: '2px solid #46C56D'} :
                                          {padding: '1em'}}
                                    onClick={()=>this.props.handleSelectClick(pictureData.uuid)}
                                />
                            </Col>
                  })}
              </Row>
          </Grid>
          {!this.props.hideFooter &&
            <div className='add-unit-footer'>
              <div className='button-regular-left'>
                  <button className='cancel-lesson-unit' onClick={browserHistory.goBack}>
                      CANCEL
                  </button>
              </div>
              <div className='button-regular-right'>
                  <button className='assign-lesson-unit' onClick={this.props.handleSaveClick}>
                      SAVE
                  </button>
              </div>
          </div>
        }
      </div>
    )
  }
}

class SetPicturePassword extends Component {
    //Expects Token passed in as a prop.
    constructor(props){
      super(props);
      this.state = {
        uuid: '',
        success: false,
        error: false
      }
      this.handleSelectClick = this.handleSelectClick.bind(this);
      this.handleSaveClick = this.handleSaveClick.bind(this);
    }

    componentWillMount(){
      let username = this.props.username;
      if(this.props.domain)
        username += `@${this.props.domain}`;
      this.props.getAllPicturePasswordsAction(username);
      this.setState({username});
    }

    handleSaveClick = ()=>{
      authAPI.postSetPicturePassword(this.state.username, this.state.uuid)
      .then(()=>{
        let redirectURL = '/picture-password';
        if(this.props.domain)
            redirectURL += `/${this.props.domain}`;
        this.props.router.replace(redirectURL);
      })
      .catch(()=>this.setState({error: true}));
    }

    handleSelectClick = (uuid)=>{
      this.setState({uuid});
    }

    render(){
      return (
        <div>
            { !this.state.error ?
              (
                this.props.pictureData && this.props.pictureData.length > 0 &&
                <PresentAllPicturePasswords pictureData={this.props.pictureData}
                                            handleSaveClick={this.handleSaveClick}
                                            handleSelectClick={this.handleSelectClick}
                                            selectedPicture={this.state.uuid}
                                            hideFooter={this.props.hideFooter}
                                            style={this.props.style}
                                             className={this.props.className}/>
              ) : (
                <div className="alert alert-danger">There was a problem saving your password. Please contact your School Admin.</div>
              )
            }
        </div>
      )
    }
}

const mapStateToProps = (state) => {
  return {
    pictureData: state.SetPicturePassword.data,
    asyncStatus: state.SetPicturePassword.asyncStatus
  }
}

export default connect(mapStateToProps, {getAllPicturePasswordsAction}, null, {withRef: true})(SetPicturePassword);
