import {
  START_JOYRIDE,
  JOYRIDE_NEXT_STEP,
  JOYRIDE_INSERT_STEP,
  JOYRIDE_STOP,
  JOYRIDE_SET_STEPS_AND_START,
  JOYRIDE_START_AT_INDEX,
  DISPLAY_TOUR_MODAL,
  HIDE_TOUR_MODAL,
  RESET_JOYRIDE,
  JOYRIDE_LOAD_STEPS,
  RESET_RESET_JOYRIDE,
  JOYRIDE_INTENT,
  ENABLE_JOYRIDE_CLICKS
} from '../actions';

const initialState = {};

const JoyrideReducer = (state = initialState, action) => {
    switch (action.type) {
        case JOYRIDE_INTENT:
            return Object.assign({}, state, {joyrideIntendsToStart: action.payload});
        case START_JOYRIDE:
            return Object.assign({}, state, {joyrideSteps: state.joyrideSteps || [], joyrideShouldRun: true, joyrideStepIndex: state.joyrideStepIndex || 0, joyrideShouldEndAt: state.joyrideStepIndex || 0});
        case JOYRIDE_NEXT_STEP:
            return Object.assign({}, state, {joyrideStepIndex: (state.joyrideStepIndex+1) || 1});
        case JOYRIDE_INSERT_STEP:
            let arr = state.joyrideSteps;
            arr.splice(state.joyrideStepIndex, 0, action.payload)
            return Object.assign({}, state, {joyrideSteps: arr})
        case JOYRIDE_SET_STEPS_AND_START:
            return Object.assign({}, state, {joyrideSteps: action.payload, joyrideShouldRun: true, joyrideStepIndex: action.startIndex, joyrideShouldEndAt: action.endIndex});
        case JOYRIDE_STOP:
            return Object.assign({}, state, {joyrideShouldRun: false});
        case JOYRIDE_START_AT_INDEX:
            return Object.assign({}, state, {joyrideSteps: state.joyrideSteps || [], joyrideShouldRun: true, joyrideStepIndex: action.startIndex, joyrideShouldEndAt: action.endIndex})
        case DISPLAY_TOUR_MODAL:
            return Object.assign({}, state, {displayTourModal: true, allowClicksThruHere: true});
        case HIDE_TOUR_MODAL:
            return Object.assign({}, state, {displayTourModal: false});
        case RESET_JOYRIDE:
            let endIndex = isNaN(action.endIndex) ? state.joyrideShouldEndAt || 0 : action.endIndex;
            return Object.assign({}, state, {reset: true, joyrideSteps: action.steps || state.joyrideSteps || [], joyrideShouldRun: true, joyrideStepIndex: 0, joyrideShouldEndAt: endIndex});
        case JOYRIDE_LOAD_STEPS:
            return Object.assign({}, state, {joyrideSteps: action.payload});
        case RESET_RESET_JOYRIDE:
            return Object.assign({}, state, {reset: false});
        case ENABLE_JOYRIDE_CLICKS:
            return Object.assign({}, state, {allowClicksThruHere: action.payload});
        default:
            return state;
    }
};

export default JoyrideReducer;
