import * as AuthAction from '../../actions/auth';


const initialState = {
    email: '',
    loading: false,
    school: {
        status: null,
        data: [],
    },
    user_create: {
        status: null
    },
    error: '',
    errors: {}
};

const defaultGetSchoolError = 'It looks like some of your district information has not been added to our system. Contact us at customerservice@schoolwide.com to set up your account.'


const handleError = payload => {
    switch (true) {
        case typeof payload === 'string':
            return { error: payload  }
        case typeof payload === 'object' && Array.isArray(payload):
            return { error: `${payload[0].field}:   ${payload[0].messages}`  }
        default:
            return { error: `${payload[0].field}:   ${payload[0].messages}`  }
    }
}
const AuthReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case AuthAction.AUTH_SET_USER_EMAIL:
            return { ...state, email: payload }


            // get scool 
        case AuthAction.AUTH_GET_SCHOOL_BY_EMAIL_PENDING:
            return { ...state, school: { ...state.school, status: 'pending' } }
        case AuthAction.AUTH_GET_SCHOOL_BY_EMAIL_SUCCESS:
            return { ...state, school: { ...state.school, status: 'success', data: payload } }
        case AuthAction.AUTH_GET_SCHOOL_BY_EMAIL_FAILED:
            return { ...state, school: { ...state.school, status: 'failed', data: [] }, error: payload || defaultGetSchoolError }


            // register new user

        case AuthAction.AUTH_REGISTER_NEW_USER_PENDING:
            return { ...state, loading: true }
        case AuthAction.AUTH_REGISTER_NEW_USER_SUCCESS:
            return { ...state, loading: false, user_create: { status: 'success', _id: payload } }
        case AuthAction.AUTH_REGISTER_NEW_USER_FAILED:
            return { ...state, errors: { ...state.errors, ...payload } , loading: false, ...handleError(payload)  }

        default:
            return state;
    }
};

export default AuthReducer;