import {
  GET_ALL_PICTURE_PASSWORDS_REQUEST,
  GET_ALL_PICTURE_PASSWORDS_SUCCESS,
  GET_ALL_PICTURE_PASSWORDS_FAILURE
} from "../actions"

const initialState = {};

const SetPicturePasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PICTURE_PASSWORDS_REQUEST:
            return Object.assign({},state, {asyncStatus:  'PENDING'});
        case GET_ALL_PICTURE_PASSWORDS_SUCCESS:
            return Object.assign({},state, {asyncStatus:  'SUCCESS', data: action.payload});
        case GET_ALL_PICTURE_PASSWORDS_FAILURE:
            return Object.assign({},state, {asyncStatus:  'FAILURE', data: action.payload});
        default:
            return state;
    }
};

export default SetPicturePasswordReducer
