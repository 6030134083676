import React, { Component } from "react";
import closeButtonIcon from "../../assets/icons/SVG/my-close-button.png";
import addToFolderIcon from "../../assets/icons/SVG/add-to-folder.svg";
import checkMark from "../../assets/icons/SVG/icon-check.svg";
import checkMarkDark from "../../assets/icons/SVG/my-check-image.svg";
import XIcon from "../../assets/icons/SVG/icon-dark-x.svg";
import AddToFolderSingleItem from "./AddToFolderSingleItem";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import { withRouter } from "react-router";
import authAPI from "../../api/authAPI";
import { SnackBarModule } from "../Shared";

class TextBox extends Component {
  render() {
    return (
      <div className="add-to-folder-modal-input-textfield">
        <input
          className={
            this.props.errorValue === ""
              ? "add-user-form"
              : "add-user-form add-user-form-error"
          }
          name={this.props.name}
          type={this.props.type ? this.props.type : "text"}
          value={this.props.value}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
        />

        {this.props.errorValue !== "" && (
          <div className="add-user-error">{this.props.errorValue}</div>
        )}
        {this.props.errorValue === "" && (
          <div className="password-validation-messages">
            {this.props.Instruction}
          </div>
        )}
      </div>
    );
  }
}

class AddToFolderModal extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      isInputOpen: false,
      folders: [],
      form: {
        folderName: "",
      },
      folderNameValid: "",
      createFolderInput: false,
      createFolder: false,
      addToLibrarySuccess: false,
      addToLibraryError: false,
      errorMessage: "",
      successMessage: "",
      showAdd: false,
    };
  }
  // INPUT LOGIC
  handleOpen = () => {
    this.setState({ isInputOpen: true });
  };

  handleClose = () => {
    this.setState({ isInputOpen: false });
  };

  handleFolderName = (event) => {
    this.setState({
      form: {
        ...this.state.form,
        [event.target.name]: event.target.value,
      },
    });
  };

  // Close modal on click outside it
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.handleClose();
    }
  };

  // Handle active / selected folders
  handleActive = (item) => {
    const copy = [...this.state.folders];
    let selectedCount = copy.filter((item) => item.isSelected === true).length;
    copy.map((el) => {
      if (el._id === item && el.isSelected === false) {
        selectedCount = selectedCount + 1;
      } else if (
        selectedCount == 1 &&
        el._id === item &&
        el.isSelected === true
      ) {
        selectedCount = selectedCount - 1 < 0 ? 0 : selectedCount - 1;
      }
    });
    this.setState({
      folders: copy.map((el) =>
        el._id === item ? { ...el, isSelected: !el.isSelected } : el
      ),
      showAdd: selectedCount > 0 ? true : false,
    });
  };

  // POST request with folders
  handleAddingToFolder = () => {
    console.log("line no 115");
    const copy = [...this.state.folders];

    if (copy.length > 0) {
      console.log("line no 119");
      // Filter all folders and get their id's for post request
      const allSelected = copy.filter((x) => x.isSelected === true);
      console.log("all selected");
      console.log(allSelected);
      const postArray = [];
      allSelected.forEach((el) => {
        postArray.push({ id: el._id });
      });

      // Call POST request and send only selected folders
      let assign_object = {};
      let pathName = this.props.location.pathname;
      let typeOfResource;

      typeOfResource = pathName.includes("teaching-resources")
        ? "Resource"
        : pathName.includes("books") || pathName.includes("texts")
        ? "Book"
        : pathName.includes("units")
        ? "Unit"
        : pathName.includes("lessons")
        ? "Lessons"
        : pathName.includes("assessments")
        ? "Assessment"
        : this.props?.itemClicked?.type === "Lesson"
        ? "Lessons"
        : this.props?.itemClicked?.type === "teaching-resource" ||
          this.props?.itemClicked?.type === "teaching-resources"
        ? "Resource"
        : this.props?.itemClicked?.type ?? "";

      assign_object = {
        item_id:
          this.props?.itemClicked?._id ?? this.props?.itemClicked?.unit_id,
        item_type: typeOfResource,
      };
      
      if(assign_object.item_id == undefined){
        this.props.handleErrorSnackbar("Please select at least one item to add in folder");
      }else if (!typeOfResource) {
        this.props.handleErrorSnackbar("Type of resource missing");
      } else if (!assign_object.item_id) {
        console.log("sklakskla123");

        this.props.handleErrorSnackbar("Item has no ID");
      } else {
        console.log("line no 159");
        postArray.forEach((folder) => {
          this.props
            .postFolderAssignmentAction(folder.id, assign_object)
            .then((res) => {
              if (
                res.statusCode === 201 &&
                res.message.message === "Resource Created"
              ) {
                this.props.handleSuccessSnackbar();
                this.props.handleClose();
              } else if (res?.message) {
                this.props.handleErrorSnackbar();
              }
            });
        });
      }
    }
  };

  handleCreateFolderSnackbar = ({ isError = false }) => {
    if (isError) {
      this.setState({ addToLibraryError: true });
      setTimeout(() => {
        this.setState({ addToLibraryError: false });
      }, 3000);
    } else {
      this.setState({ addToLibrarySuccess: true });
      setTimeout(() => {
        this.setState({ addToLibrarySuccess: false });
      }, 3000);
    }
  };

  handleAddingToFolderBulk = () => {
    const copy = [...this.state.folders];
    console.log("line no 200");
    if (copy.length > 0) {
      // Filter all folders and get their id's for post request
      const allSelected = copy.filter((x) => x.isSelected === true);
      const postArray = [];
      allSelected.forEach((el) => {
        postArray.push({ id: el._id });
      });

      // Call POST request and send only selected folders
      const allSelectedItems = [...this.props.selectedItems];
      const selectedIDs = [];
      const selectedTypes = [];

      allSelectedItems.forEach((el) => {
        selectedIDs.push(el.item_id);
        selectedTypes.push(el.item_type);
      });

      let assign_object = {
        item_id: selectedIDs,
        item_type: selectedTypes,
      };

      postArray.forEach((folder) => {
        authAPI.bulkAddToFolder(folder.id, assign_object).then((res) => {
          if (res.statusCode === 201) {
            this.props.handleSuccessSnackbar();
            this.props.handleClose();
          } else if (res?.message) {
            this.props.handleErrorSnackbar(res?.message);
          }
        });
      });
    }
  };

  // Load all folders
  getAllFolders = () => {
    this.props
      .getFoldersAction(1, "", "", localStorage.is_lite)
      .then((resp) => {
        const allFolders = [...resp.rows];
        allFolders.forEach((folder) => {
          Object.assign(folder, { isSelected: false });
        });
        this.setState({ folders: allFolders });
      });
  };

  // Check if input is empty
  validateInput() {
    let isGood = true;
    if (
      this.state.form.folderName.length === 0 ||
      !this.state.form.folderName
    ) {
      this.setState({ folderNameValid: "Folder Name cannot be blank." });
      isGood = false;
    } else {
      this.setState({ folderNameValid: "" });
    }
    return isGood;
  }

  // POST request for creating new folder
  handleCreatingNewFolder = () => {
    if (this.validateInput()) {
      const postNewFolder = authAPI.postFolder({
        name: this.state.form.folderName,
      });

      postNewFolder
        .then((response) => {
          if (response.statusCode === 201 || response.statusCode === 200) {
            this.setState({
              form: {
                folderName: "",
              },
              createFolderInput: false,
              folderNameValid: "",
              createFolder: false,
            });
            this.handleClose();
            this.getAllFolders();
            this.handleCreateFolderSnackbar({ isError: false });
          } else {
            this.showError(response.message);
            this.handleCreateFolderSnackbar({ isError: true });
            setTimeout(() => {
              this.clearError();
            }, 3000);
          }
        })
        .catch(function (error) {
          console.log("exception occurred: ", error);
        });
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.getAllFolders();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  showError = (message) => {
    this.setState({ errorMessage: message });
  };

  showSuccess = (message) => {
    this.setState({ successMessage: message });
  };

  clearError = () => {
    if (this.state.errorMessage !== "") {
      this.setState({ errorMessage: "" });
    }
  };

  clearSuccess = () => {
    if (this.state.successMessage !== "") {
      this.setState({ successMessage: "" });
    }
  };

  render() {
    return (
      <>
        <div className="add-to-folder-modal-overlay">
          <div className="add-to-folder-modal-wrapper" ref={this.wrapperRef}>
            <div className="add-to-folder-modal-header">
              <div className="add-to-folder-modal-header-text">
                <img src={addToFolderIcon} alt="Add To Library" />{" "}
                <p>Add to Folder</p>{" "}
              </div>
              <div
                className="add-to-folder-modal-close-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.handleClose();
                }}
              >
                <img
                  src={closeButtonIcon}
                  style={{ width: "16px", height: "16px" }}
                  alt="Close Button"
                />
                <div>Cancel</div>
              </div>
            </div>
            <div className="add-to-folder-modal-content">
              {this.state.errorMessage && (
                <div style={{ color: "red" }}>{this.state.errorMessage}</div>
              )}
              {this.state.successMessage && (
                <div style={{ color: "green" }}>
                  {this.state.successMessage}
                </div>
              )}
              <div className="create-folder-modal-container">
                {this.state.isInputOpen ? (
                  <div className="create-folder-modal-button-input">
                    <TextBox
                      type="text"
                      placeholder="Enter Folder Name"
                      name="folderName"
                      errorValue={this.state.folderNameValid}
                      onChange={this.handleFolderName}
                    />

                    <div
                      style={{ margin: "0 10px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.handleCreatingNewFolder();
                      }}
                    >
                      <img
                        src={checkMarkDark}
                        style={{ height: "20px", width: "20px" }}
                        alt="OK"
                      />
                    </div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.handleClose();
                      }}
                    >
                      <img
                        src={XIcon}
                        style={{ height: "25px", width: "25px" }}
                        alt="CLOSE"
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className="create-folder-modal-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.handleOpen();
                    }}
                  >
                    <span>+</span> Create A New Folder
                  </div>
                )}
              </div>
              {this.state.folders !== [] && this.state.folders !== undefined ? (
                this.state.folders.map((folder, index) => {
                  return (
                    <AddToFolderSingleItem
                      key={folder._id}
                      id={folder._id}
                      isSelected={folder.isSelected}
                      index={index}
                      isLast={this.state.folders.length === index + 1}
                      title={folder.name}
                      edited={folder.edited_at}
                      handleActive={this.handleActive}
                    />
                  );
                })
              ) : (
                <div>There are no folders at the moment</div>
              )}
            </div>
            <div>
              <div
                className={
                  this.state.showAdd == false
                    ? "add-to-folder-modal-confirm-button-disabled"
                    : "add-to-folder-modal-confirm-button"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  this.props?.selectedItems?.length
                    ? this.handleAddingToFolderBulk()
                    : this.handleAddingToFolder();
                }}
              >
                <img src={checkMark} alt="Add Button" /> Add
              </div>
            </div>
          </div>
        </div>
        <SnackBarModule
          addToLibrarySuccess={this.state.addToLibrarySuccess}
          addToLibraryError={this.state.addToLibraryError}
        />
      </>
    );
  }
}

export default connect(null, actions)(withRouter(AddToFolderModal));
