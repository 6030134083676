import React, {Component} from 'react'
import LogoImg from '../assets/icons/SVG/icon-schoolwide.svg';

class privacyPolicy extends Component {
    render(){
        return(
            <div>
                <div className="imgcontainer">
                    <img src={LogoImg} alt="Avatar" className="avatar"/>
                </div>
                <div style={{"position":"absolute","width":"85%","margin":"2% 5%"}}>
                    <h3>SCHOOLWIDE PRIVACY POLICY</h3>
                    <span>
                        The Schoolwide Privacy Policy describes how Schoolwide and its parents, affiliates, and subsidiaries treat personal information when you use Schoolwide.com services, including information provided when you use Schoolwide.com or any of Schoolwide’s websites, including Fundamentals Unlimited. 
                    </span>
                    <ol>
                    <li>
                        Personal Information
                        <ul>
                            <li>
                                Browsing: You can access information and content on Schoolwide’s websites without having an account. You also can contact us through Schoolwide.com or by emailing us directly, without having to register for an account.
                            </li>
                            <li>
                                Your Schoolwide Account: For some activities on Schoolwide.com, including using Fundamentals Unlimited, you need an account. We ask for some personal information when you create an account, including your name, job title, school district or company name, email address, and a password. The information is used to protect your account from unauthorized access.
                            </li>
                            <li>
                                Usage Information: When you use Schoolwide.com and Fundamentals Unlimited, we may record information about your site usage, such as the pages you click on.
                            </li>
                            <li>
                                Content Uploaded to the Site: Any personal information or content that you voluntarily disclose online may be viewed and used by others.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Student Information
                        <ul>
                            <li>For some activities on Schoolwide.com, including the use of Fundamentals Unlimited, you may need to upload student information, such as first name, last name, and an email address (for access purposes).</li>
                            <li>Schoolwide.com will not advertise or send any messaging, commercial or otherwise, via email directly to students.</li>
                            <li>Schoolwide’s Fundamentals Unlimited utilizes the Google Classroom API to access a teacher’s classroom and student roster data to allow the teacher to import student names and email addresses into their “Class” roster on the Fundamentals platform. After the import, we store the associated student emails and names and use them for class assignments and class notifications. We do not share or utilize the student information in any other capacity.</li>
                        </ul>
                    </li>
                    <li>
                        Uses
                        <ul>
                            <li>If you submit personal information, we may use that information to operate, maintain, and improve the features and functionality of our websites and to process any flagging activity or communication you send to us.</li>
                            <li>We do not use your email address or other personal information to send commercial or marketing messages without your consent. We may use your email address without further consent for non-marketing or administrative purposes, (such as notifying you of major website changes or for customer service purposes). By creating an account, you agree that we may contact you by email and that we may store your details for future contact. You may receive relevant marketing communications from Schoolwide, but you can unsubscribe at any time if these emails are not of interest to you.</li>
                            <li>We use cookies, web beacons, and log file information to (a) store information so that you will not have to re-enter it during your visit or the next time you visit; (b) provide customized, personalized content and information; (c) monitor the effectiveness of our marketing campaigns; (d) monitor aggregate metrics, such as total number of visitors and pages viewed; and (e) track your entries, submissions, and status in promotions, sweepstakes, and contests.</li>
                        </ul>
                    </li>
                    <li>
                        Disclosure of Information
                        <ul>
                            <li>We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties for commercial purposes. This does not include trusted third parties who assist us in operating our website, conducting our business, or serving you, long as those parties agree to keep this information confidential. We may release account and other personal information when we believe such a release is appropriate in order to comply with the law; to enforce or apply our Conditions of Use and other agreements; or to protect the rights, property, or safety of Schoolwide, our users, or others. We may transfer the personally identifiable information we collect to a third party in connection with a sale of our business. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</li>
                        </ul>
                    </li>
                    <li>
                        Your Choices
                        <ul>
                            <li>If you have a Schoolwide.com account, you may update your personal profile information, email preferences, and privacy settings at any time by visiting your account profile page.</li>
                            <li>You may control the information that is available to other users by editing your Schoolwide.com account and the features that are included on your profile page.</li>
                            <li>You may, of course, decline to submit personal information through Schoolwide.com, in which case you can still explore our websites, but Schoolwide may not be able to provide certain content or services to you.</li>
                        </ul>
                    </li>
                    <li>
                        Advertising at Schoolwide.com
                        <ul>
                            <li>Schoolwide strives to provide you with relevant and useful advertising. We may use a range of sources, including cookies, web beacons, IP addresses, usage data, and other non-personal information about your computer or device (such as browser type and operating system) to provide you with relevant advertising. If you are logged into your account, we may also show you advertising based on the information you have provided to us in your Schoolwide.com account.</li>
                            <li>Occasionally, at our discretion, our website may include information or advertising by third parties or offer third-party products or services and may contain links to other websites. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. A link does not constitute an endorsement of the content, viewpoint, accuracy, opinions, policies, products, services, security, or accessibility of that website.</li>
                        </ul>
                    </li>
                    <li>
                        How We Secure Your Information
                        <ul>
                            <li>
                                We work to protect the security of your personal information by using a variety of security measures. Schoolwide maintains several technical and organizational processes to help ensure that information collected is secure, including limiting the number of people who have physical access to our database servers and password protections that guard against unauthorized access. SSL encryption algorithms have also been integrated into our website as part of our continuing commitment to the security of electronic content and the electronic transmission of information. Schoolwide will collect only information about customers that is needed and relevant. Information collected will not be stored for longer than is necessary for the purposes described in this Privacy Policy or to otherwise meet applicable legal requirements.
                            </li>
                        </ul>
                    </li>
                </ol>   
                </div>
            </div>
        );
    }
    
}

export default privacyPolicy
