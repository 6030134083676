import React,{Component} from  'react'
import EReader from './EReader'
import "../../assets/styles/ereader.scss"

class EReaderComponent extends Component{

    componentWillReceiveProps(ownProps){
        console.log(ownProps.params);
    }

    render(){
        return(
            <div>
                <EReader />
            </div>
        );
    }
}

export default EReaderComponent