import React, {Component} from 'react'
import { Alert } from 'react-bootstrap'
import "../assets/styles/login.scss"
import * as actions from '../redux/actions'
import {connect} from 'react-redux'
import {Link} from 'react-router'
import ResendLink from './ResendLink'
import createClass from 'create-react-class';
import iconSchoolWide from '../assets/icons/SVG/icon-schoolwide.svg';

class ForgotPassword extends Component {
    constructor(){
        super();
        this.state = {
            username : "",
            myError: "",
            emailError:"",
            showResendLink: false,
            sendProcessing: false,
            showTrialMessage: false,
            noUserMessage: false
        };

        this.handleUserNameInput = this.handleUserNameInput.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    handleUserNameInput(event){
        this.setState({
            username : event.target.value
        });
    }

    handleError(myerr) {
        this.setState({
            myError: myerr
        });
    }

    handleEmailError(myerr) {
        this.setState({
            emailError: myerr
        });
    }

    resetErrors() {
        this.setState({
            myError:"",
            emailError: ""
        });
    }

    validateForm(u) {
        let hasErrors = false;
        let noOfErrors = 0;
        // eslint-disable-next-line
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (u ==='') {
            hasErrors = true;
            noOfErrors = noOfErrors + 1;
            this.handleError("The form contains "+noOfErrors+" error.");
            this.handleEmailError("Email cannot be blank.");
        }

        else if (!re.test(u)) {
            hasErrors = true;
            noOfErrors = noOfErrors + 1;
            this.handleError("The form contains "+noOfErrors+" error.");
            this.handleEmailError("Please enter a valid email address.");
        }

        else{
            hasErrors = false;
        }
        return !hasErrors;
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.submitForm();
        }
    };

    submitForm(){
        let username = this.state.username;
        this.resetErrors();
        if (this.validateForm(username)) {
            this.setState({
                sendProcessing: true,
                noUserMessage: false,
                emailError: false
            });
            this.props.recoverPasswordAction(username).then((resp) => {
                if(resp.info === "No user found with this email ID") {
                    this.setState({
                        sendProcessing: false,
                        noUserMessage: true,
                        emailError: true
                    });
                    // console.log(resp.info);
                } else {
                    if(resp.info.message === "Trial Login expired"){
                        this.setState({showTrialMessage: true});
                    }else{
                        this.setState({showTrialMessage: false});
                    }
                    this.setState({showResendLink: true});
                }
            });
        }

    }

    render() {
        return (
            <div className="bg-color">
                {this.state.showResendLink ? <ResendLink emailAddress={this.state.username} showTrialMessage={this.state.showTrialMessage}/>:
                <div className="login-box">
                    <div className="imgcontainer">
                        <img src={iconSchoolWide} alt="Avatar" className="avatar"/>
                    </div>
                    <div className="forgot-password-message">
                        Please enter your email address to receive a reset link.
                    </div>
                    <If test={this.state.myError}>
                        <Alert id="alert-danger" bsStyle="danger">
                            <strong>
                                {this.state.myError}
                            </strong>
                        </Alert>
                    </If>
                    {
                        this.state.noUserMessage &&
                        <div className="trial-expired-box">
                            <Alert id="alert-danger" bsStyle="danger" style={{textAlign: 'center'}}>
                                <strong>
                                No user found with the given email address. Please try again!
                                </strong>
                            </Alert>
                        </div>
                    }
                    <div className="align-textbox">
                        <span className='login-icons icon-icon-email'/>
                        <input id={(this.state.emailError ? "red-border" : "")} className='userid' type="text" placeholder="Email" value={this.state.username} name="uname" required onKeyPress={this.handleKeyPress} onChange={this.handleUserNameInput}/>
                        <If test={this.state.emailError}>
                            <div className="error-format">
                                {this.state.emailError}
                            </div>
                        </If>
                    </div>
                    <div className="align-button">
                        <button className="format-button"
                                onClick={()=>
                                {
                                    this.submitForm();
                                }}
                                disabled={this.state.sendProcessing}>
                                    {(this.state.sendProcessing ? 'Processing...' : 'Send')}
                        </button>
                    </div>
                    <div className="align-link">
                        <Link to='/'><span style={{color: "#666666",textDecoration: "underline"}}>&larr;&nbsp; Return to Log In</span></Link>
                    </div>
                </div>}
            </div>
        );
    }
}

let If = createClass({
    render: function() {
        if (this.props.test) {
            return this.props.children;
        }
        else {
            return false;
        }
    }
});

const mapStateToProps = (state) => {
    return {
        loginStatus : state.User.LoginStatus,
        path:state.Location
    }
};

export default connect(mapStateToProps, actions)(ForgotPassword)