import React from 'react'
import ToolTip from 'react-portal-tooltip'

class AddPopOverMobile extends React.Component {

    render() {
      let style1 = {
        style: {
          border: "1px solid #F5F5F5",
          borderRadius: "8px",
          backgroundColor: "rgba(255,255,255)",
          boxShadow: "0 2px 12px 0 rgba(0,0,0,0.5)",
          padding: "20px 20px 20px 20px",
          ...this.props.tooltipStyle
        },
        arrowStyle: {
          color: "white",
          borderColor: "#F5F5F5"
        }
      };

      return (
        <ToolTip id={`${this.props.type}-${this.props.myId}`}
            active={this.props.isTooltipActive}
            position={this.props.position}
            arrow={this.props.arrow || null}
            parent={`#${this.props.myId}`}
            style={style1}
            tooltipTimeout={200}
            >
            <div style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                marginBottom: '5px',
                color: 'rgba(153, 153, 153, 0.65)'
              }}
              onClick={this.props.hideTooltip}>
              X
            </div>
            {this.props.children}
        </ToolTip>
      )
    }
}

export default AddPopOverMobile;
