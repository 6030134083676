import React,{Component} from  'react'
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router';
import {Col, Row} from 'react-bootstrap'
import {browserHistory} from 'react-router'
import * as actions from '../../redux/actions'
import Datetime from 'react-datetime'
import PopOver from '../../components/PopOver'
import AddPopOverMobile from '../shared/AddPopOverMobile'
import GlobalSearch from '../../components/GlobalSearchComponent'
import MyMenu from '../../containers/MyMenu'
import _ from 'lodash'
import constants from '../../constants/index'
import PropsMultiStateIcon from "../../components/PropsMultiStateIcon"
let clearPopOver;

class DetailedHeader extends Component{

    constructor (props) {
        super(props);
        this.state = {
            itemType: "",
            itemId: "",
            showAdd: false,
            showDatePicker: false,
            showAddedToLibrary: false,
            showAddedToPlanner: false,
            showAddError: false,
            showDateError: false,
            showAddMobile: false,
            showDatePickerMobile: false,
            showAddedToLibraryMobile: false,
            showAddedToPlannerMobile: false,
            showAddErrorMobile: false,
            showDateErrorMobile: false,
            addErrorMessage: "",
            dateErrorMessage: "",
            showMenu:false,
            firstLoad:false,
            activeInfoIcon:false,
            showFolderOptions:false,
            folders:[]
        };
        this.addTimeout = undefined;
        this.datePickerTimeout = undefined;
        this.addedToLibraryTimeout = undefined;
        this.addedToPlannerTimeout = undefined;
        this.addErrorTimeout = undefined;
        this.dateErrorTimeout = undefined;
        this.timeoutMS = 3000;
        this.stopTour = _.debounce(this.props.stopJoyride, 1000, {leading: true, trailing: false});
        this.redirectXanedu = this.redirectXanedu.bind(this);
    }

    componentWillReceiveProps (nextProps) {
        this.setState ({
            itemType: nextProps.itemType,
            itemId: nextProps.itemId
        });
    }

    componentDidMount(){
        if(window.history.length===1){
            this.setState({firstLoad:true})
        }
    }

    onDatePicker = () => {
        const { showAdd = "" } = this.state
        if(showAdd || this.state.showAddMobile) {
            this.setState({ showAdd: false, showAddMobile: false });
            this.addClearTimeout();
        }

        if(this.state.showAddedToLibrary || this.state.showAddedToLibraryMobile) {
            this.setState({showAddedToLibrary : false, showAddedToLibraryMobile : false});
            this.addedToLibraryClearTimeout();
        }

        if(this.state.showAddedToPlanner || this.state.showAddedToPlannerMobile) {
            this.setState({showAddedToPlanner : false, showAddedToPlannerMobile : false});
            this.addedToPlannerClearTimeout();
        }

        if(this.state.showAddError || this.state.showAddErrorMobile) {
            this.setState({showAddError : false, showAddErrorMobile : false});
            this.addErrorClearTimeout();
        }

        if(this.state.showDateError || this.state.showDateErrorMobile) {
            this.setState({showDateError : false, showDateErrorMobile : false});
            this.dateClearTimeout();
        }

        if (this.state.showDatePicker === false || this.state.showDatePickerMobile === false) {
            this.setState({showDatePicker: true, showDatePickerMobile: true});
        }
    };

    onAdd = () => {
        this.stopTour();
        if(this.state.showDatePicker || this.state.showDatePickerMobile) {
            this.setState({showDatePicker : false, showDatePickerMobile : false});
            this.dateClearTimeout();
        }

        if(this.state.showAddedToLibrary || this.state.showAddedToLibraryMobile) {
            this.setState({showAddedToLibrary : false, showAddedToLibraryMobile : false});
            this.addedToLibraryClearTimeout();
        }

        if(this.state.showAddedToPlanner || this.state.showAddedToPlannerMobile) {
            this.setState({showAddedToPlanner : false, showAddedToPlannerMobile : false});
            this.addedToPlannerClearTimeout();
        }

        if(this.state.showAddError || this.state.showAddErrorMobile) {
            this.setState({showAddError : false, showAddErrorMobile : false});
            this.addErrorClearTimeout();
        }

        if(this.state.showDateError || this.state.showDateErrorMobile) {
            this.setState({showDateError : false, showDateErrorMobile : false});
            this.dateClearTimeout();
        }

        if (this.state.showAdd === false || this.state.showAddMobile === false) {
            this.setState({showAdd: true, showAddMobile: true});
        }
    };

    onClose=function(key){
      this.setState(key);
    };

    addStartTimeout = () => {
        this.addClearTimeout();
        this.addTimeout = setTimeout(() => {
            this.setState({showAdd: false});
            this.addTimeout = undefined;
        }, this.timeoutMS);
    };

    addClearTimeout = () => {
        if(this.addTimeout) {
            clearTimeout(this.addTimeout);
            this.addTimeout = undefined;
        }
    };

    dateStartTimeout = () => {
        this.dateClearTimeout();
        this.datePickerTimeout = setTimeout(() => {
            this.setState({showDatePicker: false});
            this.datePickerTimeout = undefined;
        }, this.timeoutMS);
    };

    dateClearTimeout = () => {
        if(this.datePickerTimeout) {
            clearTimeout(this.datePickerTimeout);
            this.datePickerTimeout = undefined;
        }
    };

    addedToLibraryStartTimeout = () => {
        this.addedToLibraryClearTimeout();
        this.addedToLibraryTimeout = setTimeout(() => {
            this.setState({showAddedToLibrary: false});
            this.addedToLibraryTimeout = undefined;
        }, this.timeoutMS);
    };

    addedToLibraryClearTimeout = () => {
        if(this.addedToLibraryTimeout) {
            clearTimeout(this.addedToLibraryTimeout);
            this.addedToLibraryTimeout = undefined;
        }
    };

    addedToPlannerStartTimeout = () => {
        this.addedToPlannerClearTimeout();
        this.addedToPlannerTimeout = setTimeout(() => {
            this.setState({showAddedToPlanner: false});
            this.addedToPlannerTimeout = undefined;
        }, this.timeoutMS);
    };

    addedToPlannerClearTimeout = () => {
        if(this.addedToPlannerTimeout) {
            clearTimeout(this.addedToPlannerTimeout);
            this.addedToPlannerTimeout = undefined;
        }
    };

    addErrorStartTimeout = () => {
        this.addErrorClearTimeout();
        this.addErrorTimeout = setTimeout(() => {
            this.setState({showAddError: false});
            this.addErrorTimeout = undefined;
        }, this.timeoutMS);
    };

    addErrorClearTimeout = () => {
        if(this.addErrorTimeout) {
            clearTimeout(this.addErrorTimeout);
            this.addErrorTimeout = undefined;
        }
    };

    dateErrorStartTimeout = () => {
        this.dateErrorClearTimeout();
        this.dateErrorTimeout = setTimeout(() => {
            this.setState({showDateError: false});
            this.dateErrorTimeout = undefined;
        }, this.timeoutMS);
    };

    dateErrorClearTimeout = () => {
        if(this.dateErrorTimeout) {
            clearTimeout(this.dateErrorTimeout);
            this.dateErrorTimeout = undefined;
        }
    };

    goToLibrary = () => {
        if (this.state.itemType === "Lesson" || this.state.itemType === "Correlation") {
            browserHistory.push("/library/resources");
        }
        else
        {
            browserHistory.push("/library/" + this.state.itemType.toLowerCase() + "s");
        }
        setTimeout(function(){window.location.reload()},100);
    };

    handleDate = (date) => {
        this.props.postScheduledTasksAction(date._d, this.state.itemId, this.state.itemType === "Correlation" ? this.state.itemType+"s" : this.state.itemType)
        .then((response)=>{
            //console.log("response",response);
            if(response.status === 201){
                //console.log("added to planner")
                this.setState({
                    showDatePicker: false,
                    showDatePickerMobile: false,
                    showAddedToPlanner: true,
                    showAddedToPlannerMobile: true
                });
                this.dateClearTimeout();
                this.addedToPlannerClearTimeout();
                this.props.savePlannerDateAction(date._d);
                setTimeout(()=> {
                    this.props.lessonAddedSuccessAction('')
                }, 3000)
            } else{
                console.log("failed " + response.status);
                this.setState({
                    showDatePicker: false,
                    showDatePickerMobile: false,
                    showDateError: true,
                    showDateErrorMobile: true,
                    dateErrorMessage: "Error: Unable to add item to planner."
                });
                this.dateClearTimeout();
                this.dateErrorStartTimeout();
            }
        })
        .catch((error)=>{
            console.log("failed");
            this.setState({
                showDatePicker: false,
                showDatePickerMobile: false,
                showDateError: true,
                showDateErrorMobile: true,
                dateErrorMessage: "Error: Unable to add item to planner."
            });
            this.dateClearTimeout();
            this.dateErrorStartTimeout();
        });
    };

    handleAddToLibrary= () => {
        /**
      you might need to work here
        */
        let action = null;

        if (this.state.itemType === "Resource") {
            action = this.props.postAddTeachingResourceToLibraryAction;
        }
        else if (this.state.itemType === "Book") {
            action = this.props.postAddBookToLibraryAction;
        }
        else if (this.state.itemType === "Assessment") {
            action = this.props.postAddAssessmentToLibraryAction;
        }
        else if (this.state.itemType === "Lesson") {
            action = this.props.postAddLessonToLibraryAction;
        }
        else if (this.state.itemType === "Correlation") {
            action = this.props.postAddCorrelationToLibraryAction;
        }

        if (action !== null) {

            if(this.state.showAddedToPlanner) {
                this.setState({showAddedToPlanner : false});
            }

            action(this.state.itemId)
            .then((response) => {
                /*if (response.statusCode === 200) {
                    this.props.getFoldersAction(1,'','',false).then((resp)=>{
                        if(resp.rows.length > 0){
                            const searchResults = resp.rows.map((folder,i)=>
                                <Row key={folder+i} className="shared-folder-items">
                                    <span className="add-folder-span">
                                        <img src={require('../../assets/images/folder.png')} style={{width:"15px","marginRight":"4px"}}/>
                                        {folder.name}
                                    </span>                                   
                                </Row>
                            );
                          this.setState({
                            folders:searchResults,
                            showFolderOptions:true
                          },
                            ()=>{
                                clearPopOver = setTimeout(()=>this.setState({
                                    showAdd: false,
                                    showAddMobile: false,
                                    showAddedToLibrary: true,
                                    showAddedToLibraryMobile: true,
                                    showFolderOptions:false
                                }),5000)
                                this.addClearTimeout();
                                this.addedToLibraryStartTimeout();
                            }
                         );
                        }
                      }).catch((err)=>{
                        console.log(err);
                      });
                    
                } else {*/
                if (response.statusCode === 200) {
                    this.setState({
                        showAdd: false,
                        showAddMobile: false,
                        showAddedToLibrary: true,
                        showAddedToLibraryMobile: true
                    });
                    this.addClearTimeout();
                    this.addedToLibraryStartTimeout();
                } else {
                    this.setState({
                        showAdd: false,
                        showAddMobile: false,
                        showAddError: true,
                        showAddErrorMobile: true,
                        addErrorMessage: "Error: Unable to add item to Library."
                    });
                    this.addClearTimeout();
                    this.addErrorStartTimeout();
                }
            })
            .catch((error)=>{
              let errMsg = (error.response.data.statusCode === 400 && error.response.data.message.message === "Resource already exists for object") ?
                           (<span><strong>{this.props.name}</strong> already exists in your Library.</span>) :
                            "Error: Unable to add item to Library.";
              this.setState({
                  showAdd: false,
                  showAddMobile: false,
                  showAddError: true,
                  showAddErrorMobile: true,
                  addErrorMessage: errMsg
              });
              this.addClearTimeout();
              this.addErrorStartTimeout();
            });
        }
        else {
            console.log("Bad Type");
        }
    };

    addToFolder=()=>{
       browserHistory.push({pathname:'/library/addfolder',state:{libraryItemId:this.state.itemId,libraryItemType:this.state.itemType,isLibrary:false}});
       this.props.saveAssignmentItemAction(this.state.itemType,this.state.itemId);
    }

    showSearch=()=>{
        this.props.onSearchClick().then(() => {
            this.refs.right.show();
        });
    };

    toggleSearch=()=>{
        this.props.infoClick().then(() => {
            this.refs.right.show();
        });
    };

    handleAddToUnit=()=>{
        browserHistory.push({pathname:'/add-to-unit/'+this.props.itemId,state:{lessonName:this.props.name}})
    };

    handleGoBack=()=>{
        // close the window when opening lesson on new tab
        if(this.props.itemType==='Lesson' && (document.referrer.indexOf('unit')>-1) ){
            window.close();
        }else{
            browserHistory.goBack()
        }
    };

    handleOpening = () => {
      if (this.props.bookId) {
          browserHistory.push("/books/" + this.props.bookId  + "/reader");
      } else if (this.props.file) {
          if(this.props.resourceType === 'Videos'){
              window.open(this.props.file);
          } else {
              window.open(constants.filesUrl + this.props.file?.replace("../",''));
          }
      }
    };

    handleOpeningPdf = () => {
        window.open(constants.filesUrl + this.props.printableFile?.replace("../",''));
    }

    publishDATAToSAML = () =>{
        window.open("/schoolwidefun/api/v1/users/samlp?item_id="+this.props.itemId+"&item_type="+this.props.itemType,"_blank");
    };
    
    redirectXanedu=()=>{
        //window.open('https://www.xanedu.com','_blank');
        window.open('https://my.sharedbook.com/serve/nm/schoolwide/sso/acs.html','_blank');
    }

    render(){
        let width= window.innerWidth;
        const showAddContent = (
          <div onMouseLeave={this.addStartTimeout} onMouseEnter={this.addClearTimeout}>
              <div className="modal-text-header-styles">
                  ADD OPTIONS
              </div>
              <div onClick={this.addToFolder}
                     className="header-popup-option">
                    Add to My Folders
                </div>
                {this.props.preview != true &&
                  <div onClick={this.handleAddToLibrary} className='header-popup-option'>
                      Add to My Library
                  </div>
                }
                {!this.props.bookPriceText && this.props.publish && 
                  <div onClick={this.publishDATAToSAML} className='header-popup-option'>
                      Publish 
                  </div>
                }
                {this.props.bookPriceText && this.props.publish && localStorage.user_type !== 'Student' &&
                  <div onClick={this.publishDATAToSAML} className='header-popup-option'>
                      Publish {this.props.bookPriceText}
                  </div>
                }
                {/* {this.state.itemType==='Lesson' &&
                  <div onClick={this.handleAddToUnit} className='header-popup-option'>
                      Add to My Units
                  </div>
                } */}
          </div>
        );

        const showAddedToLibraryContent = (
          <div style={width > 576 ? {height:"150px",width:"400px",padding: "20px"} : {}} onMouseLeave={this.addedToLibraryStartTimeout} onMouseEnter={this.addedToLibraryClearTimeout}>
              <div className="item-added">
                  <div style={{marginBottom: 10}} className="selected-checkbox-icon-lesson icon-icon-checkbox"/>
                  <strong>{this.props.name}</strong> was successfully added to your Library!
              </div>
              <div>
                  <span>
                          <button className="go-to-my-library-planner" onClick={this.goToLibrary}>
                              GO TO MY LIBRARY
                          </button>
                  </span>
              </div>
          </div>
        );

        const showAddErrorContent = (
          <div onMouseLeave={this.addErrorStartTimeout} onMouseEnter={this.addErrorClearTimeout}>
              <i className="icon-icon-warning" style={{color:"#D0021B", fontSize:"30px"}}/>
              <span style={{position:"absolute",left:58, paddingRight:5}}>{this.state.addErrorMessage}</span>
          </div>
        );

        const showAddedToPlannerContent = (
          <div style={width > 576 ? {height:"150px",width:"400px",padding: "20px"} : {}} onMouseLeave={this.addedToPlannerStartTimeout} onMouseEnter={this.addedToPlannerStartTimeout}>
              <div className="item-added">
                  <div style={{marginBottom: 10}} className="selected-checkbox-icon-lesson icon-icon-checkbox"/>
                  <strong>{this.props.name}</strong> was successfully added to Planner!
              </div>
              <div>
                  <span>
                      <button className="add-to-planner-library" onClick={this.handleAddToLibrary}>
                          ADD TO MY LIBRARY
                      </button>
                  </span>
                  {/* <span>
                      <Link to="/planner">
                          <button className="go-to-my-library-planner">
                              GO TO PLANNER
                          </button>
                      </Link>
                  </span> */}
              </div>
          </div>
        );

        const showDateErrorContent = (
          <div onMouseLeave={this.dateErrorStartTimeout} onMouseEnter={this.dateErrorClearTimeout}>
              <i className="icon-icon-warning" style={{color:"#D0021B", fontSize:"30px"}}/>
              {this.state.dateErrorMessage}
          </div>
        );
        let name = this.props.name;
        return(
            <div className="search-bar">
                <Row>
                    <Col md={6} sm={6} xs={12}>
                        <span className="add-padding-back-icon">
                            <i onClick={this.handleGoBack}
                                className="chevron-left-demo-icon icon-icon-back"
                                />
                        </span>
                        {this.props.name &&
                            <span>{name}</span>
                        }
                    </Col>
                    <Col md={6} sm={6} xs={12} className="lesson-header-right">
                        <div className="header-icon-right">
                            <div id="pdfReader" className="lesson-header-icons">
                                {((this.props.printableFile && this.props.printableFile !== "") || (this.props.file && this.props.file !== "")) &&
                                        <i onClick={this.handleOpening}
                                        className="icon-icon-reader-circle-solid"
                                        />
                                }
                            </div>
                        </div>
                        <div className="header-icon-right">
                            {/*This is the calendar button*/}
                            {/* If user is lite, remove onClick, pointer default, style = {{color gray}}*/}
                            {this.state.showDatePicker && width > 576 &&
                                <PopOver isTooltipActive={true} count="showDatePicker" arrow="right" width="351px" height="340px" padding="0px">
                                    <div onMouseLeave={this.dateStartTimeout} onMouseEnter={this.dateClearTimeout}>
                                        <Datetime closeOnSelect='false' disableOnClickOutside='true' locale="en" open={true} onChange={this.handleDate}/>
                                    </div>
                                </PopOver>
                            }
                            {
                                this.props.lesssonAddedSuccess && this.props.lesssonAddedSuccess.data &&this.props.lesssonAddedSuccess.data.success &&
                                    <div className='bottom-margin-browse'>
                                        <div className="unit-success-popover">
                                            <div className="popover-success-icon icon-icon-checkbox"/>
                                            <div className="popover-success-text">
                                                <strong>{this.props.name}</strong> was successfully added to your unit(s)!
                                            </div>
                                        </div>
                                    </div>
                            }
                            {this.state.showDatePickerMobile && width <= 576 &&
                              <div className="mobile-datetime-wrapper">
                                  <div className="datetime-close-x"
                                    onClick={this.onClose.bind(this, {showDatePickerMobile: false})}>
                                    X
                                  </div>
                                  <Datetime className="mobile-datetime" closeOnSelect={false} disableOnClickOutside={true} open={true} onChange={this.handleDate}/>
                              </div>
                            }
                            {this.state.showAddedToPlanner && width > 576 &&
                                <PopOver isTooltipActive={true} count="showDatePicker" arrow="right" height="150px" width="400px">
                                    {showAddedToPlannerContent}
                                </PopOver>
                            }
                            {this.state.showAddedToPlannerMobile && width <= 576 &&
                                <AddPopOverMobile
                                    isTooltipActive={true}
                                    myId="showDatePicker"
                                    arrow="right"
                                    position="bottom"
                                    hideTooltip={this.onClose.bind(this, {showAddedToPlannerMobile: false})}>
                                    {showAddedToPlannerContent}
                                </AddPopOverMobile>
                            }
                            {this.state.showDateError && width > 576 &&
                                <PopOver isTooltipActive={true} count="showDatePicker" arrow="right" height="120px" width="400px">
                                    {showDateErrorContent}
                                </PopOver>
                            }
                            {this.state.showDateErrorMobile && width <= 576 &&
                                <AddPopOverMobile
                                    isTooltipActive={true}
                                    myId="showDatePicker"
                                    arrow="right"
                                    position="bottom"
                                    hideTooltip={this.onClose.bind(this, {showDateErrorMobile: false})}>
                                    {showDateErrorContent}
                                </AddPopOverMobile>
                            }
                        </div>

                        <div className="header-icon-right">
{/* This is the add button */}
                            <div id="showAdd" className="lesson-header-icons" style={{visibility: 'visible'}}>
                                {localStorage.user_type !== "Student" &&
                                    <i  onClick={localStorage.is_lite === 'true' ? null : this.onAdd}
                                        onMouseLeave={this.addStartTimeout}
                                        id="i-show-add-icon"
                                        className="browse-demo-icon icon-icon-add-circle-solid"
                                        style={localStorage.is_lite === 'true' ? {color: 'lightgray', cursor: 'default'} : {}}
                                    />
                                }
                            </div>
                            {this.state.showAdd && width > 576 &&
                                <PopOver isTooltipActive={true} count="showAdd" arrow="right" width="200px">
                                    {showAddContent}
                                </PopOver>
                            }
                            {this.state.showFolderOptions &&
                                <PopOver isTooltipActive={true} count="showAdd" arrow="right" width="200px">
                                    <div>
                                        <h5>Select folder below:</h5> 
                                        {this.state.folders}
                                    </div>
                                </PopOver>

                            }
                            {this.state.showAddMobile && width <= 576 &&
                                <AddPopOverMobile
                                    isTooltipActive={true}
                                    myId="showAdd"
                                    arrow="right"
                                    position="bottom"
                                    hideTooltip={this.onClose.bind(this, {showAddMobile: false})}
                                    tooltipStyle={{padding: '15px'}}
                                    >
                                    {showAddContent}
                                </AddPopOverMobile>
                            }
                            {this.state.showAddedToLibrary && width > 576 &&
                                <PopOver isTooltipActive={true} count="showAdd" arrow="right" height="150px" width="400px">
                                  {showAddedToLibraryContent}
                                </PopOver>
                            }
                            {this.state.showAddedToLibraryMobile && width <= 576 &&
                                <AddPopOverMobile
                                    isTooltipActive={true}
                                    myId="showAdd"
                                    arrow="right"
                                    position="bottom"
                                    hideTooltip={this.onClose.bind(this, {showAddedToLibraryMobile: false})}
                                    >
                                  {showAddedToLibraryContent}
                                </AddPopOverMobile>
                            }
                            {console.log('DetailedHeader - success - failed: ', this.state.showAddError, this.state.showAddedToLibrary)}
                            {this.state.showAddError && (width > 576) ?
                                <PopOver isTooltipActive={this.state.showAddError && width > 576} count="showAdd" arrow="right" height="100px" width="300px">
                                  {showAddErrorContent}
                                </PopOver>
                                :
                                ''
                            }
                            {this.state.showAddErrorMobile && width <= 576 &&
                                <AddPopOverMobile
                                    isTooltipActive={true}
                                    myId="showAdd"
                                    arrow="right"
                                    position="bottom"
                                    hideTooltip={this.onClose.bind(this, {showAddErrorMobile: false})}
                                    >
                                  {showAddErrorContent}
                                </AddPopOverMobile>
                            }
                        </div>
                        <div className="header-icon-right">
                            <span className="header-vertical-line-detailed">
                                <PropsMultiStateIcon id='someText'
                                                enabled={true}
                                                active={this.props.infoClicked}
                                                onClick={this.toggleSearch}
                                                activeClasses="info-demo-icon icon-icon-info-circle-solid"
                                                inactiveClasses="info-demo-icon  icon-icon-info-circle-outline"
                                                disabledClasses="info-demo-icon browse-demo-icon icon-icon-info-circle-outline"
                                                tooltipText="Info"
                                />
                            </span>
                        </div>
                        <div className="header-icon-right">
                                <PropsMultiStateIcon enabled={true}
                                                    active={this.props.searchClicked}
                                                    onClick={this.showSearch}
                                                    sharedClasses="header-icon-detailed"
                                                    activeClasses="icon-icon-search-circle-solid"
                                                    inactiveClasses="icon-icon-search-circle-outline icon-blue"
                                                    disabledClasses="icon-icon-search-circle-outline"
                                                    tooltipText="Search"
                                />
                        </div>
                    </Col>
                </Row>
                <div>
                    <MyMenu ref="right" alignment="right" showMenu={this.props.searchClicked} showScroll={false}>
                        <GlobalSearch focus={this.props.searchClicked && window.innerWidth > 767} itemType={this.props.itemType} />
                    </MyMenu>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return ({
        lesssonAddedSuccess:state.LessonAddedSuccess
    });
};

export default connect(mapStateToProps, actions)(withRouter(DetailedHeader))
