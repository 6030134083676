import authAPI from "../../../api/authAPI";

export const GET_MACKIN_USER_BOOK_URL_PENDING =
  "GET_MACKIN_USER_BOOK_URL_PENDING";
export const GET_MACKIN_USER_BOOK_URL_SUCCESS =
  "GET_MACKIN_USER_BOOK_URL_SUCCESS";
export const GET_MACKIN_USER_BOOK_URL_FAILED =
  "GET_MACKIN_USER_BOOK_URL_FAILED";
export const GET_MACKIN_USER_BOOK_URL_DATA_CLEAR =
  "GET_MACKIN_USER_BOOK_URL_DATA_CLEAR";

export const clearMackinData = (data) => (dispatch) => {
  dispatch({
    type: GET_MACKIN_USER_BOOK_URL_DATA_CLEAR,
  });
};

export const buyBookOnMackin = (data) => (dispatch) => {
  dispatch({
    type: GET_MACKIN_USER_BOOK_URL_PENDING,
  });
  return authAPI.byBookOnMackin(data).then((response) => {
    try {
      if (response && response.data) {
        dispatch({
          type: GET_MACKIN_USER_BOOK_URL_SUCCESS,
          payload: response.data,
        });
      } else if (response.response) {
        dispatch({
          type: GET_MACKIN_USER_BOOK_URL_FAILED,
          payload: response.response.data.message,
        });
      } else {
        dispatch({
          type: GET_MACKIN_USER_BOOK_URL_FAILED,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_MACKIN_USER_BOOK_URL_FAILED,
        payload: error,
      });
    }
  });
};
