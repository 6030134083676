import React,{Component} from 'react'
import {Row,Col} from 'react-bootstrap'
import CollapsibleComponent from '../../containers/Details/CollapsibleComponent';
import mySolidArrowRight from '../../assets/icons/SVG/my-solid-arrow-right.png';
import mySolidArrow from '../../assets/icons/SVG/my-solid-arrow.png';

class NewUnitVocabularyContent extends Component{
    constructor (props) {
        super(props);
        this.state = {
            texts : []
        };
        if(this.props.mentorTexts && this.props.mentorTexts.length >0 ){
            this.props.mentorTexts.map((lesson) => {
               this.state.texts= this.state.texts.concat(lesson);
            });
        }
        this.props.lessons.map((lesson) => {
            if(lesson.mentorTexts && lesson.mentorTexts.length>0){
               this.state.texts= this.state.texts.concat(lesson.mentorTexts);
            }
        });
    }
        
    createSubArrays(wordsArr){
        if(!wordsArr) {
            return [];
        }

        let instNumber= Math.ceil(wordsArr.length/6);
        let arrayWithSotting = [];
        for(let i=0;i<6;i++){
            let arr = wordsArr.slice(instNumber*i,(i+1)*instNumber);
            arrayWithSotting.push(arr);
        }
        return arrayWithSotting;
    }

    render(){
        return(
            <div className='list-of-items items-background'>
                <CollapsibleComponent collapsed={false} header="MENTOR TEXTS VOCABULARY" headerStyle={{fontSize: "18px",fontWeight: 700, color: "#4A4A4A", borderBottom: "1px solid #CCD2DE", paddingBottom: "15px", paddingTop: "15px" }} openToggleButton={<img src={mySolidArrowRight}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={mySolidArrow}  className="arrow-icon  rss opacity"/>}>
                        {this.state.texts && this.state.texts.length>0?this.state.texts.map((mentorTextsDetail,i)=>(
                            <div>
                                {i===0?null:<hr/>}
                                <Row className='text-sub-heading' key={i}>{mentorTextsDetail.name}</Row>
                                <Row className='vocabulary-content' >
                                    {!mentorTextsDetail.vocabulary || mentorTextsDetail.vocabulary.length===0?<div className='word-style'>No Vocabulary to Display</div>:
                                        this.createSubArrays(mentorTextsDetail.vocabulary).map((mentorSubArr,i)=>(
                                            <Col md={2} sm={6} xs={12} >
                                                {mentorSubArr.map((mentorTextsVocabulary,j) =>(
                                                    <div className='word-style'>{mentorTextsVocabulary}</div>
                                                ))}
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>
                        )):(
                            <div className='word-style'>
                                No Vocabulary to Display
                            </div>
                            )}
                    </CollapsibleComponent>
            </div>
        );
    }
}

export default NewUnitVocabularyContent
