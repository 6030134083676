import {SAVE_LOCATION} from '../actions'


const initialState={};

const SaveLocationReducer=(state=initialState,action)=>{
    switch(action.type){
       
        case SAVE_LOCATION:
            return Object.assign({}, state, {location:action.payload});
        
        default:
            return state;
    }
}

export default SaveLocationReducer;
