import React from 'react'
import {Link} from 'react-router'
import AddPopOver from './AddPopOver'
import AddFolderPopOver from './AddFolderPopOver'
import authAPI from '../../api/authAPI'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux';
import Datetime from 'react-datetime'
import constants from '../../constants'
import {browserHistory} from 'react-router'
let clearPopOver,clearDatePicker;

class PopOverAllResources extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isTooltipActive: false,
            success: false,
            failed: false,
            errorMessage: "",
            toggleClass: "checkbox-icon icon-icon-checkbox",
            showDatePicker: false,
            addedToPlanner: false,
            addedToMyLibrary: false,
            //showFolderOptions:false,
            multipleItems: [],
            //folders:[]
        };
        this.addToLibrary = this.addToLibrary.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.showDatePicker = this.showDatePicker.bind(this);
        this.redirectXanedu = this.redirectXanedu.bind(this);
        this.timer = null;
    }

    showTooltip = () => {
        //if (this.timer) clearTimeout(this.timer)
        if(!this.state.isTooltipActive){
            this.setState({
                isTooltipActive: true,
                success: false,
                showDatePicker: false
            })
        }else{
            this.setState({
                isTooltipActive: false,
                success: false,
                showDatePicker: false
            })
        }

    }

    hideTooltip = () => {
        //if (this.timer) clearTimeout(this.timer)
        this.timer = setTimeout( () => this.setState({
            isTooltipActive: false,
        }), 30000 )
    }
    showDatePicker() {
        this.setState({
            showDatePicker: true,
            success:false,
            isTooltipActive:false
        },
            ()=>{clearDatePicker = setTimeout(()=>{this.setState({showDatePicker: false})},3000)}
        );
    }
    addToLibrary(){
       this.setState({
          isTooltipActive: false,
        });
       
           this.props.addToLibrary(this.props._id)
               .then((response)=>{
                   if(response.statusCode === 200){
                      /*this.props.getFoldersAction(1,'','',false).then((resp)=>{
                        if(resp.rows.length > 0){
                          this.setState({
                            folders:resp.rows,
                            showFolderOptions:true
                          },
                             ()=>{
                              clearPopOver = setTimeout(()=>this.setState({
                                showFolderOptions:false,
                                success : true,
                                addedToMyLibrary : true,
                                addedToPlanner: false
                              }),3000)
                            }
                         );
                        }
                        setTimeout(()=>this.setState({success:false}),5000)
                      }).catch((err)=>{
                        console.log(err);
                      });*/

                       this.setState({
                           success : true,
                           addedToMyLibrary : true,
                           addedToPlanner: false
                       },
                           ()=>{
                            console.log(this.state.success);
                            clearPopOver = setTimeout(()=>this.setState({success: false}),3000)
                          }
                       );
                   }else{
                       this.setState({
                           success : false,
                           failed : true,
                           errorMessage: response.response.data.message.message
                       },
                           ()=>{
                            clearPopOver = setTimeout(()=>this.setState({failed: false}),3000)
                          }
                       );
                   }
               })
               .catch((error)=>{
                  console.log("exception occured");
                  this.setState({
                    success: false,
                    failed: true,
                    errorMessage: "An exception occured"
                  },
                    ()=>{
                      clearPopOver = setTimeout(()=>this.setState({failed: false}),3000)
                    }
                );
               });
    }

    addToFolder=()=>{
      let itemType = this.props.type;
      if(itemType == 'units'){
        itemType = 'Unit';
      }else if(itemType == 'assessments'){
        itemType = 'Assessment';
      }
      browserHistory.push({pathname:'/library/addfolder',state:{libraryItemId:this.props._id,libraryItemType:itemType,isLibrary:false}});
      this.props.saveAssignmentItemAction(itemType,this.props._id);
    }

    publishDATAToSAML = () =>{
      //this.props.postSAMLData(this.props._id, this.props.type);

      window.open("/schoolwidefun/api/v1/users/samlp?item_id="+this.props._id+"&item_type="+(this.props.type == 'assessments' ? 'Assessment' : this.props.type),"_blank");
    };

    redirectXanedu=()=>{
        //window.open('https://www.xanedu.com','_blank');
        window.open('https://my.sharedbook.com/serve/nm/schoolwide/sso/acs.html','_blank');
    }
    handleAddToUnit=()=>{
        browserHistory.push({pathname:'/add-to-unit/'+this.props._id,state:{lessonName:this.props.name}})
    };

    goToLibrary=()=>{
      browserHistory.push('/library/'+constants.resourceLibraryMap[this.props.type]); 
      setTimeout(function(){window.location.reload()},100);
    }

    handleDate(date){
      this.props.scheduleTask(date._d, this.props._id, this.props.type)
          .then((response) => {
              if (response.status === 201) {
                  this.setState({
                      showDatePicker: false,
                      success: true,
                      addedToPlanner: true,
                      addedToMyLibrary: false
                  }
                  ,
                      clearPopOver = setTimeout(() => {
                      this.setState({success: false})
                   }, 3000)
                  )
              } else {
                  this.setState({showDatePicker: false})
              }
          })
          .catch((error)=>{
            this.setState({showDatePicker: false})
          });
    }

    render() {
        const { disabled } = this.props;
        let myId = this.props.count+"_"+Math.floor((Math.random() * 10) + 1);
        return (
            <div>
                {
                <div
                      onClick={this.props.multiSelectMode ? this.props.handleClickForResourcesAddBtn.bind(this, this.props.item) : () => {this.showTooltip()}}
                     id={myId}
                     className={`${disabled ? 'disabled' : ''} ${this.props.multiSelectMode ?
                         (
                             ((this.props.type === "Resource" || this.props.type === "Lesson" || this.props.type === "Correlations") ?
                                this.props.multipleItems[this.props.type].indexOf(this.props._id) === -1 :
                                this.props.multipleItems.indexOf(this.props._id) === -1) ?
                             "checkbox-icon icon-icon-checkbox":"selected-checkbox-icon icon-icon-checkbox"
                         ):
                         "lesson-search-add-icon icon-icon-add-circle-solid" }`
                       }
                 />
                 }

                {this.state.isTooltipActive &&
                <AddPopOver isTooltipActive={this.state.isTooltipActive} myId={myId} position="top">
                    <div>
                        <div className="modal-text-header-styles">
                            ADD OPTIONS
                        </div>
                        {localStorage.user_type !== 'Student' &&
                          <div onClick={this.addToFolder}
                               className="modal-text-styles">
                              Add to My Folders
                          </div>  

                        }
                        
                        {/*{!this.props.preview &&
                        <div onClick={this.addToLibrary}
                             className="modal-text-styles">
                            Add to My {localStorage.user_type === 'Student' ? 'Texts' : 'Library'}
                        </div>}*/}

                        {!this.props.preview &&
                        <div onClick={this.addToLibrary}
                             className="modal-text-styles">
                            Add to My Library
                        </div>}
                        { this.props.type != 'Book' && this.props.item.publish &&
                          <div onClick={this.publishDATAToSAML}
                             className="modal-text-styles">
                            Publish
                          </div>
                        }
                        { this.props.type == 'Book' && this.props.item.publish && localStorage.user_type !== 'Student' &&
                          <div onClick={this.publishDATAToSAML}
                             className="modal-text-styles">
                            Publish {this.props.bookPrice}
                          </div>
                        }
                                         
                        {/* {localStorage.user_type !== 'Student' &&
                        <div onClick={this.showDatePicker}
                             className="modal-text-styles">
                            Add to Planner
                        </div>
                        } */}
                       {/* {localStorage.user_type !== 'Student' && this.props.type==='Lesson' &&
                       <div onClick={this.handleAddToUnit}
                            className="modal-text-styles">
                            Add to My Units
                        </div>
                        } */}
                    </div>
                </AddPopOver>}
                {/*{this.state.showFolderOptions && 
                  <AddFolderPopOver myId={this.props.count} folderItems={this.state.folders}></AddFolderPopOver>
                }*/}

                {this.state.showDatePicker &&
                <div onMouseOver={()=>{ clearTimeout(clearDatePicker);this.setState({showDatePicker:true})}}
                     onMouseLeave={() => {clearDatePicker= setTimeout(() => this.setState({showDatePicker : false}),3000)}}
                     className="mobile-datetime-wrapper"
                >
                    <Datetime  open={true} onChange={this.handleDate}/>
                </div>
                }

                {this.state.success &&
                    <div className="single-select-popover">
                    <div
                        onMouseEnter={()=>{clearTimeout(clearPopOver); this.setState({success:true})}}
                        onMouseLeave={()=> { clearPopOver = setTimeout(()=>{this.setState({success: false})},3000 )}}
                    >
                        <div className="item-added">
                            <div style={{marginBottom: 10}} className="selected-checkbox-icon-add-multiple icon-icon-checkbox"/>
                                <div style={{textAlign: "left"}}>
                                    <strong>{this.props.name}</strong> was successfully added to {
                                        this.state.addedToMyLibrary && localStorage.user_type === 'Student' &&
                                        <span> My Texts!</span>
                                    }
                                    {
                                        this.state.addedToMyLibrary && localStorage.user_type !== 'Student' &&
                                         <span> your Library!</span>
                                    }
                                    {
                                        this.state.addedToPlanner &&
                                        <span> Planner!</span>
                                    }
                                </div>
                                </div>
                                    {this.state.addedToMyLibrary && localStorage.user_type === "Student"  && <div>
                                        <span>
                                            <button className="go-to-my-library-planner" onClick={()=>browserHistory.push('/library/'+constants.resourceLibraryMap[this.props.type])}>
                                                GO TO MY TEXTS
                                            </button>
                                         </span>
                                    </div> }
                                 {this.state.addedToMyLibrary && localStorage.user_type !== "Student"  && <div>
                                <span>
                                  <button className="go-to-my-library-planner" onClick={this.goToLibrary}>
                                       GO TO MY LIBRARY
                                  </button>
                                 </span>
                             </div>
                        }
                        {this.state.addedToPlanner &&
                          <div>
                            <span><button onClick={this.addToLibrary} className="add-to-planner-library">ADD TO MY LIBRARY</button></span>
                            {/* <span><Link to="/planner"><button className="go-to-my-library-planner">GO TO PLANNER</button></Link></span> */}
                            </div>
                        }
                    </div>
                    </div>}
                {this.state.failed &&
                     <div className="single-select-popover">
                         <div
                            onMouseEnter={()=>{clearTimeout(clearPopOver);this.setState({failed:true})}}
                            onMouseLeave={()=>{clearPopOver= setTimeout(()=>{this.setState({failed: false})},3000);}}
                         >
                        <div className="icon-icon-warning" style={{fontSize: 30,color:"#d0021b",float:"left",paddingTop:3}}/>

                        {this.state.errorMessage && localStorage.user_type === 'Student' && <div style={{position:"absolute",left: 60,right:20,textAlign:"left"}}>
                              <span><strong>{this.props.name}</strong> already exists in My Library</span>
                             </div>
                        }

                        {this.state.errorMessage && localStorage.user_type !== 'Student' && <div style={{position:"absolute",left: 60,right:20,textAlign:"left"}}>
                        <span><strong>{this.props.name}</strong> already exists in your Library</span>
                    </div>
                }
             </div>
             </div>}
            </div>
        )
    }
}

export default connect(null, actions)(PopOverAllResources);
