import {
    GET_GOAL_TYPES_REQUEST,
    GET_GOAL_TYPES_SUCCESS,
    GET_GOAL_TYPES_FAILURE
} from '../actions';


const initialState = {};

const GetGoalTypesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GOAL_TYPES_REQUEST:
            return Object.assign({},state, {asyncStatus:  'PENDING'});
        case GET_GOAL_TYPES_SUCCESS:
            return Object.assign({},state, {asyncStatus:  'SUCCESS', data: action.payload});
        case GET_GOAL_TYPES_FAILURE:
            return Object.assign({},state, {asyncStatus:  'FAILURE', data: action.payload});
        default:
            return state;
    }
};

export default GetGoalTypesReducer;