import React, { Component } from 'react'
import { Radio } from 'react-bootstrap'
import _ from 'lodash'

/**
 *  Props:
 *    radios: {
 *        nameOfButton: {
 *            checked: true | undefined,
 *            disabled: true | false
 *        }
 *    }
 *
 *    onClickCallback: Function to call when a radio button has been selected or deselected.
 */
export default class DeselectableRadioBtnGroup extends Component {

    constructor(props){
      super(props);
      this.state = {
        pathname: this.props.pathname,
        radios: {
          // name: {
          //   disabled: true | false,
          //   checked: true | undefined
          // }
        }
      }
    }

    componentWillMount(){
      this.setState({radios: this.props.radios, pathname: this.props.pathname});
    }

    componentWillReceiveProps(props){
      console.log(this.props);
      console.log(props);
      // If radio is set in props, it will overwrite the current state on rerenders.
      // if(this.state.pathname !== props.pathname && !_.isMatch(this.state.radios, props.radios)){
          this.setState({radios: props.radios, pathname: props.pathname});
      // }
    }

    render(){
      return (
        <div style={{paddingLeft: '20px'}}>
          { _.map(_.keysIn(this.state.radios), ( radioBtnName => {
              let { disabled, checked } = this.state.radios[radioBtnName];

              return (
                <Radio  id={`radio_strand_${radioBtnName}`}
                        key={radioBtnName}
                        name={this.props.groupIdentifier}
                        disabled={disabled}
                        checked={checked}
                        onClick={e => {this.props.onClickCallback(e, radioBtnName)}}>
                  { radioBtnName }
                </Radio>
              )
            }))
          }
        </div>
      )
    }
}
