import React from 'react'
import {Row, Col, Radio, Button, Label, FormControl, ControlLabel, FormGroup} from 'react-bootstrap'
import ThreeStateBtn from './ThreeStateBtn'

export default class StudentEditSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isPicturePassword: props.user_data.password_type_id === 2,
            resetBtn: false
        };
        this.onResetClicked = this.onResetClicked.bind(this);
    }

    onPicturePasswordToggled(bool){
        this.setState({
          isPicturePassword: bool,
          resetBtn: !this.state.resetBtn
        });
    }

    onResetClicked(_id, isPicturePassword) {
        return this.props.resetPasswordAction(_id, isPicturePassword)
                .then((responseAndError)=>{
                  return responseAndError.statusCode === 200 ?
                          Promise.resolve(responseAndError) :
                          Promise.reject(responseAndError);
                });
    }

    render() {
        return (
            <div id="studentEditSidebar">
                <Row>
                    <Col sm={12}>
                        <div className="student-sidebar-user-icon ">
                            <i className="icon-icon-user list-item-user-img" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <ControlLabel style={{fontFamily: "ProximaNova", color: "gray"}}>
                                Name
                            </ControlLabel>
                            <FormControl
                                type="text"
                                readOnly={true}
                                value={this.props.user_data.full_name}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <ControlLabel style={{fontFamily: "ProximaNova", color: "gray"}}>
                                ID
                            </ControlLabel>
                            <FormControl
                                type="text"
                                readOnly={true}
                                value={this.props.user_data._id}/>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <Radio name="resetPasswordType"
                            checked={!this.state.isPicturePassword}
                            onChange={this.onPicturePasswordToggled.bind(this, false)}
                        >
                            Standard Password
                        </Radio>
                        <Radio name="resetPasswordType"
                            checked={this.state.isPicturePassword}
                            onChange={this.onPicturePasswordToggled.bind(this, true)}
                        >
                            Picture Password
                        </Radio>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <ThreeStateBtn reset={this.state.resetBtn} isPicturePassword={this.state.isPicturePassword} onClickPromise={this.onResetClicked.bind(this, this.props.user_data._id, this.state.isPicturePassword)}>
                            Reset Password
                        </ThreeStateBtn>
                    </Col>
                </Row>
            </div>
        );
    }
}
