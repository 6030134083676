export const SHOW_WHAT_YOU_KNOW_SUBMIT_REVIEW = 'SHOW_WHAT_YOU_KNOW_SUBMIT_REVIEW';
export const SHOW_WHAT_YOU_KNOW_SUBMIT_RESPONSE = 'SHOW_WHAT_YOU_KNOW_SUBMIT_RESPONSE';
export const SHOW_WHAT_YOU_KNOW_SWITCH_TABS = 'SHOW_WHAT_YOU_KNOW_SWITCH_TABS'

export const submitReaderReview = (book_id, readerReview) => {
    return dispatch => {
        dispatch({
            type: SHOW_WHAT_YOU_KNOW_SUBMIT_REVIEW,
            payload: readerReview
        });
    }
};

export const submitReaderResponse = (response_id, response) => {
    return dispatch => {
        dispatch({
            type: SHOW_WHAT_YOU_KNOW_SUBMIT_RESPONSE,
            payload: {
                id: response_id,
                response: response
            }
        });
    }
};

