import React, { Component } from "react";
import { Router, Route, browserHistory } from "react-router";

import authAPI from "../api/authAPI";
import { confirmAlert } from "react-confirm-alert";
import LibrarySingleFolderContent from "../containers/LibrarySingleFolderContent";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Registration from "../pages/Registration";
import Lite from "../pages/Lite";
import Browse from "../pages/Browse";
import NewHome from "../pages/NewHome";
import SSOAuthenticate from "../pages/SSOAuthenticate";
import MyLibrary from "../components/MyLibrary/MyLibraryComponent";
import Root from "../containers/Root";
import StudentRoot from "../containers/StudentRoot";
import UnitsDetailed from "../components/UnitsComponent/UnitsDetailedView";
import TeachingResources from "../containers/TeachingResources";
import TeachingResourcesContent from "../containers/TeachingResources/TeachingResourcesContent";
import UserProfile from "../components/UserAccount/UserProfile";
import Units from "../containers/Units";
import UnitsContent from "../containers/Units/UnitsContent";
import Assessments from "../containers/Assessments";
import AssessmentsContent from "../containers/Assessments/AssessmentsContent";
import Lessons from "../containers/Lessons";
import LessonsContent from "../containers/Lessons/LessonsContent";
import LessonsDetailed from "../components/LessonsComponent";
import ResourcesDetailed from "../components/ResourcesComponent";
import BooksDetailed from "../components/BooksComponent";
import AssessmentDetailed from "../components/AssessmentsComponent";
import CorrelationDetailed from "../components/CorrelationsComponent";
import AppendicesDetailed from "../components/Appendices";
import NotFound from "../components/NotFound";
import NewUnit from "../components/NewUnitComponent";
import { Provider } from "react-redux";
import store from "../redux/store";
import SysAdmin from "../components/AdminPortal/SysAdmin";
import SchoolAdmin from "../components/AdminPortal/SchoolAdmin";
import MyClass from "../components/MyClass/MyClass";
import AddGroup from "../components/MyClass/AddGroup";
import NewBooks from "../components/Books/NewBooksComponent";
import ForgotPassword from "../components/ForgotPassword";
import Student from "../components/StudentPortal/Student";
import Assign from "../components/MyLibrary/Assign";
import Addfolder from "../components/MyLibrary/Addfolder";
import EReaderComponent from "../components/EReaderComponent";
import PicturePassword from "../components/PicturePassword";
import TrailAccount from "../components/TrailAccount/UserTrailDetails";
import JoyrideCustomComponent from "../components/JoyrideCustomComponent/JoyrideCustomComponent";
import AddLessonToUnit from "../components/LessonsComponent/AddLessonToUnitComponent";
import constants from "../constants";
//import Consultation from "../components/Consultation";
import Vbookvideo from "../components/LessonsComponent/vBookVideoComponent";
import PrivacyPolicy from "../containers/privacyPolicy";

class Navigation extends Component {
  constructor() {
    super();
    this.state = {};

    this.checkSession = this.checkSession.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("stage") === "false") {
      // this.unlisten = browserHistory.listen((location) => {
      //   window.userPageHit();
      // });
    }
  }

  componentWillUnmount() {
    if (localStorage.getItem("stage") === "false") {
      //this.unlisten();
    }
  }

  blockAccessForPrintOnlyUser = (currentPath) => {
    if (
      (localStorage.user_type === "Print Only" ||
        localStorage.is_lite === "true") &&
      (currentPath.includes("lesson") ||
        currentPath.includes("unit") ||
        currentPath.includes("assessment") ||
        currentPath.includes("book") ||
        currentPath.includes("library"))
    ) {
      browserHistory.push(constants.DEFAULT_PRINT_ONLY_USER_PATH);
    }
  };

  async checkSession() {
    console.log("checkSession started now");
    let cName = "";
    let NmeVal;
    let pCOOKIES = document.cookie.split("; ");
    let userException = ["dejital1@gmail.com", "ladiadavies@gmail.com"];
    if (!userException.includes(localStorage.getItem("username"))) {
      console.log("pCOOKIES: ", pCOOKIES);
      if (localStorage.getItem("stage") === "false") {
        //window.userPageHit();
        //console.log("userPageHit");
      }
      for (let bb = 0; bb < pCOOKIES.length; bb++) {
        NmeVal = [];
        NmeVal = pCOOKIES[bb].split("=");
        if (NmeVal[0] === "sessionId") {
          cName = unescape(NmeVal[1]);
          console.log("cName: ", cName);
        }
      }
      let uuid = localStorage.getItem("uuid");
      let isActiveSession = true;
      await authAPI.validateUserSessionByUUID(uuid).then((resp) => {
        if (resp.length > 0) isActiveSession = resp[0].active;
      });

      let currentPath = window.location.pathname;
      let currentPathIsRoot = currentPath === constants.PATHS.ROOT;

      this.blockAccessForPrintOnlyUser(currentPath);

      if (currentPath !== "/") {
        //localStorage.setItem("prevPathLoc", currentPath);
      }

      if (!cName) {
        if (
          !currentPathIsRoot &&
          ~currentPath.indexOf(constants.PATHS.LOGIN) &&
          !~currentPath.indexOf(constants.PATHS.SIGNUP)
        ) {
          browserHistory.push(constants.PATHS.ROOT);
        }
      } else if (isActiveSession === false) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1>
                  User session has ended. You have logged in through another
                  device.
                </h1>
                <button
                  onClick={() => {
                    document.cookie =
                      "sessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

                    localStorage.clear();
                    browserHistory.push(constants.PATHS.ROOT);

                    setTimeout(function () {
                      window.location.reload();
                    }, 100);
                  }}
                >
                  Continue
                </button>
              </div>
            );
          },
        });
      } else {
        if (
          currentPathIsRoot ||
          currentPath.indexOf(constants.PATHS.LOGIN) > -1 ||
          ~currentPath.indexOf(constants.PATHS.SIGNUP)
        ) {
          console.log("localStorage.user_type: ", localStorage.user_type);
          if (localStorage.user_type === "Student") {
            browserHistory.push(constants.DEFAULT_STUDENT_PATH);
          } else if (
            localStorage.user_type === "Print Only" ||
            localStorage.is_lite === "true"
          ) {
            browserHistory.push(constants.DEFAULT_PRINT_ONLY_USER_PATH);
          } else if (
            localStorage.user_type === "Unlimited" ||
            localStorage.user_type === "System Admin"
          ) {
            browserHistory.push(constants.DEFAULT_UNLIMITED_USER_PATH);
          } else {
            browserHistory.push(constants.DEFAULT_NON_STUDENT_PATH);
          }
        }
      }
    } else {
      console.log("user belongs to exception group");
    }
  }

  render() {
    return (
      <>
        <Provider store={store}>
          <Router history={browserHistory}>
            <Route path="/" component={Login} onEnter={this.checkSession} />
            <Route
              path="/login/:domain"
              component={Login}
              onEnter={this.checkSession}
            />
            <Route
              path="/signup"
              component={Registration}
              onEnter={this.checkSession}
            />
            <Route path="/lite" component={Lite} onEnter={this.checkSession} />
            <Route path="/authenticate" component={SSOAuthenticate} />
            <Route
              path="/password"
              component={UserProfile}
              onEnter={this.checkSession}
            />
            <Route path="/try" component={TrailAccount} />
            <Route path="/privacyPolicy" component={PrivacyPolicy} />
            <Route path="/picture-password" component={PicturePassword} />
            <Route
              path="/picture-password/:domain"
              component={PicturePassword}
            />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={UserProfile} />
            <Route path="/set-password" component={UserProfile} />
            <Route path="/" component={JoyrideCustomComponent}>
              <Route
                path="/user-profile"
                component={UserProfile}
                onEnter={this.checkSession}
              />
              <Route
                path="/my-class/add-group"
                component={AddGroup}
                onEnter={this.checkSession}
              />

              <Route
                path="/library/assign"
                component={Assign}
                onEnter={this.checkSession}
              />
              <Route
                path="/library/addfolder"
                component={Addfolder}
                onEnter={this.checkSession}
              />
              <Route
                path="/books/:uid/reader(/:tab)"
                component={EReaderComponent}
                onEnter={this.checkSession}
              />
              <Route
                path="/add-to-unit/:id"
                component={AddLessonToUnit}
                onEnter={this.checkSession}
              />
              <Route path="/video/:uid" component={Vbookvideo} />

              <Route
                path="/"
                component={StudentRoot}
                //onEnter={this.checkSession}
              >
                <Route
                  path="/home"
                  component={Student}
                  onEnter={this.checkSession}
                />
              </Route>
              <Route
                path="/"
                component={Root}
                //onEnter={this.checkSession}
              >
                <Route
                  path="/new-home"
                  component={NewHome}
                  onEnter={this.checkSession}
                />
                <Route
                  path="/home"
                  component={Student}
                  onEnter={this.checkSession}
                />
                <Route
                  path="/admin"
                  component={SysAdmin}
                  onEnter={this.checkSession}
                />
                <Route
                  path="/sys-admin"
                  component={SysAdmin}
                  onEnter={this.checkSession}
                />
                <Route
                  path="/school-admin/:id"
                  component={SchoolAdmin}
                  onEnter={this.checkSession}
                />
                <Route
                  path="/planner"
                  component={Home}
                  onEnter={this.checkSession}
                />
                <Route
                  path="/my-class"
                  component={MyClass}
                  onEnter={this.checkSession}
                />
                <Route
                  path="/my-class/edit-group"
                  component={MyClass}
                  onEnter={this.checkSession}
                />
                <Route
                  path="/browse"
                  component={Browse}
                  //onEnter={this.checkSession}
                />
                <Route
                  path="/browse/teaching-resources/"
                  component={TeachingResources}
                  //onEnter={this.checkSession}
                >
                  <Route
                    path="/browse/teaching-resources/:strand"
                    component={TeachingResourcesContent}
                    //onEnter={this.checkSession}
                  />
                </Route>
                <Route
                  path="/browse/assessments/"
                  component={Assessments}
                  //onEnter={this.checkSession}
                >
                  <Route
                    path="/browse/assessments/:strand"
                    component={AssessmentsContent}
                    //onEnter={this.checkSession}
                  />
                </Route>
                <Route
                  path="/browse/units/"
                  component={Units}
                  //onEnter={this.checkSession}
                >
                  <Route
                    path="/browse/units/:strand"
                    component={UnitsContent}
                    //onEnter={this.checkSession}
                  />
                </Route>
                <Route
                  path="/browse/books(/:tab)"
                  component={NewBooks}
                  //onEnter={this.checkSession}
                />

                <Route
                  path="/units/:uid(/:tab)"
                  component={UnitsDetailed}
                  //onEnter={this.checkSession}
                />
                <Route
                  path="/new-unit"
                  component={NewUnit}
                  //onEnter={this.checkSession}
                />
                <Route
                  path="/edit-unit/:uid"
                  component={NewUnit}
                  //onEnter={this.checkSession}
                />

                <Route
                  path="/books/:uid"
                  component={BooksDetailed}
                  //onEnter={this.checkSession}
                />
                <Route
                  path="/resources/:uid"
                  component={ResourcesDetailed}
                  //onEnter={this.checkSession}
                />
                <Route
                  path="/assessments/:uid"
                  component={AssessmentDetailed}
                  //onEnter={this.checkSession}
                />
                <Route
                  path="/correlations/:uid"
                  component={CorrelationDetailed}
                  //onEnter={this.checkSession}
                />
                <Route
                  path="/appendices/:uid/unit_id/:id"
                  component={AppendicesDetailed}
                  //onEnter={this.checkSession}
                />

                <Route
                  path="/browse/lessons"
                  component={Lessons}
                  //onEnter={this.checkSession}
                >
                  <Route
                    path="/browse/lessons/:strand"
                    component={LessonsContent}
                    //onEnter={this.checkSession}
                  />
                </Route>

                <Route
                  path="/lessons/:uid(/:tab)"
                  component={LessonsDetailed}
                  //onEnter={this.checkSession}
                />
                {/*<Route path="/AddNewLesson" component={AddNewLesson} onEnter={this.checkSession} />*/}

                <Route
                  path="/library(/:tab)"
                  component={MyLibrary}
                  //onEnter={this.checkSession}
                />

                <Route
                  path="/folders/:id"
                  component={LibrarySingleFolderContent}
                  //onEnter={this.checkSession}
                />

                <Route
                  path="/student/:id"
                  component={Student}
                  onEnter={this.checkSession}
                />
                {/* <Route
                  path="/consultation"
                  component={Consultation}
                  onEnter={this.checkSession}
                /> */}
              </Route>
              <Route
                path="*"
                component={NotFound}
                onEnter={this.checkSession}
              />
            </Route>
          </Router>
        </Provider>
      </>
    );
  }
}

export default Navigation;
