import React,{Component} from  'react';
import LessonsDetailedView from './LessonsDetailedView';




class LessonsComponent extends Component{

    componentWillReceiveProps(ownProps){

    }
    render(){

        return(
            <div>
                <LessonsDetailedView />
            </div>
        );
    }
}



export default LessonsComponent;