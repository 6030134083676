import React, {Component} from 'react'
import {withRouter} from 'react-router'
import "../../assets/styles/myLibrary.scss"
import LibraryHomeContent from '../../containers/LibraryHomeContent';
import NewHeader from '../../containers/NewHeader';
import LibraryResourcesContent from '../../containers/TeachingResources/LibraryResourcesContent';
import LibraryUnitsContent from '../../containers/Units/LibraryUnitsContent';
import LibraryLessonsContent from '../../containers/Lessons/LibraryLessonsContent';
import LibraryAssessmentsContent from '../../containers/Assessments/LibraryAssessmentsContent';
import LibraryTextsContent from '../../containers/LibraryTextsContent';
import LibraryFolderContent from "../../containers/Folders/LibraryFolderContent";
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import constants from '../../constants'
import authAPI from '../../api/authAPI'
import _ from 'lodash'
import * as MackinActions from "../../redux/actions/mackin";
import steps from './stepsMyLibrary'


const c= {
    "": "recents",
    "lessons": "lessons",
    "resources": "teachingResources",
    "books": "books",
    "assessments": "assessments",
    "units": "units",
    "notes-highlights": "notesHighlights",
    "folders": "folders"
};

class MyLibraryComponent extends Component {
    constructor(props) {
       super(props);
       this.state = {
           showMenu: false,
           showFilter: false,
           showFolder: false,
           showPopOver: false,
           mackin_id: null

       };

       this.continueTour = _.debounce(this.props.resetJoyride.bind(this, steps.steps, 5), 100);
     }

    componentDidMount(){
        if(this.props.myLibrary.asyncStatus !== "SUCCESS") {
            this.props.getMyLibraryAction(c[this.props.params.tab || '']);
        } else
            this.props.quietlyInitializeStrandlessAction(constants.normalizeTypeName(this.props.params.tab));
        if(this.props.params.tab && this.props.params.tab !== 'resources' && (this.props.params.tab === "books" || this.props.strand_number)){
            this.setState({showFilter: !!this.props.query});
            this.props.getCustomLibraryFiltersAction(this.props.strand_number, c[this.props.params.tab], this.props.query);
        } else {
            this.props.clearMyLibraryFiltersAction();
        }
          this.props.saveLocation(this.props.location.pathname)
          authAPI.getMySchoolDistrict()
            .then( response => {
                const district = response && response.data
                if (district) {
                    const { mackin_id } = district
                    this.setState({ mackin_id })
                }
            } )
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.params.tab !== this.props.params.tab) {
	          this.props.saveLocation('/library/'+nextProps.params.tab)
            if(nextProps.params.tab !== 'notes-highlights') {
                this.props.getMyLibraryAction(c[nextProps.params.tab || '']);
            }
            //on MyLibrary, strand will be books if the books tab is loaded, which doesn't need a strand.
            if(nextProps.params.tab !== 'books')
                this.props.clearMyLibraryFiltersAction();
        }
        if(!nextProps.params.tab)
            this.setState({showFilter: false});
    }

    showSearch=()=>{
        this.setState({
          showMenu:!this.state.showMenu,
          showFilter: false,
          showFolder: false
        });
    };

    showFilter=()=>{
        this.setState({
          showFilter:!this.state.showFilter,
          showMenu: false,
          showFolder: false,
        });
    };

    showFolder=()=>{
        this.setState({
          showFolder:!this.state.showFolder,
          showMenu: false,
          showFilter: false,
        });
    }

    renderJoyride=()=>{
      if(this.props.location.state && this.props.location.state.beginTour && this.props.location.pathname==='/library/books')
      return true;
    }

    byBook = (bookId) => {
        this.props.buyBookOnMackin(bookId)
    }

    clearMackinData = () => {
        this.props.clearMackinData()
    }

    refreshLibrary = () => {
        this.props.getMyLibraryAction("folders");
    }

    render() {
        return(
            <div style={{height: '100%', width: '100%'}}>
                <NewHeader headerType={'library'} typeOfResource={c}/>
                { this.props.params.tab === 'resources' ?
                    <LibraryResourcesContent resourcesData={this.props.myLibrary?.data?.resources}/>
                    :
                    this.props.params.tab === 'units' ?
                        <LibraryUnitsContent resourcesData={this.props.myLibrary?.data?.units}/>
                        :
                        this.props.params.tab === 'lessons' ?
                            <LibraryLessonsContent resourcesData={this.props.myLibrary?.data?.lessons}/>
                            :
                            this.props.params.tab === 'assessments' ?
                                <LibraryAssessmentsContent resourcesData={this.props.myLibrary?.data?.assessments}/>
                                :
                                this.props.params.tab === 'books' ?
                                    <LibraryTextsContent resourcesData={this.props.myLibrary?.data?.books}/>
                                    :    this.props.params.tab === 'folders' ?
                                        <LibraryFolderContent resourcesData={this.props.myLibrary?.data?.folders} refreshLibrary={this.refreshLibrary}/>
                                        :
                                        <LibraryHomeContent resourcesData={this.props.myLibrary?.data?.recents}/>
                }
            </div>
        )
    }
}

const mapStateToProps = ({ Mackin, Users, MyLibrary, CustomFilters, FilterScale }) => ({
        myLibrary : MyLibrary,
        custom: CustomFilters,
        strand_number: CustomFilters.strand,
        query: FilterScale.query,
        book: Mackin.book,
        errors: Mackin.errors,
        userPermission: Users.hasMore
    })

export default connect(mapStateToProps, { ...actions, ...MackinActions })(withRouter(MyLibraryComponent))
