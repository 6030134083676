import React,{Component} from  'react'
import {Row, Col, Alert} from 'react-bootstrap'
import MultipleSelection from './MultipleSelection'
import ToolTip from 'react-portal-tooltip'
import AssistantTooltipComponent from '../JoyrideCustomComponent/AssistantTooltipComponent'
import SetPicturePassword from './SetPicturePassword'
import '../../assets/styles/userProfile.scss'
import 'bootstrap-sass/assets/stylesheets/_bootstrap.scss';

class TextBox extends Component{

    render(){
        return(
            <div className='textBox-wrapper'>
                <h3> {this.props.valueType} <span className='sidebar-star'>*</span> </h3>
                <input
                    className={
                        (this.props.errorValue === "")? "add-user-form" : "add-user-form add-user-form-error"
                    }
                    name={this.props.name}
                    type={this.props.type?this.props.type:'text'}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                />

                {this.props.errorValue !== "" &&
                    <div className="add-user-error">{this.props.errorValue}</div>
                }
                {this.props.errorValue === "" &&
                    <div className="password-validation-messages">{this.props.Instruction}</div>
                }
            </div>
        );
    }
}

// Accepts the align, value, and fontSize props
class Label extends Component {
    render() {
        return (
            <div className="label-wrapper">
                <h3 style={{textAlign: this.props.align, fontSize: this.props.fontSize}}>{this.props.value}</h3>
            </div>
        )
    }
}

class UserDetails extends Component{

    constructor(props) {
       super(props);
       this.state = {
           form: {
               firstName: "",
               lastName: "",
               email: "",
               password:"",
               newPassword:"",
               confirmPassword:"",

           },
           firstNameValid: "",
           lastNameValid: "",
           emailValid: "",
           passwordValid:"",
           newPasswordValid:"",
           confirmPasswordValid:"",
           changePasswordValid:"",
           changeNewPasswordValid:"",
           changeConfirmPasswordValid:"",
           finalGradeLevels:[],
           stringGrades:[],
           isPasswordGood:true,

       };

       if (this.props.userData) {
           this.state.form.firstName = this.props.userData.first_name;
           this.state.form.lastName = this.props.userData.last_name;
           this.state.form.email = this.props.userData.username;
           this.state.finalGradeLevels = [];

           //taking the string array returned from the backend, and creating object array for the react select component
           for(let i = 0; i < this.props.userData.grades.length; i++)
           {
               this.state.finalGradeLevels.push({"label": this.props.userData.grades[i], "value": this.props.userData.grades[i]});
           }
       }

       this.onChange = this.onChange.bind(this);
    }

    updateGrades(gradeLevels) {
        this.setState({finalGradeLevels: gradeLevels})
    }

    onChange(event)
    {
        
        this.state.form[event.target.name] = event.target.value;
        this.setState({form: this.state.form});
        //let re =/(?=^.{8,}$)(?=(?:.*?\d){1})(?=.*[a-z])(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%^&*]*$/
        let re=/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{7,}$/

        if(event.target.name==="password"){
            this.props.removeError()
            if(this.state.form.password === "") {
                this.setState({changePasswordValid: "Please enter a valid password."});
                this.state.isPasswordGood = false;
                this.props.selectTab("PASSWORD")
            }
            else {
                this.setState({changePasswordValid:''});
                if(re.test(this.state.form.password)){
                    if (this.setState.passwordValid !== ""){
                        this.setState({passwordValid: ""});
                        this.state.form[event.target.name] = event.target.value;
                        this.setState({form: this.state.form});
                        this.state.isPasswordGood = true;
                    }
                }
                else{
                    this.setState({changePasswordValid: "Please enter a valid password."});
                    this.state.isPasswordGood = false;
                    this.props.selectTab("PASSWORD")
                }
            }


        }
        if(event.target.name==="newPassword"){
            if(this.state.form.newPassword === "") {
                this.setState({changeNewPasswordValid: "Please enter a valid password."});
                this.state.isPasswordGood = false;
                this.props.selectTab("PASSWORD")
            }
            else{
                this.setState({changeNewPasswordValid: ""});
                //let re =/(?=^.{8,}$)(?=(?:.*?\d){1})(?=.*[a-z])(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9A-Z!@#$%^&*]*$/
                let re=/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{7,}$/
                if(re.test(this.state.form.newPassword)){
                    if (this.state.newPasswordValid !== ""){
                        this.setState({newPasswordValid: ""});
                        this.state.form[event.target.name] = event.target.value;
                        this.setState({form: this.state.form});
                    }
                        // }else{
                    //     this.setState({newPasswordValid: ""});
                    //     this.state.form[event.target.name] = event.target.value;
                    //     this.setState({form: this.state.form})
                    // }
                }
                else{
                    console.log("in else");
                    this.setState({changeNewPasswordValid: "Passwords must be at least 8 characters and include at least one UPPERCASE, one lowercase, one number, and one of the following: !@#$%^&*"});
                    this.state.isPasswordGood = false;
                    this.props.selectTab("PASSWORD")
                }
               this.setState({newPasswordValid:this.state.changeNewPasswordValid})
               this.props.selectTab("PASSWORD")
            }
        }
        if(event.target.name==="confirmPassword"){

            if(this.state.form.newPassword !== this.state.form.confirmPassword) {
                this.setState({changeConfirmPasswordValid:"Passwords must match."});
                this.props.selectTab("PASSWORD")
            }
            else{
                this.setState({confirmPasswordValid:""});
                this.state.confirmPasswordValid=''
            }
        }
        //console.log(this.state.form)
    }

    // onActiveChange(event) {
    //     this.state.form.isActive = event.target.checked;
    //     this.setState({form: this.state.form});
    // }

    validateInput() {
        let isGood = true;
        let re=/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{7,}$/
        if(this.props.pathname!=='/password' && this.props.pathname!=='/reset-password'){
            this.setState({passwordValid:this.state.changePasswordValid});

            if(this.state.form.firstName === "") {
                this.setState({firstNameValid: "First Name cannot be blank."});
                isGood = false;
                this.props.selectTab("INFO")
            }
            else if (this.state.firstNameValid !== ""){
                this.setState({firstNameValid: ""});
            }

            if(this.state.form.lastName === "") {
                this.setState({lastNameValid: "Last Name cannot be blank."});
                isGood = false;
                this.props.selectTab("INFO");
            }
            else if (this.state.lastNameValid !== ""){
                this.setState({lastNameValid: ""});
            }

            if(this.state.form.newPassword !== "" || this.state.form.password !== "") {
                if(this.state.form.password === "") {
                    this.setState({passwordValid: "Please enter a valid password."});
                    isGood = false;
                    this.props.selectTab("PASSWORD");
                    this.setState({newPasswordValid:this.state.changeNewPasswordValid});
                } else {
                  if(this.state.form.newPassword === ""){
                    isGood = false;
                    this.setState({newPasswordValid: "Please enter a valid password."});
                  } else {
                    this.setState({newPasswordValid:this.state.changeNewPasswordValid});
                  }
                }

                if(this.state.form.newPassword !== this.state.form.confirmPassword) {
                    this.setState({confirmPasswordValid:"Passwords must match."});
                    isGood = false;
                    this.props.selectTab("PASSWORD")
                }
                else{
                    this.setState({confirmPasswordValid:""});
                    this.state.confirmPasswordValid='';
                }
                
            }

        }
        else if(!re.test(this.state.form.newPassword)){
            isGood = false;
            this.setState({newPasswordValid: "Passwords must be at least 8 characters and include at least one UPPERCASE, one lowercase, one number, and one of the following: !@#$%^&*"});
        }
        else{
            if(this.state.form.newPassword===''){
                this.setState({newPasswordValid:"Please enter a valid password."});
            }

            if(this.state.form.newPassword !== "") {
                this.setState({newPasswordValid:this.state.changeNewPasswordValid});

                if(this.state.form.newPassword !== this.state.form.confirmPassword) {
                    this.setState({confirmPasswordValid:"Passwords must match."});
                    isGood = false;
                    this.props.selectTab("PASSWORD");
                }
                else{
                    this.setState({confirmPasswordValid:""});
                    this.state.confirmPasswordValid='';
                }
            }
        }

        return isGood;
    }

    getData() {
        this.state.stringGrades = [];

        for(let i = 0; i < this.state.finalGradeLevels.length; i++) {
            this.state.stringGrades.push(this.state.finalGradeLevels[i].value);
         }

        return {
            firstName: this.state.form.firstName,
            lastName: this.state.form.lastName,
            email: this.state.form.email,
            oldPassword:this.state.form.password,
            newPassword:this.state.form.newPassword,
            _id:this.props.userData._id,
            grades: this.state.stringGrades

        }
    }

    getDataPasswordReset() {
        return {
            newPassword:this.state.form.newPassword
        }
    }

    generateValidationMsg=()=>{
      switch (this.props.errorResponse) {
        case 'Invalid Token':
          return 'This link has expired.';
        case 'Incorrect Password':
          return 'Incorrect Password';
        default:
          return 'Something went wrong';
      }
    }

    render() {

        const tooltipStyles = {
          style: {
            backgroundColor: 'white',
            borderRadius: '4px',
            width: '600px',
            color: '#4A4A4A',
            filter: 'drop-shadow(-1px -2px 3px rgba(0, 0, 0, 0.3)) drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3))',
            opacity: '1',
            transform: 'translate3d(0, 0, 0)',
            padding: '20px',
            zIndex: '1510'
          },
          arrowStyle: {
            color: "white",
            borderColor: "#F5F5F5"
          }
        }

        return(
          <div style={{overflowY: 'auto', height: this.props.showPasswordTour ? '100%' : '650px'}}>
          {(this.props.showPicturePassword && this.props.selectedTab === "PASSWORD") ? (
            <SetPicturePassword
                router={this.props.router}
                username={this.props.userData.username}
                hideFooter={true}
                ref={(spp)=>this.refSetPicturePassword = spp}
                className="set-password-under-btn-group"
                />
          ) : (
            <div className='form-wrapper-outer xs-scroll-content' style={{paddingTop: '0px'}}>
                <div className='form-wrapper-inner content-center-width'>
                      <form style={this.props.showPasswordTour ? {boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5)'} : {}}>
                        {this.props.selectedTab==='INFO'? (
                            <div>
                                <Row style={{paddingBottom: '25px'}}>
                                    <div className='profile-picture icon-icon-user'/>
                                </Row>
                                <Row className="row-padding-lr">
                                    <Col xs={12} md={12}>
                                        <Label value={this.state.form.email} align="center" fontSize="15"/>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <Label value={this.props.userData.district} align="center" fontSize="15"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <TextBox name='firstName' errorValue={this.state.firstNameValid} value={this.state.form.firstName}  valueType="FIRST NAME" onChange={this.onChange}/>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <TextBox name='lastName' errorValue={this.state.lastNameValid} value={this.state.form.lastName} valueType="LAST NAME" onChange={this.onChange}/>
                                    </Col>

                                </Row>
                                {localStorage.user_type !== "Student" &&
                                    <Row className="row-padding-lr">
                                        <div className='textBox-wrapper'>
                                            <h3> SELECT GRADES </h3>
                                            <MultipleSelection gradeList={this.state.finalGradeLevels} gradeUpdate={this.updateGrades.bind(this)}/>
                                        </div>
                                    </Row>
                                }

                                {/*<Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                                    <TextBox name='email' errorValue={this.state.emailValid} value={this.state.form.email} placeholder="EMAIL" valueType="EMAIL" onChange={this.onChange}/>
                                 </Row>*/}
                             </div>
                            ):(
                            <div>
                                <div style={this.props.pathname === "/user-profile" ? {} : {marginTop: '20px'}}>
                                    {this.props.errorResponse &&
                                       <Alert bsClass="text-center add-user-error-alert alert" bsStyle="danger">
                                           {this.generateValidationMsg()}
                                       </Alert>
                                    }
                                </div>
                                {this.props.pathname!=="/password" && this.props.pathname!=="/reset-password" &&
                                   <Row className="row-padding-lr">
                                       <TextBox name='password' type="password" Instruction="Please enter a valid password." errorValue={this.state.passwordValid} value={this.state.form.password}  valueType="PASSWORD" onChange={this.onChange}/>
                                   </Row>
                                }
                                <Row className="row-padding-lr">
                                    <TextBox
                                        name='newPassword'
                                        type="password"
                                        Instruction="Passwords must be at least 8 characters and include at least one UPPERCASE, one lowercase, one number, and one of the following: !@#$%^&*"
                                        errorValue={this.state.newPasswordValid}
                                        value={this.state.form.newPassword}
                                        valueType="NEW PASSWORD"
                                        onChange={this.onChange}
                                        />
                                </Row>
                                <Row className="row-padding-lr">
                                    <TextBox
                                        name='confirmPassword'
                                        type="password"
                                        Instruction="Passwords must match."
                                        errorValue={this.state.confirmPasswordValid}
                                        value={this.state.form.confirmPassword}
                                        valueType="CONFIRM NEW PASSWORD"
                                        onChange={this.onChange}
                                        />
                                </Row>
                           </div>
                            )
                        }
                      </form>
                    <ToolTip
                        id="fakejoyride"
                        parent="form"
                        position="bottom"
                        arrow="center"
                        active={this.props.showPasswordTour}
                        style={tooltipStyles}>
                        <div className="joyride-tooltip__header">Set Password</div>
                        <div className="joyride-tooltip__main">
                        <AssistantTooltipComponent
                            imgWidth={100}
                            imgHeight={130}
                            tooltipText={`
                          <p><strong>HELLO!</strong><br/>
                          Welcome to Fundamentals Unlimited! </p>
                          <p>Let’s begin by creating your password. Your password must be at least 8 characters and include at least one UPPERCASE, one lowercase, one number, and one of the following: !@#$%^&*</p>
                          <p>Go ahead and type your password into the <strong>New Password</strong> field, type again to <strong>Confirm</strong>, and then click <strong>Save</strong> in the lower right-hand corner. It’s as easy as that!</p>
                          <p>Note: Your email address will be your User ID.</p>`} />
                          </div>
                    </ToolTip>
                </div>
            </div>
          )}
          </div>
        );
    }
}

export default UserDetails
