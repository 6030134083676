import React from 'react'
import MultiStateIcon from "../MultiStateIcon";
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {Row,Col} from 'react-bootstrap'

class InformationBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showMenu: false
        };
        this.infoClick = this.infoClick.bind(this);
    }

    infoClick() {
        this.props.infoClickAction();
    }

    isUserStudent() {
        return localStorage.user_type === "Student";
    }

    isJoyrideRunning() {
        return this.props.isJoyrideRunning === true;
    }

    showInfoIcon() {
        return !this.isUserStudent() && !this.isJoyrideRunning();
    }

    // Whether the bar is being rendered as a student
    // when the user is logged in as a student, the tour is active
    isStudentView() {
        return this.isUserStudent() || this.isJoyrideRunning();
    }

    getHeaderText() {
        return this.isStudentView() ?
            // Student welcome
            'Welcome, ' + this.props.name :
            // Teacher view
            'Viewing ' + (this.props.getUserDetails.data?
                this.props.getUserDetails.data.first_name + ' ' + this.props.getUserDetails.data.last_name : 'student');
    }
    
    render() {
        return (
            <div id="info-bar">
                <Row>
                    <Col xs={10} sm={11}>
                        <a href="#">
                            <span>
                                 <i className="schoolwide-left-header-icon icon-icon-schoolwide-silo"/>
                            </span>
                            <span className="my-library" style={{paddingTop:'.5em', marginLeft: 5}}>
                                {this.getHeaderText()}
                            </span>
                        </a>
                    </Col>
                    <Col xs={2} sm={1}>
                        {this.showInfoIcon() &&
                            <div style={{paddingTop:'14px'}}>
                                <MultiStateIcon id='someText'
                                                enabled={true}
                                                active={this.state.showMenu}
                                                onClick={this.infoClick}
                                                activeClasses="info-demo-icon icon-icon-info-circle-solid"
                                                inactiveClasses="info-demo-icon  icon-icon-info-circle-outline"
                                                disabledClasses="info-demo-icon  icon-icon-info-circle-outline"/>
                            </div>}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        getUserDetails: state.GetUserDetails,
    });
};

export default connect(mapStateToProps, actions)(withRouter(InformationBar))
