import React,{Component} from  'react'
import DetailedHeader from '../../containers/Details/DetailedHeader';
import LessonsButtonGroup from './LessonsButtonGroup'
import SummaryContent from './SummaryComponent'
import PreparationContent from './PreparationComponent'
import {Row, Col, Modal, Alert} from 'react-bootstrap'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import {withRouter, browserHistory} from 'react-router'
import authAPI from '../../api/authAPI'
import constants from '../../constants'
import { confirmAlert } from 'react-confirm-alert';
import DetailedDescription from '../../containers/Details/DetailedDescription'
import LessonContent from './LessonDataComponent'
import WhatsNextCotent from './whatsNextComponent'
import _ from 'lodash'
import AssistantTooltipComponent from '../JoyrideCustomComponent/AssistantTooltipComponent'
import ResponsiveDetailButtonGroup from '../../components/Shared/ResponsiveDetailButtonGroup'
const caseMap = {
    "summary": "SUMMARY",
    "preparation":"PREPARATION",
    "lesson":"LESSON",
    "whats-next": "WHAT'S NEXT"
};

const componentMap = {
    "SUMMARY": SummaryContent,
    "PREPARATION": PreparationContent,
    "LESSON": LessonContent,
    "WHAT'S NEXT": WhatsNextCotent
};

const styleObject = {
  mainColor: '#CACACA',
  width: 600,
  height: '100px',
  overflow: 'wrap',
  borderRadius: 4,
  backgroundColor: 'white',
  beacon: {
    inner: '#a350f0',
    outer: '#a350f0',
  },
  close: {
    display: 'none'
  },
  button: {
    display: 'none'
  },
  skip: {
    marginTop: 20,
    borderRadius: 8,
    backgroundColor: '#009BBD',
    color: 'white'
  },
  back: {
    display: 'none'
  }
}

const stepsLessons = [
  {
    title: "Highlighting & Annotating",
    text: <AssistantTooltipComponent
              imgWidth={100}
              imgHeight={130}
              tooltipText={`
                <p>Fundamentals Unlimited provides you with a menu of tools that allow you to customize lessons and eBooks by highlighting and annotating text.
                To highlight and annotate text from:</p>
                <p style="margin-bottom: 0px"><strong>Personal computers or laptops:</strong>
                <br/><p style="margin-left: 15px; padding-top: 0px">Simply use the mouse to highlight any portion of text and right click.</p></p>
                <p style="margin-bottom: 0px"><strong>Tablets or cell phones:</strong>
                <br/><p style="margin-left: 15px; padding-top: 0px">Press down on any word or section of text and expand your selection accordingly.</p></p>
                <p>You may make as many notes and/or highlight as much text as you see fit!</p>
                `} />,
    selector: '#joyride-selector-lesson-button',
    position: 'bottom',
    style: {
      ...styleObject,
      arrow: {
        right: '50%',
        left: '50%'
      }
    },

  }
]

class LessonsDetailedView extends Component{
    constructor (props) {
        super(props);
        this.state = {
            selectedTab: caseMap[this.props.params.tab || "summary"],
            infoClicked: window.innerWidth < 768 ? false : true,
            searchClicked: false,
            load: "initial",
            status:"",
            data: {
                id: "",
                image: "",
                name: "",
                rationale: "",
                lessonData: ""
            },
            modal:false,
            videoId:'',
            vimeoURL:''
        };
        // this.selectTab = this.selectTab.bind(this);
        this.onInfoClick = this.onInfoClick.bind(this);
        this.continueTour = _.debounce(this.props.resetJoyride.bind(this, stepsLessons, 0), 1000, {leading: false, trailing: true});
        this.stopTour = _.debounce(this.props.stopJoyride, 1000, {leading: true, trailing: false});
        this.modalController = this.modalController.bind(this);
        this.modalCloseController = this.modalCloseController.bind(this);
    }

    componentWillMount(){
      this.props.joyrideLoadSteps(stepsLessons);
      if(window.innerWidth<678){
          this.setState({load:'',infoClicked:false})
      }
    }

    componentDidMount(){
        this.props.getLessonsDetailedAction(this.props.params.uid);
        this.props.getAnnotationsAction("Lesson", this.props.params.uid);
    }

    onInfoClick(infoClicked = !this.state.infoClicked){
        if(infoClicked) {
            this.onSearchClick(false);
        }
        return new Promise((resolve, reject) => {
            this.setState({infoClicked: infoClicked, load: ""}, () => {
                resolve();
            });
        });

    }

    onSearchClick=(searchClicked = !this.state.searchClicked)=>{
        if(searchClicked) {
            this.onInfoClick(false);
        }
        return new Promise((resolve, reject) => {
            this.setState({searchClicked: searchClicked, load: ""}, () => {
                resolve();
            });
        });
    };

    selectTab=(tab)=>{
        let uuid = localStorage.getItem("uuid");
        console.log("checksession uuid now:"+uuid);
        let isActiveSession = true;
        let userException = ["dejital1@gmail.com","ladiadavies@gmail.com"];
        authAPI.validateUserSessionByUUID(uuid).then((resp)=>{
            console.log("resp received");
            console.log(resp);
            isActiveSession = resp[0].active;
            console.log("in student home");
            if(isActiveSession == false && !userException.includes(localStorage.getItem("username"))){
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                        <div className='custom-ui'>
                            <h1>User session has ended. You have logged in through another device.</h1>
                            <button
                            onClick={() => {
                                document.cookie = "sessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    
                                localStorage.clear();
                                browserHistory.push(constants.PATHS.ROOT);
                    
                                setTimeout(function(){window.location.reload()},100);
                            }}
                            >
                            Continue
                            </button>               
                        </div>
                        );
                    }
                });
            }
        });
        this.setState({ selectedTab: tab});
        if(this.props.location.state && this.props.location.state.beginTour)
            this.stopTour();
        browserHistory.replace({pathname: this.props.location.pathname, state: {}});
    }
    componentWillReceiveProps(next){
        let width = window.innerWidth
        if(width>678){
            if(this.state.selectedTab !== next.params.tab){
                this.setState({selectedTab: caseMap[next.params.tab || "summary"]})
            }
        }
        if(next.lessonsDetail) {
            if (next.lessonsDetail.asyncStatus === "PENDING") {
                this.setState({status: "PENDING"});
            }
            else if (next.lessonsDetail.asyncStatus === "FAILURE") {
                this.setState({status: "FAILURE"});
            } else if (next.lessonsDetail.asyncStatus === "SUCCESS" && next.lessonsDetail.data.status === "FAILURE FOR PRINT ONLY USER") {
                this.setState({status: "FAILURE FOR PRINT ONLY USER"});
            } else if (next.lessonsDetail.asyncStatus === "SUCCESS" && next.lessonsDetail.data.status === "FAILURE FOR NOT APPROVED") {
                this.setState({status: "FAILURE FOR NOT APPROVED"});
            } else if (next.lessonsDetail.asyncStatus === "SUCCESS") {
                this.state.data.id = next.lessonsDetail.data._id;
                this.state.data.name = next.lessonsDetail.data.name;
                this.state.data.publish = next.lessonsDetail.data.publish;
                this.state.data.image = next.lessonsDetail.data.image;
                this.state.data.rationale = next.lessonsDetail.data.rationale;
                this.state.data.lessonData = next.lessonsDetail.data.lesson;
                this.setState({status: "SUCCESS"});
            }
        }
        if(next.params.uid!==this.props.params.uid){
            this.props.getLessonsDetailedAction(next.params.uid);
        }
        if(next.location.state && next.location.state.beginTour)
            this.continueTour();
    }

    modalController(videoUrl){
       let videoUrlArr = videoUrl.split("/");
       let videoId = videoUrlArr[videoUrlArr.length-1];
        if(!this.state.modal){
            this.setState({
              modal:true,
              videoId:videoId
            })
        }else{
            this.setState({
              modal:false,
              videoId:videoId
            })
        }
        
    }

    modalCloseController(){
      if(!this.state.modal){
            this.setState({
              modal:true
            })
        }else{
            this.setState({
              modal:false
            })
        }
    }

    render(){
        const ContentComponent = componentMap[this.state.selectedTab];
        return(
            <div className="detail-wrapper">
                <DetailedHeader file={this.state.data.file} publish={this.state.data.publish} name={this.state.data.name} onSearchClick={this.onSearchClick} infoClick={this.onInfoClick} itemType="Lesson" itemId={this.state.data.id} infoClicked={this.state.infoClicked} searchClicked={this.state.searchClicked}/>
                <div className={((this.state.infoClicked || this.state.searchClicked)?"some-area fill-height-or-more":"fill-height-or-more some-area-without-info")}>
                    {this.state.status === "SUCCESS" &&
                        <div style={{paddingTop:window.innerWidth<678?'62px':'',marginBottom:window.innerWidth<678?'35px':''}} >
                            <LessonsButtonGroup id={this.props.lessonsDetail.data._id} selectTab={this.selectTab} selectedTab={this.state.selectedTab}/>
                            {this.props.lessonsDetail.asyncStatus === 'SUCCESS' && this.props.lessonsDetail.data && (
                                <div>
                                    <ContentComponent content={this.props.lessonsDetail.data} annotations={this.props.annotations.data} modalController={this.modalController} modalCloseController={this.modalCloseController} vimeoURL={this.state.vimeoURL}/>
                                </div>
                            )}
                            <ResponsiveDetailButtonGroup itemType='lessons' id={this.props.lessonsDetail.data._id} selectTab={this.selectTab} selectedTab={this.state.selectedTab}/>
                        </div>

                    }
                    {this.state.status === "PENDING" &&
                        <div style={{paddingTop: "90px", paddingLeft: "20px", textAlign: "center"}}>Loading....</div>
                    }
                    {this.state.status === "FAILURE" &&
                        <div style={{paddingTop: "90px", paddingLeft: "20px", textAlign: "center"}}>Error Loading Data</div>
                    }
                    {this.state.status  === "FAILURE FOR PRINT ONLY USER" &&
                        <div style={{paddingTop: "10px", textAlign: "center"}}>This resource is not approved for viewing on Fundamentals Unlimited. Please contact us at <a href="mailto:help@schoolwide.com" target="_blank">help@schoolwide.com</a> for more information.</div>
                    }
                    {this.state.status  === "FAILURE FOR NOT APPROVED" &&
                        <div style={{paddingTop: "10px", textAlign: "center"}}>This resource is not approved for viewing on Fundamentals Unlimited. Please contact us at <a href="mailto:help@schoolwide.com" target="_blank">help@schoolwide.com</a> for more information.</div>
                    }
                    <DetailedDescription image={this.state.data.image} name={this.state.data.name} detailType='lessons' description={this.state.data.rationale} lessonData={this.state.data.lessonData} lessonid={this.state.data.id}/>
                </div>
                <Modal id="start-iframe-modal" bsSize="lg" show={this.state.modal} style={{'position':'relative','top':'7rem'}}>
                      <Modal.Body>
                          <Row style={{'position':'relative','bottom':'2rem','left':'84rem'}}>                         
                             <button type="button" aria-label="Close" onClick={this.modalCloseController}><span aria-hidden="true">&times;</span></button>
                          </Row>
                          <Row className='custom-modal'>
                            <iframe src={"https://player.vimeo.com/video/"+this.state.videoId} width="100%" height="450px" style={{"padding": "0px","overflow": "hidden", "border": "none"}} allowFullScreen></iframe>
                          </Row>
                       </Modal.Body>
                 </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        lessonsDetail : state.LessonsDetail,
        annotations : state.Annotations
    });
};

export default connect(mapStateToProps, actions)(withRouter(LessonsDetailedView))
