import React,{Component} from  'react'
import {withRouter} from 'react-router'
import {Col, Row} from 'react-bootstrap'
import {browserHistory} from 'react-router'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import authAPI from '../../api/authAPI'
import constants from '../../constants'
import UnattachedPopover from '../../containers/shared/UnattachedPopover'
import '../../assets/styles/index.scss'
import PropsMultiStateIcon from "../PropsMultiStateIcon"
import ToolTip from 'react-portal-tooltip'
let clearPopOver;
class SearchBar extends Component{
    constructor (props) {
        super(props);
        this.state={
            showpopover:false,
            isTooltipActive: false,
            success: false,
            failed: false,
            addedToMyLibrary: false,
            errorMessage: ""
        };
        this.close = this.close.bind(this);
         this.style1 = {
            style: {
                // height: "125px",
                // width: "200px",
                border: "1px solid #F5F5F5",
                borderRadius: "8px",
                backgroundColor: "rgba(255,255,255)",
                //boxShadow: "0 2px 12px 0 rgba(0,0,0,0.5)",
                padding: "10px 10px 10px 10px",
                filter: "drop-shadow(0px 2px 12px rgba(0,0,0,0.5))",
                zIndex: 1000
            },
            arrowStyle: {
                color: "white",
                borderColor: "#F5F5F5",
                //filter: "drop-shadow(0px 2px 12px rgba(0,0,0,0.5))"
            }
        };
    }

    close(){
        //----repalcement for browserhistory.goback, if goback doesn't work need to replace with the following code
        // console.log('historyyyy',browserHistory.goBack)
        // if(this.props.unitLocation.location){
        //     browserHistory.push(this.props.unitLocation.location);
        // }else if(this.props.customStatus===true){
        //     browserHistory.push('/library/units');
        // }else if(this.props.customStatus===false){
        //     browserHistory.push('/browse/units/featured');
        // }else{
        //     browserHistory.goBack();
        // }
        browserHistory.goBack();
    }

    toggleSideBar=()=>{
        this.props.infoClick();
    };
    
    addToLibraryItem=()=>{
        console.log("klkklk");
        console.log(this.props.unitId);
        let self =this;
        this.props.addToLibrary(this.props.unitId)

            .then((response)=>{
                if(response.statusCode === 200){
                    self.setState({
                         showpopover:false,
                         success : true,
                         addedToMyLibrary : true
                     },
                         ()=>{
                          console.log(this.state.success);
                          clearPopOver = setTimeout(()=>this.setState({success: false}),3000)
                        }
                     );
                 }else{
                     self.setState({
                         success : false,
                         showpopover:false,
                         failed : true,
                         errorMessage: response.response.data.message.message
                     },
                         ()=>{
                          clearPopOver = setTimeout(()=>this.setState({failed: false}),3000)
                        }
                     );
                 }
            })
            .catch(function (error) {
                self.setState({showpopover:false});
                self.setState({success : false});
                self.setState({failed : true});
                self.setState({ errorMessage: "failure"});
            });
    };

    goToLibrary=()=>{
        console.log(this.props);
        browserHistory.push('/library/units'); 
        setTimeout(function(){window.location.reload()},100);
    }

    editMode=()=>{
        let self = this;
        if(!this.props.customStatus){
             let lessonIds = [];
             let assessmentIds = [];
             this.props.unitData && this.props.unitData.lessons && this.props.unitData.lessons.map((lesson, i)=> {
                    lessonIds.push(lesson._id);
             })
             this.props.unitData && this.props.unitData.assessments && this.props.unitData.assessments.map((assessment, i)=> {
                    assessmentIds.push(assessment._id);
             })
             authAPI.saveUnit(this.props.params.uid,this.props.unitData.name+' Copy',
             this.props.unitData.introduction?this.props.unitData.introduction:{"text":''},
             lessonIds,this.props.unitData.image,assessmentIds,this.props.unitData.vocabulary||[],
             this.props.unitData.unit_doc && this.props.unitData.unit_doc)
             .then((response)=>{
                    if(response.status === 201){
                        this.setState({success:true});
                        //sending the success to the unitsdetail page
                        this.props.addToLibraryStatus()
                        if(self.props.isJoyrideRunning){
                          self.props.stopJoyride();
                          browserHistory.push({pathname: '/units/'+response.data._id, state: {beginTour: false, unitTourPartTwo: true}});
                        } else {
                          browserHistory.push({pathname: '/units/'+response.data._id, state: {beginTour: false, unitTourPartTwo: false}});
                        }
                    }else{
                        this.setState({success:false});
                    }
              })
        }else{
            browserHistory.replace('/edit-unit/'+this.props.params.uid);
        }
    };

    showSearch=()=>{
        this.props.onSearchClick();
    };

    showPop=()=>{
      this.setState({
        showpopover : true
      })
    };

    addToFolder=()=>{

      browserHistory.push({pathname:'/library/addfolder',state:{libraryItemId:this.props.params.uid,libraryItemType:'Unit',isLibrary:false}});
      this.props.saveAssignmentItemAction('Unit',this.props.params.uid);
    };
    publishDATAToSAML = () =>{
      window.open("/schoolwidefun/api/v1/users/samlp?item_id="+this.props.params.uid+"&item_type=Units","_blank");
    };
    render(){
        let name = this.props.name;
        return(
            <div className="search-bar">
                <Row>
                    <Col md={6} sm={6} className="unit-name">
                        <span className="add-padding-back-icon">
                            <i onClick={this.close} className="chevron-left-demo-icon icon-icon-back"/>
                        </span>
                        { this.props.name && <span >{name}</span>}
                    </Col>
                    <Col md={6} sm={6}>
                        <div style={{float: "right"}} className="header-icon-detailed">
                            <PropsMultiStateIcon enabled={true}
                                                 active={this.props.searchClicked}
                                                 onClick={this.showSearch}
                                                 sharedClasses="header-icon-detailed"
                                                 activeClasses="icon-icon-search-circle-solid"
                                                 inactiveClasses="icon-icon-search-circle-outline icon-blue"
                                                 disabledClasses="icon-icon-search-circle-outline"
                            />
                        </div>
                        <div className="add-padding-to-icons">
                            <PropsMultiStateIcon enabled={true}
                                                 active={this.props.infoClicked}
                                                 onClick={()=>this.toggleSideBar()}
                                                 sharedClasses="info-demo-icon"
                                                 activeClasses="icon-icon-info-circle-solid icon-blue"
                                                 inactiveClasses="icon-icon-info-circle-outline icon-blue"
                                                 disabledClasses="icon-icon-info-circle-outline"
                            />
                        </div>
                        {/*
                        <span className="add-padding-to-icons"> <i className="search-demo-icon icon-icon-search-circle-outline"></i> </span>
                        <span className="verticalLine"><i className="browse-demo-icon icon-icon-browse-circle-solid"></i></span>
                        */}
                         <span>
                            <div id="myDiv" className={this.props.customStatus?"hover-icon-unit icon-icon-edit-circle-solid xs-remove":"hover-icon-unit icon-icon-add-circle-solid"}  onClick={this.props.customStatus?this.editMode:this.showPop}>
                            </div>
                        </span>
                        {this.state.showpopover &&
                          <ToolTip active={true} position={"bottom"} parent={"#myDiv"} style={this.style1} arrow="right" width="351px" height="340px" padding="0px">
                              <div>
                                <div className="modal-text-header-styles">
                                    ADD OPTIONS
                                </div>
                                <div onClick={this.addToFolder}
                                       className="header-popup-option">
                                      Add to My Folders
                                  </div>
                                <div onClick={this.addToLibraryItem} className='header-popup-option'>
                                    Add to My Library
                                </div>
                                {this.props.unitData.publish && 
                                <div onClick={this.publishDATAToSAML} className='header-popup-option'>
                                    Publish
                                </div>
                                }
                            </div>
                          </ToolTip>
                         }
                    </Col>
                </Row>
                {this.props.popOverStatusMobile && window.innerWidth < 768 &&
                        <UnattachedPopover hideTooltip={this.props.hideTooltip}>
                            <div className="popover-success-text">
                                <strong>{this.props.unitData.name}</strong> was successfully added to your Library!
                            </div>
                        </UnattachedPopover>
                }
                {this.props.popOverStatus && window.innerWidth >= 768 &&
                        <div className='bottom-margin-browse'>
                            <div className="unit-success-popover">
                                <div className="popover-success-icon icon-icon-checkbox"/>
                                <div className="popover-success-text">
                                    <strong>{this.props.unitData.name}</strong> was successfully added to your Library!
                                </div>
                            </div>
                        </div>
                }
                {this.state.success &&
                    <div className="single-select-popover" style={{"zIndex":999}}>
                    <div
                        onMouseEnter={()=>{clearTimeout(clearPopOver); this.setState({success:true})}}
                        onMouseLeave={()=> { clearPopOver = setTimeout(()=>{this.setState({success: false})},3000 )}}
                    >
                        <div className="item-added">
                            <div style={{marginBottom: 10}} className="selected-checkbox-icon-add-multiple icon-icon-checkbox"/>
                                <div style={{textAlign: "left"}}>
                                    <strong>{this.props.unitData.name}</strong> was successfully added to {
                                        this.state.addedToMyLibrary && localStorage.user_type === 'Student' &&
                                        <span> My Texts!</span>
                                    }
                                    {
                                        this.state.addedToMyLibrary && localStorage.user_type !== 'Student' &&
                                         <span> your Library!</span>
                                    }
                                    {
                                        this.state.addedToPlanner &&
                                        <span> Planner!</span>
                                    }
                                </div>
                                </div>
                                    {this.state.addedToMyLibrary && localStorage.user_type === "Student"  && <div>
                                        <span>
                                            <button className="go-to-my-library-planner" onClick={()=>browserHistory.push('/library/'+constants.resourceLibraryMap[this.props.type])}>
                                                GO TO MY TEXTS
                                            </button>
                                         </span>
                                    </div> }
                                 {this.state.addedToMyLibrary && localStorage.user_type !== "Student"  && <div>
                                <span>
                                  <button className="go-to-my-library-planner" onClick={this.goToLibrary}>
                                       GO TO MY LIBRARY
                                  </button>
                                 </span>
                             </div>
                        }
                        {this.state.addedToPlanner &&
                          <div>
                            <span><button onClick={this.addToLibrary} className="add-to-planner-library">ADD TO MY LIBRARY</button></span>
                            {/* <span><Link to="/planner"><button className="go-to-my-library-planner">GO TO PLANNER</button></Link></span> */}
                            </div>
                        }
                    </div>
                    </div>}
                {this.state.failed &&
                     <div className="single-select-popover" style={{"zIndex":999}}>
                     <div
                         onMouseEnter={()=>{clearTimeout(clearPopOver); this.setState({success:true})}}
                         onMouseLeave={()=> { clearPopOver = setTimeout(()=>{this.setState({success: false})},3000 )}}
                     >
                        <div className="icon-icon-warning" style={{fontSize: 30,color:"#d0021b",float:"left",paddingTop:3}}></div>
                        {this.state.errorMessage && localStorage.user_type === 'Student' && <div style={{position:"absolute",left: 60,right:20,textAlign:"left"}}>
                              <span><strong>{this.props.unitData.name}</strong> already exists in My Library</span>
                             </div>
                        }

                        {this.state.errorMessage && localStorage.user_type !== 'Student' && <div style={{position:"absolute",left: 60,right:20,textAlign:"left"}}>
                        <span><strong>{this.props.name}</strong> already exists in your Library</span></div>}
                    </div>
                    </div>                
                }
                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return ( {
        unitLocation:state.Location,
        isJoyrideRunning: state.JoyrideReducer.joyrideShouldRun
    });
};

export default connect(mapStateToProps, actions)(withRouter(SearchBar))
