import {
    GET_NEW_CUSTOM_FILTERS_REQUEST,
    GET_NEW_CUSTOM_FILTERS_SUCCESS,
    GET_NEW_CUSTOM_FILTERS_FAILURE,
    UPDATE_NEW_FILTER_OPTIONS,
} from '../actions';

const initialState = {};

const GetNewCustomFiltersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NEW_CUSTOM_FILTERS_REQUEST:
            return Object.assign({},state, {asyncStatus:  'PENDING'});
        case GET_NEW_CUSTOM_FILTERS_SUCCESS:
            return Object.assign({},state, {asyncStatus:  'SUCCESS', data: action.payload});
        case GET_NEW_CUSTOM_FILTERS_FAILURE:
            return Object.assign({},state, {asyncStatus:  'FAILURE', data: action.payload});
        case UPDATE_NEW_FILTER_OPTIONS:
            return Object.assign({}, state, {data: action.payload})
        default:
            return state;
    }
};

export default GetNewCustomFiltersReducer;
