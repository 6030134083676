import React, {Component} from 'react'
import {Row,Col,Modal, Button} from 'react-bootstrap'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import debounce from 'debounce'
import InfiniteScroll from 'react-infinite-scroll-component'
import {browserHistory} from 'react-router'
import JoyrideTourModal from '../JoyrideCustomComponent/JoyrideTourModal'
import _ from 'lodash'
import FolderCreation from '../../components/FolderCreationComponent'
import MultiStateIcon from "../MultiStateIcon"
import authAPI from '../../api/authAPI'
import CreateFolderDialog from './CreateFolderDialog'


class TextBox extends Component{
    render(){
        return(
            <div className='textBox-wrapper'>
                <input className={(this.props.errorValue === "")? "global-unit-searchbar" : "global-unit-searchbar add-user-form-error" }
                name={this.props.name}
                type={this.props.type || 'text'}
                value={this.props.value}
                placeholder={this.props.placeholder}
                onChange={this.props.onChange}/>
                {this.props.errorValue !== "" &&
                    <div className="add-user-error">
                        {this.props.errorValue}
                    </div>
                }
            </div>
        );
    }
}
class AssignFolder extends Component {
    constructor(props){
        super(props);
        this.state={
            searchTerm: "",
            addFolders: [],
            showTourModal: false,
            assignError:'',
            queryString:'',
            showFolder: false,
            errorMessage: '',
            createFolder: false,
            form: {
                folderName:''
            },
            createFolderInput: false,
            folderNameValid: ""
        };
        this.search = debounce(this.search,100);
        this.nextJoyrideStep = debounce(this.props.nextJoyrideStep);
        this.fetchFolders = this.fetchFolders.bind(this);
        this.showError = this.showError.bind(this);
        this.isEmpty = this.isEmpty.bind(this);
        this.uncheckedItems = {};
        this.showCreateFolderInput = this.showCreateFolderInput.bind(this);
        this.validateInput = this.validateInput.bind(this);
        this.addfolderName = this.addfolderName.bind(this);
        this.folderId = this.props.folderId;        
    }

    showFolder=()=>{
        this.setState({
          showFolder:!this.state.showFolder
        });
    }

    onCreateFolderCancel = ()=>{
        this.setState({createFolder: false});
    };

    showCreateFolderInput= () => {
        this.setState({
            createFolder: true
        })
    }

    assignToFolder = (sid)=>{
        for(let i=0;i<this.state.addFolders.length;i++){
            if (sid === this.state.addFolders[i]){
                this.state.addFolders.splice(i,1);
                this.setState({addFolders: this.state.addFolders});
                return;
            }
        }
        this.state.addFolders.push(sid);
        this.setState({addFolders: this.state.addFolders,assignError:''}) ;
    };

    postAssignment = ()=>{

        if(this.state.addFolders.length>0){
            let assign_object={}
            if(this.props.location.state.libraryItemType === 'Units')
                this.props.location.state.libraryItemType = 'Unit';
            if(this.props.assignmentItem.data.type === 'Units')
                this.props.assignmentItem.data.type = 'Unit';
            assign_object={
                "item_id":  this.props.location.state.libraryItemId,
                "item_type": this.props.assignmentItem.data.type || this.props.location.state.libraryItemType
            };
            let folder_array = [];
            for(let i=0;i<this.state.addFolders.length;i++){
                this.props.postFolderAssignmentAction(this.state.addFolders[i],assign_object)
                    .then((response)=>{
                        if(response.statusCode === 201){
                            folder_array.push(response.assignment.folder_name);
                            if(this.props.location.state.isLibrary == true){
                                if(response.assignment.item_type == 'Assessment'){
                                    authAPI.getAssessmentDetailsForFolder(response.assignment.item_id)
                                    .then( res => {
                                        browserHistory.push('/library/'+response.assignment.item_type.toLowerCase()+'s?i='+res.name+'&f='+folder_array);
                                    })
                                }
                                else if(response.assignment.item_type == 'Resource'){
                                    authAPI.getResourceDetailsForFolder(response.assignment.item_id)
                                    .then( res => {
                                        browserHistory.push('/library/'+response.assignment.item_type.toLowerCase()+'s?i='+res.name+'&f='+folder_array);
                                    })
                                }
                                else if(response.assignment.item_type == 'Unit'){
                                    authAPI.getUnitsDetailForFolder(response.assignment.item_id)
                                    .then( res => {
                                        browserHistory.push('/library/'+response.assignment.item_type.toLowerCase()+'s?i='+res.name+'&f='+folder_array);
                                    })
                                }
                                else if(response.assignment.item_type == 'Book'){
                                    authAPI.getBookDetailsForFolder(response.assignment.item_id)
                                    .then( res => {
                                        browserHistory.push('/library/'+response.assignment.item_type.toLowerCase()+'s?i='+res.name+'&f='+folder_array);
                                    })
                                }else if(response.assignment.item_type == 'Lesson'){
                                    authAPI.getLessonsDetailForFolder(response.assignment.item_id)
                                    .then( res => {
                                        browserHistory.push('/library/resources?i='+res.name+'&f='+folder_array);
                                    })
                                }
                            }else{
                                browserHistory.goBack();
                            }
                        setTimeout(() => {
                            this.clearError();
                        }, 3000);
                    } else{
                        this.showError(response.message);
                        setTimeout(() => {
                            this.clearError();
                        }, 3000);
                        this.props.saveAssignmentItemAction(assign_object.item_type,assign_object.item_id);
                        this.setState({addFolders: []}) ;
                    }
                })
                .catch(function (error) {
                    console.log("exception occured",error);
                });
            }
        }else{
            this.setState({assignError:'Please select at least one Folder'})
        }

    };

    showError(message) {
        this.setState({errorMessage: message});
    }

    clearError() {
        if(this.state.errorMessage !== "") {
            this.setState({errorMessage: ""});
        }
    }

    changeFunction= (event)=>{
        let val = event.target.value;
        this.setState({searchTerm: val});
        this.search(val);
    };

    search=(val)=>{
        this.fetchFolders(val);
    };

    fetchFolders(searchTerm){
        let self=this;
        self.props.getFoldersAction(1,searchTerm,this.state.queryString,localStorage.is_lite).then((resp) => {
            self.state.resources = [];
            if(!resp){
                let props = {
                        resources:[],
                        totalCount:0
                    };
                    self.state.resources=[];
                    self.state.totalCount=0;
                    self.setState(props);
                return false;
            }
            if(searchTerm !== self.state.searchTerm){
                self.state.resources = [];
                self.state.pageNumber = 1;
            }
            self.state.searchTerm = searchTerm;
            self.state.totalCount = resp.count;
            resp.rows.map(function(item){
                if(self.props.location.state.folderId != undefined){
                    if(self.props.location.state.folderId!= item._id){
                        self.state.resources.push(item);        
                    }
                }else{
                    self.state.resources.push(item);
                }
                
            });
            let props = {
                        resources:self.state.resources,
                        totalCount:resp.count,
                        searchTerm:searchTerm
                    };
                    self.setState(props);
            if(!self.pageLoad){
            setTimeout(function(){
                if(self.state.totalCount > self.state.resources.length){
                    self.setState({hasMore : true});
                }
            },500);
        }
        })
    }

    isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    componentDidMount(){
        if(this.isEmpty(this.props.assignmentItem)) {
            browserHistory.push('/library/');
        } else {
            this.fetchFolders(this.state.searchTerm);
            if(this.props.isJoyrideRunning)
            this.nextJoyrideStep();
        }
            
    }

    componentWillReceiveProps(next){
        if(next.showTourModal)
            this.setState({showTourModal: true});
        else {
            this.setState({showTourModal: false});
        }
    }

    hideTourModal=()=>{
      this.setState({showTourModal: false});
    }

    handleAssignIconClick=(_id)=>{
        if(this.props.isJoyrideRunning){
            this.props.allowClicksThruHere(false);
            this.props.stopJoyride();
            _.debounce(this.props.startJoyrideAtIndex.bind(this, 5, 5), 700, {leading: false, trailing: true})();
            }
        this.assignToFolder(_id);
    }

    addfolderName = (event) => {
        this.state.form[event.target.name] = event.target.value;
        this.setState({form: this.state.form});
    }

    validateInput() {
        let isGood = true;
        if(this.state.form.folderName.length == 0 || !this.state.form.folderName) {
            this.setState({folderNameValid: "Folder Name cannot be blank."});
            isGood = false;
        } else {
            this.setState({folderNameValid: ""});
        }
        return isGood;
    }

    createNewFolder = () => {
        if (this.validateInput()) {
            const postNewFolder = authAPI.postFolder({
                name: this.state.form.folderName
            });

            postNewFolder.then((response)=>{
                if(response.statusCode === 201 || response.statusCode === 200){

                    this.setState({
                        form: {
                            folderName:''
                        },
                        createFolderInput: false,
                        folderNameValid: "",
                        createFolder: false
                    });
                    this.fetchFolders(this.state.searchTerm);
                }
                else{
                    this.showError(response.message);
                    setTimeout(() => {
                        this.clearError();
                    }, 3000);
                }
            })
            .catch(function (error) {
                console.log("exception occurred: ",error);
            });
        } 
    }

    render() {
        return (
            <div>
                <Col md={12} sm={12} className="add-group-header add-folder-header">
                    <Col md={3} sm={3}>
                        <div className="back-btn">
                            <button onClick={browserHistory.goBack} className="cancel-add-group">
                                BACK
                            </button>
                        </div>
                    </Col>
                    <Col md={6} sm={6}>
                        <div className="add-group">
                            ADD TO MY FOLDERS
                        </div>
                    </Col>
                    <Col md={3} sm={3}>
                        { localStorage.user_type !== 'Student' &&
                        <div>
                            <div className="two-header-btns" style={{position: 'absolute', top: '0', right: '5px'}}>
                                <MultiStateIcon iconId='folderIcon'
                                    enabled={true}
                                    active={this.state.showFolder}
                                    onClick={this.showFolder}
                                    activeClasses="header-right-icon active"
                                    inactiveClasses="header-right-icon inactive"
                                    disabledClasses="header-right-icon disabled"
                                    imageIcon="../folder.png"
                                    tooltipText="My Folders"
                                />
                            </div>
                        </div> 
                        }
                    </Col>
                </Col>
                <div className="add-folder-block">
                    <Col md={3} sm={3}/>
                    <Col md={6} sm={6} className="inner-add-group-content">
                        <Row>
                            <div className="create-new-folder text-center">
                                <span className="create-folder" onClick={this.showCreateFolderInput}>
                                    <img className="img-responsive" src="../folder.png" />
                                    Create a new folder
                                </span>
                            </div>
                        </Row>
                        {this.state.errorMessage !== "" &&
                            <Row style={{paddingLeft: '15px', paddingRight: '15px'}}>
                                <div className="add-user-error text-center">
                                    {this.state.errorMessage}
                                </div>
                            </Row>
                        }
                        <div style={{display: "inine-block",marginTop: 10,marginBottom: 10}}>
                            CHOOSE FOLDER
                        </div>
                        <div id="joyride-selector-search-by-student">
                            <span className="search-student icon-icon-search"/>
                            <input type="text"  placeholder="Search for Folder" onChange={this.changeFunction} value={this.state.searchTerm} className="search-by-student" />
                        </div>
                        {!this.state.resources &&
                        <div style={{fontFamily: "ProximaNova",color:"#9B9B9B",fontSize: 15,fontWeight: 600}}>
                            There are no such folder assigned to this Resource.
                        </div>
                        }
                         <InfiniteScroll height={420+'px'}
                                        next={()=>this.props.getFoldersAction(1,this.state.searchTerm,this.state.queryString,localStorage.is_lite)}
                                        //hasMore={this.props.users.hasMore["Student"]}
                                        loader={<Row>Loading...</Row>}
                                        dataLength={this.state?.resources?.length ?? 0}
                        >
                            {this.state?.resources?.length && this.state.resources.map((folder,i)=>
                                <Row key={folder+i}>
                                    <span className="add-folder-span">
                                        <img className="my-folder-icon img-responsive" src="../folder.png" />
                                    </span>
                                    <Col md={8} sm={9} xs={8} >
                                        <Row className="folder-name">
                                            {folder.name}
                                        </Row>
                                    </Col>
                                    <span className="add-folder-span span-right">
                                        <i onClick={()=>this.handleAssignIconClick(folder._id)}
                                            className={ this.state.addFolders.indexOf(folder._id)!== -1 ? "remove-student-solid-icon icon-icon-close-circle-solid"
                                            :"add-student-solid-icon icon-icon-add-circle-solid joyride-icon-selector"}/>
                                    </span>
                                    {<div className="my-class-add-group-border"/>}
                                </Row>
                            )}
                        </InfiniteScroll>
                    </Col>
                    <Col md={3} sm={3}>
                        {this.state.showFolder && <FolderCreation
                            focus={this.state.showFolder && window.innerWidth > 767}
                            closeStatus={this.state.showFolder && !this.state.showFolder}
                        />}
                    </Col>
                </div>
                <div className="add-group-footer">
                    {/* <JoyrideTourModal isAssignTourReady={true} hideTourModalJoyride={this.props.clearTourModal} showTourModal={this.state.showTourModal} hideTourModal={this.hideTourModal}/> */}
                    <button onClick={browserHistory.goBack} className="cancel-add-group">
                        CANCEL
                    </button>
                    <button onClick={()=>{this.props.stopJoyride();this.postAssignment()}} className="add-add-group">
                        SAVE
                    </button>
                </div>
                {this.state.createFolder &&
                    <CreateFolderDialog onCancel={this.onCreateFolderCancel} okText="SAVE" okStyle="dialog-save-button" dialogStyle='dialog-modal-create-folder' title="Create New Folder" onOk={this.createNewFolder}>
                        <div className="create-new-folder-input global-searchbar-textbox">
                            <TextBox 
                                type='text'
                                placeholder='Enter Folder Name'
                                name='folderName'
                                errorValue={this.state.folderNameValid}
                                onChange={this.addfolderName}
                            />
                        </div>
                    </CreateFolderDialog>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return ( {
        users : state.Users,
        assignmentItem: state.AssignmentItem,
        isJoyrideRunning: state.JoyrideReducer.joyrideShouldRun,
        showTourModal: state.JoyrideReducer.displayTourModal
    });
};

export default connect(mapStateToProps, actions)(AssignFolder)
