import React, { Component } from "react";
import { Alert, Row, Col } from "react-bootstrap";
import "../assets/styles/login.scss";
import * as AuthActions from "../redux/actions/auth";
import * as actions from "../redux/actions";

import createClass from 'create-react-class';

import { connect } from "react-redux";
import constants from "../constants";
import { Link, browserHistory } from "react-router";
import {FaEnvelope} from "react-icons/fa"
import "../assets/styles/mobile.scss";
let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passwordRegEx = /(?=^.{8,}$)(?=(?:.*?\d){1})(?=.*[a-z])(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%^&*]*$/;

class TextBox extends Component {
  render() {
    return (
      <div className="textBox-wrapper">
        <h3>
          {" "}
          {this.props.valueType} <span className="sidebar-star">*</span>{" "}
        </h3>
        <input
          className={
            this.props.errorValue === ""
              ? "add-user-form"
              : "add-user-form add-user-form-error"
          }
          name={this.props.name}
          type={this.props.type ? this.props.type : "text"}
          value={this.props.value}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
        />

        {this.props.errorValue !== "" && (
          <div className="add-user-error">{this.props.errorValue}</div>
        )}
        {this.props.errorValue === "" && (
          <div className="password-validation-messages">
            {this.props.Instruction}
          </div>
        )}
      </div>
    );
  }
}
class RegistrationComponent extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      confirm_password: "",
      errors: {},
      myError: ""
    };
  }

  handleChangeInput = name => event => {
    const { value } = event.target;
    this.setState({ errors: {}, myError: "" });
    switch (name) {
      case "email":
        this.props.authSetUserEmail(value);
        break;

      default:
        this.setState({ [name]: value });
        break;
    }
  };

  componentWillReceiveProps = ({ error, user_create, loginStatus = {} }) => {
    if (error && this.props.error !== error) {
      this.handleError(error);
    }
    if (user_create.status === "success") {
      this.props.login(this.props.email, this.state.password);
    }
    if (loginStatus.userData) {
      localStorage.clear();
      localStorage.user_type = loginStatus.userData.user_type;
      localStorage.first_time_login = loginStatus.userData.first_time_login;
      localStorage.trialPopupDismissed = "false";
      localStorage.is_lite = loginStatus.userData.is_lite;

      if (loginStatus.userData.district_id !== undefined) {
        localStorage.district_id = loginStatus.userData.district_id;
      }

      localStorage.picture_password_user = "false";
      localStorage.login_domain = this.props.domain
        ? this.props.domain
        : "false";
      if (
        loginStatus.userData.set_password === true &&
        loginStatus.userData.user_type !== "System Admin"
      ) {
        browserHistory.push(constants.PATHS.PASSWORD);
      } else {
        // Adding redirect flow for lite users
        if (localStorage.is_lite === "true") {
          browserHistory.push(constants.DEFAULT_LITE_USER_PATH);
        } else if (localStorage.user_type !== "Student") {
          browserHistory.push(constants.DEFAULT_NON_STUDENT_PATH);
        } else {
          browserHistory.push(constants.DEFAULT_STUDENT_PATH);
        }
      }
    }
  };

  handleError = error => {
    this.setState({
      myError: error
    });
  };

  validateForm = argum => {
    const { errors } = this.state;
    for (let [key, value] of Object.entries(argum)) {
      switch (key) {
        case "password":
        case "confirm_password":
          if (!this.validatePassword()) {
            return false;
          }
          break;
        case "email":
          if (!re.test(value)) {
            this.setState(state => ({
              errors: { ...state.errors, [key]: `Incorrect Email` }
            }));
            return false;
          }
          break;

        default:
          if (!value.length) {
            this.setState(state => ({
              errors: {
                ...state.errors,
                [key]: `The ${key} field is required.`
              }
            }));
            return false;
          } else if (value.length < 2) {
            this.setState(state => ({
              errors: {
                ...state.errors,
                [key]: `The ${key} must be at least 2 characters.`
              }
            }));
            return false;
          }
          break;
      }
    }
    return true;
  };

  validatePassword = () => {
    const { password, confirm_password } = this.state;
    if (!password.length || !confirm_password.length) {
      this.setState(state => ({
        errors: {
          ...state.errors,
          password: `The password and password confirmation field is required.`
        }
      }));
      return false;
    }
    if (password !== confirm_password) {
      this.setState(state => ({
        errors: {
          ...state.errors,
          password: `The password and password confirmation must be equal.`
        }
      }));
      return false;
    }
    if (!passwordRegEx.test(password)) {
      this.setState(state => ({
        errors: {
          ...state.errors,
          password:
            "Passwords must be at least 8 characters and include at least one UPPERCASE, one lowercase, one number, and one of the following: !@#$%^&*"
        }
      }));
      return false;
    }
    return true;
  };

  findSchoolByEmail = event => {
    event.preventDefault();
    const { first_name, last_name } = this.state;
    const { email } = this.props;
    if (this.validateForm({ first_name, last_name, email })) {
      this.props.authGetSchoolByEmail(email);
    }
  };

  confirmCredentionals = event => {
    event.preventDefault();
    const { email, school } = this.props;
    const { first_name, last_name, password, confirm_password } = this.state;
    if (this.validateForm({ password, confirm_password })) {
      this.props.authRegisterNewUser({
        username: email,
        first_name,
        password_type: "Standard",
        last_name,
        district_id: school.data._id,
        user_type: "Print Only",
        password
      });
    }
  };

  render() {
    const { email, school } = this.props;
    const {
      first_name,
      last_name,
      password,
      confirm_password,
      errors,
      myError
    } = this.state;
    // if (user_create.status === "success") {
    //   return (
    //     <div className="bg-color" style={{ display: "flex" }}>
    //       <Col xs={8} md={6} lg={3} className="login-box">
    //         <div className="imgcontainer">
    //           <img
    //             src={require("../assets/icons/SVG/icon-schoolwide.svg")}
    //             alt="Avatar"
    //             className="avatar"
    //           />
    //         </div>
    //         <If test={user_create.status === "success"}>
    //           <Alert id="alert-success" bsStyle="success">
    //             <strong>
    //               {`You have been successfully registered.
    //                                         Message with confirmation link was sent to ${email}`}
    //             </strong>
    //           </Alert>
    //         </If>
    //       </Col>
    //     </div>
    //   );
    // }
    if (school.status !== "success") {
      return (
        <div className="align-textbox bg-color" style={{ display: "flex" }}>
          <Col xs={8} md={6} lg={3} className="login-box">
            <div className="imgcontainer">
              <img
                src={require("../assets/icons/SVG/icon-schoolwide.svg")}
                alt="Avatar"
                className="avatar"
              />
            </div>
            <form onSubmit={this.findSchoolByEmail}>
              <If test={myError.length}>
                <Alert id="alert-danger" bsStyle="danger">
                  <strong>{myError}</strong>
                </Alert>
              </If>
              <div>
                <div>
                  <span className="login-icons icon-icon-user" />
                  <input
                    // id={(this.state.firstnameError ? "red-border" : "")}
                    type="text"
                    placeholder={`Firstname`}
                    value={first_name}
                    className={`login-input ${
                      "first_name" in errors ? "red-border" : ""
                    }`}
                    name="first_name"
                    required
                    onChange={this.handleChangeInput("first_name")}
                  />
                </div>
                <If test={"first_name" in errors}>
                  <div className="error-format">{errors.first_name}</div>
                </If>
              </div>

              {/* render lastname input */}
              <div>
                <span className="login-icons icon-icon-key icon-icon-user" />
                <input
                  // id={(this.state.lastnameError ? "red-border" : "")}
                  type="text"
                  placeholder={`Lastname`}
                  value={last_name}
                  name="last_name"
                  className={`login-input ${
                    "last_name" in errors ? "red-border" : ""
                  }`}
                  required
                  onChange={this.handleChangeInput("last_name")}
                />

                <If test={"last_name" in errors}>
                  <div className="error-format">{errors.last_name}</div>
                </If>
              </div>

              {/*Render username input, and domain if given*/}
              <div>
                <span
                  className="login-icons"
                  style={{ paddingLeft: 13, paddingTop: 9 }}
                >
                  <FaEnvelope />
                </span>
                <input
                  // id={(this.state.emailError ? "red-border" : "")}
                  className={`userid ${"email" in errors ? "red-border" : ""}`}
                  // type="email"
                  placeholder={`Email`}
                  value={email}
                  name="email"
                  // required
                  onChange={this.handleChangeInput("email")}
                />
                {/*Render domain if given*/}
                <If test={"email" in errors}>
                  <div className="error-format">{errors.email}</div>
                </If>
              </div>

              <div>
                <div className="align-button">
                  <button
                    className="format-button signup_btn"
                    onClick={this.findSchoolByEmail}
                  >
                    Sign Up
                  </button>
                </div>
                <div className="align-link">
                  <Link to={`/`}>
                    <span
                      style={{ color: "#666666", textDecoration: "underline" }}
                    >
                      Already have an account? Login here
                    </span>
                  </Link>
                </div>
              </div>
            </form>
          </Col>
        </div>
      );
    }
    return (
      <div className="align-textbox" style={{ display: "flex" }}>
        <Col
          xs={10}
          md={8}
          lg={6}
          className="login-box"
          style={{ paddingTop: 70 }}
        >
          <form onSubmit={this.confirmCredentionals}>
            <div
              className="profile-header"
              style={{ position: "fixed", top: 0, left: 0, right: 0 }}
            >
              <Col
                mdOffset={4}
                md={4}
                smOffset={4}
                sm={4}
                xsOffset={false ? 0 : 3}
                xs={6}
              >
                <div className="profile-header-name">SET PASSWORD</div>
              </Col>
            </div>
            <If test={myError.length}>
              <Alert id="alert-danger" bsStyle="danger">
                <strong>{myError}</strong>
              </Alert>
            </If>
            <Row className="row-padding-lr text-left">
              <TextBox
                name="newPassword"
                type="password"
                Instruction="Passwords must be at least 8 characters and include at least one UPPERCASE, one lowercase, one number, and one of the following: !@#$%^&*"
                errorValue={errors.password || ""}
                value={password}
                valueType="NEW PASSWORD"
                onChange={this.handleChangeInput("password")}
              />
            </Row>
            <Row className="row-padding-lr text-left">
              <TextBox
                name="confirmPassword"
                type="password"
                Instruction="Passwords must match."
                errorValue={errors.confirm_password || ""}
                value={confirm_password}
                valueType="CONFIRM NEW PASSWORD"
                onChange={this.handleChangeInput("confirm_password")}
              />
            </Row>

            <div
              className="add-unit-footer"
              style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
            >
              <div className="button-regular-right">
                <button
                  className="assign-lesson-unit"
                  style={{}}
                  onClick={this.confirmCredentionals}
                >
                  SAVE
                </button>
              </div>
            </div>
          </form>
        </Col>
      </div>
    );
  }
}

let If = createClass({
  render: function() {
    if (this.props.test) {
      return this.props.children;
    } else {
      return false;
    }
  }
});

const mapStateToProps = ({ Auth, User }) => ({
  email: Auth.email,
  school: Auth.school,
  error: Auth.error,
  user_create: Auth.user_create,
  loginStatus : User.LoginStatus,
});

export default connect(
  mapStateToProps,
  { ...AuthActions, ...actions }
)(RegistrationComponent);
