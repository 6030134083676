import React, { Component } from "react";
import "../../assets/styles/admin.scss";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import ListView from "./ListView";
import authAPI from "../../api/authAPI";
import * as actions from "../../redux/actions";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import infoIcon from "../../assets/icons/PNG/icon-info-circle-solid.png";
import Tooltip from "../Tooltip";

let districtIDFromLocalStorage = 0
// = localStorage.getItem("district_id")

class SchoolAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameCheck: false,
      _licenses: [],
      contactNameCheck: false,
      emailCheck: false,
      phoneNumberCheck: false,
      zipCheck: false,
      countryCheck: false,
      stateCheck: false,
      districtTypeCheck: false,
      addressCheck: false,
      cityCheck: false,
      addressError: "",
      nameError: "",
      contactNameError: "",
      emailError: "",
      phoneError: "",
      zipError: "",
      countryError: "",
      stateError: "",
      districtTypeError: "",
      districtDetails: "",
      cityError: "",
      districtFinalDetails: {},
      isChangeable: false,
      country: "",
      region: "",
      disableScreen: false,
      districtIDFromLocalStorage:0
    };

    this.changeToTextBox = this.changeToTextBox.bind(this);
    this.saveDistrict = this.saveDistrict.bind(this);
    this.errorHandling = this.errorHandling.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.reloadLicenseDetais = this.reloadLicenseDetais.bind(this);
  }

  
  changeToTextBox() {
    // Clear errors when changing edit mode
    this.setState({
      addressCheck: false,
      addressError: "",

      nameCheck: false,
      nameError: "",

      contactNameCheck: false,
      contactNameError: "",

      emailCheck: false,
      emailError: "",

      phoneNumberCheck: false,
      phoneError: "",

      zipCheck: false,
      zipError: "",

      countryCheck: false,
      countryError: "",

      stateCheck: false,
      stateError: "",

      districtTypeCheck: false,
      districtTypeError: "",

      cityCheck: false,
      cityError: "",

      isChangeable: !this.state.isChangeable
    });
  }

  selectCountry(val) {
    this.setState({ country: val, region: null });
  }

  selectRegion(val) {
    this.setState({ region: val });
  }

  async componentDidMount() {

    districtIDFromLocalStorage = this.props.params.id;
    this.setState({
      districtIDFromLocalStorage:districtIDFromLocalStorage
    })
    // Changed Teacher to Unlimited -- This goes to actions/index.js getUsersAction
    const response = await authAPI.getDistrictLicenses({
      _id: districtIDFromLocalStorage
    });
    if (response && response.data) this.setState({ _licenses: response.data });

    this.props.getUsersAction(
      "School+Admin&userType=Unlimited&userType=Print+Only&userType=Text+Only",
      "",
      districtIDFromLocalStorage,
      1,
      0,
      0
    );
    this.props.getUsersAction("Student", "", districtIDFromLocalStorage, 1, 0, 0);
    let self = this;
    authAPI.getSchoolDistrict(districtIDFromLocalStorage).then(function(resp) {
      if (resp) {
        self.setState({
          districtDetails: resp,
          country: resp.country,
          region: resp.state
        });
      }
    });

    
  }

  errorHandling() {
    if (this.refs.name.value !== "") {
      if (this.state.nameCheck !== false || this.state.nameError !== "") {
        this.setState({
          nameCheck: false,
          nameError: ""
        });
      }
    }

    if (this.refs.contactName.value !== "") {
      if (
        this.state.contactNameCheck !== false ||
        this.state.contactNameError !== ""
      ) {
        this.setState({
          contactNameCheck: false,
          contactNameError: ""
        });
      }
    }

    if (this.refs.email.value !== "") {
      if (this.state.emailCheck !== false || this.state.emailError !== "") {
        this.setState({
          emailCheck: false,
          emailError: ""
        });
      }
    }

    if (this.refs.phoneNumber.value !== "") {
      if (
        this.state.phoneNumberCheck !== false ||
        this.state.phoneError !== ""
      ) {
        this.setState({
          phoneNumberCheck: false,
          phoneError: ""
        });
      }
    }

    if (this.refs.address.value !== "") {
      if (this.state.addressCheck !== false || this.state.addressError !== "") {
        this.setState({
          addressCheck: false,
          addressError: ""
        });
      }
    }

    if (this.refs.zipCode.value !== "") {
      if (this.state.zipCheck !== false || this.state.zipError !== "") {
        this.setState({
          zipCheck: false,
          zipError: ""
        });
      }
    }

    if (this.refs.city.value !== "") {
      if (this.state.cityCheck !== false || this.state.cityError !== "") {
        this.setState({
          cityCheck: false,
          cityError: ""
        });
      }
    }
  }

  saveDistrict() {
    let hasError = false;

    if (!this.refs.name.value) {
      this.setState({
        nameCheck: true,
        nameError: "Please Enter District Name"
      });
      hasError = true;
    } else {
      this.state.districtFinalDetails["name"] = this.refs.name.value;
    }

    if (!this.refs.contactName.value) {
      this.setState({
        contactNameCheck: true,
        contactNameError: "Please Enter contact Name"
      });
      hasError = true;
    } else {
      this.state.districtFinalDetails[
        "contact_name"
      ] = this.refs.contactName.value;
    }

    if (!this.refs.address.value) {
      this.setState({
        addressCheck: true,
        addressError: "Please Enter address"
      });
      hasError = true;
    } else {
      this.state.districtFinalDetails["address"] = this.refs.address.value;
    }

    if (!this.refs.city.value) {
      this.setState({
        cityCheck: true,
        cityError: "Please Enter city"
      });
      hasError = true;
    } else {
      this.state.districtFinalDetails["city"] = this.refs.city.value;
    }

    if (!this.refs.email.value) {
      this.setState({
        emailCheck: true,
        emailError: "Please Enter Email-id"
      });
      hasError = true;
    } else {
      // eslint-disable-next-line
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(this.refs.email.value)) {
        this.state.districtFinalDetails["email"] = this.refs.email.value;
      } else {
        this.setState({
          emailCheck: true,
          emailError: "Please Enter valid Email-id"
        });
        hasError = true;
      }
    }

    if (!this.refs.phoneNumber.value) {
      this.setState({
        phoneNumberCheck: true,
        phoneError: "Please Enter phone number"
      });
      hasError = true;
    } else {
      this.state.districtFinalDetails[
        "phone_number"
      ] = this.refs.phoneNumber.value;
    }

    if (!this.refs.zipCode.value) {
      this.setState({
        zipCheck: true,
        zipError: "Please Enter zip code"
      });
      hasError = true;
    } else {
      if (/^\d{5}(-\d{4})?$/.test(this.refs.zipCode.value) === false) {
        this.setState({
          zipCheck: true,
          zipError: "Please Enter valid zip-code"
        });
        hasError = true;
      } else {
        this.state.districtFinalDetails["zip_code"] = this.refs.zipCode.value;
      }
    }

    if (!this.refs.districtType.value) {
      this.setState({
        districtTypeCheck: true,
        districtTypeError: "please select district type"
      });
      hasError = true;
    } else {
      this.state.districtFinalDetails[
        "school_district_type"
      ] = this.refs.districtType.value;
    }

    if (!this.state.country) {
      this.setState({
        countryCheck: true,
        countryError: "please select country"
      });
      hasError = true;
    } else {
      this.state.districtFinalDetails["country"] = this.state.country;
    }

    if (!this.state.region) {
      this.setState({
        stateCheck: true,
        stateError: "please select state"
      });
      hasError = true;
    } else {
      this.state.districtFinalDetails["state"] = this.state.region;
    }

    if (hasError === false) {
      authAPI
        .putDistrict(districtIDFromLocalStorage, this.state.districtFinalDetails)
        .then(resp => {
          let districtDetailsLocal = this.state.districtFinalDetails;
          districtDetailsLocal.school_district_type = {
            value: this.state.districtFinalDetails.school_district_type
          };
          if (resp.statusCode === 200) {
            this.setState({
              isChangeable: !this.state.isChangeable,
              districtDetails: districtDetailsLocal
            });
          }
        });
    }
  }

  backgroundChange = status => {
    this.setState({
      disableScreen: status
    });
  };

  resetPassword(user_id) {
    this.props.adminResetPassword(user_id);
  }

  async reloadLicenseDetais(){
    let self= this;
    let districtIDFromLocalStorage = this.props.params.id;
    // Changed Teacher to Unlimited -- This goes to actions/index.js getUsersAction
    const response = await authAPI.getDistrictLicenses({
      _id: districtIDFromLocalStorage
    });
    if (response && response.data) self.setState({ _licenses: response.data });

    // self.props.getUsersAction(
    //   "School+Admin&userType=Unlimited&userType=Print+Only",
    //   "",
    //   districtIDFromLocalStorage,
    //   1,
    //   0,
    //   0
    // );
    authAPI.getSchoolDistrict(districtIDFromLocalStorage).then(function(resp) {
      if (resp) {
        self.setState({
          districtDetails: resp,
          country: resp.country,
          region: resp.state
        });
      }
    });
  }

  render() {
    const { _licenses } = this.state;

    const licenses = {
      unlimited_count: parseInt(this.state.districtDetails.unlimited_count, 10),
      unlimited_available: parseInt(
        this.state.districtDetails.unlimited_available,
        10
      ),
      text_only_count: parseInt(this.state.districtDetails.text_only_count, 10),
      text_only_available: parseInt(
        this.state.districtDetails.text_only_available,
        10
      ),
      print_only_count: parseInt(
        this.state.districtDetails.print_only_count,
        10
      ),
      print_only_available: parseInt(
        this.state.districtDetails.print_only_available,
        10
      )
    };

    // const licenses = {
    //   unlimited_count: this.state.districtDetails.unlimited_count? parseInt(this.state.districtDetails.unlimited_count, 10) : 0,
    //   unlimited_available: this.state.districtDetails.unlimited_available? parseInt(this.state.districtDetails.unlimited_available, 10) : 0,
    //   print_only_count: this.state.districtDetails.print_only_count? parseInt(this.state.districtDetails.print_only_count, 10) : 0,
    //   print_only_available: this.state.districtDetails.print_only_available? parseInt(this.state.districtDetails.print_only_available, 10) : 0
    // };
    
    return (
      <div className={this.state.disableScreen ? "dialog-background" : ""}>
        <div className="admin-bar">
          <span className="add-padding-back-icon">
            <i
              onClick={browserHistory.goBack}
              className="chevron-left-demo-icon icon-icon-back"
            />
          </span>
          School District Admin
        </div>
        <div className="main-area">
          {(localStorage.user_type === "System Admin" ||
            localStorage.user_type === "School Admin") && (
            <div>
              <div className="admin-hero">
                <div className="details-info">
                  {this.state.isChangeable ? (
                    <div className="school-data">
                      <div className="edit-row">
                        <i
                          onClick={this.changeToTextBox}
                          className="icon-icn-close can-hover edit-school-district-close"
                        />
                      </div>
                      <div className="edit-data">
                        <div className="input-info-items">
                          <div
                            className="input-info-items"
                            style={{ float: "left" }}
                          >
                            <div className="edit-district-type">
                              District Name<span className="required-asterisk">
                                *
                              </span>
                            </div>
                            <input
                              ref="name"
                              onKeyUp={this.errorHandling}
                              className={
                                this.state.nameCheck
                                  ? "red-border input-info-district edit-district-item"
                                  : "input-info-district edit-district-item"
                              }
                              type="text"
                              defaultValue={this.state.districtDetails.name}
                            />
                            {this.state.nameError && (
                              <div className="error-format">
                                {this.state.nameError}
                              </div>
                            )}
                          </div>
                          <div
                            className="input-info-items"
                            style={{ float: "left", paddingLeft: "20px" }}
                          >
                            <div className="edit-district-type">
                              District Type<span className="required-asterisk">
                                *
                              </span>
                            </div>
                            <select
                              ref="districtType"
                              className={
                                this.state.districtTypeCheck
                                  ? "error-border dropdown-state-country edit-district-item"
                                  : "dropdown-state-country edit-district-item"
                              }
                              type="text"
                              defaultValue={
                                this.state.districtDetails.school_district_type
                                  .value
                              }
                            >
                              <option value="Public">Public</option>
                              <option value="Private">Private</option>
                              <option value="Charter">Charter</option>
                              <option value="Diocese">Diocese</option>
                              <option value="DODA">DODA</option>
                              <option value="State">State</option>
                              <option value="Home-School">Home-School</option>
                            </select>
                            {this.state.districtTypeError && (
                              <div className="error-format">
                                {this.state.districtTypeError}
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="input-info-items"
                          style={{ float: "left", clear: "left" }}
                        >
                          <div style={{ float: "left" }}>
                            <div className="edit-district-type">
                              Address<span className="required-asterisk">
                                *
                              </span>
                            </div>
                            <input
                              className={
                                this.state.addressCheck
                                  ? "red-border input-info-district edit-district-address"
                                  : "input-info-district edit-district-address"
                              }
                              ref="address"
                              onKeyUp={this.errorHandling}
                              type="text"
                              defaultValue={this.state.districtDetails.address}
                            />
                            {this.state.addressError && (
                              <div className="error-format">
                                {this.state.addressError}
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="input-info-items"
                          style={{ float: "left", paddingLeft: "20px" }}
                        >
                          <div className="edit-district-type">
                            City<span className="required-asterisk">*</span>
                          </div>
                          <input
                            ref="city"
                            onKeyUp={this.errorHandling}
                            className={
                              this.state.nameCheck
                                ? "red-border input-info-district edit-district-item"
                                : "input-info-district edit-district-item"
                            }
                            type="text"
                            defaultValue={this.state.districtDetails.city}
                          />
                          {this.state.cityError && (
                            <div className="error-format">
                              {this.state.cityError}
                            </div>
                          )}
                        </div>
                        <div
                          className="input-info-items"
                          style={{ float: "left", clear: "left" }}
                        >
                          {/*<input   type="text" defaultValue={this.state.stateValue} />*/}
                          {/*<div style={{float:'left'}}><CountryDropdown ref='country' value={this.state.country} classes={this.state.countryCheck?"error-border dropdown-state-country":"dropdown-state-country"} valueType="short" onChange={(val) => this.selectCountry(val)} />*/}
                          <div style={{ float: "left" }}>
                            <div className="edit-district-type">
                              Country<span className="required-asterisk">
                                *
                              </span>
                            </div>
                            <CountryDropdown
                              ref="country"
                              value={this.state.country}
                              classes="dropdown-state-country edit-district-item"
                              valueType="short"
                              onChange={val => this.selectCountry(val)}
                            />
                            {this.state.countryError && (
                              <div className="error-format">
                                {this.state.countryError}
                              </div>
                            )}
                          </div>
                          {/*<div style={{paddingLeft:'20px',float:'left'}}><RegionDropdown value={this.state.region} ref='state' classes={this.state.stateCheck?"error-border dropdown-state-country":"dropdown-state-country"} valueType="short" countryValueType="short" country={this.state.country?this.state.country:''}  onChange={(val) => this.selectRegion(val)} />*/}
                          <div style={{ paddingLeft: "20px", float: "left" }}>
                            <div className="edit-district-type">
                              State<span className="required-asterisk">*</span>
                            </div>
                            <RegionDropdown
                              ref="state"
                              value={this.state.region}
                              classes="dropdown-state-country edit-district-item"
                              valueType="short"
                              countryValueType="short"
                              country={
                                this.state.country
                                  ? this.state.country
                                  : this.state.districtDetails.country
                              }
                              onChange={val => this.selectRegion(val)}
                            />
                            {this.state.stateError && (
                              <div className="error-format">
                                {this.state.stateError}
                              </div>
                            )}
                          </div>
                          <div style={{ paddingLeft: "20px", float: "left" }}>
                            <div className="edit-district-type">
                              Zip Code<span className="required-asterisk">
                                *
                              </span>
                            </div>
                            <input
                              ref="zipCode"
                              className={
                                this.state.zipCheck
                                  ? "red-border input-info-district edit-district-item"
                                  : "input-info-district edit-district-item"
                              }
                              onKeyUp={this.errorHandling}
                              type="text"
                              defaultValue={this.state.districtDetails.zip_code}
                            />
                            {this.state.zipError && (
                              <div className="error-format">
                                {this.state.zipError}
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="input-info-items"
                          style={{ float: "left", clear: "left" }}
                        >
                          <div style={{ float: "left" }}>
                            <div className="edit-district-type">
                              Contact Name<span className="required-asterisk">
                                *
                              </span>
                            </div>
                            <input
                              className={
                                this.state.contactNameCheck
                                  ? "red-border input-info-district edit-district-item"
                                  : "input-info-district edit-district-item"
                              }
                              ref="contactName"
                              onKeyUp={this.errorHandling}
                              type="text"
                              defaultValue={
                                this.state.districtDetails.contact_name
                              }
                            />
                            {this.state.contactNameError && (
                              <div className="error-format">
                                {this.state.contactNameError}
                              </div>
                            )}
                          </div>
                          <div style={{ paddingLeft: "20px", float: "left" }}>
                            <div className="edit-district-type">
                              Phone Number<span className="required-asterisk">
                                *
                              </span>
                            </div>
                            <input
                              ref="phoneNumber"
                              className={
                                this.state.phoneNumberCheck
                                  ? "red-border input-info-district edit-district-item"
                                  : "input-info-district edit-district-item"
                              }
                              onKeyUp={this.errorHandling}
                              type="text"
                              defaultValue={
                                this.state.districtDetails.phone_number
                              }
                            />
                            {this.state.phoneError && (
                              <div className="error-format">
                                {this.state.phoneError}
                              </div>
                            )}
                          </div>
                          <div style={{ paddingLeft: "20px", float: "left" }}>
                            <div className="edit-district-type">
                              Email<span className="required-asterisk">*</span>
                            </div>
                            <input
                              ref="email"
                              onKeyUp={this.errorHandling}
                              className={
                                this.state.emailCheck
                                  ? "red-border input-info-district edit-district-item"
                                  : "input-info-district edit-district-item"
                              }
                              type="text"
                              defaultValue={this.state.districtDetails.email}
                            />
                            {this.state.emailError && (
                              <div className="error-format">
                                {this.state.emailError}
                              </div>
                            )}
                          </div>
                        </div>
                        <div style={{ float: "left", clear: "left" }}>
                          <button
                            onClick={this.saveDistrict}
                            className="save-edit-district"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="school-data">
                      <div className="edit-row">
                        <i
                          onClick={this.changeToTextBox}
                          className="icon-icon-pen-circle-outline can-hover edit-school-district"
                        />
                      </div>
                      <div className="edit-data">
                        <span className="district-name">
                          {this.state.districtDetails.name}
                        </span>
                        <div className="address-of-district">
                          {this.state.districtDetails.address}{" "}
                          {this.state.districtDetails.city},{" "}
                          {this.state.districtDetails.state}{" "}
                          {this.state.districtDetails.zip_code}
                        </div>
                        <div className="info-of-district">
                          <strong>Licenses: </strong>
                          {/* Added Div to hold unlimited and print only numbers*/}
                          <div
                            style={{
                              display: "inline-flex",
                              flexDirection: "column"
                            }}
                          >
                            {/* Unlimited: {this.state.districtDetails.unlimited_available}/{this.state.districtDetails.unlimited_count} (Used/Total)  */}
                            <div style={{ display: "inline-flex" }}>
                              Unlimited:{" "}
                              {this.state.districtDetails.unlimited_count -
                                this.state.districtDetails
                                  .unlimited_available}/{
                                this.state.districtDetails.unlimited_count
                              }{" "}
                              (Used/Total)
                              <Tooltip
                                icon={
                                  <img
                                    src={infoIcon}
                                    style={{ height: "20px", marginLeft: 10 }}
                                  />
                                }
                              >
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th scope="col">Available Licenses</th>
                                      <th scope="col">Expiration Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {_licenses.sort( license => license.license_type == 'U' ? -1 : 1 )
                                      .map((license,i) => (
                                        <tr key={i}>
                                          <td>{license.available}</td>
                                          <td>{license.license_type == 'P' ? 'Perpetual' : license.expire_date} </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </Tooltip>
                              
                            </div>

                            <div style={{ display: "inline-flex" }}>
                              Text Only:{" "}
                              {this.state.districtDetails.text_only_count -
                                this.state.districtDetails
                                  .text_only_available}/{
                                this.state.districtDetails.text_only_count
                              }{" "}
                              (Used/Total)
                              <Tooltip
                                icon={
                                  <img
                                    src={infoIcon}
                                    style={{ height: "20px", marginLeft: 10 }}
                                  />
                                }
                              >
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th scope="col">Available Licenses</th>
                                      <th scope="col">Expiration Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {_licenses.sort( license => license.license_type == 'T' ? -1 : 1 )
                                      .map((license,i) => (
                                        <tr key={i}>
                                          <td>{license.available}</td>
                                          <td>{license.license_type == 'P' ? 'Perpetual' : license.expire_date} </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </Tooltip>
                              
                            </div>

                            <div style={{ display: "inline-flex" }}>
                              {/* Print-Only: {this.state.districtDetails.print_only_available}/{this.state.districtDetails.print_only_count} (Used/Total) */}
                              Print-Only:{" "}
                              {this.state.districtDetails.print_only_count -
                                this.state.districtDetails
                                  .print_only_available}/{
                                this.state.districtDetails.print_only_count
                              }{" "}
                              (Used/Total)
                              
                            </div>
                          </div>
                        </div>
                        <div className="info-of-district">
                          <strong>Contact: </strong>
                          {this.state.districtDetails.contact_name}
                        </div>
                        <div className="info-of-district">
                          <strong>Phone: </strong>
                          {this.state.districtDetails.phone_number}
                        </div>
                        <div className="info-of-district">
                          <strong>Email: </strong>
                          {this.state.districtDetails.email}
                        </div>
                        {this.state.districtDetails.school_district_type && (
                          <div className="info-of-district">
                            <strong>Type: </strong>
                            {this.state.districtDetails.school_district_type
                              .value
                              ? this.state.districtDetails.school_district_type
                                  .value
                              : this.state.districtDetails.school_district_type}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div>{ }</div>
              </div>
              <div>
                {/* Changed Teacher to Unlimited */}
                <ListView
                  userType="combinedList"
                  item={"SchoolAdminItem"}
                  templateUrl="../schoolwidefun/api/v1/users/export"
                  districtName={this.state.districtDetails.name}
                  backgroundChange={this.backgroundChange}
                  getUsers={this.props.getUsersAction}
                  districtId={this.props.params.id}
                  // bulkAddType="Unlimited"
                  bulkAddType="Users"
                  heightOfTheList="400px"
                  deleteUser={this.props.deleteUserAction}
                  listTitle="Manage School Admins and Teachers"
                  resetPassword={this.resetPassword}
                  reloadLicenseDetais={this.reloadLicenseDetais}
                  {...licenses}
                />
                <ListView
                  userType="Student"
                  item={"SysAdminItem"}
                  templateUrl="../schoolwidefun/api/v1/users/export"
                  districtName={this.state.districtDetails.name}
                  backgroundChange={this.backgroundChange}
                  getUsers={this.props.getUsersAction}
                  districtId={this.props.params.id}
                  bulkAddType="Student"
                  heightOfTheList="400px"
                  deleteUser={this.props.deleteUserAction}
                  listTitle="Manage Students"
                  resetPassword={this.resetPassword}
                  {...licenses}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.Users
  };
};

export default connect(
  mapStateToProps,
  actions
)(SchoolAdmin);
