import React, { Component } from "react";
import "../assets/styles/teachingResources.scss";
import "../assets/styles/header.scss";
import NewBrowseComponent from "../components/NewBrowseComponent";
import * as actions from "../redux/actions";
import { connect } from "react-redux";
import { browserHistory, withRouter } from "react-router";
import constants from "../constants";
import { NavDropdown } from "react-bootstrap";

import logo from "../assets/images/Fundamentals_Logo.png";
import logoutIcon from "../assets/icons/SVG/logout_icon.svg";

import BrowseDropdownContents from "../components/BrowseDropdownContents";
import _ from "lodash";
import authAPI from "../api/authAPI";

import { gapi } from "gapi-script";

class NewHeader extends Component {
  constructor(props) {
    super(props);
    const showFilter =
      props.finalFilters &&
      props.finalFilters.filterList &&
      props.finalFilters.filterList.length > 0;
    this.state = {
      clicked: "",
      currentColor: "",
      filterRecieved: false,
      filterList: [],
      showSearch: false,
      showFolder: false,
      showFilter: showFilter,
      showMenu: false,
      searchColor: "#9B9B9B",
      joyrideResetFilters: false,
    };
    this.showRight = this.showRight.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.multiStateIconShouldPauseJoyride =
      this.multiStateIconShouldPauseJoyride.bind(this);
    if (showFilter) {
      setTimeout(() => {
        this.showRight();
      }, 200);
    }
  }

  showRight = () => {
    if (this.state.showSearch) {
      this.setState(
        {
          showFilter: false,
          showFolder: false,
          showMenu: true,
          searchColor: "#009BBD",
          currentColor: "##9B9B9B",
        },
        () => this?.refs?.right?.show()
      );
    } else if (
      this.state.showFilter &&
      this.props.buttonClicked !== "featured" &&
      this.props.params.strand !== "featured"
    ) {
      this.setState(
        {
          showSearch: false,
          showFolder: false,
          searchColor: "#9B9B9B",
          showMenu: true,
        },
        () => this?.refs?.right?.show()
      );

      if (this.state.currentColor !== "#009BBD") {
        this.setState({ currentColor: "#009BBD" });
      } else {
        this.setState({ currentColor: "#9B9B9B" });
      }
    } else if (this.state.showFolder) {
      this.setState(
        {
          showFilter: false,
          showSearch: false,
          searchColor: "#9B9B9B",
          currentColor: "##9B9B9B",
          showMenu: true,
        },
        () => this?.refs?.right?.show()
      );
    } else {
      this.setState(
        {
          currentColor: "#9B9B9B",
          searchColor: "#9B9B9B",
          showMenu: false,
          showFilter: false,
        },
        () => this?.refs?.right?.hide()
      );
    }
  };

  closeRight() {
    this.setState({ currentColor: "#9B9B9B", showFilter: false });
    this?.refs?.right?.hide();
  }

  componentDidMount() {
    let oldFilterQueryString = "";
    if (
      this.props.location.action === "POP" &&
      this.props.finalFilters &&
      this.props.finalFilters.filterList
    ) {
      if (this.props.finalFilters.filterList > 0) {
        oldFilterQueryString = constants.getQueryStringFromFilterList(
          this.props.finalFilters.filterList
        );
      } else if (window.sessionStorage.getItem("selectedFilters") != null) {
        oldFilterQueryString = constants.getQueryStringFromFilterList(
          JSON.parse(window.sessionStorage.getItem("selectedFilters"))
        );
      }
    } else {
      this.props.addingFiltersAction([]);
      this.setState({ showFilter: false });
    }
    if (this.props.typeOfResource === "featured") {
      this.props.getCustomFiltersAction(1, "units");
    } else if (this.props.typeOfResource === "books") {
      this.props.getCustomFiltersAction(
        "books",
        this.props.typeOfResource,
        oldFilterQueryString
      );
    } else if (this.props.typeOfResource === "lessons") {
      this.props.getCustomFiltersAction(1, "lessons");
    } else {
      let strand_to_get = this.props.buttonClicked
        ? constants.caseMap[this.props.buttonClicked]
        : constants.caseMap[this.props.params.strand];
      if (strand_to_get)
        this.props.getCustomFiltersAction(
          strand_to_get,
          this.props.typeOfResource,
          oldFilterQueryString
        );
    }
    this.props.getNewCustomFiltersAction();

  }

  filterLoadShouldStartJoyrideIfState = () => {
    if (
      this.state.joyrideResetFilters &&
      this.state.showFilter &&
      !this.props.joyrideShouldRun
    ) {
      this.props.allowClicksThruHere(false);
      _.debounce(this.props.nextJoyrideStep, 300, {
        leading: false,
        trailing: true,
      })();
      _.debounce(this.props.startJoyride, 500, {
        leading: false,
        trailing: true,
      })();
      this.setState({ joyrideResetFilters: false });
    }
    return true;
  };

  componentWillReceiveProps(nextProps) {
    let buttonValue = constants.caseMap[nextProps.buttonClicked];
    if (
      this.props.buttonClicked !== nextProps.buttonClicked &&
      buttonValue !== 0
    ) {
      this.props.getCustomFiltersAction(buttonValue, this.props.typeOfResource);
      this.props.addingFiltersAction([]);
      this.setState({ filterList: [] });
    }
    if (
      nextProps.buttonClicked === "featured" ||
      this.props.params.strand === "featured"
    ) {
      this.setState({ currentColor: "#9B9B9B", searchColor: "#9B9B9B" });
      this.closeRight();
    }

    this.setState({
      clicked: nextProps.buttonClicked,
    });

    let sidebarShouldNotShow =
      this.props.joyrideIntendsToStart ||
      nextProps.joyrideIntendsToStart ||
      this.props.joyrideShouldRun ||
      nextProps.joyrideShouldRun;
    let sidebarShouldShowWithFilters =
      this.props.finalFilters &&
      this.props.finalFilters.filterList &&
      this.props.finalFilters.filterList.length > 0 &&
      this.props.finalFilters.filterList === nextProps.finalFilters.filterList;

    if (nextProps.joyrideIntendsToStart) {
      this.closeRight();
      this.props.joyrideIntentToStart(false);
      _.debounce(() => this.props.resetJoyride(null, 2), 300, {
        leading: false,
        trailing: true,
      })();
    }

    if (!sidebarShouldNotShow && sidebarShouldShowWithFilters) {
      this.setState({ filterRecieved: true, showFilter: true });
      this.showRight();
    }
  }

  clearFilters() {
    this.setState({
      filterList: [],
    });
    this.props.addingFiltersAction([]);
    this.props.clearAllFiltersAction(true);
  }

  filterDisplay = () => {
    let self = this;
    this.setState({ showFilter: !self.state.showFilter }, () =>
      this.showRight()
    );
    this.setState({ showFolder: false });
    this.setState({ showSearch: false });
  };

  searchDisplay = () => {
    let self = this;
    this.setState({ showSearch: !self.state.showSearch }, () =>
      this.showRight()
    );
    this.setState({ showFolder: false });
    this.setState({ showFilter: false });
  };

  showFolder = () => {
    let self = this;
    this.setState({ showFolder: !self.state.showFolder }, () =>
      this.showRight()
    );
    this.setState({ showSearch: false });
    this.setState({ showFilter: false });
  };

  multiStateIconShouldPauseJoyride = () => {
    this.props.stopJoyride();
    this.setState({ joyrideResetFilters: true });
  };

  renderDropdown = () => {
    if (localStorage.is_lite === "true") {
      return (
        <div className="containerDropdown">
          <div className="header-teaching-resource fixed">
            {this.props.name}
          </div>
        </div>
      );
    } else {
      return (
        <NavDropdown
          style={{ float: "left" }}
          title={
            <div className="containerDropdown">
              <div className="header-teaching-resource fixed">
                {this.props.name}
              </div>
              <div className="header-chevron icon-icon-chevron-down fixed-item" />
            </div>
          }
        >
          <BrowseDropdownContents />
        </NavDropdown>
      );
    }
  };

  logout = () => {
    console.log("logout is getting called");
    
    var auth2 = gapi?.auth2?.getAuthInstance();
    localStorage.removeItem("prevPathLoc");

    if (auth2?.isSignedIn) {
      auth2.signOut().then(() => {
        this.setState({ user: null });
      });
      auth2.disconnect();
      let loginUrl = "/";

      if (
        localStorage.getItem("first_time_login") === "true" &&
        localStorage.getItem("googlesignin") === "true"
      ) {
        authAPI.updateFirstTimeUserLogin(
          localStorage.getItem("googlesigninusername")
        );
      }

      if (localStorage.picture_password_user === "true") {
        loginUrl += "picture-password/";
        if (
          localStorage.login_domain !== "false" &&
          localStorage.login_domain !== undefined
        )
          loginUrl += localStorage.login_domain;
      } else if (
        localStorage.login_domain !== "false" &&
        localStorage.login_domain !== undefined
      ) {
        loginUrl += "login/" + localStorage.login_domain;
      }
      document.cookie =
        "sessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      localStorage.clear();

      browserHistory.push(loginUrl);
      setTimeout(function () {
        window.location.reload();
      }, 100);
    } else {
      this.props
        .logoutAction()
        .then((response) => {
          if (response.statusCode === 200) {
            let loginUrl = "/";

            if (localStorage.picture_password_user === "true") {
              loginUrl += "picture-password/";
              if (
                localStorage.login_domain !== "false" &&
                localStorage.login_domain !== undefined
              )
                loginUrl += localStorage.login_domain;
            } else if (
              localStorage.login_domain !== "false" &&
              localStorage.login_domain !== undefined
            ) {
              loginUrl += "login/" + localStorage.login_domain;
            }
            document.cookie =
              "sessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

            localStorage.clear();

            browserHistory.push(loginUrl);
            setTimeout(function () {
              window.location.reload();
            }, 100);
          } else {
            console.log("logout failed");
          }
        })
        .catch(function (error) {
          console.log("logout exception occurred");
        });
    }
  };

  render() {
    const folderNotifications =
      this.props.myLibrary?.data?.notifications?.results || [];

    return (
      <div>
        <div className="header-container">
          <div className="header-top-part">
            <div className="header-spacing"></div>
            <div className="header-main">
              <div
                onClick={() => browserHistory.push("/browse")}
                className="main-logo"
              >
                <img width={130} src={logo} alt="" />
              </div>
              <ul className="header-nav-ul">
                {localStorage.user_type !== "Student" && (
                  <li
                    className={`${window.location.pathname.includes("/browse")
                      ? "header-nav-li active"
                      : ""
                      }`}
                    onClick={() =>
                      browserHistory.push(
                        localStorage.is_lite === "true"
                          ? "/browse/teaching-resources/all"
                          : "/browse"
                      )
                    }
                  >
                    Home
                  </li>
                )}
                {localStorage.is_lite !== "true" && (
                  <li
                    className={`${window.location.pathname.includes("/library")
                      ? "header-nav-li active"
                      : ""
                      }`}
                    onClick={() => browserHistory.push("/library")}
                  >
                    My Library
                  </li>
                )}
                <li
                  className={`${window.location.pathname === "/user-profile"
                    ? "header-nav-li active"
                    : ""
                    }`}
                  onClick={() => browserHistory.push("/user-profile")}
                >
                  My Account
                </li>
              </ul>
            </div>
            <div className="header-spacing">
              <div className="logout-btn" onClick={this.logout}>
                <img width={24} src={logoutIcon} alt="" />
                <span>Logout</span>
              </div>
            </div>
          </div>
          <div className="header-bottom-part">
            <NewBrowseComponent
              headerType={this.props?.headerType}
              location={this.props.location}
              folderNotifications={folderNotifications}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    myLibrary: state.MyLibrary,
    finalFilters: state.FilterScale,
    lesssonAddedSuccess: state.LessonAddedSuccess,
    joyrideShouldRun: state.JoyrideReducer.joyrideShouldRun,
    joyrideStepIndex: state.JoyrideReducer.joyrideStepIndex,
    joyrideIntendsToStart: state.JoyrideReducer.joyrideIntendsToStart,
  };
};

export default connect(mapStateToProps, actions)(withRouter(NewHeader));
