// Displays selected date text with selection dropdown
import React, {Component} from 'react'
import Datetime from 'react-datetime'
import '../../assets/styles/react-datetime.css'

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dev"];

// Props
// - date - date to render
// - displayShortMonth - boolean, whether to display short month names
// - showDatePicker - whether to show the datepicker
// - showDay - default false

// - toggleDatePicker - called when chevron is clicked
// - handleDate - function to call to handle a date change
export default class DropdownLabelDatePicker extends Component {

    render() {
        let width = window.innerWidth;
        const datePickerChevronDownIcon =
            (<i onClick={this.props.toggleDatePicker}
                className='month-year-demo-icon icon-icon-chevron-down'
            />);
        const date = this.props.date;
        const month = this.props.displayShortMonth? shortMonths[date.getMonth()] : months[date.getMonth()];
        const showDay = this.props.showDay? this.props.showDay : false;
        return (
            <div className="format-month-year">
                <span>
                    <strong>
                        {month}
                    </strong>
                </span>
                {showDay &&
                    <span>
                        {date.getDate()},
                    </span>
                }
                <span>
                    {date.getFullYear()}
                </span>
                {datePickerChevronDownIcon}
                {this.props.showDatePicker && (width < 768 ? (
                  <div className="mobile-datetime-wrapper">
                    <div className="datetime-close-x"
                      onClick={this.props.toggleDatePicker}>
                      X
                    </div>
                    <Datetime
                        className="mobile-datetime"
                        value={date}
                        locale='en'
                        open={true}
                        onChange={this.props.handleDate}
                    />
                  </div>
                ) : (
                    <Datetime
                        value={date}
                        locale='en'
                        open={true}
                        onChange={this.props.handleDate}
                        />
                ))
                }
            </div>
        );
    }
}
