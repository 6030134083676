import authAPI from '../../api/authAPI';
export const GET_UNITS_DETAIL_REQUEST = 'GET_UNITS_DETAIL_REQUEST';
export const GET_UNITS_DETAIL_SUCCESS = 'GET_UNITS_DETAIL_SUCCESS';
export const GET_UNITS_DETAIL_FAILURE = 'GET_UNITS_DETAIL_FAILURE';



export const getUnitsDetailAction = (id) => {
    return dispatch => {
        dispatch({type: GET_UNITS_DETAIL_REQUEST});
        authAPI.getUnitsDetail(id)
            .then((response)=>{
                dispatch ({
                    type : GET_UNITS_DETAIL_SUCCESS,
                    payload : response,
                    id: ""+id

                })

            })
            .catch(function (error) {

                dispatch({
                    type : GET_UNITS_DETAIL_FAILURE,
                    payload : error
                })

            });

    }

}