import React, {Component} from 'react'
import Classroom from './Classroom'
import constants from '../../constants/index.js'

class ClassRooms extends Component {

    render() {
        return (
            <div style={this.props.showMenu?{position: "fixed", top:"60px", bottom: "70px", width: "calc(100% - 355px)", overflowY: "auto", overflowX: "hidden"}:{position: "fixed", top:"60px", bottom: "70px", width: "100%", overflowY: "auto", overflowX: "hidden"}}>
                <div style={{textAlign: 'center', margin: '10px 0px -10px 0px'}}>
                    <a style={{color: '#666666', textDecoration: 'underline'}} href={`${constants.filesUrl}files/StudentAccessInstructions.pdf`} target="_blank">
                        Student Access Instructions
                    </a>
                </div>
                {this.props.groups.asyncStatus === 'SUCCESS' && this.props.groups.data && this.props.groups.data.map((group, i)=>
                    <div key={i}>
                        <Classroom
                            refresh={this.props.refresh}
                            setShowEditGroup={this.props.setShowEditGroup}
                            importGroupClicked={this.props.importGroupClicked}
                            groupDetails={group}/>
                    </div>
                )}
            </div>
        );
    }
}

export default ClassRooms
