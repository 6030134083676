import React,{Component} from 'react'
import {Row, Col} from 'react-bootstrap'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import '../../assets/styles/importModal.scss';
import loading from '../../assets/images/Loading.gif';

// state
// -- isUploading - Whether we are currently uploading the data and awaiting a response
// -- fileUploaded - Whether a file has been uploaded
// -- fileName - the name of the selected file, undefined if not selected
// -- fileString - contents of the file

// props
// -- headerText - text at the top of the modal
// -- templateUrl - location to download the template
// -- style - style applied to the modal
// -- id - id passed to the action
// -- type - type passed to the action if applicable (for teachers and students)
// -- onCancel - called when the modal is closed
// -- refresh -- called when new data is available
// -- importAction -- action called for import

class ImportModal extends Component {
    constructor (props) {
        super(props);
        this.state = {
            isUploading: false,  // Whether a file is currently uploading
            fileUploaded: false // Whether a file has been uploaded
        };

        this.uploadUsers = this.uploadUsers.bind(this);
        this.loadHandler = this.loadHandler.bind(this);
    }

    readFile(file) {
        let reader = new FileReader();
        reader.onload = this.loadHandler;
        reader.onerror = this.errorHandler;
        reader.readAsText(file);
    }

    componentWillReceiveProps(next) {
        if(this.state.isUploading && next.importUsers.asyncStatus !== "PENDING") {
            this.setState({isUploading: false});
            this.setState({fileUploaded: true});
            this.props.refresh();
        }else{
            console.log(next.importUsers.asyncStatus);
        }
    }

    uploadUsers() {
        this.setState({isUploading: true});
        setTimeout(() => this.props.importAction(this.props.type, this.props.id, this.state.fileString), 3000);
    }

    loadHandler(event) {
        let csv = event.target.result;
        this.setState({fileString: csv});
    }

    updateField = (field,event)=>{
        this.setState({[field] : event.target.files[0].name.toString()});
        this.readFile(event.target.files[0]);
    }

    // Is the download template button enabled?
    isDownloadDisabled() {
        return this.state.isUploading;
    }

    // Is the browse button enabled?
    isBrowseDisabled() {
        return this.state.isUploading;
    }

    // Is the upload button disabled?
    isUploadDisabled() {
        return this.state.isUploading || (this.state.fileName === undefined);
    }

    isSpinnerShown() {
        return this.state.isUploading;
    }

    isUploadStatusShown() {
       return this.state.fileUploaded && !this.state.isUploading;
    }

    browseButtonStyle(){
        return {
            opacity: this.isBrowseDisabled()? '0.8' : '1.0',
            cursor: this.isBrowseDisabled()? 'default' : 'pointer'
        }
    }

    isTotalCountDisplayed() {
        return this.props.importUsers.data.message.count &&
            (this.props.importUsers.data.message.count.total !== undefined);
    }

    isUpdatedCountDisplayed() {
        return this.props.importUsers.data.message.count &&
            (this.props.importUsers.data.message.count.updated !== undefined);
    }
    isSkippedCountDisplayed() {
        return this.props.importUsers.data.message.count &&
            (this.props.importUsers.data.message.count.skipped !== undefined);
    }

    isInsertedCountDisplayed() {
        return this.props.importUsers.data.message.count &&
            (this.props.importUsers.data.message.count.inserted !== undefined);
    }

    render(){
        return(
            <div>
                <div className='background-disable'/>
                    <div className="import-modal" /*style={this.props.style}*/ style={{'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto'}}>
                
                    <div className="bulk-add-header">
                        <span>
                            <i onClick={this.props.onCancel} className="close-modal icon-icn-close"/>
                        </span>
                        <div className="bulk-add-heading">
                            {this.props.headerText}
                        </div>
                    </div>
                    <div className="suggestions-wrapper">
                        <div className="bulk-add-suggestions">
                            <Row>
                                <Col sm={12}>
                                    <h1 className="import-text">
                                        1.) Download a new CSV template (or use an existing CSV template).
                                    </h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <a href={this.props.templateUrl+"?user_type="+this.props.type}>
                                        <button disabled={this.isDownloadDisabled()}
                                                className='csv-download-button'>
                                            DOWNLOAD CSV TEMPLATE
                                        </button>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <h1 className="import-text">
                                        2.) Add users to the CSV template, then select the file. <br /><a href="https://prodcms.schoolwide.com/CMS/Adding_Teachers_and_Students.pdf" target="_blank"><img src={"https://cdn3.iconfinder.com/data/icons/bold-blue-glyphs-free-samples/32/Info_Circle_Symbol_Information_Letter-16.png"} /> Click here for help</a>
                                    </h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <label style={this.browseButtonStyle()} className="import-modal-browse-button">
                                        <input disabled={this.isBrowseDisabled()}
                                               type="file"
                                               accept=".csv"
                                               onChange={this.updateField.bind(this,"fileName")}
                                        />
                                        BROWSE
                                    </label>
                                    <input disabled={this.isBrowseDisabled()}
                                           className="custom-file-input"
                                           readOnly
                                           value={this.state.fileName? this.state.fileName : 'No File Chosen'}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <h1 className="import-text">
                                        3.) Upload the file to add multiple users...
                                    </h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <button disabled={this.isUploadDisabled()}
                                            onClick={this.uploadUsers}
                                            className='import-modal-upload-button'>
                                        UPLOAD
                                    </button>
                                </Col>
                            </Row>
                            {this.isUploadStatusShown() &&
                                <Row>
                                    <Col sm={12}>
                                        {(this.props.importUsers.asyncStatus === "SUCCESS") &&
                                            <h1 className="import-text" style={{color: '#008523'}}>
                                                Upload Successful
                                            </h1>
                                        }
                                        {(this.props.importUsers.asyncStatus === "FAILURE") &&
                                            <h1 className="import-text" style={{color: '#D0021B'}}>
                                                Got an error during validation. No users were imported
                                            </h1>
                                        }
                                    </Col>
                                </Row>
                            }
                            {(this.isUploadStatusShown() && this.props.importUsers.asyncStatus === "SUCCESS") &&
                            <div>
                                {this.isInsertedCountDisplayed() &&
                                <Row>
                                    <Col sm={12}>
                                        <p className="import-text">
                                            {'Records Inserted: ' + this.props.importUsers.data.message.count.inserted}
                                        </p>
                                    </Col>
                                </Row>
                                }
                                {this.isSkippedCountDisplayed() &&
                                <Row>
                                    <Col sm={12}>
                                        <p className="import-text">
                                            {'Records Skipped: ' + this.props.importUsers.data.message.count.skipped}
                                        </p>
                                    </Col>
                                </Row>
                                }
                                {this.isTotalCountDisplayed() &&
                                <Row>
                                    <Col sm={12}>
                                        <p className="import-text">
                                            {'Total Records: ' + this.props.importUsers.data.message.count.total}
                                        </p>
                                    </Col>
                                </Row>
                                }
                            </div>
                            }
                            {(this.isUploadStatusShown() &&
                                    this.props.importUsers.asyncStatus === "SUCCESS" &&
                                    this.props.importUsers.data.results) &&
                                <Row>
                                    <Col sm={12} style={{maxHeight: '10em', overflowY: 'auto', marginBottom: '1em'}}>
                                        <table style={{marginBottom: 10}}>
                                            {this.props.importUsers.data.results.map((user) => {
                                                if(user.success === false) {
                                                    return (
                                                        <tr className="import-text">
                                                            <td style={{paddingRight: '1em'}}>
                                                                {user.user_id_number}:
                                                            </td>
                                                            <td>
                                                                {user.message}
                                                            </td>
                                                        </tr>);
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </table>
                                    </Col>
                                </Row>
                            }
                            {(this.isUploadStatusShown() && this.props.importUsers.asyncStatus === "FAILURE") &&
                                <Row>
                                    <Col sm={12}>
                                        <div className="import-text">
                                            <p className="error-failure-messages">
                                                <ul>
                                                    <li>{this.props.importUsers.data.response.data.message.message} {this.props.importUsers.data.response.data.message.row.username ? ' - (' + this.props.importUsers.data.response.data.message.row.username + ')' : ''}</li>
                                                    <li>Error at row number: <b>{this.props.importUsers.data.response.data.message.lineNumber}</b></li>
                                                </ul>
                                                
                                                {this.props.importUsers.data.response.data.message.row &&
                                                    <div>
                                                        <table>
                                                            <tbody>
                                                                { Object.keys(this.props.importUsers.data.response.data.message.row).length && 
                                                                    Object.keys(this.props.importUsers.data.response.data.message.row).map((e, i) => {
                                                                        return (<tr>
                                                                            <th>{e}</th>
                                                                            <td>{this.props.importUsers.data.response.data.message.row[e]}</td>
                                                                        </tr>)
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </div>
                    {this.isSpinnerShown() &&
                        <div className='gif-wrapper-outer'>
                            <div className='gif-wrapper-inner'>
                                <img className='loading-image'
                                     src={loading}
                                     alt=""
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {importUsers: state.ImportUsers}
};

export default connect(mapStateToProps, actions)(ImportModal)