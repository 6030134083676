import React from 'react'
import ToolTip from 'react-portal-tooltip'

let style2 = {
    style: {
        height: "150px",
        width: "400px",
        border: "1px solid #F5F5F5",
        borderRadius: "9px",
        backgroundColor: "rgba(255,255,255.0.9)",
        boxShadow: "0 2px 12px 0 rgba(0,0,0,0.5)",
        padding: "20px 20px 20px 20px"
    },
    arrowStyle: {
        color: "white",
        borderColor: "#F5F5F5"
    }
}

let timeout = 3000;
class PopOverSuccess extends React.Component {
    state = {
        isTooltipActive: this.props.isTooltipActive
    }
    showTooltip() {
        this.setState({isTooltipActive: true})
    }
    hideTooltip() {
        this.setState({isTooltipActive: false})
    }


    render() {
        let parent="#" + this.props.count;
        return (
            <div>

                <ToolTip active={this.props.isTooltipActive} position="bottom" arrow={this.props.arrow} parent={parent} style={style2} tooltipTimeout={timeout}>
                    {this.props.children}
                </ToolTip>
            </div>
        )
    }
}

export default PopOverSuccess;