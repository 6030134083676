import React,{Component} from  'react';
import {Col,Row} from 'react-bootstrap';
import {Link} from 'react-router';

class DistrictItem extends Component {
    render(){
        let route= '/school-admin/'+ this.props.n._id;
        let districtName = this.props.n.name;
        let districtAcronym = districtName.split(/\s/)
            .reduce(function(accumulator, word) {
              return accumulator + word.charAt(0);
            }, '');
        return(
            <div>
                <Link to={route}>
                {/*<span style={{float: "left"}}><Avatar textSizeRatio={3} color="#9da2af" className="letter-avatar" name={this.props.n.name} size="40" style={{ width: "40px", height: "40px" }} round /></span>*/}
                <span style={{float: "left"}}>
                    <div class="letter-avatar" style={{display: "inline-block",width: "40px", height: "40px", "border-radius": "500px"}}>
                        <div style={{width: "40px", height: "40px",font: "13px / 40px Helvetica, Arial, sans-serif", "text-align" : "center", "text-transform": "uppercase", color: "rgb(255, 255, 255)", background: "rgb(157, 162, 175)", "border-radius": "100%"}}>
                            {districtAcronym}
                        </div>
                    </div>
                </span>
                <Col md={8} sm={8} >
                    <Row className="text-limitation">{this.props.n.name && <span>{this.props.n.name}</span>}</Row>
                    <Row className="item-sub-text">{this.props.n.districtType && <span className="non-breaking-span">DISTRICT TYPE : {this.props.n.districtType}
                        {(this.props.n.state || this.props.n.unlimited_count) && <span>&nbsp;&#8226;&nbsp;</span>} </span>}
                        {this.props.n.state && <span className="non-breaking-span">STATE: {this.props.n.state}
                            {this.props.n.unlimited_count && <span>&nbsp;&#8226;&nbsp;</span>} </span>}
                            <br />
                        {/*{this.props.n.license_count && <span className="non-breaking-span">LICENSE COUNT: {this.props.n.license_count}</span>}*/}
{/* This is the new line for when the district shows unlimited and print only */}
                        {/* {(this.props.n.unlimited_count || this.props.n.print_only_count) && <span className="non-breaking-span">LICENSE COUNT: Print: {(this.props.n.print_only_count - this.props.n.print_only_available) || 0}/{this.props.n.print_only_count || 0} Unlimited: {(this.props.n.unlimited_count - this.props.n.unlimited_available) || 0}/{this.props.n.unlimited_count || 0}</span>} */}
                        {<span className="non-breaking-span">LICENSE COUNT: Print: {(this.props.n.print_only_count - this.props.n.print_only_available) || 0}/{this.props.n.print_only_count || 0} Unlimited: {(this.props.n.unlimited_count - this.props.n.unlimited_available) || 0}/{this.props.n.unlimited_count || 0} Text Only: {(this.props.n.text_only_count - this.props.n.text_only_available) || 0}/{this.props.n.text_only_count || 0}</span>}
                    </Row>
                </Col>
                </Link>
            </div>
        );
    }
}

export default DistrictItem;