import {
    GET_FILTER_OPTIONS_REQUEST,
    GET_FILTER_OPTIONS_SUCCESS,
    GET_FILTER_OPTIONS_FAILURE
} from '../actions';


const initialState = {};

const filterOptionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FILTER_OPTIONS_REQUEST:
            return Object.assign({},state, {asyncStatus:  'PENDING'});
        case GET_FILTER_OPTIONS_SUCCESS:
            return Object.assign({},state, {asyncStatus:  'SUCCESS', data: action.payload});
        case GET_FILTER_OPTIONS_FAILURE:
            return Object.assign({},state, {asyncStatus:  'FAILURE', data: action.payload});
        default:
            return state;
    }
};

export default filterOptionsReducer;