export const bookFilterObjectNonStudent = () => [
    {
        header: "Genre",
        tagName: 'Genre',
        queryString: 'genres',
        filters: []
    },
    {
        header: "Theme",
        tagName: 'Theme',
        queryString: 'themes',
        filters: []
    },
    {
        header: "Topic",
        tagName: 'Topic',
        queryString: 'topics',
        filters: []
    },
    {
        header: "Guided Reading Level",
        tagName: 'Guided Reading Level',
        queryString: 'readingLevels',
        filters: []
    },
    {
        header: "Lexile Level",
        tagName: 'Lexile Level',
        queryString: 'lexileLevel',
        filters: []
    },
    {
        header: "Language",
        tagName: 'Language',
        queryString: 'languages',
        filters: []
    },
    {
        header: "Interest Level",
        tagName: 'Interest Level',
        queryString: 'grades',
        filters: []
    }
];

export const bookFilterObjectStudent = () => [
    {
        header: "Genre",
        tagName: 'Genre',
        queryString: 'genres',
        filters: []
    },
    {
        header: "Theme",
        tagName: 'Theme',
        queryString: 'themes',
        filters: []
    },
    {
        header: "Topic",
        tagName: 'Topic',
        queryString: 'topics',
        filters: []
    },
    {
        header: "Guided Reading Level",
        tagName: 'Guided Reading Level',
        queryString: 'readingLevels',
        filters: []
    },
    {
        header: "Language",
        tagName: 'Language',
        queryString: 'languages',
        filters: []
    },
    {
        header: "Interest Level",
        tagName: 'Interest Level',
        queryString: 'grades',
        filters: []
    }
];

export const gradesOnly = () => [
    {
        header: "Grade Level",
        tagName: 'Grade',
        queryString: 'grades',
        filters: []
    }
];

export const lessonFilterObjects = () => [
    {
        header: "Grade Level",
        tagName: 'Grade',
        queryString: 'grades',
        filters: []
    },
    {
        header: "Skills & Strategies",
        tagName: 'Skill',
        queryString: 'skills',
        filters: []
    },
    {
        header: "Units Of Study",
        tagName: 'Unit',
        queryString: 'unitsOfStudy',
        filters: []
    },
    {
        header: "Standards",
        tagName: 'Standard',
        queryString: 'standards',
        filters: []
    },
    {
        header: "Lesson Type",
        tagName: 'Lesson',
        queryString: 'lessonType',
        filters: []
    },
];

export const unitFilterObjects = () => [
    {
        header: "Grade Level",
        tagName: 'Grade',
        queryString: 'grades',
        filters: []
    },
    {
        header: "Genre/Text Type",
        tagName: 'Unit',
        queryString: 'unitsOfStudy',
        filters: []
    },
    {
        //header: "STANDARD TYPE",
        header: "Correlations",
        tagName: 'Correlation',
        queryString: 'strands',
        filters: []
    }
];

export const resourceFilterObject = () => [
    {
        header: "Grade Level",
        tagName: 'Grade',
        queryString: 'grades',
        filters: []
    },
    {
        header: "Standards",
        tagName: 'Standard',
        queryString: 'standards',
        filters: []
    },
    {
        header: "Skills & Strategies",
        tagName: 'Skill',
        queryString: 'skills',
        filters: []
    },
    {
        header: "Resource Type",
        tagName: 'Resource',
        queryString: 'resourceTypes',
        filters: []
    },
    {
        header: "Units Of Study",
        tagName: 'Unit',
        queryString: 'unitsOfStudy',
        filters: []
    }
];

export const assessmentsFilterObject = () => [
    {
        header: "Grade Level",
        tagName: 'Grade',
        queryString: 'grades',
        filters: []
    },
    {
        header: "Standards",
        tagName: 'Standard',
        queryString: 'standards',
        filters: []
    },
    {
        header: "Skills & Strategies",
        tagName: 'Skill',
        queryString: 'skills',
        filters: []
    },
    {
        header: "Assessments Types",
        tagName: 'Assessment',
        queryString: 'assessmentTypes',
        filters: []
    },
    {
        header: "Units Of Study",
        tagName: 'Unit',
        queryString: 'unitsOfStudy',
        filters: []
    }
];