import React,{Component} from 'react';
import authAPI from '../../api/authAPI';



class CreateFolderDialog extends Component{
    render(){

        let dialogStyle = 'dialog-modal';

        if (this.props.dialogStyle) {
            dialogStyle += " " + this.props.dialogStyle;
        }

        return(
            <div>
                <div className='background-disable'/>
                    <div className={dialogStyle}>
                        <div className='dialog-header'>
                            <div className='lesson-to-unit-heading'>
                                {this.props.title}
                            </div>
                        </div>
                        {this.props.children}
                        <div className='dialog-footer col-md-12'>
                            <div className='button-regular-left'>
                                <button onClick={this.props.onCancel} className='dialog-cancel-button'>
                                    CANCEL
                                </button>
                            </div>
                            <div className='button-regular-right'>
                                <button onClick={this.props.onOk} className={this.props.okStyle}>
                                    {this.props.okText}
                                </button>
                            </div>
                        </div>
                </div>
            </div>
        );
    }
}

export default CreateFolderDialog