import {
    GET_MY_LIBRARY_RECENTS_REQUEST,
    GET_MY_LIBRARY_RECENTS_SUCCESS,
    GET_MY_LIBRARY_RECENTS_FAILURE
} from '../actions';


const initialState = {};

const MyLibraryRecentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MY_LIBRARY_RECENTS_REQUEST:
            return Object.assign({},state, {asyncStatus: 'PENDING'});
        case GET_MY_LIBRARY_RECENTS_SUCCESS:
            return Object.assign({},state, {asyncStatus: 'SUCCESS', data: {...state.data, items: action.payload}});
        case GET_MY_LIBRARY_RECENTS_FAILURE:
            return Object.assign({},state, {asyncStatus: 'FAILURE', data: action.payload});
        default:
            return state;
    }
};

export default MyLibraryRecentsReducer;