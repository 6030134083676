import React,{Component} from  'react';
import AssessmentsDetailedView from './AssessmentsDetailedView';

class AssessmentComponent extends Component{

    render(){

        return(
            <div>
                <AssessmentsDetailedView/>
            </div>
        );
    }
}



export default AssessmentComponent;
