import React from 'react'
import * as actions from '../redux/actions'
import {connect} from 'react-redux'
import '../assets/styles/react-datetime.css'
import {Row, Col} from 'react-bootstrap'
import '../assets/styles/myToolbar.scss'
import DropdownLabelDatePicker from "./Shared/DropdownLabelDatePicker";

class MyToolbar extends React.Component {

    constructor () {
        super();
        this.state = { selectedDate: new Date(), showDatePicker: false, selectedDateListView: new Date()};
        this.toggleDatePicker = this.toggleDatePicker.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.nav = this.nav.bind(this);
    }
    handleDate(date){
        this.setState({selectedDate: date._d,selectedDateListView: date._d,showDatePicker: false},()=>{this.onViewChange(this.props.view)});

    }

    toggleDatePicker(){
       this.setState({showDatePicker: !this.state.showDatePicker})
    }

    onViewChange = (view) => {
        if(view === "agenda") {
            this.props.onNavigate("",this.state.selectedDate);
            this.props.getScheduledTasksAction("list",new Date(this.state.selectedDate));
        }

        if(view === "work_week") {
            this.props.onNavigate("",this.state.selectedDate);
            this.props.getScheduledTasksAction("grid",new Date(this.state.selectedDate));
        }
        this.props.onViewChange(view);
    };

    nav = (action)=> {
        let newDate = this.state.selectedDate.getTime();
        if(this.props.view === "work_week"){
            let nextWeek= 7;
            let prevWeek= 7;
           let curDay = this.state.selectedDate.getDay();

           switch(curDay){
               case 0:
                 nextWeek = 1;
                 prevWeek=6;
                 break;
               case 1:
                   nextWeek = 7;
                   prevWeek = 7;
                   break;
               case 2:
                   nextWeek =6;
                   prevWeek = 8;
                   break;
               case 3:
                   nextWeek = 5;
                   prevWeek = 9;
                   break;
               case 4:
                   nextWeek = 4;
                   prevWeek = 10;
                   break;
               case 5:
                   nextWeek = 3;
                   prevWeek = 11;
                   break;
               case 6:
                   nextWeek = 2;
                   prevWeek =5;
                   break;
           }

            if (action === 'PREV') {
                newDate = newDate - prevWeek* 1000 *24 * 3600;

            } else if (action === 'NEXT') {
                newDate = newDate + nextWeek * 1000 * 24 * 3600;
            } else if(action === 'TODAY'){
                newDate = new Date().getTime();
            }
            this.setState({selectedDate: new Date(newDate)});
            this.props.getScheduledTasksAction("grid",new Date(newDate));
            this.props.onNavigate(action,this.state.selectedDate);
        }
        if(this.props.view === "agenda"){

            let curDay = this.state.selectedDate.getDay();
            let nextDay = 1;
            let prevDay = 1;

            if (curDay === 5)
                nextDay = 3;
            if (curDay === 1)
                prevDay = 3;

            if (action === 'PREV') {
                newDate = newDate - prevDay* 1000 *24 * 3600;

            } else if (action === 'NEXT') {
                newDate = newDate + nextDay* 1000 * 24 * 3600;
            } else if(action === 'TODAY'){
                newDate = new Date().getTime();
            }
            this.setState({selectedDate: new Date(newDate)});
            this.props.getScheduledTasksAction("list",new Date(newDate));
            this.props.onNavigate(action,this.state.selectedDate);
        }
    };

    render () {
        const date = this.state.selectedDate;//typeof this.props.date !== "string" ? this.props.date : new Date(this.props.date);

        const labelDatePickerProps = {
            toggleDatePicker: this.toggleDatePicker,
            showDatePicker: this.state.showDatePicker,
            handleDate: this.handleDate
        };

        return (
            <Row style={{paddingBottom: '1em', paddingTop: '1em'}}>

                {/* Non XS Date picker dropdown */}
                <Col sm={4} style={{padding:0}} xsHidden>
                   <DropdownLabelDatePicker {... labelDatePickerProps}
                                            date={date}
                                            displayShortMonth={false}/>
                </Col>

                {/* XS Date picker dropdown */}
                <Col xs={6} className="format-month-year" style={{padding:0}} smHidden mdHidden lgHidden>
                    <DropdownLabelDatePicker {... labelDatePickerProps}
                                             displayShortMonth={true}
                                             date={date}
                    />
                </Col>

                {/* Toggle cal and agenda view. Not for xs */}
                <Col sm={4} xsHidden>
                    <div className='format-grid-list'>
                        {this.props.views.map(v => (
                            <button className={v === this.props.view? 'active-tab': ''} onClick={this.onViewChange.bind(null, v)} >
                                {this.props.messages[v]}
                            </button>
                        ))}
                    </div>
                </Col>

                {/* PREV TODAY NEXT buttons*/}
                <Col xs={6} sm={4}>
                    <div className="format-navigate-buttons">
                        <button onClick={this.nav.bind(this, 'PREV')}>
                            <i className="navigate-demo-icon icon-icon-chevron-left"/>
                        </button>
                        <button onClick={this.nav.bind(this, 'TODAY')}>
                            {this.props.messages["today"]}
                        </button>
                        <button onClick={this.nav.bind(this, 'NEXT')}>
                            <i className="navigate-demo-icon icon-icon-chevron-right"/>
                        </button>
                    </div>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        scheduledTasks : state.ScheduledTasks

    }
};

export default connect(mapStateToProps, actions)(MyToolbar);