import React, {Component} from  'react'
import {MenuItem} from 'react-bootstrap'

export default class BrowseDropdownContents extends Component {

    render() {
        return (
            <div>
                {localStorage.user_type !== "Student" &&
                    <MenuItem href="/browse/teaching-resources/featured">
                        <div className="outer">
                            <div className="inner">
                                <div className="table-cell-nav">
                                    <div className="icons">
                                        <i className="ruler-pencil-demo-icon icon-icon-rulerpencil"/>
                                    </div>
                                    <div className="middle-comp">
                                        <h2>Teaching Resources</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MenuItem>
                }

                {localStorage.is_lite === 'false' ?
                    <MenuItem href="/browse/books">
                        <div className="outer">
                            <div className="inner">
                                <div className="table-cell-nav">
                                    <div className="icons">
                                        <i className="books-solid-demo-icon icon-icon-books-solid"/>
                                    </div>
                                    <div className="middle-comp">
                                        <h2>Texts</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MenuItem>
                    :
                    <div className="outer" style={{backgroundColor: 'lightgray'}}>
                        <div className="inner">
                            <div className="table-cell-nav">
                                <div className="icons">
                                    <i className="books-solid-demo-icon icon-icon-books-solid" style={{color: 'gray'}}/>
                                </div>
                                <div className="middle-comp">
                                    <h2 style={{color: 'gray'}}>Texts</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {localStorage.is_lite === 'false' ?
                    localStorage.user_type !== "Student" &&
                    <MenuItem href="/browse/assessments/featured">
                        <div className="outer">
                            <div className="inner">
                                <div className="table-cell-nav">
                                    <div className="icons">
                                        <i className="piechart-demo-icon icon-icon-piechart"/>
                                    </div>
                                    <div className="middle-comp">
                                        <h2>Assessments</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MenuItem>
                    :
                    <div className="outer" style={{backgroundColor: 'lightgray'}}>
                        <div className="inner">
                            <div className="table-cell-nav">
                                <div className="icons">
                                    <i className="piechart-demo-icon icon-icon-piechart" style={{color: 'gray'}}/>
                                </div>
                                <div className="middle-comp">
                                    <h2 style={{color: 'gray'}}>Assessments</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {localStorage.is_lite === 'false' ?
                    localStorage.user_type !== "Student" &&
                    <MenuItem href="/browse/units/featured">
                        <div className="outer">
                            <div className="inner">
                                <div className="table-cell-nav">
                                    <div className="icons">
                                        <i className="grid-demo-icon icon-icon-grid"/>
                                    </div>
                                    <div className="middle-comp">
                                        <h2>Units</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MenuItem>
                    :
                    <div className="outer" style={{backgroundColor: 'lightgray'}}>
                        <div className="inner">
                            <div className="table-cell-nav">
                                <div className="icons">
                                    <i className="grid-demo-icon icon-icon-grid" style={{color: 'gray'}}/>
                                </div>
                                <div className="middle-comp">
                                    <h2 style={{color: 'gray'}}>Units</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                }
        </div>);
    }
}