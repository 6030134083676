import React,{Component} from  'react';
import {Row, Col, Modal, Alert} from 'react-bootstrap'
import { withRouter } from 'react-router';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions'
import CollapsibleComponent from '../../containers/Details/CollapsibleComponent';
import {markdownHelper} from '../../markdown/MarkdownHelper';
import {annotationsHelper} from '../../annotations/AnnotationsHelper';
import AnnotationNoteComponent from '../AnnotationNoteComponent';
import AnnotationMenuComponent from '../AnnotationMenuComponent';
import constants from '../../constants/index'
import "../../assets/styles/annotate.scss";

class LessonDataComponent extends Component{

    constructor (props) {
        super(props);
        this.mdBoxes = {};
        this.addNoteDialogs = {};
        this.notes = {};
        this.contextMenus = {};
        this.selectedText = "";
        this.selectedAnchorNode = null;
        this.selectedRootNode = null;
        this.selectedAnchorOffset = null
        this.state = {
            addAnnotationState: "",
            editAnnotationState: "",
            deleteAnnotationState: "",
            //modal:false,
            //videoId:''
        };
        this.openModal = this.openModal.bind(this);
    }

    componentWillReceiveProps(next) {
        if (next.addAnnotation && next.addAnnotation.data && next.addAnnotation.data.data._id && next.addAnnotation.annotationType && next.addAnnotation.itemType  && next.addAnnotation.asyncStatus !==  this.state.addAnnotationState) {
            if (next.addAnnotation.asyncStatus !== "PENDING") {
                let configObj = next.addAnnotation.data.config;
                let savedAnnotationObj = JSON.parse(configObj.data);

                let annotation = {};
                annotation._id = next.addAnnotation.data.data._id;
                annotation.data = savedAnnotationObj.data;
                annotation.item_type = next.addAnnotation.itemType;
                annotation.annotation_type = next.addAnnotation.annotationType;

                if (this.mdBoxes[annotation.data.section] !== undefined) {
                    if (next.addAnnotation.asyncStatus === "SUCCESS") {
                        this.props.annotations.annotations.push(annotation);
                        this.setState({addAnnotationState: "SUCCESS"});
                        //console.log("Added Annotation");

                    } else if (next.addAnnotation.asyncStatus === "FAILURE") {
                        this.setState({addAnnotationState: "FAILURE"});
                    }
                }
            }
            else {
                this.state.addAnnotationState = "PENDING";
            }
        }
        else if (next.deleteAnnotation && next.deleteAnnotation.data && next.deleteAnnotation.data.data._id && next.deleteAnnotation.asyncStatus !==  this.state.deleteAnnotationState) {
            if (next.deleteAnnotation.asyncStatus !== "PENDING") {
                if (next.deleteAnnotation.asyncStatus === "SUCCESS") {
                    for (let i = 0; i < this.props.annotations.annotations.length; i++) {
                        if (this.props.annotations.annotations[i]._id === next.deleteAnnotation.data.data._id) {
                            this.props.annotations.annotations.splice(i, 1);
                            break;
                        }
                    }

                    this.setState({deleteAnnotationState: "SUCCESS"});

                } else if (next.deleteAnnotation.asyncStatus === "FAILURE") {
                    this.setState({editAnnotationState: "FAILURE"});
                }
            }
            else {
                this.state.deleteAnnotationState = "PENDING";
            }
        }
        else if (next.editAnnotation && next.editAnnotation.data && next.editAnnotation.data.data && next.editAnnotation.data.data._id && next.editAnnotation.asyncStatus !==  this.state.editAnnotationState) {
            if (next.editAnnotation.asyncStatus !== "PENDING") {
                if (next.editAnnotation.asyncStatus === "SUCCESS") {
                    for (let i = 0; i < this.props.annotations.annotations.length; i++) {
                        if (this.props.annotations.annotations[i]._id === next.editAnnotation.data.data._id) {
                            let configObj = next.editAnnotation.data.config;
                            let savedAnnotationObj = JSON.parse(configObj.data);
                            this.props.annotations.annotations[i].data.note_text = savedAnnotationObj.data.note_text;

                            break;
                        }
                    }

                    this.setState({editAnnotationState: "SUCCESS"});
                }
                else if (next.editAnnotation.asyncStatus === "FAILURE") {
                    this.setState({editAnnotationState: "FAILURE"});
                }
            }
            else {
                this.state.editAnnotationState = "PENDING";
            }
        }
    }

    addHighlight = (e, data) => {
        //console.log("Highlight");
        //console.log(data);
        //console.log(this.selectedText);

        let text = annotationsHelper.cleanString(this.selectedText);

        if(text !== "") {
            let annotationData = {
                target_text: text,
                repeat: annotationsHelper.findOcurrance(text, this.selectedRootNode, this.selectedAnchorNode, this.selectedAnchorOffset),
                color: data.color,
                section: data.type,
                page: 0
            };
            //console.log(this.mdBoxes[data.type].textContent);
            this.props.addAnnotationAction("Lesson", "Highlight", this.props.content._id, annotationData);
        }
    };

    openModal(videoUrl){
        this.props.modalController(videoUrl);
    }

    addNoteBox = (e, data) => {
        //console.log("Note");
        //console.log(data);
        //console.log(this.selectedText);

        if(this.addNoteDialogs[data.type] !== undefined){
            if(this.selectedText !== "") {
                this.notes[data.type] = {
                    selectedText: this.selectedText,
                    selectedRootNode: this.selectedRootNode,
                    selectedAnchorNode: this.selectedAnchorNode,
                    selectedAnchorOffset: this.selectedAnchorOffset
                };

                if(this.addNoteDialogs[data.type].getMode() !== "Add") {
                    this.addNoteDialogs[data.type].setModeAdd()
                }
                this.addNoteDialogs[data.type].clearText();
                this.addNoteDialogs[data.type].setItemId(null);
                this.addNoteDialogs[data.type].open();
            }
        }
    };

    deleteHighlight = (e, data, id) => {
        //alert("Delete Highlight " + id);
        this.props.deleteAnnotationAction(id);
    };

    handleShow = (e, target) => {
        //console.log(window.getSelection());
        //console.log(window.getSelection().toString());
        this.selectedText = window.getSelection().toString();
        this.selectedRootNode = annotationsHelper.getRoot(window.getSelection().anchorNode, "lesson-text-padding");
        this.selectedAnchorNode = window.getSelection().anchorNode;
        this.selectedAnchorOffset = window.getSelection().anchorOffset;
        console.log(window.getSelection().toString());
    };

    handleHide = (e, target) => {
        if (target.getMode() === "Delete") {
            target.setItemId(null);
            target.setModeAddHighlight();
        }
    };

    addNote = (note, data) => {
        let noteData = this.notes[data.type];
        let text = annotationsHelper.cleanString(noteData.selectedText);

        let annotationData = {
            target_text: text,
            repeat: annotationsHelper.findOcurrance(text, noteData.selectedRootNode, noteData.selectedAnchorNode, noteData.selectedAnchorOffset),
            note_text: note.getText(),
            section: data.type,
            page: 0
        };
        //console.log(this.mdBoxes[data.type].textContent);
        this.props.addAnnotationAction("Lesson", "Note", this.props.content._id, annotationData);

        this.notes[data.type] = undefined;

        note.hide();
    };

    editNote = (e, target, id) => {
        //alert("edit Note "  + id);

        let data = null;

        for (let i = 0; i < this.props.annotations.annotations.length; i++) {
            if (this.props.annotations.annotations[i]._id === id) {
                data = this.props.annotations.annotations[i].data;
                break;
            }
        }

        if (data !== null) {
            let newData = {
                target_text: data.target_text,
                repeat: data.repeat,
                note_text: e.getText(),
                section: data.section,
                page: data.page
            };

            this.props.editAnnotationAction(id, newData);
        }
        e.hide();
    };

    deleteNote = (e, target, id) => {
        //alert("delete note "  + id);
        this.props.deleteAnnotationAction(id);
        e.hide();
    };

    cancelNote = (note, data) => {
        note.hide();
    };

    annotationClicked  = (e) => {
        if (e.target.classList.contains("editable-annotation")) {
            let annotations = this.props.annotations.annotations;
            let annotation = null;
            let id = parseInt(e.target.getAttribute("data-id"));

            for (let i = 0; i<annotations.length; i++) {
                if (annotations[i]._id === id) {
                    annotation = annotations[i];
                    break;
                }
            }

            if (annotation !== null) {
                if (annotation.annotation_type === "Note") {
                    if(this.addNoteDialogs[annotation.data.section].getMode() !== "Edit") {
                        this.addNoteDialogs[annotation.data.section].setModeEdit();
                    }
                    this.addNoteDialogs[annotation.data.section].setText(annotation.data.note_text);
                    this.addNoteDialogs[annotation.data.section].setItemId(id);
                    this.addNoteDialogs[annotation.data.section].open();
                }
                else if (annotation.annotation_type === "Highlight") {
                    this.contextMenus[annotation.data.section].setModeDeleteHighlight();
                    this.contextMenus[annotation.data.section].setItemId(id);
                    this.contextMenus[annotation.data.section].handleContextClick(e);
                }
            }
        }
    }

    render(){
        let contentBox=[];
        let finalData= this.props.content && this.props.content.lesson;
        let lessonType = this.props.content.lessonType;
        let annotations = this.props.annotations && this.props.annotations.annotations;
        let slideUrl = this.props.content.lesson.isSilde != undefined ? this.props.content.lesson.isSilde.text : '';
        let vBookUrl = this.props.content.lesson.isVBook != undefined ? this.props.content.lesson.isVBook.text : '';
        // console.log("this.props.content.lessonType = ", this.props.content.lessonType);
        if(this.props.content && (this.props.content.lessonType==='Mini-Lesson' || this.props.content.lessonType==='vBook: Mini-Lesson' || this.props.content.lessonType==='Slide Presentation: Mini-Lesson')){
            contentBox=[
                {
                    name:'INFORM',
                    content:'inform'
                },
                {
                    name:'PRESENT',
                    content:'present'
                },
                {
                    name:'ENGAGE',
                    content:'engage'
                },
                {
                    name:'REITERATE',
                    content:'reiterate'
                }

            ]
        }
        else if(this.props.content && (this.props.content.lessonType==='Shared Writing Lesson' || this.props.content.lessonType==='Interactive Writing' || this.props.content.lessonType==='Introducing Centers'))
        {
            contentBox=[
                {
                    name:'INFORM AND BRAINSTORM',
                    content:'brainstormSubsection'
                },
                {
                    name:'COMPOSE AND CREATE',
                    content:'composeSubsection'
                },
                {
                    name:'REREAD THE TEXT',
                    content:'rereadSubsection'
                }
            ]
        }
        else{

            contentBox=[
                {
                    name:'BEFORE THE READ',
                    content:'beforeTheRead'
                },
                {
                    name:'DURING THE READ',
                    content:'duringTheRead'
                },
                {
                    name:'AFTER THE READ',
                    content:'afterTheRead'
                }
            ]
        }
        let imageNotFound = window.location.protocol + "//" + window.location.hostname+'/image-not-found.png';
        return(
            <div className="list-of-items">
                {!(this.props.content && this.props.content.lesson) &&
                <div>
                    No data found
                </div>
                }
                {
                    contentBox.map((main,i)=>(
                        <CollapsibleComponent collapsed={false} key={i} idText={'lesson-row'} header={main.name} headerStyle={{fontSize: "17px",letterSpacing:1.5,height:20.5, fontWeight:'bold', color: "#4A4A4A", borderBottom: "1px solid rgba(151, 151, 151,0.5)", paddingBottom: "35px", paddingTop: "15px",fontFamily:"ProximaNova" }} openToggleButton={<img src={require('../../assets/icons/SVG/my-solid-arrow-right.png')}  className="arrow-icon  rss opacity"/>} closeToggleButton={<img src={require('../../assets/icons/SVG/my-solid-arrow.png')}  className="arrow-icon  rss opacity"/>}>
                            {finalData[main.content] && finalData[main.content].text ?
                                <div className="annotation-note-container">
                                    {(lessonType == 'Slide Presentation: Mini-Lesson' || lessonType == 'Slide Presentation: Interactive Read-Aloud') && (main.content == 'beforeTheRead'|| main.content == 'inform') &&
                                    <a href={slideUrl} target="_blank" style={{'pointerEvents':(slideUrl !='' ? 'all':'none')}}>
                                        <img 
                                          src={this.props.content.slidepresent_thumb ? constants.filesUrl + this.props.content.slidepresent_thumb.replace("../",'') : constants.filesUrl}
                                          style={{width:"10rem",cursor:"pointer"}}
                                          />
                                    </a>
                                    }
                                    {(lessonType == 'vBook: Mini-Lesson' || lessonType == 'vBook: Interactive Read-Aloud') && (main.content == 'beforeTheRead'|| main.content == 'inform') && this.props.content.lesson.isSilde &&
                                        <a href={this.props.content.lesson.isSilde.text} target="_blank" style={{"margin":"15px"}}>
                                        <img 
                                          src={this.props.content.slidepresent_thumb ? constants.filesUrl + this.props.content.slidepresent_thumb.replace("../",'') : imageNotFound}
                                          style={{width:"10rem",height:"10rem",cursor:"pointer"}}
                                          />
                                        </a>
                                    }
                                    {(lessonType == 'vBook: Mini-Lesson' || lessonType == 'vBook: Interactive Read-Aloud') && (main.content == 'beforeTheRead'|| main.content == 'inform') &&
                                    <img
                                        onClick={()=>this.openModal(vBookUrl)}
                                        src={this.props.content.vbook_thumb ? constants.filesUrl + this.props.content.vbook_thumb.replace("../",'') : imageNotFound}
                                        style={{width:"10rem",cursor:"pointer"}}
                                    />
                                    }
                                    <AnnotationNoteComponent data={{type: main.name.toLowerCase().replace(/( )/gm, "_")}} ref={(el) => { this.addNoteDialogs[main.name.toLowerCase().replace(/( )/gm, "_")] = el }} onCancel={this.cancelNote} onAdd={this.addNote} onEdit={this.editNote} onDelete={this.deleteNote}/>
                                    <AnnotationMenuComponent id={main.name.toLowerCase().replace(/( )/gm, "_")} ref={(el) => { this.contextMenus[main.name.toLowerCase().replace(/( )/gm, "_")] = el }}  onShow={this.handleShow} onHide={this.handleHide} onAddNote={this.addNoteBox} onAddHighlight={this.addHighlight} onDeleteHighlight={this.deleteHighlight} highlightData={{type: main.name.toLowerCase().replace(/( )/gm, "_"), color: "yellow"}} noteData={{type: main.name.toLowerCase().replace(/( )/gm, "_")}} >
                                        <div onClick={this.annotationClicked} ref={(el) => { this.mdBoxes[main.name.toLowerCase().replace(/( )/gm, "_")] = el }} className='lesson-text-padding' dangerouslySetInnerHTML={{__html: annotationsHelper.annotate(markdownHelper.toHTML(finalData[main.content].text), main.name.toLowerCase().replace(/( )/gm, "_"), annotations)}}/>
                                    </AnnotationMenuComponent>
                                </div>:
                                <div className='lesson-text-padding'>'No Data Found'</div>}

                        </CollapsibleComponent>

                    ))}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        addAnnotation: state.AddAnnotation,
        editAnnotation: state.EditAnnotation,
        deleteAnnotation: state.DeleteAnnotation
    });
};

export default connect(mapStateToProps, actions)(withRouter(LessonDataComponent));