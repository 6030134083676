import React,{Component} from 'react'
import constants from "../../constants";
import DetailsListViewItem from "../../containers/Details/DetailsListViewItem";

class Assessments extends Component {
    render() {
        let assessments = this.props.content.assessments;
        // if(assessments && assessments.length > 0){
        //     assessments.sort((a,b)=>a.name.replace(/\s/g, '').localeCompare(b.name.replace(/\s/g, ''),'en',{numeric:true}));
        // }
        
        return(
            <div className={'list-of-items unit-assessments'}>
                {assessments? "" :
                    <div className='lesson-text-padding featured-reading' style={{textAlign: "center"}}> {'There are no assessments in this unit.'}</div>
                }
                {assessments && assessments[0] && assessments.map((assessmentsArray,i)=>(
                    <DetailsListViewItem
                        detailURL={"/assessments/" + assessmentsArray._id}
                        imageSrc={constants.filesUrl + (assessmentsArray.image?.replace("../",'') || '')}
                        contentHeading={assessmentsArray.name ? assessmentsArray.name : null}
                        rowId={i}
                        resourceType="units"
                    >
                        <a href={"/assessments/" + assessmentsArray._id} className="detail-link">
                            {assessmentsArray.description &&
                                (assessmentsArray.description.description ? assessmentsArray.description.description : null)
                            }
                         </a>
                    </DetailsListViewItem>
                ))}
            </div>
        );
    }
}

export default Assessments;