import React, {Component} from 'react'
import Student from './Student'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import {Row,Col} from 'react-bootstrap'
import ToolTip from 'react-portal-tooltip'
import AddPopOverMobile from '../../containers/shared/AddPopOverMobile'
import {browserHistory} from 'react-router'
import Dialog from '../AdminPortal/Dialog'
let style1 = {
    style: {
        height: "200px",
        width: "250px",
        border: "1px solid #F5F5F5",
        borderRadius: "8px",
        backgroundColor: "rgba(255,255,255)",
        boxShadow: "0 2px 12px 0 rgba(0,0,0,0.5)",
        padding: "0px"
    },
    arrowStyle: {
        color: "white",
        borderColor: "#F5F5F5"
    }
};

class ClassRoom extends Component {
    constructor(props){
        super(props);
        this.state = {
            showList: true,
            showPopOver: false,
            showPopOverMobile: false,
            deletePopUp:false
        };
        this.toggleList = this.toggleList.bind(this);
        this.importGroupClicked = this.importGroupClicked.bind(this);
        this.onStudentClicked = this.onStudentClicked.bind(this);
    }

    toggleList(){
        this.setState({showList :!this.state.showList})
    }

    importGroupClicked() {
        this.props.importGroupClicked(this.props.groupDetails._id);
        this.props.refresh();
    }

    onStudentClicked(clickedId) {
        browserHistory.push('/student/' + clickedId);
    }

    cancelPopUp=()=>{
        this.setState({deletePopUp:false})
    }

    deleteConfirm=()=>{
        this.setState({deletePopUp:false})
        this.props.deleteGroupAction(this.props.groupDetails._id)
        .then((response)=>{
        if(response.statusCode === 200){
            this.props.refresh();
        }
        })
    }
    render() {
        let parent="parent" + this.props.groupDetails._id;
        let parentId = "#"+ parent;
        let width = window.innerWidth;
        const tooltipChildren = (
          <Col>
              <Row className="myclass-tooltip-menu" onClick={()=>{browserHistory.push('/my-class/edit-group');this.props.setShowEditGroup(this.props.groupDetails._id)}}>
                  <Col md={2} xs={2}>
                      <i className="group-icon-add-group icon-icon-group"/>
                  </Col>
                  <Col md={10} xs={10}>
                      <Row className="popover-text"> Edit Group</Row>
                      <Row className="popover-sub-text">  Add or remove students</Row>
                  </Col>
              </Row>
              <Row className="myclass-tooltip-menu"
                   onClick={()=>{this.setState({deletePopUp:true,showPopOver:false,showPopOverMobile:false})}}>
                  <Col md={2} xs={2}>
                      <i className="group-icon-add-group icon-icon-trash"/>
                  </Col>
                  <Col md={10} xs={10}>
                      <Row className="popover-text"> Delete Group </Row>
                      <Row className="popover-sub-text">  Delete group</Row>
                  </Col>
              </Row>
              <Row className="myclass-tooltip-menu">
                  <Col md={2} xs={2}>
                      <i className="group-icon-add-group icon-group---white-copy"/>
                  </Col>
                  <Col md={10} xs={10}>
                      <Row className="popover-text" onClick={this.importGroupClicked}>Import Students</Row>
                      <Row className="popover-sub-text">  Bulk-import student IDs from your District</Row>
                  </Col>
              </Row>
          </Col>
        )

        return (
            <div>
                {this.state.deletePopUp &&
                    <Dialog onCancel={this.cancelPopUp}  onOk={this.deleteConfirm} title="DELETE GROUP" okText="DELETE" okStyle="dialog-delete-button">
                        <div className="dialog-spacer">
                            Are you sure you want to delete the group?
                        </div>
                    </Dialog>
                }

                <div>
                   <Row className="my-class-header">
                       <Col md={4} xs={2}>
                           {/*<div className="format-radio-button"> <input style={{height: 16,width:16}} type="radio" name="classroom"/></div>*/}
                           <div className="my-class-arrow-down-div can-hover" onClick={this.toggleList}>
                               <i className={this.state.showList?"my-class-arrow-down icon-arrow-solid-down can-hover": "my-class-arrow-right icon-arrow-solid-right can-hover"}/>
                           </div>
                       </Col>
                       <Col md={4} xs={8} style={{textAlign: 'center'}}>
                           {this.props.groupDetails.name &&
                                <div  className="classroom-text">
                                    {this.props.groupDetails.name}
                                    <span>&nbsp;</span>
                                    {this.props.groupDetails.users && this.props.groupDetails.users.length ?
                                      `(${this.props.groupDetails.users.length})` : '(0)'}
                                </div>
                           }
                       </Col>
                       <Col md={4} xs={2}>
                           <div style={{display: "inline-block",float: "right"}}>
                               <i id={parent} onClick={()=>{this.setState({showPopOver: true, showPopOverMobile: true},()=>{setTimeout(()=>{this.setState({showPopOver:false})},3000)})}} className="my-class-more-icon icon-icon-more-silo can-hover"/>
                                 {this.state.showPopOver && width > 576 &&
                                   <ToolTip active={this.state.showPopOver} position="bottom" arrow={null} parent={parentId} style={style1}>
                                      {tooltipChildren}
                                   </ToolTip>
                                 }
                                 {this.state.showPopOverMobile && width <= 576 &&
                                   <AddPopOverMobile
                                      isTooltipActive={this.state.showPopOverMobile}
                                      hideTooltip={()=>this.setState({showPopOverMobile: false})}
                                      type="menu"
                                      position="bottom"
                                      arrow={null}
                                      myId={parent}
                                      style={style1}
                                      >
                                    {tooltipChildren}
                                 </AddPopOverMobile>
                               }
                           </div>
                       </Col>
                    </Row>
                    <Row>
                    {this.state.showList && this.props.groupDetails.users && this.props.groupDetails.users.map((user)=>
                        <Col key={user._id} className="my-class-list-item" md={6} xs={12} onClick={() => {this.onStudentClicked(user._id)}}>
                            <Student user={user}/>
                            <div className="my-class-item-border"/>
                        </Col>
                    )}
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return ( {

    });
};

export default connect(mapStateToProps, actions)(ClassRoom)
