import React, { Component } from "react";
import "../assets/styles/index.scss";
import * as actions from "../redux/actions";
import { connect } from "react-redux";
import { Link } from "react-router";
import GlobalSearch from "../components/GlobalSearchComponent";
import FolderCreation from "../components/FolderCreationComponent";
import MyMenu from "../containers/MyMenu";
import AssistantTooltipComponent from "./JoyrideCustomComponent/AssistantTooltipComponent";

import assessment_blue_icon from "../assets/icons/SVG/assessment_blue_icon.svg";
import assessment_white_icon from "../assets/icons/SVG/assessment_white_icon.svg";
import library_white_icon from "../assets/icons/SVG/library_white_icon.svg";
import library_blue_icon from "../assets/icons/SVG/library_blue_icon.svg";
import home_blue_icon from "../assets/icons/SVG/home_blue_icon.svg";
import home_white_icon from "../assets/icons/SVG/home_white_icon.svg";
import units_blue_icon from "../assets/icons/SVG/units_blue_icon.svg";
import units_white_icon from "../assets/icons/SVG/units_white_icon.svg";
import lessons_blue_icon from "../assets/icons/SVG/lessons_blue_icon.svg";
import lessons_white_icon from "../assets/icons/SVG/lessons_white_icon.svg";
import lessons_gray_icon from "../assets/icons/SVG/lessons_gray.svg";
import texts_blue_icon from "../assets/icons/SVG/texts_blue_icon.svg";
import texts_white_icon from "../assets/icons/SVG/texts_white_icon.svg";
import resources_blue_icon from "../assets/icons/SVG/resources_blue_icon.svg";
import resources_white_icon from "../assets/icons/SVG/resources_white_icon.svg";
import folders_white_icon from "../assets/icons/SVG/folder_white_icon.svg";
import folders_blue_icon from "../assets/icons/SVG/folder_blue_icon.svg";

import _ from "lodash";
import PrintOnlyModal from "./Shared/PrintOnlyModal";

const styleObject = {
  mainColor: "#CACACA",
  width: 600,
  height: "100px",
  overflow: "wrap",
  borderRadius: 4,
  backgroundColor: "white",
  beacon: {
    inner: "#a350f0",
    outer: "#a350f0",
  },
  close: {
    display: "none",
  },
  button: {
    display: "none",
  },
  skip: {
    position: "fixed",
    bottom: 0,
    left: 0,
    margin: 20,
    fontWeight: 300,
  },
};

const stepsBrowseComponent = [
  {
    title: "Browse Overview",
    text: (
      <AssistantTooltipComponent
        imgWidth={100}
        imgHeight={130}
        tooltipText={`
                              <p>
                                These are the 4 primary categories of resources available on Fundamentals Unlimited: Teaching Resources, Books, Assessments, and Units.
                              </p>
                              <p>
                                Let's click <strong>Teaching Resources</strong> and I will demonstrate Fundamentals Unlimited's robust searching and filtering capabilities.
                              </p>
                              `}
      />
    ),
    selector: "#browse-btn-row",
    position: "bottom-left",
    style: {
      ...styleObject,
      arrow: {
        left: 100,
      },
    },
  },
];

const stepsUnits = [
  {
    title: "Units Overview",
    text: (
      <AssistantTooltipComponent
        imgWidth={100}
        imgHeight={130}
        tooltipText={`
            <p>
              Fundamentals Unlimited has over 200 research-based and standards-aligned Units of Study available.
              Incredible!
              In addition, you have the ability to customize each unit to meet the individual needs of your students.
            </p>
            <p>
              Let's begin by searching for and selecting a Unit of Study. Click the <strong>Units</strong> tab to get started.
            </p>
        `}
      />
    ),
    selector: "#tour-unit-btn-selector",
    position: "bottom-right",
    style: styleObject,
  },
];

class NewBrowseComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      showSearch: false,
      showFolder: false,
    };

    this.beginTRTour = _.debounce(
      () => {
        this.props.resetJoyride(stepsBrowseComponent, 0);
      },
      800,
      { leading: true, trailing: false }
    );
    this.beginUnitsTour = _.debounce(
      () => {
        this.props.resetJoyride(stepsUnits, 0);
      },
      800,
      { leading: true, trailing: false }
    );
  }

  componentDidMount() {
    //this.props.getRecommendedForYouAction();
  }

  componentWillReceiveProps(next) {
    if (
      next.location &&
      next.location.state &&
      next.location.state.beginTour &&
      !this.props.isJoyrideRunning
    )
      next.location.state.tourType === 1
        ? this.beginTRTour()
        : this.beginUnitsTour();
  }

  showSearch = (status) => {
    this.setState(
      { showMenu: status, showSearch: status, showFolder: false },
      () => this.refs.right.show()
    );
  };

  showFolder = (status) => {
    this.setState(
      { showMenu: status, showSearch: false, showFolder: status },
      () => this.refs.right.show()
    );
  };

  render() {
    return (
      <div>
        <div>
          <div>
            <div className={this.state.showMenu ? "md9class" : ""}>
              <div>
                <MiddleComponents
                  headerType={this.props.headerType}
                  showMenu={this.state.showMenu}
                  setLinkStateForTour={this.props.isJoyrideRunning}
                  folderNotifications={this.props.folderNotifications}
                />
              </div>
            </div>
            <MyMenu
              ref="right"
              alignment="right"
              showMenu={this.state.showMenu}
              showScroll={false}
            >
              {this.state.showSearch && (
                <GlobalSearch
                  focus={this.state.showMenu && window.innerWidth > 767}
                />
              )}
              {this.state.showFolder && (
                <FolderCreation
                  focus={this.state.showFolder && window.innerWidth > 767}
                  closeStatus={this.state.showFolder && !this.state.showFolder}
                />
              )}
            </MyMenu>
          </div>
        </div>
      </div>
    );
  }
}

// export default BrowseComponent;

const mapStateToProps = (state) => {
  return {
    recommendedForYou: state.RecommendedForYou,
    isJoyrideRunning: state.JoyrideReducer.joyrideShouldRun,
  };
};

export default connect(mapStateToProps, actions)(NewBrowseComponent);

class MiddleComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  componentDidMount() {
    let imageList = [
      assessment_blue_icon,
      assessment_white_icon,
      library_white_icon,
      library_blue_icon,
      home_blue_icon,
      home_white_icon,
      units_blue_icon,
      units_white_icon,
      lessons_blue_icon,
      lessons_white_icon,
      texts_blue_icon,
      texts_blue_icon,
      texts_white_icon,
      resources_blue_icon,
      resources_white_icon,
      folders_white_icon,
      folders_blue_icon,
    ];

    imageList.forEach((image) => {
      new Image().src = image;
    });
  }

  handleOpenModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  render() {
    let headerType = this.props?.headerType;
    let isBrowseHome = window.location.href.endsWith("browse");

    return (
      <div className="browse-navigation-container">
        <div className="browse-btn-spacer" />
        <div
          className={
            headerType === "library"
              ? "library-btn-row-container"
              : "browse-btn-row-container"
          }
        >
          {/* HOME BUTTON */}
          {localStorage.user_type !== "Student" ? (
            localStorage.is_lite === "false" ? (
              (localStorage.user_type !== "Text Only"?
              <Link
                to={{
                  pathname: headerType === "library" ? "/library" :"/browse",
                  state: { beginTour: this.props.setLinkStateForTour },
                }}
              >
                <div
                  id="tour-unit-btn-selector"
                  className={
                    isBrowseHome || window.location.href.endsWith("/library")
                      ? "bg-not-clickable-new active bg-not-clickable-new-home"
                      : "bg-not-clickable-new bg-not-clickable-new-home"
                  }
                >
                  <div className="table-cell-new">
                    <div className="icons-new icons-new-home">
                      <img
                        style={{ height: "30px", width: "30px" }}
                        src={
                          headerType === "library"
                            ? window.location.href.endsWith("/library")
                              ? library_white_icon
                              : library_blue_icon
                            : isBrowseHome
                            ? home_white_icon
                            : home_blue_icon
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </Link>: <Link
                to={{
                  pathname: headerType === "library" ? "/library" :"/browse/books",
                  state: { beginTour: this.props.setLinkStateForTour },
                }}
              >
                <div
                  id="tour-unit-btn-selector"
                  className={
                    isBrowseHome || window.location.href.endsWith("/library")
                      ? "bg-not-clickable-new active bg-not-clickable-new-home"
                      : "bg-not-clickable-new bg-not-clickable-new-home"
                  }
                >
                  <div className="table-cell-new">
                    <div className="icons-new icons-new-home">
                      <img
                        style={{ height: "30px", width: "30px" }}
                        src={
                          headerType === "library"
                            ? window.location.href.endsWith("/library")
                              ? library_white_icon
                              : library_blue_icon
                            : isBrowseHome
                            ? home_white_icon
                            : home_blue_icon
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </Link>)
            ) : (
              <Link to="">
                <div
                  id="tour-unit-btn-selector"
                  className={"bg-not-clickable-new bg-not-clickable-new-home"}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.handleOpenModal();
                  }}
                  style={{ opacity: "50%" }}
                >
                  <div className="table-cell-new">
                    <div className="icons-new icons-new-home">
                      <img
                        style={{ height: "30px", width: "30px" }}
                        src={home_blue_icon}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </Link>
            )
          ) : (
            <div />
          )}
          {/* Header buttons not for Text only user */}
          {localStorage.user_type !== "Text Only" && 
            <div className="btn-right-container">
              {/* FOLDERS BUTTON */}
              {localStorage.user_type !== "Student" &&
                headerType === "library" &&
                (localStorage.is_lite === "false" ? (
                  <Link
                    to={{
                      pathname:
                        headerType === "library" ? "/library/folders" : "",
                      state: { beginTour: this.props.setLinkStateForTour },
                    }}
                  >
                    <div
                      id="tour-unit-btn-selector"
                      className={
                        window.location.href.includes("folders")
                          ? "bg-not-clickable-new active"
                          : "bg-not-clickable-new"
                      }
                    >
                      <div className="table-cell-new">
                        <div className="icons-new">
                          <img
                            style={{ height: "30px", width: "30px" }}
                            src={
                              window.location.href.includes("folders")
                                ? folders_white_icon
                                : folders_blue_icon
                            }
                            alt=""
                          />
                          {/*<i className="grid-demo-icon icon-icon-grid"/>*/}
                        </div>
                        <div className="middle-comp-new">
                          <h2 id="tr">
                            {window.location.href.includes("library") ||
                            window.location.pathname.includes("folders")
                              ? "My Folders"
                              : "Folders"}
                          </h2>
                        </div>
                      </div>
                    </div>
                    {this.props.folderNotifications?.length > 0 && (
                      <div className="shared-notification">
                        <div className="shared-notification-text">
                          {this.props.folderNotifications?.length}
                        </div>
                      </div>
                    )}
                  </Link>
                ) : (
                  <div
                    id="tour-unit-btn-selector"
                    className="bg-not-clickable-new"
                    style={{
                      backgroundColor: "lightgray",
                      pointerEvents: "none",
                    }}
                  >
                    <div
                      id="tour-unit-btn-selector"
                      className="bg-not-clickable-new"
                      style={{
                        backgroundColor: "lightgray",
                        pointerEvents: "none",
                      }}
                    >
                      <div className="table-cell-new">
                        <div className="icons-new">
                          <img
                            src={
                              "https://uploads-ssl.webflow.com/614b2bd68130156ae0c9b395/620160f9b8f9b24dec708555_Lessons_S40.svg"
                            }
                            alt=""
                          />
                        </div>
                        <div className="middle-comp-new">
                          <h2 id="tr">
                            {window.location.href.includes("library") ||
                            window.location.pathname.includes("folders")
                              ? "My Folders"
                              : "Folders"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              {/* UNITS BUTTON */}
              {localStorage.user_type !== "Student" &&
                (localStorage.is_lite === "false" ? (
                  <Link
                    to={{
                      pathname:
                        headerType === "library"
                          ? "/library/units"
                          : "/browse/units/all",
                      state: { beginTour: this.props.setLinkStateForTour },
                    }}
                  >
                    <div
                      id="tour-unit-btn-selector"
                      className={
                        window.location.href.includes("units")
                          ? "bg-not-clickable-new active"
                          : "bg-not-clickable-new"
                      }
                    >
                      <div className="table-cell-new">
                        <div className="icons-new">
                          <img
                            style={{ height: "30px", width: "30px" }}
                            src={
                              window.location.href.includes("units")
                                ? units_white_icon
                                : units_blue_icon
                            }
                            alt=""
                          />
                        </div>
                        <div className="middle-comp-new">
                          <h2>
                            {window.location.href.includes("library") ||
                            window.location.pathname.includes("folders")
                              ? "My Units"
                              : "Units"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <Link to="">
                    <div
                      id="tour-unit-btn-selector"
                      className={"bg-not-clickable-new"}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.handleOpenModal();
                      }}
                      style={{ opacity: "50%" }}
                    >
                      <div className="table-cell-new">
                        <div className="icons-new">
                          <img
                            style={{ height: "30px", width: "30px" }}
                            src={units_blue_icon}
                            alt=""
                          />
                        </div>
                        <div className="middle-comp-new">
                          <h2>{"Units"}</h2>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}

              {/* LESSONS BUTTON NON STUDENT */}
              {localStorage.user_type !== "Student" &&
                (localStorage.is_lite === "false" ? (
                  <Link
                    to={{
                      pathname:
                        headerType === "library"
                          ? "/library/lessons"
                          : "/browse/lessons/all",
                      state: { beginTour: this.props.setLinkStateForTour },
                    }}
                  >
                    <div
                      id="tour-unit-btn-selector"
                      className={
                        window.location.href.includes("lessons")
                          ? "bg-not-clickable-new active"
                          : "bg-not-clickable-new"
                      }
                    >
                      <div className="table-cell-new">
                        <div className="icons-new">
                          <img
                            style={{ height: "30px", width: "30px" }}
                            src={
                              window.location.href.includes("lessons")
                                ? lessons_white_icon
                                : lessons_blue_icon
                            }
                            alt=""
                          />
                        </div>
                        <div className="middle-comp-new">
                          <h2 id="tr">
                            {window.location.href.includes("library") ||
                            window.location.pathname.includes("folders")
                              ? "My Lessons"
                              : "Lessons"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <Link to="">
                    <div
                      id="tour-unit-btn-selector"
                      className={"bg-not-clickable-new"}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.handleOpenModal();
                      }}
                      style={{ opacity: "50%" }}
                    >
                      <div className="table-cell-new">
                        <div className="icons-new">
                          <img
                            style={{ height: "30px", width: "30px" }}
                            src={lessons_blue_icon}
                            alt=""
                          />
                        </div>
                        <div className="middle-comp-new">
                          <h2 id="tr">{"Lessons"}</h2>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}

              {/* TEACHING RESOURCES BUTTON */}
              {localStorage.user_type !== "Student" && (
                <Link
                  to={{
                    pathname:
                      headerType === "library"
                        ? "/library/resources"
                        : "/browse/teaching-resources/all",
                    state: { beginTour: this.props.setLinkStateForTour },
                  }}
                >
                  <div
                    className={
                      window.location.href.includes("resources")
                        ? "bg-not-clickable-new active"
                        : "bg-not-clickable-new"
                    }
                  >
                    <div className="table-cell-new">
                      <div className="icons-new">
                        <img
                          style={{ height: "30px", width: "30px" }}
                          src={
                            window.location.href.includes("resources")
                              ? resources_white_icon
                              : resources_blue_icon
                          }
                          alt=""
                        />
                      </div>
                      <div className="middle-comp-new">
                        <h2 id="tr">
                          {window.location.href.includes("library") ||
                          window.location.pathname.includes("folders")
                            ? "My Teaching Resources"
                            : "Teaching Resources"}
                        </h2>
                      </div>
                    </div>
                  </div>
                </Link>
              )}

              {/* ASSESSMENTS BUTTON */}
              {localStorage.user_type !== "Student" &&
                (localStorage.is_lite === "false" ? (
                  <Link
                    to={
                      headerType === "library"
                        ? "/library/assessments"
                        : "/browse/assessments/all"
                    }
                  >
                    <div
                      className={
                        window.location.href.includes("assessments")
                          ? "bg-not-clickable-new active"
                          : "bg-not-clickable-new"
                      }
                    >
                      <div className="table-cell-new">
                        <div className="icons-new">
                          <img
                            style={{ height: "30px", width: "30px" }}
                            src={
                              window.location.href.includes("assessments")
                                ? assessment_white_icon
                                : assessment_blue_icon
                            }
                            alt=""
                          />
                        </div>
                        <div className="middle-comp-new">
                          <h2>
                            {window.location.href.includes("library") ||
                            window.location.pathname.includes("folders")
                              ? "My Assessments"
                              : "Assessments"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <Link to="">
                    <div
                      className={"bg-not-clickable-new"}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.handleOpenModal();
                      }}
                      style={{ opacity: "50%" }}
                    >
                      <div className="table-cell-new">
                        <div className="icons-new">
                          <img
                            style={{ height: "30px", width: "30px" }}
                            src={assessment_blue_icon}
                            alt=""
                          />
                        </div>
                        <div className="middle-comp-new">
                          <h2>{"Assessments"}</h2>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}

              {/* TEXTS BUTTON */}
              {localStorage.is_lite === "false" ? (
                <Link
                  to={
                    headerType === "library" ? "/library/books" : "/browse/books"
                  }
                >
                  <div
                    className={
                      window.location.href.includes("books")
                        ? "bg-not-clickable-new active"
                        : "bg-not-clickable-new"
                    }
                  >
                    <div className="table-cell-new">
                      <div className="icons-new">
                        <img
                          style={{ height: "30px", width: "30px" }}
                          src={
                            window.location.href.includes("books")
                              ? texts_white_icon
                              : texts_blue_icon
                          }
                          alt=""
                        />
                      </div>
                      <div className="middle-comp-new">
                        <h2>
                          {window.location.href.includes("library") ||
                          window.location.pathname.includes("folders")
                            ? "My Texts"
                            : "Texts"}
                        </h2>
                      </div>
                    </div>
                  </div>
                </Link>
              ) : (
                <Link to="">
                  <div
                    className={"bg-not-clickable-new"}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.handleOpenModal();
                    }}
                    style={{ opacity: "50%" }}
                  >
                    <div className="table-cell-new">
                      <div className="icons-new">
                        <img
                          style={{ height: "30px", width: "30px" }}
                          src={texts_blue_icon}
                          alt=""
                        />
                      </div>
                      <div className="middle-comp-new">
                        <h2>{"Texts"}</h2>
                      </div>
                    </div>
                  </div>
                </Link>
              )}
            </div>
          }
          
          {/* Header buttons for Text only user */}

          {localStorage.user_type === "Text Only" && 
            <div className="btn-right-container">
              {/* Folders Button */}
              {localStorage.user_type !== "Student" &&
                headerType === "library" &&
                (localStorage.is_lite === "false" ? (
                  <Link
                    to={{
                      pathname:
                        headerType === "library" ? "/library/folders" : "",
                      state: { beginTour: this.props.setLinkStateForTour },
                    }}
                  >
                    <div
                      id="tour-unit-btn-selector"
                      className={
                        window.location.href.includes("folders")
                          ? "bg-not-clickable-new active"
                          : "bg-not-clickable-new"
                      }
                    >
                      <div className="table-cell-new">
                        <div className="icons-new">
                          <img
                            style={{ height: "30px", width: "30px" }}
                            src={
                              window.location.href.includes("folders")
                                ? folders_white_icon
                                : folders_blue_icon
                            }
                            alt=""
                          />
                          {/*<i className="grid-demo-icon icon-icon-grid"/>*/}
                        </div>
                        <div className="middle-comp-new">
                          <h2 id="tr">
                            {window.location.href.includes("library") ||
                            window.location.pathname.includes("folders")
                              ? "My Folders"
                              : "Folders"}
                          </h2>
                        </div>
                      </div>
                    </div>
                    {this.props.folderNotifications?.length > 0 && (
                      <div className="shared-notification">
                        <div className="shared-notification-text">
                          {this.props.folderNotifications?.length}
                        </div>
                      </div>
                    )}
                  </Link>
                ) : (
                  <div
                    id="tour-unit-btn-selector"
                    className="bg-not-clickable-new"
                    style={{
                      backgroundColor: "lightgray",
                      pointerEvents: "none",
                    }}
                  >
                    <div
                      id="tour-unit-btn-selector"
                      className="bg-not-clickable-new"
                      style={{
                        backgroundColor: "lightgray",
                        pointerEvents: "none",
                      }}
                    >
                      <div className="table-cell-new">
                        <div className="icons-new">
                          <img
                            src={
                              "https://uploads-ssl.webflow.com/614b2bd68130156ae0c9b395/620160f9b8f9b24dec708555_Lessons_S40.svg"
                            }
                            alt=""
                          />
                        </div>
                        <div className="middle-comp-new">
                          <h2 id="tr">
                            {window.location.href.includes("library") ||
                            window.location.pathname.includes("folders")
                              ? "My Folders"
                              : "Folders"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {/* Text Button */}
              {localStorage.is_lite === "false" && <Link
                  to="/library/books"
                >
                  <div
                    className={
                      window.location.href.includes("books")
                        ? "bg-not-clickable-new active"
                        : "bg-not-clickable-new"
                    }
                  >
                    <div className="table-cell-new">
                      <div className="icons-new">
                        <img
                          style={{ height: "30px", width: "30px" }}
                          src={
                            window.location.href.includes("books")
                              ? texts_white_icon
                              : texts_blue_icon
                          }
                          alt=""
                        />
                      </div>
                      <div className="middle-comp-new">
                        <h2>
                          {window.location.href.includes("library") ||
                          window.location.pathname.includes("folders")
                            ? "My Texts"
                            : ""}
                        </h2>
                      </div>
                    </div>
                  </div>
                </Link>}
            </div> 
          }
        </div>
        <div className="browse-btn-spacer" />
        <PrintOnlyModal
          show={this.state.isModalOpen}
          handleClose={this.handleCloseModal}
        />
      </div>
    );
  }
}
