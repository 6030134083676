import React, { Component } from "react";
import constants from "../constants/index";
import FilterCollapsible from "./FilterCollapsible";
import FilterStrandSection from "./FilterStrandSection";
import * as actions from "../redux/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";

class FilterMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      joyrideResetFilters: false,
      noMinValue: false,
      noMaxValue: false,
      selectedFilters: { filterList: [] },
    };
    this.getFiltered = this.getFiltered.bind(this);
  }

  filterLoadShouldStartJoyrideIfState = () => {
    //If joyride isn't running and should when filters are done loading, do this.
    if (
      this.state.joyrideResetFilters &&
      this.state.showFilter &&
      !this.props.joyrideShouldRun
    ) {
      _.debounce(this.props.nextJoyrideStep, 300, {
        leading: false,
        trailing: true,
      })();
      _.debounce(this.props.startJoyride, 500, {
        leading: false,
        trailing: true,
      })();
      this.setState({ joyrideResetFilters: false });
    }
    return true;
  };

  getSelectedFilters() {
    let x = this.props.selectedFilters.filterList
      ? this.props.selectedFilters.filterList
      : this.props.selectedFilters;
    if (
      JSON.parse(window.sessionStorage.getItem("selectedFilters")) != null &&
      JSON.parse(window.sessionStorage.getItem("selectedFilters")).length > 0
    ) {
      x = JSON.parse(window.sessionStorage.getItem("selectedFilters"));
    }

    return x || [];
  }

  getFiltered(filterObj, isChecked, isRemove) {
    let queryObj = _.cloneDeep(this.props.queryObj);
    if (queryObj[filterObj.query] === undefined) queryObj[filterObj.query] = [];

    if (filterObj.query !== "audiobook" && filterObj.query !== "lexileLevel") {
      if (isChecked) {
        queryObj[filterObj.query].push(filterObj._id);
      } else {
        queryObj[filterObj.query] = _.without(
          queryObj[filterObj.query],
          filterObj._id
        );
      }
    } else if (filterObj.query === "audiobook") {
      queryObj.audiobook = [isChecked];
    } else {
      queryObj.minLexileLevel = [filterObj.min];
      queryObj.maxLexileLevel = [filterObj.max];
    }

    let buttonClickValue = constants.convertToStrandNumber(this.props.strand);
    this.props.onFilterChange(
      filterObj,
      isChecked,
      isRemove,
      buttonClickValue,
      this.props.typeOfResource,
      queryObj
    );
  }

  removeLexileLevel = (remove, filterValue) => {
    if (remove === "min") {
      filterValue.min = 0;
      this.setState({ noMinValue: true }, () =>
        setTimeout(() => this.setState({ noMinValue: false }), 2000)
      );
    }
    if (remove === "max") {
      filterValue.max = 2000;
      this.setState({ noMaxValue: true }, () =>
        setTimeout(() => this.setState({ noMaxValue: false }), 2000)
      );
    }
    this.getFiltered(filterValue, false, "removeItem");
  };

  render() {
    return (
      <div>
        {/*{this.props.showLoader && 
                    <div style={{"position":"absolute","z-index":1,"top":"50%","left":"25%","width":"100%","height":"100%"}}>
                        Updating Filters <img className='loading-image' src={require('../assets/images/Loading.gif')}/>
                    </div>
                }*/}
        <div className="center-align-heading">
          <div className="filter-options-heading">FILTER OPTIONS</div>
        </div>
        <div className="filter-options">
          {!this.props.location.pathname.includes("books") &&
            this.props.location.pathname.includes("library") && (
              <div
                className="filter-seperator"
                style={{ paddingBottom: "15px" }}
              >
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "#4A4A4A",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    fontFamily: "ProximaNova",
                  }}
                >
                  {/*{this.props.location.pathname.includes("units") ? 'UNIT TYPE' : 'STRAND'}*/}
                  STRAND
                </div>
                <FilterStrandSection
                  location={this.props.location}
                  setStrand={this.props.setStrand}
                  presetStrand={this.props.strand}
                />
              </div>
            )}
          <div className="filter-seperator" style={{ paddingBottom: "15px" }}>
            <div
              style={{
                fontSize: "15px",
                fontWeight: "600",
                color: "#4A4A4A",
                paddingBottom: "10px",
                paddingTop: "10px",
                fontFamily: "ProximaNova",
              }}
            >
              Filtered By
            </div>
            <div>
              {this.getSelectedFilters().map((filterValue, j) => (
                <div key={j}>
                  {filterValue.query === "lexileLevel" ? (
                    <div>
                      {filterValue.min > 0 ? (
                        <div className="alert-filter">
                          <span
                            className="closebtn"
                            onClick={() =>
                              this.removeLexileLevel("min", filterValue)
                            }
                          >
                            {" "}
                            &times;
                          </span>
                          <span className="tag-name">
                            {" "}
                            Min Lexile Level : {filterValue.min}{" "}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {filterValue.max < 2000 ? (
                        <div className="alert-filter">
                          <span
                            className="closebtn"
                            onClick={() =>
                              this.removeLexileLevel("max", filterValue)
                            }
                          >
                            {" "}
                            &times;
                          </span>
                          <span className="tag-name">
                            {" "}
                            Max Lexile Level : {filterValue.max}{" "}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div className="alert-filter">
                      <span
                        className="closebtn"
                        onClick={() =>
                          this.getFiltered(filterValue, false, "removeItem")
                        }
                      >
                        {" "}
                        &times;
                      </span>
                      <span className="tag-name">
                        {" "}
                        {filterValue.name} : {filterValue.value}{" "}
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        {this.props.filters && this.filterLoadShouldStartJoyrideIfState() && (
          <div className="filter-options">
            <div>
              {this.props.filters.map((optionValue, i) => (
                <div
                  key={"filterCollapsible_" + optionValue.tagName}
                  className="filter-seperator"
                >
                  <FilterCollapsible
                    header={optionValue.header}
                    tagName={optionValue.tagName}
                    queryString={optionValue.queryString}
                    filterFunction={this.getFiltered}
                    content={optionValue.filters}
                    minRange={this.state.noMinValue}
                    maxRange={this.state.noMaxValue}
                    getSelectedFilters={this.getSelectedFilters}
                    joyrideNeedsThisOpen={
                      optionValue.header === "STANDARDS"
                        ? this.props.joyrideShouldRun
                        : false
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

/**
 *
 * Container for the filter menu
 * Props
 * typeOfResource
 * strand
 */
class FilterMenuContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilters: { filterList: [] },
      queryObj: {},
      showLoader: false,
    };
    this.onFilterChange = this.onFilterChange.bind(this);
    this.addingFiltersAction = _.once(this.props.addingFiltersAction);
  }

  getSelectedFilters() {
    let x = this.state.selectedFilters.filterList
      ? this.state.selectedFilters.filterList
      : this.state.selectedFilters;
    if (
      JSON.parse(window.sessionStorage.getItem("selectedFilters")) != null &&
      JSON.parse(window.sessionStorage.getItem("selectedFilters")).length > 0
    ) {
      x = JSON.parse(window.sessionStorage.getItem("selectedFilters"));
    }

    return x || [];
  }

  onFilterChange(
    filterObj,
    isChecked,
    isRemove,
    buttonClickValue,
    typeOfResource,
    query
  ) {
    let filters = [];
    if (isChecked) {
      if (filterObj.query === "lexileLevel") {
        filters = _.filter(
          this.getSelectedFilters(),
          (selectedFilter) => selectedFilter.query !== filterObj.query
        );
        filters.push(filterObj);
        this.setState({ min: filterObj.min, max: filterObj.max });
      } else {
        let x = this.getSelectedFilters();
        filters = Array.isArray(x) ? x.slice(0) : [];
        filters.push(filterObj);
      }
    } else {
      filters = _.filter(
        this.getSelectedFilters(),
        (selectedFilter) =>
          selectedFilter._id !== filterObj._id ||
          selectedFilter.name !== filterObj.name
      );
      if (filterObj.query === "lexileLevel") {
        filters = _.filter(
          this.getSelectedFilters(),
          (selectedFilter) => selectedFilter.query !== filterObj.query
        );
        filters.push(filterObj);
      }
    }

    let queryObjectToString = "";
    for (let i of Object.keys(query)) {
      for (let value of query[i]) queryObjectToString += `&${i}=${value}`;
    }

    window.sessionStorage.setItem("selectedFilters", JSON.stringify(filters));
    this.setState(
      {
        selectedFilters: { filterList: filters },
        queryObj: query,
        showLoader: true,
      },
      () => {
        if (this.props.location.pathname.includes("browse")) {
          this.props.getCustomFiltersAction(
            buttonClickValue,
            typeOfResource,
            queryObjectToString
          );
        } else {
          this.props.getCustomLibraryFiltersAction(
            this.props.strand,
            constants.normalizeTypeName(this.props.typeOfResource),
            queryObjectToString
          );
        }
        this.props.addingFiltersAction(filters, queryObjectToString);
        let self = this;
        setTimeout(function () {
          self.setState({
            showLoader: self.props.showFilterLoader,
          });
        }, 5000);
      }
    );
  }

  createFilterGroupData() {
    const unitFilterObjects = [
      {
        header: "GRADE LEVEL",
        tagName: "Grade",
        queryString: "grades",
        filters: [],
      },
      {
        header: "STRAND",
        tagName: "Strand",
        queryString: "strands",
        filters: [],
      },
      {
        header: "GENRE/TEXT TYPE",
        tagName: "Unit",
        queryString: "unitsOfStudy",
        filters: [],
      },
      {
        //header: "STANDARD TYPE",
        header: "CORRELATIONS",
        tagName: "Correlation",
        queryString: "standardType",
        filters: [],
      },
    ];

    const resourceFilterObject = [
      {
        header: "GRADE LEVEL",
        tagName: "Grade",
        queryString: "grades",
        filters: [],
      },
      {
        header: "STANDARDS",
        tagName: "Standard",
        queryString: "standards",
        filters: [],
      },
      {
        header: "SKILLS & STRATEGIES",
        tagName: "Skill",
        queryString: "skills",
        filters: [],
      },
      {
        header: "RESOURCE TYPE",
        tagName: "Resource",
        queryString: "resourceTypes",
        filters: [],
      },
      {
        header: "UNITS OF STUDY",
        tagName: "Unit",
        queryString: "unitsOfStudy",
        filters: [],
      },
    ];

    const assessmentsFilterObject = [
      {
        header: "GRADE LEVEL",
        tagName: "Grade",
        queryString: "grades",
        filters: [],
      },
      {
        header: "STANDARDS",
        tagName: "Standard",
        queryString: "standards",
        filters: [],
      },
      {
        header: "SKILLS & STRATEGIES",
        tagName: "Skill",
        queryString: "skills",
        filters: [],
      },
      {
        header: "ASSESSMENT TYPE",
        tagName: "Assessment",
        queryString: "assessmentTypes",
        filters: [],
      },
      {
        header: "UNITS OF STUDY",
        tagName: "Unit",
        queryString: "unitsOfStudy",
        filters: [],
      },
    ];
    let bookFilterObject = [];
    /*
        hidden elearning filter as per task https://app.clickup.com/t/fah0e3 dated 14-01-2021
        */
    if (localStorage.user_type !== "Student") {
      bookFilterObject = [
        // To get a audio book option in filter section.
        // {
        //     header: "AUDIO BOOKS",
        //     tagName: 'Audio book',
        //     queryString: 'audiobook',
        //     filters: []
        // },
        {
          header: "GENRE",
          tagName: "Genre",
          queryString: "genres",
          filters: [],
        },
        {
          header: "THEME",
          tagName: "Theme",
          queryString: "themes",
          filters: [],
        },
        {
          header: "TOPIC",
          tagName: "Topic",
          queryString: "topics",
          filters: [],
        },
        {
          header: "GUIDED READING LEVEL",
          tagName: "Guided Reading Level",
          queryString: "readingLevels",
          filters: [],
        },
        {
          header: "LEXILE LEVEL",
          tagName: "Lexile Level",
          queryString: "lexileLevel",
          filters: [],
        },
        {
          header: "LANGUAGE",
          tagName: "Language",
          queryString: "languages",
          filters: [],
        },
        {
          header: "INTEREST LEVEL",
          tagName: "Interest Level",
          queryString: "grades",
          filters: [],
        } /*,
                {
                    header:"ELEARNING",
                    tagName :'eLearning',
                    queryString:'elearning'
                }*/,
      ];
    } else {
      bookFilterObject = [
        {
          header: "GENRE",
          tagName: "Genre",
          queryString: "genres",
          filters: [],
        },
        {
          header: "THEME",
          tagName: "Theme",
          queryString: "themes",
          filters: [],
        },
        {
          header: "TOPIC",
          tagName: "Topic",
          queryString: "topics",
          filters: [],
        },
        {
          header: "GUIDED READING LEVEL",
          tagName: "Guided Reading Level",
          queryString: "readingLevels",
          filters: [],
        },
        {
          header: "LANGUAGE",
          tagName: "Language",
          queryString: "languages",
          filters: [],
        },
        {
          header: "INTEREST LEVEL",
          tagName: "Interest Level",
          queryString: "grades",
          filters: [],
        } /*,
                {
                    header:"ELEARNING",
                    tagName :'eLearning',
                    queryString:'elearning'
                }*/,
      ];
    }

    // Select filter object conditional on the type of resource
    let filterObject = null;

    switch (this.props.typeOfResource) {
      case "resources":
      case "teachingResources":
        filterObject = resourceFilterObject;
        break;
      case "units":
        filterObject = unitFilterObjects;
        break;
      case "books":
        filterObject = bookFilterObject;
        break;
      case "assessments":
        filterObject = assessmentsFilterObject;
        break;
    }

    let blankFilterObject = _.cloneDeep(filterObject);
    // Load filter options with filter option prop
    if (this.props.filterOptions) {
      _.each(filterObject, (filterObjGroup, i) => {
        if (this.props.filterOptions[filterObjGroup.queryString]) {
          filterObject[i].filters =
            this.props.filterOptions[filterObjGroup.queryString];
          for (let j = 0; j < filterObject[i].filters.length; j++) {
            let matchingFilter = _.find(this.getSelectedFilters(), {
              value: filterObject[i].filters[j].value,
              _id: filterObject[i].filters[j]._id,
            });
            filterObject[i].filters[j].checked = matchingFilter !== undefined;
            if (
              matchingFilter !== undefined &&
              filterObject[i].filters[j].value === "Lexile Level"
            ) {
              filterObject[i].filters[j].min = matchingFilter.min;
              filterObject[i].filters[j].max = matchingFilter.max;
            }
          }
        }
      });
    }

    //On My Library, we have an issue with race conditions allowing incorrect filters to be partially loaded.
    //To prevent this, we do not load any filters in the UI if any category of filters is incomplete.
    return _.every(
      filterObject,
      (filterSubObject) => filterSubObject.filters.length > 0
    )
      ? filterObject
      : blankFilterObject;
  }

  componentWillReceiveProps(props) {
    if (
      this.props.strand !== props.strand ||
      this.props.typeOfResource !== props.typeOfResource
    ) {
      // Strand has changed, clear selected filters
      this.setState({ selectedFilters: { filterList: [] }, queryObj: {} });
      this.props.addingFiltersAction([]);
    } else {
      // If selected filters have changed, update state
      let selectedFilters = this.getSelectedFilters(),
        stateSelectedFilterCount = selectedFilters.length,
        propsSelectedFilterCount =
          props.selectedFilters && props.selectedFilters.filterList
            ? props.selectedFilters.filterList.length
            : 0;

      if (stateSelectedFilterCount !== propsSelectedFilterCount) {
        this.setState({
          selectedFilters: { filterList: props.selectedFilters.filterList },
        });
        selectedFilters = props.selectedFilters.filterList;
      }

      //Some functions below expect a query object with keys = collapsible category and value = array of selected filters.
      let filtersGroupedByName = _.groupBy(selectedFilters, "query"),
        lexile = _.pick(filtersGroupedByName, "lexileLevel").lexileLevel;

      _.unset(filtersGroupedByName, "lexileLevel");

      let groupedAndStrippedFilters = _.mapValues(
        filtersGroupedByName,
        (filterArr) => _.map(filterArr, (obj) => obj._id)
      );
      if (lexile && lexile[0].min)
        groupedAndStrippedFilters["minLexileLevel"] = [lexile[0].min];
      if (lexile && lexile[0].max)
        groupedAndStrippedFilters["maxLexileLevel"] = [lexile[0].max];

      this.setState({ queryObj: groupedAndStrippedFilters });
    }
  }

  libraryStrandSelected = (strandName) => {
    if (strandName)
      this.props.getCustomLibraryFiltersAction(
        constants.caseMap[strandName.toLowerCase()],
        constants.normalizeTypeName(this.props.typeOfResource),
        ""
      );
    else {
      this.props.clearMyLibraryFiltersAction();
      this.props.getMyLibraryAction(
        constants.normalizeTypeName(this.props.typeOfResource)
      );
    }
  };

  render() {
    let filters = this.createFilterGroupData(this.props.typeOfResource);
    _.each(this.getSelectedFilters(), (selectedFilter) => {
      if (!selectedFilter) return;
      let selectedValue = selectedFilter.value;
      let selectedId = selectedFilter._id;
      _.each(filters, (filterGroupOption, optionIndex) => {
        _.each(filterGroupOption.filters, (filterOption, filterIndex) => {
          if (
            filterOption.value === selectedValue &&
            filterOption._id === selectedId
          ) {
            filters[optionIndex].filters[filterIndex].checked = true;
          }
        });
      });
    });

    return this.props.shown ? (
      <FilterMenu
        filters={filters}
        onFilterChange={this.onFilterChange}
        filterLoadStatus={this.props.filterLoadStatus}
        strand={this.props.strand}
        selectedFilters={this.state.selectedFilters}
        typeOfResource={this.props.typeOfResource}
        joyrideShouldRun={this.props.joyrideShouldRun}
        location={this.props.location}
        setStrand={this.libraryStrandSelected}
        queryObj={this.state.queryObj}
        showLoader={this.state.showLoader}
      />
    ) : (
      <div />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filterLoadStatus: state.CustomFilters
      ? state.CustomFilters.asyncStatus
      : null,
    selectedFilters: state.FilterScale,
    showFilterLoader: state.FilterScale.loading,
    filterOptions: state.CustomFilters.data
      ? state.CustomFilters.data.filters
      : {},
    strand: state.CustomFilters.strand,
    joyrideShouldRun: state.JoyrideReducer.joyrideShouldRun,
    joyrideStepIndex: state.JoyrideReducer.joyrideStepIndex,
    joyrideIntendsToStart: state.JoyrideReducer.joyrideIntendsToStart,
  };
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(FilterMenuContainer));
