import React , {Component} from 'react'

import RegistrationComponent from  '../../components/RegistrationComponent';


class Registration extends Component{

    // constructor(props) {
    //     super(props)
    //     this.state = {

    //     }
    // }
    render(){

        // Fetch the domain from the route param. Defined when we navigate to /login/:domain
        return(
            <div>
                <RegistrationComponent  />
            </div>
        );
    }
}

export default Registration;