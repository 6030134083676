import React, { Component } from "react";
import { Col, Row, Button, FormControl, ButtonGroup } from "react-bootstrap";
import styled from 'styled-components'


export default class Tabs extends Component {
  state = {
      active: 0
  };

  setActive = key => {
      this.setState({ active: key })
  }
  render() {
    const { active } = this.state;
    const { tabs } = this.props;
    const { tabs_title, tabs_content } = tabs;

    return (
      <Row>
          <TabBtnWrapper>
            {tabs_title.map( (tab_title, idx) => <TabBtn key={idx} active={idx === active} onClick={() => this.setActive(idx)}>{tab_title}</TabBtn>)}
          </TabBtnWrapper>
       
        {tabs_content[active]}
      </Row>
    );
  }
}

const TabBtnWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
   align-items: center; 
`
const TabBtn = styled(Button).attrs({
    bsStyle: ({ active }) => active ? "primary" : 'default'
})`
  text-transform: uppercase;
`