import React,{Component} from 'react'
import constants from "../../constants";
import DetailsListViewItem from "../../containers/Details/DetailsListViewItem";

class Correlations extends Component {
    render() {
        return(
            <div className={'list-of-items unit-correlations'}>
                {this.props.content.correlations? "" :
                    <div className='lesson-text-padding featured-reading' style={{textAlign: "center"}}> {'There are no correlations in this unit.'}</div>
                }
                {this.props.content.correlations && this.props.content.correlations[0] && this.props.content.correlations.map((correlationsArray,i)=>(
                    <DetailsListViewItem
                        detailURL={"/correlations/" + correlationsArray._id}
                        imageSrc={constants.filesUrl + (correlationsArray.image?.replace("../",'') || '')}
                        contentHeading={correlationsArray.name ? correlationsArray.name : null}
                        rowId={i}
                    >
                        <a href={"/correlations/" + correlationsArray._id} className="detail-link">
                            {correlationsArray.description &&
                                (correlationsArray.description.description ? correlationsArray.description.description : null)
                            }
                         </a>
                    </DetailsListViewItem>
                ))}
            </div>
        );
    }
}

export default Correlations;