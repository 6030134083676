import React,{Component} from  'react'
import NewUnitDetailedView from './NewUnitDetailedView'

class NewUnitComponent extends Component{

    render(){

        return(
            <div>
                <NewUnitDetailedView />
            </div>
        );
    }
}

export default NewUnitComponent
