import {
    GET_CUSTOM_UNITS_REQUEST,
    GET_CUSTOM_UNITS_SUCCESS,
    GET_CUSTOM_UNITS_FAILURE
} from '../actions';

const initialState = {
    data: [],
    page:1,
    hasMore:true
};
//*************** flag status***************
//flag=0 first load
//flag=1 on scroll to append data to the existing array

const CustomUnitsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CUSTOM_UNITS_REQUEST:
            return Object.assign({},state, {asyncStatus:'PENDING'});
        case GET_CUSTOM_UNITS_SUCCESS:
            if(action.flag === 0){
               state.data.results=[]
            }
            if(action.payload.results.length === 0){
                return Object.assign({},state,
                    {asyncStatus:'SUCCESS',
                        data: [],
                        page: 1,
                        hasMore: false
                    });
            }
            if(action.query !== "" && action.flag === 0){
                state = initialState;
            }
            let newData= action.payload || [];
            if(state.data.results){
                newData["results"] = [...state.data.results,...action.payload.results];
            }
            return Object.assign({},state, {asyncStatus:'SUCCESS',
                data: newData,
                hasMore:action.payload.nextPage !== -1,
                page: action.payload.currentPage
            });
        case GET_CUSTOM_UNITS_FAILURE:
            return Object.assign({},state, {asyncStatus:'FAILURE', data: action.payload});
        default:
            return state;
    }
};

export default CustomUnitsReducer;