import React from 'react'
import ToolTip from 'react-portal-tooltip'
import {Row,Col,Modal, Button} from 'react-bootstrap';
import folderIcon from '../../assets/images/folder.png';

let style1 = {
    style: {
        height: "auto",
        width: "200px",
        border: "1px solid #F5F5F5",
        borderRadius: "8px",
        backgroundColor: "rgba(255,255,255)",
        boxShadow: "0 2px 12px 0 rgba(0,0,0,0.5)",
        padding: "20px 20px 20px 20px",
        left: '0px',
        zIndex: 1000
    },
    arrowStyle: {
        color: "white",
        borderColor: "#F5F5F5"
    }
};

class AddFolderPopOver extends React.Component {
    render() {
        let myPar = "#"+ this.props.myId;
        const searchResults = this.props.folderItems.map((folder,i)=>
                                <Row key={folder+i} className="shared-folder-items">
                                    <span className="add-folder-span">
                                        <img src={folderIcon} style={{width:"15px","marginRight":"4px"}}/>
                                        {folder.name}
                                    </span>                                   
                                </Row>
                            );

        return (
            <ToolTip id="availableFolders" active={true} parent={myPar} position="top" arrow="center" style={style1} positionLeft={0}>
                <div>
                    <h5>Select folder below:</h5>                    
                    {
                      searchResults
                    }
                </div>
            </ToolTip>
        )
    }
}

export default AddFolderPopOver;
