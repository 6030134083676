import React, {Component} from 'react'
import {Row,Col,Modal, Button} from 'react-bootstrap'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import debounce from 'debounce'
import InfiniteScroll from 'react-infinite-scroll-component'
import {browserHistory} from 'react-router'
import Datetime from 'react-datetime'
import JoyrideTourModal from '../JoyrideCustomComponent/JoyrideTourModal'
import _ from 'lodash'
import moment from 'moment';
import authAPI from '../../api/authAPI'

let clearDatePicker;
class CheckboxItem extends Component{
    onToggledHandler=(e,i,item)=>{
        this.props.handleClick(e, i, item);
    }

    render(){
        return(
        <div>
            {this.props.data && this.props.data.map((item,i)=>(
                    <div key={item+i} id='selectId' className='skill-item-option'>
                        <div className='checkbox-option'>
                            <input ref={item+i} className='can-hover' type="checkbox" onClick={(e) => this.onToggledHandler(e,i,item)} checked={!item.checked}/>
                            <label className='checkbox-option-text checkbox-label-skill' value={item}>
                                {item.value}
                            </label>
                        </div>
                    </div>
                ))
            }
            </div>)
    }
}
class Assign extends Component {
    constructor(props){
        super(props);
        this.state={
            searchTerm: "",
            addStudents: [],
            addGroups: [],
            assignDate: new Date(),
            assignDateValid: "",
            selectedTab:"STUDENTS",
            showDatePicker: false,
            joyridePausedForDate: false,
            showTourModal: false,
            assignError:'',
            showLearingModal:false,
            counter:0,
            writeArray:[],
            readArray:[]
        };
        this.search = debounce(this.search,100);
        this.nextJoyrideStep = debounce(this.props.nextJoyrideStep);
        this.datePickerShouldPauseJoyride = this.datePickerShouldPauseJoyride.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.saveSkills = this.saveSkills.bind(this);
        this.cancelSkills = this.cancelSkills.bind(this);
        this.uncheckedItems = {};
    }

    assignToStudent = (sid)=>{
        for(let i=0;i<this.state.addStudents.length;i++){
            if (sid === this.state.addStudents[i]){
                this.state.addStudents.splice(i,1);
                this.setState({addStudents: this.state.addStudents});
                return;
            }
        }
        this.state.addStudents.push(sid);
        this.setState({addStudents: this.state.addStudents,assignError:''}) ;
    };

    assignToGroup = (gid)=>{
        for(let i=0;i<this.state.addGroups.length;i++){
            if (gid=== this.state.addGroups[i]){
                this.state.addGroups.splice(i,1);
                this.setState({addGroups: this.state.addGroups});
                return;
            }
        }
        this.state.addGroups.push(gid);
        this.setState({addGroups: this.state.addGroups,assignError:''}) ;
    };

    postAssignment = ()=>{
        if(this.state.addStudents.length>0 || this.state.addGroups.length>0){
            if(this.state.assignDate === "") {
                this.setState({assignDateValid: "Assign date cannot be blank."});
                return;
            }
            else if (this.state.assignDateValid !== ""){
                this.setState({asignDateValid: ""});
            }
            
            let skill_ids=[]
            if(this.state.readArray){
                this.state.readArray.map((finalRead)=>{
                    if(finalRead && finalRead.checked!==true){
                        skill_ids.push(finalRead._id)
                    }
                })
            }
            if(this.state.writeArray){
                this.state.writeArray.map((finalWrite)=>{
                    if(finalWrite && finalWrite.checked!==true){
                        skill_ids.push(finalWrite._id)
                    }
                })
            }
            let totalSkills = [ ...this.state.readArray, ...this.state.writeArray]
            if(totalSkills.length===skill_ids.length){
                skill_ids=[]
            }
            let assign_object={}
            if(skill_ids.length===0){
                assign_object={
                "day" : this.state.assignDate,
                "item_id":  this.props.location.state.libraryItemId,
                "item_type": this.props.location.state.libraryItemType
                };
            }else{
                assign_object={
                "day" : this.state.assignDate,
                "item_id":  this.props.location.state.libraryItemId,
                "item_type": this.props.location.state.libraryItemType,
                "skill_ids":skill_ids
                };
            }
            
            if(this.state.selectedTab === "STUDENTS"){
                for(let i=0;i<this.state.addStudents.length;i++){
                    this.props.postAssignmentAction(this.state.addStudents[i],assign_object)
                        .then((response)=>{
                        if(response.statusCode === 201){
                            console.log("successfully assigned");
                            browserHistory.push('/library/'+this.props.assignmentItem.data.type.toLowerCase()+'s');
                        }else{
                            console.log("error occured");
                        }
                        })
                        .catch(function (error) {
                        console.log("exception occured",error);
                    });
                }
            }
            if(this.state.selectedTab === "GROUPS"){
                for(let i=0;i<this.state.addGroups.length;i++){
                    let grp= this.props.groups.data.filter(x => x._id === this.state.addGroups[i]);
                    for(let k=0;k<grp[0].users.length;k++){
                        this.props.postAssignmentAction(grp[0].users[k]._id,assign_object)
                            .then((response)=>{
                                if(response.statusCode === 201){
                                    console.log("successfully assigned");
                                    browserHistory.push('/library/'+this.props.assignmentItem.data.type.toLowerCase()+'s');
                                }else{
                                    console.log("error occured");
                                }
                            })
                            .catch(function (error) {
                                console.log("exception occured",error);
                            });
                    }
                }
            }
        }else{
            this.setState({assignError:'Please select at least one Student/Group'})
        }

    };

    hideModal=()=>{
        this.setState({showLearingModal:false})
    }

    changeFunction= (event)=>{
        let val = event.target.value;
        this.setState({searchTerm: val});
        this.search(val);
    };

    search=(val)=>{
        if(this.state.selectedTab === "STUDENTS"){
            this.props.getUsersAction("Student",val,0,1,1,0,this.props.location.state.libraryItemType, this.props.location.state.libraryItemId);
        }
        else{
            this.props.getGroupsAction(val);
        }

    };

    getSkillDetails=()=>{
        this.setState({showLearingModal:true})
    }

    componentDidMount(){
        this.props.getUsersAction("Student","",0,1,1,0,this.props.location.state.libraryItemType, this.props.location.state.libraryItemId);
        this.props.getGroupsAction("");
        let id =this.props.assignmentItem && this.props.assignmentItem.data && this.props.assignmentItem.data.id;
        this.props.getElearningSkillsAction(id || this.props.location.state.libraryItemId)
        if(this.props.isJoyrideRunning)
          this.nextJoyrideStep();
    }

    componentWillReceiveProps(next){
      if(next.showTourModal)
        this.setState({showTourModal: true});
      else {
        this.setState({showTourModal: false});
      }
    }

    handleDate=(date)=>{
        this.setState({assignDate: date._d,showDatePicker: false});
        if(this.state.joyridePausedForDate){
          this.props.allowClicksThruHere(false);
          this.props.nextJoyrideStep();
          this.props.startJoyride();
        }
    };

    datePickerShouldPauseJoyride=()=>{
      if(this.props.isJoyrideRunning){
        this.props.stopJoyride();
        this.setState({joyridePausedForDate: true});
      }
    }

    hideTourModal=()=>{
      this.setState({showTourModal: false});
    }

    handleAssignIconClick=(_id)=>{
      if(this.props.isJoyrideRunning){
        this.props.allowClicksThruHere(false);
        this.props.stopJoyride();
          _.debounce(this.props.startJoyrideAtIndex.bind(this, 5, 5), 700, {leading: false, trailing: true})();
        }
      this.assignToStudent(_id);
    }

    handleClick=(e, index, item)=>{
        this.uncheckedItems[item._id]=e.target.checked;
        item.checked = !e.target.checked;
        this.setState({readArray:this.state.readArray,writeArray:this.state.writeArray})
    }

    saveSkills=()=>{
        this.uncheckedItems = {};
        this.setState({showLearingModal:false})
    }

    cancelSkills=()=>{
         let obj = this.uncheckedItems;
        this.state.writeArray.map((item,i)=>{
            if(obj[item._id] !== undefined){
                item.checked = obj[item._id];
            }
        })
        this.state.readArray.map((item,i)=>{
            if(obj[item._id] !== undefined){
                item.checked = obj[item._id];
            }
        });
        this.uncheckedItems = {};
        this.setState({readArray:this.state.readArray,writeArray:this.state.writeArray,showLearingModal:false})
    }

    render() {
        let height=window.innerHeight;
        let width =window.innerWidth;
        let mobileWidth='576';
        this.state.writeArray = this.props.skills && this.props.skills.data && this.props.skills.data && this.props.skills.data.data.writing
        this.state.readArray = this.props.skills && this.props.skills.data && this.props.skills.data && this.props.skills.data.data.reading 
        let totalArray=[]
        if(this.state.writeArray!==undefined && this.state.writeArray!==undefined ){
            totalArray = [...this.state.readArray, ...this.state.writeArray]     
        }

        return (
            <div>
                <Col md={12} sm={12} className="add-group-header">
                    <div className="add-group">
                        ASSIGN
                    </div>
                </Col>
                <div>
                    <Col md={3} sm={3}/>
                    <Col md={6} sm={6} className="inner-add-group-content">
                      <div id="joyride-selector-assign-date">
                        <div style={{display: "inine-block",marginBottom: 10}}>
                            ASSIGN DATE <span style={{color:"orange"}}>*</span>
                        </div>
                        <div>
                            <span className="assign-calendar icon-icon-cal"/>
                            <input
                                type="text" value={this.state.assignDate.toLocaleDateString('en-US')}
                                onFocus={()=>{this.datePickerShouldPauseJoyride();clearTimeout(clearDatePicker);this.setState({showDatePicker: true, showDatePickerMobile: true})}}
                                onBlur={()=>{clearDatePicker=setTimeout(()=>{this.setState({showDatePicker: false})},3000)}}
                                className={this.state.assignDateValid === ""?"group-name search-by-student":"add-user-form add-user-form-error"}
                                />
                        </div>
                        {this.state.assignDateValid !== "" &&
                        <div className="add-group-error">
                            {this.state.assignDateValid}
                        </div>
                        }
                        {this.state.assignError!='' &&
                        <div className="add-group-error">
                            {this.state.assignError}
                        </div>
                        }
                        {this.state.showDatePicker &&
                            <Datetime className="hidden-xs" open={this.state.showDatePicker} onChange={this.handleDate}/>
                        }
                        {this.state.showDatePickerMobile &&
                          <div className="hidden-sm hidden-md hidden-lg mobile-datetime-wrapper">
                            <div className="datetime-close-x"
                                 style={{marginTop: '8px'}}
                                 onClick={()=>this.setState({showDatePickerMobile: !this.state.showDatePickerMobile})}>
                              X
                            </div>
                            <Datetime className="mobile-datetime" open={this.state.showDatePickerMobile} onChange={this.handleDate}/>
                          </div>
                        }
                      </div>
                        <Row>
                            <Col xs={1} md={2} sm={1}/>
                            <Col md={8} sm={10}>
                                <div style={{textAlign: "center",marginBottom: 25}}>
                                    <button id="joyride-select-group-btn" onClick={()=>{this.setState({selectedTab: "STUDENTS"})}}
                                            className={this.state.selectedTab === "STUDENTS"?"assign-button-students-selected":"assign-button-students"}>
                                        STUDENTS
                                    </button>
                                    <button onClick={()=>{this.setState({selectedTab: "GROUPS"})}}
                                            className={this.state.selectedTab === "GROUPS"?"assign-button-groups-selected":"assign-button-groups"}>
                                        GROUPS
                                    </button>
                                </div>
                            </Col>
                            <Col md={1} sm={1}/>
                        </Row>
                        <div style={{display: "inine-block",marginBottom: 10}}>
                            CHOOSE STUDENT/GROUP
                        </div>
                        <div id="joyride-selector-search-by-student">
                            <span className="search-student icon-icon-search"/>
                            <input type="text"  placeholder="Search for Student or Group" onChange={this.changeFunction} value={this.state.searchTerm} className="search-by-student" />
                        </div>
                        {!this.props.users.page &&
                        <div style={{fontFamily: "ProximaNova",color:"#9B9B9B",fontSize: 15,fontWeight: 600}}>
                            There are no students that meet that search criteria.
                        </div>
                        }
                        {this.state.selectedTab === "STUDENTS" &&
                         <InfiniteScroll height={this.props.location.state.libraryItemType==="Book"?height-435+'px':height-385+'px'}
                                         next={()=>this.props.getUsersAction("Student",this.state.searchTerm,0,this.props.users.page +1,1,this.props.location.state.libraryItemType, this.props.location.state.libraryItemId)}
                                         hasMore={this.props.users.hasMore["Student"]}
                                         loader={<Row>Loading...</Row>}
                                         dataLength={this.props?.users?.data?.Student?.results?.length ?? 0}
                        >
                            {this.props?.users?.data?.Student?.results?.length && this.props.users.data.Student.results.map((student,i)=>
                                <Row key={student._id} style={{overflowX:'hidden'}}>
                                    <span>
                                        <i className="icon-icon-user my-class-add-group-user-icon" />
                                    </span>
                                    {i!=0 && <div className="my-class-add-group-border"/>}
                                    <Col md={8} sm={9} xs={8} >
                                        <Row  className="student-name">
                                            {student.first_name && <span>{student.first_name}</span>}
                                            {student.last_name && <span> {student.last_name}</span>}
                                            {student.assignment_day && <span style={{color:"#009BBD",fontWeight:"bold"}}> - Assigned on {moment(student.assignment_day).format('MM/DD/YYYY')}</span>}
                                        </Row>
                                        <Row style={{marginTop: 3}} className="student-sub-text">
                                            {student.username && student.username !== "" && student.username !== " " &&
                                            <span>USERNAME: {student.username} </span>
                                            }
                                            <br className="visible-xs visible-sm visible-md"/>
                                            {student.user_id_number && student.user_id_number !== "" && student.user_id_number !== " " &&
                                                <span className="hidden-xs hidden-sm hidden-md">&nbsp;&#8226;&nbsp;</span>
                                            }
                                            {student.user_id_number && student.user_id_number !== "" && student.user_id_number !== " " &&
                                                <span> ID: {student.user_id_number}</span>
                                            }
                                        </Row>
                                    </Col>
                                    <span>
                                        <i onClick={()=>this.handleAssignIconClick(student._id)}
                                            className={ this.state.addStudents.indexOf(student._id)!== -1 ? "remove-student-solid-icon icon-icon-close-circle-solid"
                                            :"add-student-solid-icon icon-icon-add-circle-solid joyride-icon-selector"}/>
                                    </span>
                                </Row>
                            )}
                        </InfiniteScroll>}
                        {this.state.selectedTab === "GROUPS" &&
                         <InfiniteScroll  height={this.props.location.state.libraryItemType==="Book"?height-435+'px':height-385+'px'}
                                          next={()=>this.props.getGroupsAction(this.state.searchTerm)}
                                          hasMore={false}
                                          loader={<Row>Loading...</Row>}
                                          dataLength={this.props?.groups?.data?.length ?? 0}
                        >
                            {this.props?.groups?.data?.length && this.props.groups.data.map((group,j)=>
                            <div key={"groups" + j} style={{overflowX:'hidden'}}>
                                {group && group.users && group.users.length> 0 &&
                                    <Row>
                                        <span>
                                            <i className="icon-icon-user my-class-add-group-user-icon" />
                                        </span>
                                        {j!=0 && <div className="my-class-add-group-border"/>}
                                            <Col  md={8} sm={9} xs={8} >
                                                    <Row  className="student-name">
                                                        {group.name && <span>{group.name}</span>}
                                                    </Row>
                                                    <Row style={{marginTop: 3}} className="student-sub-text">
                                                        {group.users && <span>{group.users.length}&nbsp;Students</span>}
                                                    </Row>
                                            </Col>
                                        <span>
                                            <i onClick={()=>{this.assignToGroup(group._id)}}
                                                className={ this.state.addGroups.indexOf(group._id)!== -1 ? "remove-student-solid-icon icon-icon-close-circle-solid"
                                                :"add-student-solid-icon icon-icon-add-circle-solid"}
                                            />
                                        </span>
                                    </Row>
                                }
                            </div>
                            )}
                        </InfiniteScroll>}
                        { this.props.location.state.libraryItemType==="Book" && 
                            <Row className='panel-learning' 
                                 style={{backgroundColor:totalArray.length===0?'#9B9B9B':'',opacity:totalArray.length===0?'0.4':'',cursor:totalArray.length!==0?'pointer':''}} 
                                 onClick={totalArray.length!==0?this.getSkillDetails:''}>
                                <span className='elearning-icon icon-icon-elearning-circle-solid-1'/>
                                <span className='text-elearning'>Differentiate eLearning</span>
                                <span className="chevron-right-lesson icon-icon-chevron-right"/>
                            </Row>  
                        }  
                    </Col>
                    <Col md={3} sm={3}/>
                </div>
                <Modal
                    {...this.props}
                    show={this.state.showLearingModal}
                    onHide={this.hideModal}
                    dialogClassName="custom-modal"
                    >
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-lg">Differentiate eLearning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg={6} xs={12}>
                                <h3>Reading</h3>
                                <CheckboxItem  handleClick={this.handleClick} data = {this.state.readArray} />
                            </Col>
                            <Col lg={6} xs={12}>
                                <h3>Writing</h3>
                                <CheckboxItem handleClick={this.handleClick} data = {this.state.writeArray} />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.cancelSkills} style={{float:'left'}}>Close</Button>
                        <Button onClick={this.saveSkills} style={{backgroundColor:'#46C56D',color:"#FFFFFF"}}>Save</Button>
                    </Modal.Footer>
                </Modal>
                <div className="add-group-footer">
                    {/* <JoyrideTourModal isAssignTourReady={true} hideTourModalJoyride={this.props.clearTourModal} showTourModal={this.state.showTourModal} hideTourModal={this.hideTourModal}/> */}
                    <button onClick={browserHistory.goBack} className="cancel-add-group">
                        CANCEL
                    </button>
                    <button onClick={()=>{this.props.stopJoyride();this.postAssignment()}} className="add-add-group">
                        ASSIGN
                    </button>
                </div>
            </div>
        );
    }
}



const mapStateToProps = (state) => {
    return ( {
        users : state.Users,
        groups : state.Groups,
        assignmentItem: state.AssignmentItem,
        isJoyrideRunning: state.JoyrideReducer.joyrideShouldRun,
        showTourModal: state.JoyrideReducer.displayTourModal,
        skills:state.ElearningSkills
    });
};

export default connect(mapStateToProps, actions)(Assign)
