import React, {Component} from 'react'
import InformationBar from  './InformationBar'
import {Row, Col, Grid} from 'react-bootstrap'
import '../../assets/styles/student.scss'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import {withRouter, browserHistory} from 'react-router'
import authAPI from '../../api/authAPI'
import constants from '../../constants'
import { confirmAlert } from 'react-confirm-alert';
import Datetime from 'react-datetime'
import AssistantTooltipComponent from "../JoyrideCustomComponent/AssistantTooltipComponent";
import StudentTeacherList from "./StudentTeacherList";
import StudentTodoList from "./StudentTodoList";
import Layout from "../Shared/Layout";
import StudentEditSidebar from "./StudentEditSidebar";
import DropdownLabelDatePicker from "../Shared/DropdownLabelDatePicker";
import AspectRatioComponent from "../Shared/AspectRatioComponent";

const styleObjectWithExitBtn = {
  mainColor: '#CACACA',
  width: 600,
  height: '100px',
  overflow: 'wrap',
  borderRadius: 4,
  backgroundColor: 'white',
  beacon: {
    inner: '#a350f0',
    outer: '#a350f0',
  },
  close: {
    display: 'none'
  },
  button: {
    display: 'none'
  },
  skip: {
    marginTop: 20,
    borderRadius: 8,
    backgroundColor: '#009BBD',
    color: 'white'
  },
  back: {
    display: 'none'
  }
};
const styleObjectWithNextBtn = {
  mainColor: '#CACACA',
  width: 600,
  height: '100px',
  overflow: 'wrap',
  borderRadius: 4,
  backgroundColor: 'white',
  beacon: {
    inner: '#a350f0',
    outer: '#a350f0',
  },
  close: {
    display: 'none'
  },
  button: {
    color: '#333333',
    marginTop: '20px',
    backgroundColor: 'white'
  },
  skip: {
    marginTop: 20
  },
  back: {
    display: 'none'
  }
};

class StudentHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: "TO-DO",
            collapseDateList: [],
            startDate: this.stripTimeFromDate(new Date()),
            teacherIds: [],
            selectedTeacherIds: [],
            isSidebarOpen: false,
            showDatePicker: false
        };
        this.loadDone = this.loadDone.bind(this);
        this.loadTodo = this.loadTodo.bind(this);
        this.checkSession = this.checkSession.bind(this);
        this.fetchAssignments = this.fetchAssignments.bind(this);
        this.collapseAssignments = this.collapseAssignments.bind(this);
        this.getTeachers = this.getTeachers.bind(this);
        this.onSelectedTeachersChanged = this.onSelectedTeachersChanged.bind(this);
        this.completeAssignment = this.completeAssignment.bind(this);
        this.toggleDatePicker = this.toggleDatePicker.bind(this);
        if(props.tour) {
            this.props.allowClicksThruHere(false);
            setTimeout(() => {
                this.props.resetJoyride([
                    {
                        title: "Student View Overview",
                        text: <AssistantTooltipComponent
                            imgWidth={100}
                            imgHeight={130}
                            tooltipText={`<p>
                                          This is the student portal or student home page. The Fundamentals Unlimited student portal provides your students with unlimited, web-based access to any and all resources or tasks you may assign, as well as your personal Fundamentals Unlimited classroom library of eBooks and digital texts.
                                          </p>`}/>,
                        selector: '#info-bar',
                        position: 'bottom-left',
                        style: {
                            ...styleObjectWithNextBtn,
                            arrow: {
                                left: '20%'
                            },
                            width: '516px'
                        }
                    },
                    {
                        title: "To-Do List",
                        text: <AssistantTooltipComponent
                            imgWidth={100}
                            imgHeight={130}
                            tooltipText={`
                                <p>The <strong>To-Do</strong> tab displays all assignments in date order, from oldest to newest.</p>
                                <p>Assignments may include:</p>
                                    <ul>
                                        <li>
                                            Resources, such as graphic organizers or reading responses
                                        </li>
                                        <li>
                                            Assessments, such as multiple-choice quizzes or written constructed responses
                                        </li>
                                        <li>
                                            eBooks or digital texts you would like your students to read independently or at home
                                        </li>
                                    </ul>
                                <p>Upon completion, assignments are moved to the <strong>Done</strong> tab.</p>
                              `}/>,
                        selector: '#student-todo-button-group',
                        position: 'right',
                        style: {
                            ...styleObjectWithNextBtn,
                            width: '683px'
                        }
                    },
                    {
                        title: "Assignment Details",
                        text: <AssistantTooltipComponent
                            imgWidth={100}
                            imgHeight={130}
                            tooltipText={`
                                  <p>
                                    Students can easily and quickly identify the type of assignment, the title of the assignment, and the teacher who made the assignment (if the student has more than one teacher).
                                  </p>
                                  <p>
                                    Students may open and view assignments by clicking or tapping anywhere within the rectangle.
                                  </p>`}/>,
                        selector: '.assignment-item',
                        position: 'right',
                        style: {
                            ...styleObjectWithNextBtn,
                            width:'466px'
                        }
                    },
                    {
                        title: "Completing an Assignment",
                        text: <AssistantTooltipComponent
                            imgWidth={100}
                            imgHeight={130}
                            tooltipText={`After a student completes an assignment,<br/>
                                          he or she will click the <strong>Done</strong> checkmark.<br/>
                                          The assignment will then move from the<br/>
                                          <strong>To-Do</strong> tab to the <strong>Done</strong> tab.`}/>,
                        selector: '.done',
                        position: 'bottom',
                        style: {
                            ...styleObjectWithNextBtn,
                            width: '430px'
                        }
                    },
                    {
                        title: "Filtering Assignments",
                        text: <AssistantTooltipComponent
                            imgWidth={100}
                            imgHeight={130}
                            tooltipText={`If a student has more than one teacher,<br/>
                                          the student may filter assignments by<br/>
                                          teacher, and only assignments by that<br/>
                                          particular teacher will be displayed on<br/>
                                          the <strong>To-Do</strong> list.`}/>,
                        selector: '.teachers-list',
                        position: 'left',
                        style: {
                            ...styleObjectWithNextBtn,
                        }
                    },
                    {
                        title: "Student's Texts",
                        text: <AssistantTooltipComponent
                            imgWidth={100}
                            imgHeight={130}
                            tooltipText={`
                                  <p>Students may self-select titles from your digital classroom library to create their very own personalized library.</p>
                              `}/>,
                        selector: '.icon-icon-books',
                        position: 'top',
                        style: {
                            ...styleObjectWithNextBtn,
                        }
                    },

                    {
                        title: "Student's Library",
                        text: <AssistantTooltipComponent
                            imgWidth={100}
                            imgHeight={130}
                            tooltipText={`
                              <p>Students may browse and access your digital classroom library by clicking the <strong>Library</strong> icon. Your Fundamentals Unlimited license affords your students unlimited, simultaneous access to your digital classroom library both in school and at home, anywhere your students can access the Internet.</p>

                              <p>Fundamentals Unlimited empowers teachers to create and build their very own personalized digital classroom libraries.  You may search from Fundamentals Unlimited’s extensive eBook database of over 3,000 quality texts to select books
                              that best meet your students’ needs and interests.</p>

                              <p>There is no limit to the number of books you may add to your library! The Fundamentals Unlimited eBook database is searchable by interest level, guided reading level, Lexile level, genre, topic, theme, and language (Spanish and English).</p>
                              `}/>,
                        selector: '.icon-icon-browse',
                        position: 'top',
                        style: {
                            ...styleObjectWithNextBtn,
                        }
                    },
                    {
                        title: "End of Tour",
                        text: <AssistantTooltipComponent
                            imgWidth={100}
                            imgHeight={130}
                            tooltipText={
                              // `
                              // <p>Fundamentals Unlimited provides a messaging system for you to communicate with your students.  Message notifications will be indicated here.</p>
                              // <p>Examples of messages you may send include:</p>
                              // <ul>
                              // 	<li>Reminders: “Hello, don’t forget to complete the multiple-choice quiz. It is due next Friday.”</li>
                              // 	<li>Directions or Instructions: “After you read the eBook, complete the constructed response.”</li>
                              // 	<li>Congratulations or Positive Reinforcement: “Terrific job on your homework!”</li>
                              // </ul>
                              `<p>That's it for this tour! I certainly hope it was helpful and informative. Tours can be revisited and accessed at any time by clicking the <strong>Help</strong> icon in the lower right-hand corner of the screen.</p>
                              `}/>,
                        selector: '#footer',
                        position: 'top-right',
                        style: {
                            ...styleObjectWithExitBtn,
                            arrow: {
                              display: 'none'
                            }
                        },
                        isFixed: true
                    },
                ], 0);
            }, 500);
        }
    }

    stripTimeFromDate(d) {
        return new Date(d.getFullYear(), d.getMonth(), d.getDate());
    }

    componentDidMount() {
        this.fetchAssignments();
        if(!this.isJoyrideRunning()) {
            this.props.getUserDetailsByIdAction(this.props.user_id);
        }
    }

    checkSession(){
        let uuid = localStorage.getItem("uuid");
        console.log("checksession uuid now:"+uuid);
        let isActiveSession = true;
        let userException = ["dejital1@gmail.com","ladiadavies@gmail.com"];
        authAPI.validateUserSessionByUUID(uuid).then((resp)=>{
            console.log("resp received");
            console.log(resp);
            isActiveSession = resp[0].active;
            console.log("in student home");
            if(isActiveSession == false && !userException.includes(localStorage.getItem("username"))){
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                        <div className='custom-ui'>
                            <h1>User session has ended. You have logged in through another device.</h1>
                            <button
                            onClick={() => {
                                document.cookie = "sessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    
                                localStorage.clear();
                                browserHistory.push(constants.PATHS.ROOT);
                    
                                setTimeout(function(){window.location.reload()},100);
                            }}
                            >
                            Continue
                            </button>               
                        </div>
                        );
                    }
                });
            }
        });
    }

    collapseAssignments=(date)=>{
        date = new Date(date).toDateString();
        let idx= this.state.collapseDateList.indexOf(date);
        if(idx === -1){
          this.state.collapseDateList.push(date);
          this.setState({collapseDateList: this.state.collapseDateList});
        }else{
          this.state.collapseDateList.splice(idx,1);
          this.setState({collapseDateList: this.state.collapseDateList});
        }
    };

    isDoneSelected() {
        return this.state.selectedTab === "DONE";
    }

    isDueAssignmentsSelected() {
        return this.state.selectedTab === "PAST-DUE-ASSIGNMENT";
    }

    fetchAssignments() {
        if(!this.isJoyrideRunning()) {
            this.props.getUserAssignmentsAction(this.props.user_id, 1, this.isDoneSelected(), this.isDueAssignmentsSelected(), this.state.startDate, this.state.selectedTeacherIds, 0);
        }
    }

    getTourData() {
        if(this.isJoyrideRunning()) {
            return {
                "data": {
                    "results": [{
                        "_id": 9,
                        "day": new Date((new Date()).getTime() + 10000),
                        "item_type_id": 3,
                        "item_id": 3927,
                        "item_type": "Resource",
                        "count": "2",
                        "teacher": "DeLuca",
                        "name": "My Thoughts"
                    }, {
                        "_id": 1,
                        "day":new Date((new Date()).getTime() + 100000000),
                        "item_type_id": 2,
                        "item_id": 856,
                        "item_type": "Book",
                        "count": "2",
                        "teacher": "DeLuca",
                        "name": "A Baseball Kind of Day "
                    }],
                    "previousPage": -1,
                    "currentPage": 1,
                    "nextPage": -1,
                    "total_pages": 1,
                    "total": 2
                },
                "page": 1,
                "hasMore": false,
                "asyncStatus": "SUCCESS"
            };
        } else {
            return this.props.assignments;
        }
    }

    handleDate=(date)=>{
        this.checkSession();
        this.setState({
            startDate: date._d,
            showDatePicker: false}, () => {
                this.fetchAssignments();
            });
    };


    toggleDatePicker(){
        this.setState({showDatePicker: !this.state.showDatePicker})
    }

    loadTodo=()=>{
        this.checkSession();
        this.setState({selectedTab: "TO-DO"}, () => {
            this.fetchAssignments();
        });
    };

    loadDone=()=>{
        this.checkSession();
        this.setState({selectedTab: "DONE"}, () => {
            this.fetchAssignments();
        });
    };

    loadDueAssignment=()=>{
        this.checkSession();
        this.setState({selectedTab: "PAST-DUE-ASSIGNMENT"}, () => {
            this.fetchAssignments();
        });
    };

    onSelectedTeachersChanged(selected_teacher_ids) {
        // If no teachers are selected, select -1, which will return empty.
        if(selected_teacher_ids.length === 0) {
            selected_teacher_ids.push(-1);
        }
        this.setState({selectedTeacherIds: selected_teacher_ids}, () => {this.fetchAssignments()});
    }

    renderDay =( props, currentDate, selectedDate )=>{
       if(selectedDate && currentDate.date() === selectedDate.date()  && selectedDate.date() === this.state.startDate && currentDate._d.getMonth() === this.state.startDate.getMonth()){
           return <td {...props} style={{backgroundColor: "#009BBD",color: "#FFFFFF"}}>{ currentDate.date() }</td>;
       }
       else{
           return <td {...props} >{ currentDate.date() }</td>;
       }
    };

    getTeachers() {
        if(this.isJoyrideRunning()) {
            return [{
                _id: 2,
                created_at: "2017-08-01T19:39:06.615Z",
                created_by: null,
                deleted: false,
                district_id: 2,
                edited_at: "2017-09-13T14:03:30.713Z",
                edited_by: null,
                first_login: null,
                first_name:"Anthony",
                full_name: null,
                grade_type_ids: Array[1],
                is_active: true,
                last_name: "DeLuca",
                library_id: 1,
                password_type_id: 1,
                school_ids: null,
                user_id_number: null,
                user_type: { value: "System Admin"},
                user_type_id: 1,
                username:"adeluca@ips-yes.com"
            }]
        } else {
            return this.props.getUserDetails.data.teachers;
        }
    }

    isTeacherListRendered() {
        return this.isJoyrideRunning()? true :
            this.props.getUserDetails.asyncStatus === "SUCCESS" &&
            this.props.getUserDetails.data &&
            this.props.getUserDetails.data.teachers;
    }

    isJoyrideRunning() {
        return this.props.tour;
    }

    completeAssignment(asst_id,is_complete) {
        if(!this.isJoyrideRunning() && localStorage.user_type === 'Student') {
            return this.props.completeAssignmentAction(asst_id, is_complete);
        } else {
            return null;
        }
    }

    render() {

        const header = (
            <InformationBar
                name={this.props.name}
                user_id={this.props.user_id}
                infoClickAction={() => {this.setState({isSidebarOpen: !this.state.isSidebarOpen})}}
                isJoyrideRunning={this.isJoyrideRunning()}
            />
        );

        const datePicker = (
            <AspectRatioComponent width-unit="1" height-unit="1" min-height='210'>
                <Datetime
                    value={this.state.startDate}
                    renderDay={this.renderDay}
                    open={true}
                    onChange={this.handleDate}
                />
            </AspectRatioComponent>
        );

        const main = (
                <Row id="studentHome">
                    <Col xs={12} sm={12} md={8} lg={8}>
                        <div id="nav-buttons" style={{marginBottom: '1em'}}>
                            <StudentTodoList
                                assignments={this.isJoyrideRunning()? this.getTourData(): this.props.assignments}
                                selectedTab={this.state.selectedTab}
                                refresh={this.fetchAssignments}
                                collapseDateList={this.state.collapseDateList}
                                onTodoSelected={this.loadTodo}
                                onDoneSelected={this.loadDone}
                                onDueAssignmentSelected={this.loadDueAssignment}
                                fetchAssignments={this.fetchAssignments}
                                collapseAssignments={this.collapseAssignments}
                                completeAssignmentAction={this.completeAssignment}
                                deleteAssignment={this.props.deleteUserAssignmentAction}
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4}>
                        <Row style={{paddingTop: '4.5em'}}>

                            {/* MD LG Date Picker */}
                            <Col xsHidden smHidden md={12} lg={12}>
                                {datePicker}
                            </Col>

                            {/*SM Date picker*/}
                            <Col xsHidden sm={7} mdHidden lgHidden>
                                {datePicker}
                            </Col>

                            {/*XS Date picker*/}
                            <Col xs={12} smHidden mdHidden lgHidden>
                                <div id="studentDropdownDatepicker">
                                    <DropdownLabelDatePicker
                                        showDay={true}
                                        toggleDatePicker={this.toggleDatePicker}
                                        showDatePicker={this.state.showDatePicker}
                                        handleDate={this.handleDate}
                                        displayShortMonth={true}
                                        date={this.state.startDate}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} sm={5} md={12} lg={12}>
                                {this.isTeacherListRendered() &&
                                    <StudentTeacherList
                                        id="teacherList"
                                        teachers={this.getTeachers()}
                                        onSelectedTeachersChanged={this.onSelectedTeachersChanged}
                                    />
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>

        );

        const sidebar =
            this.props.getUserDetails.data? (<StudentEditSidebar
                user_data={this.props.getUserDetails.data}
                resetPasswordAction={this.props.resetUserPasswordAction}
            />): null;

        return (
           <Layout
               header={header}
               main={main}
               style={{height: '100%'}}
               sidebar={sidebar}
               isGlobalSearch={true}
               isSidebarOpen={this.state.isSidebarOpen}
           />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        myDetails: state.MyDetails,
        assignments:  state.Assignments,
        getUserDetails: state.GetUserDetails,
        joyrideShouldRun: state.JoyrideReducer.joyrideShouldRun,
        joyrideStepIndex: state.JoyrideReducer.joyrideStepIndex,
        joyrideIntendsToStart: state.JoyrideReducer.joyrideIntendsToStart
    }
};

export default connect(mapStateToProps, actions)(withRouter(StudentHome))
