const initialState = "";

const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':

            // alert("I'm session Id"+ getCookie("sessionId"));
            return action.payload;
        case 'LOGIN_FAILED':
            return action.payload;
        default:
            return state;
    }
};

export default LoginReducer;

// eslint-disable-next-line
function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    alert(ca[0]);
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
