import React,{Component} from  'react'
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import BrowsePage from "../shared/BrowsePage"
import LessonsContent from './LessonsContent';

export class Lessons extends Component{

    render(){
        return(
            <BrowsePage
                name="Lessons"
                typeOfResource="lessons"
                strandValue={this.props.params.strand}
            >
                {/*<LessonsContent/>*/}
                {this.props.children}
            </BrowsePage>
        );
    }
}

export default connect(null, actions)(Lessons)