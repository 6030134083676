import {
    GET_USERS_REQUEST,
    GET_USERS_SUCCESS,
    GET_USERS_FAILURE
} from '../actions';



const initialState = {
    data: [],
    page:1,
    hasMore: {
        "System+Admin": true,
// Changed Teacher to Unlimited
        "Unlimited": true,
        "Student": true,
        "combinedList": true,
        "admin": true,
    }
};

const UsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS_REQUEST:
            return Object.assign({},state, {asyncStatus:  'PENDING'});
        case GET_USERS_SUCCESS:
            if(action.payload.length === 0){
                state.hasMore[action.userType] = false;
                state.data[action.userType] = {};
                return Object.assign({},state,
                    {asyncStatus:  'SUCCESS',
                        data: state.data,
                        hasMore: state.hasMore,
                        page: 0
                    });
            }
            if(action.search !== "" && action.flag === 0){
                //state = initialState;
                state.data[action.userType].results = [];
                state.hasMore[action.userType] = true;
                state = {
                    data: state.data,
                    page:1,
                    hasMore: state.hasMore
                };
            }
            if(action.flag === 0 && state.data !== [] && state.data[action.userType]) {
                state.data[action.userType].results = [];
                state.hasMore[action.userType] = true;
                state = {
                    data: state.data,
                    page:1,
                    hasMore: state.hasMore
                };
            }
            const newUsers = action.payload || [];
            let newData = newUsers;
            if(state.data[action.userType]){
                newData["results"] = [...state.data[action.userType].results,...newUsers.results]

            }
            let newPage = action.payload.currentPage;
                    state.hasMore[action.userType] = action.payload.nextPage !== -1;
                    if(action.payload.nextPage === -1){
                      newPage = 1;
                    }

            console.log("actin.payload",action.payload);
            return Object.assign({},state,
                {asyncStatus:  'SUCCESS',
                    data: {...state.data,[action.userType]: newData},
                hasMore: state.hasMore,
                page: newPage
                });
        case GET_USERS_FAILURE:
            return Object.assign({},state, {asyncStatus:  'FAILURE', data: action.payload});
        default:
            return state;
    }
};

export default UsersReducer;
