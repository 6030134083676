import React, { Component } from "react";
import authAPI from "../../api/authAPI";
import remove from "../../assets/icons/SVG/delete.svg";
import googleClassroomIcon from "../../assets/icons/SVG/Google-Classroom.svg";
import pdfIcon from "../../assets/icons/SVG/pdf.svg";
import { browserHistory, withRouter } from "react-router";
import constants from "../../constants";
import addToFolder from "../../assets/icons/SVG/add-to-folder.svg";
import addToLibrary from "../../assets/icons/SVG/icon-library.svg";
import { Grid, Col, Row, Image } from "react-bootstrap";
import "../../assets/styles/mobile.scss";
import brokenImage from "../../assets/images/notFound.webp";
import AddToFolderModal from "../../components/AddToFolder/AddToFolderModal";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import {
  DeleteConfirmationModal,
  CheckBox,
  SnackBarModule,
} from "../../components/Shared";
import {
  getDescription,
  isDescriptionTooLong,
} from "../../helpers/itemDescription";
import { markdownHelper } from "../../markdown/MarkdownHelper";
import ReadMore from "../../components/ReadMore";

class ResponsiveResourcesNew extends Component {
  state = {
    showFullText: false,
    isHoveringFirst: false,
    isHoveringSecond: false,
    isHovering2First: false,
    isHovering2Second: false,
    isAddToFolderModalOpen: false,
    addToLibrarySuccess: false,
    isErrorOpen: false,
    removeFromLibrarySuccess: false,
    removeFromLibraryError: false,
    addToFolderSuccess: false,
    addToFolderError: false,
    deleteFromFolderSuccess: false,
    deleteFromFolderError: false,
    isDeleteModalOpen: false,
    isDeleteModalOpenFolder: false,
    addToFolderError: false,
    addToFolderErrorMsg: "",
  };

  handleModalOpen = () => {
    this.setState({ isAddToFolderModalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ isAddToFolderModalOpen: false });
  };

  handleAddToLibrary = (id) => {
    if (this.props?.resourceType === "Book") {
      this.props
        .postAddBookToLibraryAction(id)
        .then((res) => {
          if (res.statusCode === 200) {
            this.setState({ addToLibrarySuccess: true });
            setTimeout(() => {
              this.setState({ addToLibrarySuccess: false });
            }, 3000);
          } else {
            this.setState({ isErrorOpen: true });
            setTimeout(() => {
              this.setState({ isErrorOpen: false });
            }, 3000);
          }
        })
        .catch(() => {
          this.setState({ isErrorOpen: true });
          setTimeout(() => {
            this.setState({ isErrorOpen: false });
          }, 3000);
        });
    } else {
      this.props
        .postAddTeachingResourceToLibraryAction(id)
        .then((res) => {
          if (res.statusCode === 200) {
            this.setState({ addToLibrarySuccess: true });
            setTimeout(() => {
              this.setState({ addToLibrarySuccess: false });
            }, 3000);
          } else {
            this.setState({ isErrorOpen: true });
            setTimeout(() => {
              this.setState({ isErrorOpen: false });
            }, 3000);
          }
        })
        .catch(() => {
          this.setState({ isErrorOpen: true });
          setTimeout(() => {
            this.setState({ isErrorOpen: false });
          }, 3000);
        });
    }
  };

  removeFromLibrary = () => {
    const item = constants.normalizeTypeName(
      this.props.item.type || this.props.type
    );
    const id = this.props.item?._id;
    const strand = this.props?.strand_number;
    authAPI
      .removeLibraryItem(item === "library" ? "books" : item, id)
      .then((response) => {
        if (response.status === 200) {
          if (strand) {
            this.setState({ removeFromLibrarySuccess: true });
            setTimeout(() => {
              this.setState({ removeFromLibrarySuccess: false });
            }, 3000);
            this.props.getCustomLibraryFiltersAndDecrementAction(
              strand,
              item === "library" ? "books" : "teachingResources",
              this.props.query
            );
          } else {
            this.setState({ removeFromLibrarySuccess: true });
            setTimeout(() => {
              this.setState({ removeFromLibrarySuccess: false });
            }, 3000);
            this.props.getMyLibraryAction(
              constants.normalizeTypeName(
                item === "library" ? "books" : "teachingResources"
              )
            );
          }
        } else {
          this.setState({ removeFromLibraryError: true });
          setTimeout(() => {
            this.setState({ removeFromLibraryError: false });
          }, 3000);
        }
      })
      .catch((error) => {
        console.log("Error when removing from library");
      });
  };

  // This component is used in four!? places but it shouldn't be.
  // It's rendered by LibraryHomeContent, LibraryResourcesContent, LibrarySingleFolderContent, NewBooksComponent.
  // When in LibraryHomeContent it uses below method via this.props.removeRecents otherwise it uses the above.
  // Why it's used by two components and can it be fixed?
  removeRecentsFromLibrary = () => {
    const item = constants.normalizeTypeName(
      this.props.item.type || this.props.type
    );
    const id = this.props.item?._id;
    authAPI
      .removeLibraryItem(item, id)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ removeFromLibrarySuccess: true });
          setTimeout(() => {
            this.setState({ removeFromLibrarySuccess: false });
          }, 3000);
          this.props.getMyLibraryAction("recents");
        } else {
          this.setState({ removeFromLibraryError: true });
          setTimeout(() => {
            this.setState({ removeFromLibraryError: false });
          }, 3000);
        }
      })
      .catch((error) => {
        console.log("Error when removing from library");
      });
  };

  removeFromFolder = () => {
    const item = this.props.item;
    this.props
      .removeItemFromFolder(item, this.props?.folderId)
      .then((response) => {
        this.handleSuccessDeleteFromFolderSnackBar();
        this.handleDeleteModalCloseFolder();
        setTimeout(() => {
          this.props.refreshResources();
        }, 3000);
      })
      .catch(function (error) {
        console.log("Error when removing from library");
        this.handleErrorDeleteFromFolderSnackBar();
        this.handleDeleteModalCloseFolder();
      });
  };

  handleSuccessSnackBar = () => {
    this.setState({ addToFolderSuccess: true });
    setTimeout(() => {
      this.setState({ addToFolderSuccess: false });
    }, 3000);
  };

  handleErrorSnackBar = (msg) => {
    this.setState({
      addToFolderError: true,
      addToFolderErrorMsg: msg,
    });
    setTimeout(() => {
      this.setState({ addToFolderError: false });
    }, 3000);
  };

  handleSuccessDeleteFromFolderSnackBar = () => {
    this.setState({ deleteFromFolderSuccess: true });
    setTimeout(() => {
      this.setState({ deleteFromFolderSuccess: false });
    }, 3000);
  };

  handleErrorDeleteFromFolderSnackBar = () => {
    this.setState({ deleteFromFolderError: true });
    setTimeout(() => {
      this.setState({ deleteFromFolderError: false });
    }, 3000);
  };

  handleDeleteModalOpen = () => {
    this.setState({ isDeleteModalOpen: true });
  };

  handleDeleteModalClose = () => {
    this.setState({ isDeleteModalOpen: false });
  };

  handleDeleteModalOpenFolder = () => {
    this.setState({ isDeleteModalOpenFolder: true });
  };

  handleDeleteModalCloseFolder = () => {
    this.setState({ isDeleteModalOpenFolder: false });
  };
  pdfopener = (item) => {
    browserHistory.push(constants.filesUrl + item?.file?.replace("../", ""));
  };

  handleSelect = () => {
    const item = this.props.item;
    this.props.multiSelectMode
      ? this.props.handleMultiSelect(item)
      : window.location.pathname.includes("folders") ||
        window.location.pathname.includes("library")
      ? this.props.handleClickForResourcesThumbnail(item)
      : this.props.handleSelectResourceClick(item);
  };

  isBookPrintable = () => {
    const item = this.props.item;
    if (item.type === "Book" || this.props.resourceType === "Book") {
      return item.printable;
    } else {
      return true;
    }
  };

  isNotUnit = () => {
    const item = this.props.item;
    return (
      item.type !== "units" &&
      item.type !== "Unit" &&
      this.props.resourceType !== "Unit"
    );
  };

  render() {
    let item = this.props.item;
    let i = this.props.i;

    let handleMoreClick = (e) => {
      e.stopPropagation();
      this.setState({ showFullText: !this.state.showFullText });
    };

    const handleMouseEnterFirst = () => {
      this.setState({ isHoveringFirst: true });
    };

    const handleMouseLeaveFirst = () => {
      this.setState({ isHoveringFirst: false });
    };

    const handleMouseEnterSecond = () => {
      this.setState({ isHoveringSecond: true });
    };

    const handleMouseLeaveSecond = () => {
      this.setState({ isHoveringSecond: false });
    };

    const handleMouse2EnterFirst = () => {
      this.setState({ isHovering2First: true });
    };

    const handleMouse2LeaveFirst = () => {
      this.setState({ isHovering2First: false });
    };

    const handleMouse2EnterSecond = () => {
      this.setState({ isHovering2Second: true });
    };

    const handleMouse2LeaveSecond = () => {
      this.setState({ isHovering2Second: false });
    };

    let isBookType = this.props.location.pathname.includes("books");
    const bookSharePrefix =
      "https://classroom.google.com/share?url=" +
      window.location.hostname +
      "/books/";
    return (
      <>
        <div
          className="hover-cursor-pointer"
          key={`${item.type}-${i}`}
          onClick={this.handleSelect}
        >
          {/*Desktop component*/}
          <Col
            xsHidden
            smHidden
            className={
              this.props.multiSelectMode &&
              !this.props.selectedItems.some((e) => e.item_id === item._id)
                ? "new-resources-single-item-container-grayed"
                : this.props.active
                ? "new-resources-single-item-container-active"
                : "new-resources-single-item-container"
            }
          >
            <div className={"new-single-item-left"}>
              <div className={"flex-row"}>
                <div className={"new-single-item-image"}>
                  <img
                    className="resource-product"
                    onError={(event) => (event.target.src = brokenImage)}
                    src={
                      constants.filesUrl +
                      (item?.image?.includes("../")
                        ? item.image.replace(/\.\./g, "").replace("//", "/")
                        : item.image || "")
                    }
                    alt=""
                  />
                </div>
                <div>
                  <h3 className={"new-single-item-title"}>
                    {item?.name ?? "No Title"}
                  </h3>
                </div>
              </div>
              <div
                className={
                  item.type === "teaching_resource" || item.type === "Resource"
                    ? "color-container green"
                    : item.type === "units" || item.type === "Unit"
                    ? "color-container blue"
                    : item.type === "lessons" || item.type === "Lesson"
                    ? "color-container tirquise"
                    : item.type === "books" ||
                      item.type === "Book" ||
                      isBookType
                    ? "color-container orange"
                    : item.type === "assessments" || item.type === "Assessment"
                    ? "color-container pink"
                    : "color-container purple"
                }
              >
                <div
                  className="icons-left-container"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {this.props.multiSelectMode ? (
                    <CheckBox
                      key={this.props.selectedItems}
                      id={item?._id}
                      isChecked={this.props.selectedItems.some(
                        (e) => e.item_id === item?._id
                      )}
                      onChange={this.handleSelect}
                    />
                  ) : (
                    <>
                      {this.isNotUnit() &&
                        item.file?.includes("pdf") &&
                        this.isBookPrintable() &&
                        item.type !== "Book" &&
                        item.type !== "books" &&
                        !isBookType && (
                          <span
                            style={{
                              backgroundColor: this.state.isHovering2First
                                ? "#FFFFFF80"
                                : "",
                            }}
                            onMouseEnter={handleMouse2EnterFirst}
                            onMouseLeave={handleMouse2LeaveFirst}
                          >
                            <a
                              href={
                                constants.filesUrl +
                                (item?.file.includes(".thumb.jpg")
                                  ? item.file.replace(".thumb.jpg", "")
                                  : item?.file?.replace("../", ""))
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src={pdfIcon} alt="" />
                            </a>
                          </span>
                        )}
                      {this.isNotUnit() &&
                        item.file?.includes("pdf") &&
                        (item.type === "Book" ||
                          item.type === "books" ||
                          isBookType) &&
                        item.printable && (
                          <span
                            style={{
                              backgroundColor: this.state.isHovering2First
                                ? "#FFFFFF80"
                                : "",
                            }}
                            onMouseEnter={handleMouse2EnterFirst}
                            onMouseLeave={handleMouse2LeaveFirst}
                          >
                            <a
                              href={
                                constants.filesUrl +
                                (item?.file.includes(".thumb.jpg")
                                  ? item.file.replace(".thumb.jpg", "")
                                  : item?.file?.replace("../", ""))
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src={pdfIcon} alt="" />
                            </a>
                          </span>
                        )}
                      {item?.showClassroomShareButtonLink && (
                        <span
                          style={{
                            backgroundColor: this.state.isHovering2Second
                              ? "#FFFFFF80"
                              : "",
                          }}
                          onMouseEnter={handleMouse2EnterSecond}
                          onMouseLeave={handleMouse2LeaveSecond}
                        >
                          <a
                            href={
                              !this.props.item?.printable && this.props.bookId
                                ? bookSharePrefix +
                                  this.props.bookId +
                                  "/reader"
                                : item?.showClassroomShareButtonLink
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={googleClassroomIcon} alt="test" />
                          </a>
                        </span>
                      )}
                    </>
                  )}
                </div>
                {this.props.multiSelectMode ||
                localStorage.user_type === "Print Only" ||
                localStorage.is_lite === "true" ? (
                  <></>
                ) : (
                  <div className="icons-right-container">
                    {localStorage.getItem("user_type") != "Student" && (
                      <p
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({ isAddToFolderModalOpen: true });
                        }}
                        style={{
                          backgroundColor: this.state.isHoveringFirst
                            ? "#FFFFFF80"
                            : "",
                        }}
                        onMouseEnter={handleMouseEnterFirst}
                        onMouseLeave={handleMouseLeaveFirst}
                      >
                        <img src={addToFolder} alt="" /> Add To Folder
                      </p>
                    )}
                    {this.props?.type === "library" &&
                    localStorage.getItem("user_type") != "Student" ? (
                      <p
                        style={{
                          backgroundColor: this.state.isHoveringSecond
                            ? "#FFFFFF80"
                            : "",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleDeleteModalOpen();
                        }}
                        onMouseEnter={handleMouseEnterSecond}
                        onMouseLeave={handleMouseLeaveSecond}
                      >
                        <img src={remove} alt="" /> Remove
                      </p>
                    ) : this.props?.isFolder &&
                      localStorage.getItem("user_type") != "Student" ? (
                      <p
                        style={{
                          backgroundColor: this.state.isHoveringSecond
                            ? "#FFFFFF80"
                            : "",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          // this.removeFromFolder(item)
                          this.handleDeleteModalOpenFolder();
                        }}
                        onMouseEnter={handleMouseEnterSecond}
                        onMouseLeave={handleMouseLeaveSecond}
                      >
                        <img src={remove} alt="" /> Remove
                      </p>
                    ) : (
                      <p
                        style={{
                          backgroundColor: this.state.isHoveringSecond
                            ? "#FFFFFF80"
                            : "",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleAddToLibrary(item._id);
                        }}
                        onMouseEnter={handleMouseEnterSecond}
                        onMouseLeave={handleMouseLeaveSecond}
                      >
                        <img src={addToLibrary} alt="" /> Add To Library
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className={"new-single-item-right"}>
              {/* <Collapse in={this.state.showFullText} collapsedSize={65}>
                <p
                  className="new-single-item-right-text"
                  dangerouslySetInnerHTML={{
                    __html: markdownHelper.toHTML(getDescription(item)),
                  }}
                ></p>
              </Collapse> */}
              <ReadMore text={getDescription(item)} maxLength={150} />
              {/* {isDescriptionTooLong(item) && (
                <span onClick={handleMoreClick}>
                  {this.state.showFullText ? "Read Less" : "Read More"}
                </span>
              )} */}
            </div>
          </Col>
          {/*Mobile component*/}
          <Grid className="hidden-md hidden-lg">
            <Col mdHidden lgHidden>
              <Row>
                <Col
                  xs={2}
                  onClick={this.props.handleClickForResourcesThumbnail.bind(
                    this,
                    item
                  )}
                  className="img-2-col"
                  style={{ boxShadow: "0px 0px 2px 2px #f5f5f5" }}
                >
                  <Image
                    responsive
                    src={`${constants.filesUrl}${
                      item?.image?.replace("../", "") || ""
                    }`}
                  />
                </Col>
                <Col
                  xs={8}
                  onClick={this.props.handleClickForResourcesThumbnail.bind(
                    this,
                    item
                  )}
                  className="data-8-col"
                >
                  <Row>
                    <Col
                      xs={12}
                      className="resources-text-mobile"
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "100%",
                        paddingRight: "0px",
                      }}
                    >
                      {item.name}
                    </Col>
                    <Col
                      xs={12}
                      className="resources-subtext-mobile"
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "100%",
                        paddingRight: "0px",
                      }}
                    >
                      {item.description && item.description.text}
                    </Col>
                  </Row>
                </Col>
                <Col xs={2} />
              </Row>
              <div
                className="browse-separator"
                style={{
                  borderBottom: "1px solid #CCD2DE",
                  marginTop: "4px",
                  marginBottom: "-4px",
                  marginLeft: "62px",
                }}
              ></div>
            </Col>
          </Grid>
        </div>

        {this.state.isAddToFolderModalOpen && (
          <AddToFolderModal
            handleClose={this.handleModalClose}
            handleSuccessSnackbar={this.handleSuccessSnackBar}
            handleErrorSnackbar={this.handleErrorSnackBar}
            itemClicked={item}
          />
        )}

        {this.state.isDeleteModalOpen ? (
          <DeleteConfirmationModal
            handleRemoveFromLibrary={
              this.props.removeRecents
                ? this.removeRecentsFromLibrary
                : this.removeFromLibrary
            }
            handleDeleteModalClose={this.handleDeleteModalClose}
          />
        ) : (
          <></>
        )}

        {this.state.isDeleteModalOpenFolder && this.props.isFolder ? (
          <DeleteConfirmationModal
            handleRemoveFromLibrary={this.removeFromFolder}
            handleDeleteModalClose={this.handleDeleteModalCloseFolder}
          />
        ) : (
          <></>
        )}

        <SnackBarModule
          addToLibrarySuccess={this.state.addToLibrarySuccess}
          isErrorOpen={this.state.isErrorOpen}
          removeFromLibrarySuccess={this.state.removeFromLibrarySuccess}
          removeFromLibraryError={this.state.removeFromLibraryError}
          addToFolderSuccess={this.state.addToFolderSuccess}
          deleteFromFolderSuccess={this.state.deleteFromFolderSuccess}
          deleteFromFolderError={this.state.deleteFromFolderError}
          addToFolderError={this.state.addToFolderError}
          addToFolderErrorMsg={this.state.addToFolderErrorMsg}
        />
      </>
    );
  }
}

const mapStateToProps = ({ CustomFilters, FilterScale }) => ({
  strand_number: CustomFilters.strand,
  query: FilterScale.query,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(ResponsiveResourcesNew));
